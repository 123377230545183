// src/socket.js
import io from 'socket.io-client';
import { API_BASE_URL } from "./config/AppConfig";

let socket;

export const createSocketConnection = (userId, handleNotification) => {
  socket = io(`${API_BASE_URL}`, {
    path: '/ws/socket.io',
    transports: ['websocket'],
  });

  socket.on('connect', () => {
    console.log('Connected to server');
    socket.emit('join', userId);
    console.log('userid:', userId);
  });

  socket.on('notification', (data) => {
    console.log('Notification from server:', data);
    handleNotification(data)
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from server');
  });

  socket.on('connect_error', (err) => {
    console.error('Connection error:', err);
  });
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
};

