import React, { useEffect, useState, useRef } from 'react';
import { Input, Form, Modal, Spin } from 'antd';
import axiosInstance from '../../config/axiosConfig';
import { notification } from 'antd';
import { useSelector } from 'react-redux';

import '../../assets/Styles/Settings-SideMenu.css'

const Profile = () => {

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;

  const isSuperAdmin = authData.userInfo.is_super_admin;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const [syncHubspot, setSyncHubspot] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [form] = Form.useForm();

  const fetchSettings = async () => {
    setShowLoader(true);
    try {
      const response = await axiosInstance.get(`/settings/get_settings/${companyId}?user_id=${userId}`);
      setSyncHubspot(response.data.company.sync_hubspot)
      form.setFieldsValue({
        displayName: response.data.company.display_name,
        jobTitle: response.data.company.job_title,
        companyName: response.data.company.name,
        website: response.data.company.website,
      });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Settings' });
    }

    setShowLoader(false);
  }


  const saveProfile = async (values) => {
    setShowLoader(true);
    try {
      const payload = {
        data: {
          name: values?.companyName,
          website: values?.website,
          display_name: values?.displayName,
          job_title: values?.jobTitle,
          sync_hubspot: syncHubspot
        }
      }
      await axiosInstance.put(`/settings/update/company/${companyId}?user_id=${userId}`, payload);
      notification.success({ message: 'Success', description: 'Updated successfully' });
      fetchSettings()
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in updating the details' });
    }

    setShowLoader(false);
  }

  const handleSave = () => {
    form.submit();
  };



  useEffect(() => {
    fetchSettings()
  }, []);

  return (
    <div className="bootstrap d-flex flex-row text-white w-100 justify-content-center align-items-center settings-background">
      <div className='d-flex flex-column profile-card'>
        <div className='profile-text w-100 mb-3'>Profile</div>
        <div className='profile-sub-text w-100 mb-3'>Manage your Pipeline personal information.</div>
        <Form
          form={form}
          onFinish={saveProfile}
        >
          <div className='profile-table w-100 p-4'>
            <div className='d-flex justify-content-between profile-row pb-4'>
              <div className='profile-label pt-1'>Display Name</div>
              <Form.Item name="displayName" className='pb-0'>
                <Input className="profile-value" disabled={!editAccess} />
              </Form.Item>
            </div>
            <div className='d-flex justify-content-between profile-row pb-4 pt-4 '>
              <div className='profile-label pt-1'>Job Title</div>
              <Form.Item name="jobTitle" className='pb-0'>
                <Input className="profile-value" disabled={!editAccess} />
              </Form.Item>
            </div>
            <div className='d-flex justify-content-between profile-row pb-4 pt-4'>
              <div className='profile-label pt-1'>Company Name</div>
              <Form.Item name="companyName" className='pb-0' rules={[
                {
                  required: true,
                  message: "Please enter a valid name"
                },
              ]}>

                <Input className="profile-value" disabled={!editAccess} />
              </Form.Item>
            </div>
            <div className='d-flex justify-content-between pt-4'>
              <div className='profile-label pt-1'>Company Website</div>
              <Form.Item name="website" className='pb-0'>
                <Input className="profile-value" disabled={!editAccess} />
              </Form.Item>
            </div>
          </div>
        </Form>
        <div className='profile-save mt-3 pointer' onClick={handleSave}>Save</div>
      </div>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={showLoader}

      >
        <div className="pt-4 text-center">
          <Spin size="large" />
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
