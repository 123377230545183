import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    notification,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Table,
    Tag,
    Tooltip,
    Upload,
    Radio,
    Progress,
    Checkbox,
    Avatar,
    Dropdown,
    Drawer,
    Typography
} from "antd";
import {
    CheckCircleOutlined,
    PlusSquareOutlined,
    UnorderedListOutlined,
    InfoCircleOutlined,
    SearchOutlined,
    ArrowLeftOutlined,
} from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import axiosInstance from "../../../config/axiosConfig";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../../config/AppConfig";
import { DropdownArrow, FlagIcon } from "../../../assets/svg/Icons";
import CustomPagination from "../../../component/CustomPagination";
import CustomPaginationProspecting from "../../../component/CustomPaginationProspecting";
import TimeoutErrorModal from "../../../common/TimeoutErrorModal";
import NewProspect from "../../personas/new-prospect";
import { fundingSelectOptions, headCountOptions } from '../../../constants/MockData';
import { show } from "dom7";
import { filter } from "lodash";
import getLeadCategoryIcon from "../../../common/getLeadCategoryIcon";


const Analytics = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const authData = useSelector((state) => state.auth);
    const companyData = useSelector(
        (state) => state.companyReducer.selectedCompany
    );

    const userId = authData.userInfo.is_super_admin
        ? companyData.user_id
        : authData.userInfo.userid;
    const companyId = companyData.company_id;
    const isCompany = useRef(false);

    const { Paragraph } = Typography;
    const [analyticsCurrentPage, setAnalyticsCurrentPage] = useState(1);
    const [exportLeadModalOpen, setExportLeadModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [analyticsData, setAnalyticsData] = useState([])
    const [allSelectEnrich, setAllSelectEnrich] = useState(false);
    const [loading, setLoading] = useState(-1);
    const sortByButtonRef = useRef(null);
    const [leadCategories, setLeadCategories] = useState([])
    const [sortByOpen, setSortByOpen] = useState(false);
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [detailsDrawer, setDetailsDrawer] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(false);

    const momentTimezone = require('moment-timezone');

    const leadDetailColumns = [
        {
            title: <Checkbox className="d-flex pt-2" checked={''}
                disabled={false} />,
            width: '3%',
            render: (text, record) => (
                <Checkbox
                    className="d-flex pt-2"
                    checked={false}
                    disabled={false}

                />
            ),
        },
        {
            title: <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>NAME</div>,
            key: "name",
            dataIndex: "name",
            sorter: true,
            width: "22%",
            render: (text, record) => (
                <Space
                    style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer"
                    }}
                    onClick={() =>  { 
                        setSelectedProfile(record)
                        setDetailsDrawer(true)
                    }}
                >
                    <Space>
                      
                            <Avatar src={record.profile_pic == null ? '/assets/images/default-dp-radial-2x.png' : record.profile_pic} size={30} shape="circle" />
                     
                        <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{text}</span>
                    </Space>
                    <a href={record.linkedin_url} target="_blank">
                        <img
                            src="/assets/svg/linkedin.svg"
                            width="auto"
                            height="12"
                            alt="profile"
                        />
                    </a>
                </Space>
            ),
        },
        {
            title: (
                <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>COMPANY</div>
            ),
            key: "company",
            dataIndex: "company",
            width: "22%",
            sorter: true,
            render: (text, record) =>
                <Space
                    style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Space>
                        {record.company_logo && (
                            <Avatar src={record.company_logo} size={30} shape="circle" />
                        )}
                        <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{text}</span>
                    </Space>
                    <a href={record.website} target="_blank">
                        <img
                            src="/assets/svg/link-in-new-tab.svg"
                            width="auto"
                            height="14"
                            alt="company"
                        />
                    </a>
                </Space>

        },
        {
            title: (
                <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>JOB TITLE</div>
            ),
            key: "job_title",
            dataIndex: "job_title",
            sorter: true,
            // render: (text, record) => <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{record.job_title}</span>
            render: (text, record) =>
                <Tooltip
                    title={
                        <div className="">
                            <div style={{
                                backgroundColor: "#727672", margin: "0%",
                                border: "1px solid rgb(73, 78, 73) !important",
                                borderRadius: "5px", paddingLeft: "2%",
                                paddingRight: "2%", width: "100%", paddingBottom: "9%", paddingTop: "8%",
                                lineHeight: "13.72px"
                            }} >
                                {record?.job_title}
                            </div>

                        </div>
                    }
                >
                    <div className="pointer" style={{ fontSize: '11.25px', fontWeight: '600' }}>
                        {record?.job_title?.substring(0, 15)}{record?.job_title?.length > 15 ? "..." : ""}
                    </div>
                </Tooltip>
        },
        {
            title: (
                <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>ACCOUNT</div>
            ),
            key: "account",
            dataIndex: "account",
            sorter: true,
            render: (text, record) => <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{record.account_name}</span>
        },
        {
            title: (
                <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>CAMPAIGN NAME</div>
            ),
            key: "campaign",
            dataIndex: "campaign",
            sorter: true,
            render: (text, record) => <span style={{ fontSize: '11.25px', fontWeight: '600'/* , textWrap: 'nowrap' */ }}>{record.campaign?.campaign_name}</span>
        },
        {
            title: <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>STATUS</div>,
            key: "status",
            dataIndex: "status",
            render: (text, record) =>
            (
                <div style={renderStatus(record.profile_not_found ? "NO PROFILE FOUND" : record.already_connected? "ALREADY CONNECTED" : record.status)}>
                    {record.profile_not_found ? "NO PROFILE FOUND" : record.already_connected? "ALREADY CONNECTED" :  record.status}</div>
            )
        },
        {
            title: <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>ACTION TAKEN TIME</div>,
            key: "action_timestamp",
            dataIndex: "action_timestamp",
            sorter: true,
            render: (text, record) =>
            (
                <div style={{ fontSize: '11.25px', fontWeight: '600' }}> {record?.action_timestamp === null ? "--" :
                    <div> {moment.utc(record?.action_timestamp).local().format('MM-DD-YYYY [at] h:mm A z')}
                        {momentTimezone.tz(momentTimezone.tz.guess()).zoneAbbr()}</div>
                }
                </div>
            )
        },
        // {
        //     title: <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>INTEREST</div>,
        //     width: '25%',
        //     render: (text, record) => 
        //         record.status === 'RESPONDED' && record.interest === null ? <Select
        //             placeholder="Select"
        //             options={leadCategories}
        //             suffixIcon={<DropdownArrow />}
        //             style={{
        //                 width: "100%",
        //                 border: "2px solid #1E75FF80",
        //                 borderRadius: "5px",
        //                 backgroundColor: "#1C1C24 !important",
        //             }}
        //             onChange={(e) => {
        //                 updateLead(e,record.lead_id)
        //             }}
        //             value='Select'
        //         /> : <div style={{ fontSize: '11.25px', fontWeight: '600' }}>{record.interest}</div>         

        // }
    ];

    useEffect(() => {
        fetchLeadCategories();
        fetchAnalyticsDetails(analyticsCurrentPage);

    }, []);

    const updateLead = async (lead_category_id, lead_id) => {
        setLoading(1);

        try {
            await axiosInstance.put(`/linkedin_inbox/update_inbox_lead_status/${lead_id}?user_id=${userId}&company_id=${companyId}&lead_category_id=${lead_category_id}`);

            notification.success({ message: 'Success', description: 'Lead category has been updated successfully' });
            await fetchAnalyticsDetails(1);
            let leadCategory = leadCategories.filter(i => i.value === lead_category_id)[0]?.name
            setSelectedProfile({...selectedProfile, interest: leadCategory})

        } catch (error) {
            notification.error({ message: 'Error', description: error?.response?.data?.detail });
        }

        setLoading(0);
    }

    const fetchAnalyticsDetails = async (page, sortBy, sortOrder) => {
        setLoading(1)
        try {
            const response = await axiosInstance.get(
                `/linkedin/home_details/${userId}?company_id=${companyId}&limit=25&page=${page}&search_query=${searchQuery}&sort_by=${sortBy ? sortBy : ''}&sort_order=${sortOrder? sortOrder : ''}`
            );
            if (response.status === 200) {
                // notification.success({
                //     message: "Success",
                //     description: "Campaign details fetched successfully",
                // });
                setAnalyticsData(response.data);
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the Analytics details",
            });
        }

        setLoading(0);
    }

    const fetchLeadCategories = async () => {
        setLoading(1)
        try {
            const response = await axiosInstance.get(
                `/engage_inbox/lead_categories`
            );
            if (response.status === 200) {
                const options = [];

                response.data.forEach(filter => {
                    const row = {
                        key: filter.id,
                        value: filter.id,
                        name: filter.name,
                        label: <div className="bootstrap">
                            <div className="d-flex align-items-center">
                                <img src={`/assets/images/${getLeadCategoryIcon(filter.name)}`} width="16" height="auto" alt="icon" />
                                <span className="ps-2">{filter.name}</span>
                            </div>
                        </div>
                    }
                    options.push(row);
                });

                setLeadCategories(options);
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the Lead Categories",
            });
        }

        setLoading(0);
    }

    const renderStatus = (status) => {
        let style = {};

        switch (status) {
            case "PROFILE VIEWED":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(188, 135, 255, 0.2)', padding: '3px 7px', border: '1px solid rgba(188, 135, 255, 1)', borderRadius: '5px', display: 'inline-block' };
            case "REQUEST SENT":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(66, 138, 251, 0.52)', padding: '3px 7px', border: '1px solid rgba(123, 174, 255, 1)', borderRadius: '5px', display: 'inline-block' };
            case "WITHDRAWN":
            case "ALREADY CONNECTED":
            case "REPLIED":
            case "NO PROFILE FOUND":
            case "NOT STARTED":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(81, 78, 84, 0.66)', padding: '3px 7px', border: '1px solid rgba(113, 119, 127, 1)', borderRadius: '5px', display: 'inline-block' };
            case "ACCEPTED":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(9, 191, 16, 0.55)', padding: '3px 7px', border: '1px solid rgba(16, 239, 25, 1)', borderRadius: '5px', display: 'inline-block' };
            case "RESPONDED":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(255, 135, 250, 0.54)', padding: '3px 7px', border: '1px solid rgba(255, 135, 250, 1)', borderRadius: '5px', display: 'inline-block' };
            case "FOLLOWUP SENT":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(125, 135, 250, 0.54)', padding: '3px 7px', border: '1px solid rgba(125, 135, 250, 1)', borderRadius: '5px', display: 'inline-block' };
            default:
                return style;
        }
    };

    const getTextColor = percentage => {
        if (!percentage) return;
        let className;

        if (percentage.indexOf("-") === 0) {
            className = "text-danger";
        } else {
            className = "text-success";
        }

        return className;
    }

    const handleAnalyticsDetailTableChange = ({ page }) => {
        setAnalyticsCurrentPage(page)
        fetchAnalyticsDetails(page, sortBy, sortOrder)
    }

    const triggerInboxSearch = () => {
        const searchValue = document.querySelector("#search-box").value;
        if (!searchValue || searchValue.length < 1) {
            setSearchQuery("");
        } else {
            setSearchQuery(searchValue);
            fetchAnalyticsDetails(1);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            fetchAnalyticsDetails(1);
        }
      };


    const handleSortBy = (sortBy) => {
        setSortBy(sortBy)
        setSearchQuery("");
        setAnalyticsCurrentPage(1)
        fetchAnalyticsDetails(1, sortBy)
    }

    const handleTableChange = async (pagination, filters, sorter) => { 
        const order = sorter.order === 'ascend' ? 'asc' : 'desc';
        setSortOrder(order)  
        setSortBy(sorter.field)  
        fetchAnalyticsDetails(1, sorter.field, order)
      };

      const renderLeadCategory = (lead_category) => {
        const filteredRow = leadCategories.find(f => f.name === lead_category);
        return filteredRow?.value
    }

    const updateLeadCategory = async (lead_category_id, lead_id) => {
        updateLead(lead_category_id, lead_id);

    }

    return (

        <Layout style={{ minHeight: '100vh', padding: 35 }} className="bootstrap linkedin-analytics">
            <Row gutter={30} className="pb-3">
                <Col span={6} className="gutter-row d-flex align-items-center">
                    <div className="analytics-heading font-weight-500 px-2 d-flex flex-row">
                        <span className="text-nowrap" style={{ position: 'relative', display: 'inline-block' }}>
                            LinkedIn
                            <sup style={{ fontSize: '8px', fontWeight: '700', position: 'absolute', top: 4, right: -24 }}>AUTO</sup>
                        </span>
                    </div>

                </Col>
                <Col span={18}>
                    <div style={{ height: '37px' }} className='d-flex justify-content-end'>

                        <div
                            style={{
                                background: 'rgba(30, 117, 255, 1)',
                                width: '127px',
                                height: '32.6px',
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '32.6px',
                                letterSpacing: '0em',
                                textAlign: 'center',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRadius: '4.18px',
                                cursor: 'pointer'
                            }}
                            onClick={() => navigate("/tools/linkedin/campaign-creation")}
                        >
                            New Campaign
                        </div>
                    </div>
                </Col>
            </Row>
            <br />
            <Row gutter={30} className="pb-3">
                <Col span={24} className="gutter-row">
                    <div>
                        <Row className="campaign-detail-btns pb-4">
                            <div className="custom-card br-13">
                                <div className="d-flex justify-content-between align-items-center pb-3">
                                    <div>
                                        <span className="text-white fw-bold pe-2 title">Invites Sent</span>

                                    </div>
                                    <img src="/assets/svg/vector.svg" width="18" height="auto" alt="icon" />
                                </div>

                                <div className="text-white fs-6 fw-semibold pb-3 total">{analyticsData.invites_sent?.total}</div>

                                <div >
                                    {/* <span style={{ fontSize: '14px' }}
                                        className={`${getTextColor(analyticsData.invites_sent?.percentage)} percent`}>
                                        {analyticsData.invites_sent?.percentage}
                                    </span>
                                    <span className="white-text-71 percent"> from last week</span> */}
                                </div>
                            </div>

                            <div className="custom-card br-13" style={{ width: "16%" }}>
                                <div className="d-flex justify-content-between align-items-center pb-3">
                                    <div>
                                        <span className="text-white fw-bold pe-2 title">Invites Accepted</span>

                                    </div>
                                    <img src="/assets/svg/thumbs-up.svg" width="20" height="auto" alt="icon" />
                                </div>

                                <div className="text-white fs-6 fw-semibold pb-3 total">{analyticsData.invites_accepted?.total}</div>
                                <div >
                                    {/* <span style={{ fontSize: '14px' }}
                                        className={`${getTextColor(analyticsData.invites_accepted?.percentage)} percent`}>
                                        {analyticsData.invites_accepted?.percentage}
                                    </span>
                                    <span className="white-text-71 percent"> acceptance rate</span> */}
                                </div>
                            </div>

                            <div className="custom-card br-13" style={{ width: "16%" }}>
                                <div className="d-flex justify-content-between align-items-center pb-3">
                                    <div>
                                        <span className="text-white fw-bold pe-2 title">Profiles Viewed</span>

                                    </div>
                                    <img src="/assets/svg/happy.svg" width="23" height="auto" alt="icon" />
                                </div>

                                <div className="text-white fs-6 fw-semibold pb-3 total">{analyticsData.profile_viewed?.total}</div>

                                <div >
                                    {/* <span style={{ fontSize: '14px' }}
                                        className={`${getTextColor(analyticsData.profile_viewed?.percentage)} percent`}>
                                        {analyticsData.profile_viewed?.percentage}
                                    </span>
                                    <span className="white-text-71 percent"></span> */}
                                </div>
                            </div>

                            <div className="custom-card br-13" style={{ width: "16%" }}>
                                <div className="d-flex justify-content-between align-items-center pb-3">
                                    <div>
                                        <span className="text-white fw-bold pe-2 title">Follow-Ups Sent</span>

                                    </div>
                                    <img src="/assets/svg/chatbox.svg" width="22" height="auto" alt="icon" />
                                </div>

                                <div className="text-white fs-6 fw-semibold pb-3 total">{analyticsData.followup_sent?.total}</div>

                                <div >
                                    {/* <span style={{ fontSize: '14px' }}
                                        className={`${getTextColor(analyticsData.followup_sent?.percentage)} percent`}>
                                        {analyticsData.followup_sent?.percentage}
                                    </span>
                                    <span className="white-text-71 percent"> from last week</span> */}
                                </div>
                            </div>

                            <div className="custom-card br-13" style={{ width: "16%" }}>
                                <div className="d-flex justify-content-between align-items-center pb-3">
                                    <div>
                                        <span className="text-white fw-bold pe-2 title">Replied</span>

                                    </div>
                                    <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="22" height="auto" alt="icon" />
                                </div>

                                <div className="text-white fs-6 fw-semibold pb-3 total">{analyticsData.replied?.total}</div>
                            </div>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row gutter={30} className="pb-3">
                <Col span={8}>
                    <div className="analytics-heading font-weight-500 px-2 d-flex flex-row">
                        <span className="text-nowrap">
                            Your Leads

                        </span>
                    </div>
                </Col>
                <Col span={16}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Space size={15}>
                            <Input
                                id="search-box"
                                placeholder="search by name, email etc."
                                className="settings-enter-email custom-search-input"
                                prefix={<SearchOutlined onClick={triggerInboxSearch} style={{ color: 'inherit', fontSize: '18px' }} />}
                                style={{
                                    border: 'none', background: 'rgba(37, 37, 42, 1)',
                                    width: '363px',
                                    height: '33px', borderRadius: '7px'
                                }}
                                onChange={(e) => {
                                    setSearchQuery(e.target.value)

                                }}
                                onKeyDown={handleKeyPress}
                            />
                            <div
                                className="campaign-detail-sort-by-btn inactive d-flex align-items-center pointer" ref={sortByButtonRef}
                                onClick={() => setSortByOpen(!sortByOpen)}
                            >
                                <img src="/assets/svg/sort-by-icon.svg" className="ps-2" />
                                <span style={{ fontSize: '13.58px', fontWeight: '600' }} className="text-nowrap ps-2 pe-2">Sort By</span>
                            </div>
                        </Space>


                    </div>
                </Col>
            </Row>
            <Table
                className="linkedin-table"
                dataSource={analyticsData?.leads_data}
                columns={leadDetailColumns}
                pagination={false}
                onChange={handleTableChange}
                style={{
                    thead: { display: 'table-header-group', height: `5px` },
                    tbody: { display: 'block', height: '300px', overflow: 'auto' },
                    tr: { display: 'table', height: `$10px` }
                }}
            // scroll={{ x: '80%' }}
            />
            {analyticsData?.leads_data && <CustomPaginationProspecting
                currentPage={analyticsCurrentPage}
                totalItems={analyticsData?.pagination.total_records}
                noOfRecords={25}
                onChange={handleAnalyticsDetailTableChange}
            />}
            <Modal
                closable={false}
                width={null}
                centered={true}
                footer={null}
                title={null}
                open={loading === 1 ? true : false}

            // className="bootstrap custom-modal text-white"
            >
                <div className="pt-4 text-center">
                    <Spin size="large" />
                </div>
            </Modal>
            <Drawer
                title=""
                placement="right"
                width={376}
                closable={false}
                onClose={() => {
                    setDetailsDrawer(false);
                }}
                open={detailsDrawer}
                key="left"
                className="bootstrap"
            >
                <div className="text-white">
                    <div className="d-flex align-items-center pb-2 pointer" onClick={() => {
                        setDetailsDrawer(false)
                    }}>
                        <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
                        <span className="ps-2 white-text-62">Close</span>
                    </div>
                    <div className="campaign-detail-profile-grp" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <Avatar className="mb-2" src={`${!selectedProfile?.profile_pic ? '/assets/images/default-dp-radial-2x.png' : selectedProfile?.profile_pic}`} size={65} shape="circle" />
                        <span className="mb-2" style={{ fontSize: '24px', fontWeight: '500' }}>{selectedProfile?.name}</span>
                        
                            <Select
                                placeholder="Select Status"
                                options={leadCategories}
                                suffixIcon={<DropdownArrow />}
                                style={{
                                    border: '1px solid rgba(30, 117, 255, 0.5)',
                                    background: 'rgba(28, 28, 36, 1)',
                                    width: '70%',
                                    borderRadius: '5.19px'
                                }}
                                className="mb-3"
                                onChange={(e) => { updateLeadCategory(e, selectedProfile.lead_id) }}
                                value={selectedProfile?.interest !== undefined ? renderLeadCategory(selectedProfile?.interest) : 'Select'}
                            />
                        
                        <div className="campaign-detail-btn-grp mb-3">
                            <div style={{ marginRight: '15px', cursor: "auto" }} className='campaign-details-btn'>
                                Contact Details
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'start' }}>
                        <>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Name</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.name}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Email</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.email}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Job Title</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.job_title}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Company</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.company}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Phone</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.phonenumber}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Website</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.website}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Linkedin</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.linkedin_url}</Paragraph>
                        </>
                    </div>
                </div>
            </Drawer>
            {sortByOpen &&
                <div className="linkedin-analytics-sort-by-list overlay-container mt-2 ps-3 py-2 ms-1 
                font-size-14 font-weight-400 pointer bootstrap" style={{
                        top: sortByButtonRef.current ?
                            sortByButtonRef.current.getBoundingClientRect().bottom - -10 + window.scrollY : 0,
                        left: sortByButtonRef.current ?
                            sortByButtonRef.current.getBoundingClientRect().left - 13 : 0,
                        cursor: "pointer",
                        width: "8%"
                    }}
                    onClick={() => setSortByOpen(false)}>
                    {<div
                        onClick={() => {
                            handleSortBy("name")
                        }}
                    >Name</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('company')

                        }}
                    >Company</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('job_title')

                        }}
                    >Job Title</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('account')

                        }}
                    >Account</div>}
                    {/* {<div
                        onClick={() => {
                            handleSortBy('status')

                        }}
                    >Status</div>} */}
                    {/* {<div
                        onClick={() => {
                            handleSortBy('interest')

                        }}
                    >Interest</div>} */}
                    {<div
                        onClick={() => {
                            handleSortBy('campaign')

                        }}
                    >Campaign</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('action_timestamp')

                        }}
                    >Last Action Time</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('profile_viewed')

                        }}
                    >Profile Viewed</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('not_started')

                        }}
                    >Not Started</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('accepted')

                        }}
                    >Accepted</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('responded')

                        }}
                    >Responded</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('request_sent')

                        }}
                    >Request Sent</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('follow_up')

                        }}
                    >Follow Up</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('replied')

                        }}
                    >Replied</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('withdrawn')

                        }}
                    >Withdrawn</div>}
                </div>
            }
        </Layout>


    )
}

export default Analytics

