import React from 'react';
import { API_BASE_URL } from "../config/AppConfig";
import { ReactComponent as SlackLogo } from '../assets/svg/slack-icon.svg';
import './menu.css';

const SlackButton = ({ userId }) => {

  const handleSlackAuth = async () => {
    const response = await fetch(`${API_BASE_URL}/notifications/login?user_id=${userId}`);
    const data = await response.json();
    window.location.href = data.url;
  };

  return (
    <buttton onClick={handleSlackAuth} className="custom-btn large blue text-white " style={{ display: 'flex', alignItems: 'center', padding: '7px', paddingLeft: '17px', paddingRight: '17px'}}>
          <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>

    <SlackLogo width="30px" height="30px"/>
          </div>

        <span style={{ fontWeight: '600', fontSize: '15.83px' }}>Add Slack integration</span>
    </buttton>
  );
};

export default SlackButton;
