import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    notification,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Table,
    Tag,
    Tooltip,
    Upload,
    Radio,
    Progress,
    Checkbox,
    Avatar,
    Dropdown,
    Drawer,
    Typography
} from "antd";
import {
    CheckCircleOutlined,
    PlusSquareOutlined,
    UnorderedListOutlined,
    InfoCircleOutlined,
    SearchOutlined,
    ArrowLeftOutlined,
} from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import axiosInstance from "../../../config/axiosConfig";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../../config/AppConfig";
import { DropdownArrow, FlagIcon } from "../../../assets/svg/Icons";
import CustomPagination from "../../../component/CustomPagination";
import CustomPaginationProspecting from "../../../component/CustomPaginationProspecting";
import TimeoutErrorModal from "../../../common/TimeoutErrorModal";
import NewProspect from "../../personas/new-prospect";
import { fundingSelectOptions, headCountOptions } from '../../../constants/MockData';
import { show } from "dom7";
import { filter } from "lodash";
import getLeadCategoryIcon from "../../../common/getLeadCategoryIcon";



const CampaignsDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const scrollRef = useRef(null);
    const authData = useSelector((state) => state.auth);
    const companyData = useSelector(
        (state) => state.companyReducer.selectedCompany
    );

    const userId = authData.userInfo.is_super_admin
        ? companyData.user_id
        : authData.userInfo.userid;
    const companyId = companyData.company_id;
    const isCompany = useRef(false);
    const [campaignsDetailCurrentPage, setCampaignsDetailCurrentPage] = useState(1);
    const [exportLeadModalOpen, setExportLeadModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [campaignDetailData, setCampaignDetailData] = useState([])
    const [allSelectEnrich, setAllSelectEnrich] = useState(false);
    const [loading, setLoading] = useState(-1);
    const sortByButtonRef = useRef(null);
    const [leadCategories, setLeadCategories] = useState([])
    const [campaignDetailsDrawer, setCampaignDetailsDrawer] = useState(false)
    const [sidebarTab, setSidebarTab] = useState("details");
    const { Paragraph } = Typography;
    const [selectedProfile, setSelectedProfile] = useState()
    const [selectedCampaignId, setSelectedCampaignId] = useState('')
    const [editCamapignModal, setEditCamapignModal] = useState(false)
    const [newCampaignName, setNewCampaignName] = useState('')
    const [selectedLeads, setSelectedLeads] = useState([])
    const [allSelectedLeads, setAllSelectedLeads] = useState(false)
    const [deleteLeadsModal, setDeleteLeadsModal] = useState(false)
    const [sortByOpen, setSortByOpen] = useState(false);
    const [sortBy, setSortBy] = useState("");
    const [leadActivity, setLeadActivity] = useState()
    const [sortOrder, setSortOrder] = useState("");

    const momentTimezone = require('moment-timezone');

    const leadDetailColumns = [
        {
            title: <Checkbox className="d-flex pt-2" checked={allSelectedLeads}
                onChange={(e) => {
                    if (e.target.checked) {
                        setSelectedLeads(campaignDetailData?.leads_data?.map(i => i.lead_id))
                        setAllSelectedLeads(true)
                    }
                    else {
                        setSelectedLeads([])
                        setAllSelectedLeads(false)
                    }
                }}
                disabled={false} />,
            width: '3%',
            render: (text, record) => (
                <Checkbox
                    className="d-flex pt-2"
                    checked={selectedLeads.filter(i => i === record.lead_id).length > 0}
                    onChange={(e) => {
                        if (e.target.checked === true) {
                            setSelectedLeads([...selectedLeads, record.lead_id]);
                        } else {
                            setSelectedLeads(selectedLeads.filter((i) => i !== record.lead_id));
                        }
                    }}
                    disabled={false}

                />
            ),
        },
        {
            title: <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>NAME</div>,
            key: "name",
            dataIndex: "name",
            width: "22%",
            sorter: true,
            render: (text, record) => (
                <Space
                    style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Space>

                        <Avatar style={{ cursor: 'pointer' }} onClick={() => { setCampaignDetailsDrawer(true); setSelectedProfile(record); getLeadActivity(record) }} src={record.profile_pic == null ? '/assets/images/default-dp-radial-2x.png' : record.profile_pic} size={30} shape="circle" />

                        <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{text}</span>
                    </Space>
                    <a href={record.linkedin_url} target="_blank">
                        <img
                            src="/assets/svg/linkedin.svg"
                            width="auto"
                            height="12"
                            alt="profile"
                        />
                    </a>
                </Space>
            ),
        },
        {
            title: (

                <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>COMPANY</div>
            ),
            key: "company",
            dataIndex: "company",
            width: "22%",
            sorter: true,
            render: (text, record) =>
                <Space
                    style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Space>
                        {record.company_logo && (
                            <Avatar src={record.company_logo} size={30} shape="circle" />
                        )}
                        <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{text}</span>
                    </Space>
                    <a href={record.website} target="_blank">
                        <img
                            src="/assets/svg/link-in-new-tab.svg"
                            width="auto"
                            height="14"
                            alt="company"
                        />
                    </a>
                </Space>

        },
        {
            title: (
                <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>JOB TITLE</div>
            ),
            key: "job_title",
            dataIndex: "job_title",
            sorter: true,
            // render: (text, record) => <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{record.job_title}</span>
            render: (text, record) =>
                <Tooltip
                    title={
                        <div className="">
                            <div style={{
                                backgroundColor: "#727672", margin: "0%",
                                border: "1px solid rgb(73, 78, 73) !important",
                                borderRadius: "5px", paddingLeft: "2%",
                                paddingRight: "2%", width: "100%", paddingBottom: "9%", paddingTop: "8%",
                                lineHeight: "13.72px"
                            }} >
                                {record?.job_title}
                            </div>

                        </div>
                    }
                >
                    <div className="pointer" style={{ fontSize: '11.25px', fontWeight: '600' }}>
                        {record?.job_title?.substring(0, 15)}{record?.job_title?.length > 15 ? "..." : ""}
                    </div>
                </Tooltip>
        },
        {
            title: (
                <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>ACCOUNT</div>
            ),
            key: "account",
            dataIndex: "account",
            sorter: true,
            render: (text, record) => <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{record?.account_name}</span>
        },
        {
            title: <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>STATUS</div>,
            key: "status",
            dataIndex: "status",
            render: (text, record) =>
            (
                <div style={renderStatus(record.profile_not_found ? "NO PROFILE FOUND" : record.already_connected? "ALREADY CONNECTED" : record.status)}>
                    {record.profile_not_found ? "NO PROFILE FOUND" : record.already_connected? "ALREADY CONNECTED": record.status}</div>
            )
        },
        {
            title: <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>ACTION TAKEN TIME</div>,
            key: "action_timestamp",
            dataIndex: "action_timestamp",
            sorter: true,
            render: (text, record) =>
            (
                <div style={{ fontSize: '11.25px', fontWeight: '600' }}> {record?.action_timestamp === null ? "--" :
                    <div> {moment.utc(record?.action_timestamp).local().format('MM-DD-YYYY [at] h:mm A z')}
                        {momentTimezone.tz(momentTimezone.tz.guess()).zoneAbbr()}</div>
                }
                </div>
            )
        },
        // {
        //     title: <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>INTEREST</div>,
        //     key: "interest",
        //     dataIndex: "interest",
        //     width: '25%',
        //     render: (text, record) =>
        //         record.status === 'RESPONDED' && record.interest === null ?
        //             <Select
        //                 placeholder="Select"
        //                 options={leadCategories}
        //                 suffixIcon={<DropdownArrow />}
        //                 style={{
        //                     width: "100%",
        //                     border: "2px solid #1E75FF80",
        //                     borderRadius: "5px",
        //                     backgroundColor: "#1C1C24 !important",
        //                 }}
        //                 onChange={(e) => {
        //                     updateLeadCategory(e, record.lead_id)
        //                 }}
        //                 value={record.interest !== undefined && record.interest !== null ? renderLeadCategory(record.interest) : 'Select'}
        //             />
        //             : <div style={{ fontSize: '11.25px', fontWeight: '600' }}>{record.interest}</div>
        // }
    ];

    useEffect(() => {
        fetchComapaignDetails(campaignsDetailCurrentPage);
        fetchLeadCategories();

    }, []);

    const scrollToTop = () => {
        const scrollStep = -window.scrollY / (150 / 10);
        const scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 15); // Adjust the interval for smoothness
    };

    const fetchComapaignDetails = async (page, sortBy, sortOrder) => {
        const searchParams = new URLSearchParams(location.search);
        const paramsFromURL = searchParams.get('campaign_id');
        setSelectedCampaignId(paramsFromURL)
        setLoading(1)
        let url = `/linkedin/campaign_details/${userId}?campaign_id=${paramsFromURL}&company_id=${companyId}&page=${page}&limit=25&search_query=${searchQuery}&sort_by=${sortBy ? sortBy : ''}&sort_order=${sortOrder? sortOrder : ''}`

        try {
            const response = await axiosInstance.get(
                url
            );
            if (response.status === 200) {
                // notification.success({
                //     message: "Success",
                //     description: "Campaign details fetched successfully",
                // });
                // setSortBy("")
                scrollToTop();
                setCampaignDetailData(response.data);
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the Campaign details",
            });
        }

        setLoading(0);
    }

    const fetchLeadCategories = async () => {
        setLoading(1)
        try {
            const response = await axiosInstance.get(
                `/engage_inbox/lead_categories`
            );
            if (response.status === 200) {
                const options = [];

                response.data.forEach(filter => {
                    const row = {
                        key: filter.id,
                        value: filter.id,
                        name: filter.name,
                        label: <div className="bootstrap">
                            <div className="d-flex align-items-center">
                                <img src={`/assets/images/${getLeadCategoryIcon(filter.name)}`} width="16" height="auto" alt="icon" />
                                <span className="ps-2">{filter.name}</span>
                            </div>
                        </div>
                    }
                    options.push(row);
                });

                setLeadCategories(options);
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the Lead Categories",
            });
        }

        setLoading(0);
    }

    const renderStatus = (status) => {
        let style = {};

        switch (status) {
            case "PROFILE VIEWED":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(188, 135, 255, 0.2)', padding: '3px 7px', border: '1px solid rgba(188, 135, 255, 1)', borderRadius: '5px', display: 'inline-block' };
            case "REQUEST SENT":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(66, 138, 251, 0.52)', padding: '3px 7px', border: '1px solid rgba(123, 174, 255, 1)', borderRadius: '5px', display: 'inline-block' };
            case "WITHDRAWN":
            case "REPLIED":
            case "ALREADY CONNECTED":
            case "NO PROFILE FOUND":
            case "NOT STARTED":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(81, 78, 84, 0.66)', padding: '3px 7px', border: '1px solid rgba(113, 119, 127, 1)', borderRadius: '5px', display: 'inline-block' };
            case "ACCEPTED":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(9, 191, 16, 0.55)', padding: '3px 7px', border: '1px solid rgba(16, 239, 25, 1)', borderRadius: '5px', display: 'inline-block' };
            case "RESPONDED":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(255, 135, 250, 0.54)', padding: '3px 7px', border: '1px solid rgba(255, 135, 250, 1)', borderRadius: '5px', display: 'inline-block' };
            case "FOLLOWUP SENT":
                return { fontSize: '9.33px', fontWeight: '600', whiteSpace: 'nowrap', background: 'rgba(125, 135, 250, 0.54)', padding: '3px 7px', border: '1px solid rgba(125, 135, 250, 1)', borderRadius: '5px', display: 'inline-block' };
            default:
                return style;
        }
    };

    const getTextColor = percentage => {
        if (!percentage) return;
        let className;

        if (percentage.indexOf("-") === 0) {
            className = "text-danger";
        } else {
            className = "text-success";
        }

        return className;
    }

    const handleCampaignDetailTableChange = ({ page }) => {
        setCampaignsDetailCurrentPage(page)
        fetchComapaignDetails(page, sortBy, sortOrder)
    }

    const handleTableChange = async (pagination, filters, sorter) => { 
        const order = sorter.order === 'ascend' ? 'asc' : 'desc';
        setSortOrder(order)  
        setSortBy(sorter.field)  
        fetchComapaignDetails(1, sorter.field, order)
      };
    const handleEditCampaign = async () => {
        if (!newCampaignName) {
            notification.error({
                message: "Error",
                description: "Please fill the Campaign Name",
            });
            return
        }
        let payload = {
            campaign_name: newCampaignName,
        }
        setLoading(1)
        try {
            const response = await axiosInstance.put(
                `/linkedin/edit-campaign-name/${selectedCampaignId}`, payload
            );
            if (response.status === 200) {
                notification.success({
                    message: "Success",
                    description: "Updated Campaign Name successfully",
                });
                setEditCamapignModal(false)
                setNewCampaignName('')
                await fetchComapaignDetails(campaignsDetailCurrentPage);
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in updating the Campaign Name",
            });
        }

        setLoading(0);
    }

    const handleDeleteLeads = async () => {

        setLoading(1)
        try {
            const payload = selectedLeads

            const response = await axiosInstance.delete(
                `/linkedin/delete-campaign-leads/${selectedCampaignId}?new_status=false`, { data: payload }
            );
            if (response.status === 200) {
                await fetchComapaignDetails(campaignsDetailCurrentPage);
                notification.success({
                    message: "Success",
                    description: "Leads are deleted successfully",
                });
                setDeleteLeadsModal(false)
                if (allSelectedLeads) {

                }
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in deleting the Leads",
            });
        }

        setLoading(0);
    }

    const handleDownloadDetail = async () => {
        setLoading(1)
        try {
            const response = await axiosInstance.get(`/linkedin/download_linkedin_leads/${userId}?campaign_id=${selectedCampaignId}&company_id=${companyId}`);
            if (response?.data) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");

                link.href = url;
                link.setAttribute("download", "linkedin-campaign" + ".csv");
                document.body.appendChild(link);

                link.click();
                link.remove();
            }

            notification.success({
                message: "Success",
                description: "Export has been completed successfully",
            });
        } catch (error) {
            notification.error({
                message: "Error",
                description: "Opps! somethig went wrong, please try again later.",
            });
        }
        setLoading(0)
        setExportLeadModalOpen(false)
    };

    const triggerInboxSearch = () => {
        const searchValue = document.querySelector("#search-box").value;
        if (!searchValue || searchValue.length < 1) {
            setSearchQuery("");
        } else {
            setSearchQuery(searchValue);
            fetchComapaignDetails(1);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            fetchComapaignDetails(1);
        }
    };


    const handleSortBy = (sortByField) => {
        setSortBy(sortByField)
        setSearchQuery("");
        setCampaignsDetailCurrentPage(1)
        fetchComapaignDetails(1, sortByField)
    }

    const getLeadActivity = async (clickedThread) => {
        setLoading(1);

        try {
            const response = await axiosInstance.get(`/linkedin/linkedin/lead_activity/${userId}?campaign_id=${selectedCampaignId}&lead_id=${clickedThread.lead_id}&company_id=${companyId}`);
            if (response.data) {
                setLeadActivity(response.data)
            }
            // notification.success({ message: 'Success', description: 'Lead activity has been fetched successfully' });

        } catch (error) {
            notification.error({ message: 'Error', description: 'Error in fetching Lead Activity' });
        }

        setLoading(0);
    }

    const updateLeadCategory = async (lead_category_id, lead_id) => {
        updateLead(lead_category_id, lead_id);

    }

    const updateLead = async (lead_category_id, lead_id) => {
        setLoading(1);

        try {
            await axiosInstance.put(`/linkedin_inbox/update_inbox_lead_status/${lead_id}?user_id=${userId}&company_id=${companyId}&lead_category_id=${lead_category_id}`);

            notification.success({ message: 'Success', description: 'Lead category has been updated successfully' });
            await fetchComapaignDetails(campaignsDetailCurrentPage);
            let leadCategory = leadCategories.filter(i => i.value === lead_category_id)[0]?.name
            setSelectedProfile({...selectedProfile, interest: leadCategory})
        } catch (error) {
            notification.error({ message: 'Error', description: error?.response?.data?.detail });
        }

        setLoading(0);
    }

    const renderLeadCategory = (lead_category) => {
        const filteredRow = leadCategories.find(f => f.name === lead_category);
        return filteredRow?.value
    }

    const onDeleteCampaign = async (campaign) => {

        Modal.confirm({
          title: <div style={{ color: "#ffffff" }}>Delete Campaign</div>,
          content: (
            <div style={{ color: "#ffffff"}} >
              Do you want to delete the campaign?
            </div>
          ),
          okText: 'Yes',
          cancelText: 'No',
          onOk: async () => {
        try {
          setLoading(1)
          const response = await axiosInstance.delete(`/linkedin/delete-campaign-details/${selectedCampaignId}`);
          if (response.data.status === 200) {
            notification.success({ message: 'Success', description: 'Campaign deleted successfully' }); 
            navigate(`/tools/linkedin/campaign-list`)
          }
            else {
              notification.error({ message: 'Error', description: response.data.message }); }   
        }
        catch (error) {
          notification.error({ message: 'Error', description: error?.response?.data?.detail });
        }
        setLoading(0) }})   
      }

    const shareMenuAction = ({key}) => {
        if(key === "delete") {
           onDeleteCampaign()
         }
    }

    return (
        <>
            <Layout style={{ minHeight: '100vh', padding: 35, width: "100%" }} className="bootstrap">
                <Row gutter={30} className="pb-3 linkedin-campaign-detail">
                    <Col span={11} className="gutter-row d-flex align-items-center">
                        <div className="analytics-heading font-weight-500 px-2 d-flex flex-row">
                            <div className="pagination-right align-items-center back pointer position-relative me-2"
                                onClick={() => navigate(`/tools/linkedin/campaign-list`)}
                            >
                                <div className="arrow-parent position-absolute inverted-pagination-arrow">➜</div>
                            </div>
                            <span className="text-nowrap">
                                {campaignDetailData?.campaign_name?.length > 40
                                    ? campaignDetailData?.campaign_name.substring(0, 40) + ' ... '
                                    : campaignDetailData?.campaign_name}
                            </span>
                        </div>

                    </Col>
                    <Col span={13}>
                        <div style={{ height: '37px' }} className='d-flex justify-content-end me-2'>
                            <Input
                                id="search-box"
                                placeholder="search by name, email etc."
                                className="settings-enter-email custom-search-input"
                                prefix={<SearchOutlined onClick={triggerInboxSearch} style={{ color: 'inherit', fontSize: '18px' }} />}
                                style={{
                                    border: 'none', background: 'rgba(37, 37, 42, 1)',
                                    width: "100%",
                                    height: '33px', borderRadius: '7px'
                                }}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyDown={handleKeyPress}
                            />
                            <div
                                style={{
                                    background: 'rgba(9, 191, 16, 1)',
                                    width: '127px',
                                    height: '32.6px',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '32.6px',
                                    letterSpacing: '0em',
                                    textAlign: 'center',
                                    color: 'rgba(255, 255, 255, 1)',
                                    borderRadius: '4.18px',
                                    cursor: 'pointer',
                                    marginLeft: '20px'
                                }}
                               
                                onClick={() => navigate(`/tools/linkedin/campaign-edit?campaign_id=${selectedCampaignId}`)}
                            >
                                Edit Sequence
                            </div>
                            <Dropdown className="ms-1"
                menu={{
                  items : [         
                  {
                    label: (
                      <div className="bootstrap">
                        <div className="d-flex align-items-center">
                        <img src="/assets/svg/delete-red.svg" width="17" height="15" alt="icon" />
                          <span className="text-white small ps-2">Delete Campaign</span>
                        </div>
                      </div>
                    ),
                    key: "delete"
                  }],  
                  onClick: shareMenuAction
                }}
                dropdownRender={(menu, index) => (
                  <div className="bootstrap inbox-filter-dropdown" style={{marginTop:"5%"}} key={index}>{React.cloneElement(menu)}</div>
                )}
              >
                <div className="three-dots-btn silver pointer" style={{marginTop: "0.5%"}}>...</div>
              </Dropdown>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row gutter={30} className="pb-3">
                    <Col span={24} className="gutter-row">
                        <div>
                            <Row className="campaign-detail-btns pb-4">
                                <div className="custom-card br-13">
                                    <div className="d-flex justify-content-between align-items-center pb-3">
                                        <div>
                                            <span className="text-white fw-bold pe-2 title">Invites Sent</span>

                                        </div>
                                        <img src="/assets/svg/vector.svg" width="18" height="auto" alt="icon" />
                                    </div>

                                    <div className="text-white fs-6 fw-semibold pb-3 total">{campaignDetailData.invites_sent?.total}</div>

                                    <div >
                                        {/* <span style={{ fontSize: '14px' }}
                                            className={`${getTextColor(campaignDetailData.invites_sent?.percentage)} percent`}>
                                            {campaignDetailData.invites_sent?.percentage}
                                        </span>
                                        <span className="white-text-71 percent"> from last week</span> */}
                                    </div>
                                </div>

                                <div className="custom-card br-13">
                                    <div className="d-flex justify-content-between align-items-center pb-3">
                                        <div>
                                            <span className="text-white fw-bold pe-2 title">Invites Accepted</span>

                                        </div>
                                        <img src="/assets/svg/thumbs-up.svg" width="20" height="auto" alt="icon" />
                                    </div>

                                    <div className="text-white fs-6 fw-semibold pb-3 total">{campaignDetailData.invites_accepted?.total}</div>
                                    <div >
                                        {/* <span style={{ fontSize: '14px' }}
                                            className={`${getTextColor(campaignDetailData.invites_accepted?.percentage)} percent`}>
                                            {campaignDetailData.invites_accepted?.percentage}
                                        </span>
                                        <span className="white-text-71 percent"> acceptance rate</span> */}
                                    </div>
                                </div>

                                <div className="custom-card br-13">
                                    <div className="d-flex justify-content-between align-items-center pb-3">
                                        <div>
                                            <span className="text-white fw-bold pe-2 title">Profiles Viewed</span>

                                        </div>
                                        <img src="/assets/svg/happy.svg" width="23" height="auto" alt="icon" />
                                    </div>

                                    <div className="text-white fs-6 fw-semibold pb-3 total">{campaignDetailData.profile_viewed?.total}</div>

                                    <div >
                                        {/* <span style={{ fontSize: '14px' }}
                                            className={`${getTextColor(campaignDetailData.profile_viewed?.percentage)} percent`}>
                                            {campaignDetailData.profile_viewed?.percentage}
                                        </span>
                                        <span className="white-text-71 percent"></span> */}
                                    </div>
                                </div>

                                <div className="custom-card br-13">
                                    <div className="d-flex justify-content-between align-items-center pb-3">
                                        <div>
                                            <span className="text-white fw-bold pe-2 title">Follow-Ups Sent</span>

                                        </div>
                                        <img src="/assets/svg/chatbox.svg" width="22" height="auto" alt="icon" />
                                    </div>

                                    <div className="text-white fs-6 fw-semibold pb-3 total">{campaignDetailData.followup_sent?.total}</div>

                                    <div >
                                        {/* <span style={{ fontSize: '14px' }}
                                            className={`${getTextColor(campaignDetailData.followup_sent?.percentage)} percent`}>
                                            {campaignDetailData.followup_sent?.percentage}
                                        </span>
                                        <span className="white-text-71 percent"> from last week</span> */}
                                    </div>
                                </div>
                                <div className="custom-card br-13">
                                    <div className="d-flex justify-content-between align-items-center pb-3">
                                        <div>
                                            <span className="text-white fw-bold pe-2 title">Replied</span>

                                        </div>
                                        <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="22" height="auto" alt="icon" />
                                    </div>

                                    <div className="text-white fs-6 fw-semibold pb-3 total">{campaignDetailData.replied?.total}</div>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row gutter={30} className="pb-3" style={{ marginTop: '-25px' }}>
                    <Col span={24}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            <div
                                className="campaign-detail-sort-by-btn inactive d-flex align-items-center pointer" ref={sortByButtonRef}
                                onClick={() => setSortByOpen(!sortByOpen)}
                            >
                                <img src="/assets/svg/sort-by-icon.svg" className="ps-2" />
                                <span style={{ fontSize: '13.58px', fontWeight: '600' }} className="text-nowrap ps-2 pe-2">Sort By</span>
                            </div>

                            <div onClick={() => setEditCamapignModal(true)} className="campaign-detail-edit-name-btn inactive d-flex align-items-center pointer">
                                <img className="ps-2 pe-2" src="/assets/svg/edit-name-icon.svg" />
                            </div>
                            <div onClick={() => {
                                if (!selectedLeads.length > 0) {
                                    notification.error({
                                        message: "Error",
                                        description: "Please select the leads",
                                    });
                                } else {
                                    setDeleteLeadsModal(true)
                                }
                            }} className="edit-campaign-btn inactive d-flex align-items-center pointer">
                                <img className="ps-2 pe-2" src="/assets/svg/delete-white.svg" />
                            </div>

                            <div className="sort-by-btn inactive d-flex align-items-center mx-2 pointer" onClick={() => setExportLeadModalOpen(true)}>
                                <img className="ps-2 pe-2" src="/assets/svg/add-lead-icon.svg" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Table
                    className="linkedin-table"
                    dataSource={campaignDetailData?.leads_data}
                    columns={leadDetailColumns}
                    pagination={false}
                    onChange={handleTableChange}
                    style={{
                        thead: { display: 'table-header-group', height: `5px` },
                        tbody: { display: 'block', height: '300px', overflow: 'auto' },
                        tr: { display: 'table', height: `$10px` }
                    }}
                />
                {campaignDetailData?.leads_data && <CustomPaginationProspecting
                    currentPage={campaignsDetailCurrentPage}
                    totalItems={campaignDetailData?.pagination.total_records}
                    noOfRecords={25}
                    onChange={handleCampaignDetailTableChange}
                />}
            </Layout>
            <Modal
                closable={false}
                width={null}
                centered={true}
                footer={null}
                title={null}
                open={loading === 1 ? true : false}

            // className="bootstrap custom-modal text-white"
            >
                <div className="pt-4 text-center">
                    <Spin size="large" />
                </div>
            </Modal>
            <Modal
                title={
                    <div>
                        <span className="ps-2">Edit Campaign</span>
                    </div>
                }
                open={editCamapignModal}
                onCancel={() => {
                    setEditCamapignModal(false);
                }}
                footer={null}
                centered
                className="bootstrap custom-modal text-white"
            >

                <div className="form-layout">
                    <div className="form-field">
                        <span style={{ whiteSpace: 'nowrap' }} className="label">Camapign Name</span>
                        <Input
                            className="input"
                            placeholder="Enter new campaign name"
                            value={newCampaignName}
                            onChange={(e) => setNewCampaignName(e.target.value)}
                        />
                    </div>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <Button onClick={() => setEditCamapignModal(false)}>Cancel</Button>
                        <Button type="primary" onClick={handleEditCampaign}>
                            Submit
                        </Button>
                    </div>
                </div>

            </Modal>
            <Modal
                title=""
                open={deleteLeadsModal}
                onCancel={() => { setDeleteLeadsModal(false) }}
                footer={[]}
                centered
                className="bootstrap text-white"

            >
                <div className="d-flex flex-column align-items-center">

                    <div className="pb-4 font-weight-400 font-size-24">
                        Are you sure to delete ?
                    </div>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <Button onClick={() => setDeleteLeadsModal(false)}>Cancel</Button>
                        <Button
                            type="primary"

                            onClick={() => handleDeleteLeads()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
            <Drawer
                title=""
                placement="right"
                width={376}
                closable={false}
                onClose={() => {
                    setCampaignDetailsDrawer(false);
                }}
                open={campaignDetailsDrawer}
                key="left"
                className="bootstrap"
            >
                <div className="text-white">
                    <div className="d-flex align-items-center pb-2 pointer" onClick={() => {
                        setCampaignDetailsDrawer(false)
                    }}>
                        <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
                        <span className="ps-2 white-text-62">Close</span>
                    </div>
                    <div className="campaign-detail-profile-grp" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <Avatar className="mb-2" src={`${!selectedProfile?.profile_pic ? '/assets/images/default-dp-radial-2x.png' : selectedProfile?.profile_pic}`} size={65} shape="circle" />
                        <span className="mb-2" style={{ fontSize: '24px', fontWeight: '500' }}>{selectedProfile?.name}</span>

                        {/* {
                            selectedProfile?.status === 'REPLIED' && selectedProfile?.interest === null && */}
                            <Select
                                placeholder="Select Status"
                                options={leadCategories}
                                suffixIcon={<DropdownArrow />}
                                style={{
                                    border: '1px solid rgba(30, 117, 255, 0.5)',
                                    background: 'rgba(28, 28, 36, 1)',
                                    width: '70%',
                                    borderRadius: '5.19px'
                                }}
                                className="mb-3"
                                onChange={(e) => { updateLeadCategory(e, selectedProfile.lead_id) }}
                                value={selectedProfile?.interest !== undefined ? renderLeadCategory(selectedProfile?.interest) : 'Select'}
                            />
                        {/* } */}
                        <div className="campaign-detail-btn-grp mb-3">
                            <div onClick={() => setSidebarTab("details")} style={{ marginRight: '15px' }} className={`${sidebarTab ? 'campaign-details-btn' : 'campaign-details-btn-inactive'}`}>
                                Contact Details
                            </div>
                            {/* <div onClick={() => setSidebarTab("")} className={`${!sidebarTab ? 'campaign-details-btn' : 'campaign-details-btn-inactive'}`}>
                                Lead Activity
                            </div> */}
                        </div>



                    </div>
                    {
                        sidebarTab ?
                            <div style={{ textAlign: 'start' }}>
                                <>
                                    <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Name</Paragraph>
                                    <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.name}</Paragraph>
                                    <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Email</Paragraph>
                                    <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.email}</Paragraph>
                                    <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Job Title</Paragraph>
                                    <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.job_title}</Paragraph>
                                    <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Company</Paragraph>
                                    <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.company}</Paragraph>
                                    <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Phone</Paragraph>
                                    <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.phonenumber}</Paragraph>
                                    <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Website</Paragraph>
                                    <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.website}</Paragraph>
                                    <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Linkedin</Paragraph>
                                    <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.linkedin_url}</Paragraph>
                                </>
                            </div>
                            : <>
                                {leadActivity?.activity.length > 0 ? leadActivity?.activity.map(activity =>
                                    <div style={{ width: '100%' }}>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                        }}>
                                            <span className="mb-1" style={{ marginLeft: 'auto', fontSize: '11px', fontWeight: '400', color: 'rgba(187, 187, 189, 1)' }}>{moment.utc(activity.timestamp).local().format('ddd, MMMM Do [at] h A')}</span>
                                        </div>
                                        <div className="campaign-detail-info-card mb-2">
                                            <Space style={{ width: '100%' }} direction="vertical">
                                                <Space
                                                    style={{
                                                        width: "100%",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Space size={10}>

                                                        <Avatar src={activity.sequence_order == 1 ? '/assets/svg/happy-blue.svg' : activity.sequence_order == 2 ? '/assets/svg/vector-blue.svg' : activity.sequence_order == 3 ? '/assets/images/thumbs-up-green.png' : '/assets/svg/chatbox-pink.svg'}
                                                            size={20} shape="circle" />

                                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>{activity.sequence_name}</span>
                                                    </Space>
                                                    {[1, 2].includes(activity.sequence_order) && <div style={{ fontSize: '12px', fontWeight: '400' }}>Step {activity.sequence_order}</div>}


                                                </Space>
                                                {[1, 2].includes(activity.sequence_order) && <Space style={{
                                                    width: "100%",
                                                    alignItems: "start",
                                                    marginLeft: '10%'
                                                }} size={10}>

                                                    <Space>
                                                        <Avatar src={activity.lead_profile_pic != null ? activity.lead_profile_pic : '/assets/images/default-dp-radial-2x.png'} size={28} shape="circle" />

                                                        <span style={{ fontSize: '13px', fontWeight: '700', marginTop: '5px' }}>{activity.lead_name}</span>
                                                    </Space>
                                                </Space>}
                                            </Space>
                                        </div>
                                    </div>
                                ) : ""}

                            </>
                    }
                </div>
            </Drawer>
            {sortByOpen &&
                <div className="linkedin-campaign-detail-sort-by-list overlay-container mt-2 ps-3 py-2 ms-1 
                font-size-14 font-weight-400 pointer bootstrap" style={{
                        top: sortByButtonRef.current ?
                            sortByButtonRef.current.getBoundingClientRect().bottom - -16 + window.scrollY : 0,
                        left: sortByButtonRef.current ?
                            sortByButtonRef.current.getBoundingClientRect().left - 5 : 0,
                        cursor: "pointer",
                        width: "8%"
                    }}
                    onClick={() => setSortByOpen(false)}>
                     {<div
                        onClick={() => {
                            handleSortBy("name")
                        }}
                    >Name</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('company')

                        }}
                    >Company</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('job_title')

                        }}
                    >Job Title</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('account')

                        }}
                    >Account</div>}
                    {/* {<div
                        onClick={() => {
                            handleSortBy('status')

                        }}
                    >Status</div>} */}
                    {/* {<div
                        onClick={() => {
                            handleSortBy('interest')

                        }}
                    >Interest</div>} */}
                    {<div
                        onClick={() => {
                            handleSortBy('action_timestamp')

                        }}
                    >Last Action Time</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('profile_viewed')

                        }}
                    >Profile Viewed</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('not_started')

                        }}
                    >Not Started</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('accepted')

                        }}
                    >Accepted</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('responded')

                        }}
                    >Responded</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('request_sent')

                        }}
                    >Request Sent</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('follow_up')

                        }}
                    >Follow Up</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('replied')

                        }}
                    >Replied</div>}
                    {<div
                        onClick={() => {
                            handleSortBy('withdrawn')

                        }}
                    >Withdrawn</div>}
                </div>
            }
            <Modal
                title=" "
                open={exportLeadModalOpen}
                // onOk={closeExportLeadModal}
                onCancel={() => { setExportLeadModalOpen(false) }}
                maskClosable={true}
                footer={[]}
                centered
                className="bootstrap text-white"

            >
                <div className="d-flex flex-column align-items-center">
                    <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
                    <div className="mb-4 font-weight-500" style={{ fontSize: "24px" }}>Export as CSV</div>

                    <Button
                        className="export-btn-purple me-2 pointer font-weight-600 font-size-16"
                        onClick={handleDownloadDetail}
                        type="primary"
                    >
                        Export
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default CampaignsDetails

