import React, {useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Layout,
  Modal,
  notification,
  Result,
  Row,
  Select,
  Spin
} from "antd";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axiosInstance from "../../config/axiosConfig";
import dayjs from 'dayjs';

const {RangePicker} = DatePicker;

const AnalyticsShared = () => {
  const {campaignId} = useParams();
  const pageLoaded = useRef(false);

  const analyticsData = useRef({
    campaign_name: "",
    leads_remaining: "",
    campaign_status: "",
    contacted: 0,
    contacted_percentage: "",
    opened: 0,
    opened_rate: "",
    replied: 0,
    reply_rate: "",
    link_clicks: 0,
    link_clicks_percentage: "",
    opportunities: 0,
    opportunities_percentage: ""
  });
  const analyticsChartData = useRef([]);

  const [loading, setLoading] = useState(1);

  const [analyticsDefaultFilter, setAnalyticsDefaultFilter] = useState("last_7_days");
  const [analyticsCustomFilter, setAnalyticsCustomFilter] = useState([null, null]);
  const [analyticsDateErrorModalShown, setAnalyticsDateErrorModalShown] = useState(false);

  const getAnalyticsData = async (pageLoad = true) => {
    setLoading(1);

    let time_period, custom_start_date, custom_end_date;

    if (analyticsDefaultFilter) {
      time_period = analyticsDefaultFilter;
    } else if (analyticsCustomFilter[0]) {
      [custom_start_date, custom_end_date] = analyticsCustomFilter;

      custom_start_date = dayjs(custom_start_date).format('YYYY-MM-DD');
      custom_end_date = dayjs(custom_end_date).format('YYYY-MM-DD');
    } else if (!analyticsDefaultFilter && !analyticsCustomFilter[0]) {
      setAnalyticsDefaultFilter("last_7_days");
      time_period = "last_7_days";
    }

    try {
      let url = `/share_engage/campaign_analytics/${campaignId}`;

      if (time_period) {
        url += `?time_period=${time_period}`;
      }

      if (custom_start_date) {
        url += `?custom_start_date=${custom_start_date}&custom_end_date=${custom_end_date}`;
      }

			const response = await axiosInstance.get(url);
      analyticsData.current = response.data;
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Analytics data'});
	  }

    getChartData();
  }

  const getChartData = async () => {
    let time_period, custom_start_date, custom_end_date, label;

    const defaultFiltersLabel = {
      last_7_days: "Last 7 Days",
      month_to_date: "Month to Date",
      last_4_weeks: "Last 4 Weeks",
      last_3_months: "Last 4 Months",
    }

    if (analyticsDefaultFilter) {
      time_period = analyticsDefaultFilter;
      label = defaultFiltersLabel[time_period];
    } else if (analyticsCustomFilter[0]) {
      [custom_start_date, custom_end_date] = analyticsCustomFilter;

      custom_start_date = dayjs(custom_start_date).format('YYYY-MM-DD');
      custom_end_date = dayjs(custom_end_date).format('YYYY-MM-DD');

      label = custom_start_date + " - " + custom_end_date;
    } else if (!analyticsDefaultFilter && !analyticsCustomFilter[0]) {
      setAnalyticsDefaultFilter("last_7_days");
      time_period = "last_7_days";

      label = defaultFiltersLabel[time_period];
    }

    try {
      let url = `/share_engage/campaign_chart_analytics?campaign_id=${campaignId}`;

      if (time_period) {
        url += `&time_period=${time_period}`;
      }

      if (custom_start_date) {
        url += `&custom_start_date=${custom_start_date}&custom_end_date=${custom_end_date}`;
      }

			const response = await axiosInstance.get(url);
      analyticsChartData.current = response.data;
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Analytics chart data'});
	  }

    setLoading(0);
  }

  const getTextColor = percentage => {
    if (!percentage) return;
    let className;

    if (percentage.indexOf("-") === 0) {
      className = "text-danger";
    } else {
      className = "text-success";
    }

    return className;
  }

  const zetAnalyticsDefaultFilter = value => {
    setAnalyticsCustomFilter([null, null]);
    setAnalyticsDefaultFilter(value);
  }

  const zetAnalyticsCustomFilter = dates => {
    let date;

    if (!dates) {
      date = [null, null];
    } else {
      date = [dayjs(dates[0].$d).format('YYYY-MM-DD'), dayjs(dates[1].$d).format('YYYY-MM-DD')];

      const dateDiff = dayjs(date[1]).diff(date[0], 'day');
      if (dateDiff > 365) {
        dates = [null, null];
        setAnalyticsDateErrorModalShown(true);
      }
    }

    setAnalyticsDefaultFilter(null);
    setAnalyticsCustomFilter(dates);
  }

  const closeAnalyticsDateErrorModal = () => {
    setAnalyticsDateErrorModalShown(false);
  }

  useEffect(() => {
    if (pageLoaded.current) return;
    
    pageLoaded.current = true;
    getAnalyticsData();
  }, []);

  useEffect(() => {
    if (loading === 1) return;

    getAnalyticsData();
  }, [analyticsDefaultFilter, analyticsCustomFilter]);

  return (
    <Layout style={{minHeight: '100vh', padding: 15}} className="bootstrap">
      {/* Header */}
      <Row gutter={30} className="text-white pb-4">
        <Col span={12} className="d-flex align-items-center">
          <div className="analytics-heading font-weight-500 pe-2">Analytics</div>
          <div className="lead-status-btn inactive d-none align-items-center pointer br-13 me-2">
            <img src="/assets/images/download-gray-2x.png" width="18" height="auto" alt="icon" />
            <span className="text-nowrap white-text-71 ps-2">View Campaign</span>
          </div>
        </Col>
        <Col span={12} className="d-flex justify-content-end align-items-center">
          <Select
            suffixIcon={<img src="/assets/images/arrow-down-gray-2x.png" width="12" height="auto" alt="down" />}
            options={[
              {label: "", value: null},
              {label: "Last 7 days", value: "last_7_days"},
              {label: "Month to Date", value: "month_to_date"},
              {label: "Last 4 Weeks", value: "last_4_weeks"},
              {label: "Last 3 Months", value: "last_3_months"}
            ]}
            value={analyticsDefaultFilter}
            onChange={value => zetAnalyticsDefaultFilter(value)}
            className="w-150px custom-select5 me-2"
          />
          <RangePicker
            className={"position-relative custom-range-picker"
            + (analyticsCustomFilter[0] ? " hide-placeholder" : "")}
            placeholder={null}
            suffixIcon={null}
            value={analyticsCustomFilter}
            onChange={dates => zetAnalyticsCustomFilter(dates)}
          />
        </Col>
      </Row>

      {/* Main */}
      <Row gutter={30} id="engage-account-main" align="stretch">
        {/* Loader */}
        <div className={"loader2 w-100 flex-column justify-content-center align-items-center "
          + (loading === 0 ? "d-none" : "d-flex")}>
          <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
          <div className={"pt-4 text-center" + (loading === 1 ? "" : " d-none")}>
            <Spin size="large" />
          </div>
        </div>

        <Col span={24} className={"gutter-row" + (loading === 1 ? " d-none" : "")}>
          <div>
            <Row className="campaign-detail-btns pb-4">
              <div className="custom-card br-13">
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Contacted</span>
                  <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.contacted}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.contacted_percentage)}>
                    {analyticsData.current.contacted_percentage}
                  </span>
                  <span className="white-text-71"> from last week</span>
                </div>
              </div>

              <div className="custom-card br-13">
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Opened</span>
                  <img src="/assets/images/mail-open-gray-2x.png" width="17" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.opened}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.opened_rate)}>
                    {analyticsData.current.opened_rate}
                  </span>
                  <span className="white-text-71"> open rate</span>
                </div>
              </div>

              <div className="custom-card br-13">
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Replied</span>
                  <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="16" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.replied}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.reply_rate)}>
                    {analyticsData.current.reply_rate}
                  </span>
                  <span className="white-text-71"> reply rate</span>
                </div>
              </div>

              <div className="custom-card br-13">
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Link Clicks</span>
                  <img src="/assets/images/link-gray-2x.png" width="18" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.link_clicks}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.link_clicks_percentage)}>
                    {analyticsData.current.link_clicks_percentage}
                  </span>
                  <span className="white-text-71"> from last week</span>
                </div>
              </div>

              <div className="custom-card br-13">
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Opportunities</span>
                  <img src="/assets/images/jet-gray-2x.png" width="19" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.opportunities}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.opportunities_percentage)}>
                    {analyticsData.current.opportunities_percentage}
                  </span>
                  <span className="white-text-71"> from last week</span>
                </div>
              </div>
            </Row>

            <Row className="campaign-detail-analytics pb-4">
              <div className="w-100 custom-card br-13">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={analyticsChartData.current}
                    margin={{
                      top: 15,
                      right: 15,
                      left: 15,
                      bottom: 15,
                    }}
                  >
                    <XAxis dataKey="name" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip cursor={{fill: "#202029"}} />
                    <Legend
                      verticalAlign="top"
                      height={36} 
                      iconSize={20}
                      wrapperStyle={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        display: 'flex',
                        justifyContent: 'center'
                      }}  // Adjust spacing
                      content={props => {
                        const { payload } = props;
                  
                        return (
                          <div className="d-flex align-items-center" style={{marginRight: '20px'}}>
                            {payload.map((entry, index) => (
                              <div key={`item-${index}`} className="d-flex" style={{marginRight: '50%'}}>
                                <div style={{
                                    width: '10px',
                                    height: '10px', 
                                    backgroundColor: entry.color,
                                    marginRight: '5px',
                                    marginTop:"6%"
                                  }}
                                />
                                <span style={{color: entry.color}}>{entry.value}</span>
                              </div>
                            ))}
                          </div>
                        );
                      }}
                    />
                    
                    <Bar dataKey="outbound" fill="#1E75FF" barSize={12} radius={5} />
                    <Bar dataKey="opens" fill="#3BCD92" barSize={12} radius={5} />
                    <Bar dataKey="replies" fill="#B03BCD" barSize={12} radius={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Row>
          </div>
        </Col>
      </Row>

      {/* Modals */}
      <Modal
        title=" "
        open={analyticsDateErrorModalShown}
        onOk={closeAnalyticsDateErrorModal}
        onCancel={closeAnalyticsDateErrorModal}
        maskClosable={false}
        footer={null}
        centered
        className="bootstrap custom-modal"
      >
        <Result
          status="error"
          title="Error"
          subTitle="Date range must be within 365 days"
          extra={<Button className="black-btn" onClick={closeAnalyticsDateErrorModal}>Close</Button>}
        />
      </Modal>
    </Layout>
  )
}

export default AnalyticsShared;
