import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    notification,
    Progress,
    Row,
    Select,
    Spin,
    Table,
    Tag,
    Switch,
    Space,
    Avatar,
    Popover,
    Modal,
    Checkbox,
    Divider,
    Flex,
    Radio
} from "antd";
import {
    ArrowUpOutlined,
    EditOutlined,
    UnorderedListOutlined,
    InfoCircleOutlined,
    UserOutlined

} from "@ant-design/icons";
import moment from "moment";
import CustomPagination from "../../../component/CustomPagination";
import { useFetcher, useNavigate } from "react-router-dom";
import axiosInstance from "../../../config/axiosConfig";
import { timezoneAbbreviations } from "../../../common/TimeZone";



const Account = () => {
    const navigate = useNavigate();
    const paginationStartEnd = useRef({ start: 0, end: 10 });
    const [loading, setLoading] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [noOfRecords, setNoOfRecords] = useState(10);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        accountName: '',
        cookieValue: '',
        proxyUrl: '',
        proxyName: '',
        proxyPassword: ''
    });
    const [sendingLimitsModal, setSendingLimitsModal] = useState(false)
    const [sendingLimits, setSendingLimits] = useState()
    const [isallLinkedinAccounts, setIsallLinkedinAccounts] = useState(false)
    const authData = useSelector((state) => state.auth);
    const companyData = useSelector(state => state.companyReducer.selectedCompany);
    const companyId = companyData.company_id;
    const userId = authData.userInfo.is_super_admin
        ? companyData.user_id
        : authData.userInfo.userid;
    const selectedCompany = (companyId === 85 || companyId === 86 || companyId === 87 || companyId === 89 );
    const [selectedAccount, setSelectedAccount] = useState(undefined)
    const [reconnectModal, setReconnectModal] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState()
    const [secondFA, setSecondFA] = useState(false)
    const [secondFAModalOpen, setSecondFAModalOpen] = useState(false)
    const [sessionId, setSessionId] = useState("")
    const [liUserName, setLiUserName] = useState("")

    const [isUsingCookie, setIsUsingCookie] = useState(false)
    const [actualLinkedinPassword, setActualLinkedinPassword] = useState("")
    const [withdrawDays, setWithdrawDays] = useState("0")
    const [withdrawAllRequests, setWithdrawAllRequests] = useState(false)

    const columns = [
        {
            title: "",
            key: "actions",
            render: (_, record) => (
                <div className="d-flex align-items-center">
                    <Switch
                        checked={record.active_status}
                        onChange={(checked, e) => updateAccountStatus(record.account_id, checked, e)}
                        disabled={false}
                    />
                </div>
            ),
            width: "5%",
        },
        {
            title: <div style={{fontSize:'9.33px',fontWeight:'600',color: 'rgba(255, 255, 255, 0.73)'}}>NAME</div>,
            key: "name",
            render: (_, record) => (
                <div className="d-flex align-items-center pointer">

                    <Space>
                        {
                            <Avatar src={record.profile_pic == null ? '/assets/images/default-dp-radial-2x.png' : record.profile_pic} size={28} shape="circle" />
                        }
                        <span>{record.account_name}</span>
                        {record.reconnect_status && <img
                            src="/assets/svg/danger-bg-yellow.svg"
                            width="19"
                            height=""
                            alt="danger"
                        />}
                    </Space>
                </div>
            ),
            width:"20%"
        },
        {
            title: <div style={{fontSize:'9.33px',fontWeight:'600',color: 'rgba(255, 255, 255, 0.73)'}}>ACTIVITY</div>,
            key: "activity",
            render: (_, record) => (
                <Row className="d-flex align-items-center">
                    <Col span={4} className="d-flex align-items-center pe-4">
                        <img src="/assets/svg/vector.svg" />
                        <span className="ps-2">{record?.invites_sent}</span>
                    </Col>
                    <Col span={4} className="d-flex align-items-center pe-4">
                        <img
                            src="/assets/svg/thumbs-up.svg"
                            width="18"
                            height=""
                            alt="thumbs-up"
                        />
                        <span className="ps-2">{record?.invites_accepted}</span>
                    </Col>
                    <Col span={4} className="d-flex align-items-center pe-4">
                        <img
                            src="/assets/svg/happy.svg"
                            width="18"
                            height=""
                            alt="thumbs-up"
                        />
                        <span className="ps-2">{record?.profile_viewed}</span>
                    </Col>
                    <Col span={4} className="d-flex align-items-center pe-4">
                        <img
                            src="/assets/svg/chatbox.svg"
                            width="18"
                            height=""
                            alt="thumbs-up"
                        />
                        <span className="ps-2">{record?.followup_sent}</span>
                    </Col>
                    <Col span={4} className="d-flex align-items-center pe-4">
                         <img src="/assets/images/bidirectional-arrows-gray-2x.png"
                            width="18"
                            height=""
                            alt="reply"
                        />
                        <span className="ps-2">{record?.replied}</span>
                    </Col>
                    <Col span={4} className="d-flex align-items-center pe-4">
                    <ArrowUpOutlined />
                        <span className="ps-2">{record?.remaining_limit}</span>
                    </Col>
                </Row>
            ),
            width: "35%",
        },
        {
            title: <div style={{fontSize:'9.33px',fontWeight:'600',color: 'rgba(255, 255, 255, 0.73)'}}>LAST ACTIVITY</div>,
            key: "updated_activity",
            render: (_, record) => {
                const userTimezone = moment.tz.guess();
                const customTimezoneAbbreviation = timezoneAbbreviations[userTimezone] || moment.tz(userTimezone).format('z');
                return (
                    <span>{moment.utc(record.updated_activity).local().format('MM-DD-YYYY [at] h:mm A') + " " + customTimezoneAbbreviation}</span>
                )
            },
        },
        {
            title: <div style={{fontSize:'9.33px',fontWeight:'600',color: 'rgba(255, 255, 255, 0.73)'}}>ACTION</div>,
            width: "5%",
            render: (_, record) => <div className="d-flex align-items-center justify-content-center linkedin-account-list-popup-parent">
                <Popover
                    content={
                        <div className="linkedin-account-list-popup">
                            <div onClick={() => { fetchAccountDetails(record.account_id);  }}>
                                <img
                                    src="/assets/svg/rocket.svg"
                                    width="15"
                                    height="15"
                                    alt="action"
                                /> Sending Limits
                            </div>
                            <div onClick={() => handleRemoveAccount(record.account_id)}>
                                <img
                                    src="/assets/svg/delete-red.svg"
                                    width="15"
                                    height="15"
                                    alt="action"
                                /> Remove Account
                            </div>
                            <div onClick={() => { 
                                                setFormData({
                                                    accountName: record.account_name,
                                                    cookieValue: record.cookie_value,
                                                    proxyUrl: record.proxy_url,
                                                    proxyName: record.proxy_username,
                                                    proxyPassword: record.proxy_password,
                                                    accountLinkedinEmail: record.linkedin_username,
                                                    accountLinkedinPassword: record.linkedin_password
                                                })
                                                setActualLinkedinPassword(record.linkedin_password)
                                                if(record.cookie_value === "" || record.cookie_value === null || record.cookie_value === undefined) {
                                                    setIsUsingCookie(false)
                                                }
                                                else {
                                                    setIsUsingCookie(true)
                                                }
                                handleReconnectAccount(record.account_id)} }>
                                <img
                                    src="/assets/svg/reconnect.svg"
                                    width="15"
                                    height="15"
                                    alt="action"
                                /> Reconnect Account
                            </div>
                            <div onClick={() => window.location.assign('/settings-new/linkedIn')}>
                                <img
                                    src="/assets/svg/settings-icon.svg"
                                    width="15"
                                    height="15"
                                    alt="action"
                                /> More Settings
                            </div>
                        </div>
                    }
                    trigger="click"
                    placement="bottom"
                >
                    {
                        !record.reconnect_status ?
                            <img
                                src="/assets/svg/threedots-action-blue.svg"
                                width="25"
                                height="25"
                                alt="action"
                                style={{ cursor: 'pointer' }}
                            />
                            :
                            <img
                                src="/assets/svg/threedots-action-red.svg"
                                width="25"
                                height="25"
                                alt="action"
                                style={{ cursor: 'pointer' }}
                            />
                    }
                </Popover>
            </div>
        },
    ];

    useEffect(() => {
        fetchAccounts();
    }, []);


    const fetchAccounts = async () => {
        setLoading(1)
        try {
            const response = await axiosInstance.get(
                `/linkedin/fetch_accounts/${userId}?company_id=${companyId}`
            );
            if (response.status === 200) {

                setTableData(response.data);
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the Accounts",
            });
        }

        setLoading(0);
    };

    const postNewAccount = async () => {
        if (!formData.accountName ) {
            notification.error({
                message: "Error",
                description: `Please fill account name `,
            });
            return
        }
        if (selectedCompany && isUsingCookie && !formData.cookieValue ) {
            notification.error({
                message: "Error",
                description: `Please fill cookie value `,
            });
            return
        }
        if (!selectedCompany && !formData.cookieValue ) {
            notification.error({
                message: "Error",
                description: `Please fill cookie value `,
            });
            return
        }
        if(selectedCompany && !isUsingCookie && (!formData.accountLinkedinEmail || !formData.accountLinkedinPassword)){
            notification.error({
                message: "Error",
                description: `Please fill linkedIn details `,
            });
            return
        }
        setLoading(1)
        try {

            const data = {
                company_id: companyId,
                account_name: formData.accountName,
                sending_limits: 5,
                proxy_url: formData.proxyUrl,
                proxy_username: formData.proxyName,
                proxy_password: formData.proxyPassword,               
            }

            if((selectedCompany && isUsingCookie) || !selectedCompany){
                data.cookie_value = formData.cookieValue
            }
            else {
                data.linkedin_username= formData.accountLinkedinEmail
                data.linkedin_password= formData.accountLinkedinPassword
            }
            const response = await axiosInstance.post(`/linkedin/create_account/${userId}`, data);
            if (response.status === 200) {
                setModalOpen(false)
                setFormData({
                    accountName: '',
                    cookieValue: '',
                    proxyUrl: '',
                    proxyName: '',
                    proxyPassword: '',
                    accountLinkedinEmail: '',
                    accountLinkedinPassword: ''
                })
                setIsUsingCookie(false)
                if(response?.data?.two_factor_auth == true) {
                    setSecondFAModalOpen(true)
                    setSessionId(response.data.session_id)
                    setLiUserName(response.data.linkedin_username)
                }
                else {
                fetchAccounts()
                /*
                notification.success({
                    message: "Success",
                    description: "Account created successfully",
                });
                */
                }
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: `${error?.response?.data?.detail}`,
            });
        }
        setLoading(0);
    };

    const post2FA = async () => {
        if (secondFA === "" ) {
            notification.error({
                message: "Error",
                description: `Please fill second factor authentication `,
            });
            return
        }
        setLoading(1)
        try {

            const data = {
                session_id: sessionId,
                code: secondFA,
                company_id: companyId,   
                linkedin_username: liUserName           
            }
            
            const response = await axiosInstance.post(`/linkedin/2fa_authenticate/${userId}`, data);
            if (response.status === 200) {
                notification.success({
                    message: "Success",
                    description: "Account connected successfully",
                });
                
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in creating Account",
            });
        }
        setSecondFAModalOpen(false)
        setSessionId("")
        setSecondFA("")
        fetchAccounts()
        setFormData({
            accountName: '',
            cookieValue: '',
            proxyUrl: '',
            proxyName: '',
            proxyPassword: '',
            accountLinkedinEmail: '',
            accountLinkedinPassword: ''
        })
        setLoading(0);
    };


    const updateAccountStatus = async (account_id, checked, e) => {
        e.stopPropagation();
        postUpdateAccountStatus(account_id, checked);
    };

    const postUpdateAccountStatus = async (id, checked) => {
        setLoading(1)
        try {
            const response = await axiosInstance.put(
                `/linkedin/update_active_status/${id}?active_status=${checked}`
            );
            if (response.status === 200) {
                fetchAccounts();

            }

        } catch (error) {
            setLoading(0);
            notification.error({
                message: "Error",
                description: "Error in updating the Account status",
            });
        }
    };

    const deleteAccount = async (id) => {
        setLoading(1)
        try {
            const response = await axiosInstance.delete(
                `/linkedin/remove_account/${id}`
            );
            if (response.status === 200) {
                fetchAccounts();
                notification.success({
                    message: "Success",
                    description: "Account deleted successfully",
                });
            }

        } catch (error) {
            setLoading(0);
            notification.error({
                message: "Error",
                description: "Error in deleting the Accounts",
            });
        }


    };

    const handlePageChange = ({ page, start, end }) => {
        setCurrentPage(page);
        paginationStartEnd.current = { start, end };
    };

    const handleChange = (e, field) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleRemoveAccount = (id) => {
        deleteAccount(id);
    }

    const handleReconnectAccount = (id) => {
        setReconnectModal(true)
        setSelectedAccount(id)
    }

    const handleSendingLimits = (e) => {
        setSendingLimits(e.target.value)
    }

    const handleIsallLinkedinAccounts = (checked) => {
        setIsallLinkedinAccounts(checked)
    }

    const handlePostSendingLimits = async () => {
        if (!sendingLimits) {
            notification.error({
                message: "Error",
                description: "Please specify sending limit",
            });
            return
        }
        if (sendingLimits < 0) {
            notification.error({
                message: "Error",
                description: "please specify Sending limit zero or greater",
            });
            return
        }
        setLoading(1)
        try {
            const response = await axiosInstance.put(
                `/linkedin/update-sending-limit/${selectedAccount}?company_id=${companyId}&user_id=${userId}&set_limit=${sendingLimits}&set_limit_all=${isallLinkedinAccounts}&withdraw_days=${parseInt(withdrawDays, 10)}&withdraw_all=${withdrawAllRequests}`
            );
            if (response.status === 200) {
                setSendingLimits('')
                setIsallLinkedinAccounts(false)
                notification.success({
                    message: "Success",
                    description: "Sending limit successfully",
                });
                setLoading(0);
            }

        } catch (error) {
            setLoading(0);
            notification.error({
                message: "Error",
                description: `${error?.response?.data?.detail}`,
            });
        }

        setSendingLimitsModal(false)
    }

    const postReconnectAccount = async () => {
        if (selectedCompany && isUsingCookie && !formData.cookieValue ) {
            notification.error({
                message: "Error",
                description: `Please fill cookie value `,
            });
            return
        }
        if (!selectedCompany && !formData.cookieValue ) {
            notification.error({
                message: "Error",
                description: `Please fill cookie value `,
            });
            return
        }
        if(selectedCompany && !isUsingCookie && (!formData.accountLinkedinEmail || !formData.accountLinkedinPassword)){
            notification.error({
                message: "Error",
                description: `Please fill linkedIn details `,
            });
            return
        }
        setLoading(1)
        const payload = {
            "company_id": companyId,
            "account_id": selectedAccount,
            "proxy_url": formData.proxyUrl,
            "proxy_username": formData.proxyName,
            "proxy_password": formData.proxyPassword,
        }
        if((selectedCompany && isUsingCookie) || !selectedCompany){
            payload.cookie_value = formData.cookieValue
        }
        else {
            payload.linkedin_username= formData.accountLinkedinEmail
            if(actualLinkedinPassword === formData.accountLinkedinPassword) {
                payload.password_hash = true
                payload.linkedin_password= ""
            }
            else {
                payload.password_hash = false
                payload.linkedin_password= formData.accountLinkedinPassword
            }
        }
        try {
            const response = await axiosInstance.post(
                `/linkedin/reconnect_account/${userId}`
                , payload);
                setLoading(0);
            if (response.status === 200) {
                setReconnectModal(false)
                setFormData({
                    accountName: '',
                    cookieValue: '',
                    proxyUrl: '',
                    proxyName: '',
                    proxyPassword: '',
                    accountLinkedinEmail: '',
                    accountLinkedinPassword: ''
                })
                setIsUsingCookie(false)
                setActualLinkedinPassword("")
                if(response?.data?.two_factor_auth == true) {
                    setSecondFAModalOpen(true)
                    setSessionId(response.data.session_id)
                    setLiUserName(response.data.linkedin_username)
                }
                else {
                fetchAccounts()
                notification.success({
                    message: "Success",
                    description: "Account reconnected successfully",
                });
                }
            }

        } catch (error) {
            setLoading(0);
            notification.error({
                message: "Error",
                description: `${error?.response?.data?.detail}`,
            });
        }

        setReconnectModal(false)
    }

    const fetchAccountDetails = async (id) => {
        setLoading(1)
        try {
            const response = await axiosInstance.get(
                `/linkedin/account/fetch_details/${userId}?account_id=${id}&company_id=${companyId}`
            );
            if (response.status === 200) {
                setSendingLimitsModal(true);
                setSelectedAccount(id);
                setSendingLimits(response.data.daily_limit)
                setWithdrawDays(response.data.withdraw_days)
                setWithdrawAllRequests(response.data.withdraw_all)
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the Account Details",
            });
        }

        setLoading(0);
    };

    // Radio button controls to switch between Cookie and username/password login
    const setUsingCookie = (value) => {
        console.log("Radio button Value", value)
        setIsUsingCookie(!isUsingCookie)
    }

    const handleWithdrawKey = (e) => {
        if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        }
      };
    
      const handleWithdrawChange = (e) => {
        const value = e.target.value;
        if (!/^[0-9]*$/.test(value)) {
          e.target.value = value.replace(/[^0-9]/g, '');
        }
        setWithdrawDays(value)
      };

    return (

        <Layout
            style={{ minHeight: "100vh", padding: 15 }}
            className="bootstrap linkedin-campaign linkedin-campaign-account"
        >
            <Row gutter={30} className="pb-3">
                <Col span={12} className="gutter-row d-flex align-items-center">
                    <div className="analytics-heading font-weight-500 px-2">
                        Accounts
                    </div>

                </Col>

                <Col
                    span={12}
                    className="gutter-row d-flex align-items-center justify-content-end"
                >
                    <div
                        style={{
                            background: 'rgba(30, 117, 255, 1)',
                            width: '127px',
                            height: '32.6px',
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '32.6px',
                            letterSpacing: '0em',
                            textAlign: 'center',
                            color: 'rgba(255, 255, 255, 1)',
                            borderRadius: '4.18px',
                            cursor: 'pointer'
                        }}
                        className="me-3"
                        onClick={() => setModalOpen(true)}
                    >
                        Add Account
                    </div>
                </Col>
            </Row>
            <Row gutter={30} style={{ height: "calc(100% - 48px)" }}>
                <Col span={24} className="gutter-row">
                    <Card
                        className="pt-2"
                        style={{ height: "100%", border: "none", padding: "0" }}
                    >

                        {/* Content */}
                        <div
                            id="linkedincampaign-content-account"
                            className={loading === 0 ? "" : "d-none"}
                        >
                            <div>
                                <Table
                                    dataSource={tableData
                                        // .slice(paginationStartEnd.current.start, paginationStartEnd.current.end)
                                    }
                                    columns={columns}
                                    pagination={false}
                                    className="custom-table"
                                    headerClassName="custom-header"
                                    rowClassName="custom-row"
                                />
                                {/* <CustomPagination
                                        currentPage={currentPage}
                                        totalItems={tableData.length}
                                        noOfRecords={noOfRecords}
                                        onChange={handlePageChange}
                                    /> */}
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Modal
                title={
                    <div>
                        <span className="ps-2">Connect your Linkedin Account</span>
                    </div>
                }
                open={modalOpen}
                onCancel={() => {
                    setModalOpen(false);
                }}
                footer={null}
                centered
                className="bootstrap custom-modal-analytics text-white"
            >
                {loading === 1 ? <div className="pt-4 text-center">
                    <Spin size="large" />
                </div> :
                    <div className="form-layout">
                        <div className="form-field">
                            <span className="form-label">Display Name</span>
                            <Input
                                className="input"
                                // disabled={isUsingCookie}
                                placeholder="Enter account name"
                                value={formData.accountName}
                                onChange={(e) => handleChange(e, 'accountName')}
                            />
                        </div>
                        {selectedCompany ?
                       <div> <div className="form-field">
                            <span className="form-label">Linkedin Email</span>
                            <Input
                                className="input"
                                disabled={isUsingCookie}
                                placeholder="Enter Linkedin Email"
                                value={formData.accountLinkedinEmail}
                                onChange={(e) => handleChange(e, 'accountLinkedinEmail')}
                            />
                        </div>
                        <div className="form-field">
                            <span className="form-label">Linkedin Password</span>
                            <Input.Password
                                className="input"
                                disabled={isUsingCookie}
                                placeholder="Enter Linkedin Password"
                                value={formData.accountLinkedinPassword}
                                onChange={(e) => handleChange(e, 'accountLinkedinPassword')}
                            />
                        </div>
                        {/* <Radio onChange={setUsingCookie}>Login with Linkedin Cookie instead</Radio> */}
                        <Checkbox style={{ display: "flex" }} onChange={setUsingCookie} checked={isUsingCookie}>Login with Linkedin Session Cookie instead</Checkbox>
                        {/* Only display if Cookie is being used */}
                        {isUsingCookie &&
                            <div>
                                <br />
                                <div className="form-field">
                                    <span className="form-label">Cookie Value</span>
                                    <Input
                                        className="input"
                                        placeholder="Enter cookie"
                                        value={formData.cookieValue}
                                        onChange={(e) => handleChange(e, 'cookieValue')}
                                    />
                                </div>
                            </div>
                        }
                        </div>
                         : <div className="form-field">
                        <span className="form-label">Cookie Value</span>
                        <Input
                            className="input"
                            placeholder="Enter cookie"
                            value={formData.cookieValue}
                            onChange={(e) => handleChange(e, 'cookieValue')}
                        />
                    </div>
                    } 
                        {selectedCompany && 
                        <><Divider />
                        <div className="form-field">
                            <span className="form-label">Proxy Url</span>
                            <Input
                                className="input"
                                placeholder="Enter proxy url"
                                value={formData.proxyUrl}
                                onChange={(e) => handleChange(e, 'proxyUrl')}
                            />
                        </div>
                        <div className="form-field">
                            <span className="form-label">Proxy User Name</span>
                            <Input
                                className="input"
                                placeholder="Enter proxy user name"
                                value={formData.proxyName}
                                onChange={(e) => handleChange(e, 'proxyName')}
                            />
                        </div>
                        <div className="form-field">
                            <span className="form-label">Proxy Password</span>
                            <Input
                                className="input"
                                placeholder="Enter proxy password"
                                value={formData.proxyPassword}
                                onChange={(e) => handleChange(e, 'proxyPassword')}
                            />
                        </div> </>}
                        <div className="form-field">
                            <Button type="primary" onClick={postNewAccount}>
                                Submit
                            </Button>
                        </div>
                    </div>}

            </Modal>
            <Modal
                title={
                    <div>
                        <span className="ps-2">Connect your Linkedin Account</span>
                    </div>
                }
                open={secondFAModalOpen}
                onCancel={() => {
                    setSecondFAModalOpen(false);
                }}
                footer={null}
                centered
                className="bootstrap custom-modal-analytics text-white"
            >
                {loading === 1 ? <div className="pt-4 text-center">
                    <Spin size="large" />
                </div> :
                    <div className="form-layout">
                        <div className="form-field">
                            <span className="form-label">Two Factor Authentication</span>
                            <Input
                                className="input"
                                placeholder="Enter 2FA code"
                                onChange={(e) => setSecondFA(e.target.value)}
                            />
                        </div>
                        <div className="form-field">
                            <Button type="primary" onClick={post2FA}>
                                Submit
                            </Button>
                        </div>
                    </div>}

            </Modal>
            <Modal
                closable={false}
                width={null}
                centered={true}
                footer={null}
                title={null}
                open={loading === 1 ? true : false}

            // className="bootstrap custom-modal text-white"
            >
                <div className="pt-4 text-center">
                    <Spin size="large" />
                </div>
            </Modal>
            <Modal
                title={
                    <div>
                        <img src="\assets\svg\rocket.svg" style={{ width: '20px', height: '20px' }}></img>
                        <span className="ps-2">Sending Limits</span>
                    </div>
                }
                open={sendingLimitsModal}
                onCancel={() => {
                    setSendingLimitsModal(false);
                }}
                footer={null}
                centered
                className="bootstrap custom-modal text-white"
            >

                <div className="mt-3">
                    <span>
                        Daily number of invites sent
                    </span>
                    <Input
                        className="my-2"
                        style={{ border: '2px solid rgba(30, 117, 255, 0.5)' }}
                        value={sendingLimits}
                        onChange={(e) => handleSendingLimits(e)}
                    />
                    <div className="d-flex flex-row mt-2">
                        <Checkbox
                            checked={isallLinkedinAccounts}

                            onChange={(e) => handleIsallLinkedinAccounts(e.target.checked)}
                        />
                        <span className="ps-2" style={{ marginTop: '-0.2rem' }}>
                            Apply to all connected LinkedIn accounts
                        </span>
                    </div>
                    <div className="d-flex my-2">
                        Withdraw unaccepted requests after
                        <Input className="mx-2" 
                        style={{ border: '2px solid rgba(30, 117, 255, 0.5)', width:"69px", marginTop:"-1%" }}
                        onKeyPress={handleWithdrawKey}
                        onChange={handleWithdrawChange}
                        value={withdrawDays}
                        />
                        days.
                    </div>
                    <div className="d-flex">
                        <Checkbox 
                            checked={withdrawAllRequests}
                            onChange={(e) => setWithdrawAllRequests(e.target.checked)} style={{marginTop: "0.5%"}}/> 
                        <div className="ms-2">Withdraw all requests</div>
                    </div>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <Button
                            onClick={() => setSendingLimitsModal(false)}
                        >Cancel</Button>
                        <Button
                            type="primary"
                            className="enrich-btn"
                            onClick={handlePostSendingLimits}
                            disabled={withdrawDays === ""}
                        >
                            Save
                        </Button>
                    </div>
                </div>

            </Modal>
            <Modal
                title={
                    <div>
                        <span className="ps-2">Reconnect Account</span>
                    </div>
                }
                open={reconnectModal}
                onCancel={() => {
                    setReconnectModal(false);
                    setFormData({
                        accountName: '',
                        cookieValue: '',
                        proxyUrl: '',
                        proxyName: '',
                        proxyPassword: '',
                    })
                }}
                footer={null}
                centered
                className="bootstrap custom-modal-analytics text-white"
            >
                {loading === 1 ? <div className="pt-4 text-center">
                    <Spin size="large" />
                </div> :
                    <div className="form-layout">
                        {selectedCompany ? <div>
                        <div className="form-field">
                            <span className="form-label">Linkedin Email</span>
                            <Input
                                className="input"
                                disabled={isUsingCookie}
                                placeholder="Enter Linkedin Email"
                                value={formData.accountLinkedinEmail}
                                onChange={(e) => handleChange(e, 'accountLinkedinEmail')}
                            />
                        </div>
                        <div className="form-field">
                            <span className="form-label">Linkedin Password</span>
                            <Input.Password
                                className="input"
                                disabled={isUsingCookie}
                                placeholder="Enter Linkedin Password"
                                value={formData.accountLinkedinPassword}
                                onChange={(e) => handleChange(e, 'accountLinkedinPassword')}
                            />
                        </div>
                        <Checkbox style={{ display: "flex" }} onChange={setUsingCookie} checked={isUsingCookie}>Login with Linkedin Session Cookie instead</Checkbox>
                        {isUsingCookie &&
                            <div className="form-field">
                                <span className="form-label">Cookie Value</span>
                                <Input
                                    className="input"
                                    placeholder="Enter cookie"
                                    value={formData.cookieValue}
                                    onChange={(e) => handleChange(e, 'cookieValue')}
                                />
                            </div>} </div>  : <div className="form-field">
                                <span className="form-label">Cookie Value</span>
                                <Input
                                    className="input"
                                    placeholder="Enter cookie"
                                    value={formData.cookieValue}
                                    onChange={(e) => handleChange(e, 'cookieValue')}
                                />
                            </div>}
                       {selectedCompany &&  <> <div className="form-field">
                            <span className="form-label">Proxy Url</span>
                            <Input
                                className="input"
                                placeholder="Enter proxy url"
                                value={formData.proxyUrl}
                                onChange={(e) => handleChange(e, 'proxyUrl')}
                            />
                        </div>
                        <div className="form-field">
                            <span className="form-label">Proxy name</span>
                            <Input
                                className="input"
                                placeholder="Enter user name"
                                value={formData.proxyName}
                                onChange={(e) => handleChange(e, 'proxyName')}
                            />
                        </div>
                        <div className="form-field">
                            <span className="form-label">Proxy password</span>
                            <Input
                                className="input"
                                placeholder="Enter password"
                                value={formData.proxyPassword}
                                onChange={(e) => handleChange(e, 'proxyPassword')}
                            />
                        </div> </>}
                        <div className="form-field">
                            <Button type="primary" onClick={postReconnectAccount}>
                                Submit
                            </Button>
                        </div>
                    </div>}

            </Modal>
        </Layout>


    );
};

export default Account;
