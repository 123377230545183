import React from 'react';

const CustomPagination = ({totalItems, noOfRecords, currentPage, onChange}) => {
  if (!noOfRecords) {
    noOfRecords = 10;
  }
  const totalPages = Math.ceil(totalItems/noOfRecords);

  const startItem = ((currentPage - 1) * noOfRecords) + 1;
  let endItem = (startItem - 1) + noOfRecords;
  if (endItem > totalItems) {
    endItem = totalItems;
  }

  const handlePrev = () => {
    const page = currentPage - 1;
    const start = startItem - (noOfRecords + 1);
    const end = start + noOfRecords;

    onChange({page, start, end});
  };

  const handleNext = () => {
    const page = currentPage + 1;
    const start = (startItem - 1) + noOfRecords;
    const end = start + noOfRecords;

    onChange({page, start, end});
  };

  return (
    <div className="d-flex align-items-center mt-4 pt-4 position-relative">
      <span
        {...(currentPage !== 1 && {onClick: handlePrev})}
        className={"inverted-pagination-arrow position-absolute"
          + (currentPage === 1 ? " text-secondary disabled-upload" : " pointer pagination-arrow")}
      >➞</span>

      {/*
      <Pagination
        current={currentPage}
        total={totalItems}
        pageSize={noOfRecords}
        onChange={onChange}
        showSizeChanger={false}
        className="custom-pagination"
      />
      */}

      <span
        {...(currentPage !== totalPages && {onClick: handleNext})}
        className={"right-arrow pe-3 ps-4" + (currentPage === totalPages ? " text-secondary disabled-upload" : " pointer pagination-arrow")}
      >➞</span>

      <span className="text-secondary small">{startItem} - {endItem} of {totalItems} records</span>
    </div>
  );
};

export default CustomPagination;
