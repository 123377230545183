import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Upload,
  Radio,
  Progress,
  Checkbox,
  Avatar,
  Dropdown,
  Drawer,
  Typography
} from "antd";
import {
  SearchOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import axiosInstance from "../../config/axiosConfig";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../config/AppConfig";
import { DropdownArrow, FlagIcon } from "../../assets/svg/Icons";
import CustomPaginationProspecting from "../../component/CustomPaginationProspecting";
import TimeoutErrorModal from "../../common/TimeoutErrorModal";
import NewProspect from "../personas/new-prospect";
import NewLeads from "../personas/new-leads";
import { fundingSelectOptions, headCountOptions } from '../../constants/MockData';
import { show } from "dom7";
import { filter } from "lodash";

const { Dragger } = Upload;
const momentTimezone = require('moment-timezone');
const { Paragraph } = Typography;

const csvPeopleOptions = [
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/name-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">First Name</span>
        </div>
      </div>
    ),
    value: "First Name",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/name-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Last Name</span>
        </div>
      </div>
    ),
    value: "Last Name",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/job-title-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Role</span>
        </div>
      </div>
    ),
    value: "Role",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/name-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Company</span>
        </div>
      </div>
    ),
    value: "Company",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/linkedin-url-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Website</span>
        </div>
      </div>
    ),
    value: "Website",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/location-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Location</span>
        </div>
      </div>
    ),
    value: "Location",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/email-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Email</span>
        </div>
      </div>
    ),
    value: "Email",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/linkedin-url-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Linkedin URL</span>
        </div>
      </div>
    ),
    value: "Linkedin URL",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/do-not-include-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Do Not Include</span>
        </div>
      </div>
    ),
    value: "Do Not Include",
  },
];

const csvCompanyOptions = [
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/name-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Company</span>
        </div>
      </div>
    ),
    value: "Company",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/linkedin-url-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Website</span>
        </div>
      </div>
    ),
    value: "Website",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/location-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Location</span>
        </div>
      </div>
    ),
    value: "Location",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/linkedin-url-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Lead Count</span>
        </div>
      </div>
    ),
    value: "Lead Count",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/linkedin-url-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Head Count</span>
        </div>
      </div>
    ),
    value: "Head Count",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/job-title-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Industry</span>
        </div>
      </div>
    ),
    value: "Industry",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/linkedin-url-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Linkedin URL</span>
        </div>
      </div>
    ),
    value: "Linkedin URL",
  },
  {
    label: (
      <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/csv-mapping-icons/do-not-include-2x.png"
            width="15"
            height="auto"
            alt="icon"
          />
          <span className="ps-2">Do Not Include</span>
        </div>
      </div>
    ),
    value: "Do Not Include",
  },
];

const Prospecting = () => {
  const navigate = useNavigate();

  const authData = useSelector((state) => state.auth);
  const companyData = useSelector(
    (state) => state.companyReducer.selectedCompany
  );

  const userId = authData.userInfo.is_super_admin
    ? companyData.user_id
    : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const editAccess = authData.userInfo.isViewer === false ? true : false;
  const paramsId = new URLSearchParams(useLocation().search).get('id')

  const [form] = Form.useForm();

  const pageLoaded = useRef(false);
  const tableData = useRef([]);
  const paginationStartEnd = useRef({ start: 0, end: 10 });
  const selectedProspect = useRef(null);
  const selectedProspectName = useRef("");
  const prospectedAndAvailableLeads = useRef({});
  const isCompany = useRef(false);
  const isCompanyImport = useRef(false);
  const importedCsvFile = useRef(null);
  const csvMappings = useRef({ file_id: 0, file_name: "", columns: [] });
  const settingsData = useRef({});

  // prospects_table, form, leads_table, company_table
  const [currentSection, setCurrentSection] = useState("prospects_table");
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsCurrentPage, setLeadsCurrentPage] = useState(1);
  const [leadsTraversedPage, setLeadsTraversedPage] = useState(1);
  const [leadsDetailTraversedPage, setLeadsDetailTraversedPage] = useState(1);
  const [leadsDetailCurrentPage, setLeadsDetailCurrentPage] = useState(1);
  const [allLeadsData, setAllLeadsData] = useState([]);
  const [leadTableData, setLeadTableData] = useState([]);
  const [leadsPagination, setLeadsPagination] = useState({});
  const [selectedPersonaId, setSelectedPersonaId] = useState("");
  const [clickedPersona, setClickedPersona] = useState("");
  const [newPersonaName, setNewPersonaName] = useState("");
  const [renamePersonaName, setRenamePersonaName] = useState("");
  const [inputEnrichCount, setInputEnrichCount] = useState(0);
  const [exportLeadModalOpen, setExportLeadModalOpen] = useState(false);
  const [downloadType, setDownloadType] = useState("people");
  const [allEnrich, setAllEnrich] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [leadDetailData, setLeadDetailData] = useState([]);
  const [enrichModal, setEnrichModal] = useState(false);
  const [tableName, setTableName] = useState("prospects");
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [selectedEnrich, setSelectedEnrich] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [hiringRolesData, setHiringRolesData] = useState([]);
  const [technologiesData, setTechnologiesData] = useState([]);
  const [jobTitleData, setJobTitleData] = useState([]);
  const [excludeFields, setExcludeFields] = useState({});
  const [fieldType, setFieldType] = useState({ headCount: "select", funding: "select" });
  const [customError, setCustomError] = useState(false);
  const [leadPage, setLeadPage] = useState(1);
  // const [allSelectEnrich, setAllSelectEnrich] = useState(false);
  const [viewLeadDetails, setViewLeadDetails] = useState(false);
  const [companiesCurrentPage, setCompaniesCurrentPage] = useState(1);
  const [loading, setLoading] = useState(-1);
  const [sortBy, setSortBy] = useState(""); // company, role, location
  const [importCsvScreen, setImportCsvScreen] = useState(0); // 0 => hidden, 1 (screen), 2 (screen), 3 (screen)
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [pushCampaignModal, setPushCampaignModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [campaignList, setCampaignList] = useState([]);
  const [linkedIncampaignList, setLinkedInCampaignList] = useState([]);
  const [loadingModal, setLoadingModal] = useState(0);
  const [sortByOpen, setSortByOpen] = useState(false);
  const [enrichClick, setEnrichClick] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedLabel, setSelectedLabel] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const allFiltersButtonRef = useRef(null);
  const sortByButtonRef = useRef(null);
  const expandButtonRef = useRef(null);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [selectedLinkedInCampaignId, setSelectedLinkedInCampaignId] = useState("");
  const [progressStep, setProgressStep] = useState(1);
  const [progressPercent, setProgressPercent] = useState(5);
  const [enrichPercent, setEnrichPercent] = useState(0);
  const [errorFields, setErrorFields] = useState([]);
  const [selectedCsvMappingValues, setSelectedCsvMappingValues] = useState({});
  const [csvMappingJobTitle, setcsvMappingJobTitle] = useState([]);
  const [importDataIsCompany, setImportDataIsCompany] = useState(false);
  const [importDataManualRows, setImportDataManualRows] = useState(Array(1).fill({ first_name: '', last_name: '', linkedin_email: '' }));
  const [importDataManualHasErrors, setImportDataManualHasErrors] = useState(false);
  const [importDataManualUrlRows, setImportDataManualUrlRows] = useState(Array(1).fill(''));
  const [isImportDataManual, setIsImportDataManual] = useState(false);
  const containerRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [progressEnrich, setProgressEnrich] = useState(false);
  const [importInside, setImportInside] = useState(false);
  const [notAccLabelIds, setNotAccLabelIds] = useState([]);
  const [notContactLabelIds, setNotContactLabelIds] = useState([]);
  const [hovered, setHovered] = useState("");
  const [detailsDrawer, setDetailsDrawer] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(false);

  const gotoLeadDetails = async (personaId, page, search = "", sort = "", previousPage = false) => {
    setLoading(1)
    let url = `/v2_prospect/get_leads/${personaId}?user_id=${userId}&company_id=${companyId}&is_person=${!isCompany.current}&include_duplicates=false&limit=25&page=${page}&is_previous_page=${previousPage}`
    if (search !== "" && search !== null && search !== undefined) {
      url += `&search_query=${search}`
    }
    if (sort !== "" && sort !== null && sort !== undefined) {
      url += `&sort_by=${sort}`
    }
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        setLeadDetailData(response.data);
        let filterData = response.data?.persona_data
        const data = {
          job_title: filterData?.job_title || [],
          organization: filterData?.organizations_list || [],
          management_level: filterData?.management_level || null,
          departments_functions: filterData?.department || null,
          industry: filterData?.industry || null,
          keywords: filterData?.keyword || null,
          location: filterData?.geography || null,
          headCount_select: filterData?.headcount,
          revenue_max: filterData?.revenue_range?.max || null,
          revenue_min: filterData?.revenue_range?.min || null,
          min_years: filterData?.role_years?.min < 12 ? 0 : Math.floor(filterData?.role_years?.min / 12) || null,
          max_years: filterData?.role_years?.max < 12 ? 0 : Math.floor(filterData?.role_years?.max / 12) || null,
          min_months: filterData?.role_years?.min > 12 ? filterData?.role_years?.min % 12 : filterData?.role_years?.min || null,
          max_months: filterData?.role_years?.max > 12 ? filterData?.role_years?.max % 12 : filterData?.role_years?.max || null,
          funding_select: filterData?.company_funding || null,
          funding_max: filterData?.fund_custom_range?.max || null,
          // funding_max: filterData?.amount_raised?.max || null, This field maps to funding amount in Apollo
          funding_min: filterData?.fund_custom_range?.min || null,
          funding_date_max: filterData?.amount_raised_date?.max !== null && filterData?.amount_raised_date?.max !== undefined
              ? moment(filterData?.amount_raised_date?.max?.split('T')[0], "YYYY-MM-DD") : null,
          funding_date_min: filterData?.amount_raised_date?.min !== null && filterData?.amount_raised_date?.min !== undefined
              ? moment(filterData?.amount_raised_date?.min?.split('T')[0], "YYYY-MM-DD") : null,
          date_founded_after: filterData?.date_founded?.max !== null && filterData?.date_founded?.max !== undefined 
              ? moment(filterData?.date_founded?.max?.split('T')[0], "YYYY-MM-DD") : null,
          date_founded_before: filterData?.date_founded?.min !== null &&  filterData?.date_founded?.min !== undefined 
              ? moment(filterData?.date_founded?.min?.split('T')[0], "YYYY-MM-DD") : null,
          technologies: filterData?.technologies_used || null,
          hiring_roles: filterData?.hiring_roles || null,
          job_title_exclude: filterData?.exclude_job_title || null,
          industry_exclude: filterData?.exclude_industry.map(str => parseInt(str, 10)) || null,
          keyword_exclude: filterData?.exclude_keyword || null,
          location_exclude: filterData?.exclude_geography || null,
          organization_exclude: filterData?.exclude_organizations_list || null,
          organization_past: filterData?.person_past_organizations_list || null,
        }
        const dataWithoutNullValues = Object.fromEntries(
          Object.entries(data)?.filter(([key, value]) => value !== null)
        );
        setSelectedValues(dataWithoutNullValues)
        setSelectedLabel(dataWithoutNullValues)
        if (dataWithoutNullValues?.headCount_select !== null && dataWithoutNullValues?.headCount_select !== undefined) {
          let selectedHeadCount = headCountOptions.filter(i => i.value === dataWithoutNullValues?.headCount_select)
          if (selectedHeadCount.length <= 0) {
            let head_min = dataWithoutNullValues?.headCount_select[0].split(',')[0]
            let head_max = dataWithoutNullValues?.headCount_select[0].split(',')[1]
            setSelectedLabel(prevValues => ({
              ...prevValues,
              'headCount_min': head_min,
              'headCount_max': head_max
            }));
            setSelectedValues(prevValues => ({
              ...prevValues,
              'headCount_min': head_min,
              'headCount_max': head_max
            }));
          }
          delete dataWithoutNullValues?.headCount_select
          delete selectedLabel?.headCount_select
          delete selectedValues?.headCount_select
        }
        if (dataWithoutNullValues?.industry !== null && dataWithoutNullValues?.industry !== undefined) {
          let industryLabel = dataWithoutNullValues.industry.map(selectedValue => {
            let matchingItem = industryData.find(item => item.value == selectedValue);
            return matchingItem ? matchingItem.label : null;
          });
          delete selectedLabel?.industry
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'industry': industryLabel
          }));
        }
        if (dataWithoutNullValues?.industry_exclude !== null && dataWithoutNullValues?.industry_exclude !== undefined) {
          let industryLabel = dataWithoutNullValues.industry_exclude.map(selectedValue => {
            let matchingItem = industryData.find(item => item.value == selectedValue);
            return matchingItem ? matchingItem.label : null;
          });
          delete selectedLabel?.industry_exclude
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'industry_exclude': industryLabel
          }));
        }

        if (dataWithoutNullValues?.date_founded_after !== null && dataWithoutNullValues?.date_founded_after !== undefined) {
          let dateFoundedAfterLabel = filterData?.date_founded.max.split("-")[0]
          delete selectedLabel?.date_founded_after
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'date_founded_after': dateFoundedAfterLabel
          }));
        }
        if (dataWithoutNullValues?.date_founded_before !== null && dataWithoutNullValues?.date_founded_before !== undefined) {
          let dateFoundedBeforeLabel = filterData?.date_founded.min.split("-")[0]
          delete selectedLabel?.date_founded_before
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'date_founded_before': dateFoundedBeforeLabel
          }));
        }
        if (dataWithoutNullValues?.funding_date_min !== null && dataWithoutNullValues?.funding_date_min !== undefined) {
          let fundingDateMinLabel = filterData?.amount_raised_date.min.split("T")[0]
          delete selectedLabel?.funding_date_min
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'funding_date_min': fundingDateMinLabel
          }));
        }
        if (dataWithoutNullValues?.funding_date_max !== null && dataWithoutNullValues?.funding_date_max !== undefined) {
          let fundingDateMaxLabel = filterData?.amount_raised_date.max.split("T")[0]
          delete selectedLabel?.funding_date_max
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'funding_date_max': fundingDateMaxLabel
          }))
        }
        if (dataWithoutNullValues?.organization !== null && dataWithoutNullValues?.organization !== undefined) {
          let organizationLabel = dataWithoutNullValues?.organization?.map(i => i.name)
          delete selectedLabel?.organization
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'organization': organizationLabel
          }));
        }
        if (dataWithoutNullValues?.organization_exclude !== null && dataWithoutNullValues?.organization_exclude !== undefined) {
          let organizationLabel = dataWithoutNullValues?.organization_exclude?.map(i => i.name)
          delete selectedLabel?.organization_exclude
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'organization_exclude': organizationLabel
          }));
        }
        if (dataWithoutNullValues?.organization_past !== null && dataWithoutNullValues?.organization_past !== undefined) {
          let organizationLabel = dataWithoutNullValues?.organization_past?.map(i => i.name)
          delete selectedLabel?.organization_past
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'organization_past': organizationLabel
          }));
        }
        if (dataWithoutNullValues?.funding_select !== null && dataWithoutNullValues?.funding_select !== undefined) {
          let fundingLabel = dataWithoutNullValues.funding_select.map(selectedValue => {
            let matchingItem = fundingSelectOptions.find(item => item.value === selectedValue);
            return matchingItem ? matchingItem.label : null;
          });
          delete selectedLabel?.funding_select
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'funding_select': fundingLabel
          }));
        }
        if (dataWithoutNullValues?.hiring_roles !== null && dataWithoutNullValues?.hiring_roles !== undefined) {
          let hiringLabel = dataWithoutNullValues?.hiring_roles.map(selectedValue => {
            let matchingItem = hiringRolesData.find(item => item.value === selectedValue);
            return matchingItem ? matchingItem.label : null;
          });
          delete selectedLabel?.hiring_roles
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'hiring_roles': hiringLabel
          }));
        }
        if (dataWithoutNullValues?.technologies !== null && dataWithoutNullValues?.technologies !== undefined) {
          let technologiesLabel = dataWithoutNullValues?.technologies.map(selectedValue => {
            let matchingItem = technologiesData.find(item => item.value === selectedValue);
            return matchingItem ? matchingItem.label : null;
          });
          delete selectedLabel?.technologies
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'technologies': technologiesLabel
          }));
        }
        let excludeFields = {
          excludeJob: dataWithoutNullValues?.job_title_exclude.length > 0 ? true : false,
          excludeKeywords: dataWithoutNullValues?.keyword_exclude.length > 0 ? true : false,
          excludeLocation: dataWithoutNullValues?.location_exclude.length > 0 ? true : false,
          excludeIndustry: dataWithoutNullValues?.industry_exclude.length > 0 ? true : false,
          excludeOrganization: dataWithoutNullValues?.organization_exclude.length > 0 ? true : false,
          pastOrganization: dataWithoutNullValues?.organization_past.length > 0 ? true : false
        }
        setExcludeFields(excludeFields)
        if (!isCompany.current) {
          setTableName("people");
        }
        else {
          setTableName("company");
        }
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in fetching the details of the persona",
      });
    }
    setLoading(0)
  };

  const columns = [
    {
      title: "NAME",
      key: "name",
      render: (_, record) => (
        <div className="d-flex align-items-center justify-content-between">
          {/* <span className={"inbox-indicator position-absolute" + (record.status === true ? " unread" : "")} /> */}
          <div className="d-flex"><span
            className="pointer ps-2"
            onClick={() => {
              setClickedPersona(record);
              setLeadsDetailCurrentPage(1);
              gotoLeadDetails(record.persona_id, 1, null, null);
            }}
          >
            <>{record.persona_name}</>
          </span>
          {record.contains_duplicate && (
            <span className="ps-2">
              <Tooltip
                title={
                  <div>
                    <p style={{
                      backgroundColor: "#727672", margin: "0%",
                      border: "1px solid rgb(73, 78, 73) !important",
                      borderRadius: "5px", paddingLeft: "1%"
                    }} >
                      This persona contains duplicate leads that have already
                      been prospected for some Personas.
                    </p>

                  </div>
                }
              >
                <div>
                  <ExclamationCircleOutlined />
                </div>
              </Tooltip>
            </span>
          )}</div>
            <div>  { record.is_linkedin_campaign && <img className="me-1"
              src="/assets/svg/linkedin.svg"
              width="auto"
              height="12"
              alt="profile"
            /> }
            { record.is_email_campaign &&<img 
              src="/assets/svg/inbox-icon.svg"
              width="auto"
              height="12"
              alt="profile"
            /> }
            
            </div>
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center">
          <span className="pe-2">LEAD COUNT</span>
        </div>
      ),
      key: "lead_count",
      render: (_, record) => (
        <div className="d-flex flex-row pointer">
          <Progress
            type="circle"
            percent={(record.enriched_count / record.total_entries) * 100}
            width={20} // Adjust the width of the circle
            format={() =>
              <p className="lead-count-tool-tip">{record.enriched_count} Enriched Lead{
                record.enriched_count > 1 ? "s" : ""
              }</p>
            }
          />
          <div className="text-nowrap ms-3 align-self-center pt-1">
            {record.enriched_count}/{record.total_entries}
          </div>
        </div>
      ),
    },
    {
      title: "TYPE",
      key: "type",
      dataIndex: "type",
      render: (text, record) => (
        <div className="justify-content-center d-flex w-100 text-uppercase pointer">
          <span
            className="p-1 w-100 text-capitalize mx-5"
            style={{
              backgroundColor:
                text === "Prospected" ? "#8792FF52" : "#FF8A1D2B",
              color: text === "Prospected" ? "#ADB4FF" : "#FF8A1D",
              border:
                text === "Prospected"
                  ? "1px solid #8792FF"
                  : "1px solid #FF8A1DBA",
              textAlign: "center",
              borderRadius: "7px",
              fontWeight: "600",
              fontSize: "13px",
            }}
          >
            {text}
          </span>
        </div>
      ),
    },
    {
      title: "UPDATED",
      key: "date",
      render: (_, record) => (
        <span className="pointer">{moment.utc(record.updated).local().format('MM-DD-YYYY [at] h:mm A z')} {momentTimezone.tz(momentTimezone.tz.guess()).zoneAbbr()}</span>
      ),
    },
    {
      title: "CREATED",
      key: "date",
      render: (_, record) => (
        <span className="pointer">{moment.utc(record.created).local().format('MM-DD-YYYY [at] h:mm A z')} {momentTimezone.tz(momentTimezone.tz.guess()).zoneAbbr()} </span>
        // <span>{(record.created).split("T")[0]} {" "} at {" "} {(record.created).split("T")[1].split('.')[0]} </span>
      ),
    },
  ];

  const leadDetailColumns = [
    {
      title: <Checkbox className="d-flex pt-2" checked={allEnrich} onChange={(e) => {
        if (e.target.checked) {
          setSelectedEnrich(leadDetailData?.data?.map(i => i.id))
          setAllEnrich(true)
        }
        else {
          setSelectedEnrich([])
          setAllEnrich(false)
        }
      }} /* disabled={leadDetailData?.data?.filter(i => i.is_enriched !== true).length <= 0} */ />,
      dataIndex: "select",
      key: "select",
      render: (text, record) => (
        <Checkbox
          className="d-flex pt-2"
           checked={selectedEnrich.filter(i => i === record.id).length > 0 }
          // disabled={record.is_enriched}
          onChange={(e) => {
            let localEnrich = []
            if (e.target.checked === true) {
              localEnrich = [...selectedEnrich, record.id];
              setSelectedEnrich([...selectedEnrich, record.id]);
            } else {
              localEnrich = selectedEnrich.filter((i) => i !== record.id);
              setSelectedEnrich(selectedEnrich.filter((i) => i !== record.id));
            }
            if (localEnrich?.length === leadDetailData?.data?.length) {
              setAllEnrich(true)
            }
            else {
              setAllEnrich(false)
            }
          }}
        />
      ),
    },
    {
      title: "NAME",
      key: "name",
      dataIndex: "name",
      render: (text, record) => (
        <Space className={record.is_enriched ? "pointer" : ""}
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <Space onClick={() => {
            if (record.is_enriched) {
              setSelectedProfile(record)
              setDetailsDrawer(true)
            }
          }}>

            <Avatar src={record.profile_pic !== null ? record.profile_pic : "/assets/images/default-dp-radial-2x.png"} size={37} shape="circle" />

            <span>{text}</span>
          </Space>
          {record.linkedin_url !== null && <a href={record.linkedin_url} target="_blank">
            <img
              src="/assets/svg/linkedin.svg"
              width="auto"
              height="18"
              alt="profile"
            />
          </a>}
        </Space>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center">
          <span>COMPANY</span>
          {/* <div className="sort-icon company">
          <img src="/assets/images/table-sort-white-2x.png" width="12" height="auto" alt="sort" />
        </div> */}
        </div>
      ),
      key: "company",
      dataIndex: "company",
      render: (text, record) => (record.apollo_id === null ? <span className="reset-btn">N/A</span> :
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Space>
            {record.company_logo && (
              <Avatar src={record.company_logo} size={37} shape="circle" />
            )}
            <span>{text}</span>
          </Space>
          <a href={record.website} target="_blank">
            <img
              src="/assets/svg/link-in-new-tab.svg"
              width="auto"
              height="18"
              alt="company"
            />
          </a>
        </Space>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center">
          <span>JOB TITLE</span>
          {/* <div className="sort-icon role">
          <img src="/assets/images/table-sort-white-2x.png" width="12" height="auto" alt="sort" />
        </div> */}
        </div>
      ),
      key: "role",
      dataIndex: "role",
      render: (text, record) => (record.apollo_id === null ? <span className="reset-btn">N/A</span> : <span>{record.role}</span>)
    },
    {
      title: (
        <div className="d-flex align-items-center">
          <span>LOCATION</span>
          {/* <div className="sort-icon location">
          <img src="/assets/images/table-sort-white-2x.png" width="12" height="auto" alt="sort" />
        </div> */}
        </div>
      ),
      key: "location",
      dataIndex: "location",
      render: (text, record) => (record.apollo_id === null ? <span className="reset-btn">N/A</span> :
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <Space style={{ position: "relative" }}>
            <img
              src="/assets/svg/location-icon.svg"
              width="auto"
              height="18"
              alt="location"
            />
            <div
              style={{
                position: "absolute",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                backgroundColor: "inherit",
                border: "1px solid white",
                top: "5px",
                left: "4px",
              }}
            ></div>
            <span>{record.city},{" "}{record.state},{" "}{text}</span>
          </Space>
        </Space>
      ),
    },
    {
      title: "PHONE NUMBER",
      key: "phone_number",
      dataIndex: "phone_number",
      render: (text, record) => record.apollo_id === null ? <span className="reset-btn">N/A</span> :
        // record.is_enriched ? (
          <Tooltip className=""
            title={
              <div className="">
                <div style={{
                  backgroundColor: "#727672", margin: "0%",
                  border: "1px solid rgb(73, 78, 73) !important",
                  borderRadius: "5px", paddingLeft: "2%",
                  paddingRight: "2%", width: "100%", paddingBottom: "9%", paddingTop: "8%",
                  lineHeight: "13.72px"
                }} >
                  {record?.phone_number}
                </div>

              </div>
            }
          >
            <div className="pointer">
              {record.phone_number?.substring(0, 15)}{record?.phone_number?.length > 15 ? "..." : ""}
            </div>
          </Tooltip>
       /*  ) : (
          <div className={"justify-content-center d-flex w-100 px-3 py-1 enrich-lead-btn" + (tableName === "filterApply" ? " pointer" : "")} onClick={() => {
            if (tableName === "filterApply") {
              setSaveModalOpen(true)
              setEnrichClick(true)
            }
          }}>
            ENRICH LEAD
          </div>
        ), */
    },
    {
      title: "EMAIL",
      key: "email_address",
      dataIndex: "email_address",
      render: (text, record) =>
        record.apollo_id === null ? <div>{record.email_address}</div> :
          record.is_enriched ? (
            <Tooltip className=""
              title={
                <div className="">
                  <div style={{
                    backgroundColor: "#727672", margin: "0%",
                    border: "1px solid rgb(73, 78, 73) !important",
                    borderRadius: "5px", paddingLeft: "2%",
                    paddingRight: "2%", width: "100%", paddingBottom: "9%", paddingTop: "8%",
                    lineHeight: "13.72px"
                  }} >
                    {record?.email_address}
                  </div>

                </div>
              }
            >
              <div className="pointer px-1" >
                {record?.email_address?.substring(0, 15)}{record?.email_address?.length > 15 ? "..." : ""}
              </div>
            </Tooltip>

          ) : (
            <div className={"justify-content-center d-flex w-100 px-3 py-1 enrich-lead-btn" + (tableName === "filterApply" ? " pointer" : "")} onClick={() => {
              if (tableName === "filterApply") {
                setSaveModalOpen(true)
                setEnrichClick(true)
              }
            }}>
              ENRICH LEAD
            </div>
          ),
    },
  ];

  const leadCompanyDetailColumns = [
    {
      title: <div className="d-flex align-items-center">
        <span>COMPANY</span>
        {/* <div className="sort-icon company">
          <img src="/assets/images/table-sort-white-2x.png" width="12" height="auto" alt="sort" />
        </div> */}
      </div>,
      key: 'company',
      dataIndex: 'company',
      render: (text, record) => (
        <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>
            {record.company_logo && (
              <Avatar src={record.company_logo} size={37} shape="circle" />
            )}
            <span>{text}</span>
          </Space>
          <a href={record.website} target="_blank"><img src="/assets/svg/link-in-new-tab.svg" width="auto" height="18" alt="company" /></a>

        </Space>
      ),
    },
    {
      title: 'INDUSTRY',
      key: 'industry',
      dataIndex: 'industry',
      render: (text, record) => <div>{record.industry}</div>
    },
    {
      title: 'LEAD COUNT',
      key: 'lead_count',
      dataIndex: 'lead_count',
      render: (text, record) => <div>{record.lead_count}</div>
    },
    {
      title: 'TOTAL COUNT',
      key: 'head_count',
      dataIndex: 'head_count',
      render: (text, record) => <div>{record.head_count}</div>
    },
    {
      title: 'LOCATION',
      key: 'location',
      dataIndex: 'location',
      render: (text, record) => (
        <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', whiteSpace: 'nowrap' }}>
          <Space style={{ position: 'relative' }}>
            <img src="/assets/svg/location-icon.svg" width="auto" height="18" alt="location" />
            <div
              style={{
                position: 'absolute',
                width: '7px',
                height: '7px',
                borderRadius: '50%',
                backgroundColor: 'inherit',
                border: '1px solid white',
                top: '7px',
                left: '4px',
              }}
            ></div>
            <span>{record.city},{" "}{record.state},{" "}{text}</span>
          </Space>
        </Space>
      ),
    },
    // {
    //   title: 'CITY',
    //   key: 'city',
    //   dataIndex: 'city',
    //   render: (text, record) => <div>{record.city}</div>
    // },
    // {
    //   title: 'STATE',
    //   key: 'state',
    //   dataIndex: 'state',
    //   render: (text, record) => <div>{record.state}</div>
    // },
    {
      title: 'WEBSITE',
      key: 'website',
      dataIndex: 'website',
      render: (text, record) => (record.is_enriched_people_company || record.is_prospected ? <div><a href={text} target="_blank" rel="noopener noreferrer">{text}</a></div> :
        <div className={'justify-content-center d-flex w-100 px-3 py-1 enrich-lead-btn' + (tableName === "filterApply" ? " pointer" : "")} onClick={() => {
          if (tableName === "filterApply") {
            setSaveModalOpen(true)
            setEnrichClick(true)
          }
        }}>ENRICH LEAD</div>)
    },

  ];

  const dummyUpload = async ({ onSuccess }) => {
    onSuccess("Ok");
  };

  const csvDraggableProps = {
    name: "csv_file",
    multiple: false,
    maxCount: 1,
    customRequest: dummyUpload,
    beforeUpload(file) {
      uploadOnlyCsvFile(file);
      setLoading(-1);
      importedCsvFile.current = file;

      setLoading(0);
      setIsImportDataManual(false)
      setTimeout(() => {
        csvUpload();
      }, 1000);
    },

    onDrop(e) {
      const file = e.dataTransfer.files[0];
      uploadOnlyCsvFile(file);

      setLoading(-1);
      importedCsvFile.current = e.dataTransfer.files[0];

      setLoading(0);
    },
  };

  const uploadOnlyCsvFile = (file) => {
    if (file.type !== "text/csv") {
      notification.error({
        message: "Error",
        description: "Please upload a CSV file.",
      });
      return false;
    }
  }

  const handleDownloadDetail = async () => {
    setLoading(1)
    try {
      const response = await axiosInstance.get(`/v2_prospect/download_prospect_data/${clickedPersona?.persona_id}?user_id=${userId}&company_id=${companyId}&is_person=${downloadType === "people" ? true : false}`);
      if (response?.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", "prospect-" + downloadType + ".csv");
        document.body.appendChild(link);

        link.click();
        link.remove();
      }

      notification.success({
        message: "Success",
        description: "Export has been completed successfully",
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Opps! somethig went wrong, please try again later.",
      });
    }
    setLoading(0)
    setExportLeadModalOpen(false)
  };

  const getCampaignList = async () => {
    setLoadingModal(1);
    try {
      const response = await axiosInstance.get(
        `/v2_engage/all_campaign_list/${userId}?company_id=${companyId} `
      );
      setCampaignList(response.data);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in fetching campaigns List",
      });
    }
    setLoadingModal(0);
  };
  const getLinkedInCampaignList = async () => {
    setLoadingModal(1);
    try {
      const response = await axiosInstance.get(
        `/linkedin/all_linkedin_campaign_list/${userId}?company_id=${companyId} `
      );
      setLinkedInCampaignList(response.data);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in fetching linkedIn campaigns List",
      });
    }
    setLoadingModal(0);
  };

  const handleCampaignSelect = async (e, campaign) => {
    setLoadingModal(1);
    try {
      let url =""
      const data = {
        persona_id: clickedPersona?.persona_id,
        campaign_id: e,
        company_id: companyId,
        is_pop_shown: false,
        allow_duplicate: false,
        all_leads: allEnrich,
        lead_ids: selectedEnrich
      }
      if(campaign){
        url =`/v2_engage/push_lead_to_campaign/${userId}`
      }
      else {
        url = `/linkedin/push_lead_to_linkedin_campaign/${userId}`
      }
      const response = await axiosInstance.put(url, data);
      if (response.data.status === 201) {
        Modal.confirm({
          title: <div style={{ color: "#ffffff" }}>Confirm</div>,
          content: (
            <div style={{ color: "#ffffff" }}>
              Some of these leads are already enrolled in another campaign.
              Do you want to enroll duplicate leads into this campaign or only import net new leads that
              are not enrolled in any other campaigns?
            </div>
          ),
          okText: "Push",
          cancelText: <div style={{ color: "#000000" }}>Do not push</div>,
          onOk: async () => {
            setLoading(1);

            try {
              let modalUrl =""
              const data = {
                persona_id: clickedPersona?.persona_id,
                campaign_id: e,
                company_id: companyId,
                is_pop_shown: true,
                allow_duplicate: true,
                all_leads: allEnrich,
                lead_ids: selectedEnrich
              }
              if (campaign) {
                modalUrl = `/v2_engage/push_lead_to_campaign/${userId}`
              }
              else {
                modalUrl = `/linkedin/push_lead_to_linkedin_campaign/${userId}`
              }
              const res = await axiosInstance.put(modalUrl, data);
              notification.success({
                message: "Success",
                description: res?.data?.message,
              });
            } catch (error) {
              notification.error({
                message: "Error",
                description: "Error in pushing the leads",
              });
            }

            setLoading(0);
          },
          onCancel: async () => {
            setLoading(1);

            try {
              let modalCancelUrl =""
              const data = {
                persona_id: clickedPersona.persona_id,
                campaign_id: e,
                company_id: companyId,
                is_pop_shown: true,
                allow_duplicate: false,
                all_leads: allEnrich,
                lead_ids: selectedEnrich
              }
              if (campaign) {
                modalCancelUrl = `/v2_engage/push_lead_to_campaign/${userId}`
              }
              else {
                modalCancelUrl = `/linkedin/push_lead_to_linkedin_campaign/${userId}`
              }
              const res = await axiosInstance.put(modalCancelUrl, data);
              notification.success({
                message: "Success",
                description: res?.data?.message,
              });
            } catch (error) {
              notification.error({
                message: "Error",
                description: "Error in pushing the leads",
              });
            }

            setLoading(0);
          },
        });
      } else {
        notification.success({
          message: "Error",
          description: response?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error?.response?.data?.detail,
      });
    }
    setLoadingModal(0);
    setPushCampaignModal(false);
    setSelectedCampaign("");
  };

  const onDeleteLead = () => {
    if(allEnrich || selectedEnrich.length > 0) {
    Modal.confirm({
      title: <div style={{ color: "#ffffff" }}>Delete leads</div>,
      content: (
        <div style={{ color: "#ffffff" }} >
            Do you want to delete the leads?
        </div>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const payload = {
            persona_id: clickedPersona?.persona_id,
            company_id: companyId,
            all_leads: allEnrich,
            lead_ids: selectedEnrich
          }
          const response = await axiosInstance.put(`/v2_prospect/delete_lead_list/${userId}`, payload);
          if (response.status === 200) {
            setTimeout(async () => {
              notification.success({ message: 'Success', description: response?.data?.message });
            }, 1000);
            if(allEnrich) {
              setLeadPage(1)
              fetchProspects(1)
              setLeadsDetailCurrentPage(1)
              setLeadsDetailTraversedPage(1)
              setTableName("prospects")
              setSelectedEnrich([])
              setExcludeFields([])
              setSelectedValues([])
              setSelectedLabel([])
              setDownloadType("people")
              setSortByOpen(false)
              setImportInside(false)
              setAllEnrich(false)
            }
            else {
              gotoLeadDetails(clickedPersona?.persona_id, 1, null, null);
              setSelectedEnrich([])
              setAllEnrich(false)
            }

          }
          else {
            notification.error({ message: 'Error', description: 'Error in deleting the leads' });
          }
        } catch (error) {
          notification.error({ message: 'Error', description: error?.response?.data?.detail });
        }
      },
    });
    }
    else {
      notification.error({ message: 'Error', description: "Please select atleast one lead to delete" });
      }
  }
  
  const handleEnrich = () => {
    if (selectedCampaign[0] === "existing") {
      if(selectedCampaignId !== "" && selectedCampaignId !== "Select" ) {
        handleCampaignSelect(selectedCampaignId, true);
      }
     else if(selectedLinkedInCampaignId !== "" && selectedLinkedInCampaignId !== "Select" ) {
        handleCampaignSelect(selectedLinkedInCampaignId, false);
      }
    else {
      notification.error({
        message: "Error",
        description: "Please select any one campaign",
      });
    }
    } else if(selectedCampaign[0] === "new"){
      navigate("/engage/campaign-form", { state: { selectedProspect: clickedPersona?.persona_id, leadIds: selectedEnrich} });
    }
    else {
      navigate("/tools/linkedin/campaign-creation", { state: { selectedProspect: clickedPersona?.persona_id, allLeads: allEnrich, leadIds: selectedEnrich} });
    }
  };

  const fetchProspects = async (page) => {
    setLoading(1)
    try {
      const response = await axiosInstance.get(
        `/v2_lists/fetch_lists/${userId}?company_id=${companyId}&limit=10&page=${page}`
      );
      tableData.current = response.data
      if(paramsId!== null && paramsId !== undefined) {
        let selectPersona= response.data.data.filter(i => i.persona_id == paramsId)
        navigateToLeads(selectPersona[0])
      }
      setLoading(0);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in fetching the Persona(s)",
      });
    }
    setLoading(0)
  };
  const csvUpload = async () => {
    setProgressStep(2);
    setProgressPercent(20);

    const formData = new FormData();
    formData.append("file", importedCsvFile.current);

    const config = {
      method: "post",
      baseURL: API_BASE_URL,
      url: `/v2_prospect/upload_csv/${userId}?company_id=${companyId}`,
      headers: {
        Authorization: `Bearer ${authData.token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    const res = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        if (err.request || err.response) {
          console.error(err);
          return false;
        } else {
          return null;
        }
      });

    if (res) {
      setProgressStep(3)
      setProgressPercent(20)
      setTimeout(() => {
        setProgressStep(4)
        setProgressPercent(80)
      }, 2000);
      csvMappings.current = { ...res, file_name: importedCsvFile.current.name };

      for (const column of csvMappings.current.columns) {
        const updatedValues = csvMappings.current.columns.reduce((acc, column) => {
          acc[column] = 'Do Not include';
          return acc;
        }, {});

        setSelectedCsvMappingValues(prevState => ({
          ...prevState,
          ...updatedValues
        }));
      }

      notification.success({
        message: "Success",
        description: "File has been uploaded successfully",
      });
      setImportCsvScreen(2);
    } else if (res === false) {
      notification.error({
        message: "Error",
        description: "Error in Uploading the file",
      });
    } else {
      setShowTimeoutModal(true);
    }

  };

  const postCsvMappings = async () => {

    const values = {};
    for (const column of csvMappings.current.columns) {
      values[column] = selectedCsvMappingValues[column] ? selectedCsvMappingValues[column] : '';
    }
    const emptyFields = csvMappings.current.columns.filter((column) => !values[column]);

    setErrorFields(emptyFields);

    if (emptyFields.length > 0) {
      setLoading(0);
      return;
    }

    if (importDataIsCompany && progressStep == 4) {
      setProgressStep(5)
      setProgressPercent(90)
      return
    }

    if (importDataIsCompany && progressStep == 5 && !csvMappingJobTitle) {
      notification.error({
        message: "Error",
        description: "Please select Job Title",
      });
      return
    }

    const data = {
      file_id: csvMappings.current.file_id,
      mapping: values,
      data_type: importDataIsCompany ? 'company' : 'people'
    };

    try {
      const response = await axiosInstance.post(`/v2_prospect/column_mapping/${userId}?company_id=${companyId}`, data);

      if (response.status === 200) {
        if(!importDataIsCompany) {
        Modal.confirm({
          title: <div style={{ color: "#ffffff" }}>Confirm</div>,
          content: (<div style={{ color: "#ffffff" }}>Would you like to enrich leads or just import this list of LinkedIn URLs without enriching? If you enrich, this will count towards your monthly limit of leads that can be enriched</div>),
          okText: "Enrich",
          cancelText: 'Dont Enrich',
          onOk: async () => {
            enrichLeads(data.file_id, true)
          },
          onCancel: async () => {
            enrichLeads(data.file_id, false)
          },
            })
          }
          else {
              enrichLeads(data.file_id, true); 
          }
        setProgressStep(6)
        setProgressPercent(100)

        notification.success({
          message: "Success",
          description: "Column mappings have been saved successfully",
        });
      }
    } catch (error) {
      setProgressStep(7)
      notification.error({
        message: "Error",
        description: "Error in saving the mappings",
      });
    }

  };

  const postManualUploadPeopleMapping = async () => {

    setProgressStep(2);
    setProgressPercent(20);

    if (importDataManualHasErrors.length > 0) {
      return
    } else if (importDataManualRows.some(row => !row['linkedin_email'])) {
      notification.error({
        message: "Error",
        description: "Please fill Linkedin or Email",
      });
      return
    }

    let data = {
      data: importDataManualRows,
      // persona_id: clickedPersona?.persona_id
    };

    if (importInside) {
      data.persona_id = clickedPersona?.persona_id
    }
    try {
      if(!importDataIsCompany) {
        Modal.confirm({
          title: <div style={{ color: "#ffffff" }}>Confirm</div>,
          content: (<div style={{ color: "#ffffff" }}>Would you like to enrich leads or just import this list of LinkedIn URLs without enriching? If you enrich, this will count towards your monthly limit of leads that can be enriched</div>),
          okText: "Enrich",
          cancelText: 'Dont Enrich',
          onOk: async () => {
            data.enrich_status = true
            const response = await axiosInstance.put(`/v2_prospect/import_lead_manual/${userId}?company_id=${companyId}&is_person=${!importDataIsCompany}`, data);
            if (response.status === 200) {
              setProgressStep(6)
              setProgressPercent(100)
              if(response?.data?.status === 400) {
                notification.success({
                  message: "Success",
                  description: response?.data?.message,
                });
              }
              else {
              notification.success({
                message: "Success",
                description: "Leads are getting enriched in background",
              });
            }
            }
          },
          onCancel: async () => {
            data.enrich_status = false
            const response = await axiosInstance.put(`/v2_prospect/import_lead_manual/${userId}?company_id=${companyId}&is_person=${!importDataIsCompany}`, data);
            if (response.status === 200) {
              setProgressStep(6)
              setProgressPercent(100)
      
              notification.success({
                message: "Success",
                description: "Leads are getting enriched in background",
              });
            }
          },
            })

      }
      else {
            const response = await axiosInstance.put(`/v2_prospect/import_lead_manual/${userId}?company_id=${companyId}&is_person=${!importDataIsCompany}`, data);
            if (response.status === 200) {
              setProgressStep(6)
              setProgressPercent(100)
      
              notification.success({
                message: "Success",
                description: "Leads are getting enriched in background",
              });
            }
      }
      


    } catch (error) {
      console.error("Error saving mappings:", error);
      setProgressStep(7)
      notification.error({
        message: "Error",
        description: error?.response?.data?.detail,
      });
    }


  };

  const postManualUploadComapanyMapping = async () => {

    setProgressStep(2);
    setProgressPercent(20);

    const data = {
      domain: importDataManualUrlRows,
      job_title: csvMappingJobTitle,
      // persona_id: clickedPersona?.persona_id
    };

    if (importInside) {
      data.persona_id = clickedPersona?.persona_id
    }

    try {
      const response = await axiosInstance.put(`/v2_prospect/import_org_manual/${userId}?company_id=${companyId}&is_person=${!importDataIsCompany}`, data);

      if (response.status === 200) {
        setProgressStep(6)
        setProgressPercent(100)

        notification.success({
          message: "Success",
          description: "Organisation are getting enriched in background",
        });
      }


    } catch (error) {
      console.error("Error saving mappings:", error);
      setProgressStep(7)
      notification.error({
        message: "Error",
        description: "Error in enriching",
      });
    }


  };

  const enrichLeads = async (fileId, enrichStatus) => {
    const data = {
      file_id: Number(fileId),
      crm_leads: true,
      enrich_status: enrichStatus
      // persona_id: clickedPersona?.persona_id
    };

    if (importDataIsCompany) {
      data.job_title = csvMappingJobTitle;
      data.count = 10;
    }

    if (importInside) {
      data.persona_id = clickedPersona?.persona_id
    }


    try {
      const response = await axiosInstance.post(`/v2_prospect/import/enrich_leads/${userId}?company_id=${companyId}`, data);
      if (response.status === 200) { 
        if(response?.data?.message === "Enrich credit limit exceeded") {
          Modal.confirm({
            title: <div style={{ color: "#ffffff" }}>Confirm</div>,
            content: (<div style={{ color: "#ffffff" }}>Importing data exceeds your enrichment credit limit. Do you still want to enrich upto the limit?</div>),
            okText: "Yes",
            cancelText: 'No',
            onOk: async () => {
              data.enrich_confirm = true
              const responseCredit = await axiosInstance.post(`/v2_prospect/import/enrich_leads/${userId}?company_id=${companyId}`, data);
              if (responseCredit.status === 200) { 
                notification.success({
                  message: "Success",
                  description: "Leads are getting imported in the background",
                });
               }
            } })
        }
        else {
        notification.success({
          message: "Success",
          description: "Leads are getting imported in the background",
        });
      }
        setSelectedPersonaId(response.data.persona_id)
      }
    } catch (error) {
      notification.error({ message: 'Error', description: error?.response?.data?.detail });
    }

  };

  const handleLeadsTableChange = ({ page }) => {
    setLeadsCurrentPage(page);
    if (page > leadsTraversedPage) {
      setLeadsTraversedPage(page);
      fetchLeads(selectedPersonaId, page);
    } else {
      setLeadTableData(allLeadsData[page - 1]);
    }
  };

  const handleLeadsDetailTableChange = async ({ page }) => {
    setLeadsDetailCurrentPage(page);
    if (page > leadsDetailTraversedPage) {
      setLeadsDetailTraversedPage(page)
    }
    if (page % 4 === 0 && page > leadsDetailTraversedPage) {
      setLoading(1)
      setTimeout(async () => {
        await gotoLeadDetails(clickedPersona?.persona_id, page, searchQuery, sortBy, !(page > leadsDetailTraversedPage));
        setLoading(0)
      }, 10000);
    }
    else {
      setLoading(1)
      setTimeout(async () => {
        await gotoLeadDetails(clickedPersona?.persona_id, page, searchQuery, sortBy, !(page > leadsDetailTraversedPage));
        setLoading(0)
      }, 2000);
    }
  };

  const handleProspectTableChange = ({ page }) => {
    setLeadPage(page);
    fetchProspects(page);
  };

  const closeTimeoutModal = () => {
    setShowTimeoutModal(false);
  };

  const updateFilter = (values, label, excludeData, fieldData, error) => {
    setSelectedValues(values)
    setSelectedLabel(label)
    setExcludeFields(excludeData)
    setFieldType(fieldData)
    setCustomError(error)
  }

  const handleCloseFilter = () => {
    setShowFilters(false)
  }

  const getIndustries = async () => {
    // setLoading(1);
    try {
      const response = await axiosInstance.get(`/persona/get_industries`);
      const options = [];

      response.data.forEach(industry => {
        const option = {
          label: industry.industry_name,
          value: industry.industry_id
        }

        options.push(option);
      });

      setIndustryData(options)
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Industry' });
    }
    getCompanyRoles()
  }

  const getCompanyRoles = async () => {
    // setLoading(1);
    try {
      const response = await axiosInstance.get(`/persona/get_job_postings`);
      const options = [];

      response.data.forEach(item => {
        const option = {
          label: item.post_name,
          value: item.post_name
        }

        options.push(option);
      });

      setHiringRolesData(options)
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Company Roles' });
    }
    // setLoading(0);
  }

  const getJobTitles = async () => {
    // setLoading(1);
    try {
      const response = await axiosInstance.get(`/persona/get_job_title`);
      const options = [];

      response.data.forEach(item => {
        const option = {
          label: item.job_name,
          value: item.job_name
        }
        options.push(option);
      });

      setJobTitleData(options)
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Job Title' });
    }
    // setLoading(0);
    getTechnologies()
  }

  const getTechnologies = async () => {
    // setLoading(1);
    try {
      const response = await axiosInstance.get(`/persona/get_technologies`);
      const options = [];

      response.data.forEach(item => {
        const option = {
          label: item.tech_name,
          value: item.tech_id
        }

        options.push(option);
      });

      setTechnologiesData(options);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Technologies' });
    }
    // setLoading(0);
    // getCompanyRoles();
  }

  const copyShareUrl = (shareUrl) => {
    navigator.clipboard.writeText(shareUrl);
    notification.success({message: 'Success', description: 'Share link has been copied successfully'});
  }

  const renderSelectedValues = () => {
    return (
      <div className="d-flex flex-row font-weight-600" ref={containerRef}>
        {Object.entries(visibleItems).map(([fieldName, values]) => (
          Array.isArray(values) ?
            values.map((value, index) => (
              <div key={`${fieldName}-${index}`} className={`m-1 px-2 py-1 d-flex flex-row justify-content-between 
                ${getFieldColor(fieldName)} width-fit-content border-radius-6 text-capitalize`}>
                <div onClick={() => {
                  if (fieldName === "industry") {
                    let industryId = industryData.filter(i => i.label === value)
                    setSelectedLabel(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    })
                    setSelectedValues(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== industryId[0].value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    })
                  } else if (fieldName === "industry_exclude") {
                    let industryId = industryData.filter(i => i.label === value)
                    setSelectedLabel(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    })
                    setSelectedValues(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== industryId[0].value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    })
                  }
                  else if (fieldName === "organization" || fieldName === "organization_exclude" 
                  || fieldName == "organization_past") {
                   let values = selectedValues[fieldName].map(i => { 
                    if(typeof(i)=== "string"){
                      return JSON.parse(i)
                    } 
                  else {
                    return i
                  }})
                  values = values.filter(v => v.name!== value)
                  values = values.map(i => JSON.stringify(i))
                  
                 console.log("Values removed ", values)
                   setSelectedLabel(prevValues => {
                     const updatedValues = {
                       ...prevValues,
                       [fieldName]: prevValues[fieldName].filter(x => x !== value),
                     };
                     if (updatedValues[fieldName].length === 0) {
                       delete updatedValues[fieldName];
                     }
                     return updatedValues;
                   })
                   setSelectedValues(prevValues => {
                     const updatedValues = {
                       ...prevValues,
                       [fieldName]: values,
                     };
                     if (updatedValues[fieldName].length === 0) {
                       delete updatedValues[fieldName];
                     }
                     return updatedValues;
                   })
                 }
                  else if (fieldName === "funding_select") {
                    let fundingSelectId = fundingSelectOptions.filter(i => i.label === value)
                    setSelectedLabel(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    })
                    setSelectedValues(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== fundingSelectId[0].value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    })
                  }
                  else if (fieldName === "hiring_roles") {
                    let hiringSelectId = hiringRolesData.filter(i => i.label === value)
                    setSelectedLabel(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    })
                    setSelectedValues(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== hiringSelectId[0].value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    })
                  }
                  else if (fieldName === "technologies") {
                    let technologiesSelectId = technologiesData.filter(i => i.label === value)
                    setSelectedLabel(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    });
                    setSelectedValues(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== technologiesSelectId[0].value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    });
                  }
                  else {
                    setSelectedLabel(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    });
                    setSelectedValues(prevValues => {
                      const updatedValues = {
                        ...prevValues,
                        [fieldName]: prevValues[fieldName].filter(x => x !== value),
                      };
                      if (updatedValues[fieldName].length === 0) {
                        delete updatedValues[fieldName];
                      }
                      return updatedValues;
                    });
                  }
                }} className='pointer me-2'><img src="/assets/images/close-gray-2x.png" width={15} />
                </div>
                <div>{fieldName === "organization" || fieldName=== "organization_exclude" || fieldName === "organization_past" ?
                  (() => {
                  try {
                    const parsedValue = JSON.parse(value);
                    return parsedValue.name;
                  } 
                  catch (error) {
                    return typeof value === "string" ? value.replaceAll('_', " ") : value;
                  }
                })
              ()
                 : typeof value === "string" ? value?.replaceAll('_', " ") : value}</div>
              </div>
            )) :
            <div key={`${fieldName}`} className={`m-1 px-2 py-1 d-flex flex-row justify-content-between 
            ${getFieldColor(fieldName)} width-fit-content border-radius-6 text-capitalize`}>
              <div 
              onClick={() => {
                const updatedValues = { ...selectedValues };
                const updatedLabel = { ...selectedLabel };
                delete updatedValues[fieldName];
                delete updatedLabel[fieldName];
                setSelectedLabel(updatedLabel);
                setSelectedValues(updatedValues)
              }} 
              className='pointer me-2'><img src="/assets/images/close-gray-2x.png" width={15} />
              </div>
              <div>{fieldName === "organization" || fieldName=== "organization_exclude" || fieldName === "organization_past" ?
              (() => {
                try {
                  const parsedValue = JSON.parse(values);
                  return parsedValue.name;
                } catch (error) {
                  // Handle parsing error (values is not a JSON string)
                  return typeof values === "string" ? values.replaceAll('_', " ") : values;
                }
              }) ()
            
              :  typeof values === "string" ? values?.replaceAll('_', " ") : values}</div>
            </div>

        ))} {Object.keys(hiddenItems).length > 0 && Object.entries(hiddenItems).reduce((acc, currentValue) => {
          if (Array.isArray(currentValue)) {
            return acc + currentValue.length;
          } else {
            return acc + 1;
          }
        }, 0) > 0 && <span className="hide-filter p-1" ref={expandButtonRef} onClick={() => setExpanded(!expanded)}>
            {Object.entries(hiddenItems).reduce((acc, currentValue) => {
              if (Array.isArray(currentValue[1])) {
                return acc + currentValue[1].length;
              } else {
                return acc + 1;
              }
            }, 0)} + </span>}
        {expanded && Object.keys(hiddenItems).length > 0 && <div className='overlay-container d-flex expanded-filter p-1'
          style={{
            top: expandButtonRef ?
              expandButtonRef?.current?.getBoundingClientRect()?.bottom - 20 + window.scrollY : 0,
            left: expandButtonRef ?
              expandButtonRef?.current?.getBoundingClientRect()?.left - 250 : 0,
            maxHeight: "200px", overflowY: "auto", backgroundColor: "#13131A"
          }}>
          {Object.entries(hiddenItems).map(([fieldName, values]) => (
            Array.isArray(values) ?
              values.map((value, index) => (
                <div key={`${fieldName}-${index}`} className={`m-1 p-2 d-flex flex-row justify-content-between 
            ${getFieldColor(fieldName)} width-fit-content border-radius-6 text-capitalize`}>
                  <div onClick={() => {
                    if (fieldName === "industry") {
                      let industryId = industryData.filter(i => i.label === value)
                      setSelectedLabel(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                      setSelectedValues(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== industryId[0].value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                    } else if (fieldName === "industry_exclude") {
                      let industryId = industryData.filter(i => i.label === value)
                      setSelectedLabel(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                      setSelectedValues(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== industryId[0].value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                    }
                    else if (fieldName === "organization" || fieldName === "organization_exclude" 
                     || fieldName == "organization_past") {
                      let values = selectedValues[fieldName].map(i => { 
                        if(typeof(i)=== "string"){
                          return JSON.parse(i)
                        } 
                      else {
                        return i
                      }})
                     values = values.filter(v => v.name!== value)
                     values = values.map(i => JSON.stringify(i))
                     
                    console.log("Values removed ", values)
                      setSelectedLabel(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                      setSelectedValues(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: values,
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                    }
                    else if (fieldName === "funding_select") {
                      let fundingSelectId = fundingSelectOptions.filter(i => i.label === value)
                      setSelectedLabel(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                      setSelectedValues(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== fundingSelectId[0].value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                    }
                    else if (fieldName === "hiring_roles") {
                      let hiringSelectId = hiringRolesData.filter(i => i.label === value)
                      setSelectedLabel(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                      setSelectedValues(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== hiringSelectId[0].value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      })
                    }
                    else if (fieldName === "technologies") {
                      let technologiesSelectId = technologiesData.filter(i => i.label === value)
                      setSelectedLabel(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      });
                      setSelectedValues(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== technologiesSelectId[0].value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      });
                    }
                    else {
                      setSelectedLabel(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      });
                      setSelectedValues(prevValues => {
                        const updatedValues = {
                          ...prevValues,
                          [fieldName]: prevValues[fieldName].filter(x => x !== value),
                        };
                        if (updatedValues[fieldName].length === 0) {
                          delete updatedValues[fieldName];
                        }
                        return updatedValues;
                      });
                    }
                  }} className='pointer me-2'><img src="/assets/images/close-gray-2x.png" width={15} />
                  </div>
                  <div>{fieldName === "organization" || fieldName=== "organization_exclude" || fieldName === "organization_past" ?
                  (() => {
                    try {
                      const parsedValue = JSON.parse(value);
                      return parsedValue.name;
                    } catch (error) {
                      // Handle parsing error (values is not a JSON string)
                      return typeof value === "string" ? value.replaceAll('_', " ") : value;
                    }
                  })()
                
                    : typeof value === "string" ? value?.replaceAll('_', " ") : value}</div>
                </div>
              )) :
              <span key={`${fieldName}`} className={`m-1 p-2 d-flex flex-row justify-content-between 
            ${getFieldColor(fieldName)} width-fit-content border-radius-6 text-capitalize`}>
                <div onClick={() => {
                  const updatedValues = { ...selectedValues };
                  const updatedLabel = { ...selectedLabel };
                  delete updatedValues[fieldName];
                  delete updatedLabel[fieldName];
                  setSelectedLabel(updatedLabel);
                  setSelectedValues(updatedValues)
                }} className='pointer me-2'><img src="/assets/images/close-gray-2x.png" width={15} />
                </div>
                <div>{fieldName === "organization" || fieldName === "organization_exclude" || fieldName === "organization_past" ? 
                (() => {
                  try {
                    const parsedValue = JSON.parse(values);
                    return parsedValue.name;
                  } catch (error) {
                    // Handle parsing error (values is not a JSON string)
                    return typeof values === "string" ? values.replaceAll('_', " ") : values;
                  }
                })()
              
                : typeof values === "string" ? values?.replaceAll('_', " ") : values}</div>
              </span>

          ))}
        </div>}
        <div></div>
      </div>)
  };

  const getFieldColor = (fieldName) => {
    // Define color mappings for different fields
    const colorMap = {
      job_title: 'job-title-green',
      job_title_exclude: 'exclude-filter',
      organization: 'job-title-green',
      organization_exclude: 'job-title-green',
      organization_past: 'job-title-green',
      location: 'location-color',
      location_exclude: 'exclude-filter',
      management_level: "management-color",
      departments_functions: "dept-fn-color",
      industry: "industry-color",
      industry_exclude: "exclude-filter",
      keywords: "keywords-color",
      keywords_exclude: "exclude-filter",
      headCount_min: "head-count-color",
      headCount_max: "head-count-color",
      headCount_select: "head-count-color",
      revenue_min: "revenue-color",
      revenue_max: "revenue-color",
      funding_select: "funding-color",
      funding_min: "funding-color",
      funding_max: "funding-color",
      funding_date_min: "funding-color",
      funding_date_max: "funding-color",
      min_years: "time-spent-color",
      min_months: "time-spent-color",
      max_years: "time-spent-color",
      max_months: "time-spent-color",
      hiring_roles: "hiring-color",
      technologies: "technologies-color",
      date_founded_before: "date-founded-color",
      date_founded_after: "date-founded-color",
    };
    return colorMap[fieldName] || "";
  };

  const fetchLeads = async (personaId, page) => {
    setLoading(1)
    try {
      const payload = {
        persona_id: personaId,
        count: 25,
        is_person_generated: !isCompany.current,
        crm_leads: true,
        file_id: 0,
        not_contact_label_ids: page == 1 ? [] : notContactLabelIds,
        not_account_label_ids: page == 1 ? [] : notAccLabelIds,
        page: page,
      };
      let leadResponse;
      if (!isCompany.current) {
        leadResponse = await axiosInstance.post(
          `/v2_prospect/search_apollo_leads/${userId}?company_id=${companyId}`,
          payload
        );
      }
      else {
        leadResponse = await axiosInstance.post(
          `/v2_prospect/search_apollo_companies/${userId}?company_id=${companyId}`,
          payload
        );
      }

      setNotAccLabelIds([...leadResponse.data.not_account_label_ids]);
      setNotContactLabelIds([...leadResponse.data.not_contact_label_ids])
      setLeadTableData(leadResponse.data.data);
      let allLeadData = [...allLeadsData, leadResponse.data.data];
      setAllLeadsData(allLeadData);
      setLeadsPagination(leadResponse.data.pagination);
      setTableName("filterApply");
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in fetching the leads",
      });
    }
    setLoading(0)
  };

  const onApplyFilter = async () => {
    setLoading(1)
    const data = {
      company_id: companyId,
      persona_name: "",
      type: "Prospected",
      job_title: selectedValues?.job_title || [],
      organizations_list: selectedValues?.organization?.map((i) => {
        if(typeof(i) === "string"){
          return JSON.parse(i)  
        }
        else {
          return i
        }
        })
       || [],
      management_level: selectedValues?.management_level || null,
      department: selectedValues?.departments_functions || null,
      industry: selectedValues?.industry || null,
      keyword: selectedValues?.keywords || null,
      geography: selectedValues?.location || null,
      headcount: selectedValues?.headCount_select || [(selectedValues?.headCount_min !== undefined ? selectedValues?.headCount_min : "")
        + ',' + (selectedValues?.headCount_max !== undefined ? selectedValues?.headCount_max : "")] || null,
      revenue_range: { max: selectedValues?.revenue_max || null, min: selectedValues?.revenue_min || null },
      role_years: {
        min: (Number(selectedValues?.min_years * 12 || 0) + Number(selectedValues?.min_months || 0)) || null,
        max: (Number(selectedValues?.max_years * 12 || 0) + Number(selectedValues?.max_months || 0)) || null
      },
      company_funding: selectedValues?.funding_select || null,
      fund_custom_range: { max: selectedValues?.funding_max || null, min: selectedValues?.funding_min || null },
      amount_raised_date: { max: selectedValues?.funding_date_max || null, min: selectedValues?.funding_date_min || null },
      date_founded: { max: selectedValues?.date_founded_after || null, min: selectedValues?.date_founded_before || null },
      technologies_used: selectedValues?.technologies || null,
      hiring_roles: selectedValues?.hiring_roles || null,
      exclude_job_title: selectedValues?.job_title_exclude || null,
      exclude_industry: selectedValues?.industry_exclude || null,
      exclude_keyword: selectedValues?.keyword_exclude || null,
      exclude_geography: selectedValues?.location_exclude || null,
      exclude_organizations_list: selectedValues?.organization_exclude?.map((i) => {
        if(typeof(i) === "string"){
          return JSON.parse(i)  
        }
        else {
          return i
        }
        })
       || [],
      person_past_organizations_list: selectedValues?.organization_past?.map((i) => {
        if(typeof(i) === "string"){
          return JSON.parse(i)  
        }
        else {
          return i
        }
        })
       || [],
    }
    if (data.headcount[0] === ",") {
      delete data.headcount
    }
    if (data.revenue_range.max === null || data.revenue_range.min === null) {
      delete data.revenue_range
    }
    if (data.role_years.max === null || data.role_years.min === null) {
      delete data.role_years
    }
    if (data.date_founded.max === null) {
      delete data.date_founded.max
    }
    if (data.date_founded.min === null) {
      delete data.date_founded.min
    }
    if ((data.date_founded.min === null || data.date_founded.min === undefined) 
      && (data.date_founded.max === null || data.date_founded.max === undefined)) {
      delete data.date_founded
    }
    if (data.amount_raised_date.max === null) {
      delete data.amount_raised_date.max
    }
    if (data.amount_raised_date.min === null) {
      delete data.amount_raised_date.min
    }
    if ((data.amount_raised_date.max === null || data.amount_raised_date.max === undefined) && 
        (data.amount_raised_date.min === null || data.amount_raised_date.min === undefined)) {
      delete data.amount_raised_date
    }
    if (data.fund_custom_range.max === null) {
      delete data.fund_custom_range.max
    }
    if (data.fund_custom_range.min === null) {
      delete data.fund_custom_range.min
    }
    if ((data.fund_custom_range.min === null || data.fund_custom_range.min === undefined) && 
        (data.fund_custom_range.max === null || data.fund_custom_range.max === undefined)) {
        delete data.fund_custom_range
    }
    const dataWithoutNullValues = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    );

    const payload = { ...dataWithoutNullValues }
    try {
      const response = await axiosInstance.post(
        `/v2_prospect/create_filters/${userId}`,
        payload
      );
      setSelectedPersonaId(response.data.persona_id);
      await fetchLeads(response.data.persona_id, 1);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in creating filters",
      });
    }
    setLoading(0);
  };

  const onUpdateFilter = async () => {
    setLoading(1)
    const data = {
      company_id: companyId,
      persona_name: "",
      job_title: selectedValues?.job_title || [],
      organizations_list: selectedValues?.organization?.map((i) => {
        if(typeof(i) === "string"){
          return JSON.parse(i)  
        }
        else {
          return i
        }
        })
       || [],
      management_level: selectedValues?.management_level || null,
      department: selectedValues?.departments_functions || null,
      industry: selectedValues?.industry || null,
      keyword: selectedValues?.keywords || null,
      geography: selectedValues?.location || null,
      headcount: selectedValues?.headCount_select || [(selectedValues?.headCount_min !== undefined ? selectedValues?.headCount_min : "")
        + ',' + (selectedValues?.headCount_max !== undefined ? selectedValues?.headCount_max : "")] || null,
      revenue_range: { max: selectedValues?.revenue_max || null, min: selectedValues?.revenue_min || null },
      role_years: {
        min: (Number(selectedValues?.min_years * 12 || 0) + Number(selectedValues?.min_months || 0)) || null,
        max: (Number(selectedValues?.max_years * 12 || 0) + Number(selectedValues?.max_months || 0)) || null
      },
      company_funding: selectedValues?.funding_select || null,
      fund_custom_range: { max: selectedValues?.funding_max || null, min: selectedValues?.funding_min || null },
      amount_raised_date: { max: selectedValues?.funding_date_max || null, min: selectedValues?.funding_date_min || null },
      date_founded: { max: selectedValues?.date_founded_after || null, min: selectedValues?.date_founded_before || null },
      technologies_used: selectedValues?.technologies || null,
      hiring_roles: selectedValues?.hiring_roles || null,
      exclude_job_title: selectedValues?.job_title_exclude || null,
      exclude_industry: selectedValues?.industry_exclude || null,
      exclude_keyword: selectedValues?.keyword_exclude || null,
      exclude_geography: selectedValues?.location_exclude || null,
      exclude_organizations_list: selectedValues?.organization_exclude?.map((i) => {
        if(typeof(i) === "string"){
          return JSON.parse(i)  
        }
        else {
          return i
        }
        })
       || [],
      person_past_organizations_list: selectedValues?.organization_past?.map((i) => {
        if(typeof(i) === "string"){
          return JSON.parse(i)  
        }
        else {
          return i
        }
        })
       || [],
    }
    if (data.headcount[0] === ",") {
      delete data.headcount
    }
    if (data.revenue_range.max === null || data.revenue_range.min === null) {
      delete data.revenue_range
    }
    if (data.role_years.max === null || data.role_years.min === null) {
      delete data.role_years
    }
    if (data.date_founded.max === null) {
      delete data.date_founded.max
    }
    if (data.date_founded.min === null) {
      delete data.date_founded.min
    }
    if ((data.date_founded.min === null || data.date_founded.min === undefined) 
      && (data.date_founded.max === null || data.date_founded.max === undefined)) {
      delete data.date_founded
    }
    if (data.amount_raised_date.max === null) {
      delete data.amount_raised_date.max
    }
    if (data.amount_raised_date.min === null) {
      delete data.amount_raised_date.min
    }
    if ((data.amount_raised_date.max === null || data.amount_raised_date.max === undefined) && 
        (data.amount_raised_date.min === null || data.amount_raised_date.min === undefined)) {
      delete data.amount_raised_date
    }
    if (data.fund_custom_range.max === null) {
      delete data.fund_custom_range.max
    }
    if (data.fund_custom_range.min === null) {
      delete data.fund_custom_range.min
    }
    if ((data.fund_custom_range.min === null || data.fund_custom_range.min === undefined) && 
        (data.fund_custom_range.max === null || data.fund_custom_range.max === undefined)) {
        delete data.fund_custom_range
    }
    const dataWithoutNullValues = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    );

    const payload = {
      data: dataWithoutNullValues
    }
    try {
      const response = await axiosInstance.put(`/v2_prospect/update_filters/${userId}?persona_id=${selectedPersonaId}`, payload)
      await fetchLeads(selectedPersonaId, 1)
    }
    catch (error) {
      notification.error({ message: 'Error', description: 'Error in creating filters' })
    }
    setLoading(0)
  }

  const onSavePersona = async () => {
    setLoadingModal(1);
    try {
      const payload = {
        persona_id: selectedPersonaId,
        count: 1000,
        is_person_generated: !isCompany.current,
        crm_leads: true,
        persona_name: newPersonaName
      }
      const response = await axiosInstance.post(`/v2_prospect/save_apollo_leads/${userId}?company_id=${companyId}`, payload)
      notification.success({ message: 'Success', description: 'Leads are getting prospected in the background' })
      setSaveModalOpen(false)
      setEnrichClick(false)
      setLeadPage(1)
      await fetchProspects(1)
      setTableName("prospects")
    }
    catch (error) {
      notification.error({
        message: "Error",
        description: "Error in prospecting",
      });
    }
    setLoadingModal(0);
  };

  const onRenamePersona = async () => {
    setLoadingModal(1)
    try {
      const response = await axiosInstance.put(`/v2_prospect/rename_list/${userId}?persona_id=${clickedPersona?.persona_id}&company_id=${companyId}&name=${renamePersonaName}`)
      let personaDetails = { ...clickedPersona };
      personaDetails.persona_name = renamePersonaName;
      setClickedPersona(personaDetails);
      setRenameModalOpen(false)
      setRenamePersonaName("")
      notification.success({ message: 'Success', description: 'Renamed successfully' })
    }
    catch (error) {
      notification.error({ message: 'Error', description: 'Error in renaming persona name' })
    }
    setLoadingModal(0)
  }

  const onEnrich = async () => {
    setLoadingModal(1);
    try {
      const payload = {
        company_id: companyId,
        persona_id: clickedPersona?.persona_id,
        count: inputEnrichCount,
        all_leads: allEnrich,
        lead_ids: selectedEnrich,
      };
      const response = await axiosInstance.post(
        `/v2_prospect/enrich_leads/${userId}`,
        payload
      );
      notification.success({
        message: "Success",
        description: "Leads are getting enriched in the background",
      });
      setLeadsDetailCurrentPage(1);
      setEnrichModal(false);
      setProgressEnrich(true)
      setInterval(() => {
        if (enrichPercent < 100) {
          setEnrichPercent((prevPercent) => prevPercent + 20);
        } else {
          setEnrichPercent(0);
        }
      }, 1000);
      setTimeout(async () => {
        gotoLeadDetails(clickedPersona?.persona_id, 1, null, null);
        setProgressEnrich(false)
      }, 5000);

    } catch (error) {
      notification.error({
        message: "Error",
        description: error.response.data.detail,
      });
    }
    setLoadingModal(0);
  };

  const getWidthOfItem = (value) => {
    const approximateCharacterWidth = 10;
    const padding = 20;
    const width = value?.toString()?.length * approximateCharacterWidth + padding;
    return width;
  };

  const navigateToLeads = (persona) => {
    setTableName("people")
    setClickedPersona(persona)
    gotoLeadDetails(persona.persona_id, 1, null, null);
  }

  useEffect(() => {
    fetchProspects(1);
  }, []);

  useEffect(() => {
    if (Object.keys(hiddenItems).length === 0) {
      setExpanded(false)
    }
  }, [hiddenItems]);

  useEffect(() => {
    const containerWidth = 500;
    const iconWidth = 20;
    const availableWidth = containerWidth - iconWidth;
    let currentWidth = 0;
    let itemsToShow = [];
    let itemsToHide = [];
    console.log("selectedLabel", selectedLabel)
    const flattenedValues = Object.entries(selectedLabel).flatMap(([key, value]) =>
      Array.isArray(value) ? value.map(v => ({ key, value: v })) : [{ key, value }]
    );
    console.log("flattenedValues", flattenedValues)
    flattenedValues.sort((a, b) => a.key.localeCompare(b.key));
    flattenedValues.forEach(({ key, value }) => {
      const itemWidth = getWidthOfItem(value);
      if (currentWidth + itemWidth <= availableWidth) {
        itemsToShow.push({ key, value });
        currentWidth += itemWidth;
      } else {
        itemsToHide.push({ key, value });
      }
    });
    const reconstructedVisibleItems = {};
    const reconstructedHiddenItems = {};

    itemsToShow.forEach(({ key, value }) => {
      if (!reconstructedVisibleItems[key]) {
        reconstructedVisibleItems[key] = typeof (selectedLabel[key]) === "string"
          || typeof (selectedLabel[key]) === "number" ? value : [value];
      } else {
        reconstructedVisibleItems[key].push(value);
      }
    });

    itemsToHide.forEach(({ key, value }) => {
      if (!reconstructedHiddenItems[key]) {
        reconstructedHiddenItems[key] = typeof (selectedLabel[key]) === "string" ||
          typeof (selectedLabel[key]) === "number" ? value : [value];
      } else {
        reconstructedHiddenItems[key].push(value);
      }
    });
    setVisibleItems(reconstructedVisibleItems);
    setHiddenItems(reconstructedHiddenItems);
  }, [selectedLabel, selectedValues, excludeFields]);

  useEffect(() => {
    getIndustries();
    getJobTitles()
  }, []);

  const handleProgressPreviousBtn = () => {
    if (progressStep == 1) {
      setImportModalOpen(false)
      importedCsvFile.current = null
      setImportDataManualRows(Array(1).fill({ first_name: '', last_name: '', linkedin_email: '' }))
      setImportDataManualUrlRows(Array(1).fill(''))
    } else if (progressStep == 2 || progressStep == 3) {
      setProgressStep(1)
      setProgressPercent(5)
    } else if (progressStep == 4) {
      setProgressStep(1)
      setProgressPercent(5)
    } else if (progressStep == 5) {
      if (isImportDataManual) {
        setProgressStep(10)
        setProgressPercent(50)
      } else {
        setProgressStep(4)
        setProgressPercent(20)
      }

    } else if (progressStep == 8) {
      setProgressStep(1)
      setProgressPercent(1)
    } else if (progressStep == 9) {
      setProgressStep(8)
      setProgressPercent(50)
    } else if (progressStep == 10) {
      setProgressStep(8)
      setProgressPercent(50)
    }

    if (isImportDataManual) {

    }
  };

  const handleProgressSaveBtn = () => {
    if (isImportDataManual) {
      if (progressStep == 9) {
        postManualUploadPeopleMapping()
      } else {
        if (progressStep == 8) {
          if (importDataIsCompany) {
            setProgressStep(10);
            setProgressPercent(50)
          } else {
            setProgressStep(9)
            setProgressPercent(80)
          }
        } else if (progressStep == 10) {
          setProgressStep(5)
          setProgressPercent(90)
        } else if (progressStep == 5) {
          postManualUploadComapanyMapping();
        }
      }

    } else {
      postCsvMappings()
    }
  }

  const handleSelectChange = (value, column) => {

    setSelectedCsvMappingValues({
      ...selectedCsvMappingValues,
      [column]: value,
    });
  };

  const handleManualImportChange = (e, rowIndex, columnName) => {
    const updatedRows = importDataManualRows.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [columnName]: e.target.value };
      }
      return row;
    });
    ;
    setImportDataManualRows(updatedRows);

    const linkedinUrlPattern = /^(https?:\/\/)?(www\.)?linkedin\.(com|in)$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const namePattern = /^[A-Za-z ]+$/;
    const errors = updatedRows.reduce((acc, row, index) => {
      Object.keys(row).forEach(key => {
        if (key === 'linkedin_email') {
          if (!linkedinUrlPattern.test(row[key]) && !emailPattern.test(row[key]) && namePattern.test(row[key])) {
            if (!acc[index]) {
              acc[index] = { [key]: 'Invalid input' };
            } else {
              acc[index][key] = 'Invalid input';
            }
          }
        } else if (key === 'first_name' || key === 'last_name') {
          if (emailPattern.test(row[key]) || linkedinUrlPattern.test(row[key])) {
            if (!acc[index]) {
              acc[index] = { [key]: 'Invalid input' };
            } else {
              acc[index][key] = 'Invalid input';
            }
          }
        }
      });
      return acc;
    }, []);
    setImportDataManualHasErrors(errors);
  };

  const handleAddManualRow = () => {
    setImportDataManualRows([...importDataManualRows, { first_name: '', last_name: '', linkedin_email: '' }]);
  };

  const handleManualImportUrlChange = (e, rowIndex) => {
    const updatedRows = [...importDataManualUrlRows];

    updatedRows[rowIndex] = e.target.value;

    setImportDataManualUrlRows(updatedRows);

  };

  const handleAddManualUrlRow = () => {
    setImportDataManualUrlRows([...importDataManualUrlRows, '']);
  };

  const isEmptyObjectOrAllEmptyValues = (obj) => {
    if (Object.keys(obj).length === 0) {
      return true;
    }

    // Check if all values are either empty arrays or empty strings
    for (const value of Object.values(obj)) {
      if (!(Array.isArray(value) && value.length === 0) && !(typeof value === 'string' && value.trim() === '')) {
        return false;
      }
    }

    return true;
  }

  const shareMenuAction = ({key}) => {
   if(key === "delete") {
      onDeleteLead()
    }
  }

  return (
    <Layout style={{ minHeight: '100vh', padding: 15 }} className="bootstrap pt-0 prospecting-layout">
      <Row style={{ minHeight: "100vh", width: "100%" }}>
        <Col span={24}>
          {tableName === "prospects" && (
            <Layout
              style={{ minHeight: "100vh", padding: 35, width: "100%" }}
              className="bootstrap prospecting-layout"
            >
              <Row gutter={30} className="pb-3">
                <Col span={24} className="gutter-row d-flex align-items-center">
                  <div className="analytics-heading font-weight-600 px-2">
                    <span>Lead Lists</span>
                  </div>
                </Col>
                <Col
                  span={24}
                  className={
                    "gutter-row d-flex align-items-center justify-content-end"
                  }
                  style={{paddingRight:"30px"}}
                >
                  {editAccess && (
                    <div className="d-flex flex-row">
                      <div
                        onClick={() => {
                          setImportModalOpen(true);
                        }}
                        className="import-data-button d-flex align-items-center me-2 pe-3 ps-2 pointer"
                      >
                        <img
                          src="/assets/svg/thunder-dark-3x.svg"
                          width="18"
                          height="18"
                          alt="edit"
                          className="ps-1"
                        />
                        <span className="text-nowrap ps-1">
                          Import Data
                        </span>
                      </div>
                      <Button
                        type="primary"
                        className="new-list-btn"
                        onClick={() => {
                          setTableName("newList");
                          setSelectedLabel([])
                          setSelectedValues([])
                          setShowFilters(true)
                        }}
                      >
                        New List
                      </Button>{" "}
                      {/* <div className={"new-leads-btn pointer ms-2 " + 
                            (tableData.current.new_lead_count < 1 ? "disabled-lead" : "")} onClick={() => setTableName("newLeads")}
                        >
                        <img className="me-2"
                          src="/assets/svg/new-leads-icon.png" />{tableData.current.new_lead_count > 10 ? "10+" : tableData.current.new_lead_count} New Leads</div> */}
                    </div>
                  )}
                </Col>
              </Row>
              <Table
                className="prospect-table"
                dataSource={tableData.current.data}
                columns={columns}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setClickedPersona(record);
                      setLeadsDetailCurrentPage(1);
                      gotoLeadDetails(record.persona_id, 1, null, null);
                    },
                  };
                }}
              />
              {tableData.current.data && <CustomPaginationProspecting
                currentPage={leadPage}
                totalItems={tableData.current.total}
                noOfRecords={10}
                onChange={handleProspectTableChange}
              />}
            </Layout>
          )}

          {tableName === "newList" && (
            <Layout
              style={{ minHeight: "100vh", padding: 35, width: "100%" }}
              className="bootstrap prospecting-layout"
            >
              <Row gutter={30} className="pb-3">
                <div className="analytics-heading font-weight-500 px-2">
                  <span>👋 Let's find leads.</span>
                </div>
              </Row>
              <div className="font-size-14 d-flex flex-row">
                <div className="filter-search d-flex flex-row " style={{ width: "55%" }}>
                  <img
                    src="/assets/images/search-white-2x.png"
                    width={20}
                    height={20}
                    className="align-self-center ms-2 me-2"
                  />
                  {isEmptyObjectOrAllEmptyValues(selectedLabel) &&
                    <div className="add-filter-btn m-1 px-2 py-1">
                      <PlusOutlined className="me-1" style={{ height: "9px" }} />Add a filter</div>}
                  {renderSelectedValues()}
                </div>
                <div className={`h-auto ms-2 apply-btn px-4 pt-2 " + ${(Object.keys(selectedValues).length <= 0 ||
                  customError) ? " disabled-div" : ""}`}
                  // disabled={Object.keys(selectedValues).length <= 0 ||
                  //   customError}
                  onClick={() => {
                    onApplyFilter()
                    setShowFilters(false)
                  }}>Apply</div>
              </div>
              <Row>
                <div className="my-2 font-size-14" onClick={() => setShowFilters(true)}>
                  {" "}
                  <Button className="light-purple">
                    <img src="/assets/svg/all-filters-icon.svg" className="me-1" />{" "}
                    All Filters
                  </Button>
                </div>
              </Row>
              {showFilters &&
                <div className="w-100">
                  <NewProspect updateFilter={updateFilter} updatedSelectedValues={selectedValues}
                    handleCloseFilter={handleCloseFilter}
                    updatedSelectedLabels={selectedLabel}
                    hiringRolesOptions={hiringRolesData} industryOptions={industryData}
                    technologiesOptions={technologiesData}
                    jobTitleOptions={jobTitleData}
                    updatedFieldType={fieldType}
                    excludeValue={excludeFields} />
                </div>}
            </Layout>
          )}

          {tableName === "filterApply" && <Layout style={{ minHeight: '100vh', padding: 35, width: "100%" }} className="bootstrap prospecting-layout">
            <Row gutter={30} className="pb-3">
              <div className="analytics-heading font-weight-500 px-2">
                <span >👋 You have {leadsPagination?.total_entries?.toLocaleString()} new leads.</span>
              </div>
            </Row>

            <div className=' justify-content-between d-flex flex-row'>
              <div className="d-flex flex-row w-100">
                <div className="filter-search d-flex flex-row font-size-14" style={{ width: "55%" }}>
                  <img src="/assets/images/search-white-2x.png" width={15} height={15} className='align-self-center ms-2 me-2' />
                  {isEmptyObjectOrAllEmptyValues(selectedLabel) && <div className="add-filter-btn m-1 px-2 py-1">
                    <PlusOutlined className="me-1" style={{ height: "9px" }} />Add a filter</div>}
                  {renderSelectedValues()}
                </div>
                <div className={`h-auto ms-2 apply-btn px-4 pt-2 " + ${(Object.keys(selectedValues).length <= 0 ||
                  customError) ? " disabled-div" : ""}`} onClick={() => {
                    setLeadsCurrentPage(1)
                    setLeadsTraversedPage(1)
                    setNotAccLabelIds([])
                    setNotContactLabelIds([])
                    onUpdateFilter()
                    setShowFilters(false)
                  }}>Update</div>
              </div>
              <div>
                <div className="me-2 save-list-btn h-100 pointer px-3 pt-2" onClick={() => {
                  setSaveModalOpen(true)
                }}> Save List</div>
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className='my-2 font-size-14' ref={allFiltersButtonRef} onClick={() => setShowFilters(true)}>
                <Button style={{ backgroundColor: "#929CFF24" }}><img src="/assets/svg/all-filters-icon.svg" className='me-1' />
                  All Filters</Button></div>
              <div className="reset-btn ms-2 mt-3 pointer" onClick={() => {
                let excludeFields = {
                  excludeJob: false,
                  excludeKeywords: false,
                  excludeLocation: false,
                  excludeIndustry: false
                }
                setExcludeFields(excludeFields)
                setSelectedLabel([])
                setSelectedValues([])
                setFieldType({ headCount: "select", funding: "select" })
                if (showFilters) {
                  setShowFilters(false)
                  setShowFilters(true)
                }
              }}>Reset <Space style={{ position: "relative" }} >
                  <img src="/assets/svg/reset-btn-icon-1.svg" className="ms-1" />
                  <div
                    style={{
                      position: "absolute",
                      width: "7px",
                      height: "7px",
                      // borderRadius: "50%",
                      // backgroundColor: "inherit",
                      // border: "1px solid white",
                      top: "-4px",
                      left: "9px",
                    }}
                  ><img src="/assets/svg/reset-btn-icon-2.svg" /></div>
                </Space>
              </div>
            </div>
            {showFilters && <div className='overlay-container' style={{
              top: allFiltersButtonRef.current ?
                allFiltersButtonRef.current.getBoundingClientRect().bottom + window.scrollY : 0
            }}>
              <NewProspect updateFilter={updateFilter} updatedSelectedValues={selectedValues}
                handleCloseFilter={handleCloseFilter}
                updatedSelectedLabels={selectedLabel}
                hiringRolesOptions={hiringRolesData} industryOptions={industryData}
                technologiesOptions={technologiesData}
                jobTitleOptions={jobTitleData} excludeValue={excludeFields}
                updatedFieldType={fieldType} /> </div>}
            <div className={"d-flex flex-column"}>
              <div className="align-items-center d-flex flex-row justify-content-end">
                <div className={"lead-list-btn toggle pointer" + (!isCompany.current ? " active" : "")}
                  onClick={async () => {
                    isCompany.current = false
                    await fetchLeads(selectedPersonaId, 1)
                    setCurrentPage(1)
                    // setCompanyToggle(false)
                  }}>
                  <>People</>
                </div>
                <div className={"lead-list-btn toggle pointer" + (isCompany.current ? " active" : "")}
                  onClick={async () => {
                    isCompany.current = true
                    await fetchLeads(selectedPersonaId, 1)
                    setCurrentPage(1)
                    // setCompanyToggle(true)
                  }} style={{ marginLeft: '-15px' }}>
                  <>Company</>
                </div>
              </div>
            </div>
            <div className={"table-section mt-4"}
            >
              <Table className='prospect-table apply-table'
                dataSource={leadTableData}
                columns={isCompany.current ? leadCompanyDetailColumns.filter(i => i.key !== 'lead_count') : leadDetailColumns}
                pagination={false}
              />
              {leadTableData && <CustomPaginationProspecting
                currentPage={leadsCurrentPage}
                totalItems={leadsPagination.total_entries || 0}
                onChange={handleLeadsTableChange}
                noOfRecords={25}
              />}
            </div>
          </Layout>}

          {(tableName === "people" || tableName === "company") && <>
            <Layout style={{ minHeight: '100vh', padding: 35, width: "100%" }} className="bootstrap prospecting-layout">
              <Row gutter={30} className="pb-3">
                <Col span={16} className="gutter-row d-flex align-items-center">
                  <div className="analytics-heading font-weight-500 px-2 d-flex flex-row">
                    <div className="pagination-right align-items-center back pointer position-relative me-2"
                      onClick={() => {
                        setLeadPage(1)
                        fetchProspects(1)
                        setLeadsDetailCurrentPage(1)
                        setLeadsDetailTraversedPage(1)
                        setTableName("prospects")
                        setSelectedEnrich([])
                        setExcludeFields([])
                        setSelectedValues([])
                        setSelectedLabel([])
                        setDownloadType("people")
                        setSortByOpen(false)
                        setImportInside(false)
                        setAllEnrich(false)
                      }}
                    >
                      <div className="arrow-parent position-absolute inverted-pagination-arrow">➜</div>
                    </div>
                    <span className="text-nowrap">{clickedPersona?.persona_name}</span>
                  </div>
                  <div className={"align-items-center d-flex flex-row justify-content-start ms-4"}>
                    <div
                      className={"lead-list-btn toggle pointer" + (!isCompany.current ? " active" : "")}
                      onClick={async () => {
                        isCompany.current = false
                        setSearchQuery("")
                        setSortBy("")
                        setSortByOpen(false)
                        setLeadsDetailCurrentPage(1)
                        await gotoLeadDetails(clickedPersona?.persona_id, 1, null, null)
                      }}>
                      <>People</>
                    </div>
                    <div
                      className={"lead-list-btn toggle pointer" + (isCompany.current ? " active" : "")}
                      onClick={async () => {
                        isCompany.current = true
                        setSearchQuery("")
                        setSortBy("")
                        setSortByOpen(false)
                        setLeadsDetailCurrentPage(1)
                        await gotoLeadDetails(clickedPersona?.persona_id, 1, null, null)
                      }}
                      style={{ marginLeft: '-15px' }}
                    >
                      <>Company</>
                    </div>
                  </div>

                </Col>
                <Col span={8} className="d-flex justify-content-end">
                  <div className='justify-content-end me-2'>

                    <Input
                      id="search-box"
                      placeholder="search by name, email etc."
                      prefix={<SearchOutlined />}
                      className="settings-enter-email custom-search-input"
                      // prefix={<SearchOutlined onClick={searchLeadsTrigger} style={{ color: 'inherit',fontSize: '18px' }} />}
                      style={{ border: 'none', backgroundColor: '#25252A', borderRadius: '7px', height: '40px', color: '#DADADB', width: '100%' }}
                      onChange={(e) => {
                        setSearchQuery(e.target.value)
                        gotoLeadDetails(clickedPersona?.persona_id, 1, e.target.value, sortBy)
                        setLeadsDetailCurrentPage(1)
                      }}
                    />
                  </div>
                  <Dropdown className="ms-1"
                menu={{
                  items : [         
                  {
                    label: (
                      <div className="bootstrap">
                        <div className="d-flex align-items-center">
                        <img src="/assets/svg/delete-red.svg" width="17" height="15" alt="icon" />
                          <span className="text-white small ps-2">Delete List</span>
                        </div>
                      </div>
                    ),
                    key: "delete"
                  }],  
                  onClick: shareMenuAction
                }}
                dropdownRender={(menu, index) => (
                  <div className="inbox-filter-dropdown" key={index} style={{marginTop:"5%"}}>{React.cloneElement(menu)}</div>
                )}
              >
                <div className="three-dots-btn silver pointer" style={{marginTop: "2%"}}>...</div>
              </Dropdown>
                </Col>
              </Row>
              <Row gutter={30} className="pb-3 mt-2">
                <Col span={6} style={{ color: '#DADADB' }}>
                  <Space>
                    <span className='p-2' style={{ backgroundColor: '#1B1B1D', borderRadius: '7px' }}>{leadDetailData?.total?.toLocaleString()} Leads</span>
                    <div className='d-flex flex-row' style={{ marginLeft: '10px' }}>
                      <Progress
                        type="circle"
                        percent={leadDetailData.enriched_count / leadDetailData.total * 100}
                        width={16}
                        format={() => `${leadDetailData.enriched_count} Enriched Lead${leadDetailData.enriched_count > 1 ? 's' : ""}`}
                      />
                      <Space style={{ marginLeft: '10px' }}>
                        <div>{leadDetailData?.enriched_count?.toLocaleString()}/{leadDetailData?.total?.toLocaleString()}</div>
                      </Space>
                    </div>
                  </Space>
                </Col>
                <Col span={18}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', pointerEvents: clickedPersona === null || clickedPersona === undefined ? "none" : "" }}>
                    {editAccess && clickedPersona?.type === "Imported" && (
                      <div className="d-flex flex-row">
                        <div
                          onClick={() => {
                            setImportModalOpen(true);
                            setImportInside(true)
                          }}
                          className="edit-filter-btn d-flex align-items-center me-1 pe-2 ps-2 pointer"
                        >
                          <img
                            src="/assets/svg/push-campaign-icon.svg"
                            // width="18"
                            // height="18"
                            alt="edit"
                          // className="ps-1"
                          />
                          <span className="text-nowrap ps-1">
                            Import Data
                          </span>
                        </div> </div>)
                    }
                    <div className="enrich-lead-btn-green d-flex align-items-center p-2 mx-1 pointer" onClick={() => setEnrichModal(true)}>
                      <img className="ps-1" src="/assets/svg/enrich-icon.svg" />
                      <span className="text-nowrap ps-1 pe-1">Enrich Leads</span>
                    </div>
                    <div className="push-campaign d-flex align-items-center p-2 mx-1 pointer" onClick={() => { setPushCampaignModal(true); 
                      getCampaignList(); 
                      getLinkedInCampaignList();
                      }}>
                      <img className="ps-1" src="/assets/svg/push-campaign-icon.svg" />
                      <span className="text-nowrap ps-1 pe-1">Push to Campaign</span>
                    </div>
                    {editAccess && clickedPersona?.type === "Prospected" && (
                      <div className="edit-filter-btn inactive d-flex align-items-center p-2 mx-1 pointer" onClick={() => {
                        setShowFilters(true)
                        setTableName("newList")
                      }}>
                        <img className="ps-1" src="/assets/svg/edit-filter-icon.svg" />
                        <span className="text-nowrap ps-1 pe-1">Edit Filters</span>
                      </div>
                    )}
                    <div
                      className="sort-by-btn inactive d-flex align-items-center mx-1 pointer" ref={sortByButtonRef}
                      onClick={() => setSortByOpen(!sortByOpen)}>
                      <img src="/assets/svg/sort-by-icon.svg" className="ps-2" />
                      <span className="text-nowrap ps-1 pe-2">Sort By</span>
                    </div>

                    <div className="edit-name-btn inactive d-flex align-items-center mx-1 pointer" onClick={() => setRenameModalOpen(true)}>
                      <img className="" src="/assets/svg/edit-name-icon.svg" />
                    </div>
                    <div className="sort-by-btn inactive d-flex align-items-center mx-1 pointer" onClick={() => setExportLeadModalOpen(true)}>
                      <img className="ps-2 pe-2" src="/assets/svg/add-lead-icon.svg" />
                    </div>
                  </div>
                </Col>
              </Row>
              {sortByOpen &&
                <div className="sort-by-list overlay-container mt-2 ps-3 py-2 ms-1 
                font-size-14 font-weight-400 pointer" style={{
                    top: sortByButtonRef.current ?
                      sortByButtonRef.current.getBoundingClientRect().bottom + window.scrollY : 0,
                    left: sortByButtonRef.current ?
                      sortByButtonRef.current.getBoundingClientRect().left - 285 : 0,
                    cursor: "pointer",
                    width: "12%"
                  }}
                  onClick={() => setSortByOpen(false)}>
                  {!isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("name"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "name" ? 'blue' : '' }} onClick={() => {
                      setSortBy('name')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'name')
                    }}>Name</div>}
                  {!isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("company"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "company" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('company')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'company')
                    }}>Company</div>}
                  {!isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("jobTitle"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "jobTitle" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('role')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'role')
                    }}>Job Title</div>}
                  {isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("company"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "company" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('company')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'company')
                    }}>Company</div>}
                  {isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("lead_count"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "lead_count" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('lead_count')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'lead_count')
                    }}>Lead Count</div>}
                  {isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("industry"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "industry" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('industry')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'industry')
                    }}>Industry</div>}
                  <div className="pb-2"
                    onMouseEnter={() => { setHovered("location"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "location" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('location')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'location')
                    }}>Location</div>
                  {!isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("phone"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "phone" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('phone')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'phone')
                    }}>Phone </div>}
                  {!isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("email"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "email" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('email')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'email')
                    }}>Email </div>}
                  {!isCompany.current && <div className="pb-3 pt-2 view-color">VIEW </div>}
                  {!isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("allLeads"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "allLeads" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, '')
                    }}>All Leads </div>}
                  {!isCompany.current && <div className="pb-2"
                    onMouseEnter={() => { setHovered("enriched"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "enriched" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('enriched')
                      setLeadsDetailCurrentPage(1)
                      gotoLeadDetails(clickedPersona?.persona_id, 1, searchQuery, 'enriched')
                    }}>Enriched Leads </div>}
                </div>}
              <Table
                className="prospect-table apply-table"
                dataSource={leadDetailData?.data}
                columns={isCompany.current ? leadCompanyDetailColumns : leadDetailColumns}
                pagination={false}
              // scroll={{ x: '80%' }}
              />
              {leadDetailData?.data && <CustomPaginationProspecting
                currentPage={leadsDetailCurrentPage}
                totalItems={leadDetailData?.total}
                noOfRecords={25}
                onChange={handleLeadsDetailTableChange}
              />}
            </Layout>
          </>
          }
                  {(tableName === "newLeads") && 
        <NewLeads navigateToLeads={navigateToLeads}/> }
        </Col>

        {importModalOpen ? (
          <div className="bottom-fixed-prospecting">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {
                progressStep != 6 &&
                <Button
                  style={{ background: "#1E75FF" }}
                  type="primary"
                  icon={<ArrowLeftOutlined />}
                  onClick={handleProgressPreviousBtn}
                >
                  Back
                </Button>
              }
              {
                (progressStep == 4 || progressStep == 5 || progressStep == 8 || progressStep == 9 || progressStep == 10) &&
                <Button
                  style={{
                    background: "#591EFF",
                    padding: "17px 30px",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                  type="primary"
                  onClick={handleProgressSaveBtn}
                  disabled={(progressStep === 4 && !(Object.values(selectedCsvMappingValues).some(value => value !== "Do Not include"))) 
                            || (progressStep === 9 && importDataManualHasErrors.length > 0)
                            || (progressStep === 9 && importDataManualRows.some(row => !row['linkedin_email']))
                            || (progressStep === 10 && importDataManualUrlRows.some(row => row === ""))}
                >
                  {(progressStep == 8 || progressStep == 10) ? 'Next' : 'Save'}
                </Button>
              }
            </div>
            <Progress
              percent={progressPercent}
              status="active"
              className="mb-0"
              showInfo={false}
            />
          </div>
        ) : (
          ""
        )}
      </Row>

      <Modal
        title={null}
        closable={false}
        open={enrichModal}
        onCancel={() => {
          setEnrichModal(false);
        }}
        footer={null}
        style={{ top: "25%" }}
        className="bootstrap text-white"
      >
        <div>
          <div className="d-flex">
            <img src="\assets\svg\enrich-icon-green.svg" className="align-self-start pt-1"></img>
            <span className="ps-2 font-weight-500" style={{ fontSize: "21px" }}>Enrich Leads</span>
          </div>
          {loadingModal === 1 ? (
            <div className="pt-4 text-center">
              <Spin size="large" />
            </div>
          ) : (
            <div >
              {selectedEnrich.length > 0 ? (
                <div>
                  <div className="font-weight-500 font-size-13 mt-2">
                    Confirming you'd like to enrich the {selectedEnrich.length}{" "}
                    leads you selected.
                  </div>
                  <div className="d-flex flex-row mt-4 font-weight-500 font-size-13">
                    <Checkbox
                      checked={allEnrich}
                      onChange={(e) => setAllEnrich(e.target.checked)}
                    />
                    <div>
                    <div className="ps-2">
                      Select All Leads in this list to Enrich
                    </div>
                    <div>{allEnrich &&<div className='mb-2 ms-2 font-weight-400 font-size-13 fst-italic'>Only 100 leads will be enriched at a time</div>}</div>
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-between w-100 font-weight-400 font-size-16">
                    <Button onClick={() => setEnrichModal(false)}>Cancel</Button>
                    <Button
                      type="primary"
                      className="enrich-btn"
                      onClick={onEnrich}
                    >
                      Enrich
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="font-weight-500 font-size-13 mt-2 mb-2">How many leads would you like to enrich?</div>
                  <div className="font-weight-500 font-size-13">
                    <Input
                      disabled={allEnrich}
                      className="my-2"
                      type="number"
                      value={inputEnrichCount}
                      onChange={(e) => {
                          setInputEnrichCount(e.target.value)
                        if (e.target.value !== "") {
                          setAllEnrich(false);
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex flex-row mt-3 font-weight-500 font-size-13">
                    <Checkbox
                      checked={allEnrich}
                      onChange={(e) => {
                        setAllEnrich(e.target.checked);
                        if (e.target.checked) {
                          setInputEnrichCount(0);
                        }
                      }}
                    />
                    <div>
                    <div className="ps-2">
                      Select All Leads in this list to Enrich
                    </div>
                    <div>{allEnrich &&<div className='mb-2 ms-2 font-weight-400 font-size-13 fst-italic'>Only 100 leads will be enriched at a time</div>}</div>
                  </div>
                  </div>
                  <div className="d-flex flex-row justify-content-between w-100 ">
                    <Button onClick={() => setEnrichModal(false)} className="modal-cancel-btn">Cancel</Button>
                    <Button
                      type="primary"
                      className="modal-ok-btn"
                      onClick={onEnrich}
                    >
                      Enrich
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}</div>
      </Modal>
      <Modal
        title={null}
        closable={false}
        open={saveModalOpen}
        onCancel={() => {
          setSaveModalOpen(false);
          setEnrichClick(false)
        }}
        footer={null}
        style={{ top: "25%" }}
        className="bootstrap text-white"
      >
        <div>
          {loadingModal === 1 ? (
            <div className="pt-4 text-center">
              <Spin size="large" />
            </div>
          ) : (
            <div>
              <div className="d-flex mb-2">
                <img src="\assets\svg\save-list-icon.svg" style={{ paddingBottom: "0.5%" }}></img>
                <span className="ps-2 font-weight-500" style={{ fontSize: "21px" }}>Save List</span>
              </div>

              <div className='mb-2 font-weight-500 font-size-13'>Enter a name for your saved list
                {enrichClick === true ? <span>to enrich leads</span> : null}
              </div>

              <Input className='mb-4 input-blue-border' onChange={(e) => setNewPersonaName(e.target.value)} />
              <div className='d-flex flex-row justify-content-between w-100'>
                <Button className="modal-cancel-btn" onClick={() => {
                  setSaveModalOpen(false)
                  setEnrichClick(false)
                }
                }>Cancel</Button>
                <Button
                  type="primary"
                  className='modal-ok-btn'
                  disabled={newPersonaName === ""}
                  onClick={onSavePersona}
                >
                  Save List
                </Button>
              </div>
            </div>
          )}</div>
      </Modal>
      <Modal
        title={null}
        closable={false}
        open={renameModalOpen}
        onCancel={() => {
          setRenameModalOpen(false)
        }}
        footer={null}
        className="bootstrap text-white"
        style={{ top: "25%" }}
      >
        <div>
          {loadingModal === 1 ? <div className="pt-4 text-center">
            <Spin size="large" />
          </div> :
            <div>
              <div className="d-flex">
                <img src="\assets\svg\edit-name-icon.svg" height={28} width={28}
                  className="align-self-start pt-1"></img>
                <span className="ps-2 font-weight-500" style={{ fontSize: "21px" }}
                >Rename Lead List</span>
              </div>

              <div className='mb-3 font-weight-500 font-size-13 mt-4'>Edit the name of your list below.</div>

              <Input className='mb-4 input-blue-border' placeholder={"Enter a new name"}
                onChange={(e) => setRenamePersonaName(e.target.value)} value={renamePersonaName} />

              <div className='d-flex flex-row justify-content-between w-100'>
                <Button onClick={() => {
                  setRenamePersonaName("")
                  setRenameModalOpen(false)
                }} className="modal-cancel-btn">Cancel</Button>
                <Button type="primary" className='modal-ok-btn' disabled={renamePersonaName === ""}
                  onClick={onRenamePersona}
                >Update
                </Button>
              </div>
            </div>}
        </div>
      </Modal>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={loading === 1 ? true : false}

      // className="bootstrap custom-modal text-white"
      >
        <div className="pt-4 text-center">
          <Spin size="large" />
        </div>
      </Modal>
      <Modal
        closable={false}
        footer={null}
        title={null}
        open={progressEnrich}
        height={8}
        className="bootstrap "
        centered={true}
      // className="bootstrap progress-modal text-white"
      >
        {/* <div style={{ width: "65%", padding: "22% 0" }} className="d-flex flex-column align-items-center justify-content-center mx-auto"> */}
        <div><Progress
          // style={{ marginTop: "25px" }}
          strokeColor="#67FB42"
          percent={enrichPercent}
          status="active"
          className="mb-0"
          showInfo={false}
        />
        </div>
      </Modal>
      <Modal
        title={null}
        closable={false}
        open={pushCampaignModal}
        onCancel={() => {
          setPushCampaignModal(false);
          setSelectedCampaign("");
        }}
        footer={null}
        style={{ top: "25%" }}
        className="bootstrap text-white"
      >
        <div>
          <div className="d-flex">
            <img src="\assets\svg\push-leads-campaign.svg" className="align-self-start pt-1"></img>
            <span className="ps-2 push-campaign-text">Push Leads to a Campaign</span>
          </div>

          {loadingModal === 1 ? (
            <div className="pt-4 text-center">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={(checkedValue) => {
                  const updatedValue =
                    checkedValue.length > 0
                      ? [checkedValue[checkedValue.length - 1]]
                      : [];
                  setSelectedCampaign(updatedValue);
                }}
                value={selectedCampaign}
              >
                <Row>
                  <Col
                    className="font-weight-500 font-size-13"
                    span={24}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "25px",
                    }}
                  >
                    <Checkbox value="new"></Checkbox>
                    <span className="push-leads-list"
                    >
                      New Email Campaign
                    </span>
                  </Col>
                  <Col
                    className="font-weight-500 font-size-13"
                    span={24}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox value="new-linkedIn"></Checkbox>
                    <span className="push-leads-list"
                    >
                      New LinkedIn Campaign
                    </span>
                  </Col>
                  <Col className="font-weight-500 font-size-13" span={24} style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox value="existing" className="mb-0 pb-0"></Checkbox>
                    <span className="push-leads-list"
                    >
                      Existing Campaign
                    </span>
                  </Col>
                  <Col className="font-weight-500 font-size-13"
                    span={24}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {selectedCampaign[0] === "existing" && (
                      <div className="ms-4"
                      >
                        <Row className="ms-4 align-items-center mb-2">
                          <Col span={6}>
                          <img src="\assets\svg\push-email.svg" className="me-3 mt-1 " width={22} height={17}/>
                          </Col>
                          <Col span={18}>
                        <Select
                          className="mt-2 select-email"
                          placeholder="Email Campaigns"
                          options={[
                            { label: "Select", value: "Select" }, // Additional option
                            ...campaignList.map((campaign) => ({
                              label: campaign.campaign_name,
                              value: campaign.campaign_id,
                            }))
                          ]}
                          suffixIcon={<DropdownArrow />}
                          onChange={(selectedValue) =>
                            setSelectedCampaignId(selectedValue)
                          }
                          disabled={selectedLinkedInCampaignId !== "" && selectedLinkedInCampaignId !== "Select"}
                        />
                        </Col>
                        </Row>
                        <Row className="ms-4 align-items-center">
                          <Col span={6}>
                          <img src="\assets\svg\push-linkedIn.svg" className="me-3 mt-1 " width={28} height={28}/>
                          </Col>
                          <Col span={18}>
                        <Select
                          className="mt-2 select-email"
                          placeholder="LinkedIn Campaigns"
                          options={[
                            { label: "Select", value: "Select" }, // Additional option
                            ...linkedIncampaignList.map((campaign) => ({
                              label: campaign.campaign_name,
                              value: campaign.campaign_id,
                            }))
                          ]}
                          suffixIcon={<DropdownArrow />}
                          onChange={(selectedValue) =>
                            setSelectedLinkedInCampaignId(selectedValue)
                          }
                          disabled={selectedCampaignId !== "" && selectedCampaignId !== "Select"}
                        />
                        </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                  <Col className="mt-4" span={12}>
                    <div className="push-cancel-btn pointer" style={{ float: "left" }}
                      onClick={() => {
                        setPushCampaignModal(false);
                        setSelectedCampaign("");
                      }}
                      
                    >
                      Cancel
                    </div>
                  </Col>
                  <Col className="mt-4" span={12}>
                    <div
                      style={{ float: "right" }}
                      className="push-btn pointer"
                      onClick={handleEnrich}
                    >
                      Push
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </>
          )}</div>
      </Modal>
      <Modal
        title={
          <Space direction="vertical">
            <div style={{ color: "#DADADB" }}>
              <img src="\assets\svg\import-data.svg" />
              <span className="ps-2">Import Data {isImportDataManual && (progressStep == 8 || progressStep == 9 || progressStep == 2 || progressStep == 6 || progressStep == 10 || progressStep == 5) && 'Manually'}</span>
              {progressStep == 1 && (
                <Space style={{ marginTop: "10px", fontSize: "13px", display: 'block' }}>
                  <small>
                    You can enrich data by uploading a CSV directly or manually
                    add the links to begin mapping.
                  </small>
                </Space>
              )}
            </div>
          </Space>
        }
        open={importModalOpen}
        onCancel={() => {
          setImportModalOpen(false);
          setProgressStep(1)
          importedCsvFile.current = null
          setImportDataManualRows(Array(1).fill({ first_name: '', last_name: '', linkedin_email: '' }))
          setImportDataManualUrlRows(Array(1).fill(''))
        }}
        footer={null}
        centered
        className="bootstrap custom-modal-import-data text-white"
        width={800}
        maskClosable={false}
      >
        {loadingModal === 1 ? (
          <div className="pt-4 text-center">
            <Spin size="large" />
          </div>
        ) : (
          <>

            {progressStep == 1 && (
              <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "40px", height: '50vh' }}>
                <div
                  id="upload-csv-holder"
                  className="pb-4 m-auto d-flex flex-column align-items-center justify-content-center"
                >
                  <Dragger {...csvDraggableProps}>
                    <div style={{ width: "300px" }}>
                      <Space direction="vertical" size={10}>
                        <img
                          src="/assets/images/export-gray-2x.png"
                          width="24"
                          height="auto"
                          alt="export"
                          style={{ display: "inline-block" }}
                        />
                        <div>
                          <span className="ps-2 fw-semibold d-block">
                            Click here to{" "}
                            <span style={{ color: "rgba(30, 117, 255, 0.86)" }}>
                              upload a file{" "}
                            </span>
                          </span>
                          <span>or drag and drop.</span>
                        </div>
                        <div>.csv files only</div>
                      </Space>
                    </div>
                  </Dragger>
                  <span style={{ marginTop: "30px" }}>
                    or enter the{" "}
                    <span
                      onClick={() => { setIsImportDataManual(true); setProgressStep(8); }}
                      style={{
                        color: "rgba(30, 117, 255, 0.86)",
                        textDecoration: "underline",
                        cursor: 'pointer'
                      }}
                    >
                      links manually.
                    </span>
                  </span>
                </div>
              </div>
            )}

            {progressStep == 2 && (
              <div
                style={{ width: "65%", padding: "22% 0" }}
                className="d-flex flex-column align-items-center justify-content-center mx-auto"
              >
                <span>Hang tight...</span>
                <Progress
                  style={{ marginTop: "25px" }}
                  strokeColor="#67FB42"
                  percent={progressPercent}
                  showInfo={false}
                />
              </div>
            )}
            {progressStep == 3 && (
              <div
                style={{ width: "65%", padding: "15% 0" }}
                className="d-flex flex-column align-items-center justify-content-center mx-auto"
              >
                <div>
                  <img
                    src="/assets/images/🎉successful.png"
                    width="auto"
                    height="40"
                    alt="successful"
                  />
                </div>
                <span style={{ marginTop: "25px" }}>
                  Successfully Uploaded!
                </span>
                <Progress
                  style={{ margin: "25px 0" }}
                  strokeColor="#67FB42"
                  percent={progressPercent}
                  showInfo={false}
                />
                {/* <small>233 leads found.</small> */}
              </div>
            )}
            {progressStep == 4 && (
              <div>
                <div>
                  <Space>
                    <img
                      src="/assets/svg/file.svg"
                      width="auto"
                      height="15"
                      alt="successful"
                    />
                    <span>{importedCsvFile.current.name}</span>
                  </Space>
                </div>
                <Row className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                  <Col span={12}>

                    <div style={{ display: "flex", flexDirection: "column", height: "50%" }}>

                      <div style={{ marginTop: "auto", textAlign: "center", padding: '0 14px' }}>
                        <span style={{ display: "block" }}>What type of data are you uploading?</span>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                          <Space size={10}>
                            <Button type="primary" className={"importdata-btn toggle" + (!importDataIsCompany ? " active" : "")} onClick={() => setImportDataIsCompany(false)}>People</Button>
                            <Button className={"importdata-btn toggle" + (importDataIsCompany ? " active" : "")} onClick={() => setImportDataIsCompany(true)}>Companies</Button>
                          </Space>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ maxHeight: "410px", overflowY: "auto", overflowX: "hidden" }}>
                      <div>
                        <Row gutter={30} align="middle">
                          <Col span={8} className="gutter-row">
                            <div className="text-secondary small pb-3">
                              Column Name
                            </div>
                          </Col>
                          <Col span={16} className="gutter-row">
                            <div className="text-secondary small pb-3">
                              Select Type
                            </div>
                          </Col>
                        </Row>
                        {csvMappings.current.columns.map((column, index) => {
                          return (
                            <div className="pb-3" key={index}>
                              <Row gutter={30} align="middle">
                                <Col span={8} className="gutter-row">
                                  {column}
                                </Col>
                                <Col span={16} className="gutter-row">
                                  <Select
                                    value={selectedCsvMappingValues[column]}
                                    name={column}
                                    className="w-100 custom-select3 csv-mapping"
                                    options={
                                      importDataIsCompany
                                        ? csvCompanyOptions
                                        : csvPeopleOptions
                                    }
                                    placeholder="Select Type"
                                    suffixIcon={<DropdownArrow />}
                                    onChange={(value) => handleSelectChange(value, column)}
                                  // }
                                  />
                                  {errorFields.includes(column) && (
                                    <div style={{ color: 'red' }}>Please enter an option</div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {progressStep == 5 && (<div>
              <Row style={{ marginLeft: "13px", marginTop: "40px", height: '50vh' }}>
                <Col span={24} className="pb-4 d-flex justify-content-center align-items-center">
                  <Space direction="vertical" size={12}>
                    <span style={{ display: "block" }}>What Job Titles do you want to prospect for?</span>
                    <div style={{ width: '350px' }}>
                      <Select
                        mode="tags"
                        virtual={true}
                        className="w-100 importdata-custom-select csv-mapping"
                        options={jobTitleData}
                        placeholder={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <img src="/assets/svg/profile.svg" alt="Profile Icon" style={{ width: "15px", height: "15px" }} />
                            <span style={{ marginLeft: "8px", color: "#fff" }}>Select a Job Title</span>
                          </div>
                        }
                        suffixIcon={<DropdownArrow />}

                        onChange={(value) => setcsvMappingJobTitle(value)}
                      />
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>
            )}
            {progressStep == 6 && (
              <div>
                {isImportDataManual ? "" : <span>{importedCsvFile.current.name}</span>}
                <div
                  style={{ width: "65%", padding: "8% 0" }}
                  className="d-flex flex-column align-items-center justify-content-center mx-auto"
                >
                  <div>
                    <img
                      src="/assets/images/🎉successful.png"
                      width="auto"
                      height="40"
                      alt="successful"
                    />
                  </div>
                  <span style={{ marginTop: "25px" }}>
                    Successfully Mapped!
                  </span>
                  <Progress
                    style={{ margin: "25px 0" }}
                    strokeColor="#67FB42"
                    percent={100}
                    showInfo={false}
                  />
                  <Button className="import-data-view-list-btn" size="large"
                    onClick={() => {
                      if (importInside) {
                        setImportModalOpen(false)
                        gotoLeadDetails(clickedPersona?.persona_id, 1, null, null);
                        setProgressStep(1)
                        importedCsvFile.current = null
                        setImportDataManualRows(Array(1).fill({ first_name: '', last_name: '', linkedin_email: '' }))
                        setImportDataManualUrlRows(Array(1).fill(''))
                      }
                      else {
                        isCompany.current = importDataIsCompany;
                        setLeadsDetailCurrentPage(1);
                        setImportModalOpen(false)
                        importedCsvFile.current = null
                        setImportDataManualRows(Array(1).fill({ first_name: '', last_name: '', linkedin_email: '' }))
                        setImportDataManualUrlRows(Array(1).fill(''))
                        fetchProspects(1)
                        setProgressStep(1)
                        // gotoLeadDetails(selectedPersonaId, 1, null, null);
                      }
                    }}>View List</Button>
                </div>
              </div>
            )}
            {progressStep == 7 && (
              <div>
                {isImportDataManual ? "" : <span>{importedCsvFile.current.name}</span>}
                <div
                  style={{ width: "65%", padding: "8% 0" }}
                  className="d-flex flex-column align-items-center justify-content-center mx-auto"
                >
                  <div>
                    <img
                      src="/assets/images/👎thumbs-down-unsuccessful.png"
                      width="auto"
                      height="40"
                      alt="unsuccessful"
                    />
                  </div>
                  <span style={{ marginTop: "25px" }}>
                    Upload Unsuccessful. Try again!
                  </span>
                  <Progress
                    style={{ margin: "25px 0" }}
                    strokeColor="rgba(251, 66, 66, 1)"
                    percent={5}
                    showInfo={false}
                  />
                  <Button onClick={() => { setProgressStep(1) }} size="large" style={{ backgroundColor: 'rgba(85, 57, 195, 1)' }}>Go Back</Button>
                </div>
              </div>
            )}
            {progressStep == 8 && (
              <div style={{ height: '50vh' }} className="d-flex flex-column justify-content-center align-items-center">
                <span style={{ display: "block", whiteSpace: "nowrap", textAlign: "center" }}>What type of data are you uploading?</span>

                <div className="mt-3">
                  <Space size={10}>
                    <Button type="primary" className={`importdata-btn toggle ${!importDataIsCompany ? "active" : ""}`} onClick={() => { setImportDataIsCompany(false); }}>People</Button>
                    <Button className={`importdata-btn toggle ${importDataIsCompany ? "active" : ""}`} onClick={() => { setImportDataIsCompany(true); }}>Companies</Button>
                  </Space>
                </div>
              </div>
            )}
            {progressStep == 9 && (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <div style={{ padding: '0 20px' }} >
                  <p style={{ margin: '20px 0', textAlign: 'center', whiteSpace: 'nowrap' }}>You must enter a First Name, Last Name and LinkedIn URL/Email for each lead.</p>
                  <div style={{ maxHeight: "300px", overflowY: "auto", overflowX: "hidden" }}>
                    <Row gutter={16}>
                      <Col span={8}><small style={{ fontSize: '9px' }}>FIRST NAME</small></Col>
                      <Col span={8}><small style={{ fontSize: '9px' }}>LAST NAME</small></Col>
                      <Col span={8}><small style={{ fontSize: '9px' }}>LINKEDIN or EMAIL</small></Col>
                    </Row>

                    {importDataManualRows.map((row, rowIndex) => (
                      <Row key={rowIndex} className="import-manual-data-row">
                        <Col span={8} className={importDataManualHasErrors[rowIndex] && importDataManualHasErrors[rowIndex]['first_name'] ? 'import-manual-data-column-red-border' : 'import-manual-data-column'}>
                          <Input

                            value={row.first_name}
                            onChange={(e) => handleManualImportChange(e, rowIndex, 'first_name')}
                          />
                        </Col>
                        <Col span={8} className={importDataManualHasErrors[rowIndex] && importDataManualHasErrors[rowIndex]['last_name'] ? 'import-manual-data-column-red-border' : 'import-manual-data-column'}>
                          <Input

                            value={row.last_name}
                            onChange={(e) => handleManualImportChange(e, rowIndex, 'last_name')}
                          />
                        </Col>
                        <Col span={8} className={importDataManualHasErrors[rowIndex] && importDataManualHasErrors[rowIndex]['linkedin_email'] ? 'import-manual-data-column-red-border' : 'import-manual-data-column'}>
                          <Input

                            value={row.linkedin_email}
                            onChange={(e) => handleManualImportChange(e, rowIndex, 'linkedin_email')}
                          />
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <div className="import-manual-data-btn pointer" onClick={handleAddManualRow}><span className="plus-sign">+</span></div>
                </div>
              </div>
            )}
            {progressStep == 10 && (
              <div className="d-flex justify-content-start align-items-center" style={{ height: '50vh' }}>
                <div style={{ marginTop: '15px', paddingLeft: '20px', width: '80%' }}>
                  <div style={{ maxHeight: "300px", overflowY: "auto", overflowX: "hidden" }}>
                    <Row gutter={16}>
                      <Col span={8}>Enter URLs here:</Col>

                    </Row>

                    {importDataManualUrlRows.map((row, rowIndex) => (
                      <Row key={rowIndex} gutter={16} className="import-manual-data-row">
                        <Col span={14} className={'import-manual-data-column'}>
                          <Input

                            value={row.Url}
                            onChange={(e) => handleManualImportUrlChange(e, rowIndex)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <div className="import-manual-data-btn pointer" onClick={handleAddManualUrlRow}><span className="plus-sign">+</span></div>
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
      <Modal
        title=" "
        open={exportLeadModalOpen}
        // onOk={closeExportLeadModal}
        onCancel={() => { setExportLeadModalOpen(false) }}
        maskClosable={true}
        footer={[]}
        centered
        className="bootstrap text-white"

      >
        <div className="d-flex flex-column align-items-center">
          <img src="/assets/images/loading-logo.png" width="180" height="180" alt="loading" />
          <div className="mb-4 font-weight-500" style={{ fontSize: "24px" }}>Export as CSV</div>
          <div className="small text-center pb-4 font-weight-400 font-size-12">
            Select what data you want to export
          </div>
          <div className="select-company w-100 pb-2 d-flex flex-row justify-content-center w-100">
            <Button
              type={(downloadType === "people" ? "primary" : "")}
              className="border-blue me-2 font-size-16"
              onClick={() => setDownloadType('people')}
            >
              People
            </Button>
            <Button
              type={(downloadType === "company" ? "primary" : "")}
              className="border-blue font-size-16"
              onClick={() => setDownloadType('company')}
            >
              Companies
            </Button>
          </div>
          <Button
            className="export-btn-purple me-2 pointer font-weight-600 font-size-16 mt-1 mb-3"
            onClick={handleDownloadDetail}
            type="primary"
          >
            Export
          </Button>

          <div className="mt-2 w-100">
          <Input
            className="custom-input-analytics mb-4 w-100"
            value={`${window.location.href.split('/').slice(0, 3).join('/')}/share/leadlist?id=${clickedPersona?.persona_id}`}
            suffix={<div className="copy-link-analytics py-1 px-2 pointer"
            onClick={() => copyShareUrl(`${window.location.href.split('/').slice(0, 3).join('/')}/share/leadlist?id=${clickedPersona?.persona_id}`)}>Copy Link</div>}
          />
          </div>
          <div className="d-flex">
          <span className="blue-font analytics-warning">Remember {" "} </span> 
          <span className="analytics-warning text-white">{" "} : You must be logged into a Pipeline account to view this link. </span>
          </div>
        </div>
      </Modal>
      <Drawer
        title=""
        placement="right"
        width={376}
        closable={false}
        onClose={() => {
          setDetailsDrawer(false);
        }}
        open={detailsDrawer}
        key="left"
        className="bootstrap"
      >
        <div className="text-white">
          <div className="d-flex align-items-center pb-2 pointer" onClick={() => {
            setDetailsDrawer(false)
          }}>
            <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
            <span className="ps-2 white-text-62">Close</span>
          </div>
          <div className="campaign-detail-profile-grp" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Avatar className="mb-2" src={`${!selectedProfile?.profile_pic ? '/assets/images/default-dp-radial-2x.png' : selectedProfile?.profile_pic}`} size={65} shape="circle" />
            <span className="mb-2" style={{ fontSize: '24px', fontWeight: '500' }}>{selectedProfile?.name}</span>

            <div className="campaign-detail-btn-grp mb-3">
              <div style={{ marginRight: '15px', cursor: "auto" }} className='campaign-details-btn'>
                Contact Details
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'start' }}>
            <>
              <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Name</Paragraph>
              <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.name}</Paragraph>
              <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Email</Paragraph>
              <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.email_address}</Paragraph>
              <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Job Title</Paragraph>
              <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.role}</Paragraph>
              <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Company</Paragraph>
              <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.company}</Paragraph>
              <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Phone</Paragraph>
              <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.phone_number}</Paragraph>
              <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Website</Paragraph>
              <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.website}</Paragraph>
              <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Linkedin</Paragraph>
              <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{selectedProfile?.linkedin_url}</Paragraph>
              <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Keywords</Paragraph>
              <Paragraph className="inbox-details-para inbox-details-para-color mb-3">{
                selectedProfile?.keyword?.map((k, i) => {
              return (
                <Tag color="blue" key={i} className="mb-1">{k}</Tag>
              )
            })
          }</Paragraph>
            </>
          </div>
        </div>
      </Drawer>
      <TimeoutErrorModal
        shown={showTimeoutModal}
        action={closeTimeoutModal}
        close={closeTimeoutModal}
      />
    </Layout>
  );
};

export default Prospecting;
