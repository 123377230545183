const departmentRawData = [
  ["C-Suite", "c_suite", [
    ["Executive", "executive"],
    ["Finance Executive", "finance_executive"],
    ["Founder", "founder"],
    ["Human Resources Executive", "human_resources_executive"],
    ["Information Technology Executive", "information_technology_executive"],
    ["Legal Executive", "legal_executive"],
    ["Marketing Executive", "marketing_executive"],
    ["Medical & Health Executive", "medical_health_executive"],
    ["Operations Executive", "operations_executive"],
    ["Sales Leader", "sales_leader"],
  ]],
  ["Engineering & Technical", "master_engineering_technical", [
    ["Artificial Intelligence & Machine Learning", "artificial_intelligence_machine_learning"],
    ["Bioengineering", "bioengineering"],
    ["Biometrics", "biometrics"],
    ["Business Intelligence", "business_intelligence"],
    ["Chemical Engineering", "chemical_engineering"],
    ["Cloud/Mobility", "cloud_mobility"],
    ["Data Science", "data_science"],
    ["DevOps", "devops"],
    ["Digital Transformation", "digital_transformation"],
    ["Emerging Technology/Innovation", "emerging_technology_innovation"],
    ["Engineering & Technical", "engineering_technical"],
    ["Industrial Engineering", "industrial_engineering"],
    ["Mechanic", "mechanic"],
    ["Mobile Development", "mobile_development"],
    ["Product Develonment", "product_development"],
    ["Product Management", "product_mangement"],
    ["Project Management", "project_management"],
    ["Research & Development", "research_development"],
    ["Scrum Master/Agile Coach", "scrum_master_agile_coach"],
    ["Software Development", "software_development" ],
    ["Support/Technical", "support_technical_services"],
    ["Technician", "technician"],
    ["Technology Operations", "technology_operations"],
    ["Test/Quality Assurance", "test_quality_assurance"],
    ["UI/UX", "ui_ux"],
    ["Web Development", "web_development"]
  ]],
  ["Design", "design", [
    ["All Design", "all_design"],
    ["Product or UI/UX Design", "product_ui_ux_design"],
    ["Graphic/Visual/Brand Design", "graphic_design"]
  ]],
  ["Education", "education", [
    ["Teacher", "teacher"],
    ["Principal", "principal"],
    ["Superintendent", "superintendent"],
    ["Professor", "professor"]
  ]],
  ["Finance", "master_finance", [
    ["Accounting", "accounting"],
    ["Finance", "finance"],
    ["Financial Planning & Analysis", "financial_planning_analysis"],
    ["Financial Reporting", "financial_reporting"],
    ["Financial Strategy", "financial_strategy"],
    ["Financial Systems", "financial_systems"],
    ["Internal Audit & Control", "internal_audit_control"],
    ["Investor Relations", "investor_relations"],
    ["Mergers & Acquisitions", "mergers_acquisitions"],
    ["Real Estate Finance", "real_estate_finance"],
    ["Financial Risk", "financial_risk"],
    ["Shared Services", "shared_services"],
    ["Sourcing/Procurement", "sourcing_procurement"],
    ["Tax", "tax"],
    ["Treasury", "treasury"]
  ]],
  ["Human Resources", "master_human_resources", [
    ["Compensation & Benefits", "compensation_benefits"],
    ["Culture, Diversity & Inclusion", "culture_diversity_inclusion"],
    ["Employee & Labor Relations", "employee_labor_relations"],
    ["Health & Safety", "health_safety"],
    ["Human Resource Information System", "human_resource_information_system"],
    ["Human Resources", "human_resources"],
    ["HR Business Partner", "hr_business_partner"],
    ["Learning & Development", "learning_development"],
    ["Organizational Development", "organizational_development"],
    ["Recruiting & Talent Acquisition", "recruiting_talent_acquisition"],
    ["Talent Management", "talent_management"],
    ["Workforce Management", "workforce_mangement"],
    ["People Operations", "people_operations"]
  ]],
  ["Information Technology", "master_information_technology", [
    ["Application Development", "application_development"],
    ["Business Service Management/ITSM", "business_service_management_itsm"],
    ["Collaboration & Web App", "collaboration_web_app"],
    ["Data Center", "data_center"],
    ["Data Warehouse", "data_warehouse"],
    ["Database Administration", "database_administration"],
    ["ecommerce Development", "ecommerce_development"],
    ["Enterprise Architecture", "enterprise_architecture"],
    ["Help Desk/Desktop Services", "help_desk_desktop_services"],
    ["HR/Financial/ERP Systems", "hr_financial_erp_systems"],
    ["Information Security", "information_security"],
    ["Information Technology", "information_technology"],
    ["Infrastructure", "infrastructure"],
    ["IT Asset Management", "it_asset_management"],
    ["IT Audit/IT Compliance", "it_audit_it_compliance"],
    ["IT Operations", "it_operations"],
    ["IT Procurement", "it_procurement"],
    ["IT Strategy", "it_strategy"],
    ["IT Training", "it_training"],
    ["Networking", "networking"],
    ["Project & Program Management", "project_program_management"],
    ["Quality Assurance", "quality_assurance"],
    ["Retail/Store Systems", "retail_store_systems"],
    ["Servers", "servers"],
    ["Storage & Disaster Recovery", "storage_disaster_recovery"],
    ["Telecommunications", "telecommunications"],
    ["Virtualization", "virtualization"]
  ]],
  ["Legal", "master_legal", [
    ["Acquisitions", "acquisitions"],
    ["Compliance", "compliance"],
    ["Contracts", "contracts"],
    ["Corporate Secretary", "corporate_secretary"],
    ["eDiscovery", "ediscovery"],
    ["Ethics", "ethics"],
    ["Governance", "governance"],
    ["Governmental Affairs & Regulatory Law", "governmental_affairs_regulatory_law"],
    ["Intellectual Property & Patent", "intellectual_property_patent"],
    ["Labor & Employment", "labor_employment"],
    ["Lawyer/Attorney", "lawyer_attorney"],
    ["Legal", "legal"],
    ["Legal Counsel", "legal_counsel"],
    ["Legal Operations", "legal_operations"],
    ["Litigation", "litigation"],
    ["Privacy", "privacy"]
  ]],
  ["Marketing", "master_marketing", [
    ["Advertising", "advertising"],
    ["Brand Management", "brand_management"],
    ["Content Marketing", "content_marketing"],
    ["Customer Experience", "customer_experience"],
    ["Customer Marketing", "customer_marketing"],
    ["Demand Generation", "demand_generation"],
    ["Digital Marketing", "digital_marketing"],
    ["eCommerce Marketing", "ecommerce_marketing"],
    ["Event Marketing", "event_marketing"],
    ["Field Marketing", "field_marketing"],
    ["Lead Generation", "lead_generation"],
    ["Marketing", "marketing"],
    ["Marketing Analytics/Insights", "marketing_analytics_insights"],
    ["Marketing Communications", "marketing_communications"],
    ["Marketing Operations", "marketing_operations"],
    ["Product Marketing", "product_marketing"],
    ["Public Relations", "public_relations"],
    ["Search Engine Optimization/Pay Per Click", "search_engine_optimization_pay_per_click"],
    ["Social Media Marketing", "social_media_marketing"],
    ["Strategic Communications", "strategic_communications"],
    ["Technical Marketing", "technical_marketing"]
  ]],
  ["Medical & Health", "master_health", [
    ["Anesthesiology", "anesthesiology"],
    ["Chiropractics", "chiropractics"],
    ["Clinical Systems", "clinical_systems"],
    ["Dentistry", "dentistry"],
    ["Dermatology", "dermatology"],
    ["Doctors/Physicians", "doctors_physicians"],
    ["Epidemiology", "epidemiology"],
    ["First Responder", "first_responder"],
    ["Infectious Disease", "infectious_disease"],
    ["Medical Administration", "medical_administration"],
    ["Medical Education & Training", "medical_education_training"],
    ["Medical Research", "medical_research"],
    ["Medicine", "medicine"],
    ["Neurology", "neurology"],
    ["Nursing", "nursing"],
    ["Nutrition & Dietetics", "nutrition_dietetics"],
    ["Obstetrics/Gynecology", "obstetrics_gynecology"],
    ["Oncology", "oncology"],
    ["Opthalmology", "opthalmology"],
    ["Optometry", "optometry"],
    ["Orthopedics", "orthopedics"],
    ["Pathology", "pathology"],
    ["Pediatrics", "pediatrics"],
    ["Pharmacy", "pharmacy"],
    ["Physical Therapy", "physical_therapy"],
    ["Psychiatry", "psychiatry"],
    ["Psychology", "psychology"],
    ["Public Health", "public_health"],
    ["Radiology", "radiology"],
    ["Social Work", "social_work"]
  ]],
  ["Operations", "master_operations", [
    ["Call Center", "call_center"],
    ["Construction", "construction"],
    ["Corporate Strategy", "corporate_strategy"],
    ["Customer Service/Support", "customer_service_support"],
    ["Enterprise Resource Planning", "enterprise_resource_planning"],
    ["Facilities Management", "facilities_management"],
    ["Leasing", "leasing"],
    ["Logistics", "logistics"],
    ["Office Operations", "office_operations"],
    ["Operations", "operations"],
    ["Physical Security", "physical_security"],
    ["Proiect Development", "project_development"],
    ["Quality Management", "quality_management"],
    ["Real Estate", "real_estate"],
    ["Safety", "safety"],
    ["Store Operations", "store_operations"],
    ["Supoly Chain", "supply_chain"]
  ]],
  ["Sales", "master_sales", [
    ["Account Management", "account_management"],
    ["Business Development", "business_development"],
    ["Channel Sales", "channel_sales"],
    ["Customer Retention & Development", "customer_retention_development"],
    ["Customer Success", "customer_success"],
    ["Field/Outside Sales", "field_outside_sales"],
    ["Inside Sales", "inside_sales"],
    ["Partnerships", "partnerships"],
    ["Revenue Operations", "revenue_operations"],
    ["Sales", "sales"],
    ["Sales Enablement", "sales_enablement"],
    ["Sales Engineering", "sales_engineering"],
    ["Sales Operations", "sales_operations"],
    ["Sales Training","sales_training"]
  ]],
  ["Consulting", "consulting", [
    ["Consultant", "consultant"]
  ]],
];

const departmentTreeData = [];
const departmentKeyData = [];

let parentKey = 0, childKey = 0;

departmentRawData.forEach(category => {
  childKey = 0;

  const department = {
    title: category[0],
    value: category[1],
    // key: parentKey
    key: category[1],
  };

  departmentKeyData.push({key: department.key, value: department.value});

  const children = [];
  const subCategories = category[2];
  
  subCategories.forEach(subCategory => {
    const child = {
      title: subCategory[0],
      value: subCategory[1],
      // key: parentKey + "-" + childKey
      key: subCategory[1],
    }

    children.push(child);
    departmentKeyData.push({key: child.key, value: child.value});
  });

  department['children'] = [...children];
  departmentTreeData.push(department);

  parentKey++;
});

const departmentData = {departmentKeyData, departmentTreeData};
export default departmentData;
