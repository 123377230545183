import React, {useState} from "react";
import { Button, Modal, Result } from "antd";
import { SmileOutlined } from '@ant-design/icons';

const TimeoutErrorModal = ({shown, action, close}) => {
  return (
    <Modal
      title=" "
      open={shown}
      onOk={action}
      onCancel={close}
      maskClosable={false}
      footer={null}
      centered
      className="bootstrap custom-modal"
    >
      <Result
        icon={<SmileOutlined />}
        subTitle="The process will take few more minutes to complete. Please refresh the page after some time."
        extra={<Button type="primary" onClick={action}>Ok</Button>}
      />
    </Modal>
  );
}

export default TimeoutErrorModal;
