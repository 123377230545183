import $ from 'jquery';

const normalizeHtml = message => {
  if (!message || message.length < 1) {
    return "";
  }

  let replacedHtml = message.replaceAll("\n\n", "\r\n"); // 1. formatting Chat GPT response
  replacedHtml = replacedHtml.replaceAll("<div", "<p").replaceAll("</div", "</p"); // 2. formatting Smartlead response
  // 3. No need to normalize Wang editor's response

  const $newElement = $("<div></div>").html(replacedHtml);
  const $lineBreaks = $newElement.find("br[fr-original-style]");

  $lineBreaks.each((i, e) => {
    const $parent = $(e).parent();
    $parent.remove();
  });

  return $newElement.html();
}

export default normalizeHtml;
