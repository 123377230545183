import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  notification,
  Progress,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Switch,
  Modal,
  Checkbox
} from "antd";
import {
  CopyOutlined,
  EditOutlined,
  UnorderedListOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import CustomPagination from "../../../component/CustomPagination";
import { useFetcher, useNavigate } from "react-router-dom";
import axiosInstance from "../../../config/axiosConfig";
import { timezoneAbbreviations } from "../../../common/TimeZone";

const LinkedinCampaignsList = () => {
  const navigate = useNavigate();
  const paginationStartEnd = useRef({ start: 0, end: 10 });
  const [loading, setLoading] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfRecords, setNoOfRecords] = useState(10);
  const authData = useSelector((state) => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);
  const companyId = companyData.company_id;
  const userId = authData.userInfo.is_super_admin
    ? companyData.user_id
    : authData.userInfo.userid;

  const columns = [
    {
      title: "",
      key: "actions",
      render: (_, record) => (
        <div className="d-flex align-items-center">
          <Switch
            checked={record.active_status}
            onChange={(checked, e) => updateCampaignStatus(record.campaign_id, checked, e)}
            disabled={false}
          />
        </div>
      ),
      width: "5%",
    },
    {
      title: <div style={{fontSize:'9.33px',fontWeight:'600',color: 'rgba(255, 255, 255, 0.73)'}}>NAME</div>,
      key: "name",
      render: (_, record) => (
        <div className="d-flex align-items-center pointer">
          <span className="ps-0">{record.campaign_name?.length > 35 ? record.campaign_name.substring(0, 35) + ' ... ' : record.campaign_name}</span>
        </div>
      ),
    },
    {
      title: <div style={{fontSize:'9.33px',fontWeight:'600',color: 'rgba(255, 255, 255, 0.73)', marginLeft:"3%"}}>ACTIVITY</div>,
      key: "activity",
      render: (_, record) => (

        // <div className="d-flex align-items-center justify-content-between" style={{ width: '100%', padding: '4px 0' }}>
        //   <div className="d-flex align-items-center justify-content-center" style={{ width: '20%' }}>
        //     <div style={{ width: '18px', display: 'inline-block', textAlign: 'center' }}>
        //       <img src="/assets/svg/vector.svg" />
        //     </div>
        //     <span style={{ minWidth: '30px', textAlign: 'right', marginLeft: '8px' }}>{record.invites_sent}</span>
        //   </div>
        //   <div className="d-flex align-items-center justify-content-center" style={{ width: '20%' }}>
        //     <div style={{ width: '18px', display: 'inline-block', textAlign: 'center' }}>
        //     <img src="/assets/svg/happy.svg" />☺
        //     </div>
        //     <span style={{ minWidth: '30px', textAlign: 'right', marginLeft: '8px' }}>{record.profile_viewed}</span>
        //   </div>
        //   <div className="d-flex align-items-center justify-content-center" style={{ width: '20%' }}>
        //     <div style={{ width: '18px', display: 'inline-block', textAlign: 'center' }}>
        //     <img
        //       src="/assets/svg/thumbs-up.svg"
        //       width="18"
        //       height=""
        //       alt="thumbs-up"
        //     />
        //     </div>
        //     <span style={{ minWidth: '30px', textAlign: 'right', marginLeft: '8px' }}>{record.invites_accepted}</span>
        //   </div>
        //   <div className="d-flex align-items-center justify-content-center" style={{ width: '20%' }}>
        //     <div style={{ width: '18px', display: 'inline-block', textAlign: 'center' }}>
        //     <img
        //       src="/assets/svg/chatbox.svg"
        //       width="18"
        //       height=""
        //       alt="thumbs-up"
        //     />
        //     </div>
        //     <span style={{ minWidth: '30px', textAlign: 'right', marginLeft: '8px' }}>{record.followup_sent}</span>
        //   </div>
        //   <div className="d-flex align-items-center justify-content-center" style={{ width: '20%' }}>
        //     <div style={{ width: '18px', display: 'inline-block', textAlign: 'center' }}>
        //     <img src="/assets/images/bidirectional-arrows-gray-2x.png"
        //       width="18"
        //       height=""
        //       alt="reply"
        //     />
        //     </div>
        //     <span style={{ minWidth: '30px', textAlign: 'right', marginLeft: '8px' }}>{record.replied}</span>
        //   </div>
        // </div>

        <div className="d-flex align-items-center">
          <Col span={4} className="d-flex align-items-center pe-4">
            <img src="/assets/svg/happy.svg" />
            <span className="ps-2">{record.profile_viewed}</span>
          </Col>
          <Col span={4} className="d-flex align-items-center pe-4">
            <img src="/assets/svg/vector.svg" />
            <span className="ps-2">{record.invites_sent}</span>
          </Col>
          <Col span={4} className="d-flex align-items-center pe-4">
            <img
              src="/assets/svg/thumbs-up.svg"
              width="18"
              height=""
              alt="thumbs-up"
            />
            <span className="ps-2">{record.invites_accepted}</span>
          </Col>
          <Col span={4} className="d-flex align-items-center pe-4">
            <img
              src="/assets/svg/chatbox.svg"
              width="18"
              height=""
              alt="thumbs-up"
            />
            <span className="ps-2">{record.followup_sent}</span>
          </Col>
          <Col span={4} className="d-flex align-items-center pe-4">
            <img src="/assets/images/bidirectional-arrows-gray-2x.png"
              width="18"
              height=""
              alt="reply"
            />
            <span className="ps-2">{record.replied}</span>
          </Col>
        </div>
      ),
    },
    {
      title: <div style={{fontSize:'9.33px',fontWeight:'600',color: 'rgba(255, 255, 255, 0.73)'}}>PROGRESS</div>,
      key: "progress",
      render: (_, record) => (
        // <div className="d-flex">
        //   <Progress
        //     type="circle"
        //     percent={(record.sent_leads / record.total_lead) * 100}
        //     width={17}
        //     format={() => (Math.round(record.sent_leads) / Math.round(record.total_lead)) * 100 + '%'}
        //   />
        //   <div className="text-nowrap align-self-center" >
        //     <span className="ps-2">{record.sent_leads}/{record.total_lead}</span>
        //   </div>
        // </div>
        <div className="d-flex flex-row">
          <Progress
            type="circle"
            percent={(record.sent_leads / record.total_lead) * 100}
            width={17}
            format={() =>
              <p className="campaign-list-progress-tool-tip">{(!record.sent_leads == 0 && !record.total_lead == 0) ?  Math.round((record.sent_leads / record.total_lead) * 100) : 0}% Progress</p>
            }
          />
          <div className="text-nowrap ms-1 align-self-center">
            <span className="ps-2">{record.sent_leads}/{record.total_lead}</span>
          </div>
        </div>

      ),
    },
    {
      title: <div style={{fontSize:'9.33px',fontWeight:'600',color: 'rgba(255, 255, 255, 0.73)', whiteSpace:"noWrap"}}>RE-FILL</div>,
      key: "auto_prospect",
      render: (_, record) => (
        <div className="switch-container">
        {record.prospect_type === "Prospected" && <Switch 
          className="custom-switch"
          checked={record.auto_prospect}
          onChange={(checked, e) => updateAutoProspect(record.campaign_id, checked, e)}
          size="small"
        /> }
      </div>
      ),
      width: "5%",
    },
    {
      title: <div style={{fontSize:'9.33px',fontWeight:'600',color: 'rgba(255, 255, 255, 0.73)'}}>LAST UPDATED</div>,
      key: "date",
      render: (_, record) => {
        const userTimezone = moment.tz.guess();
        const customTimezoneAbbreviation = timezoneAbbreviations[userTimezone] || moment.tz(userTimezone).format('z');
        return (
          <span>{moment.utc(record.updated_activity).local().format('MM-DD-YYYY [at] h:mm A') + " " + customTimezoneAbbreviation}</span>
        )
      },
    },
  ];

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    setLoading(1)
    try {
      const response = await axiosInstance.get(
        `/linkedin/fetch_campaigns/${userId}?company_id=${companyId}`
      );
      if (response.status === 200) {
        setTableData(response.data);
      }

    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in fetching the Campaigns",
      });
    }

    setLoading(0);
  };

  const postUpdateCampaignStatus = async (id, checked) => {
    setLoading(1)
    try {
      const response = await axiosInstance.put(
        `/linkedin/update-campaign-details/${id}?active_status=${checked}`
      );
      if (response.status === 200) {
        fetchCampaigns();

      }

    } catch (error) {
      setLoading(0);
      notification.error({
        message: "Error",
        description: "Error in updating the Campaign status",
      });
    }


  };


  const updateCampaignStatus = async (id, checked, e) => {
    e.stopPropagation();
    postUpdateCampaignStatus(id, checked);
  };

  const updateAutoProspect = async (id, checked, e) => {
    e.stopPropagation();
    setLoading(1)
    try {
      const response = await axiosInstance.put(
        `linkedin/update-campaign-auto-prospect/${id}?user_id=${userId}&active_status=${checked}`
      );
      if (response.status === 200) {
        fetchCampaigns();
      }

    } catch (error) {
      setLoading(0);
      notification.error({
        message: "Error",
        description: "Error in updating auto prospect",
      });
    }
  };

  const handlePageChange = ({ page, start, end }) => {
    setCurrentPage(page);
    paginationStartEnd.current = { start, end };
  };

  return (

    <Layout
      style={{ minHeight: "100vh", padding: 15 }}
      className="bootstrap linkedin-campaign"
    >
      <Row gutter={30} className="pb-3">
        <Col span={12} className="gutter-row d-flex align-items-center">
          <div className="headingWrapper">
            <img
              className="linkedinIcon"
              src="/assets/svg/linkedin-white-back.svg"
              alt="linkedin"
            />
            <div className="analytics-heading font-weight-500 px-2">
              Campaigns
            </div>
          </div>
        </Col>

        <Col
          span={12}
          className="gutter-row d-flex align-items-center justify-content-end"
        >
          <div
            style={{
              background: 'rgba(30, 117, 255, 1)',
              width: '127px',
              height: '32.6px',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '32.6px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: 'rgba(255, 255, 255, 1)',
              borderRadius: '4.18px',
              cursor: 'pointer'
            }}
            className="me-3"
            onClick={() => navigate("/tools/linkedin/campaign-creation")}
          >
            New Campaign
          </div>
        </Col>
      </Row>
      <Row gutter={30} style={{ height: "calc(100% - 48px)" }}>
        <Col span={24} className="gutter-row">
          <Card
            className="pt-2"
            style={{ height: "100%", border: "none", padding: "0" }}
          >

            {/* Content */}
            <div
              id="linkedincampaign-content"
              className={loading === 0 ? "" : "d-none"}
            >
              <div>
                <Table
                  dataSource={tableData
                    // .slice(paginationStartEnd.current.start, paginationStartEnd.current.end)
                  }
                  columns={columns}
                  pagination={false}
                  className="custom-table"
                  headerClassName="custom-header"
                  rowClassName="custom-row"
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        navigate(`/tools/linkedin/campaign-detail?campaign_id=${record.campaign_id}`);
                      },
                    };
                  }}
                />
                {/* <CustomPagination
                    currentPage={currentPage}
                    totalItems={tableData.length}
                    noOfRecords={noOfRecords}
                    onChange={handlePageChange}
                  /> */}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={loading === 1 ? true : false}

      // className="bootstrap custom-modal text-white"
      >
        <div className="pt-4 text-center">
          <Spin size="large" />
        </div>
      </Modal>
    </Layout>


  );
};

export default LinkedinCampaignsList;
