import React, { useEffect, useState, useRef } from 'react';
import { Input, Select, Modal, Drawer, Button, Upload, Spin } from 'antd';
import { DropdownArrow } from "../../assets/svg/Icons";
import { useLoader } from '../../store/loaderContext';
import axiosInstance from '../../config/axiosConfig';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from "../../config/AppConfig";
import axios from 'axios'
import '../../assets/Styles/Settings-SideMenu.css'
const { Dragger } = Upload;
const Blocklist = () => {


  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;

  const isSuperAdmin = authData.userInfo.is_super_admin;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const [blockContact, setBlockContact] = useState();
  const [settingsData, setSettingsData] = useState();
  const [selectedBlockType, setSelectedBlockType] = useState("all");
  const [showLoader, setShowLoader] = useState(false);
  const [showBlockListError, setShowBlockListError] = useState(false);
  const [showUploadList, setShowUploadList] = useState(false);
  const [importedCsvFile, setImportedCsvFile] = useState();
  const [isCompany, setIsCompany] = useState(false);
  const blocklistType = useRef("all");

  const fetchSettings = async () => {
    setShowLoader(true);
    try {
      const response = await axiosInstance.get(`/settings/get_settings/${companyId}?user_id=${userId}`);
      setSettingsData(response.data)
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Settings' });
    }

    setShowLoader(false);
  }

  const fetchBlockedContacts = async (blockListType) => {
    setShowLoader(true);


    let blockType
    if (blockListType !== null && blockListType !== undefined) {
      blockType = blockListType
    }
    else {
      blockType = selectedBlockType
    }
    if (blockType === "all") {
      fetchSettings();
      return;
    }
    try {
      const response = await axiosInstance.get(`/settings/block/list/${userId}?company_id=${companyId}&block_type=${blockType}`);

      const currentSettingsData = { ...settingsData };
      currentSettingsData.block_contact = [...response.data];

      setSettingsData({ ...currentSettingsData });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching blocked contacts' });
      setShowLoader(false);
    }

    setShowLoader(false);
  }
  const setBlocklistType = (key) => {
    setSelectedBlockType(key)
    fetchBlockedContacts(key);
  }

  const addBlocklist = async () => {
    setShowLoader(true);

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const urlRegex = /^(https?:\/\/)?((([a-zA-Z\d-]+)\.)+[a-zA-Z]{2,})(:\d{1,5})?([/?#]\S*)?$/;

    // if (!(emailRegex.test(blockContact) || urlRegex.test(blockContact))) {
    //   setShowLoader(false);
    //   setShowBlockListError(true);
    //   return;
    // } else {
    //   setShowLoader(false);
    //   setShowBlockListError(false);
    // }

    try {
      const response = await axiosInstance.post(`settings/block_list/${companyId}?user_id=${userId}`, { value: blockContact });
      if (response.data.leads !== null) {
        let selectLeads = response.data.leads
        Modal.confirm({
          title: <div style={{ color: "#ffffff" }}>Block leads</div>,
          content: (
            <div style={{ color: "#ffffff", height: "100px", overflowY: "auto" }} >
              <div>
                The following lead exists in running campaigns. Do you still wish to block?
              </div>
              <div>
                {response.data.leads.lead_email}
              </div>
            </div>
          ),
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: async () => {
            try {
              const payload = {
                company_id: companyId,
                leads: []
              }
              payload.leads.push(selectLeads.lead_id)
              const response = await axiosInstance.post(`settings/block/pause_lead/${userId}`, payload);
              if (response.status === 200) {
                setTimeout(async () => {
                  notification.success({ message: 'Success', description: 'Leads blocked successfully' });
                }, 1000);
              }
              else {
                notification.error({ message: 'Error', description: 'Error in blocking leads' });
              }
            } catch (error) {
              notification.error({ message: 'Error', description: 'Error in blocking leads' });
            }
          },
        });
      }
      else {
        notification.success({ message: 'Success', description: 'Added to Block list' });
      }
      setBlockContact("");
      fetchBlockedContacts();
    } catch (error) {
      notification.error({ message: "Duplicate Data", description: "This entry already exists in the list" });
      setShowLoader(false);
    }
  };

  const deleteBlockedContact = async id => {
    setShowLoader(true);

    try {
      await axiosInstance.delete(`settings/blocked-contacts/${id}?user_id=${userId}&company_id=${companyId}`);
      notification.success({ message: 'Success', description: 'Deleted contact from Block list' });

      fetchBlockedContacts();
    } catch (error) {
      notification.error({ message: 'Oops!', description: 'Something went wrong' });
      setShowLoader(false);
    }
  }


  const dummyUpload = async ({ onSuccess }) => {
    onSuccess("Ok");
  }
  const csvDraggableProps = {
    name: 'csv_file',
    multiple: false,
    maxCount: 1,
    customRequest: dummyUpload,
    beforeUpload(file) {
      setShowLoader(true);
      setImportedCsvFile(file);

      setShowLoader(false);
    }
  };


  const csvUpload = async () => {
    setShowUploadList(false);
    setShowLoader(true);

    const formData = new FormData();
    formData.append('file', importedCsvFile);
    const dataType = isCompany === true ? "company" : "people"
    const config = {
      method: 'post',
      baseURL: API_BASE_URL,
      url: `/settings/block/upload_csv/${userId}?data_type=${dataType}&company_id=${companyId}`,
      headers: {
        Authorization: `Bearer ${authData.token}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    };

    const res = await axios(config)
      .then(function (res) {
        return res.data;
      })
      .catch(function (err) {
        if (err.request || err.response) {
          console.error(err);
          return false;
        } else {
          return null;
        }
      });

    if (res) {
      // if (res.leads.length > 0) {
      //   let allLeads = [...res.leads]
      //   // allLeads = allLeads.map(lead => ({
      //   //   ...lead,
      //   //   selected: false
      //   // }));
      //   setSelectedLeads([...allLeads])

      //   Modal.confirm({
      //     title: <div style={{ color: "#ffffff" }}>Block leads</div>,
      //     content: (
      //       <div style={{ color: "#ffffff", height: "200px", overflowY : "auto" }} >
      //         <div>
      //           The following leads exists in running campaigns. If you still wish to block, 
      //           please select the leads which you want to block in existing campaigns?
      //         </div>
      //         <div>
      //     <ul>
      //       {allLeads.map((lead, index) => (
      //         <li key={index}>
      //           <div className='d-flex flex-row'>
      //             <Checkbox
      //               onChange={(e) => { 
      //                 allLeads = allLeads.map(i => i.lead_id === lead.lead_id ? { ...i, selected: e.target.checked } : i);
      //                 setSelectedLeads([...allLeads]);
      //                 }}
      //             />
      //             <div style={{ marginLeft: "2%" }}>{lead.lead_email}</div>
      //           </div>
      //         </li>
      //       ))}
      //     </ul>
      //   </div>
      // </div>
      //     ),
      //     okText: 'OK',
      //     cancelText: 'Cancel',
      //     onOk: async () => {
      //       if(allLeads.filter(lead => lead.selected === true).length > 0) {
      //       try {
      //         const payload = {
      //           company_id: companyId,
      //           leads: allLeads.filter(lead => lead.selected === true).map(i => i.lead_id),
      //         }
      //         const response = await axiosInstance.post(`settings/block/pause_lead/${userId}`, payload);
      //         if (response.status === 200) {
      //           setTimeout(async () => {
      //             notification.success({ message: 'Success', description: 'Leads blocked successfully' });
      //           }, 1000);
      //         }
      //         else {
      //           notification.error({ message: 'Error', description: 'Error in blocking leads' });
      //         }
      //       } catch (error) {
      //         notification.error({ message: 'Error', description: 'Error in blocking leads' });
      //       }
      //     }
      //     },
      //   });
      // }
      // else {
      notification.success({ message: 'Success', description: 'Leads are being blocked' });
      // }
      fetchSettings()
    } else if (res === false) {
      notification.error({ message: 'Error', description: 'Error in Uploading the file' });
    }
    setShowLoader(false);
  }

  useEffect(() => {
    fetchSettings()
  }, []);

  return (
    <div className="bootstrap d-flex flex-row text-white w-100 justify-content-center align-items-center settings-background">
      <div className='d-flex flex-column profile-card'>
        <div className='profile-text w-100 mb-3'>Blocklist</div>
        <div className='profile-sub-text w-100 mb-3'>
          Companies and people added to the Blocklist will prevent them from being prospected as leads
          or contacted through email outreach.
        </div>
        <div className='blocklist-not-prospect w-100 mb-2'>
          Do not Prospect, Do not Contact
        </div> {editAccess &&
         <div> <div className='blocklist-input-table d-flex p-2 mb-1'>
            <Input className="blocklist-input" placeholder="Enter email or Domain" value={blockContact}
              onChange={(e) => { 
                setBlockContact(e.target.value)
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const urlRegex = /^(https?:\/\/)?((([a-zA-Z\d-]+)\.)+[a-zA-Z]{2,})(:\d{1,5})?([/?#]\S*)?$/;
            
                if (!(emailRegex.test(e.target.value) || urlRegex.test(e.target.value))) {
                  setShowBlockListError(true);
                  return;
                } else {
                  setShowBlockListError(false);
                }
                }}/>
            <div className={ 'ms-2 blocklist-add pointer' + (showBlockListError ? " disabled-div" : "")}onClick={addBlocklist}>Add</div>
                
          </div>{showBlockListError && <div className='invalid-input'>Please input valid email or domain</div>} </div>}
        <div className='mt-2 d-flex justify-content-end'>
          <Select
            placeholder="View"
            options={[
              { label: "People", value: "people" },
              { label: "Domain", value: "domain" },
              { label: "All", value: "all" },
            ]}
            suffixIcon={<DropdownArrow />}
            className="blocklist-view"
            onChange={(value) => {
              setBlocklistType(value)
            }}
          />
          {editAccess && <div className='blocklist-import-csv ms-2 pointer' onClick={() => setShowUploadList(true)}>Import CSV</div>}
        </div>
        <div> {settingsData?.block_contact?.map(i =>
          <div className='d-flex my-2'>
            <img src="\assets\svg\blocklist-close.svg" className="pointer" onClick={() => deleteBlockedContact(i.block_id)} />
            <div className='ms-2 blocklist-name'>{i.contact}</div>
          </div>)}
        </div>
      </div>
      <Drawer
        title=""
        placement="right"
        width={450}
        closable={false}
        onClose={() => {
          setShowUploadList(false)
        }}
        open={showUploadList}
        key="left"
        className="bootstrap"
      >
        <div className="d-flex align-items-center pb-2 pointer" onClick={() => {
          setShowUploadList(false)
        }
        }>
          <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
          <span className="ps-2 white-text-62">Close</span>
        </div>

        <div className={"d-flex flex-column justify-content-center"} style={{ height: "calc(100% - 38px)" }}>
          <div id="upload-csv-holder" className="w-50 pb-4 mx-auto">
            <Dragger {...csvDraggableProps}>
              <div className="d-flex align-items-center justify-content-center">
                <img src="/assets/images/export-gray-2x.png" width="14" height="auto" alt="export" />
                <span className="ps-2 fw-semibold">Upload CSV</span>
              </div>
            </Dragger>
          </div>

          <div className="text-center text-white pt-3 pb-4">Drag and Drop a CSV file to<br />begin the import.</div>
          <div className="select-company d-flex flex-column justify-content-center align-items-center text-white">
            <input type="hidden" id="import-data-type" value={isCompany ? "company" : "people"} />
            <div
              id="import-by-company"
              className="company-row w-100 continue position-relative mb-3 pointer"
              onClick={() => setIsCompany(!isCompany)}
            >
              <span className="text-nowrap small fw-semibold">Company (URL) - Block leads for the entire company</span>
              <div className={"arrow-parent position-absolute" + (isCompany ? "" : " d-none")}
              >✓</div>
            </div>
            <div
              id="import-by-people"
              className="company-row w-100 continue position-relative mb-3 pointer"
              onClick={() => setIsCompany(!isCompany)}
            >
              <span className="text-nowrap small fw-semibold">People - Block Email</span>
              <div className={"arrow-parent position-absolute" + (isCompany ? " d-none" : "")}
              >✓</div>
            </div>
          </div>
          <div className="d-flex justify-content-center text-white">
            <Button type="primary" className="br-10" onClick={() => {
              Modal.confirm({
                title: (<div style={{ color: "#ffffff" }}>Confirm</div>),
                content: (<div style={{ color: "#ffffff" }}>If any leads in your blocklist are already enrolled in an
                  existing campaign, they will not receive future emails or messages from your campaigns.</div>),
                okText: 'OK',
                cancelText: 'Cancel',
                centered: true,
                onOk: async () => {
                  csvUpload()
                },
                onCancel: async () => {
                  setShowUploadList(false)
                }
              })
            }}
              disabled={importedCsvFile === null || importedCsvFile === undefined}>Upload</Button>
          </div>
        </div>

      </Drawer>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={showLoader}

      >
        <div className="pt-4 text-center">
          <Spin size="large" />
        </div>
      </Modal>
    </div>
  );
};

export default Blocklist;
