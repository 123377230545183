import {
  AccountsIcon,
  AnalyticsIcon,
  CampaingsIcon,
  InboxIcon,
  LeadsIcon,
  TasksIcon,
  CopywritingIcon,
  CrmIcon,
  SettingsIcon,
  LinkedInWhiteIcon,
  PipelinePurpleIcon,
  EmailMenuIcon,
  ReportsIcon,
  LogoutIcon
} from '../assets/svg/Icons';
import AuthenticationNew from '../views/Auth/AuthenticationNew';
import SelectCompanyNew from '../views/Auth/select-company-new';
import ProspectingNew from '../views/personas/prospecting-new';
import Account from '../views/engagement/account';
import CampaignsForm from '../views/engagement/campaigns-form';
import CampaignsList from '../views/engagement/campaigns-list';
import CampaignsDetails from '../views/engagement/campaigns-details';
import Inbox from '../views/engagement/inbox';
import AnalyticsNew from '../views/engagement/analytics-new';
import AnalyticsShared from '../views/engagement/analytics-shared';
import PersonaSettings from '../views/personas/settings';
import LinkedinCampaignsList from "../views/tools/linkedin/campaign-list";
import LinkedinCampaignCreation from "../views/tools/linkedin/campaign-creation"
import LinkedinAccount from "../views/tools/linkedin/account"
import LinkedinCampaignsDetail from "../views/tools/linkedin/campaign-detail"
import LinkedinAnalytics from "../views/tools/linkedin/analytics"
import LinkedinInbox from "../views/tools/linkedin/inbox"
import LinkedinCampaignEdit from "../views/tools/linkedin/campaign-edit"
import Profile from '../views/settings/profile'

export const profileMenuItems = [
  {
    key: '/profile',
    icon: <PipelinePurpleIcon />,
    iconSelected: <PipelinePurpleIcon />,
    route: '/settings-new/profile',
    component: <Profile />,
    isMenu: true,
    parent: "profile",
    subMenu: true,
    disabled: false,
    subMenuItems: [
      {
        label: "Settings",
        route: '/settings-new/profile',
        key: '/settings',
        component: <Profile />,
        iconRight: <SettingsIcon />,
        iconSelectedRight: <SettingsIcon color="yellow"/>,
        isMenu: true,
        parent: "profile"
      },
      {
        label: "Contact Us",
        route: '/settings-new',
        key: '/settings/contact',
        component: <Profile />,
        isMenu: true,
      },
      {
        label: "Log out",
        route: '/settings-new',
        key: '/settings/logout',
        component: <Profile />,
        iconRight: <LogoutIcon />,
        iconSelectedRight: <LogoutIcon color="yellow"/>,
        isMenu: true,
        parent: 'profile'
      }
    ]
}
]

export const menuItems = [
  {
    key: "auth",
    route: "/auth",
    component: <AuthenticationNew />,
    isMenu: false,
  },
  {
    key: "select-company",
    label: "Select Company",
    route: "/select-company",
    component: <SelectCompanyNew />,
    isMenu: false,
  },
  {
    key: "share-prospecting",
    label: "Prospecting",
    route: "/share/leadlist",
    component: <ProspectingNew />,
    isMenu: false,
  },
  {
    key: "/auth",
    route: "/",
    component: <AuthenticationNew />,
    isMenu: false,
  },
  {
    route: '/settings',
    key: '/settings-old',
    component: <PersonaSettings />,
    isMenu: false,
  },
  {
    key: '/prospecting-new',
    label: 'Prospecting',
    icon: <LeadsIcon />,
    iconSelected: <LeadsIcon color="#FAFF13"/>,
    route: '/prospecting-new',
    component: <ProspectingNew />,
    isMenu: true,
    parent: "menu"
  },
  {
    key: '/email',
    label: "Email",
    icon: <EmailMenuIcon />,
    iconSelected: <EmailMenuIcon color="yellow" />,
    disabledIcon: <EmailMenuIcon color="#4F4F4F" />,
    route: "/engage/campaign-list",
    component: <CampaignsList />,
    isMenu: true,
    parent: "email",
    subMenu: true,
    disabled: false,
    subMenuItems: [
        {
          key: "/engage/campaign-list",
          label: "Campaigns",
          icon: <CampaingsIcon />,
          iconSelected: <CampaingsIcon color="#FAFF13" />,
          route: "/engage/campaign-list",
          component: <CampaignsList />,
          isMenu: true,
          parent: "email"
        },
        {
          key: "/engage/campaign-form",
          label: "Campaign Form",
          icon: <CampaingsIcon />,
          route: "/engage/campaign-form",
          component: <CampaignsForm />,
          isMenu: false,
          parent: "email",
        },
        {
          key: "/engage/campaign-details",
          label: "Campaign Details",
          icon: <CampaingsIcon />,
          iconSelected: <CampaingsIcon color="#FAFF13" />,
          route: "/engage/campaign-details/:campaignId",
          component: <CampaignsDetails />,
          isMenu: false,
          parent: "email",
        },
        {
          key: "/engage/inbox",
          label: "Inbox",
          icon: <InboxIcon />,
          iconSelected: <InboxIcon color="#FAFF13"/>,
          route: "/engage/inbox",
          component: <Inbox />,
          isMenu: true,
          parent: "email",
        },
        {
          key: '/engage/analytics',
          label: 'Analytics',
          icon: <AnalyticsIcon />,
          iconSelected: <AnalyticsIcon color="#FAFF13"/>,
          route: '/engage/analytics',
          component: <AnalyticsNew />,
          isMenu: true,
          parent: "email",
        },
        // {
        //   key: '/engage/analytics-new',
        //   label: 'Analytics',
        //   icon: <AnalyticsIcon />,
        //   iconSelected: <AnalyticsIcon color="yellow"/>,
        //   route: '/engage/analytics-new',
        //   component: <AnalyticsNew />,
        //   isMenu: false,
        //   parent: "engage",
        // },
        {
          key: '/engage/accounts',
          label: 'Accounts',
          icon: <AccountsIcon />,
          iconSelected: <AccountsIcon color="#FAFF13"/>,
          route: '/engage/accounts',
          component: <Account />,
          isMenu: true,
          parent: "email"
        },
]},
{
  key: '/tools/linkedIn',
  label: 'LinkedIn',
  icon: <LinkedInWhiteIcon />,
  iconSelected: <LinkedInWhiteIcon color="yellow"/>,
  route: '/tools/linkedIn',
  component: <LinkedinAnalytics />,
  isMenu: false,
  parent: "tools"
},
{
  key: '/tools/linkedIn',
  label: 'LinkedIn',
  icon: <LinkedInWhiteIcon />,
  iconSelected: <LinkedInWhiteIcon color="yellow"/>,
  route: '/tools/linkedIn',
  component: <LinkedinAnalytics />,
  isMenu: true,
  parent: "tools",
  subMenu: true,
  disabled: false,
  subMenuItems: [
    {
      route: '/tools/linkedIn/campaign-creation',
      component: <LinkedinCampaignCreation />,
      key: '/tools/linkedIn/campaign-creation',
      isMenu: false,
    },
    {

      route: '/tools/linkedIn/campaign-edit',
      component: <LinkedinCampaignEdit />,
      key: '/tools/linkedin/campaign-edit',
      isMenu: false,
    },
    {
      route: '/tools/linkedIn/campaign-detail',
      component: <LinkedinCampaignsDetail />,
      key: '/tools/linkedIn/campaign-detail',
      isMenu: false,
    },
    {
      icon: <InboxIcon />,
      iconSelected: <InboxIcon color="yellow"/>,
      disabledIcon: <InboxIcon color="#4F4F4F"/>,
      label: "Inbox",
      route: '/tools/linkedIn/inbox',
      key: '/tools/linkedIn/inbox',
      component: <LinkedinInbox />,
      isMenu: true,
      disabled: true,
    },
    {
      route: '/tools/linkedIn/analytics',
      key: '/tools/linkedIn/analytics',
      component: <AnalyticsIcon />,
      isMenu: false,
    },
    {
      icon: <CampaingsIcon />,
      iconSelected: <CampaingsIcon color="yellow"/>,
      label: "Campaigns",
      route: '/tools/linkedIn/campaign-list',
      component: <LinkedinCampaignsList />,
      key: '/tools/linkedIn/campaign-list',
      isMenu: true,
    },
    {
      icon: <AccountsIcon />,
      iconSelected: <AccountsIcon color="yellow"/>,
      label: "Accounts",
      route: '/tools/linkedIn/accounts',
      key: '/tools/linkedIn/accounts',
      component: <LinkedinAccount />,
      isMenu: true,
    }
  ]
},
{
  key: '/share/analytics-full',
  label: 'Analytics',
  icon: <AnalyticsIcon />,
  route: "/share/analytics-full/:companyId/:userId",
  component: <AnalyticsNew />,
  isMenu: false,
},
{
  key: '/share/analytics',
  label: 'Analytics',
  icon: <AnalyticsIcon />,
  route: "/share/analytics/:campaignId",
  component: <AnalyticsShared />,
  isMenu: false,
}
];

export const toolsMenuHeading = 'TOOLS'

export const toolsMenuItems = [
  {
    key: '/tools/reports',
    label: 'Reports',
    icon: <ReportsIcon color="#4F4F4F"/>,
    iconSelected: <ReportsIcon color="#4F4F4F"/>,
    // route: '/engage/inbox',
    // component: <Inbox />,
    isMenu: true,
    parent: "tools",
    disabled: true,
    comingSoon: true
  },
  {
    key: '/tools/copywriting',
    label: 'Copywriting',
    icon: <CopywritingIcon color="#4F4F4F"/>,
    iconSelected: <CopywritingIcon color="#4F4F4F"/>,
    // route: '/engage/inbox',
    // component: <Inbox />,
    isMenu: true,
    parent: "tools",
    disabled: true,
    comingSoon: true
  },
  {
    key: '/tools/tasks',
    label: 'Tasks',
    icon: <TasksIcon color="#4F4F4F"/>,
    iconSelected: <TasksIcon color="#4F4F4F"/>,
    // route: '/engage/inbox',
    // component: <Inbox />,
    isMenu: true,
    parent: "tools",
    disabled: true,
    comingSoon: true
  },
  {
    key: '/tools/crm',
    label: 'CRM',
    icon: <CrmIcon color="#4F4F4F"/>,
    iconSelected: <CrmIcon color="#4F4F4F"/>,
    // route: '/engage/inbox',
    // component: <Inbox />,
    isMenu: true,
    parent: "tools",
    disabled: true,
    comingSoon: true
  }
  
]

export const adminMenuItems = [];
