import axios from 'axios';
import { API_BASE_URL, AUTH_TOKEN } from './AppConfig';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 300000
});

const refreshToken = async () => {
  try {
    let payload= {
      old_token : localStorage.getItem('authToken')
    }
    const response = await axios.post(`${API_BASE_URL}/login/refresh_token`, payload);
    const { token } = response.data; // Adjust this according to your API response
    localStorage.setItem(AUTH_TOKEN, token);
    return token;
  } catch (error) {
    console.error('Failed to refresh token', error);
    throw error;
  }
};
// Add an interceptor to set the authentication token in the request headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }// 
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        console.error('Token refresh failed', err);
        localStorage.removeItem('authToken');
        localStorage.removeItem('persist:root');
        window.location.assign('/auth');
        return Promise.reject(err);
      }
    } else if (error.response.status === 405) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('persist:root');
      window.location.assign('/auth');
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
