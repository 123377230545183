import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { PiWarningBold } from "react-icons/pi";
import './NotificationCard.css';

const ActionableNotification = ({ id, title, message, button_text, action_url, createdAt, isRead, onDismiss, markAsRead, icon_type }) => {
  const navigate = useNavigate();

  const calculateTime = (createdAt) => {
    return moment.utc(createdAt).local().startOf('minute').fromNow();
  };

  const handleClick = () => {
    markAsRead(id);
    navigate(`${action_url}`);
  };

  const handleDismissClick = () => {
  onDismiss(id);
  };

  const renderIcon = () => {
    switch (icon_type) {
      case 1:
        return (
          <div className="pipeline-icon">
            <img src="/assets/svg/purple-persona-icon.svg" alt="logo" />
          </div>
        );
      case 2:
        return (
          <div className="warning-icon">
            <PiWarningBold className="icon-warning"/>
          </div>
        );
      case 3:
        return (
          <div className="lead-profile-icon">
            <div className="placeholder-icon">
              <span className="text-white">?</span> {/* Placeholder icon */}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`notification ${isRead ? 'bg-read' : 'bg-unread'}`}>

        {renderIcon()}

      <div className="content-container">
        <div className="title">{title}</div>
        <div className="message">{message}</div>
          <div className="buttons">
            <button className="view-button" onClick={handleClick}>{button_text}</button>
            <button className="dismiss-button" onClick={handleDismissClick}>Dismiss</button>
          </div>
          <div className="timestamp">{calculateTime(createdAt)}</div>
      </div>
    </div>
  );

};

export default ActionableNotification;




