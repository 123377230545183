// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* loader.css */
.loader-container {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5);
     display: flex;
     justify-content: center;
     align-items: center;
   }
   
   .loader {
     border: 4px solid #f3f3f3;
     border-top: 4px solid #3498db;
     border-radius: 50%;
     width: 50px;
     height: 50px;
     animation: spin 1s linear infinite;
   }
   
   @keyframes spin {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(360deg);
     }
   }
   `, "",{"version":3,"sources":["webpack://./src/assets/Styles/Loader.css"],"names":[],"mappings":"AAAA,eAAe;AACf;KACK,eAAe;KACf,MAAM;KACN,OAAO;KACP,WAAW;KACX,YAAY;KACZ,oCAAoC;KACpC,aAAa;KACb,uBAAuB;KACvB,mBAAmB;GACrB;;GAEA;KACE,yBAAyB;KACzB,6BAA6B;KAC7B,kBAAkB;KAClB,WAAW;KACX,YAAY;KACZ,kCAAkC;GACpC;;GAEA;KACE;OACE,uBAAuB;KACzB;KACA;OACE,yBAAyB;KAC3B;GACF","sourcesContent":["/* loader.css */\n.loader-container {\n     position: fixed;\n     top: 0;\n     left: 0;\n     width: 100%;\n     height: 100%;\n     background-color: rgba(0, 0, 0, 0.5);\n     display: flex;\n     justify-content: center;\n     align-items: center;\n   }\n   \n   .loader {\n     border: 4px solid #f3f3f3;\n     border-top: 4px solid #3498db;\n     border-radius: 50%;\n     width: 50px;\n     height: 50px;\n     animation: spin 1s linear infinite;\n   }\n   \n   @keyframes spin {\n     0% {\n       transform: rotate(0deg);\n     }\n     100% {\n       transform: rotate(360deg);\n     }\n   }\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
