import React, {useEffect, useRef, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  Layout,
  List,
  Modal,
  notification,
  Progress,
  Result,
  Row,
  Select,
  Spin,
  Switch,
  Upload
} from "antd";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  CaretUpOutlined ,
  CaretDownOutlined 
} from '@ant-design/icons';
import InfiniteScroll from "react-infinite-scroll-component";
import { AnalyticsDocIcon, DownloadIcon, DropdownArrowWhite, DropdownArrow } from "../../assets/svg/Icons";
import axios from "axios";
import axiosInstance from "../../config/axiosConfig";
import { API_BASE_URL, AUTH_TOKEN } from "../../config/AppConfig";

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';

import {
  getChance,
  getWidthPercent,
  getSubjectLength,
  getWordCount,
  getQuestionCount,
  getReadingLevel,
  getSpamWords,
  getNoOfLinks
} from '../../common/copywritingFunctions';

import getLeadCategoryIcon, {getProspectsStatusIcon} from "../../common/getLeadCategoryIcon";

import $ from 'jquery';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import normalizeHtml from "../../common/normalizeHtml";
import insertLineBreak from "../../common/insertLineBreak";
import TimeoutErrorModal from "../../common/TimeoutErrorModal";
import moment from "moment";

const toolbarConfig = {
  toolbarKeys: [
    'insertVariable',
    'insertLink',
    'bold',
    'italic',
    'underline',
    'bulletedList',
    'numberedList',
    'uploadImage'
  ]
};

const emptyScores = {
  score_percentage: 0,
  total_score: 0,
  subject_length: 0,
  word_count: 0,
  question_count: 0,
  reading_level: 0,
  spam_words: 0,
  no_of_links: 0
};

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const { Dragger } = Upload;
const { Option } = Select;

const CampaignsDetails = () => {
  /* Page */
  const navigate = useNavigate();
  const {campaignId} = useParams();
  const shareUrl = window.location.href.substring(0, window.location.href.indexOf("/engage"))
  + "/share/analytics/" + campaignId;

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ?  companyData.user_id : authData.userInfo.userid;
  const isSuperAdmin = authData.userInfo.is_super_admin;
  const isAdmin = authData.userInfo.isAdmin === true ? true : false;
  const editAccess = authData.userInfo.isViewer === false ? true : false;
  const isViewer = authData.userInfo.isViewer === true ? true : false;

  const pageLoaded = useRef(false);
  const isLoading = useRef(false);

  /* Forms */
  const [improveCopyForm] = Form.useForm();
  const [editCopyForm] = Form.useForm();
  const [editProspectsDetailsForm] = Form.useForm();

  /* Analytics */
  const analyticsData = useRef({
    campaign_name: "",
    leads_remaining: "",
    campaign_status: "",
    contacted: 0,
    contacted_percentage: "",
    opened: 0,
    opened_rate: "",
    replied: 0,
    reply_rate: "",
    link_clicks: 0,
    link_clicks_percentage: "",
    opportunities: 0,
    opportunities_percentage: "",
    total_contacted_percentage: "",
    total_opened_rate: "",
    total_sent: 0,
    total_open: 0,
    total_link_clicks_percentage: "",
    total_click: 0
  });
  const analyticsChartData = useRef([]);
  const [menuItems, setMenuItems] = useState([]);

  /* Prospects */
  const [prospectsData, setProspectsData] = useState({total_leads: 0, leads: []});
  const [prospectsFilters, setProspectFilters] = useState([]);
  const selectedProspect = useRef({});
  const importedCsvFile = useRef(null);
  const leadActivity = useRef({});

  /* Sequence */
  const [emailCopies, setEmailCopies] = useState([]);
  const copyWriteScore = useRef({...emptyScores});
  const isNewStep = useRef(false);
  const isNewVariant = useRef(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const inputRef = useRef(null);

  const [editor, setEditor] = useState(null);
  const [html, setHtml] = useState(null);

  let editorConfig = {
    placeholder: 'Type here...',
    MENU_CONF : {}
  }


  /* Settings */
  const settingsEmailAccsUsed = useRef([]);

  /* Page */
  const [loading, setLoading] = useState(1);
  const [tab, setTab] = useState("analytics");

  /* Analytics */
  const [analyticsDefaultFilter, setAnalyticsDefaultFilter] = useState("last_7_days");
  const [analyticsCustomFilter, setAnalyticsCustomFilter] = useState([null, null]);
  const [analyticsDateErrorModalShown, setAnalyticsDateErrorModalShown] = useState(false);
  const [shareModalShown, setShareModalShown] = useState(false);

  /* Prospects */
  const [prospectsPageNo, setProspectsPageNo] = useState(1);
  const [selectedProspectsStatus, setSelectedProspectsStatus] = useState("");
  const [selectedProspectsFilters, setSelectedProspectsFilters] = useState("");
  const [prospectsSearchString, setProspectsSearchString] = useState("");
  const [selectedProspects, setSelectedProspects] = useState([]);
  const [showProspectsDetails, setShowProspectsDetails] = useState(false);
  const [editProspectsDetails, setEditProspectsDetails] = useState(false);
  const [showUploadProspects, setShowUploadProspects] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [sidebarTab, setSidebarTab] = useState("details");
  const [sortType, setSortType] = useState("");
  const [sortOrder, setSortOrder] = useState(true);
  const [importCsvScreen, setImportCsvScreen] = useState(0);
  const csvMappings = useRef({file_id: 0, file_name: "", columns: []});

  /* Sequence */
  const [additionalEmails, setAdditionalEmails] = useState(true);
  const [currentCopy, setCurrentCopy] = useState("1-1");
  const [editCopy, setEditCopy] = useState(false);
  const [improveCopy, setImproveCopy] = useState(false);
  const [copyProgress, setCopyProgress] = useState(false);
  const [improveCopyTone, setImproveCopyTone] = useState("");
  const [improveCopyToneError, setImproveCopyToneError] = useState(false);
  const [improveCopyLength, setImproveCopyLength] = useState("");
  const [improveCopyLengthError, setImproveCopyLengthError] = useState(false);
  const [importLead, setImportLead] = useState(false);
  const [ignoreLead, setIgnoreLead] = useState(false);
  const [newVariantDetails, setNewVariantDetails] = useState(false); 
  const [newvariantStep, setNewvariantStep] = useState(); 
  const [sendTestMail, setSendTestMail] = useState(false); 
  const [testMailId, setTestMailId] = useState(""); 
  const [loadingModal, setLoadingModal] = useState(0); 
  const [selectedVariable, setSelectedVariable] = useState(); 

  /* Settings */
  const [sendingLimit, setSendingLimit] = useState(null);
  const [minTimeBtwEmails, setminTimeBtwEmails] = useState(null);
  const [stopCampaign, setStopCampaign] = useState(false);
  const [defaultAllMail, setDefaultAllMail] = useState(false);
  const [emailAccounts, setEmailAccounts] = useState([]);
  const [emailList, setEmailList] = useState([]);

  editorConfig.MENU_CONF['uploadImage'] = {
    server: `${API_BASE_URL}/engage/upload_image/${userId}`,
    fieldName: 'file',
    timeout: 3 * 100000,
    meta: {
      company_id: companyData.company_id,
      campaign_id: campaignId,
      variant_id: emailCopies[currentCopy.split("-")[0] - 1]?.variants[currentCopy.split("-")[1] - 1]?.id,
      upload_type: "email",
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`
    },

    onSuccess(file, res) {  
      console.log(`${file.name} uploaded`, res)
    },
    onFailed(file, res) { 
      console.log(`${file.name} failed`, res)
    },

    customInsert(res, insertFn) {                  
      insertFn(`${API_BASE_URL}/engage/get_image/${res.file_id}`, "", `${API_BASE_URL}/engage/get_image/${res.file_id}`)
    },

  }

  /* Analytics */
  const getAnalyticsData = async (pageLoad = true) => {
    setLoading(1);

    let time_period, custom_start_date, custom_end_date;

    if (analyticsDefaultFilter) {
      time_period = analyticsDefaultFilter;
    } else if (analyticsCustomFilter[0]) {
      [custom_start_date, custom_end_date] = analyticsCustomFilter;

      custom_start_date = dayjs(custom_start_date).format('YYYY-MM-DD');
      custom_end_date = dayjs(custom_end_date).format('YYYY-MM-DD');
    } else if (!analyticsDefaultFilter && !analyticsCustomFilter[0]) {
      setAnalyticsDefaultFilter("last_7_days");
      time_period = "last_7_days";
    }

    try {
      let url = `/engage/campaign_analytics/${campaignId}?user_id=${userId}`;

      if (time_period) {
        url += `&time_period=${time_period}`;
      }

      if (custom_start_date) {
        url += `&custom_start_date=${custom_start_date}&custom_end_date=${custom_end_date}`;
      }

			const response = await axiosInstance.get(url);
      analyticsData.current = response.data;
      let items = []
      if(response.data.campaign_status === "DRAFTED") {
        items = [         
          {
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src="/assets/images/download-blue-2x.png" width="17" height="auto" alt="icon" />
              <span className="text-white small ps-2">Download as CSV</span>
            </div>
          </div>,
          key: "download"
        },
        {
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src="/assets/images/share-blue-2x.png" width="17" height="auto" alt="icon" />
              <span className="text-white small ps-2">Share Campaign</span>
            </div>
          </div>,
          key: "share"
        },  
        {
          label: (
            <div className="bootstrap">
              <div className="d-flex align-items-center">
              <img src="/assets/svg/delete-red.svg" width="17" height="15" alt="icon" />
                <span className="text-white small ps-2">Delete Campaign</span>
              </div>
            </div>
          ),
          key: "delete"
        }]  
      }
      else {
        items = [         
          {
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src="/assets/images/download-blue-2x.png" width="17" height="auto" alt="icon" />
              <span className="text-white small ps-2">Download as CSV</span>
            </div>
          </div>,
          key: "download"
        },
        {
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src="/assets/images/share-blue-2x.png" width="17" height="auto" alt="icon" />
              <span className="text-white small ps-2">Share Campaign</span>
            </div>
          </div>,
          key: "share"
        }]
      }
      setMenuItems(items)
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Analytics data'});
	  }

    getChartData();
  }

  const getChartData = async () => {
    let time_period, custom_start_date, custom_end_date;

    if (analyticsDefaultFilter) {
      time_period = analyticsDefaultFilter;
    } else if (analyticsCustomFilter[0]) {
      [custom_start_date, custom_end_date] = analyticsCustomFilter;

      custom_start_date = dayjs(custom_start_date).format('YYYY-MM-DD');
      custom_end_date = dayjs(custom_end_date).format('YYYY-MM-DD');
    } else if (!analyticsDefaultFilter && !analyticsCustomFilter[0]) {
      setAnalyticsDefaultFilter("last_7_days");
      time_period = "last_7_days";
    }

    try {
      let url = `/engage/campaign_chart_analytics?campaign_id=${campaignId}&user_id=${userId}`;

      if (time_period) {
        url += `&time_period=${time_period}`;
      }

      if (custom_start_date) {
        url += `&custom_start_date=${custom_start_date}&custom_end_date=${custom_end_date}`;
      }

			const response = await axiosInstance.get(url);
      analyticsChartData.current = response.data;
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Analytics chart data'});
	  }

    setLoading(0);
  }

  const getTextColor = percentage => {
    if (!percentage) return;
    let className;

    if (percentage.indexOf("-") === 0) {
      className = "text-danger";
    } else {
      className = "text-success";
    }

    return className;
  }

  const getBatteryLevel = score => {
    let level = "full";

    if (score === 0 ) {
      level = "empty";
    } else if (score <= 25) {
      level = "low";
    } else if (score <= 50) {
      level = "half";
    } else if (score <= 90) {
      level = "high";
    }

    return level;
  }

  const updateCampaignStatus = async (id, toggle) => {
    setLoading(1);

    const payload = {
      user_id: userId,
      status: toggle ? "START" : "PAUSED"
    }

    try {
			await axiosInstance.put(`/engage/update_campaign_status/${id}`, payload);
      notification.success({message: 'Success', description: 'Campaign has been updated successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in updating the Campaign'});
	  }

    getAnalyticsData(false);
  }

  const gotoList = () => {
    setShowTimeoutModal(false);
    navigate("/engage/campaign-list");
  }

  const zetAnalyticsDefaultFilter = value => {
    setAnalyticsCustomFilter([null, null]);
    setAnalyticsDefaultFilter(value);
  }

  const zetAnalyticsCustomFilter = dates => {
    let date;

    if (!dates) {
      date = [null, null];
    } else {
      date = [dayjs(dates[0].$d).format('YYYY-MM-DD'), dayjs(dates[1].$d).format('YYYY-MM-DD')];

      const dateDiff = dayjs(date[1]).diff(date[0], 'day');
      if (dateDiff > 365) {
        dates = [null, null];
        setAnalyticsDateErrorModalShown(true);
      }
    }

    setAnalyticsDefaultFilter(null);
    setAnalyticsCustomFilter(dates);
  }

  const closeAnalyticsDateErrorModal = () => {
    setAnalyticsDateErrorModalShown(false);
  }

  const shareMenuAction = ({key}) => {
    if (key === "share") {
      setShareModalShown(true);
    } 
    else if(key === "delete") {
      deleteCampaign()
    }
    else {
      downloadAllProspects();
    }
  }

  const closeShareModal = () => {
    setShareModalShown(false);
  }

  const copyShareUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    notification.success({message: 'Success', description: 'Share link has been copied successfully'});
  }

  /* Prospects */

  const csvPeopleOptions = [
    {
      label: <div className="bootstrap"><div className="d-flex align-items-center">
        <img src="/assets/images/csv-mapping-icons/name-2x.png" width="15" height="auto" alt="icon" />
        <span className="ps-2">First Name</span>
      </div></div>,
      value: "First Name"
    },
    {
      label: <div className="bootstrap"><div className="d-flex align-items-center">
      <img src="/assets/images/csv-mapping-icons/name-2x.png" width="15" height="auto" alt="icon" />
      <span className="ps-2">Last Name</span>
    </div></div>,
      value: "Last Name"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/job-title-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Role</span>
        </div>
      </div>,
      value: "Role"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/name-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Company</span>
        </div>
      </div>,
      value: "Company"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/linkedin-url-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Website</span>
        </div>
      </div>,
      value: "Website"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/location-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Location</span>
        </div>
      </div>,
      value: "Location"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/email-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Email</span>
        </div>
      </div>,
      value: "Email"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/linkedin-url-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Linkedin URL</span>
        </div>
      </div>,
      value: "Linkedin URL"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/do-not-include-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Do Not Include</span>
        </div>
      </div>,
      value: "Do Not Include"
    }
  ]

  const getCampaignProspects = async (freshLoad = true) => {
    if (prospectsData.leads.length) {
      isLoading.current = true;
    }
    
    // setLoading(1);

    try {
      let url = `/engage/campaign_prospects/${campaignId}`;
      url += `?user_id=${userId}&page=${prospectsPageNo}&limit=25`;

      if (selectedProspectsStatus.length) {
        url += `&status=${selectedProspectsStatus}`
      }

      if (selectedProspectsFilters.length) {
        url += `&lead_category_id=${selectedProspectsFilters}`
      }

      if (prospectsSearchString.length) {
        url += `&search=${prospectsSearchString}`;
      }

      if (sortType != "") {
        let sortBy = sortOrder === true ? "asc" : "desc"
        url += `&sort_by=${sortType}&sort_order=${sortBy}`;
      }

			const response = await axiosInstance.get(url);

      const fetchedLeads = response.data.leads;
      
      const oldLeads = [...prospectsData.leads];

      const {data} = response;
      if (prospectsSearchString.length) {
        data.leads = [...fetchedLeads];
      }
      else {
        data.leads = [...oldLeads, ...fetchedLeads];
      }

      setProspectsData({...data});

		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Prospects data'});
	  }

    isLoading.current = false;

    if (freshLoad) {
      getProspectFilters();
    } else {
      // setLoading(0);
    }
  }

  const getProspectFilters = async () => {
    try {
      const filters = [];
			const response = await axiosInstance.get(`/engage_inbox/lead_categories`);

      response.data.forEach(filter => {
        const row = {
          key: filter.id,
          value: filter.id,
          name: filter.name,
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src={`/assets/images/${getLeadCategoryIcon(filter.name)}`} width="16" height="auto" alt="icon" />
              <span className="ps-2">{filter.name}</span>
            </div>
          </div>
        }

        filters.push(row);
      });

      const statusRows = [
        {
          key: "STARTED",
          value: "STARTED",
          name: "STARTED",
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src={getProspectsStatusIcon("STARTED")} width="16" height="auto" alt="icon" />
              <span className="ps-2">STARTED</span>
            </div>
          </div>
        },
        {
          key: "COMPLETED",
          value: "COMPLETED",
          name: "COMPLETED",
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src={getProspectsStatusIcon("COMPLETED")} width="16" height="auto" alt="icon" />
              <span className="ps-2">COMPLETED</span>
            </div>
          </div>
        },
        {
          key: "INPROGRESS",
          value: "INPROGRESS",
          name: "INPROGRESS",
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src={getProspectsStatusIcon("INPROGRESS")} width="16" height="auto" alt="icon" />
              <span className="ps-2">INPROGRESS</span>
            </div>
          </div>
        },
        {
          key: "STOPPED",
          value: "STOPPED",
          name: "STOPPED",
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src={getProspectsStatusIcon("STOPPED")} width="16" height="auto" alt="icon" />
              <span className="ps-2">STOPPED</span>
            </div>
          </div>
        },
        {
          key: "BLOCKED",
          value: "BLOCKED",
          name: "BLOCKED",
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src={getProspectsStatusIcon("BLOCKED")} width="16" height="auto" alt="icon" />
              <span className="ps-2">BLOCKED</span>
            </div>
          </div>
        },
        {
          key: "PAUSED",
          value: "PAUSED",
          name: "PAUSED",
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src={getProspectsStatusIcon("PAUSED")} width="16" height="auto" alt="icon" />
              <span className="ps-2">PAUSED</span>
            </div>
          </div>
        }
      ]

      setProspectFilters([...filters, ...statusRows]);
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Prospect Filters'});
	  }

    setLoading(0);
  }

  const setProspectsFilters = ({key}) => {
    setLoading(1);

    if (isNaN(key)) {
      setSelectedProspectsFilters("");
      setLoading(0);

      if (key === selectedProspectsStatus) {
        setSelectedProspectsStatus("");
      } else {
        setSelectedProspectsStatus(key);
        setProspectsData({total_leads: 0, leads: []})
        setProspectsPageNo(1)
      }
    } else {
      setSelectedProspectsStatus("");
      setLoading(0);

      if (key === selectedProspectsFilters) {
        setSelectedProspectsFilters("");
      } else {
        setSelectedProspectsFilters(key);
        setProspectsData({total_leads: 0, leads: []})
        setProspectsPageNo(1)
      }
    }
  }

  const loadMoreProspects = () => {
    const loadProspectsInterval = setInterval(() => {
      if (isLoading.current === false) {
        clearInterval(loadProspectsInterval);
        setProspectsPageNo(prospectsPageNo => prospectsPageNo + 1);
      }
    }, 500);
  }

  const triggerProspectsSearch = () => {
    const searchValue = document.querySelector("#prospects-search-input").value;

    if (!searchValue || searchValue.length < 1) {
      setProspectsSearchString("");
    } else {
      setProspectsSearchString(searchValue);
    }
  }

  const setProspectsSelection = () => {
    if (selectedProspects.length === prospectsData.leads.length) {
      setSelectedProspects([]);
    } else {
      const selection = [];

      prospectsData.leads.forEach(lead => {
        selection.push(lead.lead_id);
      });

      setSelectedProspects([...selection]);
    }
  }

  const setProspectsSelectionById = id => {
    const currentSelection = [...selectedProspects];
    const index = currentSelection.indexOf(id);

    if (index > -1) {
      currentSelection.splice(index, 1);
    } else {
      currentSelection.push(id);
    }

    setSelectedProspects([...currentSelection]);
  }

  const downloadAllProspects = async (all = true) => {
    setLoading(1);

    try {
      let payload = null;

      if (all === false) {
        payload = [...selectedProspects];
      }

      const response = await axiosInstance.post(`/engage/download_leads_by_id/${campaignId}?user_id=${userId}`, payload);

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', (all ? "all_leads" : "selected_leads") + ".csv");
        document.body.appendChild(link);

        link.click();
        link.remove();
      }

      notification.success({message: 'Success', description: 'Prospects have been downloaded successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in downloading the prospects'});
	  }

    setLoading(0);
  }

  const deleteCampaign = async () => {
    setLoading(1);

    try {
      await axiosInstance.put(`/v2_engage/delete_campaign/${campaignId}?user_id=${userId}`);
      notification.success({message: 'Success', description: 'Campaign deleted successfully'});
      navigate("/engage/campaign-list");
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in deleting the campaign'});
	  }

    setLoading(0);
  }

  const downloadSelectedProspects = () => {
    downloadAllProspects(false);
  }

  const deleteProspects = async () => {
    // setLoading(1);
    const allLeads = [...prospectsData.leads];

    try {
      const payload = [...selectedProspects];
      await axiosInstance.delete(`/engage/delete_leads_by_id/?user_id=${userId}`,{data: payload});

      const existingLeads = allLeads.filter(lead => payload.includes(lead.lead_id) === false);
      prospectsData.leads = [...existingLeads];

      notification.success({message: 'Success', description: 'Selected Prospects have been deleted successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in deleting the prospects'});
	  }

    setSelectedProspects([]);
    // setLoading(0);
  }

  const viewProspectDetails = (lead, index) => {
    editProspectsDetailsForm.setFieldsValue({
      first_name: lead.contact,
      email: lead.email,
      phone_number: lead.phone,
      website: lead.website,
      role: lead.job_title,
      company_name: lead.company,
      linkedin_profile: lead.linkedin
    });

    selectedProspect.current = {...lead, index};
    setShowProspectsDetails(true);
  }

  const saveProspectsDetails = async values => {
    setShowProspectsDetails(false);
    setLoading(1);

    try {
      const payload = {...values};
      payload.user_id = userId;
      payload.last_name = "";

      await axiosInstance.put(`/engage/leads_edit/${selectedProspect.current.lead_id}`, payload);
      notification.success({message: 'Success', description: 'Lead details have been edited successfully'});
		} catch (error) {
      setShowProspectsDetails(true);
      notification.error({message: 'Error', description: 'Error in editing the lead details'});
	  }

    const editedLead = {...prospectsData.leads[selectedProspect.current.index]};
    editedLead.email = values.email;
    editedLead.contact = values.first_name;
    editedLead.company = values.company_name;
    editedLead.job_title = values.role;
    editedLead.phone = values.phone_number;
    editedLead.website = values.website;
    editedLead.linkedin = values.linkedin_profile;

    prospectsData.leads[selectedProspect.current.index] = {...editedLead};

    setEditProspectsDetails(false);
    setLoading(0);
  }

  const dummyUpload = async ({onSuccess}) => {
    onSuccess("Ok");
  }

  const csvDraggableProps = {
    name: 'csv_file',
    multiple: false,
    maxCount: 1,
    customRequest: dummyUpload,
    beforeUpload (file) {
      setLoading(1);
      importedCsvFile.current = file;

      setLoading(0);
    },
    /*
    onDrop (e) {
      setLoading(1);
      importedCsvFile.current = e.dataTransfer.files[0];

      setLoading(0);
    },
    */
  };

  const csvUpload = async () => {
    setLoading(1);

    const data = {
      campaign_id : campaignId,
      remove_duplicate : ignoreLead,
      remove_blocklist : importLead
    }

    const formData = new FormData();
    formData.append('file', importedCsvFile.current);
    formData.append('data', JSON.stringify(data));

    const config = {
      method: 'post',
      baseURL: API_BASE_URL,
      url:  `/prospect/upload_csv/${userId}?data_type=people&company_id=${companyData.company_id}`,
      headers: {
        Authorization: `Bearer ${authData.token}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    };

    const res = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        if (err.request || err.response) {
          console.error(err);
          return false;
        } else {
          return null;
        }
      });
    if (res) {
      csvMappings.current = {...res, file_name: importedCsvFile.current.name};

      notification.success({message: 'Success', description: 'File has been uploaded successfully'});
      setImportCsvScreen(2);
    } else if (res === false) {
      notification.error({message: 'Error', description: 'Error in Uploading the file'});
    } else {
      setShowTimeoutModal(true);
    }

    setLoading(0);
  }

  const postCsvMappings = async values => {
    setLoading(1);
    const data = {
      file_id: csvMappings.current.file_id,
      mapping: values
    }

    try {
      await axiosInstance.post(`/prospect/column_mapping/${userId}`, data);
      await enrichLeads(data.file_id);

      // notification.success({message: 'Success', description: 'Column mappings have been saved successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in Saving the mappings'});
	  }
    setLoading(0);
   
  }

  const enrichLeads = async fileId => {
    const data = {
      file_id: Number(fileId),
      campaign_id : campaignId,
      remove_duplicate : ignoreLead,
      remove_blocklist : importLead
    }

    try {
      const res = await axiosInstance.post(`/engage/enrich_csv/${userId}`, data);   
      // if (res.data.missed_list.length > 0) {
      //   if(res.data.missed_type === "email_address") {
      //   Modal.confirm({
      //     title: <div style={{ color: "#ffffff" }}>Add prospects</div>,
      //     content: (
      //       <div style={{ color: "#ffffff" }}>
      //         <div>
      //           Following prospects could not be enriched. Do you still wish to add them to the campaigns?
      //         </div>
      //         <div>
      //           <ol>
      //             {res.data.missed_list.map((email, index) => (
      //               <li key={index}>{email}</li>
      //             ))}
      //           </ol>
      //         </div>
      //       </div>
      //     ),
      //     okText: 'OK',
      //     cancelText: 'Cancel',
      //     onOk: async () => {
      //       try {
      //         const payload= {
      //           campaign_id: campaignId,
      //           company_id: companyData.company_id,
      //           emails_list: res.data.missed_list,
      //           file_id: res.data.file_id
      //         }
      //         const response = await axiosInstance.post(`/engage/add_lead/${userId}`, payload);
      //         if (response.status === 200) {
      //           setTimeout(async () => {
      //             setProspectsPageNo(1)
      //             setProspectsData({ total_leads: 0, leads: [] })
      //             getCampaignProspects();
      //             notification.success({ message: 'Success', description: 'Leads added successfully' });
      //           }, 1000);           
      //         }
      //         else {
      //           notification.error({message: 'Error', description: 'Error in adding leads'});
      //         }
      //       } catch (error) {
      //         notification.error({message: 'Error', description: 'Error in adding leads'});
      //       }
      //     },
      //   });
      // } else if(res.data.missed_type === "linkedin_URL"){
      //   Modal.info({
      //     title: <div style={{ color: "#ffffff" }}>LinkedIn URLs</div>,
      //     content: (
      //       <div style={{ color: "#ffffff" }}>
      //         <div>
      //           No emails were found for the following leads so they were removed from your lead list.
      //         </div>
      //         <div>
      //           <ol>
      //             {res.data.missed_list.map((email, index) => (
      //               <li key={index}>{email}</li>
      //             ))}
      //           </ol>
      //         </div>
      //       </div>
      //     ),
      //     okText: 'OK',
      //   });
      // }
      // } 
      // else {
          notification.success({message: 'Success', description: 'Leads are being uploaded in the background'});
          // getCampaignProspects();
      // } 
      setImportCsvScreen(0);
      setShowUploadProspects(false);
		} catch (error) {
      // setShowTimeoutModal(true)
	  }

    setLoading(0);
  }

  const triggerPostCsv = () => {
    document.querySelector('#post-csv-btn').click();
  }

  const closeTimeoutModal = () => {
    setShowTimeoutModal(false);
  }

  /* Sequence */
  const getCampaignSequence = async () => {
    setLoading(1);

    try {
			const response = await axiosInstance.get(`/engage/campaign_sequence/${campaignId}?user_id=${userId}`);
      setEmailCopies(response.data)
      let sequences = response.data
      if(sequences?.length === 1 && sequences[0]?.variants.length === 1 && 
        sequences[0]?.variants[0].variant_message === "" && sequences[0]?.variants[0].variant_subject === "" && sequences[0]?.variants[0].variant_id === null) {
          setCurrentCopy("1-1")
          setEditCopy(true)
          initiateEditCopy(sequences[0]?.seq_id, sequences[0]?.variants[0])
        }
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Sequence data'});
	  }

    setLoading(0);
  }

  const addVariant = async copyNo => {
    if (editCopy) {
      setEditCopy(false);
    }

    const copyIndex = copyNo - 1;

    const copies = [...emailCopies];
    const variants = [...copies[copyIndex].variants];

    const newVariantNo = variants.length + 1;

    const newVariant = {
      variant_id: 0,
      variant_message: "",
      id: 0,
      variant_name: `Variant ${newVariantNo}`,
      variant_subject: "",
      score: {...emptyScores}
    }

    variants.push(newVariant);
    copies[copyIndex].variants = [...variants];
    setEmailCopies([...copies]);

    setCurrentCopy(copyNo + "-" + newVariantNo);
    setNewvariantStep(copyIndex)
    setNewVariantDetails(copies[copyIndex])
    setLoading(1);

    setTimeout(() => {
      initiateEditCopy(copies[copyIndex].seq_id, newVariant, true);
      setLoading(0);
    }, 500);
  }

  const addEmailStep = () => {
    setLoading(1);
    const newCopyNo = emailCopies.length + 1;
    setNewvariantStep(emailCopies.length)
    const newStep = {
      engage_campaign_id: campaignId,
      delay_in_days: 1,
      variants: [
        {
          variant_id: 0,
          variant_message: "",
          id: 0,
          variant_name: `Variant 1`,
          variant_subject: "",
          score: {...emptyScores}
        }
      ]
    };
    setNewVariantDetails(newStep)
    setEmailCopies([...emailCopies, newStep]);
    setCurrentCopy(newCopyNo + "-1");

    setTimeout(() => {
      initiateEditCopy(0, newVariant, true);
      setLoading(0);
    }, 500);
  }

  const triggerEditCopy = () => {
    document.querySelector("#edit-copy-btn-" + currentCopy).click();
  }

  const destroyWangEditor = () => {
    if (!editor) return;

    $('.wang-editor').off();
    const $toolbar = $('.wang-copywriting-toolbar');
    const toolbarLength = $toolbar.length;

    if (toolbarLength) {
      $toolbar.first().remove();
    }

    editor.destroy();
    
    setEditor(null);
    setHtml("");
  }

  const initiateEditCopy = (seqId, variant, action = null) => {
    editCopyForm.setFieldsValue({
      seq_id: seqId,
      var_id: variant.id,
      variant_name: variant.variant_name,
      subject: variant.variant_subject,
      body: variant.variant_message,
    });

    if (action === null) {
      action = !editCopy;
      isNewVariant.current = false;
    } else {
      isNewVariant.current = true;
    }

    if (action) { // open
      destroyWangEditor();
      setHtml(normalizeHtml(variant.variant_message));
    }

    if (seqId === 0) {
      isNewStep.current = true;
    } else {
      isNewStep.current = false;
    }

    setEditCopy(action);
  }

  const setHtmlContent = (content, text) => {
    const value = text.length ? content : "";

    let editValue = value
    let tempElement = document.createElement('div');
    tempElement.innerHTML = editValue;
    let imgTags = tempElement.getElementsByTagName('img');
    for (let i = 0; i < imgTags.length; i++) {
      let imgTag = imgTags[i];
      if (!imgTag.style.width) {
        imgTag.style.width = '100%';
      }
    }
    editValue = tempElement.innerHTML;

    editCopyForm.setFieldValue("body", editValue);
    if(isNewVariant.current === true) {
      let details = newVariantDetails
      let lastIndex = newVariantDetails?.variants?.length -1 
      if(lastIndex >= 0) {
      details.variants[lastIndex].variant_message = editValue
      }
    }
    setHtml(editValue);
  }

  const newStep = async (values, newVarDetails) => {
    const newCopyNo = emailCopies.length + 1;
    let payload = {} 
    if(values !== null && values !== undefined) {
      payload = {
      subject: values.subject || "",
      body: values.body.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      campaign_id: campaignId,
      seq_label: "Step " + newCopyNo,
      save_seq: true
    }
  }
  else {
    payload = {
      subject: newVarDetails.variants[0].variant_subject || "",
      body:  newVarDetails.variants[0].variant_message.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      campaign_id: campaignId,
      seq_label: "Step " + newCopyNo,
      save_seq: true
    }
  }

    try {
      let url;
      if(analyticsData.current.campaign_status === "DRAFTED") {
        url=`/v2_engage/add_new_variant/${userId}?company_id=${companyData.company_id}`
        payload.new_step = true
        payload.variant_label= "Variant 1"
        payload.seq_label= "Step " + emailCopies.length
        payload.delay_in_days = 1
      }
      else {
        url=`/engage/add_step/${userId}`
      }
			const response = await axiosInstance.post(url, payload);
      setEmailCopies(response.data);

      notification.success({message: 'Success', description: 'Step has been added successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in adding the Step'});
	  }

    setEditCopy(false);
    setLoading(0);
  }

  const newVariant = async (values, newVarDetails) => {
    if (Number(values?.seq_id) === 0 || newVariantDetails.seq_id === null || newVariantDetails.seq_id === undefined) {
      newStep(values, newVarDetails);
      return;
    }

    let payload = {}
    if(values !== null && values !== undefined) {
      payload = {
      subject: values.subject,
      body: values.body.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      seq_id: values.seq_id,
      variant_label: values.variant_name,
      save_seq: true
    }
  }
  else {
    let index = newVarDetails.variants.length -1
    payload = {
      subject: newVarDetails.variants[index].variant_subject,
      body: newVarDetails.variants[index].variant_message.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      seq_id: newVarDetails.seq_id,
      variant_label: newVarDetails.variants[index].variant_name,
      save_seq: true
  }
}

    try {
      let url;
      if(analyticsData.current.campaign_status === "DRAFTED") {
        url=`/v2_engage/add_new_variant/${userId}?company_id=${companyData.company_id}`
        payload.new_step = false
        payload.seq_label= values.seq_label
        payload.campaign_id= campaignId
        payload.delay_in_days = 0
      }
      else {
        url=`/engage/add_variant/${userId}`
      }
			const response = await axiosInstance.post(url, payload);
      setEmailCopies(response.data);

      notification.success({message: 'Success', description: 'Variant has been added successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in adding the Variant'});
	  }

    setEditCopy(false);
    setLoading(0);
  }

  const deleteStep = async (index, id) => {
    Modal.confirm({
      title: (<div style={{ color: "#ffffff" }}>Delete step?</div>),
      content: (<div style={{ color: "#ffffff" }}>Are you sure you  want to delete this step?</div>),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: async () => {
        setEditCopy(false);
        setLoading(1);

        try {
          let url;
          if(analyticsData.current.campaign_status === "DRAFTED") {
              url=`/v2_engage/delete_new_sequence/${id}?user_id=${userId}`
          }
          else {
            url = `/engage/delete_sequence/${id}?user_id=${userId}`
          }
          await axiosInstance.put(url);
          notification.success({ message: 'Success', description: 'Sequence has been deleted successfully' });

          const copies = [...emailCopies];
          copies.splice(index, 1);
          setEmailCopies([...copies]);

          let lastCopyIndex = copies.length;
          let lastVariantIndex;

          if (lastCopyIndex < 1) {
            setCurrentCopy("");
          } else {
            const variantsLength = copies[lastCopyIndex - 1].variants.length;
            lastVariantIndex = variantsLength;

            setCurrentCopy(lastCopyIndex + "-" + lastVariantIndex);
          }
        } catch (error) {
          notification.error({ message: 'Error', description: 'Error in deleting the Sequence' });
        }

        setLoading(0);
      }
    })
  }

  const deleteVariant = async (copyIndex, variantIndex, id, enable) => {
    Modal.confirm({
      title: (<div style={{ color: "#ffffff" }}>Confirm</div>),
      content: (<div style={{ color: "#ffffff" }}>Are you sure about {enable? "enabling" : "disabling"} the variant?</div>),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: async () => {
        setEditCopy(false);
        setLoading(1);

        const copies = [...emailCopies];
        const variants = [...copies[copyIndex].variants];

        try {
          if (id > 0) {
            let url;
            if (analyticsData.current.campaign_status === "DRAFTED") {
              url = `/v2_engage/delete_new_variant/${id}?user_id=${userId}&enable_variant=${enable}`
            }
            else {
              url = `/engage/delete_variant/${id}?user_id=${userId}&enable_variant=${enable}`
            }
            await axiosInstance.put(url);
          }

          variants[variantIndex].row_status = enable
          copies[copyIndex].variants = [...variants];
          const isAnyRowStatusTrue = copies[copyIndex]?.variants?.some(variant => variant.row_status === true);
          if (isAnyRowStatusTrue) {
            setEmailCopies([...copies]);
            setCurrentCopy((copyIndex+1) + "-" + (variantIndex+1));
          } else {
            copies.splice(copyIndex, 1)
            setEmailCopies([...copies]);
            setCurrentCopy("1-1")
          }
          
          if(enable) {
          notification.success({ message: 'Success', description: 'Variant has been enabled successfully' });
          }else {
            notification.success({ message: 'Success', description: 'Variant has been disabled successfully' });
          }
        // let lastCopyIndex = copies.length;
        // let lastVariantIndex = variants.length;

        // if (lastVariantIndex < 1) {
        //   lastCopyIndex = lastCopyIndex - 1;

        //   if (lastCopyIndex < 1) {
        //     setCurrentCopy("");
        //   } else {
        //     const variantsLength = copies[lastCopyIndex - 1].variants.length;
        //     lastVariantIndex = variantsLength;

        //     setCurrentCopy(lastCopyIndex + "-" + lastVariantIndex);
        //   }
        // } else {
        //   setCurrentCopy(lastCopyIndex + "-" + lastVariantIndex);
        // }
        
          
        } catch (error) {
          if(enable) {
            notification.error({ message: 'Error', description: 'Error in enabling the Variant' });
            }else {
              notification.error({ message: 'Error', description: 'Error in disabling the Variant' });
            }
          
        }

        // let lastCopyIndex = copies.length;
        // let lastVariantIndex = variants.length;

        // if (lastVariantIndex < 1) {
        //   lastCopyIndex = lastCopyIndex - 1;

        //   if (lastCopyIndex < 1) {
        //     setCurrentCopy("");
        //   } else {
        //     const variantsLength = copies[lastCopyIndex - 1].variants.length;
        //     lastVariantIndex = variantsLength;

        //     setCurrentCopy(lastCopyIndex + "-" + lastVariantIndex);
        //   }
        // } else {
        //   setCurrentCopy(lastCopyIndex + "-" + lastVariantIndex);
        // }

        setLoading(0);
      }
    })
  }

  const editVariant = async (values, newVarDetails ) => {
    setLoading(1);

    if (isNewVariant.current || values.var_id === 0) {
      if(newVarDetails !== null && newVarDetails !== undefined) {
        newVariant(values, newVarDetails)
      }
      else {
        newVariant(values);
      }
      return;
    }

    const payload = {
      subject: values.subject,
      body: values.body.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      edit_smartlead_sequence: true,
      user_id: userId
    }

    try {
      let url;
      if(analyticsData.current.campaign_status === "DRAFTED") {
        url=`/v2_engage/edit_new_variant/${values.var_id}`
      }
      else {
        url = `/engage/edit_variant/${values.var_id}`
      }
			await axiosInstance.put(url, payload);
      let [copyIndex, variantIndex] = currentCopy.split("-");

      copyIndex = Number(copyIndex) - 1;
      variantIndex = Number(variantIndex) - 1;

      const copies = [...emailCopies];
      const variants = [...copies[copyIndex].variants];

      const editedVariant = {...variants[variantIndex]};
      editedVariant.variant_subject = payload.subject;
      editedVariant.variant_message = payload.body;
            
      variants[variantIndex] = {...editedVariant};
      copies[copyIndex].variants = [...variants];
      setEmailCopies([...copies]);

      notification.success({message: 'Success', description: 'Variant has been edited successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in editing the Variant'});
	  }

    setEditCopy(false);
    setSelectedVariable("")
    setLoading(0);
  }

  const triggerUpdateSequence = () => {
    document.querySelector("#sequence-update-btn").click();
  }
 
  const updateSequence = async values => {
    setLoading(1);

    const payload = {user_id: userId};
    const sequences = [];
    const seqLength = emailCopies.length;
    for (let a = 0; a < seqLength; a++) {
      sequences.push({
        seq_id: emailCopies[a].seq_id,
        delay_days: Number(values["delay_days_" + a])
      }) 
    }

    payload.sequences = sequences;

    try {
      let url;
      if(analyticsData.current.campaign_status === "DRAFTED") {
        url=`/v2_engage/update_sequence_delay_days/${campaignId}`
      }else {
        url= `/engage/campaign_sequence/${campaignId}`
      }
      await axiosInstance.put(url, payload);
      notification.success({message: 'Success', description: 'Sequence has been updated successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in updating sequence'});
	  }

    setLoading(0);
  }

  const triggerRegenerateFormSubmit = () => {
    if (improveCopyTone.length < 1) {
      setImproveCopyToneError(true);
    } else {
      setImproveCopyToneError(false);
    }
    
    if (improveCopyLength.length < 1) {
      setImproveCopyLengthError(true);
    } else {
      setImproveCopyLengthError(false);
    }

    document.querySelector(`#regenerate-form-btn`).click();
  }

  const improveVariant = async values => {
    setEditCopy(false);
    
    if (improveCopyToneError || improveCopyLengthError) {
      return;
    }

    setLoading(1);

    const payload = {
      tone: improveCopyTone,
      length: improveCopyLength,
      cta: values.cta,
      user_input: values.user_input,
      edit_smartlead_sequence: true,
      user_id: userId
    }

    try {
      let [copyIndex, variantIndex] = currentCopy.split("-");

      copyIndex = Number(copyIndex) - 1;
      variantIndex = Number(variantIndex) - 1;

      const copies = [...emailCopies];
      const variants = [...copies[copyIndex].variants];

      const improvedVariant = {...variants[variantIndex]};

			const response = await axiosInstance.put(`/engage/improve_variant/${improvedVariant.id}`, payload);
      notification.success({message: 'Success', description: 'Variant has been improved successfully'});

      improvedVariant.variant_subject = response.data.variant_subject;
      improvedVariant.variant_message = response.data.variant_message;
            
      variants[variantIndex] = {...improvedVariant};
      copies[copyIndex].variants = [...variants];
      setEmailCopies([...copies]);

      setImproveCopyTone("");
      setImproveCopyToneError(false);
      setImproveCopyLength("");
      setImproveCopyLengthError(false);
      improveCopyForm.resetFields();
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in improving the Variant'});
    }

    setLoading(0);
  }

  const sendTestEmail = async () => {
    setLoadingModal(1)
    try {
      let copyIndex = Number(currentCopy.split("-")[0]) - 1
      let variantIndex = Number(currentCopy.split("-")[1]) - 1

      let payload = {
        company_id : companyData.company_id,
        campaign_id: campaignId,
        sequence_id: emailCopies[copyIndex].variants[variantIndex].variant_id,
        test_email: testMailId
      };

      const response = await axiosInstance.post(`/engage/send/test_mail/${userId}`, payload);
      notification.success({message: 'Success', description: 'Test Email has been sent successfully'});
    }
    catch(error) {
      notification.error({message: 'Error', description: 'Error in sending Test Email'});
    }
    setLoadingModal(0)
    setSendTestMail(false)
    setTestMailId("")
  }
  const dropdownOptions = [
   {label : "First Name", value: "{{first_name}}"},
   {label : "Last Name", value: "{{last_name}}"},
   {label : "Email", value: "{{email}}"},
   {label : "Phone Number", value: "{{phone_number}}"},
   {label : "Company Name", value: "{{company_name}}"},
   {label : "Website", value: "{{website}}"},
   {label : "LinkedIn Profile", value: "{{linkedin_profile}}"},
   {label : "Location", value: "{{location}}"},
   {label : "Role", value: "{{role}}"},
   {label : "Day of week", value: "{{sl_day_of_week}}"},
   {label : "Time of Day", value: "{{sl_time_of_day}}"},
   {label : "Time of Day Cap", value: "{{sl_time_of_day_cap}}"},
  ];

  const handleInputChange = (e) => {
    if (isNewVariant.current === true && newVariantDetails) {
      let details = newVariantDetails;
      let lastIndex = newVariantDetails.variants.length - 1;
      details.variants[lastIndex].variant_subject = e.target.value;
    }
  };
  const handleInputClick = (event) => {
    setCursorPosition(event.target.selectionStart);
  };

  const handleDropdownChange = (value) => {
    setSelectedVariable(value)
    const currentInputValue = inputRef.current.input.value;
    const newValue = 
      currentInputValue.slice(0, cursorPosition) +
      value +
      currentInputValue.slice(cursorPosition);
    editCopyForm.setFieldValue('subject', newValue)
    setCursorPosition(cursorPosition + value.length);

    // Move the cursor to the end of the inserted text
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(cursorPosition + value.length, cursorPosition + value.length);
    }, 0);
  };

  /* Settings */
  const gotoAccounts = () => {
    navigate("/engage/accounts?campaign_id=" + campaignId);
  }

  const getCampaignSettings = async () => {
    setLoading(1);

    try {
			const response = await axiosInstance.get(`/engage/campaign_settings/${campaignId}?user_id=${userId}`);
      const {min_time_btw_emails, max_new_leads_per_day, email_accounts_used, default_all_email} = response.data;

      settingsEmailAccsUsed.current = email_accounts_used;
      setSendingLimit(max_new_leads_per_day);
      setminTimeBtwEmails(min_time_btw_emails);
      setDefaultAllMail(default_all_email)
      setStopCampaign(response.data?.pause_campaign_reply === true ? true : false)
      let selectedEmailAccounts = email_accounts_used.map(i => i.account_id)
      setEmailAccounts(selectedEmailAccounts)
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Settings data'});
	  }

    setTimeout(() => setLoading(0), 500);
  }

  const updateCampaignSettings = async (is_email) => {
    setLoading(1);

    const payload = {
      min_time_btw_emails: 10,
      max_new_leads_per_day: 1000,
      user_id: userId,
      auto_pause: stopCampaign,
      default_all_email: defaultAllMail,
      email_accounts_used: emailAccounts,
      is_update_email: is_email
    };

    try {
      const response = await axiosInstance.put(`/engage/campaign_settings/${campaignId}`, payload);
      if(response?.data?.status === 200) {
        notification.success({message: 'Success', description: 'Setting has been updated successfully'});
      }
      else {
        notification.error({message: 'Error', description: response?.data?.message});
      }
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in updating Setting'});
	  }

    setLoading(0);
  }

  const getAccountsList = async () => {
    try {
      const response = await axiosInstance.get(`/engage/accounts_list/${userId}?company_id=${companyData.company_id}&inbox_accounts=true`);
      setEmailList(response.data);
    } catch {
      notification.error({message: 'Error', description: 'Error in fetching email list'});
    }
  }

  const removeSettingsEmail = async id => {
    setLoading(1);

    const payload = {account_id: id, user_id: userId};

    try {
      await axiosInstance.put(`/engage/campaign_email_settings/${campaignId}`, payload);
      notification.success({message: 'Success', description: 'Setting has been updated successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in updating Setting'});
	  }

    getCampaignSettings();
  }

  const getLeadActivity = async () => {
    setLoading(1);

    try {
			const {data} = await axiosInstance.get(`/engage_inbox/lead_activity/${userId}?lead_id=${selectedProspect.current.lead_id}`);
      leadActivity.current = data;
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching the Lead activity'});
	  }

    setLoading(0);
  }

  const processActivation = async () => {
    try {
			const response = await axiosInstance.get(`/engage/campaign_settings/${campaignId}?user_id=${userId}`);
      const {email_accounts_used, default_all_email} = response.data;
      const payload = {
      max_new_leads_per_day : 1000,
      min_time_btw_emails: 10,
      user_id: userId,
      remove_duplicate: null,
      default_all_email: email_accounts_used.length === 0 ? true : false,
      email_accounts_used: email_accounts_used,
      file_id: 0
    }

    
			await axiosInstance.put(`/v2_engage/activate_new_campaign/${campaignId}`, payload);
      // notification.success({message: 'Success', description: 'Campaign activation request successful. Refresh in a few minutes.'});
      Modal.info({
        title: (<div style={{ color: "#ffffff" }}>Campaign activation</div>),
        content: (<div style={{ color: "#ffffff" }}>Campaign will be activated in few minutes</div>),
        okText: 'OK',
        centered: true,
      })
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in Campaign activattion'});
	  }

    setLoading(0);
    getCampaignSettings()
    navigate("/engage/campaign-list");
  }

  /* Page */
  useEffect(() => {
    if (pageLoaded.current) {
      return;
    }
    
    pageLoaded.current = true;
    getAnalyticsData();
    // getCampaignSettings()
  }, []);

  /* Analytics */
  useEffect(() => {
    if (loading === 1) {
      return;
    }
    
    getAnalyticsData();
  }, [analyticsDefaultFilter, analyticsCustomFilter]);

  /* Prospects */
  useEffect(() => {
    if (loading === 1) {
      return;
    }

    const data = {...prospectsData};
    data.leads = [];
    setProspectsData({total_leads: 0, leads: []})
    getCampaignProspects(false);
  }, [selectedProspectsStatus, selectedProspectsFilters, prospectsSearchString, sortOrder, sortType]);


  useEffect(() => {
    if (loading === 1) {
      return;
    }
    
    getCampaignProspects(false);
  }, [prospectsPageNo]);

  useEffect(() => {
    if (loading === 1 || sidebarTab !== "activity") {
      return;
    }

    getLeadActivity();
  }, [sidebarTab]);

  /* Sequence */
  useEffect(() => {
    if (loading === 1 || !editor) {
      return;
    }

    insertLineBreak(editor);
  }, [editor]);

  /* Settings */
  useEffect(() => {
    if (loading === 1) {
      return;
    }
    updateCampaignSettings(false);
  }, [sendingLimit, minTimeBtwEmails, stopCampaign]);

  useEffect(() => {
    getAccountsList()
  },[])

  return (
    <Layout style={{minHeight: '100vh', padding: 15}} className="bootstrap">
      {/* Main */}
      <Row gutter={30} id="engage-account-main" align="stretch">
        {/* Loader */}
        <div className={"loader2 w-100 flex-column justify-content-center align-items-center "
          + ((loading === 0 || isLoading.current) ? "d-none" : "d-flex")}>
          <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
          <div className={"pt-4 text-center" + (loading === 1 ? "" : " d-none")}>
            <Spin size="large" />
          </div>
        </div>

        <Col span={24} className={"gutter-row" + ((loading === 0 || isLoading.current) ? "" : " d-none")}>
          <Row justify="space-between" align="middle" className="text-white pb-4">
            <Col className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="pagination-right align-items-center back pointer position-relative me-2"
                  onClick={gotoList}
                >
                  <div className="arrow-parent position-absolute inverted-pagination-arrow">➜</div>
                </div>
                <span className="fs-5">{analyticsData.current.campaign_name}</span>
              </div>
              {analyticsData.current.campaign_status !== "DRAFTED" && <div className="d-flex align-items-center ps-4 ms-4">
                <Switch
                  checked={analyticsData.current.campaign_status === "ACTIVE" ||
                  analyticsData.current.campaign_status === "COMPLETED"}
                  onChange={(toggleState) => updateCampaignStatus(campaignId, toggleState)}
                  disabled={analyticsData.current.campaign_status === "COMPLETED" || !editAccess}
                />
                <span className="ps-2">
                  {(analyticsData.current.campaign_status === "ACTIVE" ||
                  analyticsData.current.campaign_status === "COMPLETED") ? "ON" : "OFF"}
                </span>
              </div> }
            </Col>
            {/* <Col className={"small text-secondary" + (tab === "sequence" ? "" : " d-none")}>
              <span>All changes are saved automatically</span>
            </Col> */}
            <Col className="d-flex justify-content-end align-items-center">
            {analyticsData.current.campaign_status === "DRAFTED" && <div
                className={"lead-status-btn pointer br-13 me-2 mtng_booked"
               }
              onClick={()=> processActivation()}>
                <span className="text-nowrap">
                  Activate Campaign
                </span>
              </div> }
              {analyticsData.current.campaign_status !== "DRAFTED" && 
              <div className="d-flex align-items-center me-2">
                <div className={"battery " + getBatteryLevel(Number(analyticsData.current.leads_remaining))}></div>
                <span className="ps-2">{analyticsData.current.leads_remaining}% Remaining</span>
              </div> }
              <div
                className={"lead-status-btn br-13 me-2 inactive"
/*                 + (analyticsData.current.campaign_status !== "PAUSED"
                  ? analyticsData.current.campaign_status === "COMPLETED" ? "replied" : "mtng_booked"
                  : "inactive") */}
              >
                <span className="text-nowrap ">
                  {analyticsData.current.campaign_status === "DRAFTED" ? "DRAFT" : analyticsData.current.campaign_status}
                </span>
              </div>
              
              <Dropdown
                menu={{
                  items: menuItems,
                  onClick: shareMenuAction
                }}
                dropdownRender={(menu, index) => (
                  <div className="inbox-filter-dropdown" key={index}>{React.cloneElement(menu)}</div>
                )}
              >
                <div className="three-dots-btn silver pointer">...</div>
              </Dropdown>
            </Col>
          </Row>

          <Row className="pb-4" justify="space-between">
            <Col className="d-flex align-items-center">
              <div
                className={"w-120px lead-status-btn pointer br-13 me-2 "
                + (tab === "analytics" ? "mtng_booked" : "inactive")}
                onClick={() => {
                  setProspectsData({total_leads: 0, leads: []});
                  setEditCopy(false);
                  setTab("analytics");
                }}
              >Analytics</div>
              <div
                className={"w-120px lead-status-btn pointer br-13 me-2 "
                + (tab === "prospects" ? "mtng_booked" : "inactive")}
                onClick={() => {
                  setProspectsData({total_leads: 0, leads: []});
                  setEditCopy(false);
                  setTab("prospects");
                  getCampaignProspects();
                }}
              >Prospects</div>
              <div
                className={"w-120px lead-status-btn pointer br-13 me-2 "
                + (tab === "sequence" ? "mtng_booked" : "inactive")}
                onClick={() => {
                  setProspectsData({total_leads: 0, leads: []});
                  setEditCopy(false);
                  setTab("sequence");
                  getCampaignSequence();
                  setCurrentCopy("1-1")
                }}
              >Sequence</div>
              <div
                className={"w-120px lead-status-btn pointer br-13 me-2 "
                + (tab === "settings" ? "mtng_booked" : "inactive")}
                onClick={() => {
                  setProspectsData({total_leads: 0, leads: []});
                  setEditCopy(false);
                  setTab("settings");
                  getCampaignSettings();
                }}
              >Settings</div>
            </Col>
            {tab === "analytics" &&
              <Col>
                <Select
                  suffixIcon={<img src="/assets/images/arrow-down-gray-2x.png" width="12" height="auto" alt="down" />}
                  options={[
                    {label: "", value: null},
                    {label: "Last 7 days", value: "last_7_days"},
                    {label: "Month to Date", value: "month_to_date"},
                    {label: "Last 4 Weeks", value: "last_4_weeks"},
                    {label: "Last 3 Months", value: "last_3_months"}
                  ]}
                  value={analyticsDefaultFilter}
                  onChange={value => zetAnalyticsDefaultFilter(value)}
                  className="w-150px custom-select5 me-2"
                />
                <RangePicker
                  className={"position-relative custom-range-picker"
                  + (analyticsCustomFilter[0] ? " hide-placeholder" : "")}
                  placeholder={null}
                  suffixIcon={null}
                  value={analyticsCustomFilter}
                  onChange={dates => zetAnalyticsCustomFilter(dates)}
                />
              </Col>
            }
            {tab === "prospects" &&
              <Col>
                <Input
                  id="prospects-search-input"
                  placeholder="search by email"
                  suffix={
                    <div className="arrow-parent pointer" onClick={triggerProspectsSearch}>➜</div>}   
                  className="search-input4 settings-enter-email"
                />
              </Col>
            }
            {tab === "sequence-d-none" &&
              <Col>
                <div className="lead-status-btn generate-copy d-flex align-items-center br-13 pointer">
                  <img src="/assets/images/flash-white-2x.png" width="18" height="auto" alt="icon"></img>
                  <span className="text-nowrap ps-2">Create AI Variant</span>
                </div>
              </Col>
            }
          </Row>

          {/* Analytics */}
          <div className={tab === "analytics" ? "d-block" : "d-none"}>
            <Row className="campaign-detail-btns pb-4">
              <div className="custom-card br-13 mb-2" style={{width: "calc((100% - 100px)/7)" }}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Unique Contacted</span>
                  <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.contacted}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.contacted_percentage)}>
                    {analyticsData.current.contacted_percentage}
                  </span>
                  <span className="white-text-71"> from last week</span>
                </div>
              </div>
              <div className="custom-card br-13" style={{width: "calc((100% - 100px)/7)" }}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Total Contacted</span>
                  <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.total_sent}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.total_contacted_percentage)}>
                    {analyticsData.current.total_contacted_percentage}
                  </span>
                  <span className="white-text-71"> from last week</span>
                </div>
              </div>

              <div className="custom-card br-13" style={{width: "calc((100% - 100px)/7)" }}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Unique Opened</span>
                  <img src="/assets/images/mail-open-gray-2x.png" width="17" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.opened}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.opened_rate)}>
                    {analyticsData.current.opened_rate}%
                  </span>
                  <span className="white-text-71"> open rate</span>
                </div>
              </div>

              <div className="custom-card br-13" style={{width: "calc((100% - 100px)/7)" }}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Total Opened</span>
                  <img src="/assets/images/mail-open-gray-2x.png" width="17" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.total_open}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.total_opened_rate)}>
                    {analyticsData.current.total_opened_rate}%
                  </span>
                  <span className="white-text-71"> open rate</span>
                </div>
              </div>

              <div className="custom-card br-13" style={{width: "calc((100% - 100px)/7)" }}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Replied</span>
                  <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="16" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.replied}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.reply_rate)}>
                    {analyticsData.current.reply_rate}%
                  </span>
                  <span className="white-text-71"> reply rate</span>
                </div>
              </div>

              <div className="custom-card br-13" style={{width: "calc((100% - 100px)/7)" }}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Link Clicks</span>
                  <img src="/assets/images/link-gray-2x.png" width="18" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.total_click}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.total_link_clicks_percentage)}>
                    {analyticsData.current.total_link_clicks_percentage}
                  </span>
                  <span className="white-text-71"> from last week</span>
                </div>
              </div>

              <div className="custom-card br-13" style={{width: "calc((100% - 100px)/7)" }}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Opportunities</span>
                  <img src="/assets/images/jet-gray-2x.png" width="19" height="auto" alt="icon" />
                </div>

                <div className="text-white fs-6 fw-semibold pb-3">{analyticsData.current.opportunities}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.opportunities_percentage)}>
                    {analyticsData.current.opportunities_percentage}
                  </span>
                  <span className="white-text-71"> from last week</span>
                </div>
              </div>
            </Row>

            <Row className="campaign-detail-analytics pb-4">
              <div className="w-100 custom-card br-13">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={analyticsChartData.current}
                    margin={{
                      top: 15,
                      right: 15,
                      left: 15,
                      bottom: 15,
                    }}
                  >
                    <XAxis dataKey="name" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip cursor={{fill: "#202029"}} />
                    <Legend
                      verticalAlign="top"
                      height={36} 
                      iconSize={20}
                      wrapperStyle={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        display: 'flex',
                        justifyContent: 'center'
                      }}  // Adjust spacing
                      content={props => {
                        const { payload } = props;
                  
                        return (
                          <div className="d-flex align-items-center" style={{marginRight: '20px'}}>
                            {payload.map((entry, index) => (
                              <div key={`item-${index}`} className="d-flex" style={{marginRight: '50%'}}>
                                <div style={{
                                    width: '10px',
                                    height: '10px', 
                                    backgroundColor: entry.color,
                                    marginRight: '5px',
                                    marginTop:"6%"
                                  }}
                                />
                                <span style={{color: entry.color}}>{entry.value}</span>
                              </div>
                            ))}
                          </div>
                        );
                      }}
                    />
                    
                    <Bar dataKey="outbound" fill="#1E75FF" barSize={12} radius={5} />
                    <Bar dataKey="opens" fill="#3BCD92" barSize={12} radius={5} />
                    <Bar dataKey="replies" fill="#B03BCD" barSize={12} radius={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Row>

                    
            <Row className="campaign-detail-table pb-4">
              <Col span={24} className="text-white fs-6 fw-semibold pb-2">Sequence Analytics</Col>
              <Row gutter={30} className="w-100 pb-2 mx-2">
                <Col span={4} className="gutter-row text-white fw-semibold">Step</Col>
                <Col span={4} className="gutter-row d-flex justify-content-center align-items-center">
                  <span className="text-white fw-semibold pe-2">Contacted</span>
                  <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="auto" alt="icon" />
                </Col>
                <Col span={4} className="gutter-row d-flex justify-content-center align-items-center">
                  <span className="text-white fw-semibold pe-2">Opened</span>
                  <img src="/assets/images/mail-open-gray-2x.png" width="17" height="auto" alt="icon" />
                </Col>
                <Col span={4} className="gutter-row d-flex justify-content-center align-items-center">
                  <span className="text-white fw-semibold pe-2">Replied</span>
                  <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="16" height="auto" alt="icon" />
                </Col>
                <Col span={4} className="gutter-row d-flex justify-content-center align-items-center">
                  <span className="text-white fw-semibold pe-2">Link Clicks</span>
                  <img src="/assets/images/link-gray-2x.png" width="18" height="auto" alt="icon" />
                </Col>
                <Col span={4} className="gutter-row d-flex justify-content-center align-items-center">
                  <span className="text-white fw-semibold pe-2">Opportunities</span>
                  <img src="/assets/images/jet-gray-2x.png" width="19" height="auto" alt="icon" />
                </Col>
              </Row>
              
              <div className="w-100">
                {analyticsData.current?.sequence_analytics?.length > 0 ? analyticsData.current?.sequence_analytics?.map((seq) => (
                  <div className="w-100 custom-card br-13 mb-5">
                  <Row gutter={30} className="pb-2">
                    <Col span={4} className="gutter-row text-white">{seq.seq_name}</Col>
                    <Col span={4} className="gutter-row text-center text-pale-pink">{seq.seq_sent_count}</Col>
                    <Col span={4} className="gutter-row text-center text-pale-pink">{seq.seq_open_count}</Col>
                    <Col span={4} className="gutter-row text-center text-pale-pink">{seq.seq_reply_count}</Col>
                    <Col span={4} className="gutter-row text-center text-pale-pink">{seq.seq_click_count}</Col>
                    <Col span={4} className="gutter-row text-center text-pale-pink">{seq.seq_opportunities_count}</Col>
                  </Row>
                  <Divider  className="my-2" />
                    {seq?.variants?.map((variant) => (
                    <Row gutter={30} className="pb-2">
                      <Col span={4} className="gutter-row text-secondary">
                      <Switch checked={true} disabled />
                        <span className="ps-2">{variant.variant_name}</span>
                      </Col>
                      <Col span={4} className="gutter-row text-center text-secondary">{variant.sent_count}</Col>
                      <Col span={4} className="gutter-row text-center text-secondary">{variant.open_count}</Col>
                      <Col span={4} className="gutter-row text-center text-secondary">{variant.reply_count}</Col>
                      <Col span={4} className="gutter-row text-center text-secondary">{variant.click_count}</Col>
                      <Col span={4} className="gutter-row text-center text-secondary">{variant.opportunities_count}</Col>
                    </Row>
                    ))}
                  </div>
                )) : <div className="w-100 custom-card br-13 mb-5 gutter-row d-flex justify-content-center text-white fw-semibold"> No data available</div>}
              </div>
            </Row>
           
          </div>

          {/* Prospects */}          
          <div className={tab === "prospects" ? "d-block" : "d-none"}>
            {prospectsData.hasOwnProperty("total_leads") &&
              <>
                <div className="d-flex justify-content-between align-items-center pb-4">
                  <div className="bg-darkgray text-white px-3 py-2 br-13 d-flex align-items-center">
                    <div className="d-flex align-items-center pe-4">
                      <img src="/assets/images/man-gray-2x.png" width="18" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">{prospectsData.total_leads}</span>
                    </div>
                    <div className="d-none align-items-center pe-4">
                      <img src="/assets/images/arrow-cross-gray-2x.png" width="18" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">{prospectsData.emails_sent}</span>
                    </div>
                    <div className="d-flex align-items-center pe-4">
                      <img src="/assets/images/silent-gray-2x.png" width="18" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">{prospectsData.not_interested}</span>
                    </div>
                    <div className="d-none align-items-center pe-4">
                      <img src="/assets/images/sad-face-gray-2x.png" width="18" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">{prospectsData.uninterested}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/jet-gray-2x.png" width="18" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">{prospectsData.interested}</span>
                    </div>
                  </div>

                  <div className={"align-items-center crm-right-btns "
                  + (selectedProspects.length ? "d-none" : "d-flex")}>
                    <Dropdown
                      menu={{
                        items: prospectsFilters,
                        onClick: setProspectsFilters,
                        selectedKeys: [selectedProspectsFilters, selectedProspectsStatus],
                      }}
                      dropdownRender={(menu, index) => (
                        <div className="inbox-filter-dropdown" key={index}>{React.cloneElement(menu)}</div>
                      )}
                      className="me-2"
                    >
                      <div
                        className="lead-status-btn inactive d-flex align-items-center pointer br-13"
                      >
                        <DownloadIcon />
                        <span className="text-nowrap ps-2">View By Status</span>
                      </div>
                    </Dropdown>

                    {/* {editAccess &&    
                      <div
                        className="lead-status-btn mtng_booked d-flex align-items-center pointer br-13 me-2"
                        onClick={() => { 
                          setShowUploadProspects(true) }}
                      >
                        <AnalyticsDocIcon />
                        <span className="text-nowrap ps-2">Import</span>
                      </div>
                    } */}
                  </div>

                  <div className={"justify-content-end align-items-center "
                  + (selectedProspects.length ? "d-flex" : "d-none")}>
                    <span className="text-white pe-3">{selectedProspects.length} Selected</span>
                    {isViewer ? null :
                      <div
                        className="br-10 px-3 py-2 pointer me-3"
                        style={{backgroundColor: "rgba(226,1,1,0.20)"}}
                        onClick={deleteProspects}
                      >
                        <img src="/assets/svg/delete-red.svg" alt="delete" width="22" height="auto" />
                      </div>
                    }
                    <div
                      className="lead-status-btn inactive d-flex align-items-center pointer br-10 me-3"
                      onClick={downloadSelectedProspects}
                    >
                      <img src="/assets/images/download-gray-2x.png" alt="move" width="17" height="auto" />
                      <span className="text-nowrap ps-2">Download Selected</span>
                    </div>
                    {/*
                    {editAccess &&
                      <div className="lead-status-btn inactive d-flex align-items-center pointer br-10">
                        <img src="/assets/images/move-gray-2x.png" alt="move" width="19" height="auto" />
                        <span className="text-nowrap ps-2">Move Lead</span>
                      </div>
                    }
                    */}
                  </div>
                </div>

                <Row className="campaign-detail-table text-white">
                  <Row gutter={30} className="w-100 pb-4" style={{padding: "0 15px"}}>
                    <Col span={1} className="gutter-row fw-semibold">
                      <Checkbox
                        className="custom-checkbox"
                        onChange={setProspectsSelection}
                        checked={selectedProspects.length > 0 &&
                          selectedProspects.length === prospectsData.leads.length}
                      />
                    </Col>
                    <Col span={1} className="gutter-row fw-semibold">Profile</Col>
                    <Col span={2} className="gutter-row fw-semibold">Name</Col>
                    <Col span={5} className="gutter-row fw-semibold">Email</Col>
                    <Col span={2} className="gutter-row text-center fw-semibold">Status</Col>
                    <Col span={2} className="gutter-row d-flex justify-content-center align-items-center">
                      <span className="fw-semibold pe-2">Opened
                      </span>
                      <img src="/assets/images/mail-open-gray-2x.png" width="17" height="auto" alt="icon" />
                      <span
                        onClick={() => {
                          if(sortType === "opened") {
                            setProspectsData({total_leads: 0, leads: []})
                            setSortOrder(!sortOrder)
                          }
                          else {
                          setProspectsData({total_leads: 0, leads: []})  
                          setSortType('opened')
                          setSortOrder(true)
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                          <div style={{ color: sortType === "opened" ? "blue" : "white" }}> {sortType=== "opened" ? 
                                sortOrder ? <CaretUpOutlined  />
                          : <CaretDownOutlined /> : <CaretUpOutlined  />}                          
                          </div>
                      </span>
                    </Col>
                    <Col span={3} className="gutter-row d-flex justify-content-center align-items-center">
                      <span className="fw-semibold pe-2">Replied</span>
                      <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="16" height="auto" alt="icon" />
                      <span
                        onClick={() => {
                          if(sortType === "replied") {
                            setProspectsData({total_leads: 0, leads: []})
                            setSortOrder(!sortOrder)
                          }
                          else {
                          setProspectsData({total_leads: 0, leads: []})  
                          setSortType('replied')
                          setSortOrder(true)
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                          <div style={{ color: sortType === "replied" ? "blue" : "white" }}> {sortType=== "replied" ? 
                                sortOrder ? <CaretUpOutlined  />
                          : <CaretDownOutlined /> : <CaretUpOutlined  />}
                          </div>
                      </span>
                    </Col>
                    <Col span={4} className="gutter-row d-flex justify-content-center align-items-center">
                      <span className="fw-semibold pe-2">Link Clicks</span>
                      <img src="/assets/images/link-gray-2x.png" width="18" height="auto" alt="icon" />
                      <span
                        onClick={() => {
                          if(sortType === "link_clicks") {
                            setProspectsData({total_leads: 0, leads: []})
                            setSortOrder(!sortOrder)
                          }
                          else {
                          setProspectsData({total_leads: 0, leads: []})  
                          setSortType('link_clicks')
                          setSortOrder(true)
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                          <div style={{ color: sortType === "link_clicks" ? "blue" : "white" }}> {sortType=== "link_clicks" ? 
                                sortOrder ? <CaretUpOutlined  />
                          : <CaretDownOutlined /> : <CaretUpOutlined  />}
                          </div>
                      </span>
                    </Col>
                    {/* <Col span={2} className="gutter-row fw-semibold">Contact</Col> */}
                    <Col span={2} className="gutter-row fw-semibold">Company</Col>
                    <Col span={2} className="gutter-row fw-semibold">Job Title</Col>
                  </Row>
                  
                  <div id="scrollable-prospects-div" className="w-100 custom-card br-13">
                    <InfiniteScroll
                      dataLength={prospectsPageNo *25}
                      next={loadMoreProspects}
                      hasMore={prospectsData.leads.length < prospectsData.total_leads || prospectsPageNo * 25 <prospectsData.total_leads}
                      loader={<div className="d-flex justify-content-center p-4"><Spin size="large" /></div>}
                      endMessage={<Divider plain>No more leads to show.</Divider>}
                      scrollableTarget="scrollable-prospects-div"
                    >
                      <List
                        dataSource={prospectsData.leads}
                        renderItem={(lead, index) => (
                          <Row gutter={30} className="pb-3" align="middle" key={index}>
                            <Col span={1} className="gutter-row fw-semibold">
                              <Checkbox
                                className="custom-checkbox"
                                onChange={() => setProspectsSelectionById(lead.lead_id)}
                                checked={selectedProspects.includes(lead.lead_id)}
                              />
                            </Col>
                            <Col span={1} className="gutter-row fw-semibold pointer" onClick={() => viewProspectDetails(lead, index)}>
                              <img src={lead.profile_pic === null ? "/assets/images/default-dp-radial-2x.png" : lead.profile_pic}
                              onError={(e) => { e.target.onerror = null; e.target.src = "/assets/images/default-dp-radial-2x.png"; }}
                               width="20" height="auto" alt="profile" className="mb-3" />
                            </Col>
                            <Col span={2} className="gutter-row pb-2 pointer"
                                onClick={() => viewProspectDetails(lead, index)}>{lead.first_name + " " + lead.last_name}</Col>
                            <Col span={5} className="gutter-row pb-2">
                              <span
                                className="pointer"
                                onClick={() => viewProspectDetails(lead, index)}
                              >{lead.email}</span>
                            </Col>
                            <Col span={2} className="gutter-row d-flex justify-content-center pb-2">
                              <div className="border-mid-gray br-10 px-3 py-1 d-inline-flex align-items-center">
                                <img src={getProspectsStatusIcon(lead.status)} width="16" height="auto" alt="status" />
                                <span className="ps-2 small fw-semibold">{lead.status}</span>
                              </div>
                            </Col>
                            <Col span={2} className="gutter-row text-center pb-2">{lead.opened}</Col>
                            <Col span={3} className="gutter-row text-center pb-2">{lead.replied}</Col>
                            <Col span={4} className="gutter-row text-center pb-2">{lead.link_clicks}</Col>
                            {/* <Col span={2} className="gutter-row pb-2">{lead.contact ?? "-"}</Col> */}
                            <Col span={2} className="gutter-row pb-2">{lead.company ?? "-"}</Col>
                            <Col span={2} className="gutter-row pb-2">{lead.job_title ?? "-"}</Col>
                          </Row>
                        )}
                      />
                    </InfiniteScroll>

                    {/*
                    <Row gutter={30} className="pb-3" align="middle">
                      <Col span={1} className="gutter-row fw-semibold"><Radio /></Col>
                      <Col span={3} className="gutter-row pb-2">test@gmail.com</Col>
                      <Col span={2} className="gutter-row d-flex justify-content-center pb-2">
                        <div className="border-mid-gray br-10 px-3 py-1 d-inline-flex align-items-center">
                          <img src="/assets/images/clock-gray-2x.png" width="16" height="auto" alt="status" />
                          <span className="ps-2 small fw-semibold">Queued</span>
                        </div>
                      </Col>
                      <Col span={3} className="gutter-row text-center pb-2">5</Col>
                      <Col span={3} className="gutter-row text-center pb-2">4</Col>
                      <Col span={3} className="gutter-row text-center pb-2">7</Col>
                      <Col span={3} className="gutter-row pb-2">Bob Smith</Col>
                      <Col span={3} className="gutter-row pb-2">Test Name</Col>
                      <Col span={3} className="gutter-row pb-2">Test Name</Col>
                    </Row>

                    <Row gutter={30} className="pb-3" align="middle">
                      <Col span={1} className="gutter-row fw-semibold"><Radio /></Col>
                      <Col span={3} className="gutter-row pb-2">test@gmail.com</Col>
                      <Col span={2} className="gutter-row d-flex justify-content-center pb-2">
                        <div className="border-mid-gray br-10 px-3 py-1 d-inline-flex align-items-center">
                          <img src="/assets/svg/block-red.svg" width="16" height="auto" alt="status" />
                          <span className="ps-2 small fw-semibold">Bounced</span>
                        </div>
                      </Col>
                      <Col span={3} className="gutter-row text-center pb-2">5</Col>
                      <Col span={3} className="gutter-row text-center pb-2">4</Col>
                      <Col span={3} className="gutter-row text-center pb-2">7</Col>
                      <Col span={3} className="gutter-row pb-2">Bob Smith</Col>
                      <Col span={3} className="gutter-row pb-2">Test Name</Col>
                      <Col span={3} className="gutter-row pb-2">Test Name</Col>
                    </Row>

                    <Row gutter={30} className="pb-3" align="middle">
                      <Col span={1} className="gutter-row fw-semibold"><Radio /></Col>
                      <Col span={3} className="gutter-row pb-2">test@gmail.com</Col>
                      <Col span={2} className="gutter-row d-flex justify-content-center pb-2">
                        <div className="border-mid-gray br-10 px-3 py-1 d-inline-flex align-items-center">
                          <img src="/assets/images/alert-orange-2x.png" width="16" height="auto" alt="status" />
                          <span className="ps-2 small fw-semibold">Spam</span>
                        </div>
                      </Col>
                      <Col span={3} className="gutter-row text-center pb-2">5</Col>
                      <Col span={3} className="gutter-row text-center pb-2">4</Col>
                      <Col span={3} className="gutter-row text-center pb-2">7</Col>
                      <Col span={3} className="gutter-row pb-2">Bob Smith</Col>
                      <Col span={3} className="gutter-row pb-2">Test Name</Col>
                      <Col span={3} className="gutter-row pb-2">Test Name</Col>
                    </Row>
                    */}
                  </div>
                </Row>
              </>
            }
          </div>

          {/* Sequence */}
          <div className={tab === "sequence" ? "d-block" : "d-none"}>
            <Row gutter={30}>
              <Col span={8} className="gutter-row"> 
                {/* Improve Copy */}
                <div className={"h-100 improve-copy-black flex-column justify-content-center "
                  + (improveCopy ? "d-flex" : " d-none")}
                >
                  <div className="custom-card text-white w-100 p-4 br-13">
                    <div className="d-flex justify-content-end fw-semibold pb-4">
                      <span
                        className="close-btn d-flex align-items-center justify-content-center pointer"
                        onClick={() => {
                          setImproveCopy(false);
                          setAdditionalEmails(true);
                        }}
                      >X</span>
                    </div>

                    <Form form={improveCopyForm} onFinish={improveVariant}>
                      <div className="fw-semibold fs-5">Change the tone</div>
                      <div className="d-flex align-items-center pt-3 pb-4 flex-wrap">
                        <div
                          className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                          + (improveCopyTone === "casual" ? " active" : "")}
                          onClick={() => setImproveCopyTone("casual")}
                        >Casual</div>
                        <div
                          className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                          + (improveCopyTone === "friendly" ? " active" : "")}
                          onClick={() => setImproveCopyTone("friendly")}
                        >Friendly</div>
                        <div
                          className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                          + (improveCopyTone === "professional" ? " active" : "")}
                          onClick={() => setImproveCopyTone("professional")}
                        >Professional</div>
                        <div
                          className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                          + (improveCopyTone === "humorous" ? " active" : "")}
                          onClick={() => setImproveCopyTone("humorous")}
                        >Humorous</div>
                      </div>
                      <div
                        class={"pb-4" + (improveCopyToneError ? "" : " d-none")}
                        style={{color: "#dc4446", marginTop: "-1rem"}}
                      >Please select a tone</div>

                      <div className="fw-semibold fs-5">Change the length</div>
                      <div className="d-flex align-items-center pt-3 pb-4 flex-wrap">
                        <div
                          className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                          + (improveCopyLength === "shorter" ? " active" : "")}
                          onClick={() => setImproveCopyLength("shorter")}
                        >Shorter</div>
                        <div
                          className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                          + (improveCopyLength === "longer" ? " active" : "")}
                          onClick={() => setImproveCopyLength("longer")}
                        >Longer</div>
                      </div>
                      <div
                        class={"pb-4" + (improveCopyLengthError ? "" : " d-none")}
                        style={{color: "#dc4446", marginTop: "-1rem"}}
                      >Please select a length</div>

                      <div className="fw-semibold fs-5">Switch Call to Action</div>
                      <Form.Item
                        name="cta"
                        className="pt-3 pb-4"
                        rules={[
                          { 
                            required: true,
                            message: "Please select Call to Action"
                          },
                        ]}
                      >
                        <Select
                          className="custom-select3 w-75"
                          placeholder="Call To Action"
                          suffixIcon={<DropdownArrowWhite />}
                          options={[
                            {value: 'view_demo', label: 'View a Demo Video'},
                            {value: 'schedule_meeting', label: 'Schedule a Meeting'},
                            {value: 'event_signup', label: 'Event Sign-Up'},
                            {value: 'product_signup', label: 'Product Sign-Up'},
                            {value: 'user_research', label: 'User Research'}
                          ]}
                        />
                      </Form.Item>

                      <div className="fw-semibold fs-6 py-3">Describe what you want to improve:</div>
                      <Form.Item
                        name="user_input"
                        className="pt-3 pb-4"
                        rules={[
                          { 
                            required: true,
                            message: "Please enter a message"
                          },
                        ]}
                      >
                        <TextArea
                          className="custom-copy-improve"
                          rows={6}
                        />
                      </Form.Item>
                      <Button htmlType="submit" id="regenerate-form-btn" className="d-none" />
                    </Form>

                    <div className="d-flex justify-content-center pt-4">
                      <div
                        className="lead-status-btn generate br-13 d-flex align-items-center pointer"
                        onClick={triggerRegenerateFormSubmit}
                      >
                        <img src="/assets/images/stars-white-2x.png" width="auto" height="24" alt="stars" />
                        <span className="text-nowrap ps-2">Regenerate</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Scores */}
                <div
                  className={"h-100 improve-copy-black flex-column justify-content-center "
                  + (copyProgress ? "d-flex" : " d-none")}
                >
                  <div className="custom-card text-white w-100 p-4 br-13">
                    <div className="d-flex justify-content-between fw-semibold pb-4">
                      <span className='copywrite-score-text'>Copywriting Score</span>
                      <span
                        className="close-btn d-flex align-items-center justify-content-center pointer"
                        onClick={() => {
                          setCopyProgress(false);
                          setAdditionalEmails(true);
                        }}
                      >X</span>
                    </div>
                    <div
                      className="mb-3 copy-text"
                      style={{
                        color: copyWriteScore.current.total_score > 8
                        ? (copyWriteScore.current.total_score > 12 ? "#3EDF23" : "#FFA41B")
                        : "#DD2525"
                      }}
                    >
                        {`${getChance(copyWriteScore.current.total_score)} chance to receive a response.`}
                    </div>

                    <div className="copy-header-txt">Subject Length</div>
                    <div
                      className="custom-progress copy-progress-cnt"
                      style={{width: `${getWidthPercent(copyWriteScore.current.subject_length, 18)}%`}}
                    >
                      {copyWriteScore.current.subject_length}
                    </div>
                    <Progress
                      percent={`${getWidthPercent(copyWriteScore.current.subject_length, 18)}`}
                      status="active"
                      className='mb-4'
                      showInfo={false}
                      strokeColor={`${getSubjectLength(copyWriteScore.current.subject_length)}`}
                    />

                    <div className="copy-header-txt">Word Count</div>
                    <div
                      className="custom-progress copy-progress-cnt"
                      style={{width: `${getWidthPercent(copyWriteScore.current.word_count, 600)}%`}}
                    >
                      {copyWriteScore.current.word_count}
                    </div>
                    <Progress
                      percent={`${getWidthPercent(copyWriteScore.current.word_count, 600)}`}
                      status="active"
                      className='mb-4'
                      showInfo={false}
                      strokeColor={`${getWordCount(copyWriteScore.current.word_count)}`}
                    />

                    <div className="copy-header-txt">Question Count</div>
                    <div
                      className="custom-progress copy-progress-cnt"
                      style={{width: `${getWidthPercent(copyWriteScore.current.question_count, 3)}%`}}
                    >
                      {copyWriteScore.current.question_count}
                    </div>
                    <Progress
                      percent={`${getWidthPercent(copyWriteScore.current.question_count, 3)}`}
                      status="active"
                      className='mb-4'
                      showInfo={false}
                      strokeColor={`${getQuestionCount(copyWriteScore.current.question_count)}`}
                    />

                    <div className="copy-header-txt">Reading Level</div>
                    <div
                      className="custom-progress copy-progress-cnt"
                      style={{width: `${getWidthPercent(copyWriteScore.current.reading_level, 21)}%`}}
                    >
                      {copyWriteScore.current.reading_level}
                    </div>
                    <Progress
                      percent={`${getWidthPercent(copyWriteScore.current.reading_level, 21)}`}
                      status="active"
                      className='mb-4'
                      showInfo={false}
                      strokeColor={`${getReadingLevel(copyWriteScore.current.reading_level)}`}
                    />

                    <div className="copy-header-txt">Spam words</div>
                    <div
                      className="custom-progress copy-progress-cnt"
                      style={{width: `${getWidthPercent(copyWriteScore.current.spam_words, 8)}%`}}
                    >
                      {copyWriteScore.current.spam_words}
                    </div>
                    <Progress
                      percent={`${getWidthPercent(copyWriteScore.current.spam_words, 8)}`}
                      status="active"
                      className='mb-4'
                      showInfo={false}
                      strokeColor={`${getSpamWords(copyWriteScore.current.spam_words)}`}
                    />

                    <div className="copy-header-txt">Number of links</div>
                    <div
                      className="custom-progress copy-progress-cnt"
                      style={{width: `${getWidthPercent(copyWriteScore.current.no_of_links, 4)}%`}}
                    >
                      {copyWriteScore.current.no_of_links}
                    </div>
                    <Progress
                      percent={`${getWidthPercent(copyWriteScore.current.no_of_links, 4)}`}
                      status="active"
                      className='mb-4'
                      showInfo={false}
                      strokeColor={`${getNoOfLinks(copyWriteScore.current.no_of_links)}`}
                    />

                    {/*
                    <div className="d-flex justify-content-center pt-4">
                      <div className="lead-status-btn mtng_booked d-flex align-items-center pointer generate" onClick={improveCopy}>
                        <img src="/assets/images/stars-white-2x.png" width="auto" height="24" alt="stars" />
                        <span className="text-nowrap ps-2">Fix copy</span>
                      </div>
                    </div>
                    */}
                  </div>
                </div>

                {/* Additional Emails */}
                <div className={"h-100 flex-column justify-content-between " 
                + (additionalEmails ? "d-flex" : " d-none")}>
                  <>
                    <Form onFinish={updateSequence} id="additional-emails-container">
                      {emailCopies?.map((step, index) => {
                        const copyNo = index + 1;
                        return (
                          <div className="step-block" key={copyNo}>
                            {index === 0 &&
                              <>
                                <Form.Item name="campaign_id" initialValue={step.engage_campaign_id}
                                className="d-none">
                                  <Input />
                                </Form.Item>
                                <Form.Item name="seq_length" initialValue={emailCopies.length}
                                className="d-none">
                                  <Input />
                                </Form.Item>
                              </>
                            }
                            <div className={"text-white align-items-center pb-2 "
                            + (index === 0 ? "d-none" : "d-flex")}>
                              <span>Wait for</span>
                              <Form.Item
                                name={`delay_days_${index}`}
                                initialValue={step.delay_in_days}
                                className="pb-0"
                                style={{width: "50px"}} 
                              >
                                <Input
                                  className="custom-input mx-2"
                                  onBlur={triggerUpdateSequence}
                                  disabled={!editAccess}
                                />
                              </Form.Item>
                              <span className="ms-3">days then send:</span>
                            </div>
                            <div className="custom-card text-white w-100 p-3 br-13 mb-4">
                              <div className="d-flex align-items-center justify-content-between pb-3">
                                <span className="step-count fw-semibold">Step {copyNo}</span>
                              {isViewer || emailCopies?.length === 1 ? null :
                              <img
                                src="/assets/images/delete-gray-2x.png"
                                width="20"
                                height="auto"
                                alt="delete"
                                className="pointer"
                                onClick={() => {
                                  if (editCopy && isNewVariant.current) {
                                    Modal.confirm({
                                      title: (<div style={{ color: "#ffffff" }}>Save/Delete Variant?</div>),
                                      content: (<div style={{ color: "#ffffff" }}>Would you like to save or delete the unsaved variant?</div>),
                                      okText: (newvariantStep === 0 && 
                                      newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                      newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                      (newvariantStep !== 0 && 
                                        newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")? 'Save' : "Go Back",
                                      cancelText: 'Delete',
                                      onOk: async () => {
                                        if((newvariantStep === 0 && 
                                          newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                          newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                          (newvariantStep !== 0 && 
                                            newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")) {
                                              editVariant(null, newVariantDetails)
                                            }
                                            else {
                                              setCurrentCopy((newvariantStep + 1 ) + '-' + (newVariantDetails.variants.length))
                                              setEditCopy(true)
                                            }
                                      },
                                      onCancel: async () => {
                                        let updatedEmailCopies = [...emailCopies];
                                        if (updatedEmailCopies[newvariantStep]?.variants.length < 2) {
                                          updatedEmailCopies = [
                                            ...updatedEmailCopies.slice(0, newvariantStep),
                                            ...updatedEmailCopies.slice(newvariantStep + 1)
                                          ];
                                        }
                                        else {
                                          updatedEmailCopies[newvariantStep] = {
                                            ...updatedEmailCopies[newvariantStep],
                                            variants: updatedEmailCopies[newvariantStep]?.variants.slice(0, -1),
                                          };
                                        }
                                        setEmailCopies([...updatedEmailCopies])
                                        setCurrentCopy("1-1");
                                        setEditCopy(false)
                                      }
                                    })
                                  }
                                  else {
                                  deleteStep(index, step.seq_id)}} }
                              />
                              }
                              </div>
                              {step?.variants?.map((variant, index_2) => {
                                const variantNo = index_2 + 1;
                                return (
                                  <div
                                    className={"d-flex align-items-center justify-content-between px-3 "
                                      + "py-2 mb-3 pointer variant-row"
                                      + (currentCopy === `${copyNo}-${variantNo}` ? " active" : "")}
                                    key={variantNo}
                                    >
                                    <div className="d-flex align-items-center"
                                      onMouseUp={() => {
                                        if (editCopy && isNewVariant.current) {
                                          Modal.confirm({
                                            title: (<div style={{ color: "#ffffff" }}>Save/Delete Variant?</div>),
                                            content: (<div style={{ color: "#ffffff" }}>Would you like to save or delete the unsaved variant?</div>),
                                            okText: (newvariantStep === 0 && 
                                              newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                              newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                              (newvariantStep !== 0 && 
                                                newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")? 'Save' : "Go Back",
                                            cancelText: 'Delete',
                                            onOk: async () => {
                                              if((newvariantStep === 0 && 
                                                newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                                newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                                (newvariantStep !== 0 && 
                                                  newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")) {
                                                    editVariant(null, newVariantDetails)
                                                  }
                                                  else {
                                                    setCurrentCopy((newvariantStep + 1 ) + '-' + (newVariantDetails.variants.length))
                                                    setEditCopy(true)
                                                  }
                                            },
                                            onCancel: async () => {
                                              let updatedEmailCopies = [...emailCopies];
                                              if (updatedEmailCopies[newvariantStep]?.variants.length < 2) {
                                                updatedEmailCopies = [
                                                  ...updatedEmailCopies.slice(0, newvariantStep),
                                                  ...updatedEmailCopies.slice(newvariantStep + 1)
                                                ];
                                              }
                                              else {
                                                updatedEmailCopies[newvariantStep] = {
                                                  ...updatedEmailCopies[newvariantStep],
                                                  variants: updatedEmailCopies[newvariantStep]?.variants.slice(0, -1),
                                                };
                                              }
                                              setEmailCopies([...updatedEmailCopies])
                                              setCurrentCopy("1-1");
                                              setEditCopy(false)
                                            }
                                          })
                                        }
                                        else {
                                          setCurrentCopy(copyNo + "-" + variantNo);
                                        }
                                      }}>
                                      <img src="/assets/images/mail-blue-2x.png" width="20" height="auto" alt="mail" />
                                      <span className="fw-semibold ps-2">Variant {variantNo}</span>
                                    </div>
                                    
                                    {isViewer || step?.variants?.length === 1 
                                      ? null :
                                      <Switch checked={variant.row_status!== null && variant.row_status!== undefined ? variant.row_status : false} 
                                       disabled={(emailCopies?.length === 1 && variant.row_status === true && (
                                        step?.variants?.filter(i => i.row_status === true).length <= 1
                                      ))}  
                                        // src="/assets/images/close-gray-2x.png"
                                        // width="20"
                                        // height="auto"
                                        // alt="close"
                                        // className="pointer"
                                        onChange={(checked, e) => {
                                          e.stopPropagation();
                                          if (editCopy && isNewVariant.current) {
                                            Modal.confirm({
                                              title: (<div style={{ color: "#ffffff" }}>Save/Delete Variant?</div>),
                                              content: (<div style={{ color: "#ffffff" }}>Would you like to save or delete the unsaved variant?</div>),
                                              okText: (newvariantStep === 0 &&
                                                newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "" &&
                                                newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_subject !== "") ||
                                                (newvariantStep !== 0 &&
                                                  newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "") ? 'Save' : "Go Back",
                                              cancelText: 'Delete',
                                              onOk: async () => {
                                                if ((newvariantStep === 0 &&
                                                  newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "" &&
                                                  newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_subject !== "") ||
                                                  (newvariantStep !== 0 &&
                                                    newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "")) {
                                                  editVariant(null, newVariantDetails)
                                                }
                                                else {
                                                  setCurrentCopy((newvariantStep + 1) + '-' + (newVariantDetails.variants.length))
                                                  setEditCopy(true)
                                                }
                                              },
                                              onCancel: async () => {
                                                let updatedEmailCopies = [...emailCopies];
                                                if (updatedEmailCopies[newvariantStep]?.variants.length < 2) {
                                                  updatedEmailCopies = [
                                                    ...updatedEmailCopies.slice(0, newvariantStep),
                                                    ...updatedEmailCopies.slice(newvariantStep + 1)
                                                  ];
                                                }
                                                else {
                                                  updatedEmailCopies[newvariantStep] = {
                                                    ...updatedEmailCopies[newvariantStep],
                                                    variants: updatedEmailCopies[newvariantStep]?.variants.slice(0, -1),
                                                  };
                                                }
                                                setEmailCopies([...updatedEmailCopies])
                                                setCurrentCopy("1-1");
                                                setEditCopy(false)
                                              }
                                            })
                                          }
                                          else { deleteVariant(index, index_2, variant.id, checked) }
                                        }}
                                      />
                                    }
                                  </div>
                                )
                              })}
                              <div className={"justify-content-end " + (editAccess ? "d-flex" : "d-none")}>
                                <div
                                  className="lead-status-btn d-flex align-items-center pointer"
                                  onClick={() => { 
                                    if(editCopy) {
                                      notification.error({message: 'Error', description: 'Please save the current variant first'})
                                    }
                                    else {
                                    addVariant(copyNo) 
                                  }}}
                                >
                                  <CheckCircleOutlined />
                                  <span className="text-nowrap ps-2">Add Variant</span>
                                </div>
                              </div>
                            </div>
                            <Form.Item name={`seq_id_${index}`} initialValue={step.seq_id} className="d-none">
                              <Input />
                            </Form.Item>
                          </div>
                        )
                      })}
                      <Button type="primary" htmlType="submit" id="sequence-update-btn"
                      className="d-none">Save</Button>
                    </Form>
                  </>
                </div>
              </Col>

              <Col span={16} className="gutter-row">
                {emailCopies.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                <Card className={"custom-card h-100 text-white p-3" + (emailCopies.length === 0 ? " d-none" : "")}>
                  {emailCopies.map((copy, index) => {
                    const copyNo = index + 1;

                    return (
                      <div key={copyNo}>
                        {copy.variants?.map((variant, index_2) => {
                          const variantNo = index_2 + 1;
                          const key = copyNo + "-" + variantNo;
                          // const scorePercent = Math.round(variant.score.score_percentage);
                          return (
                            <div key={key} className={currentCopy === key ? "" : "d-none"}>
                              <Row>
                                <Col span={18}>
                                  <div className="w-100 d-flex align-items-center position-relative">
                                    <span className="d-block inbox-indicator pull-left unread position-absolute" />
                                    <span className="pe-2">{analyticsData.current.campaign_name}</span>
                                    <img
                                      src="/assets/images/wizard-blue-2x.png"
                                      width="18"
                                      height="18"
                                      alt="wizard"
                                      className="d-none pointer"
                                    />
                                  </div>
                                </Col>
                                {/* <Col
                                  span={6}
                                  className="custom-progress"
                                >
                                  <div className='d-flex align-items-center'>
                                    <Progress
                                      percent={scorePercent}
                                      status="active"
                                      className='mb-0'
                                      showInfo={false}
                                      strokeColor={scorePercent > 50 ? (scorePercent > 74 ? "#3EDF23" : "#FFA41B") : "#DD2525"}
                                    />
                                    <InfoCircleOutlined
                                      className="text-white small pointer"
                                      onClick={() => {
                                        copyWriteScore.current = variant.score;
                                        setAdditionalEmails(false);
                                        setImproveCopy(false);
                                        setCopyProgress(true);
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{width: "50%"}}
                                    className="font-weight-400 font-size-10 d-flex align-items-center justify-content-between">
                                    <span className="pr-2">Score</span>
                                    <span>{scorePercent}%</span>
                                  </div>
                                </Col> */}
                              </Row>

                              <Row className={"pt-4" + (editCopy ? " d-none" : "")}>
                                <Col span={24}>
                                  <div className="d-inline-block black-highlight fw-semibold p-1 mb-4">Email copy {copyNo}</div>
                                  <div className="copywriting-subject fw-semibold pb-4">Subject: {variant.variant_subject}</div>
                                  <div
                                    className="copywriting-body small"
                                    dangerouslySetInnerHTML={{__html: variant.variant_message}}
                                  />
                                </Col>
                                <Button
                                  className="d-none"
                                  id={"edit-copy-btn-" + key}
                                  onClick={() => initiateEditCopy(copy.seq_id, variant)}
                                />
                              </Row>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}

                  <Row className={"pt-4" + (editCopy ? "" : " d-none")}>
                    <Col span={24}>
                      <Form form={editCopyForm} onFinish={editVariant} className="copywriting-form">
                        <Form.Item name="seq_id" className="d-none">
                          <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item name="var_id" className="d-none">
                          <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item name="variant_name" className="d-none">
                          <Input className="custom-input" />
                        </Form.Item>
                        <Row className="d-flex flex-row me-2">
                        <Col span={18}>
                        <Form.Item
                          name="subject"
                          rules={[
                            {
                              validator: (rule, value) => {
                                const isSubjectRequired = emailCopies.length + 1 <= 1 || currentCopy.split('-')[0] === "1";
                                if (isSubjectRequired && !value) {
                                  return Promise.reject("Please enter a subject");
                                }
                        
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input className="custom-input" 
                            placeholder={emailCopies.length + 1 <= 1 || currentCopy.split('-')[0] === "1" ? 
                            "Enter subject" 
                            : "Leave subject blank to thread response. Adding a subject will create a new email thread."
                            }
                            onChange={handleInputChange}
                            onClick={handleInputClick}
                            onKeyUp={handleInputClick}
                            ref={inputRef}
                            />

                        </Form.Item>
                        </Col>
                       <Col span={6} > <Select
                            style={{ width: 190 }}
                            onChange={handleDropdownChange}
                            placeholder="Insert Variable"
                            value={selectedVariable}
                            className="ms-2"
                          >
                            {dropdownOptions.map((option, index) => (
                              <Option key={index + 1} value={option.value}>{option.label}</Option>
                            ))}
                          </Select></Col>
                        </Row>
                        {editCopy &&
                          <>
                            <Editor
                              defaultConfig={editorConfig}
                              value={html}
                              onCreated={setEditor}
                              onChange={editor => setHtmlContent(editor.getHtml(), editor.getText())}
                              mode="default"
                              className="wang-editor wang-copywriting-editor br-10"
                              style={{height: '400px', overflowX: 'hidden', overflowY: 'auto'}}
                            />
                            <Toolbar
                              editor={editor}
                              defaultConfig={toolbarConfig}
                              mode="default"
                              className="wang-toolbar wang-copywriting-toolbar"
                            />
                          </>
                        }
                        <Form.Item
                          name="body"
                          rules={[
                            { 
                              required: true,
                              message: "Please enter your message"
                            },
                          ]}
                          className="copywriting-email-text"
                        >
                          <Input className="custom-input2" />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" className="ms-3">
                          {isNewVariant.current && isNewStep.current === false ? "Add New Variant" : "Save Variant"}
                        </Button>
                      </Form>
                    </Col>
                  </Row>

                  {currentCopy && editAccess &&    
                    <Row className="pt-4">
                      <Col span={24} className="d-flex align-items-center">
                        <div
                          className="lead-status-btn copy d-flex align-items-center pointer br-13 me-2"
                          onClick={() => { 
                            if(editCopy) {
                              notification.error({message: 'Error', description: 'Please save the current variant first'})
                            }
                            else {triggerEditCopy() }}}
                        >
                          <img src="/assets/images/settings-white-2x.png" width="15" height="auto" alt="settings" />
                          <span className="text-nowrap ps-2">Edit</span>
                        </div>
                        <div
                          className="lead-status-btn generate d-flex align-items-center br-13 pointer me-2"
                          onClick={() => {
                            setImproveCopy(true);
                            setAdditionalEmails(false);
                            setCopyProgress(false);
                          }}
                        >
                          <img src="/assets/images/stars-white-2x.png" width="auto" height="24" alt="stars" />
                          <span className="text-nowrap ps-2">Improve Copy</span>
                        </div>
                        <div
                          className="lead-status-btn inactive d-flex align-items-center br-13 pointer me-2"
                          onClick={() => { 
                            if(editCopy) {
                              notification.error({message: 'Error', description: 'Please save the current variant first'})
                            }
                            else {
                            addEmailStep() 
                          }}}
                        >
                          <PlusCircleOutlined style={{height: "24px"}} />
                          <span className="ps-2">Add new step</span>
                        </div>
                        { !editCopy && <div className="lead-status-btn generate-copy d-flex align-items-center pointer br-13 me-2"
                         onClick={() =>  {
                            setSendTestMail(true)} }>
                          <span className="text-nowrap ps-2">Send Test Mail</span>
                        </div> }
                      </Col>
                    </Row>
                  }
                </Card>
              </Col>
            </Row>
          </div>

          {/* Settings */}
          <div className={tab === "settings" ? "d-block" : "d-none"}>
            <div className="w-50 improve-copy-black">
              <div className="custom-card text-white p-4 br-13">
                <div className="fs-5 fw-semibold pb-2">Campaign Settings</div>
                <div className="small text-secondary mb-4">These settings will determine how your campaign runs</div>

                {/* <div className="w-100 fs-6 fw-semibold pb-3">Daily Sending Limit Per Campaign</div>
                <div className="d-flex align-items-center pb-4 mb-4">
                  <div
                    className={"white-outlined-btns fill-blue me-3 "
                    + (editAccess ? "pointer" : "prohibited")
                    + (sendingLimit === 15 ? " active" : "")}
                    {...(editAccess && {onClick: () => setSendingLimit(15)})}
                  >15</div>
                  <div
                    className={"white-outlined-btns fill-blue me-3 "
                    + (editAccess ? "pointer" : "prohibited")
                    + (sendingLimit === 30 ? " active" : "")}
                    {...(editAccess && {onClick: () => setSendingLimit(30)})}
                  >30</div>
                  <div
                    className={"white-outlined-btns fill-blue me-3 "
                    + (editAccess ? "pointer" : "prohibited")
                    + (sendingLimit === 60 ? " active" : "")}
                    {...(editAccess && {onClick: () => setSendingLimit(60)})}
                  >60</div>
                  <div
                    className={"white-outlined-btns fill-blue me-3 "
                    + (editAccess ? "pointer" : "prohibited")
                    + (sendingLimit === 90 ? " active" : "")}
                    {...(editAccess && {onClick: () => setSendingLimit(90)})}
                  >90</div>
                  <Input
                    id="sending-limit-input"
                    className="custom-input"
                    placeholder="enter custom amount"
                    value={sendingLimit}
                    {...(editAccess && {onChange: (e) => setSendingLimit(Number(e.target.value))})}
                    style={{width: "200px"}}
                  />
                </div> */}

                {/* <div className="w-100 fs-6 fw-semibold pb-3">Time gap between emails (minutes)</div>
                <div className="d-flex align-items-center pb-4 mb-4">
                  <div
                    className={"white-outlined-btns fill-blue me-3 "
                    + (editAccess ? "pointer" : "prohibited")
                    + (minTimeBtwEmails === 20 ? " active" : "")}
                    {...(editAccess && {onClick: () => setminTimeBtwEmails(20)})}
                  >20</div>
                  <div
                    className={"white-outlined-btns fill-blue me-3 "
                    + (editAccess ? "pointer" : "prohibited")
                    + (minTimeBtwEmails === 30 ? " active" : "")}
                    {...(editAccess && {onClick: () => setminTimeBtwEmails(30)})}
                  >30</div>
                  <div
                    className={"white-outlined-btns fill-blue me-3 "
                    + (editAccess ? "pointer" : "prohibited")
                    + (minTimeBtwEmails === 45 ? " active" : "")}
                    {...(editAccess && {onClick: () => setminTimeBtwEmails(45)})}
                  >45</div>
                  <div
                    className={"white-outlined-btns fill-blue me-3 "
                    + (editAccess ? "pointer" : "prohibited")
                    + (minTimeBtwEmails === 60 ? " active" : "")}
                    {...(editAccess && {onClick: () => setminTimeBtwEmails(60)})}
                  >60</div>
                  <Input
                    id="sending-limit-input"
                    className="custom-input"
                    placeholder="enter custom amount"
                    value={minTimeBtwEmails}
                    {...(editAccess && {onChange: (e) => setminTimeBtwEmails(Number(e.target.value))})}
                    style={{width: "200px"}}
                  />
                </div> */}

                {/* <div className="fs-5 fw-semibold pb-2">Email Accounts Used</div>
                <div className="small text-secondary pb-4">Remove or Add emails to send to emails from for this campaign.</div>

                <div className="d-flex flex-wrap pb-2">
                  {settingsEmailAccsUsed.current.map((email, index) => (
                    <div
                      key={index}
                      className={"d-flex align-items-center mb-3 " + (index % 2 === 0 ? "me-4" : "flex-grow-100")}
                    >
                      <img
                        src="/assets/images/close-ivory-2x.png"
                        width="18"
                        height="auto"
                        alt="close"
                        className={editAccess ? "pointer" : "d-none"}
                        onClick={() => removeSettingsEmail(email.account_id)}
                      />
                      <Input
                        className="custom-input2 semiheight ms-2"
                        placeholder={email.email}
                        disabled={true}
                        style={{width: "250px"}}
                      />
                    </div>
                  ))}
                </div> */}

                <div className="fs-5 fw-semibold pb-2">Select Emails to use for this Campaign</div>
                <div className="d-flex flex-row">
                <Select mode="multiple" className="mt-2 w-50" value={emailAccounts} 
                style={{ maxHeight: '32px', overflowY: 'auto' }}
                onChange={((e) => {
                  if (e.length > 0) {
                    setDefaultAllMail(false)
                  }
                  // if(e.length < 1) {
                  //   setDefaultAllMail(true)
                  // }
                  setEmailAccounts(e)
                })}>
                  {emailList.map((i) => (
                    <Option key={i.account_id} value={i.account_id}>{i.account_email}</Option>
                  ))}
                </Select>
                <Button type="primary" className="ms-3 mt-2" onClick={() => updateCampaignSettings(true)}>Apply</Button>
                </div>
                <Checkbox className="d-flex mb-2 mt-2"
                // <Checkbox className="d-flex mb-2 mt-2"  disabled={emailAccounts.length <1}
                  checked={defaultAllMail}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setEmailAccounts([])
                    }
                    setDefaultAllMail(e.target.checked)
                  }}>
                  Send emails from all connected email addresses</Checkbox>
                  {editAccess &&
                  <>
                    <div
                      className="lead-status-btn mtng_booked d-inline-flex align-items-center pointer br-13"
                      onClick={gotoAccounts}
                    >
                      <img src="/assets/images/arrow-white-2x.png" width="18" height="auto" alt="arrow" />
                      <span className="text-nowrap ps-2">Connect More</span>
                    </div>

                    <div className="d-flex align-items-start pt-4">
                      <Checkbox
                        onChange={(e) => setStopCampaign(e.target.checked)}
                        checked={stopCampaign}
                      />
                      <span className="small ps-2">Stop outreach to same company when a lead has been marked as a positive response.</span>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Modals */}
      <Modal
        title=" "
        open={analyticsDateErrorModalShown}
        onOk={closeAnalyticsDateErrorModal}
        onCancel={closeAnalyticsDateErrorModal}
        maskClosable={false}
        footer={null}
        centered
        className="bootstrap custom-modal"
      >
        <Result
          status="error"
          title="Error"
          subTitle="Date range must be within 365 days"
          extra={<Button className="black-btn" onClick={closeAnalyticsDateErrorModal}>Close</Button>}
        />
      </Modal>

      <Modal
        title=" "
        open={shareModalShown}
        onOk={closeShareModal}
        onCancel={closeShareModal}
        maskClosable={false}
        footer={null}
        centered
        className="bootstrap custom-modal show-close"
      >
        <div className="d-flex flex-column align-items-center">
          <span className="fw-semibold fs-5 pb-4">Share Campaign</span>
          <span className="small text-center white-text-62 pb-4">Copy your shareable link or export your
          <br />campaign as a CSV to share.</span>
          <Input
            className="custom-input mb-4"
            value={shareUrl}
            suffix={<img
                src="/assets/images/copy-blue-2x.png"
                width="21"
                height="auto"
                alt="arrow"
                className="pointer"
                onClick={copyShareUrl}
              />}
          />
          <div className="small text-center pb-4">
            <span className="text-primary">Remember: </span>
            <span className="white-text-62">This link will not work once the original campaign is deleted</span>
          </div>
          <div className="d-flex align-items-center">
            <div
              className="lead-status-btn s-2 w-150px mtng_booked d-flex justify-content-center align-items-center pointer br-10 me-2"
              onClick={copyShareUrl}
            >
              <img src="/assets/images/link-white-2x.png" width="19" height="auto" alt="arrow" />
              <span className="text-nowrap ps-2">Copy Link</span>
            </div>
            <div
              className="lead-status-btn s-2 w-150px replied d-flex justify-content-center align-items-center pointer br-10 me-2"
              onClick={downloadAllProspects}
            >
              <img src="/assets/images/graph-white-2x.png" width="19" height="auto" alt="arrow" />
              <span className="text-nowrap ps-2">Export CSV</span>
            </div>
          </div>
        </div>
      </Modal>

      <TimeoutErrorModal shown={showTimeoutModal} action={gotoList} close={closeTimeoutModal} />

      {/* Drawers */}
      <Drawer
        title=""
        placement="right"
        width={450}
        closable={false}
        onClose={() => setShowProspectsDetails(false)}
        open={showProspectsDetails}
        key="left"
        className="bootstrap"
      >
        <div className="d-flex align-items-center pb-2 pointer" onClick={() => setShowProspectsDetails(false)}>
          <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
          <span className="ps-2 white-text-62">Close</span>
        </div>

        <div className="d-flex flex-column align-items-center text-white">
          <img src="/assets/images/default-dp-radial-2x.png" width="48" height="auto" alt="arrow" className="mb-4" />

          <div className="fs-6 fw-semibold pb-4">{selectedProspect.current.email ?? ""}</div>

          {/* <div className="d-flex justify-content-center align-items-center pb-4">
            <div className="border-mid-gray br-10 px-3 py-1 d-inline-flex align-items-center me-3">
              <img src="/assets/images/tick-green-2x.png" width="16" height="auto" alt="status" />
              <span className="ps-2 small fw-semibold">Sent</span>
            </div>

            <div className="border-mid-gray br-10 px-3 py-1 d-inline-flex align-items-center me-3">
              <img src="/assets/images/mail-open-cyan-2x.png" width="16" height="auto" alt="status" />
              <span className="ps-2 small fw-semibold">Opened</span>
            </div>

            <div className="border-mid-gray br-10 px-3 py-1 d-inline-flex align-items-center">
              <img src="/assets/images/link-pink-2x.png" width="16" height="auto" alt="status" />
              <span className="ps-2 small fw-semibold">Link Clicked</span>
            </div>
          </div> */}

          <div className="d-flex justify-content-between align-items-center">
          <div
                className={"lead-status-btn d-flex align-items-center pointer br-13 me-2 "
                + (sidebarTab === "details" ? "mtng_booked" : "inactive")}
                onClick={() => setSidebarTab("details")}
              >
                <span className="text-nowrap ps-2">Contact Details</span>
              </div>
              <div
                className={"lead-status-btn d-flex align-items-center pointer br-13 "
                + (sidebarTab === "activity" ? "mtng_booked" : "inactive")}
                onClick={() => setSidebarTab("activity")}
              >
                <span className="text-nowrap ps-2">Lead Activity</span>
              </div>
          </div>

          <div className={"w-100 pt-4 " + (editProspectsDetails ? "d-none" : "d-block")}>  
            <div className={sidebarTab === "details" ? "d-block" : "d-none"}>
              <div className="pb-4">
                <div>Name</div>
                <div className="pt-2 white-text-62">{selectedProspect.current.contact ?? "-"}</div>
              </div>

              <div className="pb-4">
                <div>Email</div>
                <div className="pt-2 white-text-62">{selectedProspect.current.email ?? "-"}</div>
              </div>

              <div className="pb-4">
                <div>Job Title</div>
                <div className="pt-2 white-text-62">{selectedProspect.current.job_title ?? "-"}</div>
              </div>

              <div className="pb-4">
                <div>Company</div>
                <div className="pt-2 white-text-62">{selectedProspect.current.company ?? "-"}</div>
              </div>

              <div className="pb-4">
                <div>Phone</div>
                <div className="pt-2 white-text-62">{selectedProspect.current.phone ?? "-"}</div>
              </div>

              <div className="pb-4">
                <div>Website</div>
                <div className="pt-2 white-text-62">{selectedProspect.current.website ? (
                  <a href={selectedProspect.current.website} target="_blank" rel="noopener noreferrer">
                    {selectedProspect.current.website}
                  </a>
                ) : (
                  "-"
                )}</div>
              </div>

              <div className="pb-4">
                <div>Linkedin</div>
                <div className="pt-2 white-text-62">{selectedProspect.current.linkedin ? (
                  <a href={selectedProspect.current.linkedin} target="_blank" rel="noopener noreferrer">
                    {selectedProspect.current.linkedin}
                  </a>
                ) : (
                  "-"
                )}</div>
              </div>
            </div>
            <div className={sidebarTab === "activity" ? "d-block" : "d-none"}>
            <div className="d-flex justify-content-center align-items-center mt-2 mb-4">
              <div className="d-flex align-items-center pe-4">
                <img src="/assets/images/mail-open-gray-2x.png" width="18" height="auto" alt="icon" />
                <span className="ps-2 fw-semibold fs-6">{leadActivity.current.opened_count ?? 0}</span>
              </div>
              <div className="d-flex align-items-center pe-4">
                <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="18" height="auto" alt="icon" />
                <span className="ps-2 fw-semibold fs-6">{leadActivity.current.replied_count ?? 0}</span>
              </div>
              <div className="d-flex align-items-center">
                <img src="/assets/images/link-gray-2x.png" width="18" height="auto" alt="icon" />
                <span className="ps-2 fw-semibold fs-6">{leadActivity.current.link_clicked_count ?? 0}</span>
              </div>
            </div>

            {leadActivity.current.hasOwnProperty('lead_activity') &&
            leadActivity.current.lead_activity.map((activity, index) => (
              <>
                <div
                  className={"emails-list-row darker mb-3" + (activity.opened ? "" : " d-none")}
                  key={index + "_1"}
                >
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/mail-open-cyan-2x.png" width="12" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">Opened</span>
                    </div>
                    {activity.opened &&
                      <span className="small white-text-62">
                        {moment(activity.open_time).format('ddd, MMM Do @ h A')}
                      </span>
                    }
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <span className="small">{activity.email ?? ""}</span>
                    <span className="small">{activity.step_name ?? ""}, {activity.variant_name ?? ""}</span>
                  </div>
                </div>

                <div
                  className={"emails-list-row darker mb-3" + (activity.replied ? "" : " d-none")}
                  key={index + "_2"}
                >
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/flash-white-2x.png" width="12" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">Replied</span>
                    </div>
                    {activity.replied &&
                      <span className="small white-text-62">
                        {moment(activity.reply_time).format('ddd, MMM Do @ h A')}
                      </span>
                    }
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <span className="small">{activity.email ?? ""}</span>
                    <span className="small">{activity.step_name ?? ""}, {activity.variant_name ?? ""}</span>
                  </div>
                </div>

                <div
                  className={"emails-list-row darker mb-3" + (activity.link_clicked ? "" : " d-none")}
                  key={index + "_3"}
                >
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/link-pink-2x.png" width="12" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">Clicked</span>
                    </div>
                    {activity.link_clicked &&
                      <span className="small white-text-62">
                        {moment(activity.click_time).format('ddd, MMM Do @ h A')}
                      </span>
                    }
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <span className="small">{activity.email ?? ""}</span>
                    <span className="small">{activity.step_name ?? ""}, {activity.variant_name ?? ""}</span>
                  </div>
                </div>

                <div
                  className={"emails-list-row darker mb-3" + (activity.sent ? "" : " d-none")}
                  key={index + "_4"}
                >
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/arrow-cross-blue-2x.png" width="12" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">Sent</span>
                    </div>
                    {activity.sent &&
                      <span className="small white-text-62">
                        {moment(activity.sent_time).format('ddd, MMM Do @ h A')}
                      </span>
                    }
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <span className="small">{activity.email ?? ""}</span>
                    <span className="small">{activity.step_name ?? ""}, {activity.variant_name ?? ""}</span>
                  </div>
                </div>
              </>
            ))}
          </div>
            <div className={sidebarTab === "details" ? "d-block" : "d-none"}>
              <div className="d-flex justify-content-end">
                <Button type="primary" className="br-10" onClick={() => setEditProspectsDetails(true)}>Edit</Button>
              </div>
            </div>
          </div>

          <div className={"w-100 pt-4 " + (editProspectsDetails ? "d-block" : "d-none")}>
            <Form
              form={editProspectsDetailsForm}
              onFinish={saveProspectsDetails}
            >
              <div className="pb-2">Name</div>
              <Form.Item
                name="first_name"
                rules={[{required: true, message: "Enter a name"}]}
                className="w-75"
              >
                <Input className="custom-input"/>
              </Form.Item>

              <div className="pb-2">Email</div>
              <Form.Item
                name="email"
                rules={[{required: true, type: "email",  message: "Enter an email"}]}
                className="w-75"
              >
                <Input className="custom-input"/>
              </Form.Item>

              <div className="pb-2">Job Title</div>
              <Form.Item
                name="role"
                rules={[{required: true, message: "Enter a title"}]}
                className="w-75"
              >
                <Input className="custom-input"/>
              </Form.Item>

              <div className="pb-2">Company</div>
              <Form.Item
                name="company_name"
                rules={[{required: true, message: "Enter a name"}]}
                className="w-75"
              >
                <Input className="custom-input"/>
              </Form.Item>

              <div className="pb-2">Phone</div>
              <Form.Item
                name="phone_number"
                rules={[{required: true, message: "Enter Phone Number"}]}
                className="w-75"
              >
                <Input className="custom-input"/>
              </Form.Item>

              <div className="pb-2">Website</div>
              <Form.Item
                name="website"
                rules={[{required: true, message: "Enter a Website"}]}
                className="w-75"
              >
                <Input className="custom-input"/>
              </Form.Item>

              <div className="pb-2">LinkedIn</div>
              <Form.Item
                name="linkedin_profile"
                rules={[{required: true, message: "Enter an URL"}]}
                className="w-75"
              >
                <Input className="custom-input"/>
              </Form.Item>

              <div className="d-flex justify-content-end">
                <Button type="primary" className="br-10" htmlType="submit">Save</Button>
              </div>
            </Form>
          </div>
        </div>
      </Drawer>

      <Drawer
        title=""
        placement="right"
        width={450}
        closable={false}
        onClose={() => setShowUploadProspects(false)}
        open={showUploadProspects}
        key="left"
        className="bootstrap"
      >
        <div className="d-flex align-items-center pb-2 pointer" onClick={() => setShowUploadProspects(false)}>
          <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
          <span className="ps-2 white-text-62">Close</span>
        </div>

        <div className={"d-flex flex-column justify-content-center" + (importCsvScreen === 2 ? " d-none" : "")} style={{height: "calc(100% - 38px)"}}>
          <div id="upload-csv-holder" className="w-50 pb-4 mx-auto">
            <Dragger {...csvDraggableProps}>
              <div className="d-flex align-items-center justify-content-center">
                <img src="/assets/images/export-gray-2x.png" width="14" height="auto" alt="export" />
                <span className="ps-2 fw-semibold">Upload CSV</span>
              </div>
            </Dragger>
          </div>

          <div className="text-center text-white py-4">Drag and Drop a CSV file to<br />begin the import.</div>
          <div className="d-flex flex-column">
            <Checkbox
              checked={importLead}
              onChange={(e) => setImportLead(e.target.checked)}
              className="d-flex mb-4"
            >
              Import Leads Even If They Are In The Global Block List
            </Checkbox>
            <Checkbox
              checked={ignoreLead}
              onChange={(e) => setIgnoreLead(e.target.checked)}
              className="d-flex"
            >
              Ignore The Leads That Exist In Another Campaign
            </Checkbox>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Button type="primary" className="br-10" onClick={csvUpload}>Upload</Button>
          </div>
        </div>

        <div id="upload-csv-mapping-parent" className={"right-sidebar-parent text-white"
            + (importCsvScreen === 2 ? "" : " d-none")}>
              <div className="d-flex align-items-center pb-4">
                <img src="/assets/images/folder-gray-2x.png" width="18" height="auto" alt="folder" />
                <span className="px-2">{csvMappings.current.file_name}</span>
                <img
                  src="/assets/images/close-white-2x.png"
                  width="14"
                  height="auto"
                  alt="close"
                  className="pointer"
                  onClick={() => setImportCsvScreen(0)}
                />
              </div>
              <Row>
                <Col span={24}>
                  <Row gutter={30} align="middle">
                    <Col span={8} className="gutter-row">
                      <div className="text-secondary small pb-3">Column Name</div>
                    </Col>
                    <Col span={16} className="gutter-row">
                      <div className="text-secondary small pb-3">Select Type</div>
                    </Col>
                  </Row>
                  <Form onFinish={postCsvMappings}>
                    {csvMappings.current.columns.map((column, index) => {
                      return (
                        <Row gutter={30} align="middle" className="pb-4" key={index}>
                          <Col span={8} className="gutter-row">{column}</Col>
                          <Col span={16} className="gutter-row">
                            <Form.Item
                              name={column}
                              className="pb-0"
                              rules={[{required: true, message: "please enter an option"}]}
                            >
                              <Select
                                className="w-100 custom-select3 csv-mapping"
                                options={csvPeopleOptions}
                                placeholder="Select Type"
                                suffixIcon={<DropdownArrow />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )
                    })}
                    <Row gutter={30} className="pb-4" justify="center">
                      <Col className="gutter-row">
                        <div
                          className="w-150px lead-status-btn generate d-flex align-items-center justify-content-center
                          br-13 pointer"
                          onClick={triggerPostCsv}
                        >
                          <img src="/assets/images/stars-white-2x.png" width="auto" height="24" alt="stars" />
                          <span className="text-nowrap ps-2">save</span>
                        </div>
                        <Button id="post-csv-btn" htmlType="submit" className="d-none">Save</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>              
            </div>
      </Drawer>

      <Modal
        title="Send Test Email"
        open={sendTestMail}
        onCancel={() => setSendTestMail(false)}
        footer={null}
        centered
        className="bootstrap custom-modal show-close"
      >
        {loadingModal === 1 ?
          <div className={"pt-4 justify-content-center"} style={{ display: "flex" }}>
            <Spin size="large" />
          </div> :
          <div className="d-flex flex-column align-items-center">
            <div className="pb-3">
              Send email to your email id to test the email has sent properly.
            </div>
            <Input value={testMailId} onChange={(e) => {
              setTestMailId(e.target.value)
            }} />
            <div className="lead-status-btn generate-copy d-flex align-items-center justify-content-center pointer br-13 me-2 w-100 mt-3"
              onClick={sendTestEmail}>
              <span className="text-nowrap ps-2">Send Test Email</span>
            </div>
          </div>}
      </Modal>

    </Layout>
  )
}

export default CampaignsDetails;
