import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Checkbox, Col, Dropdown, Input, Layout, Modal, Row, Slider, Space, Form, Select, Upload, Drawer } from 'antd';
import { EditIcon, } from '../../assets/svg/Icons';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '../../store/slices/authSlice';
import ImageComponent from '../../component/ImageComponent';
import axiosInstance from '../../config/axiosConfig';
import { notification } from 'antd';
import { useLoader } from '../../store/loaderContext';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from "../../config/AppConfig";
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { setCompany as setCompanyRedux } from "../../store/slices/companySlice";
import SlackButton from '../../component/SlackButton';

const { Option } = Select;
const { Dragger } = Upload;
const days = [0, 1, 2, 3, 4, 5, 6];
const daysLabel = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const leadStatusOptions = [
  { label: "Do not contact", value: "Do not contact" },
  { label: "Information Request", value: "Information Request" },
  { label: "Out of Office", value: "Out of Office" },
  { label: "Wrong Person", value: "Wrong Person" },
  { label: "Interested", value: "Interested" },
  { label: "Meeting Request", value: "Meeting Request" },
  { label: "Not Interested", value: "Not Interested" },
]
const engageTimeOptions = [
  { label: "00:00", value: "00:00" },
  { label: "01:00", value: "01:00" },
  { label: "02:00", value: "02:00" },
  { label: "03:00", value: "03:00" },
  { label: "04:00", value: "04:00" },
  { label: "05:00", value: "05:00" },
  { label: "06:00", value: "06:00" },
  { label: "07:00", value: "07:00" },
  { label: "08:00", value: "08:00" },
  { label: "09:00", value: "09:00" },
  { label: "10:00", value: "10:00" },
  { label: "11:00", value: "11:00" },
  { label: "12:00", value: "12:00" },
  { label: "13:00", value: "13:00" },
  { label: "14:00", value: "14:00" },
  { label: "15:00", value: "15:00" },
  { label: "16:00", value: "16:00" },
  { label: "17:00", value: "17:00" },
  { label: "18:00", value: "18:00" },
  { label: "19:00", value: "19:00" },
  { label: "20:00", value: "20:00" },
  { label: "21:00", value: "21:00" },
  { label: "22:00", value: "22:00" },
  { label: "23:00", value: "23:00" }
]
const holidaysList = [
  {
    label: "New Years",
    value: "New Years",
  },
  {
    label: "Martin Luther King Jr. Day",
    value: "Martin Luther King Jr. Day",
  },
  {
    label: "President's Day",
    value: "President's Day",
  },
  {
    label: "Memorial Day",
    value: "Memorial Day",
  },
  {
    label: "Juneteenth",
    value: "Juneteenth",
  },
  {
    label: "Independence Day",
    value: "Independence Day",
  },
  {
    label: "Labor Day",
    value: "Labor Day",
  },
  {
    label: "Columbus Day",
    value: "Columbus Day",
  },
  {
    label: "Veteran's Day",
    value: "Veteran's Day",
  },
  {
    label: "Thanksgiving",
    value: "Thanksgiving",
  },
  {
    label: "Thanksgiving (Full week)",
    value: "Thanksgiving (Full week)",
  },
  {
    label: "Christmas",
    value: "Christmas",
  },
  {
    label: "Christmas (Full week)",
    value: "Christmas (Full week)",
  },
];

const PersonaSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [form] = Form.useForm();
  const [hubspotApiKeyForm] = Form.useForm();
  const [smartleadsApiKeyForm] = Form.useForm();
  const [collaboratorForm] = Form.useForm();
  const { setShowLoader } = useLoader();

  const pageLoaded = useRef(false);
  const blocklistType = useRef("all");
  const billingData = useRef(null);

  const [tab, setTab] = useState("general")
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [settingsData, setSettingsData] = useState({});
  const [blockContact, setBlockContact] = useState("");
  const [showBlockListError, setShowBlockListError] = useState(false);

  const [showCompanyError, setShowCompanyError] = useState(false);
  const [showWebsiteError, setShowWebsiteError] = useState(false);
  const [showPricingError, setShowPricingError] = useState(false);
  const [hubspotApiKeyModalOpen, setHubspotApiKeyModalOpen] = useState(false);
  const [hubspotDisconnectModalOpen, setHubspotDisconnectModalOpen] = useState(false);
  const [smartleadsApiKeyModalOpen, setSmartleadsApiKeyModalOpen] = useState(false);
  const [smartleadsDisconnectModalOpen, setSmartleadsDisconnectModalOpen] = useState(false);
  const [emailDays, setEmailDays] = useState([]);
  const [emailStartHour, setEmailStartHour] = useState("09:00");
  const [emailEndHour, setEmailEndHour] = useState("18:00");
  const [engageDoNotSendEmail, setEngageDoNotSendEmail] = useState(false);
  const [syncLeadStatus, setSyncLeadStatus] = useState(false);
  const [linkedInEmailDays, setLinkedInEmailDays] = useState([]);
  const [linkedInEmailStartHour, setLinkedInEmailStartHour] = useState("09:00");
  const [linkedInEmailEndHour, setLinkedInEmailEndHour] = useState("18:00");
  const [linkedInDoNotSendEmail, setLinkedInDoNotSendEmail] = useState(false);
  const [linkedInSyncLeadStatus, setLinkedInSyncLeadStatus] = useState(false);
  const [stopCampaignsOnHolidays, setStopCampaignsOnHolidays] = useState(false);
  const [hubspotField, setHubspotField] = useState(false);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState();
  const [selectedHubspotField, setSelectedHubspotField] = useState();
  const [selectedHubspot, setSelectedHubspot] = useState();
  const [selectedHolidayList, setSelectedHolidayList] = useState([]);
  const [showUploadList, setShowUploadList] = useState(false);
  const [importedCsvFile, setImportedCsvFile] = useState();
  const [isCompany, setIsCompany] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const isSuperAdmin = authData.userInfo.is_super_admin;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get(`/settings/get_settings/${companyId}?user_id=${userId}`);

      setCustomEmails([...response.data.collaborators]);

      setEmailDays(response.data.engage_settings.days_of_the_week.length
        ?
        [...response.data.engage_settings.days_of_the_week]
        :
        [2]
      )
      setEmailStartHour(response.data.engage_settings.start_hour);
      setEmailEndHour(response.data.engage_settings.end_hour);
      setEngageDoNotSendEmail(response.data.engage_settings.emails_to_leads_another_campaign);
      setSelectedHolidayList(response.data.engage_settings.holiday_list);
      setStopCampaignsOnHolidays(response.data.engage_settings.is_holiday);
      setSyncLeadStatus(response.data.engage_settings.sync_lead_status);
      setLinkedInEmailDays(response?.data?.linkedin_settings?.days_of_the_week?.length
        ?
        [...response?.data?.linkedin_settings?.days_of_the_week]
        :
        [2]
      )
      setLinkedInEmailStartHour(response?.data?.linkedin_settings?.start_hour);
      setLinkedInEmailEndHour(response?.data?.linkedin_settings?.end_hour);
      setLinkedInDoNotSendEmail(response?.data?.linkedin_settings?.emails_to_leads_another_campaign);
      setLinkedInSyncLeadStatus(response?.data?.linkedin_settings?.sync_lead_status);

      setSettingsData(response?.data);
      if (response?.data?.hubspost_synced === true) {
        fetchHubspotFields()
        fetchSelectedHubspotFields()
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Settings' });
    }

    setShowLoader(false);
  }

  const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  };

  const [editMode, setEditMode] = useState(false);
  const [customEmails, setCustomEmails] = useState([]);

  const SubmitButton = (
    <ImageComponent src="/assets/svg/submit-button.svg" className="pointer" style={{ width: '20px' }} />
  )

  const setBlocklistType = ({ key }) => {
    blocklistType.current = key;
    fetchBlockedContacts(key);
  }

  const fetchBlockedContacts = async (type = blocklistType.current) => {
    setShowLoader(true);

    if (blocklistType.current === "all") {
      fetchSettings();
      return;
    }

    try {
      const response = await axiosInstance.get(`/settings/block/list/${userId}?company_id=${companyId}&block_type=${blocklistType.current}`);

      const currentSettingsData = { ...settingsData };
      currentSettingsData.block_contact = [...response.data];

      setSettingsData({ ...currentSettingsData });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching blocked contacts' });
      setShowLoader(false);
    }

    setShowLoader(false);
  }

  const addBlocklist = async () => {
    setShowLoader(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const urlRegex = /^(https?:\/\/)?((([a-zA-Z\d-]+)\.)+[a-zA-Z]{2,})(:\d{1,5})?([/?#]\S*)?$/;

    if (!(emailRegex.test(blockContact) || urlRegex.test(blockContact))) {
      setShowLoader(false);
      setShowBlockListError(true);
      return;
    } else {
      setShowBlockListError(false);
    }

    try {
      const response = await axiosInstance.post(`settings/block_list/${companyId}?user_id=${userId}`, { value: blockContact });
      if (response.data.leads !== null) {
        let selectLeads = response.data.leads
        Modal.confirm({
          title: <div style={{ color: "#ffffff" }}>Block leads</div>,
          content: (
            <div style={{ color: "#ffffff", height: "100px", overflowY: "auto" }} >
              <div>
                The following lead exists in running campaigns. Do you still wish to block?
              </div>
              <div>
                {response.data.leads.lead_email}
              </div>
            </div>
          ),
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: async () => {
            try {
              const payload = {
                company_id: companyId,
                leads: []
              }
              payload.leads.push(selectLeads.lead_id)
              const response = await axiosInstance.post(`settings/block/pause_lead/${userId}`, payload);
              if (response.status === 200) {
                setTimeout(async () => {
                  notification.success({ message: 'Success', description: 'Leads blocked successfully' });
                }, 1000);
              }
              else {
                notification.error({ message: 'Error', description: 'Error in blocking leads' });
              }
            } catch (error) {
              notification.error({ message: 'Error', description: 'Error in blocking leads' });
            }
          },
        });
      }
      else {
        notification.success({ message: 'Success', description: 'Added to Block list' });
      }
      setBlockContact("");
      fetchBlockedContacts();
    } catch (error) {
      notification.error({ message: "Duplicate Data", description: "This entry already exists in the list" });
      setShowLoader(false);
    }
  };

  const deleteBlockedContact = async id => {
    setShowLoader(true);

    try {
      await axiosInstance.delete(`settings/blocked-contacts/${id}?user_id=${userId}&company_id=${companyId}`);
      notification.success({ message: 'Success', description: 'Deleted contact from Block list' });

      fetchBlockedContacts();
    } catch (error) {
      notification.error({ message: 'Oops!', description: 'Something went wrong' });
      setShowLoader(false);
    }
  }

  const createProduct = () => {
    setIsModalVisible(true)
  }

  const handleCloseModal = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const closeHubspotApiKeyModal = () => {
    setHubspotApiKeyModalOpen(false);
  }

  const closeHubspotDisconnectModal = () => {
    setHubspotDisconnectModalOpen(false);
  }

  const closeSmartleadsApiKeyModal = () => {
    setSmartleadsApiKeyModalOpen(false);
  }

  const closeSmartleadsDisconnectModal = () => {
    setSmartleadsDisconnectModalOpen(false);
  }

  const submitForm = (values) => {
    console.log('values', values);
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        // Validation succeeded
        submitForm(values);
      })
      .catch((errors) => {
        // Validation failed
        console.log('Form validation errors:', errors);
      });
  }

  const renderProducts = (item) => {
    const productText = (item.product_name) ? `Product - ${item.product_name}` : 'Product'
    return (
      <>
        {renderCard(`company_description-${item.product_id}`, "enter company description", item.company_description, "Description", "Edit", "company_information")}
        {renderCard(`product_description-${item.product_id}`, "enter product description", item.product_description, productText, "Edit Product Description", "company_information", `product_name-${item.product_id}`, item.product_name)}
        {renderCard(`product_price-${item.product_id}`, "enter product price", item.product_price, "Pricing", "Edit", "company_information")}
      </>
    )
  }

  const renderCard = (
    key, placeholder, value, title,
    editLabel = 'Edit', updateObject = "company",
    productKey = "", productValue = ""
  ) => {
    return (
      <Card className="custom-card mb-4">
        {(productKey === "") && <h3 className="card-title mb-2">{title}</h3>}
        {(productKey != "") &&
          <>
            {(editMode != productKey) && <h3 className="card-title mb-2">{title}</h3>}
            {editMode === productKey &&
              <>
                <Input
                  id={productKey}
                  defaultValue={productValue}
                  placeholder={"enter product name"}
                  className="settings-enter-email custom-search-input company_website"
                  suffix={
                    <div
                      className="arrow-parent pointer"
                      onClick={(e) => edit(productKey, updateObject)}>➜</div>
                  }
                />
              </>
            }
          </>
        }
        {editMode === key &&
          <>
            <Input
              id={key}
              defaultValue={value}
              placeholder={placeholder}
              className="settings-enter-email custom-search-input company_website"
              suffix={
                <div
                  className="arrow-parent pointer"
                  onClick={(e) => edit(key, updateObject)}>➜</div>
              }
            />
            {(key.includes("name") && showCompanyError) &&
              <div className="w-100 pt-2" style={{ color: "#dc4446" }}>Enter a valid Company Name</div>
            }
            {(key.includes("website") && showWebsiteError) &&
              <div className="w-100 pt-2" style={{ color: "#dc4446" }}>Enter a valid website</div>
            }
            {(key.includes("product_price") && showPricingError) &&
              <div className="w-100 pt-2" style={{ color: "#dc4446" }}>Enter valid pricing</div>
            }
          </>
        }
        {editMode !== key ? (title === "Company Website" ? <a href={value && (value.startsWith('http') ? value : `https://${value}`)}
          target="_blank" rel="noopener noreferrer">{value}
        </a> :
          <p className="card-subtitle mb-0">{
            (value) ? value : placeholder
          }</p>) : null}

        {editAccess &&
          <div className="card-top-right">
            <Space>
              {
                (productKey != "") &&
                <div className="d-flex align-items-center pointer" onClick={() => setEditMode(productKey)}>
                  <EditIcon />
                  <span className="card-subtitle ps-2">Edit Product Name</span>
                </div>
              }
              {/* <div className="card-top-right"> */}
              <div className="d-flex align-items-center pointer" onClick={() => setEditMode(key)}>
                <EditIcon />
                <span className="card-subtitle ps-2">{editLabel}</span>
              </div>
              {/* </div> */}
            </Space>
          </div>
        }
      </Card>
    )
  }

  const saveHubspotApiKey = async ({ api_key }) => {
    closeHubspotApiKeyModal();
    setShowLoader(true);

    try {
      const data = { api_key };

      await axiosInstance.put(`/settings/update/hubspot_key/${companyId}?user_id=${userId}`, data);
      notification.success({ message: 'Success', description: 'Hubspot API key has been saved successfully' });
      fetchHubspotFields()
      fetchSelectedHubspotFields()
    } catch (error) {
      setHubspotApiKeyModalOpen(true);
      notification.error({ message: 'Error', description: 'Error in saving the Hubspot API key' });
    }

    const data = { ...settingsData };
    data.hubspost_synced = true;

    setSettingsData({ ...data });
    setShowLoader(false);
  }

  const disconnectHubspotApiKey = async () => {
    closeHubspotDisconnectModal();
    setShowLoader(true);

    try {
      await axiosInstance.put(`/settings/disconnect_hubspot/${companyId}?user_id=${userId}`);
      notification.success({ message: 'Success', description: 'Hubspot has been disconnected successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in disconnecting Hubspot' });
    }

    const data = { ...settingsData };
    data.hubspost_synced = false;

    setSettingsData({ ...data });
    setShowLoader(false);
  }

  const saveSmartleadsApiKey = async ({ api_key }) => {
    closeSmartleadsApiKeyModal();
    setShowLoader(true);

    try {
      const data = { api_key };

      await axiosInstance.put(`/settings/update/smartlead_key/${companyId}?user_id=${userId}`, data);
      notification.success({ message: 'Success', description: 'Smartlead API key has been saved successfully' });
      dispatch(setCompanyRedux({company_id: Number(companyId), user_id: Number(userId), sl_key: 'true'}));
    } catch (error) {
      setSmartleadsApiKeyModalOpen(true);
      notification.error({ message: 'Error', description: 'Error in saving the Smartlead API key' });
    }

    const data = { ...settingsData };
    data.smartlead_synced = true;

    setSettingsData({ ...data });
    setShowLoader(false);
  }

  const disconnectSmartleadsApiKey = async () => {
    closeSmartleadsDisconnectModal();
    setShowLoader(true);

    try {
      await axiosInstance.put(`/settings/disconnect_smartlead/${companyId}?user_id=${userId}`);
      notification.success({ message: 'Success', description: 'Smartlead has been disconnected successfully' });
      dispatch(setCompanyRedux({company_id: Number(companyId), user_id: Number(userId), sl_key: 'false'}));
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in disconnecting Smartlead' });
    }

    const data = { ...settingsData };
    data.smartlead_synced = false;

    setSettingsData({ ...data });
    setShowLoader(false);
  }

  const handleCheckboxChange = (event) => {
    setShowLoader(true);

    const newValue = event.target.checked;
    const payload = {
      ...settingsData.company,
    }
    payload['sync_hubspot'] = newValue
    updateSettings({ data: payload }, `/settings/update/company/${companyId}?user_id=${userId}`)
  };

  const updateSettings = async (payload, endPoint) => {
    try {
      const response = await axiosInstance.put(endPoint, payload);
      notification.success({ message: 'Success', description: 'Settings updated' });
      fetchSettings()
      setEditMode(false);
    } catch (error) {
      notification.error({ message: 'Oops!', description: 'Something went wrong' });
      setShowLoader(false)
    }
  }

  const updateProduct = (key, value) => {
    let productId = key.split('-')[1]
    let productKey = key.split('-')[0]
    const allProducts = [
      ...settingsData.company_information
    ]
    const [filteredProduct] = allProducts.filter((item) => item.product_id == productId)
    const payload = { ...filteredProduct }
    payload[productKey] = value
    delete payload.product_id
    updateSettings({ data: payload }, `/settings/update/company_information/${companyId}?user_id=${userId}&product_id=${productId}`)
  }

  const validateCompanyName = name => {
    const regex = /^[a-zA-Z0-9]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/;

    if (regex.test(name) === false) {
      setShowCompanyError(true);
      return false;
    } else {
      setShowCompanyError(false);
      return true;
    }
  }

  const validateWebsite = url => {
    const urlRegex = /^(https?:\/\/)?((([a-zA-Z\d-]+)\.)+[a-zA-Z]{2,})(:\d{1,5})?([/?#]\S*)?$/;

    if (urlRegex.test(url) === false) {
      setShowWebsiteError(true);
      return false;
    } else {
      setShowWebsiteError(false);
      return true;
    }
  }

  const validatePricing = price => {
    const priceRegex = /^\$?([1-9]{1}[0-9]{0,2}(\,[0-9]{3})*(\.[0-9]{0,2})?|[1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/g;

    if (priceRegex.test(price) === false) {
      setShowPricingError(true);
      return false;
    } else {
      setShowPricingError(false);
      return true;
    }
  }

  const edit = (property, updateObject) => {
    setShowLoader(true);
    const value = document.getElementById(property).value;

    switch (updateObject) {
      case 'company':
        if (property.includes("name") && validateCompanyName(value) === false) {
          setShowLoader(false);
          break;
        }

        if (property.includes("website") && validateWebsite(value) === false) {
          setShowLoader(false);
          break;
        }

        const payload = {
          ...settingsData.company,
        }
        payload[property] = value
        updateSettings({ data: payload }, `/settings/update/company/${companyId}?user_id=${userId}`)
        break;
      case 'company_information':
        if (property.includes("product_price") && validatePricing(value) === false) {
          setShowLoader(false);
          break;
        }

        updateProduct(property, value)
        break;
      case 'market_information':
        const marketPayload = {
          ...settingsData.market_information,
        }
        marketPayload[property] = value
        updateSettings({ data: marketPayload }, `/settings/update/market_information/${companyId}?user_id=${userId}`)
        break;
      default:
        setEditMode(false);
        setShowLoader(false)
        break;
    }
  }

  const addEmail = async values => {
    setShowLoader(true);

    const emails = [...customEmails];

    const payload = {
      email: values.email,
      company_id: companyId,
      role_id: values.role
    }

    try {
      const response = await axiosInstance.post(`/settings/add_collaborator/${userId}`, payload);

      emails.push({
        collaborator_id: response.data.collaborator_id,
        collaborator_email: values.email,
        role_id: values.role
      });

      setCustomEmails([...emails]);

      collaboratorForm.resetFields();
      notification.success({ message: 'Success', description: 'Collaborator has been added successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in adding Collaborator' });
    }

    setShowLoader(false);
  }

  const deleteEmail = async (emailIndex, emailId) => {
    setShowLoader(true);

    const emails = [...customEmails];
    const payload = { collaborator_id: emailId, company_id: companyId };

    try {
      await axiosInstance.delete(`/settings/delete_collaborator/${userId}`, { data: payload });
      notification.success({ message: 'Success', description: 'Collaborator has been deleted successfully' });

      emails.splice(emailIndex, 1);
      setCustomEmails([...emails]);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in deleting the Collaborator' });
    }

    setShowLoader(false);
  }

  const signOut = async () => {
    try {
      const payload ={
        userid: Number(userId),
        company_id: Number(companyId),
        login: false
      }
			axiosInstance.post(`/login/auth_update`, payload);
		} catch (error) {
      console.log("error", error)
	  }
    dispatch(setToken(null));

    localStorage.removeItem('authToken');
    localStorage.removeItem('persist:root');
    navigate("/auth");
  }

  const getBillingData = async () => {
    setShowLoader(true);

    try {
      const response = await axiosInstance.get(`/settings/billing/${userId}?company_id=${companyId}`);
      const { data } = response;

      if (data.prospected_count === data.prospect_limit) {
        data.full = true;
      } else {
        data.full = false;
      }

      data.percent = (data.prospected_count / data.prospect_limit) * 100;
      console.log(data.percent)

      billingData.current = { ...data };
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Billing settings' });
    }

    setShowLoader(false);
  }

  const setEmailDay = (day, linkedIn) => {
    const days = linkedIn ? [...linkedInEmailDays] : [...emailDays];
    const index = days.indexOf(day);

    if (index > -1 && days.length > 1) {
      days.splice(index, 1);
    } else {
      days.push(day);
    }
    if(linkedIn) {
      setLinkedInEmailDays([...days]);
    }
    else {
      setEmailDays([...days])
    }
  }

  const fetchHubspotFields = async () => {
    try {
      const response = await axiosInstance.get(`/settings/list/hs_status/${userId}?company_id=${companyId}`);
      setHubspotField(response.data)
    }
    catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching hubspot fields' });
    }
  }

  const fetchSelectedHubspotFields = async () => {
    try {
      const response = await axiosInstance.get(`/settings/fetch/hs_status/${userId}?company_id=${companyId}`);
      setSelectedHubspot(response.data)
      setSelectedHubspotField(response.data.hs_field)
      setSelectedLeadStatus(response.data.sl_fields)
    }
    catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching selected fields' });
    }
  }

  const updateEngageSettings = async () => {
    setShowLoader(true);

    const payload = {
      days_of_the_week: [...emailDays],
      start_hour: emailStartHour,
      end_hour: emailEndHour,
      emails_to_leads_another_campaign: engageDoNotSendEmail,
      sync_lead_status: syncLeadStatus,
      is_holiday: stopCampaignsOnHolidays,
      holiday_list: selectedHolidayList
    }

    try {
      await axiosInstance.put(`/settings/update_engage_settings/${companyId}?user_id=${userId}`, payload);
      notification.success({ message: 'Success', description: 'Engage settings has been updated successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in updating Engage settings' });
    }

    setShowLoader(false);
  }

  const updateLinkedInSettings = async () => {
    setShowLoader(true);

    const payload = {
      days_of_the_week: [...linkedInEmailDays],
      start_hour: linkedInEmailStartHour,
      end_hour: linkedInEmailEndHour,
      emails_to_leads_another_campaign: linkedInDoNotSendEmail,
      sync_lead_status: linkedInSyncLeadStatus,
      // is_holiday: stopCampaignsOnHolidays,
      // holiday_list: selectedHolidayList
    }

    try {
      await axiosInstance.put(`/linkedin_settings/update_linkedin_settings/${companyId}?user_id=${userId}`, payload);
      notification.success({ message: 'Success', description: 'LinkedIn settings has been updated successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in updating linkedIn settings' });
    }

    setShowLoader(false);
  }

  const saveHubspotField = async () => {
    const payload = {
      company_id: companyId,
      sl_fields: selectedLeadStatus,
      hs_field: selectedHubspotField
    }
    try {
      await axiosInstance.post(`/settings/save/hs_status/${userId}`, payload);
      notification.success({ message: 'Success', description: 'Hubspot fields mapped successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in mapping hubspot fields' });
    }
  }

  const dummyUpload = async ({ onSuccess }) => {
    onSuccess("Ok");
  }

  const csvDraggableProps = {
    name: 'csv_file',
    multiple: false,
    maxCount: 1,
    customRequest: dummyUpload,
    beforeUpload(file) {
      setShowLoader(true);
      setImportedCsvFile(file);

      setShowLoader(false);
    }
  };




  const csvUpload = async () => {
    setShowUploadList(false);
    setShowLoader(true);

    const formData = new FormData();
    formData.append('file', importedCsvFile);
    const dataType = isCompany === true ? "company" : "people"
    const config = {
      method: 'post',
      baseURL: API_BASE_URL,
      url: `/settings/block/upload_csv/${userId}?data_type=${dataType}&company_id=${companyId}`,
      headers: {
        Authorization: `Bearer ${authData.token}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    };

    const res = await axios(config)
      .then(function (res) {
        return res.data;
      })
      .catch(function (err) {
        if (err.request || err.response) {
          console.error(err);
          return false;
        } else {
          return null;
        }
      });

    if (res) {
      // if (res.leads.length > 0) {
      //   let allLeads = [...res.leads]
      //   // allLeads = allLeads.map(lead => ({
      //   //   ...lead,
      //   //   selected: false
      //   // }));
      //   setSelectedLeads([...allLeads])

      //   Modal.confirm({
      //     title: <div style={{ color: "#ffffff" }}>Block leads</div>,
      //     content: (
      //       <div style={{ color: "#ffffff", height: "200px", overflowY : "auto" }} >
      //         <div>
      //           The following leads exists in running campaigns. If you still wish to block, 
      //           please select the leads which you want to block in existing campaigns?
      //         </div>
      //         <div>
      //     <ul>
      //       {allLeads.map((lead, index) => (
      //         <li key={index}>
      //           <div className='d-flex flex-row'>
      //             <Checkbox
      //               onChange={(e) => { 
      //                 allLeads = allLeads.map(i => i.lead_id === lead.lead_id ? { ...i, selected: e.target.checked } : i);
      //                 setSelectedLeads([...allLeads]);
      //                 }}
      //             />
      //             <div style={{ marginLeft: "2%" }}>{lead.lead_email}</div>
      //           </div>
      //         </li>
      //       ))}
      //     </ul>
      //   </div>
      // </div>
      //     ),
      //     okText: 'OK',
      //     cancelText: 'Cancel',
      //     onOk: async () => {
      //       if(allLeads.filter(lead => lead.selected === true).length > 0) {
      //       try {
      //         const payload = {
      //           company_id: companyId,
      //           leads: allLeads.filter(lead => lead.selected === true).map(i => i.lead_id),
      //         }
      //         const response = await axiosInstance.post(`settings/block/pause_lead/${userId}`, payload);
      //         if (response.status === 200) {
      //           setTimeout(async () => {
      //             notification.success({ message: 'Success', description: 'Leads blocked successfully' });
      //           }, 1000);
      //         }
      //         else {
      //           notification.error({ message: 'Error', description: 'Error in blocking leads' });
      //         }
      //       } catch (error) {
      //         notification.error({ message: 'Error', description: 'Error in blocking leads' });
      //       }
      //     }
      //     },
      //   });
      // }
      // else {
      notification.success({ message: 'Success', description: 'Leads are being blocked' });
      // }
      fetchSettings()
    } else if (res === false) {
      notification.error({ message: 'Error', description: 'Error in Uploading the file' });
    }
    setShowLoader(false);
  }

  useEffect(() => {
    if (pageLoaded.current) {
      return;
    }

    pageLoaded.current = true;
    if(state?.linkedIn) {
      setTab("linkedin")
    }
    setShowLoader(true);
    fetchSettings();
  }, []);

  useEffect(() => {

   // pageLoaded.current = true;

    setShowLoader(true);
    fetchSettings();
  },[]);

  useEffect(() => {
    if (pageLoaded.current === false || tab !== "engage") {
      return;
    }
    let startHour = parseInt(emailStartHour.split(":")[0], 10)
    let endHour = parseInt(emailEndHour.split(":")[0], 10)
    if(startHour > endHour) {
      return;
    }
    updateEngageSettings();
  }, [emailDays, emailStartHour, emailEndHour, engageDoNotSendEmail, syncLeadStatus, stopCampaignsOnHolidays, selectedHolidayList]);
  
  useEffect(() => {
    if (pageLoaded.current === false || tab !== "linkedin") {
      return;
    }
    let linkedInStartHour = parseInt(linkedInEmailStartHour.split(":")[0], 10)
    let linkedInEndHour = parseInt(linkedInEmailEndHour.split(":")[0], 10)
    if(linkedInStartHour > linkedInEndHour) {
      return
    }
    updateLinkedInSettings();
  }, [linkedInEmailDays, linkedInEmailStartHour, linkedInEmailEndHour, linkedInDoNotSendEmail, linkedInSyncLeadStatus]);

  return (
    <Layout style={{ minHeight: '100vh', padding: 15 }} className="bootstrap">
      {/* Page Title */}
      <Row gutter={30} className="pb-3">
        <Col span={12} className="gutter-row">
          <div className="analytics-heading font-weight-500 px-2">Settings</div>
        </Col>
      </Row>

      {/* Tabs */}
      <Row gutter={30} className="pb-3">
        <Col span={24} className="gutter-row d-flex align-items-center">
          <div
            className={"w-120px lead-status-btn pointer br-13 me-2 "
              + (tab === "general" ? "mtng_booked" : "inactive")}
            onClick={() => setTab("general")}
          >General</div>
          <div
            className={"lead-status-btn pointer br-13 me-2 "
              + (tab === "company_info" ? "mtng_booked" : "inactive")}
            onClick={() => setTab("company_info")}
          >Company Info</div>
          <div
            className={"w-120px lead-status-btn pointer br-13 me-2 "
              + (tab === "blocklist" ? "mtng_booked" : "inactive")}
            onClick={() => setTab("blocklist")}
          >Blocklist</div>
          <div
            className={"w-120px lead-status-btn pointer br-13 me-2 "
              + (tab === "prospecting" ? "mtng_booked" : "inactive")}
            onClick={() => setTab("prospecting")}
          >Prospecting</div>
          <div
            className={"w-120px lead-status-btn pointer br-13 me-2 "
              + (tab === "engage" ? "mtng_booked" : "inactive")}
            onClick={() => setTab("engage")}
          >Email</div>
          <div
            className={"w-120px lead-status-btn pointer br-13 me-2 "
              + (tab === "linkedin" ? "mtng_booked" : "inactive")}
            onClick={() => setTab("linkedin")}
          >LinkedIn</div>
          {editAccess &&
            <div
              className={"w-120px lead-status-btn pointer br-13 me-2 "
                + (tab === "billing" ? "mtng_booked" : "inactive")}
              onClick={() => {
                getBillingData();
                setTab("billing");
              }}
            >Billing</div>
          }
        </Col>
      </Row>

      {/* Content */}
      <Row gutter={30} className={tab === "general" ? "" : "d-none"}>
        <Col span={24}>
          {authData?.userInfo.role_id === "2" ? null : renderCard("display_name", "enter display name", settingsData?.company?.display_name, "Display Name", "Edit Name", "company")}
          {authData?.userInfo.role_id === "2" ? null : renderCard("job_title", "enter job title", settingsData?.company?.job_title, "Job Title", "Edit Title", "company")}
          {renderCard("name", "enter company name", settingsData?.company?.name, "Company Name", "Edit Name", "company")}
          {renderCard("website", "enter company website", settingsData?.company?.website, "Company Website", "Edit Website", "company")}

        {/* Render Slack Button */}
        <Card className="custom-card mb-4">
          <h3 className="card-title mb-2">Receive Slack Notifications</h3>
          <SlackButton userId={userId} />
        </Card>

          <Card className="custom-card mb-4">
            <h3 className="card-title mb-2">Collaborators</h3>
            <div id="settings-email-parent">
              {customEmails.map((email, index) => {
                return (
                  <div className="email-row d-flex align-items-center pb-3" key={index}>
                    <span className="email pe-3">{email.collaborator_email}</span>
                    {email.role_id === 1
                      ?
                      <button className="custom-btn small purple text-white me-3">Viewer</button>
                      :
                      <button className="custom-btn small blue text-white me-3">Editor</button>
                    }
                    {isSuperAdmin &&
                      <div className="pointer delete-icon" onClick={() => deleteEmail(index, email.collaborator_id)}>
                        <img src="/assets/svg/delete-red.svg" width="14" height="auto" alt="delete" />
                      </div>
                    }
                  </div>
                )
              })}

              {/*
              <div className="email-row d-flex align-items-center pb-3">
                <span className="email pe-3">Add New Collaborator</span>
                <Input
                  key={Math.random()}
                  id="add-email"
                  placeholder="enter email address"
                  className="settings-enter-email custom-search-input"
                  suffix={
                    <div className="arrow-parent pointer" onClick={addEmail}>➜</div>
                  }
                  disabled={!editAccess}
                />
              </div>
              */}

              {editAccess &&
                <Form
                  form={collaboratorForm}
                  className={"email-row align-items-start pb-3 " + (editAccess ? "d-flex" : "d-none")}
                  onFinish={addEmail}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter a valid email"
                      },
                    ]}
                    className="pb-0 pe-4"
                  >
                    <Input className="custom-input" placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Please select a role"
                      },
                    ]}
                    className="pb-0 pe-2"
                  >
                    <Select
                      className="w-120px custom-input"
                      options={[
                        { label: "Editor", value: 2 },
                        { label: "Viewer", value: 1 },
                      ]}
                      placeholder="Role"
                    />
                  </Form.Item>

                  <Button type="primary" htmlType="submit" className="ms-3">
                    Add Collaborator
                  </Button>
                </Form>
              }
            </div>
          </Card>

          <Button type="primary" onClick={signOut}>Sign Out</Button>
        </Col>
      </Row>

      <Row gutter={30} className={tab === "company_info" ? "" : "d-none"}>
        <Col span={24} className="pb-3">
          <p className='white-text font-size-16'>Company Information</p>
          {
            settingsData?.company_information?.map((item) => {
              return renderProducts(item)
            })
          }

          {/* 
          <Row gutter={24}>
            <Col span={10}>
              <div style={{display:'flex',alignItems:'center'}}>
                <span style={{width:'200px'}} className='font-size-10 white-text-71'>Create a New Product</span>
                <Input className="custom-input white-text-71" 
                  placeholder='enter product name' 
                  suffix={<div onClick={() => createProduct()}>{SubmitButton}</div> }
                />
              </div> 
            </Col>
          </Row>
          */}
        </Col>
        <Col span={24}>
          <p className='white-text font-size-16'>Market Information</p>
          {renderCard("competition", "Enter companies here, separated by commas.", settingsData?.market_information?.competition, "What are companies that are similar or competitive to you?", "Edit", "market_information")}
          {renderCard("complementary", "Enter companies here, separated by commas (ex: if they use Shopify they should use Stripe)", settingsData?.market_information?.complementary, "What are complementary companies to you?", "Edit", "market_information")}
          {renderCard("market_focus", "Enter markets here, separated by commas.", settingsData?.market_information?.market_focus, "What industries or markets do you sell into?", "Edit", "market_information")}
          {renderCard("company_focus", "Enter companies here, separated by commas.", settingsData?.market_information?.company_focus, "What are specific companies you sell to right now?", "Edit", "market_information")}
        </Col>
      </Row>

      <Row gutter={30} className={tab === "blocklist" ? "" : "d-none"}>
        <Col span={24} className="pb-3 d-flex align-items-center justify-content-between">
          <Dropdown
            menu={{
              items: [
                { key: "all", label: "View All" },
                { key: "people", label: "View Blocked People" },
                { key: "domain", label: "View Blocked Companies" }
              ],
              onClick: setBlocklistType,
            }}
            dropdownRender={(menu, index) => (
              <div className="inbox-filter-dropdown" key={index}>{React.cloneElement(menu)}</div>
            )}
          >
            <div className="lead-status-btn inactive d-flex align-items-center pointer br-13 me-2">
              <img src="/assets/images/download-gray-2x.png" width="18" height="auto" alt="icon" />
              <span className="text-nowrap white-text-71 ps-2">
                {blocklistType.current === "all" && <span>View All</span>}
                {blocklistType.current === "people" && <span>View Blocked  People</span>}
                {blocklistType.current === "domain" && <span>View Blocked  Companies</span>}
              </span>
            </div>
          </Dropdown>

          {editAccess &&
            <div className="lead-status-btn find-leads d-flex align-items-center br-13 pointer" onClick={() => setShowUploadList(true)}>
              <img src="/assets/images/export-black-2x.png" width="auto" height="20" alt="edit" />
              <span className="text-nowrap text-dark fw-semibold ps-2">Import List</span>
            </div>
          }
        </Col>
        <Col span={24}>
          <Card className="custom-card br-10 mb-4">
            <div className="fw-bold pb-1">Do not Prospect, Do not Contact</div>
            <div className="white-text-62 pb-3">Companies and people added to the Blocklist will prevent them from being prospected as leads or contacted through email outreach.</div>

            <Row>
              <Col span={16}>
                <div className="d-flex flex-wrap pb-2">
                  {settingsData.block_contact && settingsData.block_contact.map((contact, index) => (
                    <div className="d-flex align-items-center mb-3 me-4" key={index}>
                      {isSuperAdmin &&
                        <img
                          src="/assets/images/close-ivory-2x.png"
                          width="18"
                          height="auto"
                          alt="close"
                          className="pointer"
                          onClick={() => deleteBlockedContact(contact.block_id)}
                        />
                      }
                      <Input
                        className="custom-input2 semiheight ms-2"
                        placeholder={contact.contact}
                        style={{ width: "200px" }}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>

            {/*
            {(settingsData.block_contact && settingsData.block_contact.length > 0) &&
              <Row>
                <Col span={16} className="d-flex flex-wrap pb-2">
                  {settingsData.block_contact.map((item, i) => {
                    return (
                      <div className="d-flex align-items-center mb-3 me-4" key={i}>
                        <img
                          src="/assets/images/close-ivory-2x.png"
                          width="18"
                          height="auto"
                          alt="close"
                          className="pointer"
                          onClick={() => deleteBlockedContact(item)}
                        />
                        <Input
                          className="custom-input2 semiheight ms-2"
                          placeholder={item.contact}
                          style={{width: "200px"}}
                          disabled={true}
                        />
                      </div>
                    )
                  })}
                </Col>
              </Row>
            }
            */}

            {editAccess &&
              <Row>
                <Col span={8}>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mt-2">
                      <span className="white-text-62 text-nowrap pe-4">Add New:</span>
                      <Input
                        className="custom-input white-text-71 br-13"
                        id="block_contact"
                        placeholder='enter email or domain'
                        value={blockContact}
                        onChange={(e) => setBlockContact(e.target.value)}
                        suffix={<div onClick={addBlocklist}>{SubmitButton}</div>}
                      />
                    </div>
                    {showBlockListError &&
                      <div
                        className="w-100 pt-2"
                        style={{ paddingLeft: "175px", color: "#dc4446" }}
                      >Enter a valid email or domain</div>
                    }
                  </div>
                </Col>
              </Row>
            }
          </Card>
        </Col>
      </Row>

      <Row gutter={30} className={tab === "prospecting" ? "" : "d-none"}>
        <Col span={24}>
          <Card className="custom-card mb-4">
            <h3 className="card-title mb-2">Prospecting Settings</h3>
            <div style={{ display: 'flex', marginBottom: '-25px' }}>
              <Checkbox
                checked={(settingsData?.company?.sync_hubspot)}
                onChange={handleCheckboxChange}
                style={{ marginRight: '8px' }}
                disabled={!editAccess}
              />
              <span style={{ fontSize: "10px" }}>Automatically Sync Leads to HubSpot</span>
            </div>
          </Card>

          <Row gutter={24}>
            <Col span={6}>
              <Card
                className='default-background'
                style={{ lineHeight: '10px', padding: '0px' }}
              >
                <Space direction='vertical' size={"middle"}>
                  <ImageComponent src={'/assets/svg/hubspot.svg'} />
                  <div>
                    <p className='font-size-16'>Hubspot</p>
                    <span style={{ color: '#AAAAAA' }}>Login credentials</span>
                  </div>
                  {!settingsData.hubspost_synced &&
                    <Button
                      type='primary'
                      icon={<PlusSquareOutlined />}
                      onClick={() => setHubspotApiKeyModalOpen(true)}
                      disabled={!editAccess}
                    >
                      Connect
                    </Button>
                  }
                  {settingsData.hubspost_synced &&
                    <Button
                      icon={<MinusSquareOutlined />}
                      onClick={() => setHubspotDisconnectModalOpen(true)}
                      className="red-btn"
                      disabled={!editAccess}
                    >
                      Disconnect
                    </Button>
                  }
                </Space>
              </Card>
            </Col>

            { isSuperAdmin && <Col span={6}>
              <Card
                className='default-background'
                style={{ lineHeight: '10px', padding: '0px' }}
              >
                <Space direction='vertical' size={"middle"}>
                  <img src="/assets/images/smart-lead.png" width="auto" height="52" alt="Smart Lead" />
                  <div>
                    <p className='font-size-16'>Smartlead</p>
                    <span style={{ color: '#AAAAAA' }}>Login credentials</span>
                  </div>
                  {!settingsData.smartlead_synced &&
                    <Button
                      type='primary'
                      icon={<PlusSquareOutlined />}
                      onClick={() => setSmartleadsApiKeyModalOpen(true)}
                      disabled={!editAccess}
                    >
                      Connect
                    </Button>
                  }
                  {settingsData.smartlead_synced &&
                    <Button
                      icon={<MinusSquareOutlined />}
                      onClick={() => setSmartleadsDisconnectModalOpen(true)}
                      className="red-btn"
                      disabled={!editAccess}
                    >
                      Disconnect
                    </Button>
                  }
                </Space>
              </Card>
            </Col> }
          </Row>
          {settingsData.hubspost_synced === true && settingsData.smartlead_synced === true ?
            <div className='mt-4'>
              <div>
                <span className="white-text mt-3 me-3">Create deals when lead is marked as</span>
                <Select className="mt-2 w-25 ms-3"
                  mode="multiple"
                  value={selectedLeadStatus}
                  onChange={(e) => {
                    setSelectedLeadStatus(e)
                  }}
                >
                  {leadStatusOptions.map((i) => <Option value={i.value}>{i.label}</Option>)}
                </Select>
              </div>
              <div className='mt-4'>
                <span className="white-text mt-3 me-3">Select Hubspot Stage for Deal Creation</span>
                <Select className="mt-2 w-25" value={selectedHubspotField}
                  onChange={(e) => {
                    setSelectedHubspotField(e)
                  }}
                >
                  {hubspotField && hubspotField.map((i) => <Option value={i.field_value}>{i.field_label}</Option>)}
                </Select>
              </div>
              <div className='text-end w-75'><Button type="primary" onClick={saveHubspotField}>Save</Button></div>
            </div>
            : null}
        </Col>
      </Row>

      <Row gutter={30} className={tab === "engage" ? "" : "d-none"}>
        <Col span={16} className="gutter-row">
          <Card className="custom-card p-2 br-10">
            <div className="fs-5 fw-semibold pb-2">Campaign Settings</div>
            <div className="small white-text-62 pb-4 mb-4">These settings will determine how your campaign runs</div>

            <div className="w-100 fs-6 fw-semibold pb-3">Send emails on these days:</div>
            <div className="d-flex align-items-center pb-4 mb-4">
              {days.map((day, index) => (
                <div
                  key={index}
                  className={"white-outlined-btns fill-blue me-3"
                    + (editAccess ? " pointer" : "")
                    + (emailDays.includes(day) ? " active" : "")}
                  {...(editAccess && { onClick: () => setEmailDay(day, false) })}
                >{daysLabel[day]}</div>
              ))}
            </div>

            <div className="w-100 fs-6 fw-semibold pb-3">Send emails between this time:</div>
            <div className="d-flex align-items-center pb-4 mb-1">
              {/*<div className="white-outlined-btns fill-blue pointer me-3">6am</div>*/}
              <Select
                options={engageTimeOptions}
                value={emailStartHour}
                onChange={(v) => setEmailStartHour(v)}
                style={{ width: "100px" }}
                disabled={!editAccess}
              />
              <span className="px-3">-</span>
              {/*<div className="white-outlined-btns fill-blue pointer me-3">5pm</div>*/}
              <Select
                options={engageTimeOptions}
                value={emailEndHour}
                onChange={(v) => setEmailEndHour(v)}
                style={{ width: "100px" }}
                disabled={!editAccess}
              />
              <span className="pt-2 white-text-62 small ps-2">PST</span>
            </div>
            {(parseInt(emailStartHour.split(":")[0], 10) > parseInt(emailEndHour.split(":")[0], 10)) &&
              <div className='mt-2' style={{ color: "red" }}>Start hour should be less than end hour</div>}
            <div className="d-flex pb-4 mt-4">
              <Checkbox
                checked={engageDoNotSendEmail}
                onChange={e => setEngageDoNotSendEmail(e.target.checked)}
                disabled={!editAccess}
                className="mt-1"
              />
              <span className="fs-6 fw-semibold ps-3">Do not send emails to leads already in a separate campaign.</span>
            </div>

            {/*
            <div className="d-flex align-items-center pb-4">
              <input type="checkbox" />
              <span className="fs-6 fw-semibold ps-3">Do not send emails during major Holidays.</span>
            </div>
            */}

            <div className="d-flex pb-0">
              <Checkbox
                checked={stopCampaignsOnHolidays}
                onChange={e => setStopCampaignsOnHolidays(e.target.checked)}
                disabled={!editAccess}
                className="mt-1"
              />
              <span className="fs-6 fw-semibold ps-3">Turn off customers' campaigns during the Holidays </span>
            </div>
            {stopCampaignsOnHolidays === true ?
              <div className="d-flex flex-column pb-4">
                <div className="fs-6 fw-semibold pb-2">Holidays List</div>
                <Select
                  mode="multiple"
                  options={holidaysList}
                  className="custom-input"
                  style={{ width: "50%" }}
                  value={selectedHolidayList}
                  onChange={(e) => setSelectedHolidayList(e)}
                />
              </div> : null}


            {/*
            <div className="d-flex align-items-center py-4">
              <div className="fs-6 fw-semibold me-3">Enable Custom Tracking Domain</div>
              <div className="white-outlined-btns fill-blue pointer me-3">Yes</div>
              <div className="white-outlined-btns fill-blue pointer me-3">No</div>
            </div>
            */}

            <div className="fs-5 fw-semibold pb-2">CRM Settings</div>
            <div className="d-flex pb-4">
              <Checkbox
                checked={syncLeadStatus}
                onChange={e => setSyncLeadStatus(e.target.checked)}
                disabled={!editAccess}
                className="mt-1"
              />
              <span className="fs-6 fw-semibold ps-3">Sync Prospect lead status from inbox to Hubspot.</span>
            </div>
          </Card>
        </Col>

        {/*
        <Col span={8} className="gutter-row">
          <Card className="custom-card p-2 br-10">
            <div className="fs-5 fw-semibold pb-4">Custom Tracking Domain</div>
            <div className="small white-text-62 pb-4">Add your new CNAME record for your custom tracking domain or subdomain.</div>
            <div className="fs-5 fw-semibold pb-4">Record Type: CNAME</div>
          </Card>
        </Col>
        */}
      </Row>

      <Row gutter={30} className={tab === "billing" ? "" : "d-none"}>
        <Col span={16}>
          {billingData.current &&
            <Card className="custom-card p-2 br-10">
              <div className="pb-2">Number of Leads Enriched</div>
              <div id="slider-tooltip-parent" className="d-flex justify-content-between align-items-center">
                <div
                  style={{ width: billingData.current.percent + "%" }}
                  className="small white-text-71 text-end"
                >
                  {billingData.current.enriched_count}
                </div>
                {!billingData.current.full &&
                  <div className="small white-text-71 ps-4">{billingData.current.prospect_limit}</div>
                }
              </div>
              <Slider
                value={billingData.current.percent}
                railStyle={{ backgroundColor: "rgba(255,255,255,0.08)" }}
                trackStyle={{ backgroundColor: "#0266FF" }}
                disabled={true}
              />
              <div className="small white-text-62 pt-2">Plan Refresh Date: {billingData.current.refresh_datetime}</div>
            </Card>
          }
        </Col>
      </Row>

      <Row gutter={30} className={tab === "linkedin" ? "" : "d-none"}>
        <Col span={16} className="gutter-row">
          <Card className="custom-card p-2 br-10">
            <div className="fs-5 fw-semibold pb-2">LinkedIn Campaign Settings</div>
            <div className="small white-text-62 pb-4 mb-4">These settings will determine how your linkedIn campaign runs</div>

            <div className="w-100 fs-6 fw-semibold pb-3">Send linkedIn messages on these days:</div>
            <div className="d-flex align-items-center pb-4 mb-4">
              {days.map((day, index) => (
                <div
                  key={index}
                  className={"white-outlined-btns fill-blue me-3"
                    + (editAccess ? " pointer" : "")
                    + (linkedInEmailDays.includes(day) ? " active" : "")}
                  {...(editAccess && { onClick: () => setEmailDay(day, true) })}
                >{daysLabel[day]}</div>
              ))}
            </div>

            <div className="w-100 fs-6 fw-semibold pb-3">Send linkedIn messages between this time:</div>
            <div className="d-flex align-items-center pb-4 mb-1">
              {/*<div className="white-outlined-btns fill-blue pointer me-3">6am</div>*/}
              <Select
                options={engageTimeOptions}
                value={linkedInEmailStartHour}
                onChange={(v) => setLinkedInEmailStartHour(v)}
                style={{ width: "100px" }}
                disabled={!editAccess}
              />
              <span className="px-3">-</span>
              {/*<div className="white-outlined-btns fill-blue pointer me-3">5pm</div>*/}
              <Select
                options={engageTimeOptions}
                value={linkedInEmailEndHour}
                onChange={(v) => setLinkedInEmailEndHour(v)}
                style={{ width: "100px" }}
                disabled={!editAccess}
              />
              <span className="pt-2 white-text-62 small ps-2">PST</span>
            </div>
            {(parseInt(linkedInEmailStartHour.split(":")[0], 10) > parseInt(linkedInEmailEndHour.split(":")[0], 10)) &&
              <div className='mt-2' style={{ color: "red" }}>Start hour should be less than end hour</div>}
            <div className="d-flex pb-4 mt-4">
              <Checkbox
                checked={linkedInDoNotSendEmail}
                onChange={e => setLinkedInDoNotSendEmail(e.target.checked)}
                disabled={!editAccess}
                className="mt-1"
              />
              <span className="fs-6 fw-semibold ps-3">Do not send linkedIn messages to leads already in a separate campaign.</span>
            </div>

            {/*
            <div className="d-flex align-items-center pb-4">
              <input type="checkbox" />
              <span className="fs-6 fw-semibold ps-3">Do not send emails during major Holidays.</span>
            </div>
            */}

            {/* <div className="d-flex pb-0">
              <Checkbox
                checked={stopCampaignsOnHolidays}
                onChange={e => setStopCampaignsOnHolidays(e.target.checked)}
                disabled={!editAccess}
                className="mt-1"
              />
              <span className="fs-6 fw-semibold ps-3">Turn off customers' campaigns during the Holidays </span>
            </div>
            {stopCampaignsOnHolidays === true ?
              <div className="d-flex flex-column pb-4">
                <div className="fs-6 fw-semibold pb-2">Holidays List</div>
                <Select
                  mode="multiple"
                  options={holidaysList}
                  className="custom-input"
                  style={{ width: "50%" }}
                  value={selectedHolidayList}
                  onChange={(e) => setSelectedHolidayList(e)}
                />
              </div> : null} */}


            {/*
            <div className="d-flex align-items-center py-4">
              <div className="fs-6 fw-semibold me-3">Enable Custom Tracking Domain</div>
              <div className="white-outlined-btns fill-blue pointer me-3">Yes</div>
              <div className="white-outlined-btns fill-blue pointer me-3">No</div>
            </div>
            */}

            <div className="fs-5 fw-semibold pb-2">CRM Settings</div>
            <div className="d-flex pb-4">
              <Checkbox
                checked={linkedInSyncLeadStatus}
                onChange={e => setLinkedInSyncLeadStatus(e.target.checked)}
                disabled={!editAccess}
                className="mt-1"
              />
              <span className="fs-6 fw-semibold ps-3">Sync Prospect lead status from inbox to Hubspot.</span>
            </div>
          </Card>
        </Col>

        {/*
        <Col span={8} className="gutter-row">
          <Card className="custom-card p-2 br-10">
            <div className="fs-5 fw-semibold pb-4">Custom Tracking Domain</div>
            <div className="small white-text-62 pb-4">Add your new CNAME record for your custom tracking domain or subdomain.</div>
            <div className="fs-5 fw-semibold pb-4">Record Type: CNAME</div>
          </Card>
        </Col>
        */}
      </Row>

      {/* Modals */}
      <Modal
        title="Create Product"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="cancel" onClick={handleCloseModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary"
            onClick={() => onFinish()}
          >
            Create
          </Button>
        ]}
      >
        <Form
          form={form}
          colon={false}
          labelAlign="left"
        >
          <Form.Item
            {...formItemLayout}
            label="Product"
            name="product_name"
            rules={[{ required: true, message: "Enter the Product" }]}
          >
            <Input className="custom-input white-text-71"
              placeholder='Product name'
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Description"
            name="description"
            rules={[{ required: true, message: "Enter the Description" }]}
          >
            <Input className="custom-input white-text-71"
              placeholder='Description'
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Pricing"
            name="pricing"
            rules={[{ required: true, message: "Enter the Pricing" }]}
          >
            <Input
              className="custom-input white-text-71"
              placeholder='Pricing'
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title=" "
        open={hubspotApiKeyModalOpen}
        onOk={closeHubspotApiKeyModal}
        onCancel={closeHubspotApiKeyModal}
        maskClosable={false}
        footer={[]}
        centered
        className="bootstrap custom-modal"
      >
        <div className="fs-6 pb-4">Hubspot - Login Credentials</div>
        <div className="d-flex flex-column align-items-center">
          <Form form={hubspotApiKeyForm} onFinish={saveHubspotApiKey} className="w-100">
            <Form.Item
              name="api_key"
              className="w-100"
              rules={[
                {
                  required: true,
                  message: "please enter api key"
                },
              ]}
            >
              <Input className="custom-select" placeholder="enter api key" />
            </Form.Item>
            <div className="w-100 d-flex align-items-center justify-content-end">
              <button type="submit" disabled aria-hidden="true" className="d-none">PreventSubmit</button>
              <Button type="primary" htmlType="submit">Save Key</Button>
              <Button className="ms-2 black-btn" onClick={closeHubspotApiKeyModal}>Close</Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title=" "
        open={hubspotDisconnectModalOpen}
        onOk={closeHubspotDisconnectModal}
        onCancel={closeHubspotDisconnectModal}
        maskClosable={false}
        footer={[]}
        centered
        className="bootstrap custom-modal"
      >
        <div className="fs-6 pb-4">Disconnect Hubspot</div>
        <div className="w-100 d-flex align-items-center justify-content-end">
          <button type="submit" disabled aria-hidden="true" className="d-none">PreventSubmit</button>
          <Button htmlType="submit" className="red-btn" onClick={disconnectHubspotApiKey}>Disconnect</Button>
          <Button className="ms-2 black-btn" onClick={closeHubspotDisconnectModal}>Close</Button>
        </div>
      </Modal>

      <Modal
        title=" "
        open={smartleadsApiKeyModalOpen}
        onOk={closeSmartleadsApiKeyModal}
        onCancel={closeSmartleadsApiKeyModal}
        maskClosable={false}
        footer={[]}
        centered
        className="bootstrap custom-modal"
      >
        <div className="fs-6 pb-4">Smartlead - Login Credentials</div>
        <div className="d-flex flex-column align-items-center">
          <Form form={smartleadsApiKeyForm} onFinish={saveSmartleadsApiKey} className="w-100">
            <Form.Item
              name="api_key"
              className="w-100"
              rules={[
                {
                  required: true,
                  message: "please enter api key"
                },
              ]}
            >
              <Input className="custom-select" placeholder="enter api key" />
            </Form.Item>
            <div className="w-100 d-flex align-items-center justify-content-end">
              <button type="submit" disabled aria-hidden="true" className="d-none">PreventSubmit</button>
              <Button type="primary" htmlType="submit">Save Key</Button>
              <Button className="ms-2 black-btn" onClick={closeSmartleadsApiKeyModal}>Close</Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title=" "
        open={smartleadsDisconnectModalOpen}
        onOk={closeSmartleadsDisconnectModal}
        onCancel={closeSmartleadsDisconnectModal}
        maskClosable={false}
        footer={[]}
        centered
        className="bootstrap custom-modal"
      >
        <div className="fs-6 pb-4">Disconnect Smartlead</div>
        <div className="w-100 d-flex align-items-center justify-content-end">
          <button type="submit" disabled aria-hidden="true" className="d-none">PreventSubmit</button>
          <Button htmlType="submit" className="red-btn" onClick={disconnectSmartleadsApiKey}>Disconnect</Button>
          <Button className="ms-2 black-btn" onClick={closeSmartleadsDisconnectModal}>Close</Button>
        </div>
      </Modal>

      <Drawer
        title=""
        placement="right"
        width={450}
        closable={false}
        onClose={() => {
          setShowUploadList(false)
        }}
        open={showUploadList}
        key="left"
        className="bootstrap"
      >
        <div className="d-flex align-items-center pb-2 pointer" onClick={() => {
          setShowUploadList(false)
        }
        }>
          <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
          <span className="ps-2 white-text-62">Close</span>
        </div>

        <div className={"d-flex flex-column justify-content-center"} style={{ height: "calc(100% - 38px)" }}>
          <div id="upload-csv-holder" className="w-50 pb-4 mx-auto">
            <Dragger {...csvDraggableProps}>
              <div className="d-flex align-items-center justify-content-center">
                <img src="/assets/images/export-gray-2x.png" width="14" height="auto" alt="export" />
                <span className="ps-2 fw-semibold">Upload CSV</span>
              </div>
            </Dragger>
          </div>

          <div className="text-center text-white pt-3 pb-4">Drag and Drop a CSV file to<br />begin the import.</div>
          <div className="select-company d-flex flex-column justify-content-center align-items-center text-white">
            <input type="hidden" id="import-data-type" value={isCompany ? "company" : "people"} />
            <div
              id="import-by-company"
              className="company-row w-100 continue position-relative mb-3 pointer"
              onClick={() => setIsCompany(!isCompany)}
            >
              <span className="text-nowrap small fw-semibold">Company (URL) - Block leads for the entire company</span>
              <div className={"arrow-parent position-absolute" + (isCompany ? "" : " d-none")}
              >✓</div>
            </div>
            <div
              id="import-by-people"
              className="company-row w-100 continue position-relative mb-3 pointer"
              onClick={() => setIsCompany(!isCompany)}
            >
              <span className="text-nowrap small fw-semibold">People - Block Email</span>
              <div className={"arrow-parent position-absolute" + (isCompany ? " d-none" : "")}
              >✓</div>
            </div>
          </div>
          <div className="d-flex justify-content-center text-white">
            <Button type="primary" className="br-10" onClick={() => {
              Modal.confirm({
                title: (<div style={{ color: "#ffffff" }}>Confirm</div>),
                content: (<div style={{ color: "#ffffff" }}>If any leads in your blocklist are already enrolled in an
                  existing campaign, they will not receive future emails or messages from your campaigns.</div>),
                okText: 'OK',
                cancelText: 'Cancel',
                centered: true,
                onOk: async () => {
                  csvUpload()
                },
                onCancel: async () => {
                  setShowUploadList(false)
                }
              })
            }}
              disabled={importedCsvFile === null || importedCsvFile === undefined}>Upload</Button>
          </div>
        </div>

      </Drawer>
    </Layout>
  );
};

export default PersonaSettings;
