import $ from "jquery";
import { Boot } from '@wangeditor/editor';

class InsertVariableMenu {
  constructor() {
    this.title = 'Insert Variable'
    // this.iconSvg = '<svg >...</svg>'
    this.tag = 'button';
    this.showDropPanel = true;
  }

  isActive(editor) {
    return false;
  }

  getValue(editor) {
    return '';
  }

  isDisabled(editor) {
    return false;
  }

  exec(editor, value) {
  }

  getPanelContentElem(editor) {
    const $list = $(`
      <ul>
        <li data-content="{{first_name}}">First Name</li>
        <li data-content="{{last_name}}">Last Name</li>
        <li data-content="{{email}}">Email</li>
        <li data-content="{{phone_number}}">Phone Number</li>
        <li data-content="{{company_name}}">Company Name</li>
        <li data-content="{{website}}">Website</li>
        <li data-content="{{linkedin_profile}}">Linkedin Profile</li>
        <li data-content="{{location}}">Location</li>
        <li data-content="{{role}}">Role</li>
        <li data-content="{{sl_day_of_week}}">Day of Week</li>
        <li data-content="{{sl_time_of_day}}">Time of Day</li>
        <li data-content="{{sl_time_of_day_cap}}">Time of Day Cap</li>
      </ul>
    `);

    $list.on('click', 'li', function () {
      editor.insertText($(this).attr("data-content"));
    })

    return $list[0];
  }
}

const insertVariableMenuConf = {
  key: 'insertVariable',
  factory() {
    return new InsertVariableMenu();
  },
}

const initInsertVariableMenu = () => {
  Boot.registerMenu(insertVariableMenuConf);
}

export default initInsertVariableMenu;
