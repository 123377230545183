import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { getPlacePredictions } from './googleApiService'; // Update the path accordingly

const { Option } = Select;

const LocationDropdown = ({locations, handleChangeLocation}) => {
  const apiKey = "AIzaSyDLE1Pfe5OWQrbTsHQyOjJl1ZkHE6KkyqY";
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (inputValue) => {
    setLoading(true);

    getPlacePredictions(inputValue, apiKey, (predictions) => {
      setOptions(predictions || []);
      setLoading(false);
    });
  };

  useEffect(() => {
    // loadGoogleMapsScript(apiKey, () => {
    //   // Google Maps script loaded
    // });
  }, []);

  const handlePlaceSelect = (place) => {
    handleChangeLocation(place, 'add')
  };

  const handlePlaceRemove = (place) => {
    handleChangeLocation(place, 'remove')
  }


  return (
    <Select
      showSearch
      placeholder="Search for a location"
      filterOption={false}
      onSearch={handleSearch}
      notFoundContent={loading ? 'Loading...' : 'No results found'}
      mode="multiple"
      className='custom-select'
      onSelect={handlePlaceSelect}
      value={locations}
      onDeselect={handlePlaceRemove}
    >
      {options.map((option) => (
        <Option key={option.place_id} value={option.description}>
          {option.description}
        </Option>
      ))}
    </Select>
  );
};

export default LocationDropdown;
