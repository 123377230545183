import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Input, Select, Form, Modal, Spin } from 'antd';
import axiosInstance from '../../config/axiosConfig';
import { notification } from 'antd';
import { useSelector } from 'react-redux';

import '../../assets/Styles/Settings-SideMenu.css'

const Email = () => {

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const isSuperAdmin = authData.userInfo.is_super_admin;
  const editAccess = authData.userInfo.isViewer === false ? true : false;


  const [form] = Form.useForm();

  const [collaborator, setCollaborator] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const fetchSettings = async () => {
    setShowLoader(true);
    try {
      const response = await axiosInstance.get(`/settings/get_settings/${companyId}?user_id=${userId}`);
      setCollaborator(response.data.collaborators)
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Settings' });
    }
    setShowLoader(false);
  }

  const deleteCollaborator = async (emailId) => {
    setShowLoader(true);

    const payload = { collaborator_id: emailId, company_id: companyId };

    try {
      await axiosInstance.delete(`/settings/delete_collaborator/${userId}`, { data: payload });
      notification.success({ message: 'Success', description: 'Collaborator has been deleted successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in deleting the Collaborator' });
    }

    setShowLoader(false);
    fetchSettings()
  }

  const addCollaborator = async values => {
    setShowLoader(true);

    const payload = {
      email: values.email,
      company_id: companyId,
      role_id: values.role
    }

    try {
      const response = await axiosInstance.post(`/settings/add_collaborator/${userId}`, payload);
      form.setFieldsValue({
        email: '',
        role: '',
      });
      fetchSettings()
      notification.success({ message: 'Success', description: 'Collaborator has been added successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in adding Collaborator' });
    }

    setShowLoader(false);
  }

  useEffect(() => {
    setShowLoader(true);
    fetchSettings()
  }, []);

  return (
    <div className="bootstrap d-flex flex-row text-white w-100 justify-content-center align-items-center settings-background">
      <div className='d-flex flex-column profile-card'>
        <div className='profile-text w-100 mb-3'>Collaborators</div>
        <div className='profile-sub-text w-100 mb-3'>
          Manage and invite your team to your Pipeline Workspace.
        </div>
        {editAccess &&
          <div className='collob-add-member mb-2'>Add Team Member</div>}
        {editAccess && <div className='blocklist-input-table d-flex p-2 mb-1 '>
          <Form
            form={form}
            onFinish={addCollaborator}
            className='d-flex'
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email"
                },
              ]}
              className='pb-0'
            >
              <Input className="blocklist-input me-2" placeholder="Enter their email" style={{ width: "380px" }} />
            </Form.Item>
            <Form.Item
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select a role"
                },
              ]}
              className='pb-0'
            ><Select
                placeholder="Assign Role"
                options={[
                  { label: "Editor", value: 2 },
                  { label: "Viewer", value: 1 },
                ]}
                suffixIcon={<img src="/assets/svg/dropdown-arrow.svg" height={8} width={9} style={{ paddingTop: "0%" }} />}
                className="email-time me-2"
                style={{ width: "150px" }}
              // onChange={(value) => {
              // }}
              /></Form.Item>
          </Form>
          <div className='blocklist-add pointer' onClick={() => form.submit()}>Add</div>
        </div>}
        {collaborator.map(i =>
          <div className='d-flex mt-3'>
            <img src="/assets/svg/cross-icon.svg" className='me-4 pointer' onClick={() => deleteCollaborator(i.collaborator_id)} />
            <div className={'me-4 py-1 ' + (i.role_name === "Viewer" ? "collab-viewer" : "collab-editor")}>{i.role_name}</div>
            <div className='collab-editor-name'>{i.collaborator_email}</div>
          </div>
        )}
      </div>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={showLoader}

      >
        <div className="pt-4 text-center">
          <Spin size="large" />
        </div>
      </Modal>
    </div>
  );
};

export default Email;
