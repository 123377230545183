// src/components/PopUpNotification.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './menu.css';

const PopUpNotification = ({ id, title, message, onDismiss, action_url, notification_type }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss(id);
    }, 5000);

    return () => clearTimeout(timer);
  }, [id, onDismiss]);

  const handleClick = () => {
    navigate(`${action_url}`);
  };

  const renderActionButtons = () => {
    if (notification_type === 2) {
      return (
        <>
          <button
            onClick={handleClick}
            className="popup-button"
          >
            View
          </button>
          <button
            className="popup-button"
            onClick={() => onDismiss(id)}
          >
            Dismiss
          </button>
        </>
      );
    }

    return (
      <button
        className="popup-button"
        onClick={() => onDismiss(id)}
      >
        Dismiss
      </button>
    );
  };

  return (
    <div className="popup-notification">
      <div className="popup-content">
        <div className="popup-header">
          <div className="popup-header-content">
            <p className="popup-title">
              {title}
            </p>
            <p className="popup-message">
              {message}
            </p>
          </div>
        </div>
      </div>

      <div className="popup-actions">
        {renderActionButtons()}
      </div>
    </div>
  );

};

export default PopUpNotification;

