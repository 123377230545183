import $ from 'jquery';

const insertLineBreak = editor => {
  $('.wang-editor').on('keydown', e => {
    if ((e.key === "Enter" && e.shiftKey) || e.key === "Enter") {
      e.preventDefault();
      editor.insertText("\r\n");
    }
  });
}

export default insertLineBreak;
