import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SequenceSteps from "../../../component/LinkedinSequenceSteps"
import {
    Layout,
    Modal,
    Row,
    Col
} from "antd";



const CampaignEdit = () => {


    return (
        <Layout style={{ minHeight: "100vh", padding: 15 }} className="linkedin-campaign-creation bootstrap">
            <Row
                style={{ width: "100%" }}
                className="linkedin-campaign-creation bootstrap"
            >
                <Col className="gutter-row " span={24}>
                    <SequenceSteps />
                </Col>
            </Row>

        </Layout>
    )
}

export default CampaignEdit