// AuthContext.js
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { UNAUTHENTICATED_ENTRY } from '../config/AppConfig';
import NotificationListener from '../component/NotificationListener';

export const AuthContext = React.createContext(null);

export const useAuth = () => {
  return React.useContext(AuthContext);
}

export const AuthProvider = ({children}) => {
  const navigate = useNavigate();
  const authData = useSelector(state => state.auth);

  const [authToken, setAuthToken] = useState(null);

  const fetchToken = async () => {
    // console.log("Token Assignment"); // xxx
    if (authData.token) {
      setAuthToken(authData.token);
    } else {
      navigate(UNAUTHENTICATED_ENTRY);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  const value = {
    authToken,
    fetchToken
  };

  return( 
    <AuthContext.Provider value={value}>
      {children}
      {authToken && <NotificationListener userId={authData.userInfo.userid} />}
    </AuthContext.Provider>
  );
}
