import { combineReducers } from 'redux';
import auth from './slices/authSlice';
import { companySlice } from './slices/companySlice';
import notificationReducer from './slices/notificationSlice';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        auth,
        companyReducer:companySlice.reducer,
          notifications: notificationReducer,
        ...asyncReducers,
    })

    return combinedReducer(state, action);
}
  
export default rootReducer
