import React from 'react';
import { Button, Checkbox } from 'antd';

const CustomEditorButtons = ({send, close, isInbox, signatureSet}) => {
  return (
    <div
      style={{width: "30%", right: 0, top: "250px"}}
      className="d-flex justify-content-end align-items-center position-absolute"
    >
      {isInbox &&
        <div className="d-flex">
          <Checkbox className="custom-checkbox squared" onClick={(e) => signatureSet(e.target.checked)}/>
          <span className="text-nowrap ps-2 pe-4">Add Signature of the email Account</span>
        </div>
      }
      <Button
        type="primary"
        onClick={send}
      >
        <div className="d-flex align-items-center">
          <img src="/assets/images/arrow-white-2x.png" width="14" height="auto" alt="send" />
          <span className="ps-1">Send</span>
        </div>
      </Button>
      <Button
        type="primary"
        className="black-btn mx-2"
        onClick={close}
      >
        Close
      </Button>
    </div>
  );
}

export default CustomEditorButtons;
