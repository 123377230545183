import React from "react";

const ImageComponent = ({
     src,
     style={},
     onClickEvent=() => {},
     className=""
}) => {
     return (
          // <Image 
          // src={src} 
          // preview={false}
          // style={style}
          // />
          <img 
          src={src} 
          style={style}
          onClick={onClickEvent}
          className={className}
          />
     )
}

export default ImageComponent;
