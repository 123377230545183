export const statistics = {  
    "sent" :{
      value:1200,
      percentage:25,
      last_updated:""
    },
    "opened" :{
      value:1200,
      percentage:25,
      last_updated:"Updated 1 week ago"
    },
    "replied" :{
      value:1200,
      percentage:25,
      last_updated:""
    },
    "opportunities" :{
      value:1200,
      percentage:25,
      last_updated:""
    },
    "links_clicked" :{
      value:1200,
      percentage:25,
      last_updated:"Updated 1 week ago"
    },
    "opened_percent" :{
      value:25,
      percentage:25,
      last_updated:"Updated 1 week ago"
    },
    "replied_percent" :{
      value:50,
      percentage:25,
      last_updated:""
    },
    "bounced" :{
      value:1200,
      percentage:25,
      last_updated:""
    }
}

export const monthChartData = [
     {
       name: "January",
       Sent: 4000,
       Opened: 2400,
       Replies: 1500,
       Opportunities: 0,
       Bounced: 0,
     },
     {
          name: "February",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 1200,
          LinksClicked: 900,
        },
        {
          name: "March",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 5000,
          Bounced: 350,
        },
        {
          name: "April",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 0,
        },
        {
          name: "May",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 0,
        },
        {
          name: "June",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 0,
        },
        {
          name: "July",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 0,
        },
        {
          name: "August",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 0,
        },
        {
          name: "September",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 0,
        },
        {
          name: "October",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 0,
        },
        {
          name: "November",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 0,
        },
        {
          name: "December",
          Sent: 4000,
          Opened: 2400,
          Replies: 1500,
          Opportunities: 0,
          Bounced: 0,
        },
   ]; 

   export const weekChartData = [
    {
      name: "Week 1",
      Sent: 4000,
      Opened: 2400,
      Replies: 1500,
      Opportunities: 0,
      Bounced: 0,
      LinksClicked:500
    },
    {
        name: "Week 2",
         Sent: 4000,
         Opened: 2400,
         Replies: 1500,
         Opportunities: 0,
         Bounced: 1200,
         LinksClicked: 900,
       },
       {
        name: "Week 3",
         Sent: 4000,
         Opened: 2400,
         Replies: 1500,
         Opportunities: 5000,
         Bounced: 350,
       },
       {
        name: "Week 4",
         Sent: 4000,
         Opened: 2400,
         Replies: 1500,
         Opportunities: 0,
         Bounced: 0,
       }
  ]; 

  export const daysChartData = [
    {
      name: "Monday",
      Sent: 4000,
      Opened: 2400,
      Replies: 1500,
      Opportunities: 0,
      Bounced: 0,
    },
    {
        name: "Tuesday",
         Sent: 4000,
         Opened: 2400,
         Replies: 1500,
         Opportunities: 0,
         Bounced: 1200,
         LinksClicked: 900,
       },
       {
        name: "Wednesday",
         Sent: 4000,
         Opened: 2400,
         Replies: 1500,
         Opportunities: 5000,
         Bounced: 350,
       },
       {
        name: "Thursday",
         Sent: 4000,
         Opened: 2400,
         Replies: 1500,
         Opportunities: 0,
         Bounced: 0,
       },
       {
        name: "Friday",
         Sent: 4000,
         Opened: 2400,
         Replies: 1500,
         Opportunities: 0,
         Bounced: 0,
       },
       {
        name: "Saturday",
         Sent: 4000,
         Opened: 2400,
         Replies: 1500,
         Opportunities: 0,
         Bounced: 0,
       },
       {
        name: "Sunday",
         Sent: 4000,
         Opened: 2400,
         Replies: 1500,
         Opportunities: 0,
         Bounced: 0,
       }
  ]; 

  export const leadsData = [
    {
      key: '1',
      name: 'John Ross',
      jobTitle: 'CEO',
      company: 'Branding Inc.',
      responseRate: '85',
    },
    {
      key: '1',
      name: 'John Doe',
      jobTitle: 'Operations',
      company: 'Klassic Inc.',
      responseRate: '85',
    },
    {
      key: '1',
      name: 'Ronald Keena',
      jobTitle: 'CEO',
      company: 'Tovin Inc.',
      responseRate: '50',
    },
  ];

  export const leadsApiData = [{"id":1,"email":"haris.dizdarevic@roomorders.com","first_name":"haris","last_name":"dizdarevic","address_line_1":null,"address_line_2":null,"city":null,"state":null,"zip":null,"country":null,"org":"null","date_created":"2023-04-21t05:52:49.978000"}];

  export const userData = {
    name: 'John Doe',
    jobDescription:'Engineer at Klassic Inc',
    jobTitle: 'Operations',
    company: 'Klassic Inc.',
    email: 'john@gmail.com',
    phone:'650-452-2155',
    image:'/assets/images/user_profile.png'
  }

  export const fileData = {
    file_id: 0,
    file_name: "Contracts",
    file_type: "Path",
    link_type: "",
    file_link: "https://www.africau.edu/images/default/sample.pdf",
    file_path: "https://www.africau.edu/images/default/sample.pdf",
    file_extension: "pdf",
    created_at: "2023-07-14"
  }

  export const pipelineFiles = [
    {
      folder_id:'1',
      folder_name:'Onboarding',
      modified_at:'02/05/2023',
      // isEditable:false,
      files_count:5,
      files:[
        fileData,
        fileData
        // {
          
        //   fileType:'pdf',
        //   fileName:'Contracts',
        //   uploadOn:'02/05/2023',
        //   fileLink:"https://www.africau.edu/images/default/sample.pdf"
        // },
        // {
        //   fileType:'notion',
        //   fileName:'Pipeline Onboarding',
        //   uploadOn:'02/05/2023',
        //   fileLink:"https://www.africau.edu/images/default/sample.pdf"
        // },
        // {
        //   fileType:'notion',
        //   fileName:'Pipeline Questionare',
        //   uploadOn:'02/05/2023',
        //   fileLink:"https://www.africau.edu/images/default/sample.pdf"
        // },
        // {
        //   fileType:'sheet',
        //   fileName:'Customer Master List',
        //   uploadOn:'02/05/2023',
        //   fileLink:"https://www.africau.edu/images/default/sample.pdf"
        // }
      ]
    },
    {
      folder_id:'2',
      folder_name:'Prospecting',
      modified_at:'02/05/2023',
      // isEditable:false,
      files_count:2,
      files:[
        fileData,
        fileData
      ]
    },
    {
      folder_id:'2',
      folder_name:'Sequence Copy',
      modified_at:'02/05/2023',
      // isEditable:false,
      files_count:2,
      files:[
        fileData,
        fileData
      ]
    },
    {
      folder_id:'4',
      folder_name:'ICP Validation',
      modified_at:'02/05/2023',
      // isEditable:false,
      files_count:2,
      files:[
        fileData,
        fileData
      ]
    },
    {
      folder_id:'5',
      folder_name:'Miscellaneous',
      modified_at:'02/05/2023',
      // isEditable:false,
      files_count:2,
      files:[
        fileData,
        fileData
      ]
    }
  ];

  export const uploadFiles = [
    {
      folder_id:'7',
      folder_name:'Demo Videos',
      modified_at:'02/05/2023',
      // isEditable:false,
      files_count:2,
      files:[
        fileData,
        fileData
      ]
    },
    {
      folder_id:'8',
      folder_name:'Contacts',
      modified_at:'02/05/2023',
      // isEditable:false,
      files_count:2,
      files:[
        fileData,
        fileData
      ]
    }
  ];

  export const fileIcons = {
    'notion' : "/assets/svg/notion.svg",
    'sheet' : "/assets/svg/sheet.svg",
    'loom' : "/assets/svg/video.svg",
    'webpage' : "/assets/svg/webpage.svg" ,
    'pdf' : "/assets/svg/pdf.svg",
    'mp4' : "/assets/svg/video.svg",
    'xls':"/assets/svg/excel.svg",
    'word':"/assets/svg/word.svg"
  }

  export const linkTypes = [
    { value: 'notion', label: 'Notion', image: "/assets/svg/notion.svg" },
    { value: 'sheet', label: 'Google Sheets', image: "/assets/svg/sheet.svg" },
    { value: 'loom', label: 'Loom', image: "/assets/svg/video.svg" },
    { value: 'webpage', label: 'Webpage', image: "/assets/svg/webpage.svg" },
  ]

  export const fileTypes = [
    { value: 'pdf', label: 'PDF', image: "/assets/svg/pdf.svg" },
    { value: 'xls', label: 'Xls', image: "/assets/svg/excel.svg" },
    { value: 'word', label: 'Word', image: "/assets/svg/word.svg" },
    { value: 'mp4', label: 'MP4', image: "/assets/svg/video.svg" },
  ]

  export const archievedFiles = [

  ]

  const companyUsers = [
    {
      email:'suds@withpipeline.com',
      role:1
    },
    {
      email:'dean@withpipeline.com',
      role:2
    },
    {
      email:'John@gmail.com',
      role:3
    },
    {
      email:'Kevin@gmail.com',
      role:4
    },
    {
      email:'Jemma@gmail.com',
      role:4
    }
  ];

  export const companiesList = [
    {
      id: '1',
      key: '1',
      name: 'Lobby.com',
      date_created: '12/13/2023',
      isActive:true,
      users:companyUsers
    },
    {
      id: '2',
      key: '2',
      name: 'Gatherly',
      date_created: '12/13/2023',
      isActive:true,
      users:companyUsers
    },
    {
      id: '3',
      key: '3',
      name: 'Microgigs',
      date_created: '12/13/2023',
      isActive:true,
      users:companyUsers
    },
    {
      id: '4',
      key: '4',
      name: 'Quill',
      date_created: '12/13/2023',
      isActive:false,
      users:companyUsers
    }
  ]; 

  export const usersData = companyUsers;

  export const roles = [
    {
      id:1,
      name:'Super Admin',
      color:'#1D5ABD'
    },
    {
      id:2,
      name:'Pipeline Team',
      color:'#FF6523'
    },
    {
      id:3,
      name:'Admin',
      color:'#FF002E'
    },
    {
      id:4,
      name:'Viewer',
      color:'#88339F'
    }
  ]

  export const apiTM = [
    {
      "email": "jayanthi@microgigs.dev",
      "first_name": "Jayanthi",
      "id": 1,
      "last_name": "Mohan"
    },
    {
      "email": "test@gmail.com",
      "first_name": "test",
      "id": 2,
      "last_name": "test"
    },
    {
      "email": "laren@yopmail.com",
      "first_name": "Laren",
      "id": 3,
      "last_name": "Mr"
    },
    {
      "email": "newman@yopmail.com",
      "first_name": "newman",
      "id": 4,
      "last_name": "last"
    }
  ]

  export const teamMembers = [
    {
      id:'1',
      email: "test@gmail.com",
      name:'Sudarshan S',
      companyCount:'8',
      isActive:true,
      dateCreated: '12/13/2023',
      role:1
    },
    {
      id:'2',
      email: "test@gmail.com",
      name:'Dean Lapinid',
      companyCount:'8',
      isActive:true,
      dateCreated: '12/13/2023',
      role:2
    },
    {
      id:'3',
      email: "test@gmail.com",
      name:'Microgigs',
      companyCount:'8',
      isActive:false,
      dateCreated: '12/13/2023',
      role:2
    }
  ]

  export const headCountOptions = [
    {label:'1-10', value:'1,10'},
    {label:'11-20', value:'11,20'},
    {label:'21-50', value:'21,50'},
    {label:'51-100', value:'51,100'},
    {label:'101-200', value:'101,200'},
    {label:'201-500', value:'201,500'},
    {label:'501-1000', value:'501,1000'},
    {label:'1001-2000', value:'1001,2000'},
    {label:'2001-5000', value:'2001,5000'},
    {label:'5001-10000', value:'5001,10000'},
    {label:'10000+', value:'10001'}
  ]

  export const fundingOptions = [
    {label:'$0.1M', value:'$100000'},
    {label:'$0.5M', value:'$500000'},
    {label:'$1M', value:'$1000000'},
    {label:'$5M', value:'$5000000'},
    {label:'$10M', value:'$10000000'},
    {label:'$25M', value:'$25000000'},
    {label:'$50M', value:'$50000000'},
    {label:'$100M', value:'$100000000'},
    {label:'$500M', value:'$500000000'},
    {label:'$1B', value:'$1000000000'},
    {label:'$5B', value:'$5000000000'},
    {label:'$10B', value:'$10000000000'}
  ]

  export const fundingSelectOptions = [
    {label:'Seed', value:'0'},
    {label:'Angel', value:'1'},
    {label:'Venture (Round Not Specified)', value:'10'},
    {label:'SeriesA', value:'2'},
    {label:'SeriesB', value:'3'},
    {label:'SeriesC', value:'4'},
    {label:'SeriesD', value:'5'},
    {label:'SeriesE', value:'6'},
    {label:'SeriesF', value:'7'},
    {label:'Debt Financing', value:'13'},
    {label:'Equity Crowdfunding', value:'14'},
    {label:'Convertible Note', value:'15'},
    {label:'Private Equity', value:'11'},
    {label:'Other', value:'12'}
  ]

  export const geographyOptions = [
    {label:'Chennai', value:'chennai'},
    {label:'Delhi', value:'delhi'},
  ]

  export const jobTitleOptions = [
    {label:'Manager', value:'manager'},
    {label:'Accountant', value:'accountant'},
  ]

  export const monthOptions = [
    {label:'1', value:'1'},
    {label:'2', value:'2'},
    {label:'3', value:'3'},
    {label:'4', value:'4'},
    {label:'5', value:'5'},
    {label:'6', value:'6'},
    {label:'7', value:'7'},
    {label:'8', value:'8'},
    {label:'9', value:'9'},
    {label:'10', value:'10'},
    {label:'11', value:'11'}
  ]
