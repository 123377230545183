// src/components/NotificationDropdown.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotifications, markNotificationAsRead, dismissNotification, clearNotifications } from '../../store/slices/notificationSlice';
import { API_BASE_URL } from "../../config/AppConfig";
import { Dropdown, Badge } from 'antd';
import './NotificationDropdown.css';

// NOTIFICATION TYPE IMPORTS
import DismissOnlyNotification from '../../component/notification_cards/DissmissOnlyNotification';
import ActionableNotification from '../../component/notification_cards/ActionableNotification';

const NotificationDropdown = ({ userId }) => {
  const authData = useSelector(state => state.auth);
  const notifications = useSelector(state => state.notifications.notifications);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/notifications/${userId}`, {
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${authData.token}`
          }
        });


        const data = await response.json();

        // Sort notifications by read status, priority, and created_at in descending order
        const sortedNotifications = data.sort((a, b) => {
          if (a.isRead !== b.isRead) {
            return a.isRead - b.isRead; // Unread first
          }
          if (a.priority !== b.priority) {
            return a.priority - b.priority; // Higher priority first
          }
          return new Date(b.created_at) - new Date(a.created_at); // Newer first
        });

         
        dispatch(setNotifications(sortedNotifications));
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [userId, dispatch, authData.token]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      await fetch(`${API_BASE_URL}/notifications/${notificationId}/isRead`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authData.token}`,
        },
        body: JSON.stringify({ isRead: true }),
      });
      dispatch(markNotificationAsRead(notificationId));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleDismiss = async (notificationId) => {
    try {
      await fetch(`${API_BASE_URL}/notifications/dismiss/${notificationId}`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${authData.token}`
        }
      });

      dispatch(dismissNotification(notificationId));
    } catch (error) {
      console.error('Error dismissing notification:', error);
    }
  };

  const markAllAsRead = async () => {
  try {
    await fetch(`${API_BASE_URL}/notifications/mark_all_as_read/${userId}`, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${authData.token}`
      }
    });

    // Update the state to mark all notifications as read
    dispatch(setNotifications(notifications.map(notification => ({ ...notification, isRead: true }))));
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  /*
  const handleClearNotifications = () => {
    dispatch(clearNotifications());
  };
  */
  const renderNotificationCard = (notification) => {
    switch (notification.notification_type) {
      case 1:
        return (
          <DismissOnlyNotification
            key={notification.id}
            id={notification.id}
            title={notification.title}
            message={notification.message}
            icon_type={notification.icon_type}
            createdAt={notification.created_at}
            isRead={notification.isRead}
            onDismiss={handleDismiss}
          />
        );
      case 2:
        return (
          <ActionableNotification
            key={notification.id}
            id={notification.id}
            title={notification.title}
            message={notification.message}
            button_text={notification.button_text}
            action_url={notification.action_url}
            icon_type={notification.icon_type}
            createdAt={notification.created_at}
            isRead={notification.isRead}
            onDismiss={handleDismiss}
            markAsRead={handleMarkAsRead}
          />
        );
      default:
        return null;
    }
  };

  const unreadCount = notifications.filter(notification => !notification.isRead).length;

  const menu = (
  <div className="notification-menu">
    <div className="notification-header">
      <div className="notification-header-info">
        Notifications <Badge count={unreadCount} className="custom-badge" />
      </div>
      {notifications.length > 0 && (
        <div className="notification-header-actions" onClick={markAllAsRead}>
          Mark all as read
        </div>
      )}
    </div>

    <div className="notification-content">
      {notifications.length > 0 ? (
        notifications.map(renderNotificationCard)
      ) : (
      <div className="no-notif-container">
        <h3>Nothing to see here!</h3>
        <p>Check back in soon to see if you have any new notifications.</p>
      </div>
      )}
    </div>
  </div>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} overlayClassName="notification-dropdown">
      <Badge count={unreadCount} dot className="bell-badge">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ fontSize: '19px', color: '#fff', cursor: 'pointer' }}
          className="bell-size"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
          />
        </svg>
      </Badge>
    </Dropdown>
  );
};

export default NotificationDropdown;

