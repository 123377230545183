const imagesArray = [
  "thumbs-down-2x.png",
  "outgoing-green-2x.png",
  "happy-face-green-2x.png",
  "refresh-pink-2x.png",
  "snooze-orange-2x.png",
  "sad-face-blue-2x.png",
  "sad-face-gray-2x.png"
];

const getLeadCategoryIcon = category => {
  let image;

  switch (category) {
    case "Meeting Request":
      image = imagesArray[1];
      break;
    case "Not Interested":
      image = imagesArray[0];
      break;
    case "Do Not Contact":
      image = imagesArray[6];
      break;
    case "Information Request":
      image = imagesArray[3];
      break;
    case "Out Of Office":
      image = imagesArray[5];
      break;
    case "Wrong Person":
      image = imagesArray[6];
      break;
    default:
      // interested
      image = imagesArray[2];
  }

  return image;
}

const prospectsStatusIcons = [
  "/assets/svg/submit-button.svg",
  "/assets/images/tick-green-2x.png",
  "/assets/images/refresh-pink-2x.png",
  "/assets/images/alert-orange-2x.png",
  "/assets/svg/block-red.svg",
  "/assets/images/alert-yellow-2x.png"
]

export const getProspectsStatusIcon = category => {
  let image;

  switch (category) {
    case "STARTED":
      image = prospectsStatusIcons[0];
      break;
    case "COMPLETED":
      image = prospectsStatusIcons[1];
      break;
    case "INPROGRESS":
      image = prospectsStatusIcons[2];
      break;
    case "STOPPED":
      image = prospectsStatusIcons[3];
      break;
    case "BLOCKED":
      image = prospectsStatusIcons[4];
      break;
    case "PAUSED":
      image = prospectsStatusIcons[5];
      break;
    default:
      image = prospectsStatusIcons[0];
  }

  return image;
}

export default getLeadCategoryIcon;
