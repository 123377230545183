import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConfigProvider, Menu } from 'antd';
import { adminMenuItems, menuItems, toolsMenuHeading, toolsMenuItems, profileMenuItems } from '../constants/Menu';
import isViewAsMember from '../common/isViewAsMember';
import { LoaderProvider } from '../store/loaderContext';
import { TEAM_MEMBER_ID } from '../constants/AppConstants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '../store/slices/authSlice';
import axiosInstance from '../config/axiosConfig';
import NotificationDropdown from '../views/settings/NotificationDropdown';

import './menu.css'
import { t } from '@wangeditor/editor';

const SideMenu = ({ children, isLogin, isShare }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [menuList, setMenuList] = useState(adminMenuItems);
  const { SubMenu } = Menu;
  const navigate = useNavigate();

  const authData = useSelector((state) => state.auth);
  const notificationsData = useSelector((state) => state.notificationsReducer);

  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [viewAsMember, setViewAsMember] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [profileSubMenuItems, setProfileSubMenuItems] = useState([]);
  const profileRef = useRef(null);

  const isLsAuthTokenSet = localStorage.hasOwnProperty('authToken');

  const theme = {
    token: {
      iconMarginInlineEnd: "20px"
    },
  };

  const isMemberView = () => {
    const memberView = isViewAsMember(authData);
    setViewAsMember(memberView);

    if (memberView) {
      setMenuList(menuItems);
    } else if (authData?.userInfo.is_admin === "dummyData") {
      const updatedMenu = (authData?.userInfo.role_id == TEAM_MEMBER_ID) ?
        adminMenuItems.filter((obj) => obj.key !== '/admin-users') : adminMenuItems;
      setMenuList(updatedMenu);
    } else {
      setMenuList(menuItems);
    }
  }

  const isSameMenuItem = ({ key }) => {
    if (key === selectedKeys[0]) {
      window.location.reload();
    }
  }

  useEffect(() => {
    isMemberView();

    // Extract the current pathname from the location object
    let { pathname } = location;

    if (pathname === '/company-view') {
      pathname = '/companies'
    }

    if (pathname.split('/')[2] === 'campaign-details') {
      pathname = '/engage/campaign-list'
    }

    // Update the selectedKeys state with the current pathname
    setSelectedKeys([pathname]);
    setOpenKeys([]);

    if ((pathname.split('/')[2]) == 'linkedIn') {
      if (openKeys.length > 1) {
        setOpenKeys(['/linkedin']);
      } else {
        setOpenKeys(['tmp_key-12']);
      }
    }

  }, [location]);

  useEffect(() => {
  let profileSubMenu = menuList.filter(i => i.key === "/profile")[0]?.subMenuItems
  setProfileSubMenuItems(profileSubMenu)

  }, []);

  const onSubMenuOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const signOut = async () => {
    try {
      const payload ={
        userid: Number(userId),
        company_id: Number(companyId),
        login: false
      }
			axiosInstance.post(`/login/auth_update`, payload);
		} catch (error) {
      console.log("error", error)
	  }
    dispatch(setToken(null));

    localStorage.removeItem('authToken');
    localStorage.removeItem('company_id');
    localStorage.removeItem('persist:root');
    navigate("/auth");
  }

  const to = "suds@withpipeline.com";
  const cc = "jitin@withpipeline.com";

  const mailtoLink = `mailto:${to}?cc=${encodeURIComponent(cc)}`;

  return (
    <>
    <div className={location.pathname === '/auth' ? 'flex flex-row' : isLogin === false && ((isLsAuthTokenSet && authData.token) || isShare) ? 'app-container' : 'loading-container'}>
      {location.pathname !== '/auth' && isLogin === false && isLsAuthTokenSet && authData.token && isShare === false &&
   <>

   
          <Menu
            selectedKeys={selectedKeys}
            theme="dark"
            mode="inline"
            className="default-background side-menu"
            onClick={itemProps => isSameMenuItem(itemProps)}
          >

            {/* Profile Header Section */}
            <div className='profile-header-container'>
               <div className='bootstrap d-flex flex-row pointer'
                  onClick={() => setProfileOpen(!profileOpen)}
                  style={{
                    marginRight: "2%",
                    marginBottom: "8%",
                    paddingTop:"2px",
                    paddingBottom:"3px",
                    paddingRight: "8px",
                    backgroundColor: profileOpen ? "#1A1A21" : "transparent",
                    borderRadius: "3px"
                  }}
                  ref={profileRef}
            >
                <div className='profile-info'style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src="/assets/svg/purple-persona-icon.svg"
                    height={25.26}
                    width={25.26}
                    style={{ marginLeft: '8px', marginRight: '10px' }}
                    alt="Purple Persona Icon"
                  />
                  <span className='profile-menu'>{authData.userInfo.name}</span>
                </div>
                <div className='arrow-container'>
                  <img 
                    src={profileOpen ? "/assets/svg/up-arrow.svg" : "/assets/svg/down-arrow.svg"} 
                    width="8px"
                    alt={profileOpen ? "Up Arrow" : "Down Arrow"}
                  />
              </div>

              </div>
                <div className='notification-bell'>
                  <NotificationDropdown userId={authData.userInfo.userid}/>
                </div>
              </div>

            {menuList.map(item => (
              (item.isMenu) &&

              // Deals with the Main menu items
              <>
              {item.subMenu 
              
              ?

              <SubMenu 
                icon={item.key === selectedKeys[0] || item.subMenuItems.find(i => i.key === selectedKeys[0]) ? item.iconSelected : 
                  item.key === "/email" && companyData.sl_key === "false" ? item.disabledIcon: item.icon}
                title={
                    <Link style={{ color: 'inherit', textDecoration: 'none' }}
                      to={item.route + (viewAsMember ? "?viewer=1" : "")}>
                        <span className={'submenu-list' + (item.key === "/email" && companyData.sl_key === "false" ? " disabled-menu-item" : "")}>
                            {item.label}
                        </span>
                    </Link>
                  }
                  className={(item.key === "/email" && companyData.sl_key === "false" ? " disabled-menu-item" : "")}
              >
                {item.subMenuItems.map((subMenu) => (
                  subMenu.isMenu && 
                  <Menu.Item
                    key={subMenu.key}
                    className={`submenu-list w-100 ${subMenu.disabled ? 'disabled-menu' : ''}`}
                    // style={{ margin: "100px" }}
                    icon={subMenu.key === selectedKeys[0] ? subMenu.iconSelected : subMenu.disabled? subMenu.disabledIcon: subMenu.icon}
                  >
                    {/* {subMenu.key === "/settings/logout" ? 
                        <div 
                          className={`bootstrap d-flex align-items-center justify-content-between pointer
                                        flex-row${subMenu.hasOwnProperty('group') ? ' font-size-8' : ''}`} 
                          onClick={signOut}
                        >
                          <div> 
                            {subMenu.label}
                          </div>
                          {subMenu.parent === "profile" && <div>{subMenu.iconRight}</div>}
                        </div>
                        :  */}
                        <Link to={subMenu.route + (viewAsMember ? '?viewer=1' : '')}>
                          <div className={`submenu-list  d-flex align-items-center justify-content-between 
                                flex-row${subMenu.hasOwnProperty('group') ? ' font-size-8' : ''}`}
                          >
                            <div>
                              {subMenu.label}
                            </div>
                            {subMenu.parent === "profile" && <div>{subMenu.iconRight}</div>}
                          </div>
                        </Link> 
                  {/* } */}
                  </Menu.Item> 
                ))}
              </SubMenu> 
                
              :

              // Menu section of `Non-sub-menu items`
              <Menu.Item className={"w-100" + (item.disabled === true ? "disabled-menu" : '')}
                // style={{ paddingLeft: "5px !important" }}
                key={item.key}
                icon={item.key === selectedKeys[0] ? item.iconSelected : item.icon}
                {...(item.hasOwnProperty('parent') && { className: "child-item" })}
                {...(item.hasOwnProperty('parent') && item.disabled === true && { className: "child-item disabled-menu" })}
                {...(item.hasOwnProperty('group') && { group: item.group })}
                {...(item.hasOwnProperty('group') && { className: "cursor-not-allowed group-style" } /* && {style:{height: "15px", paddingLeft:"5px"}} */)}
                {...(item.hasOwnProperty('parent') && { parent: item.parent })}
                {...(item.hasOwnProperty('subgroup') && { className: "subgroup-class" })}
              >
                {/* Deals with `Prospecting` */}
                <Link to={item.route + (viewAsMember ? "?viewer=1" : "")}>
                  <div > 
                    <span className='submenu-list'>
                      {item.label} 
                    </span>
                    {(item.label === "Inbox2" && notificationsData.counts.inbox > -1) &&
                      <span className="menu-count ms-2">{notificationsData.counts.inbox}</span>
                    }
                  </div>
                </Link>
              </Menu.Item>
            }
            </> 
          ))}

            {/* Tools disabled Heading */}
            <Menu.Item 
              className='w-100 disabled-menu'
              style={{ marginTop: "30.76px" }}
            >
              <span className='disabled-submenu-heading'>
                {toolsMenuHeading}
              </span>
            </Menu.Item>

            {/* Tools disabled menu */}
            {toolsMenuItems.map(item => (
              <>
              {

                // Menu section of `Non-sub-menu items`
                <Menu.Item 
                  className='w-100 disabled-menu'
                  key={item.key}
                  icon={item.key === selectedKeys[0] ? item.iconSelected : item.icon}
                >
                  {/* Deals with `Prospecting` */}
                    <div > 
                      <span className='disabled-submenu-list'>
                        {item.label}
                        {item.label === "Copywriting" && <sup className='ps-1 font-size-6' style={{ paddingLeft: "2%" }}>AI</sup> } 
                      </span>
                      {(item.label === "Inbox2" && notificationsData.counts.inbox > -1) &&
                        <span className="menu-count ms-2">{notificationsData.counts.inbox}</span>
                      }
                    </div>
                </Menu.Item>
              }
              </> 
            ))}
          </Menu>
          
        {/* Profile View Popup */}
          {profileOpen &&
            <div
              style={{
                top: profileRef.current ?
                  profileRef.current.getBoundingClientRect().bottom + window.scrollY + 50 : 0,
                left: profileRef.current ?
                  profileRef.current.getBoundingClientRect().left + 0 : 0,
                cursor: "pointer",
                marginLeft: "3px",
                width: "204px",
                marginTop: "3px",
              }}
              className='overlay-container profile-open'
            >
              {profileMenuItems?.filter(menu => menu.key === "/profile")[0]?.subMenuItems?.map((i, index) =>
                <div>
                  {i.key === "/settings/contact" ?
                    <div className='profile-container profile-border-bottom'>
                      <a href={mailtoLink} style={{ color: 'inherit', textDecoration: 'none' }} onClick={() => setProfileOpen(false)}>{i.label}</a>
                    </div> :
                    i.key === "/settings/logout" ?
                      <div className='profile-container border-bottom-radius-8'
                        onClick={() => {
                          setProfileOpen(false)
                          signOut()
                        }}>
                        {i.label}
                      </div> :
                      <div className='profile-container border-top-radius-8'
                      onClick={() => window.location.assign(i.route)}>
                  <span>{i.label}</span>
                  <span style={{float:"right"}}>{i.iconRight}</span>
                </div>}
                </div>
              )}
            </div>}
          <div 
            className='bottom-question'
          >
            <img src="/assets/svg/question-mark.svg" />
          </div>
        </> 
      }

      <LoaderProvider>
        {children}
      </LoaderProvider>
    </div>

          </>
  );
};

export default SideMenu;
