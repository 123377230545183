import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Input, Select, Switch, Modal, Spin } from 'antd';
import { LoaderProvider } from '../../store/loaderContext';
import { useNavigate } from 'react-router-dom';
import { menuItems } from '../../constants/Settings-Menu'
import { DropdownArrow } from "../../assets/svg/Icons";
import axiosInstance from '../../config/axiosConfig';
import { notification } from 'antd';
import { useSelector } from 'react-redux';

import '../../assets/Styles/Settings-SideMenu.css'

const LinkedIn = () => {
  const [settingsData, setSettingsData] = useState([]);
  const [startHour, setStartHour] = useState("06");
  const [startMeridiem, setStartMeridiem] = useState('AM');
  const [endHour, setEndHour] = useState('06');
  const [endMeridiem, setEndMeridiem] = useState('PM');
  const [engageDoNotSendEmail, setEngageDoNotSendEmail] = useState(false);
  const [emailDays, setEmailDays] = useState([]);
  const [syncLeadStatus, setSyncLeadStatus] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);

  const days = [0, 1, 2, 3, 4, 5, 6];
  const daysLabel = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;

  const isSuperAdmin = authData.userInfo.is_super_admin;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const regex = /^([1-9]|1[0-2])?$/;

  const pageLoaded = useRef(true);

  const convertTo12HourFormat = (time24) => {
    let [hours, minutes] = time24.split(':');
    hours = parseInt(hours, 10);
    const suffix = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${hours} ${suffix}`;
  };

  const setEmailDay = (day) => {
    const days = [...emailDays];
    const index = days.indexOf(day);

    if (index > -1 && days.length > 1) {
      days.splice(index, 1);
    } else {
      days.push(day);
    }
    setEmailDays([...days])
  }

  const fetchSettings = async () => {
    setShowLoader(true)
    try {
      const response = await axiosInstance.get(`/settings/get_settings/${companyId}?user_id=${userId}`);
      setSettingsData(response.data)
      const emailStartHour = convertTo12HourFormat(response?.data?.linkedin_settings?.start_hour)
      const emailEndHour = convertTo12HourFormat(response?.data?.linkedin_settings?.end_hour)
      setStartHour(emailStartHour.split(" ")[0])
      setStartMeridiem(emailStartHour.split(" ")[1])
      setEndHour(emailEndHour.split(" ")[0])
      setEndMeridiem(emailEndHour.split(" ")[1])
      setEmailDays(response?.data?.linkedin_settings?.days_of_the_week.length
        ?
        [...response?.data?.linkedin_settings?.days_of_the_week]
        :
        [2]
      )
      setEngageDoNotSendEmail(response?.data?.linkedin_settings?.emails_to_leads_another_campaign)
      setSyncLeadStatus(response?.data?.linkedin_settings?.sync_lead_status);
      setSendMessage(response?.data?.linkedin_settings?.linkedin_24hour)
      pageLoaded.current = false
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Settings' });
    }
    setShowLoader(false)
  }

  const convertTo24HourFormat = (hours, modifier) => {
    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours?.toString()?.padStart(2, '0')}:00`;
  };

  const updateEngageSettings = async () => {
    if(startHour !== "" && endHour !== "" ) {
    setShowLoader(true)
    const payload = {
      days_of_the_week: [...emailDays],
      start_hour: convertTo24HourFormat(startHour, startMeridiem),
      end_hour: convertTo24HourFormat(endHour, endMeridiem),
      emails_to_leads_another_campaign: engageDoNotSendEmail,
      sync_lead_status: syncLeadStatus,
      linkedin_24hour: sendMessage
    }

    try {
      await axiosInstance.put(`/linkedin_settings/update_linkedin_settings/${companyId}?user_id=${userId}`, payload);
      notification.success({ message: 'Success', description: 'LinkedIn settings has been updated successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: error?.response?.data?.detail });
    }
    setShowLoader(false)
  }
  }

  useEffect(() => {
    fetchSettings()
  }, []);

  useEffect(() => {
    if (pageLoaded.current === true) {
      return;
    }
    updateEngageSettings()
  }, [emailDays, startHour, startMeridiem, endHour, endMeridiem, engageDoNotSendEmail, syncLeadStatus, sendMessage]);


  return (
    <div className="bootstrap d-flex flex-row text-white w-100 justify-content-center align-items-center settings-background">
      <div className='d-flex flex-column profile-card'>
        <div className='profile-text w-100 mb-3'>LinkedIn</div>
        <div className='d-flex flex-row justify-content-between'>
          <div className='email-sending-text mt-2'>Message Sending Schedule</div>
          <div>
            <div className='d-flex flex-row'>
              {days?.map((day) =>
                <div className={`email-sending-days me-2 
                  ${emailDays.includes(day) ? "selected-days" : ""}` + (editAccess ? " pointer" : "") + (sendMessage ? " disabled-div" : "")}
                  {...(editAccess && { onClick: () => setEmailDay(day, false) })}
                >
                  {daysLabel[day]}</div>)}
            </div>
          </div>
        </div>
        <div className='profile-row mt-4 mb-3 h-100'></div>
        <div className='d-flex flex-row justify-content-between'>
          <div className='email-sending-text mt-2'>Send message 24/7 from your campaigns</div>
          <div>
            <Switch checked={sendMessage}  onChange={(e)=> setSendMessage(e)}/>
          </div>
        </div>
        <div className='profile-row mt-4 mb-3 h-100'></div>
        <div className='d-flex flex-row justify-content-between'>
          <div className='email-sending-text mt-2'>LinkedIn Message Sending Time (PST)</div>
          <div className='d-flex flex-row'>
            <Input placeholder="6" className='me-2 mt-1' style={{ width: "41px", height: "32px" }} value={startHour}
              onChange={(e) => {
                if (regex.test(e.target.value)) {
                  setStartHour(e.target.value)
                }
              }} disabled={!editAccess || sendMessage} />
            <Select
              disabled={!editAccess || sendMessage }
              placeholder="AM"
              options={[
                { label: "AM", value: "am" },
                { label: "PM", value: "pm" },
              ]}
              suffixIcon={<img src="/assets/svg/dropdown-arrow.svg" height={8} width={9} style={{ paddingTop: "0%" }} />}
              className="email-time mt-1"
              value={startMeridiem}
              onChange={(value) => {
                setStartMeridiem(value)
              }}
            />
            <div className='email-hyphen mx-2'>-</div>
            <Input placeholder="6" className='me-2 mt-1' style={{ width: "41px", height: "32px" }} value={endHour}
              onChange={(e) => {
                if (regex.test(e.target.value)) {
                  setEndHour(e.target.value)
                }
              }} disabled={!editAccess || sendMessage} />
            <Select
              disabled={!editAccess || sendMessage }
              placeholder="AM"
              options={[
                { label: "AM", value: "am" },
                { label: "PM", value: "pm" },
              ]}
              suffixIcon={<img src="/assets/svg/dropdown-arrow.svg" height={8} width={9} style={{ paddingTop: "0%" }} />}
              className="email-time mt-1"
              value={endMeridiem}
              onChange={(value) => {
                setEndMeridiem(value)
              }}
            />
          </div>
        </div>
        <div className='profile-row mt-4 mb-3 h-100'></div>
        <div className='d-flex flex-row justify-content-between'>
          <div className='email-sending-text mt-2'>Do not send LinkedIn messages to leads already enrolled in another campaign</div>
          <div>
            <Switch checked={engageDoNotSendEmail}
              onChange={(checked) => setEngageDoNotSendEmail(checked)} disabled={!editAccess} />
          </div>
        </div>
        <div className='profile-row mt-4 mb-3 h-100'></div>
        <div className='d-flex flex-row justify-content-between'>
          <div className='email-sending-text mt-2'>Sync manually set Lead Status from Inbox to Hubspot</div>
          <div>
            <Switch checked={syncLeadStatus}
              onChange={(checked) => setSyncLeadStatus(checked)} disabled={!editAccess} />
          </div>
        </div>
      </div>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={showLoader}

      >
        <div className="pt-4 text-center">
          <Spin size="large" />
        </div>
      </Modal>
    </div>
  );
};

export default LinkedIn;
