import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    notification,
    Progress,
    Row,
    Select,
    Spin,
    Table,
    Tag,
    Switch,
    Modal,
    Space,
    Avatar,
    Typography,
    Dropdown,
    List,
    Divider,
    Drawer
} from "antd";
import {
    CopyOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import CustomPagination from "../../../component/CustomPagination";
import { useFetcher, useNavigate } from "react-router-dom";
import axiosInstance from "../../../config/axiosConfig";
import { DownloadIcon, DropdownArrow } from "../../../assets/svg/Icons";
import InfiniteScroll from "react-infinite-scroll-component";
import getLeadCategoryIcon from "../../../common/getLeadCategoryIcon";
import { click } from "dom7";

const Inbox = () => {

    const { Paragraph } = Typography;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(1);
    const [getInboxData, setInboxData] = useState();
    const [clickedThread, setClickedThread] = useState();
    const companyData = useSelector(state => state.companyReducer.selectedCompany);
    const authData = useSelector(state => state.auth);

    const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
    const companyId = companyData.company_id;
    const inboxFilters = useRef([]);
    const [selectedInboxFilters, setSelectedInboxFilters] = useState([]);
    const [inboxSearchString, setInboxSearchString] = useState("");
    const [inboxPageNo, setInboxPageNo] = useState(1);
    const inboxMessages = useRef({ total_leads: 0, leads: [] });
    const [leadCategoryValue, setLeadCategoryValue] = useState(null);
    const [sidebarTab, setSidebarTab] = useState("details");
    const [showContactDetails, setShowContactDetails] = useState(false);
    const [leadActivity, setLeadActivity] = useState();
    const [expandedThreads, setExpandedThreads] = useState([0]);
    const [selectedEmailIds, setSelectedEmailIds] = useState([]);
    const [emailIds, setEmailIds] = useState([]);

    const { Option } = Select;

    const getInboxMessages = async (selectedInboxFilters, inboxPageNo, emailSelected, emailFilters) => {
        let selectMailFilters = [], savingLeads = [], savingMails= [];
        let url = `/linkedin_inbox/linkedin_inbox_message/${userId}?company_id=${companyId}&page=${inboxPageNo}&limit=10`;
        setLoading(1)
        let repliedIndex, inboxFilters;
        if (selectedInboxFilters.length) {
            repliedIndex = selectedInboxFilters?.findIndex(f => f === "replied");

            if (repliedIndex > -1) {
                inboxFilters = selectedInboxFilters?.toSpliced(repliedIndex, 1);
                url += "&status=replied";
                savingLeads.push("replied")
            } else {
                inboxFilters = [...selectedInboxFilters];
                savingLeads = [...selectedInboxFilters];
            }

            if (inboxFilters.length) {
                url += `&lead_category_ids=${inboxFilters.toString()}`;
            }
        }

        if (inboxSearchString.length) {
            url += `&search=${inboxSearchString}`;
        }

        if (emailFilters !== null && emailFilters !== undefined) {
            selectMailFilters = [...emailFilters]
          }
          else {
            selectMailFilters = [...selectedEmailIds]
          }

        if(emailSelected !== false && selectMailFilters.length){
            savingMails = [...selectMailFilters];
            url +=`&email_accounts=${selectMailFilters.toString()}`
        }

        try {

            const response = await axiosInstance.get(url);

            if (inboxPageNo == 1) {
                setInboxData(response?.data)
                if (response?.data?.total_leads) {
                    getLeadActivity(response?.data?.leads[0])
                }
                setClickedThread(response?.data?.leads[0])
                if (response?.data?.leads?.length > 0 && response?.data?.leads[0]?.email_history.length > 0 && response?.data?.leads[0]?.email_history[0]?.is_read !== true) {
                    updateMessageReadStatus(response?.data?.leads[0]?.email_history[0]?.id, response?.data)
                }
            } else {
                setInboxData({ total_leads: response?.data.total_leads, leads: [...(getInboxData?.leads?.length > 0 ? getInboxData?.leads : []), ...(response?.data?.leads || [])] })
            }

            const payload = {
                email_accounts_selected : [...savingMails],
                view_by_status_selected : [...savingLeads]
              }
        
              await axiosInstance.put(`/linkedin_inbox/update_linkedin_inbox_filters/${userId}?company_id=${companyId}`, payload)
        }
        catch (error) {
            notification.error({ message: 'Error', description: 'Error in fetching Inbox messages' });
        }
        setLoading(0)

    }

    const setSelectedMails = (e) => {

        setSelectedEmailIds(e);
    }

    useEffect(() => {

        setLoading(1)

        getLeadCategories()
            .then(async () => {
                const response = await getSavedFilters();
                return response
            })
            .then((response) => {
                let inboxFilter = [...response?.data[0]?.view_by_status_selected] || [];
                let mailIdFilter = [...response?.data[0]?.email_accounts_selected] || [];
                mailIdFilter = mailIdFilter.map(i =>parseInt(i, 10))
                setSelectedInboxFilters([...inboxFilter]);
                setSelectedEmailIds([...mailIdFilter]);
                getInboxMessages([...inboxFilter], inboxPageNo, true, [...mailIdFilter]);
                return
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

            getEmailIds()
        setLoading(0)
    }, []);

    const getEmailIds = async () => {
        
        setLoading(1);
    
        try {
          const response = await axiosInstance.get(`/linkedin/fetch_accounts/${userId}?company_id=${companyId}`);
    
          setEmailIds(response.data)
        } catch (error) {
          notification.error({ message: 'Error', description: 'Error in fetching Email Ids' });
        }
        setLoading(0);
      }

    const setInboxFilters = ({ key }) => {
        const filters = [...selectedInboxFilters];
        const index = filters?.indexOf(key);

        if (index > -1) {
            filters.splice(index, 1);
        } else {
            filters.push(key);
        }

        getInboxMessages([...filters], 1);
        setSelectedInboxFilters([...filters]);
        setInboxPageNo(1)
        updateInboxFilters([...filters])
    }

    const updateInboxFilters = async (filters) => {
        try {

            const response = await axiosInstance.put(`/linkedin_inbox/update_linkedin_inbox_filters/${userId}?company_id=${companyId}`,
                {
                    "email_accounts_selected": selectedEmailIds,
                    "view_by_status_selected": filters
                });

        }
        catch (error) {
            notification.error({ message: 'Error', description: 'Error in updating Inbox filters' });
        }
    }

    const getLeadCategoryValue = (clickedThread) => {
        if (!clickedThread) {
            setLeadCategoryValue(null);
            return;
        };

        const filteredRow = inboxFilters.current.find(f => f.name === clickedThread.lead_category);

        if (!filteredRow) {
            setLeadCategoryValue(null);
            return;
        };

        setLeadCategoryValue(filteredRow.value);
    }

    const getLeadCategories = async () => {
        try {
            const filters = [];

            filters.push({
                key: "replied",
                value: "replied",
                name: "replied",
                label: <div className="bootstrap">
                    <div className="d-flex align-items-center">
                        <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="16" height="auto" alt="icon" />
                        <span className="ps-2">Replied</span>
                    </div>
                </div>
            });

            const response = await axiosInstance.get(`/engage_inbox/lead_categories`);
            response.data.forEach(filter => {
                const row = {
                    key: filter.id,
                    value: filter.id,
                    name: filter.name,
                    label: <div className="bootstrap">
                        <div className="d-flex align-items-center">
                            <img src={`/assets/images/${getLeadCategoryIcon(filter.name)}`} width="16" height="auto" alt="icon" />
                            <span className="ps-2">{filter.name}</span>
                        </div>
                    </div>
                }
                filters.push(row);
            });

            inboxFilters.current = [...filters];
        } catch (error) {
            notification.error({ message: 'Error', description: 'Error in fetching Lead categories' });
        }

    }

    const getSavedFilters = async () => {

        try {
            const response = await axiosInstance.get(`/linkedin_inbox/get_linkedin_inbox_filters/${userId}?company_id=${companyId}`);
            return response
        } catch (error) {
            console.error('Error in fetching Inbox filters:', error);
        }
    }

    const triggerInboxSearch = () => {
        getInboxMessages(selectedInboxFilters, 1);
        setInboxPageNo(1)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            getInboxMessages(selectedInboxFilters, 1);
            setInboxPageNo(1)
        }
    };

    const updateLeadCategory = async lead_category_id => {
        updateLead(lead_category_id);

    }

    const updateLead = async (lead_category_id) => {
        setLoading(1);

        try {
            await axiosInstance.put(`/linkedin_inbox/update_inbox_lead_status/${clickedThread.id}?user_id=${userId}&company_id=${companyId}&lead_category_id=${lead_category_id}`);

            notification.success({ message: 'Success', description: 'Lead category has been updated successfully' });
            await getInboxMessages(selectedInboxFilters, inboxPageNo);

        } catch (error) {
            notification.error({ message: 'Error', description: error?.response?.data?.detail });
        }

        setLoading(0);
    }

    const getLeadActivity = async (clickedThread) => {
        setLoading(1);

        try {
            const response = await axiosInstance.get(`/linkedin/linkedin/lead_activity/${userId}?campaign_id=${clickedThread.campaign_id}&lead_id=${clickedThread.id}&company_id=${companyId}`);
            if (response.data) {
                setLeadActivity(response.data)
            }
            // notification.success({ message: 'Success', description: 'Lead activity has been fetched successfully' });

        } catch (error) {
            notification.error({ message: 'Error', description: 'Error in fetching Lead Activity' });
        }

        setLoading(0);
    }

    const loadMoreData = () => {
        getInboxMessages(selectedInboxFilters, inboxPageNo + 1)
        setInboxPageNo(inboxPageNo => inboxPageNo + 1);
    };

    const renderLeadCategory = (lead_category) => {
        const filteredRow = inboxFilters.current.find(f => f.name === lead_category);
        return filteredRow.value
    }

    const expandAll = async () => {
        const emailHistoryLength = clickedThread?.email_history.length;
        const indexes = [];

        for (let a = 0; a < emailHistoryLength; a++) {
            indexes.push(a);
        }

        setExpandedThreads([...indexes]);

        for (let i = 0; i < clickedThread?.email_history.length; i++) {
            if (clickedThread?.email_history[i].is_read !== true) {
                await updateMessageReadStatus(clickedThread?.email_history[i].id, getInboxData);
            }
        }
    }

    const updateIsRead = (emailId, inboxData) => {
        let leadId
        const updatedLeads = inboxData?.leads?.map(lead => {
            const updatedEmailHistory = lead.email_history.map(email => {
                if (email.id === emailId) {
                    leadId = lead.id
                    return { ...email, is_read: true };
                }
                return email;
            });
            return { ...lead, email_history: updatedEmailHistory };
        });
        setInboxData({ ...getInboxData, leads: updatedLeads });
        let selectedEmailLead = updatedLeads.filter(i => i.id === leadId)
        setClickedThread(...selectedEmailLead)
    }

    const updateMessageReadStatus = async (id, inboxData) => {

        try {
            const response = await axiosInstance.put(`/linkedin_inbox/update_linkedin_message_is_read/${id}`);
            if (response.status === 200) {
                updateIsRead(id, inboxData)
            }

            // notification.success({ message: 'Success', description: 'Message status has been updated successfully' });
            // await getInboxMessages(selectedInboxFilters, inboxPageNo);

        } catch (error) {
            notification.error({ message: 'Error', description: 'Error in updating in message read status' });
        }
    }

    const checkMessageReadStatus = (message) => {
        const statuses = message.email_history.map(msg => msg.is_read);

        // Check if the statuses array includes any false value
        const allTrue = !statuses.includes(false);
        return allTrue
    }

    return (
        <>
            <Layout style={{ minHeight: '100vh', padding: 25 }} className="bootstrap linkedin-inbox">
                <Row gutter={30} className="pb-3">
                    <Col span={6} className="gutter-row d-flex align-items-center">
                        <div className="analytics-heading font-weight-500 px-2 d-flex flex-row">
                            <span className="text-nowrap" style={{ position: 'relative', display: 'inline-block' }}>
                                Inbox
                                <sup style={{
                                    fontSize: '8px', fontWeight: '700', position: 'absolute',
                                    top: 4, right: -40
                                }}>LINKEDIN</sup>
                            </span>
                        </div>

                    </Col>
                    <Col span={18}>
                        <div style={{ height: '37px', color: 'white' }} className='d-flex justify-content-end'>

                            <div
                                style={{
                                    background: 'rgba(30, 117, 255, 1)',
                                    width: '127px',
                                    height: '32.6px',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '32.6px',
                                    letterSpacing: '0em',
                                    textAlign: 'center',
                                    color: 'rgba(255, 255, 255, 1)',
                                    borderRadius: '4.18px',
                                    cursor: 'pointer'
                                }}
                                className=""
                                onClick={() => navigate("/tools/linkedIn")}
                            >
                                Go to Linkedin
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row gutter={30} className="pb-3">
                    <Col span={16} className="crm-right-btns">
                        <div className="d-flex flex-row " >
                            <Dropdown
                                menu={{
                                    items: inboxFilters.current,
                                    onClick: setInboxFilters,
                                    selectedKeys: selectedInboxFilters,
                                }}
                                dropdownRender={(menu, index) => (
                                    <div className="inbox-filter-dropdown" key={index}>{React.cloneElement(menu)}</div>
                                )}
                            >
                                <div
                                    className="linkedin-inbox-status-btn inactive d-flex align-items-center pointer br-13"
                                >
                                    <img src="/assets/images/download-gray-2x.png" width="18" height="auto" alt="arrow" />
                                    <span className="text-nowrap ps-1">View By Status</span>
                                </div>
                            </Dropdown>
                            <Select mode="multiple" className="mx-2 w-25" onChange={setSelectedMails} placeholder="Please select accounts"
                                value={selectedEmailIds}
                                style={{ height: "33px", overflowY: "auto" }}
                            >
                                {emailIds.map((i) => (
                                    <Option key={i.account_id} value={i.account_id}>{i.account_name}</Option>
                                ))}
                            </Select>
                            <Button type="primary" className="me-2" onClick={() => {
                                inboxMessages.current.leads = [];
                                if (inboxPageNo !== 1) {
                                    setInboxPageNo(1);
                                } else {
                                    getInboxMessages(selectedInboxFilters,1);
                                }
                            }}>Apply</Button>
                            <Button className="me-2" onClick={() => {
                                setSelectedEmailIds([])
                                inboxMessages.current.leads = [];
                                if (inboxPageNo !== 1) {
                                    setInboxPageNo(1);
                                } else {
                                    getInboxMessages(selectedInboxFilters, 1, false);
                                }
                            }}>Clear</Button>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="d-flex justify-content-end">
                            <Input
                                id="search-box"
                                placeholder="search by name, email etc."
                                className="settings-enter-email custom-search-input"
                                prefix={<SearchOutlined onClick={triggerInboxSearch} style={{ color: 'inherit', fontSize: '18px' }} />}
                                style={{
                                    border: 'none', background: 'rgba(37, 37, 42, 1)',
                                    width: '363px',
                                    height: '32px', borderRadius: '7px'
                                }}
                                onChange={(e) => setInboxSearchString(e.target.value)}
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                    </Col>
                </Row>

                <Row gutter={20} className="text-white pb-3">
                    <Col span={6} id="scrollable-inbox-div" className="linkedin-inbox-list-column">
                        <InfiniteScroll
                            dataLength={inboxPageNo * 10}
                            next={loadMoreData}
                            hasMore={getInboxData?.leads?.length < getInboxData?.total_leads}
                            loader={<div className="d-flex justify-content-center p-4"><Spin size="large" /></div>}
                            endMessage={<Divider plain>No more messages to show.</Divider>}
                            scrollableTarget="scrollable-inbox-div"
                        >
                            <List
                                dataSource={getInboxData?.leads}
                                renderItem={(msg, index) => {

                                    return (
                                        <div onClick={() => {
                                            setClickedThread(msg);
                                            getLeadCategoryValue(msg);
                                            getLeadActivity(msg);
                                            setExpandedThreads([0]);
                                            updateMessageReadStatus(msg.email_history[0].id, getInboxData)
                                        }} className={`${msg.id == clickedThread.id ? 'linkedin-inbox-list-card' + '-active' : 'linkedin-inbox-list-card'} mb-2`}>
                                            <Space
                                                style={{
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Space size={10}>

                                                    <Avatar className="pointer" src={msg.from_profile_pic != null ? msg.from_profile_pic : '/assets/images/default-dp-radial-2x.png'} size={27} shape="circle"
                                                        onClick={() => setShowContactDetails(true)} />

                                                    <span style={{ fontSize: '10.1px', fontWeight: '600' }}>{msg.first_name}{" "}{msg.last_name}</span>
                                                </Space>
                                                <div><Space size={10}>
                                                    {msg.lead_category && <img width={16} height={16} src={`/assets/images/${getLeadCategoryIcon(msg.lead_category)}`} />}

                                                    {!checkMessageReadStatus(msg) && <img width={10} height={10} src="/assets/svg/small-circle-blue-bg.svg" />}</Space></div>

                                            </Space>
                                        </div>
                                    )
                                }}
                            />
                        </InfiniteScroll>

                    </Col>

                    <Col span={18} className={"gutter-row linkedin-inbox-list-column"}>
                        {clickedThread?.email_history?.map((email, idx) => {

                            const datetime = moment(`${email.date} ${email.time_str}`).local();

                            // Get the current date
                            const currentDate = moment();

                            // Calculate the difference in days between the current date and the activity date
                            const daysDiff = currentDate.diff(datetime, 'days');

                            // Format the date based on the difference in days
                            let formattedDateTime = '';

                            if (daysDiff === 0) {
                                formattedDateTime = `Today ${datetime.format('h:mm A')}`;
                            } else if (daysDiff === 1) {
                                formattedDateTime = `Yesterday ${datetime.format('h:mm A')}`;
                            } else {
                                formattedDateTime = datetime.format('ddd, MMM D, YYYY h:mm A');
                            }

                            return (
                                <>
                                    {/* Expanded */}
                                    <div className={"pb-4" + (expandedThreads.includes(idx) ? "" : " d-none")}>
                                        <div className="">
                                            <Space
                                                style={{
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Space size={10}>

                                                    <Avatar src={email.profile_pic !== null ?
                                                        email.profile_pic : '/assets/images/default-dp-radial-2x.png'}
                                                        size={28} shape="circle" />

                                                    <span style={{ fontSize: '16.86px', fontWeight: '600' }}>{email.sender}</span>
                                                    <span style={{ fontSize: '9.91px', fontWeight: '400', color: 'rgba(187, 187, 189, 1)' }}>
                                                        {email.types}</span>
                                                </Space>
                                                <div><Space size={7}>{idx == 0 ? (email.types == 'SENT' ? <img width={16} height={16} src="/assets/svg/vector-blue.svg" /> : '') : ''}
                                                    <span style={{ fontSize: '9.85px', fontWeight: '600' }}>{idx == 0 ? (email.types == 'SENT' ? 'Connection Request Sent with Message' : formattedDateTime) : formattedDateTime}
                                                    </span></Space></div>

                                            </Space>
                                        </div>
                                        <div className="inbox-content">

                                            <Typography>
                                                {/* <Paragraph className="inbox-content-para">Hey Mallory,</Paragraph> */}
                                                <Paragraph className="inbox-content-para">
                                                    <div dangerouslySetInnerHTML={{ __html: email.body }} style={{ whiteSpace: 'pre-line' }} />
                                                </Paragraph>
                                                {/* <Paragraph className="inbox-content-para">
                                        I look forward to hearing from you soon.
                                        </Paragraph>
                                        <Paragraph className="inbox-content-para">
                                            Best,<br />
                                            John
                                        </Paragraph> */}
                                            </Typography>
                                        </div>
                                    </div>

                                    {/* Expand all */}
                                    {idx === 0 &&
                                        <div className="d-flex justify-content-end align-items-center pointer mb-3" onClick={expandAll}>
                                            <span className="text-secondary small pe-2">Expand all</span>
                                            <img src="/assets/images/expand-gray-2x.png" width="16" height="auto" alt="icon" />
                                        </div>
                                    }

                                    {/* Collapsed */}
                                    <div style={{ cursor: 'pointer' }} className={"pb-4" + (expandedThreads.includes(idx) ? " d-none" : " ")}
                                        key={idx}
                                        onClick={() => {
                                            const expandedItems = [...expandedThreads];
                                            expandedItems.push(idx);
                                            if (email.is_read !== true) {
                                                updateMessageReadStatus(email.id, getInboxData)
                                            }
                                            setExpandedThreads([...expandedItems]);
                                        }}
                                    >
                                        <div className="">
                                            <Space
                                                style={{
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Space size={10}>

                                                    <Avatar src={email.profile_pic !== null ?
                                                        email.profile_pic : '/assets/images/default-dp-radial-2x.png'}
                                                        size={18} shape="circle" />

                                                    <span style={{ fontSize: '12.86px', fontWeight: '600' }}>{email.sender}</span>
                                                    <span style={{ fontSize: '7.91px', fontWeight: '400', color: 'rgba(187, 187, 189, 1)' }}>
                                                        {email.types}</span>
                                                </Space>
                                                <div><Space size={7}>{idx == 0 ? (email.types == 'SENT' ? <img width={16} height={16} src="/assets/svg/vector-blue.svg" /> : '') : ''}
                                                    <span style={{ fontSize: '9.85px', fontWeight: '100' }}>{idx == 0 ? (email.types == 'SENT' ? 'Connection Request Sent with Message' : formattedDateTime) : formattedDateTime}
                                                    </span></Space></div>

                                            </Space>
                                        </div>

                                        <div className="inbox-content">

                                            <Typography>
                                                <Paragraph className="inbox-content-para">
                                                    <div style={{ whiteSpace: 'nowrap' }}>{email.body.replace(/<[^>]*>/g, ' ').slice(0, 128)}{email.body.replace(/<[^>]*>/g, ' ').length > 110 ? "..." : ""}</div>
                                                </Paragraph>
                                            </Typography>
                                        </div>
                                    </div>


                                </>
                            )
                        })}
                    </Col>
                    {/* <Col span={6} className="gutter-row">
                        
                    </Col> */}

                </Row>
                <Modal
                    closable={false}
                    width={null}
                    centered={true}
                    footer={null}
                    title={null}
                    open={loading === 1 ? true : false}

                // className="bootstrap custom-modal text-white"
                >
                    <div className="pt-4 text-center">
                        <Spin size="large" />
                    </div>
                </Modal>
                <Drawer
                    title=""
                    placement="right"
                    width={450}
                    closable={false}
                    onClose={() => {
                        setSidebarTab("details");
                        setShowContactDetails(false);
                    }}
                    open={showContactDetails}
                    key="left"
                    className="bootstrap "
                >

                    <div className="inbox-details text-white linkedin-inbox">
                        <div className="d-flex align-items-center pb-2 pointer" onClick={() => {
                            setShowContactDetails(false)
                        }}>
                            <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
                            <span className="ps-2 white-text-62">Close</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <a href={clickedThread?.linkedin_profile} target="_blank">
                                <img
                                    src="/assets/svg/linkedin.svg"
                                    width="auto"
                                    height="20"
                                    alt="profile"

                                />
                            </a>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <Avatar className="mb-2" src={clickedThread?.from_profile_pic != null ? clickedThread?.from_profile_pic
                                : '/assets/images/default-dp-radial-2x.png'} size={50} shape="circle" />
                            <span className="mb-2" style={{ fontSize: '17.67px', fontWeight: '500' }}>{clickedThread?.first_name} {" "}
                                {clickedThread?.last_name}</span>

                            <Select
                                placeholder="Select Status"
                                options={(inboxFilters.current).slice(1)}
                                suffixIcon={<DropdownArrow />}
                                style={{
                                    border: '1px solid rgba(30, 117, 255, 0.5)',
                                    background: 'rgba(28, 28, 36, 1)',
                                    width: '100%',
                                    borderRadius: '5.19px'
                                }}
                                className="mb-3"
                                onChange={updateLeadCategory}
                                value={clickedThread?.lead_category !== undefined && clickedThread?.lead_category !== null ? renderLeadCategory(clickedThread?.lead_category) : 'Select Interest'}
                            />
                            <div className="inbox-detail-btn-grp mb-3">
                                <div onClick={() => setSidebarTab("details")} style={{ marginRight: '15px' }} className={`${sidebarTab ? 'inbox-details-btn' : 'inbox-details-btn-inactive'}`}>
                                    Contact Details
                                </div>
                                {/* <div onClick={() => { setSidebarTab(""); }} className={`${!sidebarTab ? 'inbox-details-btn' : 'inbox-details-btn-inactive'}`}>
                                    Lead Activity
                                </div> */}
                            </div>

                        </div>
                        {sidebarTab ? <div>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Name</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color">{clickedThread?.first_name} {" "}
                                {clickedThread?.last_name}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Email</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color">{clickedThread?.email}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Job Title</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color">{clickedThread?.role}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Company</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color">{clickedThread?.company_name}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Phone</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color">{clickedThread?.phone_number}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Website</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color">{clickedThread?.website}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Linkedin</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color">{clickedThread?.linkedin_profile}</Paragraph>
                            <Paragraph style={{ marginBottom: '0' }} className="inbox-details-para">Email</Paragraph>
                            <Paragraph className="inbox-details-para inbox-details-para-color">{clickedThread?.email}</Paragraph>
                        </div>
                            : <>
                                {leadActivity?.activity.length > 0 ? leadActivity?.activity.map(activity =>
                                    <div style={{ width: '100%' }}>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                        }}>
                                            <span className="mb-1" style={{ marginLeft: 'auto', fontSize: '11px', fontWeight: '400', color: 'rgba(187, 187, 189, 1)' }}>{moment.utc(activity.timestamp).local().format('ddd, MMMM Do [at] h A')}</span>
                                        </div>
                                        <div className="campaign-detail-info-card mb-2">
                                            <Space style={{ width: '100%' }} direction="vertical">
                                                <Space
                                                    style={{
                                                        width: "100%",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Space size={10}>

                                                        <Avatar
                                                            src={activity.sequence_order == 1 ? '/assets/svg/happy-blue.svg' : activity.sequence_order == 2 ? '/assets/svg/vector-blue.svg' : activity.sequence_order == 3 ? '/assets/images/thumbs-up-green.png' : '/assets/svg/chatbox-pink.svg'}
                                                            size={20} shape="circle" />

                                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>{activity.sequence_name}</span>
                                                    </Space>
                                                    {[1, 2].includes(activity.sequence_order) && <div style={{ fontSize: '12px', fontWeight: '400' }}>Step {activity.sequence_order}</div>}


                                                </Space>
                                                {[1, 2].includes(activity.sequence_order) && <Space style={{
                                                    width: "100%",
                                                    alignItems: "start",
                                                    marginLeft: '10%'
                                                }} size={10}>

                                                    <Space>
                                                        <Avatar src={activity.lead_profile_pic != null ? activity.lead_profile_pic : '/assets/images/default-dp-radial-2x.png'} size={28} shape="circle" />

                                                        <span style={{ fontSize: '13px', fontWeight: '700', marginTop: '5px' }}>{activity.lead_name}</span>
                                                    </Space>
                                                </Space>}
                                            </Space>
                                        </div>
                                    </div>
                                ) : ""}</>
                        }
                    </div>
                </Drawer>

            </Layout>
        </>
    )
}

export default Inbox