import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Upload,
  Radio,
  Checkbox,
  TreeSelect,
  DatePicker,
  Divider,
  Avatar
} from 'antd';
import { MailOutlined , PlusSquareOutlined, UnorderedListOutlined, InfoCircleOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from 'axios';
import axiosInstance from '../../config/axiosConfig';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from '../../config/AppConfig';
import { DropdownArrow, FlagIcon } from '../../assets/svg/Icons';
import CustomPagination from '../../component/CustomPagination';
import TimeoutErrorModal from '../../common/TimeoutErrorModal';
import { jobTitleOptions, headCountOptions, fundingSelectOptions, fundingOptions, monthOptions } from '../../constants/MockData';
import departmentData from '../../constants/Departments';
import LocationDropdown from '../../component/LocationDropdown';
import { filter } from 'lodash';

const NewLeads = ({ navigateToLeads }) => {
  const navigate = useNavigate();

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const { SHOW_PARENT } = TreeSelect;

  const [form] = Form.useForm();

  const [filterOpen, setFilterOpen] = useState("")
  const [jobTitleExclude, setJobTitleExclude] = useState(false)
  const [pushCampaignModal, setPushCampaignModal] = useState(false)
  const [loading, setLoading] = useState(0)
  const [loadingModal, setLoadingModal] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newLeadsData, setNewLeadsData] = useState([]);
  const [campaignsList, setCampaignsList] = useState([]);
  const [hovered, setHovered] = useState('');
  const [openCount, setOpenCount] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortByOpen, setSortByOpen] = useState(false);
  const sortByButtonRef = useRef(null);
  const [enrichModal, setEnrichModal] = useState(false);
  const [allEnrich, setAllEnrich] = useState(false);
  const [selectedEnrich, setSelectedEnrich] = useState([]);
  const [inputEnrichCount, setInputEnrichCount] = useState(0);
  const [clickedPersona, setClickedPersona] = useState();
  const [allSelectEnrich, setAllSelectEnrich] = useState(false);
  const [pushCampaignPersona, setPushCampaignPersona] = useState();
  const { Option } = Select;

  const leadColumns = (persona) => [
    {
      title: <Checkbox className="d-flex pt-2" checked={allSelectEnrich} onChange={(e) => {
        if (e.target.checked) {
          setSelectedEnrich(newLeadsData?.filter(i => i.persona_id === persona.persona_id)[0]?.leads?.filter(x => x.is_enriched !== true)?.map(i => i.id))
          setAllSelectEnrich(true)
        }
        else {
          setSelectedEnrich([])
          setAllSelectEnrich(false)
        }
      }} disabled={newLeadsData?.filter(i => i.persona_id === persona.persona_id)[0]?.leads?.filter(i => i.is_enriched !== true).length <= 0} />,
      dataIndex: "select",
      key: "select",
      render: (text, record) => (
        <Checkbox
          className="d-flex pt-2"
          checked={selectedEnrich.filter(i => i === record.id).length > 0 && record.is_enriched !== true}
          disabled={record.is_enriched}
          onChange={(e) => {
            let localEnrich = []
            if (e.target.checked === true) {
              localEnrich = [...selectedEnrich, record.id];
              setSelectedEnrich([...selectedEnrich, record.id]);
            } else {
              localEnrich = selectedEnrich.filter((i) => i !== record.id);
              setSelectedEnrich(selectedEnrich.filter((i) => i !== record.id));
            }
            if (localEnrich?.length === newLeadsData?.filter(i => i.persona_id === persona.persona_id)[0]?.leads?.filter(i => i.is_enriched !== true).length) {
              setAllSelectEnrich(true)
            }
            else {
              setAllSelectEnrich(false)
            }
          }}
        />
      ),
    },
    {
        title: <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>NAME</div>,
        key: "name",
        dataIndex: "name",
        width: "22%",
        render: (text, record) => (
            <Space
                style={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer"
                }}
                // onClick={() =>  { 
                //     setSelectedProfile(record)
                //     setDetailsDrawer(true)
                // }}
            >
                <Space>
                  
                        <Avatar src={record.profile_pic == null ? '/assets/images/default-dp-radial-2x.png' : record.profile_pic} size={30} shape="circle" />
                 
                    <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{text}</span>
                </Space>
                <a href={record.linkedin_url} target="_blank">
                    <img
                        src="/assets/svg/linkedin.svg"
                        width="auto"
                        height="12"
                        alt="profile"
                    />
                </a>
            </Space>
        ),
    },
    {
        title: (
            <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>COMPANY</div>
        ),
        key: "company",
        dataIndex: "company",
        width: "22%",
        render: (text, record) =>
            <Space
                style={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Space>
                    {record.company_logo && (
                        <Avatar src={record.company_logo} size={30} shape="circle" />
                    )}
                    <span style={{ fontSize: '11.25px', fontWeight: '600' }}>{text}</span>
                </Space>
                <a href={record.website} target="_blank">
                    <img
                        src="/assets/svg/link-in-new-tab.svg"
                        width="auto"
                        height="14"
                        alt="company"
                    />
                </a>
            </Space>

    },
    {
      title: (
          <div style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)', whiteSpace: "nowrap" }}>JOB TITLE</div>
      ),
      key: "job_title",
      dataIndex: "job_title",
      render: (text, record) =>           <Tooltip className=""
      title={
        <div className="">
          <div style={{
            backgroundColor: "#727672", margin: "0%",
            border: "1px solid rgb(73, 78, 73) !important",
            borderRadius: "5px", paddingLeft: "2%",
            paddingRight: "2%", width: "100%", paddingBottom: "9%", paddingTop: "8%",
            lineHeight: "13.72px",
            fontSize: '11.25px', fontWeight: '600'
          }} >
            {record?.role}
          </div>

        </div>
      }
    >
      <div className="pointer px-1 text-nowrap" >
        {record?.role?.substring(0, 15)}{record?.role?.length > 15 ? "..." : ""}
      </div>
    </Tooltip>
  },
  {
    title: (
      <div className="d-flex align-items-center" style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>
        <span>LOCATION</span>
        {/* <div className="sort-icon location">
        <img src="/assets/images/table-sort-white-2x.png" width="12" height="auto" alt="sort" />
      </div> */}
      </div>
    ),
    key: "location",
    dataIndex: "location",
    render: (text, record) => (record.apollo_id === null ? <span className="reset-btn">N/A</span> :
      <Space
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <Space style={{ position: "relative" }}>
          <img
            src="/assets/svg/location-icon.svg"
            width="auto"
            height="18"
            alt="location"
          />
          <div
            style={{
              position: "absolute",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              backgroundColor: "inherit",
              border: "1px solid white",
              top: "5px",
              left: "4px",
            }}
          ></div>
          <span style={{ fontSize: '11.25px', fontWeight: '600'}}>{record.city},{" "}{record.state},{" "}{text}</span>
        </Space>
      </Space>
    ),
  },
  {
    title: (
      <div className="d-flex align-items-center" 
      style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>
        <span>PHONE NUMBER</span>
      </div>
    ),
    key: "phone_number",
    dataIndex: "phone_number",
    render: (text, record) => record.apollo_id === null ? <span className="reset-btn">N/A</span> :
      // record.is_enriched ? (
        <Tooltip className=""
          title={
            <div className="">
              <div style={{
                backgroundColor: "#727672", margin: "0%",
                border: "1px solid rgb(73, 78, 73) !important",
                borderRadius: "5px", paddingLeft: "2%",
                paddingRight: "2%", width: "100%", paddingBottom: "9%", paddingTop: "8%",
                lineHeight: "13.72px",
                fontSize: '11.25px', fontWeight: '600'
              }} >
                {record?.phone_number}
              </div>

            </div>
          }
        >
          <div className="pointer text-nowrap">
            {record.phone_number?.substring(0, 15)}{record?.phone_number?.length > 15 ? "..." : ""}
          </div>
        </Tooltip>
      // ) : (
      //   <div className={"justify-content-center d-flex w-100 px-3 py-1 enrich-lead-btn-new-leads text-nowrap"}>
      //     ENRICH LEAD
      //   </div>
      // ),
  },
  {
    title: (
      <div className="d-flex align-items-center" style={{ fontSize: '9.33px', fontWeight: '600', color: 'rgba(255, 255, 255, 0.73)' }}>
        <span>EMAIL</span>
      </div>
    ),
    key: "email_address",
    dataIndex: "email_address",
    render: (text, record) =>
      record.apollo_id === null ? <div>{record.email_address}</div> :
        record.is_enriched ? (
          <Tooltip className=""
            title={
              <div className="">
                <div style={{
                  backgroundColor: "#727672", margin: "0%",
                  border: "1px solid rgb(73, 78, 73) !important",
                  borderRadius: "5px", paddingLeft: "2%",
                  paddingRight: "2%", width: "100%", paddingBottom: "9%", paddingTop: "8%",
                  lineHeight: "13.72px",
                  fontSize: '11.25px', fontWeight: '600'
                }} >
                  {record?.email_address}
                </div>

              </div>
            }
          >
            <div className="pointer px-1" >
              {record?.email_address?.substring(0, 15)}{record?.email_address?.length > 15 ? "..." : ""}
            </div>
          </Tooltip>

        ) : (
          <div className={"justify-content-center d-flex w-100 px-3 py-1 enrich-lead-btn-new-leads text-nowrap"}>
            ENRICH LEAD
          </div>
        ),
  },
];

const handleChange = (selectedOption, persona) => {
  setSelectedOptions(selectedOption);
  setPushCampaignPersona(persona)
  if(selectedOption === "Select") {
    setPushCampaignPersona(null)
  } 
};

const renderOption = (option) => (
  <div>
    <Checkbox /* checked={selectedOptions.includes(option.value)} */>
      {option.label}
    </Checkbox>
    <img src={option.icon}  style={{ float: 'right', marginTop: '5px' }} />
  </div>
);

const fetchNewLeads = async (sort) => {
  setLoading(1)
  try {
    let url=`/v2_prospect/get_new_leads/${userId}?company_id=${companyId}`
    let response
    if(sort === null || sort === undefined) {
      response = await axiosInstance.get(url)
    }
    else {
      url = `${url}&sort_by=${sort}`
      response = await axiosInstance.get(url)
    }
    setNewLeadsData(response.data)

    let countData = response.data
    countData  = countData.map(persona => ({
      leadId: persona.persona_id,
      count: 3
    }));
    setOpenCount(countData)
    setLoading(0);
  } catch (error) {
    notification.error({
      message: "Error",
      description: "Error in fetching the Persona(s)",
    });
  }
  setLoading(0)
};


const onEnrich = async () => {
  setLoadingModal(1);
  try {
    const payload = {
      company_id: companyId,
      persona_id: clickedPersona.persona_id,
      count: inputEnrichCount,
      all_leads: allEnrich,
      lead_ids: selectedEnrich,
      net_new_lead: true
    };
    const response = await axiosInstance.post(
      `/v2_prospect/enrich_leads/${userId}`,
      payload
    );
    notification.success({
      message: "Success",
      description: "Leads are getting enriched in the background",
    });
    // setLeadsDetailCurrentPage(1);
    setEnrichModal(false);
    // setProgressEnrich(true)
    // setInterval(() => {
    //   if (enrichPercent < 100) {
    //     setEnrichPercent((prevPercent) => prevPercent + 20);
    //   } else {
    //     setEnrichPercent(0);
    //   }
    // }, 1000);
    setTimeout(async () => {
      // gotoLeadDetails(clickedPersona.persona_id, 1, null, null);
      // setProgressEnrich(false)
    }, 5000);

  } catch (error) {
    notification.error({
      message: "Error",
      description: error.response.data.detail,
    });
  }
  setLoadingModal(0);
};

const handleCampaignSelect = async (e) => {
  setLoadingModal(1);
  try {
    let url =""
    const data = {
      persona_id: pushCampaignPersona.persona_id,
      campaign_id: selectedOptions.slice(0,2) !== "LI" ? parseInt(selectedOptions, 10) : parseInt(selectedOptions.slice(2), 10),
      company_id: companyId,
      is_pop_shown: false,
      allow_duplicate: false,
      all_leads: allEnrich,
      lead_ids: selectedEnrich
    }
    if(selectedOptions.slice(0,2) !== "LI"){
      url =`/v2_engage/push_lead_to_campaign/${userId}`
    }
    else {
      url = `/linkedin/push_lead_to_linkedin_campaign/${userId}`
    }
    const response = await axiosInstance.put(url, data);
    if (response.data.status === 201) {
      Modal.confirm({
        title: <div style={{ color: "#ffffff" }}>Confirm</div>,
        content: (
          <div style={{ color: "#ffffff" }}>
            Some of these leads are already enrolled in another campaign.
            Do you want to enroll duplicate leads into this campaign or only import net new leads that
            are not enrolled in any other campaigns?
          </div>
        ),
        okText: "Push",
        cancelText: <div style={{ color: "#000000" }}>Do not push</div>,
        onOk: async () => {
          setLoading(1);

          try {
            let modalUrl =""
            const data = {
              persona_id: pushCampaignPersona.persona_id,
              campaign_id: selectedOptions.slice(0,2) !== "LI" ? parseInt(selectedOptions, 10) : parseInt(selectedOptions.slice(2), 10),
              company_id: companyId,
              is_pop_shown: true,
              allow_duplicate: true,
              all_leads: allEnrich,
              lead_ids: selectedEnrich
            }
            if (selectedOptions.slice(0,2) !== "LI") {
              modalUrl = `/v2_engage/push_lead_to_campaign/${userId}`
            }
            else {
              modalUrl = `/linkedin/push_lead_to_linkedin_campaign/${userId}`
            }
            const res = await axiosInstance.put(modalUrl, data);
            notification.success({
              message: "Success",
              description: res?.data?.message,
            });
          } catch (error) {
            notification.error({
              message: "Error",
              description: "Error in pushing the leads",
            });
          }

          setLoading(0);
        },
        onCancel: async () => {
          setLoading(1);

          try {
            let modalCancelUrl =""
            const data = {
              persona_id: pushCampaignPersona.persona_id,
              campaign_id: selectedOptions.slice(0,2) !== "LI" ? parseInt(selectedOptions, 10) : parseInt(selectedOptions.slice(2), 10),
              company_id: companyId,
              is_pop_shown: true,
              allow_duplicate: false,
              all_leads: allEnrich,
              lead_ids: selectedEnrich
            }
            if (selectedOptions.slice(0,2) !== "LI") {
              modalCancelUrl = `/v2_engage/push_lead_to_campaign/${userId}`
            }
            else {
              modalCancelUrl = `/linkedin/push_lead_to_linkedin_campaign/${userId}`
            }
            const res = await axiosInstance.put(modalCancelUrl, data);
            notification.success({
              message: "Success",
              description: res?.data?.message,
            });
          } catch (error) {
            notification.error({
              message: "Error",
              description: "Error in pushing the leads",
            });
          }

          setLoading(0);
        },
      });
    } else {
      notification.success({
        message: "Error",
        description: response?.data?.message,
      });
    }
  } catch (error) {
    notification.error({
      message: "Error",
      description: error?.response?.data?.detail,
    });
  }
  setLoadingModal(0);
  setPushCampaignModal(false);
  // setSelectedCampaign("");
};

const getCampaignsList = async (sort) => {
  setLoading(1)
  try {
    let url=`v2_engage/new_lead_campaign_list/${userId}?company_id=${companyId}`
    let response = await axiosInstance.get(url)
    setCampaignsList(response.data)
    setLoading(0);
  } catch (error) {
    notification.error({
      message: "Error",
      description: "Error in fetching the campaigns",
    });
  }
  setLoading(0)
};

  const dismissLead = async (personaId) => {
    setLoading(1)
    try {
      Modal.confirm({
        title: <span style={{color:"#FFFFFF"}}>Dismiss leads</span>,
        content: <span style={{color:"#FFFFFF"}}>Do you want to dismiss all the leads?</span>,
        onOk: async () => { 
          let url = `/v2_prospect/dismiss_new_lead/${userId}?persona_id=${personaId}&company_id=${companyId}`
          let response = await axiosInstance.put(url)
          if(response.status === 200) {
          notification.success({
            message: "Success",
            description: "Successfully removed lead from the new lead list",
          });
          fetchNewLeads(null)
        }
        },
      });

    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in dismissing the lead",
      });
    }
    setLoading(0)
  };

useEffect(() => {
  fetchNewLeads(null);
}, []);

  return (
    <>
    {loading === 1 ?
      <div
        className="loader2 flex-column justify-content-center align-items-center d-flex"
      >
        <div className={"pt-4 text-center"}>
          <Spin size="large" />
        </div>
      </div> :
      <div>
        <div className='new-lead-found'>
          New Leads Found
        </div>
        <div className='d-flex flex-row justify-content-between mb-4 mt-2'>
            <div className='new-leads-count-text mb-2 mt-2'>We found {newLeadsData.reduce((accumulator, currentValue) => {
              return accumulator + parseInt(currentValue.new_leads_count, 10);
            }, 0)} new leads that match the filters you selected from your saved leads lists.</div>
          <div className='d-flex flex-row'>
            <div className="push-campaign-new-leads d-flex align-items-center p-2 mx-1 pointer"
            onClick={() => {
              setPushCampaignModal(true)
              setSortByOpen(false)
              getCampaignsList()
            }
                          // getCampaignList(); 
                          // getLinkedInCampaignList();
                          }
            >
              <img className="ps-1" src="/assets/svg/push-campaign-icon.svg" />
              <span className="text-nowrap ps-1 pe-1">Push to Campaigns</span>
            </div>
            <div
              className="sort-by-new-leads d-flex align-items-center mx-1 pointer" 
              ref={sortByButtonRef}
              onClick={() => setSortByOpen(!sortByOpen)}
              >
              <img src="/assets/svg/sort-by-icon.svg" className="ps-2" />
              <span className="text-nowrap ps-1 pe-2">Sort By</span>
            </div>
            {/* <div className="edit-name-leads pointer px-2 d-flex align-items-center" 
            // onClick={() => setRenameModalOpen(true)}
            >
                      <img className="" src="/assets/svg/export-new-leads.svg" />
                    </div> */}
          </div>
        </div>
        {sortByOpen &&
                <div className="sort-by-list overlay-container mt-2 ps-3 py-2 ms-1 
                font-size-14 font-weight-400 pointer" style={{
                    top: sortByButtonRef.current ?
                      sortByButtonRef.current.getBoundingClientRect().bottom + window.scrollY -10 : 0,
                    left: sortByButtonRef.current ?
                      sortByButtonRef.current.getBoundingClientRect().left - 295 : 0,
                    cursor: "pointer",
                    width: "12%"
                  }}
                  onClick={() => setSortByOpen(false)}>
                  <div className="pb-2"
                    onMouseEnter={() => { setHovered("name"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "name" ? 'blue' : '' }} onClick={() => {
                      setSortBy('name')
                      fetchNewLeads('name')
                    }}>Name</div>
                   <div className="pb-2"
                    onMouseEnter={() => { setHovered("jobTitle"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "jobTitle" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('role')
                      fetchNewLeads('role')
                    }}>Job Title</div>    
                   <div className="pb-2"
                    onMouseEnter={() => { setHovered("company"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "company" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('company')
                      fetchNewLeads('company')
                    }}>Company</div>

                  <div className="pb-2"
                    onMouseEnter={() => { setHovered("location"); }}
                    onMouseLeave={() => { setHovered(""); }}
                    style={{ color: hovered === "location" ? 'blue' : '' }}
                    onClick={() => {
                      setSortBy('location')
                      fetchNewLeads('location')
                    }}>Location</div>
                </div>}
          {newLeadsData.map(persona => (
            <div>
              <div className='d-flex flex-row justify-content-between lead-header p-2 w-100'>
                <div className='lead-title-font pt-1'>
                <span  style={{verticalAlign: "text-bottom"}}  className='pointer mb-3 ms-2 me-1'
                onClick={() => {
                  navigateToLeads(persona)
                  }}> <img src="/assets/svg/document-icon.svg" width="23" height="23" alt="icon" /></span>
                  <span className='pe-2'>{persona.persona_name}</span>
                  <span className='new-leads-count'>{persona.new_leads_count}</span>
                  </div>
                  <div className='d-flex flex-row'>
                    <div className='px-3 me-2 py-2 dismiss-btn pointer'
                    onClick = {() => dismissLead(persona.persona_id)}>Ignore Leads</div>
                {persona.is_all_enriched ?
                  <div className='enrich-green'><img src="/assets/svg/enrich-icon-green.svg"
                    className='me-1 vertical-align-bottom' />Enriched</div> :
                  <div className='new-enrich-leads pointer px-3 py-2' onClick={() =>  { 
                    setClickedPersona(persona)
                    setEnrichModal(true)}}>Enrich Leads</div>}
                  </div>
              </div>
              <Table
                className="new-lead-table mb-3"
                dataSource={persona.leads.slice(0,openCount.filter(i => i.leadId === persona.persona_id)[0]?.count)}
                columns={leadColumns(persona)}
                pagination={false}
                style={{
                  thead: { display: 'table-header-group', height: `5px` },
                  tbody: { display: 'block', height: '300px', overflow: 'auto' },
                  tr: { display: 'table', height: `$10px` }
                }}
              />
              {persona.new_leads_count > openCount.filter(i => i.leadId === persona.persona_id)[0]?.count &&
               <div style={{color:"#CAC6C6", fontSize: "11.25px", lineHeight: "13.61px"}} className='pointer mb-3'
                onClick={() => {                  
                    const updatedCount = openCount.map(i => {
                      if (i.leadId === persona.persona_id) {
                        return { ...i, count: i.new_leads_count }; // Create a new object with the updated count
                      }
                      return i; // Return the original object if there's no match
                    });
                    setOpenCount(updatedCount)
                  }}>View All →</div>}
            </div>))}
      </div>
      }
      <Modal
        title={null}
        closable={false}
        open={pushCampaignModal}
        onCancel={() => {
          setPushCampaignModal(false)
        }}
        footer={null}
        style={{ top: "25%" }}
        className="bootstrap text-white"
      >
        <div>
          {loadingModal === 1 ? (
            <div className="pt-4 text-center">
              <Spin size="large" />
            </div>
          ) : (
            <div>
              <div className="d-flex mb-4">
                <img src="\assets\svg\push-lead-purple-icon.svg" style={{ paddingBottom: "0.5%" }}></img>
                <span className="ps-2 font-weight-500" style={{ fontSize: "25.2px" }}>Push Leads to a Campaign</span>
              </div>
              {campaignsList.map(lead => 
              <div >
              <div className='push-lead-list-name mb-2 mt-2'>
                {lead.persona_name}
              </div>
                <Select
                  className="push-select-dropdown mb-4"
                  placeholder="Choose campaign"
                  // mode="multiple"
                  // value={selectedOptions}
                  onChange={(e) => handleChange(e,lead)}
                  optionLabelProp="label"
                  // disabled={pushCampaignPersona.persona_id!== null && pushCampaignPersona.persona_id !== undefined && pushCampaignPersona.persona_id !== lead.persona_id}
                >
                  {lead.campaigns.map(campaign => 
                  <Option value={campaign.campaign_id} key={campaign.campaign_id} label={campaign.campaign_name}>
                    {renderOption({ label: campaign.campaign_name, value: campaign.campaign_id, icon: "/assets/svg/inbox-icon.svg" })}
                  </Option>
                  )}
                  {lead.linkedin_campaigns.map(liCampaign => 
                  <Option value={`LI${liCampaign.campaign_id}`} key={liCampaign.campaign_id} label={liCampaign.campaign_name}>
                    {renderOption({ label: liCampaign.campaign_name, value: liCampaign.campaign_id, icon: "/assets/svg/linkedin.svg" })}
                  </Option>
                  )}
                  <Option value="Select" key="Select" label="Select">
                    {renderOption({ label: "Select", value: "Select" })}
                  </Option>

                </Select>
                    </div>)}
              <div className='d-flex flex-row justify-content-between w-100'>
                <div className="modal-push-cancel-btn py-1 px-3 pointer" onClick={() => {
                  setPushCampaignModal(false)
                }
                }>Cancel</div>
                <div                  
                  className='modal-push-btn py-1 px-3 pointer'
                  // disabled={newPersonaName === ""}
                  onClick={() => { setPushCampaignModal(false)
                    handleCampaignSelect()
                  }}
                >
                  Push
                </div>
              </div>
            </div>
          )}</div>
      </Modal>
      <Modal
        title={null}
        closable={false}
        open={enrichModal}
        onCancel={() => {
          setEnrichModal(false);
        }}
        footer={null}
        style={{ top: "25%" }}
        className="bootstrap text-white"
      >
        <div>
          <div className="d-flex">
            <img src="\assets\svg\enrich-icon-green.svg" className="align-self-start pt-1"></img>
            <span className="ps-2 font-weight-500" style={{ fontSize: "21px" }}>Enrich Leads</span>
          </div>
          {loadingModal === 1 ? (
            <div className="pt-4 text-center">
              <Spin size="large" />
            </div>
          ) : (
            <div >
              {selectedEnrich.length > 0 ? (
                <div>
                  <div className="font-weight-500 font-size-13 mt-2">
                    Confirming you'd like to enrich the {selectedEnrich.length}{" "}
                    leads you selected.
                  </div>
                  <div className="d-flex flex-row mt-4 font-weight-500 font-size-13">
                    <Checkbox
                      checked={allEnrich}
                      onChange={(e) => setAllEnrich(e.target.checked)}
                    />
                    <div>
                    <div className="ps-2">
                      Select All Leads in this list to Enrich
                    </div>
                    <div>{allEnrich && <div className='mb-2 ms-2 font-weight-400 font-size-13 fst-italic'>Only 100 leads will be enriched at a time</div>}</div>
                    </div>
                  </div>
                  
                  <div className="d-flex flex-row justify-content-between w-100 font-weight-400 font-size-16">
                    <Button onClick={() => setEnrichModal(false)}>Cancel</Button>
                    <Button
                      type="primary"
                      className="enrich-btn"
                      onClick={onEnrich}
                    >
                      Enrich
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="font-weight-500 font-size-13 mt-2 mb-2">How many leads would you like to enrich?</div>
                  <div className="font-weight-500 font-size-13">
                    <Input
                      disabled={allEnrich}
                      className="my-2"
                      type="number"
                      value={inputEnrichCount}
                      onChange={(e) => {
                        setInputEnrichCount(e.target.value)
                        if (e.target.value !== "") {
                          setAllEnrich(false);
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex flex-row mt-3 font-weight-500 font-size-13">
                    <Checkbox
                      checked={allEnrich}
                      onChange={(e) => {
                        setAllEnrich(e.target.checked);
                        if (e.target.checked) {
                          setInputEnrichCount(0);
                        }
                      }}
                    />
                    <div>
                    <div className="ps-2">
                      Select All Leads in this list to Enrich
                    </div>
                    <div>{allEnrich && <div className='mb-2 ms-2 font-weight-400 font-size-13 fst-italic'>Only 100 leads will be enriched at a time</div>}</div>
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-between w-100 ">
                    <Button onClick={() => setEnrichModal(false)} className="modal-cancel-btn">Cancel</Button>
                    <Button
                      type="primary"
                      className="modal-ok-btn"
                      onClick={onEnrich}
                    >
                      Enrich
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}</div>
      </Modal>
      {/* <TimeoutErrorModal shown={showTimeoutModal} action={closeTimeoutModal} close={closeTimeoutModal} /> */}
    </>
  );
};

export default NewLeads;
