import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Upload,
  Radio,
  Checkbox,
  TreeSelect,
  DatePicker,
  Divider
} from 'antd';
import { CheckCircleOutlined, PlusSquareOutlined, UnorderedListOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from 'axios';
import axiosInstance from '../../config/axiosConfig';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from '../../config/AppConfig';
import { DropdownArrow, FlagIcon } from '../../assets/svg/Icons';
import CustomPagination from '../../component/CustomPagination';
import TimeoutErrorModal from '../../common/TimeoutErrorModal';
import { jobTitleOptions, headCountOptions, fundingSelectOptions, fundingOptions, monthOptions } from '../../constants/MockData';
import departmentData from '../../constants/Departments';
import LocationDropdown from '../../component/LocationDropdown';
import { filter } from 'lodash';

const NewProspect = ({ updateFilter, handleCloseFilter, updatedFieldType, updatedSelectedValues, updatedSelectedLabels, industryOptions, hiringRolesOptions,
  technologiesOptions, jobTitleOptions, excludeValue }) => {
  const navigate = useNavigate();

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const { SHOW_PARENT } = TreeSelect;

  const [form] = Form.useForm();

  const [filterOpen, setFilterOpen] = useState("")
  const [jobTitleExclude, setJobTitleExclude] = useState(false)
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedLabel, setSelectedLabel] = useState({});
  const [departmentValues, setDepartmentValues] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationsExclude, setLocationsExclude] = useState([]);
  const [jobTitleData, setJobTitleData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [technologiesData, setTechnologiesData] = useState([]);
  const [hiringRolesData, setHiringRolesData] = useState([]);
  const [loading, setLoading] = useState(0);
  const [keywords, setKeywords] = useState([]);
  const [keywordTxt, setKeywordTxt] = useState('');
  const [fieldType, setFieldType] = useState({ headCount: "select", funding: "select" });
  const [locationExclude, setLocationExclude] = useState(false);
  const [industryExclude, setIndustryExclude] = useState(false);
  const [keywordsExclude, setKeywordsExclude] = useState(false);
  const [organizationExclude, setOrganizationExclude] = useState(false);
  const [organizationPast, setOrganizationPast] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [customError, setCustomError] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const { departmentKeyData, departmentTreeData } = departmentData;

  const onDepartmentChange = value => {
    setDepartmentValues(value);
  };

  const departmentTreeProps = {
    treeDefaultExpandAll: false,
    treeData: departmentTreeData,
    value: departmentValues,
    onChange: onDepartmentChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Please select the departments',
  };

  const onKeywordChange = event => {
    setKeywordTxt(event.target.value);
  };

  const addKeyword = e => {
    e.preventDefault();
    setKeywords([...keywords, keywordTxt]);
    setKeywordTxt('');
  };

  const managementLevelOptions = [
    { value: "owner", label: "Owner" },
    { value: "founder", label: "Founder" },
    { value: "c_suite", label: "C Suite" },
    { value: "partner", label: "Partner" },
    { value: "vp", label: "VP" },
    { value: "head", label: "Head" },
    { value: "director", label: "Director" },
    { value: "manager", label: "Manager" },
    { value: "senior", label: "Senior" },
    { value: "entry", label: "Entry" },
    { value: "intern", label: "Intern" }
  ];

  const getCompanyList = async (value) => {
    setLoadingOptions(true);
    const payload = {
      q_organization_fuzzy_name: value
    }
    try {
      const response = await axiosInstance.post(
        `/v2_prospect/q_organisation_search/${userId}?company_id=${companyId} `, payload
      );
      setCompanyList(response.data);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in fetching companies List",
      });
    }
    setLoadingOptions(false);
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    let selectedFilters = {
      ...selectedValues,
      ...changedValues,
    }

    setSelectedValues(prevValues => ({
      ...prevValues,
      ...changedValues,
    }));
    let selectedFilterlabel = {
      ...selectedLabel,
      ...changedValues
    }
    if (Object.entries(changedValues)[0][1] === "" || Object.entries(changedValues)[0][1] === undefined ||
      (Array.isArray(Object.entries(changedValues)[0][1]) && Object.entries(changedValues)[0][1].length <= 0)) {
      delete selectedValues[Object.entries(changedValues)[0][0]]
      delete selectedLabel[Object.entries(changedValues)[0][0]]
      delete selectedFilterlabel[Object.entries(changedValues)[0][0]]
      delete selectedFilters[Object.entries(changedValues)[0][0]]
    }
    else {
      if (Object.entries(changedValues)[0][0] === "date_founded_before") {
        if (Object.entries(changedValues)[0][1] === null || Object.entries(changedValues)[0][1] === undefined) {
          delete selectedValues['date_founded_before']
          delete selectedLabel['date_founded_before']
          delete selectedFilterlabel['date_founded_before']
          delete selectedFilters['date_founded_before']
          // form.setFieldValue('headCount_select', [])
        }
        else {
          let selectedDate = Object.entries(changedValues)[0][1]?.year()
          selectedFilterlabel = {
            ...selectedLabel,
            'date_founded_before': selectedDate
          }
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'date_founded_before': selectedDate
          }));

          // selectedFilters = {
          //   ...selectedLabel,
          //   'date_founded_before': selectedDate
          // }
          // setSelectedValues(prevValues => ({
          //   ...prevValues,
          //   'date_founded_before': selectedDate
          // }));
        }
      }
      else if (Object.entries(changedValues)[0][0] === "date_founded_after") {
        if (Object.entries(changedValues)[0][1] === null || Object.entries(changedValues)[0][1] === undefined) {
          delete selectedValues['date_founded_after']
          delete selectedLabel['date_founded_after']
          delete selectedFilterlabel['date_founded_after']
          delete selectedFilters['date_founded_after']
          // form.setFieldValue('headCount_select', [])
        }
        else {
          let selectedDate = Object.entries(changedValues)[0][1]?.year()
          selectedFilterlabel = {
            ...selectedLabel,
            'date_founded_after': selectedDate
          }
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'date_founded_after': selectedDate
          }));
          // selectedFilters = {
          //   ...selectedLabel,
          //   'date_founded_after': selectedDate
          // }
          // setSelectedValues(prevValues => ({
          //   ...prevValues,
          //   'date_founded_after': selectedDate
          // }));
        }
      }
      else if (Object.entries(changedValues)[0][0] === "funding_date_min") {
        if (Object.entries(changedValues)[0][1] === null || Object.entries(changedValues)[0][1] === undefined) {
          delete selectedValues['funding_date_min']
          delete selectedLabel['funding_date_min']
          delete selectedFilterlabel['funding_date_min']
          delete selectedFilters['funding_date_min']
          // form.setFieldValue('headCount_select', [])
        }
        else {
          let selectDate = Object.entries(changedValues)[0][1]; // Get the selected date
          let selectedDate = selectDate.format('YYYY-MM-DD');
          selectedFilterlabel = {
            ...selectedLabel,
            'funding_date_min': selectedDate
          }
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'funding_date_min': selectedDate
          }));
        }
      }
      else if (Object.entries(changedValues)[0][0] === "funding_date_max") {
        if (Object.entries(changedValues)[0][1] === null || Object.entries(changedValues)[0][1] === undefined) {
          delete selectedValues['funding_date_max']
          delete selectedLabel['funding_date_max']
          delete selectedFilterlabel['funding_date_max']
          delete selectedFilters['funding_date_max']
          // form.setFieldValue('headCount_select', [])
        }
        else {
          let selectDate = Object.entries(changedValues)[0][1]; // Get the selected date
          let selectedDate = selectDate.format('YYYY-MM-DD');
          selectedFilterlabel = {
            ...selectedLabel,
            'funding_date_max': selectedDate
          }
          setSelectedLabel(prevValues => ({
            ...prevValues,
            'funding_date_max': selectedDate
          }));
        }
      }
      else if (Object.entries(changedValues)[0][0] === "organization" || Object.entries(changedValues)[0][0] === "organization_exclude" 
      || Object.entries(changedValues)[0][0] === "organization_past") {
        let companyLabel = Object.entries(changedValues)[0][1].map(selectedValue => {
          if (typeof (selectedValue) === "string") {
            return JSON.parse(selectedValue).name
          }
          else {
            return selectedValue.name
          }
        })
        let companyValue = Object.entries(changedValues)[0][1].map(selectedValue => {
          if (typeof (selectedValue) === "string") {
            return JSON.parse(selectedValue)
          }
          else {
            return selectedValue
          }
        })
        selectedFilterlabel = {
          ...selectedLabel,
          [Object.entries(changedValues)[0][0]]: companyLabel
        }
        setSelectedLabel(prevValues => ({
          ...prevValues,
          [Object.entries(changedValues)[0][0]]: companyLabel
        }));
        selectedFilters = {
          ...selectedValues,
          [Object.entries(changedValues)[0][0]]: companyValue
        }
        setSelectedValues(prevValues => ({
          ...prevValues,
          [Object.entries(changedValues)[0][0]]: companyValue
        }));
      }
      else if (Object.entries(changedValues)[0][0] === "industry") {
        let industryLabel = Object.entries(changedValues)[0][1].map(selectedValue => {
          let matchingItem = industryData.find(item => item.value === selectedValue);
          return matchingItem ? matchingItem.label : null;
        });
        selectedFilterlabel = {
          ...selectedLabel,
          'industry': industryLabel
        }
        setSelectedLabel(prevValues => ({
          ...prevValues,
          'industry': industryLabel
        }));
      }
      else if (Object.entries(changedValues)[0][0] === "industry_exclude") {
        let industryLabel = Object.entries(changedValues)[0][1].map(selectedValue => {
          let matchingItem = industryData.find(item => item.value === selectedValue);
          return matchingItem ? matchingItem.label : null;
        });
        selectedFilterlabel = {
          ...selectedLabel,
          'industry_exclude': industryLabel
        }
        setSelectedLabel(prevValues => ({
          ...prevValues,
          'industry_exclude': industryLabel
        }));
      }
      else if (Object.entries(changedValues)[0][0] === "headCount_min" || Object.entries(changedValues)[0][0] === "headCount_max") {
        delete selectedValues['headCount_select']
        delete selectedLabel['headCount_select']
        delete selectedFilterlabel['headCount_select']
        delete selectedFilters['headCount_select']
        form.setFieldValue('headCount_select', [])
        setSelectedLabel(prevValues => ({
          ...prevValues,
          ...changedValues,
        }));
        selectedFilterlabel = {
          ...selectedLabel,
          ...changedValues,
        }
      }
      else if (Object.entries(changedValues)[0][0] === "headCount_select") {
        delete selectedValues['headCount_min']
        delete selectedLabel['headCount_min']
        delete selectedFilterlabel['headCount_min']
        delete selectedFilters['headCount_min']
        form.setFieldValue('headCount_min', '')
        delete selectedValues['headCount_max']
        delete selectedLabel['headCount_max']
        delete selectedFilterlabel['headCount_max']
        delete selectedFilters['headCount_max']
        form.setFieldValue('headCount_max', '')
        setSelectedLabel(prevValues => ({
          ...prevValues,
          ...changedValues,
        }));
        selectedFilterlabel = {
          ...selectedLabel,
          ...changedValues,
        }
      }
      else if (Object.entries(changedValues)[0][0] === "funding_min" || Object.entries(changedValues)[0][0] === "funding_max"
        || Object.entries(changedValues)[0][0] === "funding_date_min" || Object.entries(changedValues)[0][0] === "funding_date_max") {
        delete selectedValues['funding_select']
        delete selectedLabel['funding_select']
        delete selectedFilterlabel['funding_select']
        delete selectedFilters['funding_select']
        form.setFieldValue('funding_select', [])
        setSelectedLabel(prevValues => ({
          ...prevValues,
          ...changedValues,
        }));
        selectedFilterlabel = {
          ...selectedLabel,
          ...changedValues,
        }
      }
      else if (Object.entries(changedValues)[0][0] === "funding_select") {
        delete selectedValues['funding_min']
        delete selectedLabel['funding_min']
        delete selectedFilterlabel['funding_min']
        delete selectedFilters['funding_min']
        form.setFieldValue('funding_min', '')
        delete selectedValues['funding_max']
        delete selectedLabel['funding_max']
        delete selectedFilterlabel['funding_max']
        delete selectedFilters['funding_max']
        form.setFieldValue('funding_max', '')
        delete selectedValues['funding_date_min']
        delete selectedLabel['funding_date_min']
        delete selectedFilterlabel['funding_date_min']
        delete selectedFilters['funding_date_min']
        form.setFieldValue('funding_date_min', '')
        delete selectedValues['funding_date_max']
        delete selectedLabel['funding_date_max']
        delete selectedFilterlabel['funding_date_max']
        delete selectedFilters['funding_date_max']
        form.setFieldValue('funding_date_max', '')
        let fundingLabel = Object.entries(changedValues)[0][1].map(selectedValue => {
          let matchingItem = fundingSelectOptions.find(item => item.value === selectedValue);
          return matchingItem ? matchingItem.label : null;
        });
        selectedFilterlabel = {
          ...selectedLabel,
          'funding_select': fundingLabel
        }
        setSelectedLabel(prevValues => ({
          ...prevValues,
          'funding_select': fundingLabel
        }));
      }
      else if (Object.entries(changedValues)[0][0] === "hiring_roles") {
        let hiringLabel = Object.entries(changedValues)[0][1].map(selectedValue => {
          let matchingItem = hiringRolesData.find(item => item.value === selectedValue);
          return matchingItem ? matchingItem.label : selectedValue;
        });
        selectedFilterlabel = {
          ...selectedLabel,
          'hiring_roles': hiringLabel
        }
        setSelectedLabel(prevValues => ({
          ...prevValues,
          'hiring_roles': hiringLabel
        }));
      }
      else if (Object.entries(changedValues)[0][0] === "technologies") {
        let technologiesLabel = Object.entries(changedValues)[0][1].map(selectedValue => {
          let matchingItem = technologiesData.find(item => item.value === selectedValue);
          return matchingItem ? matchingItem.label : null;
        });
        selectedFilterlabel = {
          ...selectedLabel,
          'technologies': technologiesLabel
        }
        setSelectedLabel(prevValues => ({
          ...prevValues,
          'technologies': technologiesLabel
        }));
      }

      else {
        setSelectedLabel(prevValues => ({
          ...prevValues,
          ...changedValues,
        }));
        selectedFilterlabel = {
          ...selectedLabel,
          ...changedValues,
        }
      }
    }
    let excludeFields = {
      excludeJob: jobTitleExclude,
      excludeKeywords: keywordsExclude,
      excludeLocation: locationExclude,
      excludeIndustry: industryExclude,
      excludeOrganization: organizationExclude,
      pastOrganization: organizationPast,
    }
    updateFilter(selectedFilters, selectedFilterlabel, excludeFields, fieldType, null)
  };

  const handleChangeLocation = (place, type) => {
    if (type === "add") {
      let updatedLocations = []
      if (locations !== null && locations !== undefined) {
        updatedLocations = [...locations, place];
      }
      else {
        updatedLocations.push(place)
      }
      setLocations(updatedLocations);
      form.setFieldValue('location', updatedLocations)
      const searchLocations = {
        location: [...updatedLocations]
      }
      let selectedFilters = {
        ...selectedValues,
        ...searchLocations,
      }
      let selectedFilterlabel = {
        ...selectedLabel,
        ...searchLocations,
      }
      let excludeFields = {
        excludeJob: jobTitleExclude,
        excludeKeywords: keywordsExclude,
        excludeLocation: locationExclude,
        excludeIndustry: industryExclude,
        excludeOrganization: organizationExclude,
        pastOrganization: organizationPast,
      }
      updateFilter(selectedFilters, selectedFilterlabel, excludeFields, fieldType, null)
      setSelectedValues(prevValues => ({
        ...prevValues,
        ...searchLocations,
      }))
      setSelectedLabel(prevValues => ({
        ...prevValues,
        ...searchLocations,
      }))

    } else {
      const allLocations = [...locations];
      const updatedLocations = allLocations.filter(item => item !== place);
      setLocations(updatedLocations);
      form.setFieldValue('location', updatedLocations)
      const searchLocations = {
        location: [...updatedLocations]
      }
      let selectedFilters = {
        ...selectedValues,
        ...searchLocations,
      }
      let selectedFilterlabel = {
        ...selectedLabel,
        ...searchLocations,
      }
      let excludeFields = {
        excludeJob: jobTitleExclude,
        excludeKeywords: keywordsExclude,
        excludeLocation: locationExclude,
        excludeIndustry: industryExclude,
        excludeOrganization: organizationExclude,
        pastOrganization: organizationPast,
      }
      updateFilter(selectedFilters, selectedFilterlabel, excludeFields, fieldType, null)
      setSelectedValues(prevValues => ({
        ...prevValues,
        ...searchLocations,
      }))
      setSelectedLabel(prevValues => ({
        ...prevValues,
        ...searchLocations,
      }))
    }
  }
  const handleChangeLocationExclude = (place, type) => {
    if (type === "add") {
      let updatedLocations = []
      if (locationsExclude !== null && locationsExclude !== undefined) {
        updatedLocations = [...locationsExclude, place];
      }
      else {
        updatedLocations.push(place)
      }
      setLocationsExclude(updatedLocations);
      form.setFieldValue('location_exclude', updatedLocations)
      const searchLocations = {
        location_exclude: [...updatedLocations]
      }
      let selectedFilters = {
        ...selectedValues,
        ...searchLocations,
      }
      let selectedFilterlabel = {
        ...selectedLabel,
        ...searchLocations,
      }
      let excludeFields = {
        excludeJob: jobTitleExclude,
        excludeKeywords: keywordsExclude,
        excludeLocation: locationExclude,
        excludeIndustry: industryExclude,
        excludeOrganization: organizationExclude,
        pastOrganization: organizationPast,
      }
      updateFilter(selectedFilters, selectedFilterlabel, excludeFields, fieldType, null)
      setSelectedValues(prevValues => ({
        ...prevValues,
        ...searchLocations,
      }))
      setSelectedLabel(prevValues => ({
        ...prevValues,
        ...searchLocations,
      }))

    } else {
      const allLocations = [...locationsExclude];
      const updatedLocations = allLocations.filter(item => item !== place);
      setLocationsExclude(updatedLocations);
      form.setFieldValue('location_exclude', updatedLocations)
      const searchLocations = {
        location_exclude: [...updatedLocations]
      }
      let selectedFilters = {
        ...selectedValues,
        ...searchLocations,
      }
      let selectedFilterlabel = {
        ...selectedLabel,
        ...searchLocations,
      }
      let excludeFields = {
        excludeJob: jobTitleExclude,
        excludeKeywords: keywordsExclude,
        excludeLocation: locationExclude,
        excludeIndustry: industryExclude,
        excludeOrganization: organizationExclude,
        pastOrganization: organizationPast,
      }
      updateFilter(selectedFilters, selectedFilterlabel, excludeFields, fieldType, null)
      setSelectedValues(prevValues => ({
        ...prevValues,
        ...searchLocations,
      }))
      setSelectedLabel(prevValues => ({
        ...prevValues,
        ...searchLocations,
      }))
    }
  }

  const handleRadioChange = (e, key) => {
    const updatedObj = { ...fieldType }
    const value = e.target.value;

    updatedObj[key] = value;
    setFieldType(updatedObj);
  };

  const validateFundingMaxValue = (_, value) => {
    const minValue = form.getFieldValue('funding_min');

    if (value && minValue && Number(value) <= Number(minValue)) {
      return Promise.reject(new Error('Max value must be greater than min value'));
    }

    return Promise.resolve();
  };

  const validateFundingMinValue = (_, value) => {
    const maxValue = form.getFieldValue('funding_max');

    if (value && maxValue && Number(value) >= Number(maxValue)) {
      return Promise.reject(new Error('Min value must be less than max value'));
    }

    return Promise.resolve();
  };

  const validateRevenueMinValue = (_, value) => {
    const maxValue = form.getFieldValue('revenue_max');
    if (value && maxValue !== undefined && maxValue!== null && maxValue !== "" && Number(value) >= Number(maxValue)) {
      return Promise.reject(new Error('Min value must be less than max value'));
    }
    return Promise.resolve();
  };

  const validateRevenueMaxValue = (_, value) => {
    const minValue = form.getFieldValue('revenue_min');
    let excludeFields = {
      excludeJob: jobTitleExclude,
      excludeKeywords: keywordsExclude,
      excludeLocation: locationExclude,
      excludeIndustry: industryExclude,
      excludeOrganization: organizationExclude,
      pastOrganization: organizationPast,
    }
    if (value && minValue !== undefined && Number(value) <= Number(minValue)) {
      setCustomError(true)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, true)
      return Promise.reject(new Error('Max value must be greater than min value'));
    }
    else {
      setCustomError(false)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, false)
    }
    return Promise.resolve();
  };

  const validateRoleYearsMin = (_, value) => {
    let excludeFields = {
      excludeJob: jobTitleExclude,
      excludeKeywords: keywordsExclude,
      excludeLocation: locationExclude,
      excludeIndustry: industryExclude,
      excludeOrganization: organizationExclude,
      pastOrganization: organizationPast,
    }
    if (value && Number(value) < 0) {
      setCustomError(true)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, true)
      return Promise.reject(new Error('Please enter a valid value for Min Years'));
    }
    else if ((form.getFieldValue('max_years') !== undefined && form.getFieldValue('max_years') !== null)
      || (form.getFieldValue('max_months') !== undefined && form.getFieldValue('max_months') !== null)) {
      // min values fields
      const minYears = form.getFieldValue('min_years') ? form.getFieldValue('min_years') : 0;
      const minMonths = form.getFieldValue('min_months') ? form.getFieldValue('min_months') : 0;
      const minRoleMonths = parseInt(minYears, 10) * 12 + parseInt(minMonths, 10)
      // max values fields
      const maxYears = form.getFieldValue('max_years') ? form.getFieldValue('max_years') : 0;
      const maxMonths = form.getFieldValue('max_months') ? form.getFieldValue('max_months') : 0;
      const maxRoleMonths = parseInt(maxYears, 10) * 12 + parseInt(maxMonths, 10)
      if (maxRoleMonths && Number(maxRoleMonths) <= Number(minRoleMonths) || (!maxRoleMonths && minRoleMonths && Number(minRoleMonths) !== 0)) {
        setCustomError(true)
        updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, true)
        return Promise.reject(new Error('Max value must be greater than min value'));
      } else {
        setCustomError(false)
        updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, false)
      }
    }
    return Promise.resolve();
  };

  const validateRoleYearsMax = (_, value) => {
    let excludeFields = {
      excludeJob: jobTitleExclude,
      excludeKeywords: keywordsExclude,
      excludeLocation: locationExclude,
      excludeIndustry: industryExclude,
      excludeOrganization: organizationExclude,
      pastOrganization: organizationPast,
    }
    if (Number(form.getFieldValue('max_years')) < 0) {
      setCustomError(true)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, true)
      return Promise.reject(new Error('Please enter a valid value for Max Years'));
    }
    else if (form.getFieldValue('min_years') !== undefined
      || form.getFieldValue('min_months') !== undefined) {
      // min values fields
      const minYears = form.getFieldValue('min_years') ? form.getFieldValue('min_years') : 0;
      const minMonths = form.getFieldValue('min_months') ? form.getFieldValue('min_months') : 0;
      const minRoleMonths = parseInt(minYears, 10) * 12 + parseInt(minMonths, 10)
      // max values fields
      const maxYears = form.getFieldValue('max_years') ? form.getFieldValue('max_years') : 0;
      const maxMonths = form.getFieldValue('max_months') ? form.getFieldValue('max_months') : 0;
      const maxRoleMonths = parseInt(maxYears, 10) * 12 + parseInt(maxMonths, 10)
      if (maxRoleMonths && Number(maxRoleMonths) <= Number(minRoleMonths) || (!maxRoleMonths && minRoleMonths && Number(minRoleMonths) !== 0)) {
        setCustomError(true)
        updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, true)
        return Promise.reject(new Error('Max value must be greater than min value'));
      } else {
        setCustomError(false)
        updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, false)
      }
    } else {
      setCustomError(false)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, false)
    }
    return Promise.resolve();
  };

  // Head count fields validation
  const validateMaxValue = (_, value) => {
    let excludeFields = {
      excludeJob: jobTitleExclude,
      excludeKeywords: keywordsExclude,
      excludeLocation: locationExclude,
      excludeIndustry: industryExclude,
      excludeOrganization: organizationExclude,
      pastOrganization: organizationPast,
    }
    const minValue = form.getFieldValue('headCount_min');

    if (Number(value) < 0) {
      setCustomError(true)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, true)
      return Promise.reject(new Error('Please enter valid value'));
    }
    else if (value && minValue && Number(value) <= Number(minValue)) {
      setCustomError(true)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, true)
      return Promise.reject(new Error('Max value must be greater than min value'));
    }
    else {
      setCustomError(false)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, false)
    }

    return Promise.resolve();
  };

  const validateMinValue = (_, value) => {
    let excludeFields = {
      excludeJob: jobTitleExclude,
      excludeKeywords: keywordsExclude,
      excludeLocation: locationExclude,
      excludeIndustry: industryExclude,
      excludeOrganization: organizationExclude,
      pastOrganization: organizationPast,
    }
    const maxValue = form.getFieldValue('headCount_max');
    if (Number(value) < 0) {
      setCustomError(true)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, true)
      return Promise.reject(new Error('Please enter valid value'));
    }
    else if (value && maxValue && Number(value) >= Number(maxValue)) {
      setCustomError(true)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, true)
      return Promise.reject(new Error('Min value must be less than max value'));
    }
    else {
      setCustomError(false)
      updateFilter(selectedValues, selectedLabel, excludeFields, fieldType, false)
    }

    return Promise.resolve();
  };

  useEffect(() => {
    form.setFieldValue('job_title', [])
    form.setFieldValue('organization', [])
    form.setFieldValue('job_title_exclude', [])
    form.setFieldValue('organization_exclude', [])
    form.setFieldValue('organization_past', [])
    form.setFieldValue('location', [])
    form.setFieldValue('location_exclude', [])
    form.setFieldValue('management_level', [])
    form.setFieldValue('departments_functions', [])
    form.setFieldValue('industry', [])
    form.setFieldValue('industry_exclude', [])
    form.setFieldValue('keywords', [])
    form.setFieldValue('keywords_exclude', [])
    form.setFieldValue('hiring_roles', [])
    form.setFieldValue('technologies', [])
    form.setFieldValue('date_founded_before', null)
    form.setFieldValue('date_founded_after', null)
    form.setFieldValue('headCount_select', [])
    form.setFieldValue('headCount_min', null)
    form.setFieldValue('headCount_max', null)
    form.setFieldValue('revenue_min', null)
    form.setFieldValue('revenue_max', null)
    form.setFieldValue('funding_select', [])
    form.setFieldValue('funding_min', null)
    form.setFieldValue('funding_max', null)
    form.setFieldValue('funding_date_min', null)
    form.setFieldValue('funding_date_max', null)
    form.setFieldValue('min_years', null)
    form.setFieldValue('min_months', null)
    form.setFieldValue('max_years', null)
    form.setFieldValue('max_months', null)
    form.setFieldValue('hiring_roles', [])
    Object.entries(updatedSelectedValues).map(([fieldName, values]) => {
      form.setFieldValue(fieldName, values)
    })
    setSelectedValues(updatedSelectedValues)
    setSelectedLabel(updatedSelectedLabels)
    setLocations(updatedSelectedValues.location)
    setFieldType(updatedFieldType)
    if (updatedSelectedValues.hasOwnProperty('funding_min') || updatedSelectedValues.hasOwnProperty('funding_max')) {
      setFieldType({ ...fieldType, funding: "input" })
    }
    if (updatedSelectedValues.hasOwnProperty('headCount_min') || updatedSelectedValues.hasOwnProperty('headCount_max')) {
      setFieldType({ ...fieldType, headCount: "input" })
    }
    if (updatedSelectedValues.hasOwnProperty('organization') ) {
      let orgValue = updatedSelectedValues.organization[0]
      
      if(typeof(orgValue) === "string") {
        // setSelectedValues({ ...updatedSelectedValues, organization: updatedSelectedValues.organization.map(i =>JSON.parse(i)) })
        form.setFieldValue('organization', updatedSelectedValues.organization.map(orgString => {
          const org = JSON.parse(orgString);
          return {
            label: org.name,
            value: org,
          };
        }));
      }
      else {
        setSelectedValues({ ...updatedSelectedValues, organization: updatedSelectedValues.organization.map(i =>JSON.stringify(i)) })
        form.setFieldValue('organization', updatedSelectedValues.organization.map(org => ({
          label: org.name,
          value: org,
        })))
      }      
    }
    if (updatedSelectedValues.hasOwnProperty('organization_exclude') ) {
      let orgValue = updatedSelectedValues.organization_exclude[0]
      
      if(typeof(orgValue) === "string") {
        // setSelectedValues({ ...updatedSelectedValues, organization: updatedSelectedValues.organization.map(i =>JSON.parse(i)) })
        form.setFieldValue('organization_exclude', updatedSelectedValues.organization_exclude.map(orgString => {
          const org = JSON.parse(orgString);
          return {
            label: org.name,
            value: org,
          };
        }));
      }
      else {
        setSelectedValues({ ...updatedSelectedValues, organization_exclude: updatedSelectedValues.organization_exclude.map(i =>JSON.stringify(i)) })
        form.setFieldValue('organization_exclude', updatedSelectedValues.organization_exclude.map(org => ({
          label: org.name,
          value: org,
        })))
      }      
    }
    if (updatedSelectedValues.hasOwnProperty('organization_past') ) {
      let orgValue = updatedSelectedValues.organization_past[0]
      
      if(typeof(orgValue) === "string") {
        // setSelectedValues({ ...updatedSelectedValues, organization: updatedSelectedValues.organization.map(i =>JSON.parse(i)) })
        form.setFieldValue('organization_past', updatedSelectedValues.organization_past.map(orgString => {
          const org = JSON.parse(orgString);
          return {
            label: org.name,
            value: org,
          };
        }));
      }
      else {
        setSelectedValues({ ...updatedSelectedValues, organization_past: updatedSelectedValues.organization_past.map(i =>JSON.stringify(i)) })
        form.setFieldValue('organization_past', updatedSelectedValues.organization_past.map(org => ({
          label: org.name,
          value: org,
        })))
      }      
    }
  }, [updatedSelectedValues, updatedSelectedLabels, updatedFieldType]);

  useEffect(() => {
    setIndustryData(industryOptions)
    setJobTitleData(jobTitleOptions)
    setHiringRolesData(hiringRolesOptions)
    setTechnologiesData(technologiesOptions)
  }, [industryOptions, jobTitleOptions, hiringRolesOptions, technologiesOptions]);

  useEffect(() => {
    setLocationExclude(excludeValue?.excludeLocation)
    setIndustryExclude(excludeValue?.excludeIndustry)
    setJobTitleExclude(excludeValue?.excludeJob)
    setKeywordsExclude(excludeValue?.excludeKeywords)
    setOrganizationExclude(excludeValue?.excludeOrganization)
    setOrganizationPast(excludeValue?.pastOrganization)
  }, [excludeValue]);

  const handleFilter = (inputValue, option) => {
    return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  return (
    <>{loading === 1 ?
      <div
        className="loader2 flex-column justify-content-center align-items-center d-flex"
      >
        <div className={"pt-4 text-center"}>
          <Spin size="large" />
        </div>
      </div> :
      <Layout style={{ width: "50%", overflowY: "auto" }} className="bootstrap pt-2">
        <div>
          <Form style={{ width: "100%" }} onValuesChange={handleFormValuesChange}
            form={form}
          >
            <div className='w-100 filter-group py-2'
              style={{ height: "70vh", overflowY: "auto", borderRadius: "5px" }}>
              <div >
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('job_title')?.length > 0 || form.getFieldValue('job_title_exclude')?.length > 0 ? " job-title-green" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/job-icon.svg" className='me-2' />
                    <div>Current Job Title </div>
                    <div className={(form.getFieldValue('job_title')?.length > 0 || form.getFieldValue('job_title_exclude')?.length > 0 ?
                      "ms-2 px-2 font-size-10 highlighted-job-title border-radius-4"
                      : "d-none")}>{((form.getFieldValue('job_title')?.length) || 0) + ((form.getFieldValue('job_title_exclude')?.length) || 0)}</div>
                  </div>
                  {filterOpen === "jobTitle" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> : <div onClick={() => setFilterOpen('jobTitle')} className='pointer'><img src="/assets/svg/plus-white.svg"
                      width={13} />
                    </div>}
                </div>
                {filterOpen === "jobTitle" &&
                  <div className='ms-4 me-3'>
                    <Radio.Group value={1} className="pb-2 ms-2">
                      <Radio value={1}>Include</Radio>
                    </Radio.Group>
                    <Form.Item name="job_title" className='pb-0'>
                      <Select
                        mode="tags"
                        options={jobTitleData}
                        className="custom-select px-1 pb-1 ps-4"
                        virtual={true}
                      />
                    </Form.Item>
                    <div className="d-flex mt-2 pt-1 pb-1">
                      <Checkbox className="d-flex ms-2"
                        onChange={(e) => {
                          setJobTitleExclude(e.target.checked)
                          if (!e.target.checked) {
                            form.setFieldValue('job_title_exclude', [])
                            let values = selectedValues
                            let label = selectedLabel
                            delete selectedValues['job_title_exclude']
                            delete selectedLabel['job_title_exclude']
                            delete values['job_title_exclude']
                            delete label['job_title_exclude']
                            let excludeFields = {
                              excludeJob: e.target.checked,
                              excludeKeywords: keywordsExclude,
                              excludeLocation: locationExclude,
                              excludeIndustry: industryExclude,
                              excludeOrganization: organizationExclude,
                              pastOrganization: organizationPast,
                            }
                            updateFilter(values, label, excludeFields, fieldType, null)
                          }
                        }}
                        checked={jobTitleExclude}
                      />  <span className="ps-2 pt-1">Exclude Job title</span> </div>
                    {jobTitleExclude && <Form.Item name="job_title_exclude" className='pb-0'>
                      <Select
                        options={jobTitleData}
                        className="custom-select px-1 pb-1 ps-4 mt-2"
                        mode="tags"
                        virtual={true}
                      />
                    </Form.Item>}
                  </div>}
              </div>
              <div >
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('organization')?.length > 0 ||
                    form.getFieldValue('organization_exclude')?.length > 0 ||
                    form.getFieldValue('organization_past')?.length > 0
                    ? " job-title-green" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/job-icon.svg" className='me-2' />
                    <div>Company </div>
                    <div className={(form.getFieldValue('organization')?.length > 0 ||
                      form.getFieldValue('organization_exclude')?.length > 0 ||
                      form.getFieldValue('organization_past')?.length > 0
                      ?
                      "ms-2 px-2 font-size-10 highlighted-job-title border-radius-4"
                      : "d-none")}>{((form.getFieldValue('organization')?.length) || 0) +
                        ((form.getFieldValue('organization_exclude')?.length) || 0) +
                        ((form.getFieldValue('organization_past')?.length) || 0)
                      }</div>
                  </div>
                  {filterOpen === "organization" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> : <div onClick={() => setFilterOpen('organization')} className='pointer'><img src="/assets/svg/plus-white.svg"
                      width={13} />
                    </div>}
                </div>
                {filterOpen === "organization" &&
                  <div className='ms-4 me-3'>
                    <Radio.Group value={1} className="pb-2 ms-2">
                      <Radio value={1}>Include</Radio>
                    </Radio.Group>
                    <Form.Item name="organization" className='pb-0'>
                      <Select
                        mode="multiple"
                        options={companyList?.organizations?.map(org => ({
                          label: org.name,
                          value: JSON.stringify(org),
                        }))}
                        className="custom-select px-1 pb-1 ps-4"
                        // virtual={true}
                        onSearch={getCompanyList}
                        loading={loadingOptions}
                        optionLabelProp="label" 
                        optionValueProp="value"
                      />
                    </Form.Item>
                    <div className="d-flex mt-2 pt-1 pb-1">
                      <Checkbox className="d-flex ms-2"
                        onChange={(e) => {
                          setOrganizationExclude(e.target.checked)
                          if (!e.target.checked) {
                            form.setFieldValue('organization_exclude', [])
                            let values = selectedValues
                            let label = selectedLabel
                            delete selectedValues['organization_exclude']
                            delete selectedLabel['organization_exclude']
                            delete values['organization_exclude']
                            delete label['organization_exclude']
                            let excludeFields = {
                              excludeJob: e.target.checked,
                              excludeKeywords: keywordsExclude,
                              excludeLocation: locationExclude,
                              excludeIndustry: industryExclude,
                              excludeOrganization: organizationExclude,
                              pastOrganization: organizationPast,
                            }
                            updateFilter(values, label, excludeFields, fieldType, null)
                          }
                        }}
                        checked={organizationExclude}
                      />  <span className="ps-2 pt-1">Exclude Company</span> </div>
                    {organizationExclude && <Form.Item name="organization_exclude" className='pb-0'>
                    <Select
                        mode="multiple"
                        options={companyList?.organizations?.map(org => ({
                          label: org.name,
                          value: JSON.stringify(org),
                        }))}
                        className="custom-select px-1 pb-1 ps-4"
                        // virtual={true}
                        onSearch={getCompanyList}
                        loading={loadingOptions}
                        optionLabelProp="label" 
                        optionValueProp="value"
                      />
                    </Form.Item>}
                    <div className="d-flex mt-2 pt-1 pb-1">
                      <Checkbox className="d-flex ms-2"
                        onChange={(e) => {
                          setOrganizationPast(e.target.checked)
                          if (!e.target.checked) {
                            form.setFieldValue('organization_past', [])
                            let values = selectedValues
                            let label = selectedLabel
                            delete selectedValues['organization_past']
                            delete selectedLabel['organization_past']
                            delete values['organization_past']
                            delete label['organization_past']
                            let excludeFields = {
                              excludeJob: e.target.checked,
                              excludeKeywords: keywordsExclude,
                              excludeLocation: locationExclude,
                              excludeIndustry: industryExclude,
                              excludeOrganization: organizationExclude,
                              pastOrganization: organizationPast,
                            }
                            updateFilter(values, label, excludeFields, fieldType, null)
                          }
                        }}
                        checked={organizationPast}
                      />  <span className="ps-2 pt-1">Past Company</span> </div>
                    {organizationPast && <Form.Item name="organization_past" className='pb-0'>
                    <Select
                        mode="multiple"
                        options={companyList?.organizations?.map(org => ({
                          label: org.name,
                          value: JSON.stringify(org),
                        }))}
                        className="custom-select px-1 pb-1 ps-4"
                        // virtual={true}
                        onSearch={getCompanyList}
                        loading={loadingOptions}
                        optionLabelProp="label" 
                        optionValueProp="value"
                      />
                    </Form.Item>}
                  </div>}
              </div>
              <div >
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('location')?.length > 0 || form.getFieldValue('location_exclude')?.length > 0 ? " location-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/location-icon.svg" className='me-2' />
                    <div>Location </div>
                    <div className={
                      (form.getFieldValue('location')?.length > 0 || form.getFieldValue('location_exclude')?.length > 0 ? "ms-2 border-radius-4 px-2 font-size-10 highlighted-location-color" : "d-none")}>
                      {(form.getFieldValue('location')?.length || 0) + (form.getFieldValue('location_exclude')?.length || 0)}</div>
                  </div>
                  {filterOpen === "location" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} /> </div> :
                    <div onClick={() => setFilterOpen('location')} className='pointer'><img src="/assets/svg/plus-white.svg"
                      width={13} /></div>}
                </div>
                {filterOpen === "location" && <div className='ms-4 me-3'>
                  <Radio.Group value={1} className="pb-2 ms-2">
                    <Radio value={1}>Include</Radio>
                  </Radio.Group>
                  <Form.Item name="location" className='pb-0 mx-1 ps-4'>
                    <LocationDropdown
                      handleChangeLocation={handleChangeLocation}
                      locations={locations}
                    />
                  </Form.Item>
                  <div className="d-flex mt-2 pt-1 pb-1">
                    <Checkbox className="d-flex ms-2"
                      onChange={(e) => {
                        setLocationExclude(e.target.checked)
                        if (!e.target.checked) {
                          form.setFieldValue('location_exclude', [])
                          let values = selectedValues
                          let label = selectedLabel
                          delete selectedValues['location_exclude']
                          delete selectedLabel['location_exclude']
                          delete values['location_exclude']
                          delete label['location_exclude']
                          let excludeFields = {
                            excludeJob: jobTitleExclude,
                            excludeKeywords: keywordsExclude,
                            excludeLocation: e.target.checked,
                            excludeIndustry: industryExclude,
                            excludeOrganization: organizationExclude,
                            pastOrganization: organizationPast,
                          }
                          updateFilter(values, label, excludeFields, fieldType, null)
                        }
                      }}
                      checked={locationExclude}
                    />  <span className="ps-2 pt-1">Exclude Location</span> </div>
                  {locationExclude && <Form.Item name="location_exclude" className='pb-0 mx-1 ps-4 mt-2'>
                    <LocationDropdown
                      handleChangeLocation={handleChangeLocationExclude}
                      locations={locationsExclude}
                    />
                  </Form.Item>}
                </div>}
              </div>
              <div >
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('management_level')?.length > 0 ? " management-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/management-icon.svg" className='me-2' />
                    <div>Management Level </div>
                    <div className={
                      (form.getFieldValue('management_level')?.length > 0 ? "ms-2 px-2 font-size-10 highlighted-management-color border-radius-4" : "d-none")}>{form.getFieldValue('management_level')?.length}</div>
                  </div>
                  {filterOpen === "management_level" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('management_level')} className='pointer'><img src="/assets/svg/plus-white.svg" width={13} />
                    </div>}
                </div>
                {filterOpen === "management_level" && <div className='ms-4 me-2'>
                  <Form.Item name="management_level" className='pb-0'>
                    <Select
                      mode="multiple"
                      options={managementLevelOptions}
                      className="custom-select px-1 pb-1"
                    />
                  </Form.Item>
                </div>}
              </div>
              <div >
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('departments_functions')?.length > 0 ? " dept-fn-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/dept-fn-icon.svg" className='me-2' />
                    <div>Departments & Functions </div>
                    <div className={
                      (form.getFieldValue('departments_functions')?.length > 0 ? "ms-2 px-2 font-size-10 highlighted-dept-fn-color border-radius-4" : "d-none")}>{form.getFieldValue('departments_functions')?.length}</div>
                  </div>
                  {filterOpen === "departments_functions" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('departments_functions')} className='pointer'><img src="/assets/svg/plus-white.svg" width={13} />
                    </div>}
                </div>
                {filterOpen === "departments_functions" && <div className='ms-4 me-2'>
                  <Form.Item name="departments_functions" className='mx-1 pb-0'>
                    <TreeSelect {...departmentTreeProps} className="custom-select" />
                  </Form.Item>
                </div>}
              </div>
              <div >
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('industry')?.length > 0 || form.getFieldValue('industry_exclude')?.length > 0 ? " industry-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/industry-icon.svg" className='me-2' />
                    <div>Industry </div>
                    <div className={
                      (form.getFieldValue('industry')?.length > 0 || form.getFieldValue('industry_exclude')?.length > 0 ?
                        "ms-2 px-2 font-size-10 highlighted-industry-color border-radius-4" : "d-none")}>
                      {(form.getFieldValue('industry')?.length || 0) + (form.getFieldValue('industry_exclude')?.length || 0)}</div>
                  </div>
                  {filterOpen === "industry" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('industry')} className='pointer'><img src="/assets/svg/plus-white.svg" width={13} />
                    </div>}
                </div>
                {filterOpen === "industry" && <div className='ms-4 me-2'>
                  <Radio.Group value={1} className="pb-2 ms-2">
                    <Radio value={1}>Include</Radio>
                  </Radio.Group>
                  <Form.Item name="industry" className='pb-0 ps-4'>
                    <Select
                      mode="multiple"
                      options={industryData}
                      className="custom-select px-1 pb-1"
                      filterOption={handleFilter}
                    />
                  </Form.Item>
                  <div className="d-flex mt-2 pt-1 pb-1">
                    <Checkbox className="d-flex ms-2"
                      onChange={(e) => {
                        setIndustryExclude(e.target.checked)
                        if (!e.target.checked) {
                          form.setFieldValue('industry_exclude', [])
                          let values = selectedValues
                          let label = selectedLabel
                          delete selectedValues['industry_exclude']
                          delete selectedLabel['industry_exclude']
                          delete values['industry_exclude']
                          delete label['industry_exclude']
                          let excludeFields = {
                            excludeJob: jobTitleExclude,
                            excludeKeywords: keywordsExclude,
                            excludeLocation: locationExclude,
                            excludeIndustry: e.target.checked,
                            excludeOrganization: organizationExclude,
                            pastOrganization: organizationPast,
                          }
                          updateFilter(values, label, excludeFields, fieldType, null)
                        }
                      }}
                      checked={industryExclude}
                    />  <span className="ps-2 pt-1">Exclude Industry</span> </div>
                  {industryExclude && <Form.Item name="industry_exclude" className='pb-0 ps-4 mt-2'>
                    <Select
                      mode="multiple"
                      options={industryData}
                      className="custom-select px-1 pb-1"
                      filterOption={handleFilter}
                    />
                  </Form.Item>}
                </div>}
              </div>
              <div >
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('keywords')?.length > 0 || form.getFieldValue('keywords_exclude')?.length > 0 ? " keywords-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/keywords-icon.svg" className='me-2' />
                    <div>Keywords </div>
                    <div className={
                      (form.getFieldValue('keywords')?.length > 0 || form.getFieldValue('keywords_exclude')?.length > 0 ?
                        'ms-2 px-2 font-size-10 highlighted-keywords-color border-radius-4' : "d-none")}>
                      {(form.getFieldValue('keywords')?.length || 0) + (form.getFieldValue('keywords_exclude')?.length || 0)}</div>
                  </div>
                  {filterOpen === "keywords" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('keywords')} className='pointer'><img src="/assets/svg/plus-white.svg" width={13} />
                    </div>}
                </div>
                {filterOpen === "keywords" && <div className='ms-4 me-2'>
                  <Radio.Group value={1} className="pb-2 ms-2">
                    <Radio value={1}>Include</Radio>
                  </Radio.Group>
                  <Form.Item name="keywords" className='pb-0 ps-2'>
                    {/* <Select
                      mode="multiple"
                      placeholder="Enter Keywords"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <div className="bootstrap">
                            <Divider className="my-3" />
                            <div className="d-flex align-items-center justify-content-center pb-3">
                              <Input
                                placeholder="Enter a keyword"
                                value={keywordTxt}
                                onChange={onKeywordChange}
                                className="me-2"
                              />
                              <Button type="primary" icon={<PlusSquareOutlined />} onClick={addKeyword}>
                                Add Keyword
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                      options={keywords.map(keyword => ({
                        label: keyword,
                        value: keyword,
                      }))}
                      className="custom-select w-100"
                    /> */}
                    <Select
                        // options={jobTitleData}
                        className="custom-select px-1 pb-1 ps-4 mt-2"
                        mode="tags"
                        virtual={true}
                      />
                  </Form.Item>
                  <div className="d-flex mt-2 pt-1 pb-1">
                    <Checkbox className="d-flex ms-2"
                      onChange={(e) => {
                        setKeywordsExclude(e.target.checked)
                        if (!e.target.checked) {
                          form.setFieldValue('keywords_exclude', [])
                          let values = selectedValues
                          let label = selectedLabel
                          delete selectedValues['keywords_exclude']
                          delete selectedLabel['keywords_exclude']
                          delete values['keywords_exclude']
                          delete label['keywords_exclude']
                          let excludeFields = {
                            excludeJob: jobTitleExclude,
                            excludeKeywords: e.target.checked,
                            excludeLocation: locationExclude,
                            excludeIndustry: industryExclude,
                            excludeOrganization: organizationExclude,
                            pastOrganization: organizationPast,
                          }
                          updateFilter(values, label, excludeFields, fieldType, null)
                        }
                      }}
                      checked={keywordsExclude}
                    />  <span className="ps-2 pt-1">Exclude Keywords</span> </div>
                  {keywordsExclude && <Form.Item name="keywords_exclude" className='pb-0 ps-4 mt-2'>
                    {/* <Select
                      mode="multiple"
                      placeholder="Enter Keywords"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <div className="bootstrap">
                            <Divider className="my-3" />
                            <div className="d-flex align-items-center justify-content-center pb-3">
                              <Input
                                placeholder="Enter a keyword"
                                value={keywordTxt}
                                onChange={onKeywordChange}
                                className="me-2"
                              />
                              <Button type="primary" icon={<PlusSquareOutlined />} onClick={addKeyword}>
                                Add Keyword
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                      options={keywords.map(keyword => ({
                        label: keyword,
                        value: keyword,
                      }))}
                      className="custom-select w-100"
                    /> */}
                                        <Select
                        // options={jobTitleData}
                        className="custom-select px-1 pb-1 ps-4 mt-2"
                        mode="tags"
                        virtual={true}
                      />
                  </Form.Item>}
                </div>}
              </div>
              <div>
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('headCount_select')?.length > 0 || form.getFieldValue('headCount_min')?.length > 0 ||
                    form.getFieldValue('headCount_max')?.length > 0 ? " head-count-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/head-count-icon.svg" className='me-2' />
                    <div>Headcount </div>
                    <div className={
                      (form.getFieldValue('headCount_select')?.length > 0 || form.getFieldValue('headCount_min')?.length > 0 ||
                        form.getFieldValue('headCount_max')?.length > 0 ?
                        "ms-2 px-2 font-size-10 highlighted-head-count-color border-radius-4" : "d-none")}>
                      {(form.getFieldValue('headCount_select')?.length || 0) +
                        (form.getFieldValue('headCount_min') !== null && form.getFieldValue('headCount_min') !== undefined ? 1 : 0) +
                        (form.getFieldValue('headCount_max') !== null && form.getFieldValue('headCount_max') !== undefined ? 1 : 0)}</div>
                  </div>
                  {filterOpen === "head_count" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('head_count')} className='pointer'><img src="/assets/svg/plus-white.svg" width={13} />
                    </div>}
                </div>
                {filterOpen === "head_count" && <div className='ms-4 me-2'>
                  <div>
                    <Radio.Group
                      onChange={(e) => handleRadioChange(e, 'headCount')}
                      value={fieldType['headCount']}
                    >
                      <Radio value="select">Select</Radio>
                      <Radio value="input">Custom Range</Radio>
                    </Radio.Group>

                    <div className="mt-3 pb-0">
                      <Form.Item
                        name="headCount_select"
                        className={fieldType['headCount'] === "select" ? "" : " d-none"}
                      >
                        <Select
                          style={{ width: 200 }}
                          className="custom-select"
                          placeholder="Select a head count"
                          options={headCountOptions}
                          mode='multiple'
                        />
                      </Form.Item>
                      <div className={fieldType['headCount'] === "input" ? "" : " d-none"}>
                        <Form.Item
                          name="headCount_min"
                          rules={[{ validator: validateMinValue }]}
                        >
                          <Input className="custom-input" placeholder="Min" type="number" min={0} />
                        </Form.Item>
                        <Form.Item
                          name="headCount_max"
                          rules={[{ validator: validateMaxValue }]}
                        >
                          <Input className="custom-input" placeholder="Max" type="number" min={0} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
              <div>
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  ((form.getFieldValue('revenue_min') !== null && form.getFieldValue('revenue_min') !== undefined)
                    || (form.getFieldValue('revenue_max') !== null && form.getFieldValue('revenue_max') !== undefined)
                    ? " revenue-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/revenue-icon.svg" className='me-2' />
                    <div>Revenue </div>
                    <div className={
                      (((form.getFieldValue('revenue_min') !== null) && (form.getFieldValue('revenue_min') !== undefined) ||
                        form.getFieldValue('revenue_max') !== null && form.getFieldValue('revenue_max') !== undefined) ?
                        "ms-2 px-2 font-size-10 highlighted-revenue-color border-radius-4" : "d-none")}>
                      {(form.getFieldValue('revenue_min') !== null && form.getFieldValue('revenue_min') !== undefined ? 1 : 0)
                        + (form.getFieldValue('revenue_max') !== null && form.getFieldValue('revenue_max') !== undefined ? 1 : 0)}
                    </div>
                  </div>
                  {filterOpen === "revenue" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('revenue')} className='pointer'><img src="/assets/svg/plus-white.svg" width={13} />
                    </div>}
                </div>
                {filterOpen === "revenue" && <div className='ms-4 me-2'>
                  <Form.Item
                    name="revenue_min"
                    rules={[
                      // { required: true, message: 'Please select a min value' },
                      { validator: validateRevenueMinValue }
                    ]}
                  >
                    <Input className="custom-input mt-1" placeholder="Min" type="number" min={0} />
                  </Form.Item>
                  <Form.Item
                    name="revenue_max"
                    rules={[
                      // { required: true, message: 'Please select a max value' },
                      { validator: validateRevenueMaxValue },
                    ]}
                  >
                    <Input className="custom-input" placeholder="Max" type="number" min={0} />
                  </Form.Item>
                </div>}
              </div>
              <div>
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('funding_select')?.length > 0 ||
                    form.getFieldValue('funding_min')?.length > 0 ||
                    form.getFieldValue('funding_max')?.length > 0 ||
                    form.getFieldValue('funding_date_min') !== null && form.getFieldValue('funding_date_min') !== undefined ||
                    form.getFieldValue('funding_date_max') !== null && form.getFieldValue('funding_date_max') !== undefined ?
                    " funding-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/funding-icon.svg" className='me-2' />
                    <div>Funding </div>
                    <div className={
                      (form.getFieldValue('funding_select')?.length > 0 ||
                        form.getFieldValue('funding_min')?.length > 0 ||
                        form.getFieldValue('funding_max')?.length > 0 ||
                        form.getFieldValue('funding_date_min') !== null && form.getFieldValue('funding_date_min') !== undefined ||
                        form.getFieldValue('funding_date_max') !== null && form.getFieldValue('funding_date_max') !== undefined ?
                        "ms-2 px-2 font-size-10 highlighted-funding-color border-radius-4" : "d-none")}>
                      {(form.getFieldValue('funding_select')?.length || 0) +
                        (form.getFieldValue('funding_min') !== null && form.getFieldValue('funding_min') !== undefined ? 1 : 0) +
                        (form.getFieldValue('funding_max') !== null && form.getFieldValue('funding_max') !== undefined ? 1 : 0) +
                        (form.getFieldValue('funding_date_min') !== null && form.getFieldValue('funding_date_min') !== undefined ? 1 : 0) +
                        (form.getFieldValue('funding_date_max') !== null && form.getFieldValue('funding_date_max') !== undefined ? 1 : 0)
                      }</div>
                  </div>
                  {filterOpen === "funding" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('funding')} className='pointer'><img src="/assets/svg/plus-white.svg" width={13} />
                    </div>}
                </div>
                {filterOpen === "funding" && <div className='ms-4 me-2'>
                  <div className='mx-2'>
                    <Radio.Group onChange={(e) => handleRadioChange(e, 'funding')}
                      value={fieldType['funding']}>
                      <Radio value="select">Select</Radio>
                      <Radio value="input">Custom Range</Radio>
                    </Radio.Group>
                    <div className="mt-3 mb-4">
                      <Form.Item
                        name="funding_select"
                        className={"pb-0" + (fieldType['funding'] === "select" ? "" : " d-none")}
                      >
                        <Select
                          className="custom-select mb-4"
                          placeholder="Select a Company funding"
                          options={fundingSelectOptions}
                          mode="multiple"
                        />
                      </Form.Item>
                      
                      <div className={fieldType['funding'] === "input" ? "" : " d-none"}>
                        <Form.Item
                          name="funding_min"
                          rules={[{ validator: validateFundingMinValue }]}
                        >
                          <Input className="custom-input mt-1" placeholder="Min" type="number" min={0} />
                        </Form.Item>
                        <Form.Item
                          name="funding_max"
                          rules={[{ validator: validateFundingMaxValue }]}
                        >
                          <Input className="custom-input" placeholder="Max" type="number" min={0} />
                        </Form.Item>
                      </div>
                      <Form.Item
                          name="funding_date_min"
                        // className="pb-0 "
                        >
                          <DatePicker picker="date" className="custom-input mb-1 w-100" locale={{ locale: 'en' }} 
                            placeholder="Enter start date"/>
                        </Form.Item>
                        <Form.Item
                          name="funding_date_max"
                          className="pb-0"
                        >
                          <DatePicker picker="date" className="custom-input w-100" locale={{ locale: 'en' }} 
                          placeholder="Enter end date"/>
                        </Form.Item>
                    </div>
                  </div>
                </div>}
              </div>
              <div>
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  ((form.getFieldValue('min_years') !== null && form.getFieldValue('min_years') !== undefined &&
                    form.getFieldValue('min_years') !== "") ||
                    (form.getFieldValue('max_years') !== null && form.getFieldValue('max_years') !== undefined &&
                      form.getFieldValue('max_years') !== "") ||
                    (form.getFieldValue('min_months') !== null && form.getFieldValue('min_months') !== undefined &&
                      form.getFieldValue('min_months') !== "") ||
                    (form.getFieldValue('max_months') !== null && form.getFieldValue('max_months') !== undefined &&
                      form.getFieldValue('max_months') !== "") ? " time-spent-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/time-spent-icon.svg" className='me-2' />
                    <div>Time Spent in Role </div>
                    <div className={
                      ((form.getFieldValue('min_years') !== null && form.getFieldValue('min_years') !== undefined &&
                        form.getFieldValue('min_years') !== "") ||
                        (form.getFieldValue('max_years') !== null && form.getFieldValue('max_years') !== undefined &&
                          form.getFieldValue('max_years') !== "") ||
                        (form.getFieldValue('min_months') !== null && form.getFieldValue('min_months') !== undefined &&
                          form.getFieldValue('min_months') !== "") ||
                        (form.getFieldValue('max_months') !== null && form.getFieldValue('max_months') !== undefined &&
                          form.getFieldValue('max_months') !== "") ?
                        "ms-2 px-2 font-size-10 highlighted-time-spent-color border-radius-4" : "d-none")}>
                      {(form.getFieldValue('min_years') >= 0 || (form.getFieldValue('min_months') >= 0) ? 1 : 0) +
                        (form.getFieldValue('max_years') >= 0 || (form.getFieldValue('max_months') >= 0) ? 1 : 0)}
                    </div>
                  </div>
                  {filterOpen === "time_spent" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('time_spent')} className='pointer'><img src="/assets/svg/plus-white.svg"
                      width={13} />
                    </div>}
                </div>
                {filterOpen === "time_spent" && <div className='mx-2'>
                  <Space className="w-100 pb-3">
                    <p className="mb-0">Min</p>
                    <Form.Item
                      name="min_years"
                      className="pb-0"

                      rules={[
                        {
                          validator: validateRoleYearsMin
                        }
                      ]}

                    >
                      <Input
                        // style={{ width: '200px' }}
                        className="custom-input"
                        placeholder="Years"
                        type='number'
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item
                      name="min_months"
                      className="pb-0"
                    /* 
                    rules={[
                      {
                        validator:validateRoleYearsMin
                      }
                    ]}
                    */
                    >
                      <Select
                        className='custom-select'
                        style={{ width: '100px' }}
                        options={monthOptions}
                        placeholder="months"
                        allowClear={true}
                      />
                    </Form.Item>
                  </Space>
                  <Space className="w-100 pb-4">
                    <p className="mb-0">Max </p>
                    <Form.Item
                      name="max_years"
                      className="pb-0"
                      rules={[
                        {
                          validator: validateRoleYearsMax
                        }
                      ]}
                    >
                      <Input
                        // style={{ width: '200px' }}
                        className="custom-input"
                        placeholder="Years"
                        type='number'
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item
                      name="max_months"
                      className="pb-0"
                      rules={[
                        {
                          validator: validateRoleYearsMax
                        }
                      ]}
                    >
                      <Select
                        className='custom-select'
                        style={{ width: '100px' }}
                        options={monthOptions}
                        placeholder="months"
                        allowClear={true}
                      />
                    </Form.Item>
                  </Space>
                </div>}
              </div>
              <div>
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('hiring_roles')?.length > 0 ? " hiring-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/hiring-icon.svg" className='me-2' />
                    <div>Hiring Roles </div>
                    <div className={
                      (form.getFieldValue('hiring_roles')?.length > 0 ? "ms-2 px-2 font-size-10 highlighted-hiring-color border-radius-4" : "d-none")}>{form.getFieldValue('hiring_roles')?.length}
                    </div>
                  </div>
                  {filterOpen === "hiring_roles" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('hiring_roles')} className='pointer'><img src="/assets/svg/plus-white.svg"
                      width={13} />
                    </div>}
                </div>
                {filterOpen === "hiring_roles" && <div className='ms-4 me-2'>
                  <Form.Item name="hiring_roles" className='pb-0'>
                    <Select
                      options={hiringRolesData}
                      className="custom-select px-1 pb-1"
                      mode="tags"
                      virtual={true}
                    />
                  </Form.Item>
                </div>}
              </div>
              <div>
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  (form.getFieldValue('technologies')?.length > 0 ? " technologies-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/technologies-icon.svg" className='me-2' />
                    <div>Technologies Used </div>
                    <div className={
                      (form.getFieldValue('technologies')?.length > 0 ? "ms-2 px-2 font-size-10 highlighted-technologies-color border-radius-4" : "d-none")}>{form.getFieldValue('technologies')?.length}
                    </div>
                  </div>
                  {filterOpen === "technologies" ?
                    <div onClick={() => setFilterOpen('')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} /> </div> :
                    <div onClick={() => setFilterOpen('technologies')} className='pointer'><img src="/assets/svg/plus-white.svg"
                      width={13} />
                    </div>}
                </div>
                {filterOpen === "technologies" && <div className='ms-4 me-2'>
                  <Form.Item name="technologies" className='pb-0'>
                    <Select
                      mode="multiple"
                      options={technologiesData}
                      className="custom-select px-1 pb-1"
                      filterOption={handleFilter}
                    />
                  </Form.Item>
                </div>}
              </div>
              <div>
                <div className={'d-flex flex-row border-radius-6 justify-content-between mx-1 p-2 mb-1' +
                  ((form.getFieldValue('date_founded_before') !== null && form.getFieldValue('date_founded_before') !== undefined) ||
                    (form.getFieldValue('date_founded_after') !== null && form.getFieldValue('date_founded_after') !== undefined) ?
                    " date-founded-color" : "")}>
                  <div className='d-flex text-nowrap'>
                    <img src="/assets/svg/date-founded-icon.svg" className='me-2' />
                    <div>Date Founded </div>
                    <div className={
                      ((form.getFieldValue('date_founded_before') !== null && form.getFieldValue('date_founded_before') !== undefined) ||
                        (form.getFieldValue('date_founded_after') !== null && form.getFieldValue('date_founded_after') !== undefined) ?
                        "ms-2 px-2 font-size-10 highlighted-date-founded-color border-radius-4" : "d-none")}>
                      {(form.getFieldValue('date_founded_before') !== null ? 1 : 0) + (form.getFieldValue('date_founded_after') !== null ? 1 : 0)}
                    </div>
                  </div>
                  {filterOpen === "date_founded" ?
                    <div onClick={() => setFilterOpen('date_founded')} className='pointer'><img src="/assets/images/close-gray-2x.png"
                      width={15} />
                    </div> :
                    <div onClick={() => setFilterOpen('date_founded')} className='pointer'><img src="/assets/svg/plus-white.svg"
                      width={13} />
                    </div>}
                </div>
                {filterOpen === "date_founded" && <div className='ms-2 me-2'>
                  <div className="align-items-center pb-4">
                    <div className='d-flex justify-content-between  mx-2 mb-2'>
                      <span className="text-secondary pe-2">Min</span>
                      <Form.Item
                        name="date_founded_before"
                        className="pb-0"
                      /* rules={[
                        { 
                          required: formDirection === 1 ? true : false,
                          message: "please enter a valid answer"
                        },
                      ]} */
                      >
                        <DatePicker picker="year" className="custom-input" locale={{ locale: 'en' }} />
                      </Form.Item>
                    </div>
                    <div className='d-flex justify-content-between  mx-2'>
                      <span className="text-secondary text-nowrap">Max</span>
                      <Form.Item
                        name="date_founded_after"
                        className="pb-0"
                      /* rules={[
                        { 
                          required: formDirection === 1 ? true : false,
                          message: "please enter a valid answer"
                        },
                      ]} */
                      >
                        <DatePicker picker="year" className="custom-input" locale={{ locale: 'en' }} />
                      </Form.Item>
                    </div>
                  </div>
                </div>}
                <div className="ms-2 pt-2 pointer d-flex justify-content-end">
                  <span className="text-secondary pe-2" onClick={() => handleCloseFilter()}>Close Filters</span>
                </div>
              </div>
            </div>

          </Form>
        </div>
      </Layout>}


      {/* <TimeoutErrorModal shown={showTimeoutModal} action={closeTimeoutModal} close={closeTimeoutModal} /> */}
    </>
  );
};

export default NewProspect;
