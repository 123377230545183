import { Row, Col } from "antd"


const LinkedinCampaignCreationTitle = ({ isCampaignEdit }) => {
    return (
        <div style={{paddingTop:'40px'}}>
            {!isCampaignEdit &&
                <Row gutter={30} className="pb-2">
                    <Col span={3} className="gutter-row d-flex align-items-center">
                        <div className="linkedin-campaign-creation-btn">New</div>
                    </Col>
                </Row>}
            <Row gutter={30} className="pb-3">
                <Col span={12} className="gutter-row d-flex align-items-center">
                    <div className="headingWrapper">
                        <img
                            className="linkedinIcon"
                            src="/assets/svg/linkedin-white-back.svg"
                            alt="linkedin"
                        />
                        <div className="analytics-heading font-weight-500 px-2">
                            Campaigns
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default LinkedinCampaignCreationTitle