import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	loading: false,
	message: "",
	showMessage: false,
	redirect: "",
	token: null,
  userInfo: {}
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setUserInfo: (state, action) => {
			state.userInfo = action.payload;
		}
	},
})

export const { 
	authenticated,
	setToken,
	setUserInfo
} = authSlice.actions

export default authSlice.reducer
