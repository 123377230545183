import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import axiosInstance from '../../config/axiosConfig';
import { notification } from 'antd';
import { useSelector } from 'react-redux';

import '../../assets/Styles/Settings-SideMenu.css'

const Billing = () => {

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;

  const [billingData, setBillingData] = useState();

  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get(`/settings/billing/${userId}?company_id=${companyId}`);
      setBillingData(response.data)
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Settings' });
    }
  }

  useEffect(() => {
    fetchSettings()
  }, []);

  return (
    <div className="bootstrap d-flex flex-row text-white w-100 justify-content-center align-items-center settings-background">
      <div className='d-flex flex-column profile-card'>
        <div className='profile-text w-100 mb-3'>Billing</div>
        <div className='profile-sub-text w-100 mb-3'>
          View your plans enriched lead limits.
        </div>
        <div className='collob-add-member mb-2'>Number of Leads Enriched</div>
        <div className='d-flex justify-content-end mb-1'>{billingData?.prospect_limit} leads</div>
        <Progress
          percent={(billingData?.enriched_count / billingData?.prospect_limit) * 100}
          strokeWidth={25}
          className="custom-progress-setting"
          showInfo={false}
        />
        <div style={{ left: (billingData?.enriched_count / billingData?.prospect_limit) * 100 + "%", position: "relative" }}>{billingData?.enriched_count} leads</div>
        <div className='mt-4 billing-refresh'>Your Plan Refreshes: {billingData?.refresh_datetime}</div>
      </div>
    </div>
  );
};

export default Billing;
