import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  notification,
  Progress,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Switch,
  Avatar,
  Space,
  Typography,
  Modal,
  Result
} from "antd";
import {
  CopyOutlined,
  EditOutlined,
  UnorderedListOutlined,
  InfoCircleOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  MinusOutlined,
  CloseOutlined
} from "@ant-design/icons";
import moment from "moment";
import CustomPagination from "../../../component/CustomPagination";
import { useFetcher, useNavigate } from "react-router-dom";
import { DropdownArrow } from "../../../assets/svg/Icons";
import axiosInstance from "../../../config/axiosConfig";
import LinkedinCampaignCreationTitle from "../../../component/LinkedinCampaignCreationTitle"
import SequenceSteps from "../../../component/LinkedinSequenceSteps"
import { useLocation } from "react-router-dom";


const LinkedinCampaignCreation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(0);
  const [step, setStep] = useState(1);
  const [newCampaignDetails, setNewCampaignDetails] = useState({
    Name: "",
    SelectedLead: undefined
  });
  const [leadListOptions, setLeadListOptions] = useState([]);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [includeDuplicates, setIncludeDuplicates] = useState(false);
  const [allDuplicates, setAllDuplicates] = useState(false);
  const [progress, setProgress] = useState(10);
  const [isStepUpdate, setIsStepUpdate] = useState(false)
  const [allLeadsSelected, setAllLeadsSelected] = useState(false)
  const [selectedLeadIds, setSelectedLeadIds] = useState([])
  const authData = useSelector((state) => state.auth);
  const companyData = useSelector(
    (state) => state.companyReducer.selectedCompany
  );
  const companyId = companyData.company_id;
  const userId = authData.userInfo.is_super_admin
    ? companyData.user_id
    : authData.userInfo.userid;
  const location = useLocation();

  useEffect(() => {

    fetchPersonas();

  }, []);

  useEffect(() => {
    if (location?.state !== null) {
      // fetchCopies(location?.state?.selectedProspect)
      setNewCampaignDetails({ SelectedLead: location?.state?.selectedProspect });
      setSelectedLeadIds(location?.state?.leadIds);
      setAllLeadsSelected(location?.state?.allLeads);
    }
  }, [location?.state]);


  const fetchPersonas = async () => {
    setLoading(1)
    try {
      const response = await axiosInstance.get(
        `/v2_lists/fetch_lists_only/${userId}?company_id=${companyId}`
      );
      const options = [];

      response.data.forEach((persona) => {
        // if (!persona.status) {
        //   return;
        // }

        const option = {
          label: persona.persona_name,
          value: persona.persona_id,
        };

        options.push(option);
      });
      setLeadListOptions(options);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in fetching the Leads(s)",
      });
    }
    setLoading(0)
  };



  const updateStep = (val) => {
    if (val) {
      setStep(3)
      setProgress(100)
    } else {
      setIsStepUpdate(false)
    }
  }

  const validateStep1Details = () => {
    if (newCampaignDetails.Name === "" || newCampaignDetails.SelectedLead === undefined) {
      notification.error({
        message: "Error",
        description: "Please fill the required fields",
      });
      return false;
    }
    return true;
  };

  const closeDuplicateModal = () => {
    setShowDuplicateModal(false);
  }

  const checkDuplication = async personaId => {
    setLoading(1)
    try {
			const {data} = await axiosInstance.get(`linkedin/verify_linkedin_duplicate/${personaId}?company_id=${companyId}`);
      if (data.is_duplicate) {
        setShowDuplicateModal(true);
      }
      if (data.is_duplicate) {
        if (data.type === "Prospected") {
          setAllDuplicates(false)
        }
        else {
          setAllDuplicates(data.all_leads_duplicate)
        }
      }
      setNewCampaignDetails({ ...newCampaignDetails, non_duplicate_leads: data.duplicates.non_duplicates_leads_url, SelectedLead : personaId });
		} catch (error) {
      console.error("Network Error")
	  }

    setLoading(0);
  }

  const handleNextBtn = () => {

    if (step == "1") {
      if (validateStep1Details()) {
        setProgress(20);
        setStep(step + 1);
      }

    } else {
      setIsStepUpdate(true)
    }
  };

  const handleBackBtn = () => {
    setStep(step - 1);
    if (step - 1 == "1") {
      setProgress(10);
    } else if(step - 1 == '2') {
      
      setProgress(10);
    } else {
      navigate("/tools/linkedIn/campaign-list")
    }
  };

  const handleSelectedLead = (selectedValue) => {
    setSelectedLeadIds([]);
    setAllLeadsSelected(false);
    const selectedLabel =
      leadListOptions.find((item) => item.value === selectedValue)?.label ||
      "";

    setNewCampaignDetails((prevState) => ({
      ...prevState,
      SelectedLead: selectedValue,
    }));
    checkDuplication(selectedValue)
  };

  const handleCampaignName = (e) => {

    setNewCampaignDetails((prevState) => ({
      ...prevState,
      Name: e.target.value,
    }));
  };



  return (
    <Layout style={{ minHeight: "100vh", padding: 15 }} className="linkedin-campaign-creation bootstrap" >
      <Row
        style={{ width: "100%" }}
        
      >
        <Col className="gutter-row " span={24}>
          <Card
            className=""
            style={{ background:'inherit', border: "none", padding: "0" }}
          >
            {step != 2 && <LinkedinCampaignCreationTitle />}
            {/* Content */}
            {step == "1" && (
              <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "50vh" }}
              >
                <Row
                  gutter={30}
                  className="d-flex align-items-center w-100"
                >
                  <Col span={7} className="gutter-row"></Col>
                  <Col span={10} className="gutter-row">
                    <p style={{ fontSize: "24.52px", fontWeight: '500' }}>
                      Name your campaign:
                    </p>

                    <Input
                      className="copyright-input"
                      placeholder="Enter a name"
                      style={{
                        width: "100%",
                        border: "2.63px solid #1E75FF80",
                        background: "#1C1C24",
                        borderRadius: '10.54px'
                      }}
                      onChange={handleCampaignName}
                      value={newCampaignDetails.Name}
                    />
                    <br />
                    <br />
                    <p style={{ fontSize: "24.52px", fontWeight: '500', whiteSpace: 'nowrap' }}>
                      Choose a Lead List to start your campaign
                    </p>
                    <Select
                    className="campaign-creation-step1-select"
                      placeholder="Select"
                      options={leadListOptions}
                      suffixIcon={<DropdownArrow />}
                      style={{
                        width: "100%",
                        border: "2.79px solid #1E75FF80",
                        backgroundColor: "#1C1C24 !important",
                        borderRadius: '11.16px',
                        height:'40px'
                      }}
                      onChange={handleSelectedLead}
                      value={newCampaignDetails.SelectedLead !== undefined ? newCampaignDetails.SelectedLead : 'Select'}
                    />
                  </Col>
                  <Col span={7} className="gutter-row"></Col>

                </Row>
              </div>
            )}
            {step == "2" && (
              <SequenceSteps updateStep={updateStep} isStepUpdate={isStepUpdate} newCampaignDetails={newCampaignDetails} 
                  allLeads={ allLeadsSelected }    
                  leadIds={selectedLeadIds}/>
            )}
            {step == "3" && (
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ height: "50vh" }}
              >
                <Space direction="vertical" size="large" align="center">
                  <img
                    className="campaign-creation-linkedinIcon"
                    src="/assets/images/🎉successful.png"
                    alt="linkedin"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <span>Your LinkedIn Campaign has been created!</span>
                  <Button onClick={() => navigate('/tools/linkedin/campaign-list')} style={{ background: 'rgba(89, 30, 255, 1)', padding: '0 20px' }} type="primary">View Campaign</Button>
                </Space>
              </div>
            )}
          </Card>


        </Col>
        <div className="bottom-fixed">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {step != "3" &&
              <Button
                style={{ background: "#1E75FF" }}
                type="primary"
                icon={<ArrowLeftOutlined />}
                onClick={handleBackBtn}
              >
                Back
              </Button>
            }
            {step != "3" &&
              <Button
                style={{
                  background: "#591EFF",
                  padding: "17px 30px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
                type="primary"
                onClick={handleNextBtn}
              >
                Next
              </Button>
            }
          </div>
          <Progress
            percent={progress}
            status="active"
            className="mb-0"
            showInfo={false}
          />
        </div>
      </Row>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={loading === 1 ? true : false}

      // className="bootstrap custom-modal text-white"
      >
        <div className="pt-4 text-center">
          <Spin size="large" />
        </div>
      </Modal>
      <Modal
        title=" "
        open={showDuplicateModal}
        onOk={closeDuplicateModal}
        onCancel={closeDuplicateModal}
        maskClosable={false}
        footer={null}
        centered
        className="bootstrap custom-modal"
      >
        <Result
          subTitle={<div>Selected Persona has {allDuplicates ? "all" : ""} duplicate leads</div>}
          extra={
            <div className="d-flex justify-content-center align-items-center">
              <Button type="primary" onClick={() => {
                setIncludeDuplicates(true);
                setNewCampaignDetails({ ...newCampaignDetails, remove_duplicate: false });
                closeDuplicateModal();
              }}>Include Duplicate</Button>
              {!allDuplicates && <Button className="ms-2 red-btn" onClick={() => {
                setIncludeDuplicates(false);
                setNewCampaignDetails({ ...newCampaignDetails, remove_duplicate: true });
                closeDuplicateModal();
              }}>Exclude Duplicate</Button>}
              {allDuplicates && <Button className="ms-2 red-btn" onClick={() => {
                closeDuplicateModal();
                setNewCampaignDetails({ ...newCampaignDetails, SelectedLead: undefined });
              }}>Cancel</Button>}
            </div>
          }
        />
      </Modal>
    </Layout>
  );
};

export default LinkedinCampaignCreation;
