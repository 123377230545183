import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Form, Card, Input, notification, Button } from "antd";

import ImageComponent from "../../component/ImageComponent";
import LogoBackground from "../../component/LogoBackground";

import axiosInstance from '../../config/axiosConfig';
import { setUserInfo } from "../../store/slices/authSlice";

const Authentication = () => {
  const url = new URL(window.location)
  const params = new URLSearchParams(url.search);

  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const pageLoaded = useRef(false);
  const userId = useRef();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");

  const [pageData, setPageData] = useState({
    pageName: "signin",
    pageText: "Sign in"
  });
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);

  const onFinish = (values) => {
    switch (pageData.pageName) {
      case 'signin':
        signIn(values);
        break;
      case 'registration':
        register(values);
        break;
      case 'verify':
        verify(values);
        break;
      case 'forgot':
        initiateResetPassword(values);
        break;
      case 'newPassword':
        newPasswordCode(values);
        break;
      case 'passwordConfirm':
        resetPassword(values);
        break;
      default:
        signIn(values);
    }
  }

  const handleSubmit = (e) => {
    form
      .validateFields()
      .then((values) => {
        // console.log('values',values)
        // Validation succeeded
        onFinish(values);
      })
      .catch((errors) => {
        // Validation failed
        console.log('Form validation errors:', errors);
      });
  };

  const SubmitButton = () => {
    return (
      <ImageComponent src="/assets/svg/submit-button.svg" onClickEvent={handleSubmit} />
    )
  }

  const handleReset = () => {
    form.resetFields();
  };

  const register = async values => {
    setLoading(true);
    setSelectedEmail(values.username)
    /*
    if (authCode === "") {
      notification.error({
        message: 'Error',
        description: 'Access is limited to invited users only. Please review your email for the invitation'
      });
      setLoading(false);
      return false;
    }
    */

    const data = {
      email: values.username,
      password: values.password,
      name: values.name
    }

    if (params.has('user_id')) {
      data.user_id = params.get('user_id');
    }

    if (params.has('code')) {
      data.code = params.get('code');
    }

    try {
      const response = await axiosInstance.post('/login/signup', data);
      console.log(response)
      userId.current = response.data.userid;

      setPageData({ pageName: 'verify', pageText: 'Email verification' });
      notification.success({ message: 'User registered', description: 'Please verify your email' });
    } catch (error) {
      if (error.response?.data?.detail === "An account with the given email already exists.") {
        await resendVerificationCode(values.username);

        setPageData({ pageName: 'verify', pageText: 'Email verification' })
        notification.error({ message: 'Verification required', description: error.response?.data?.detail });
      } else {
        notification.error({ message: 'Error', description: error.response?.data?.detail });
      }
    }

    setLoading(false);
  }

  const verify = async values => {
    setLoading(true);

    const data = {
      // userid: userId.current,
      email: selectedEmail,
      code: otp.join(""),
    }

    try {
      await axiosInstance.post('/login/confirm_signup', data);
      handleReset();

      // setPageData({pageName:'signin',pageText:'Pipeline Persona <sup>AI</sup>'});
      window.location = "/auth?verification_success=true";
    } catch (error) {
      // Handle error here
      notification.error({ message: 'Verification error', description: error.response?.data?.detail });
    }

    setLoading(false);
  }

const signIn = async values => {
    setLoading(true);

    const data = {
      username: values.username,
      password: values.password
    }

    if (params.has('code')) {
      data.code = params.get('code');
    }

    try {
      const response = await axiosInstance.post('/login/auth_login', data);
      if (response?.data?.userid != null) {
        const { token } = response.data;

        localStorage.setItem('authToken', token);
        reduxDispatch(setUserInfo({ ...response.data }));

        notification.success({ message: 'Signed in', description: 'Welcome back!' });
        navigate('/select-company');
      } else {
        notification.error({ message: 'Failed to login', description: 'Please contact admin' });
      }
    } catch (error) {
      if (error.response?.data?.detail === "User is not confirmed.") {
        await resendVerificationCode(values.username);

        setPageData({ pageName: 'verify', pageText: 'Verify login' })
        notification.error({ message: 'Verification required', description: 'Please verify your email' });
      } else {
        notification.error({ message: 'Sign-in', description: error.response.data.detail });
      }
    }

    setLoading(false);
  }

  const resendVerificationCode = async email => {
    await axiosInstance.get(`/login/resend_code?email=${email}`);
  }

  const initiateResetPassword = async values => {
    setLoading(true);
    setSelectedEmail(values.username)
    let encodedEmail = encodeURIComponent(values.username)
    try {
      await axiosInstance.get(`/login/forgot_password?email=${encodedEmail}`);
      handleReset();
      setPageData({ pageName: 'newPassword', pageText: 'Forgot Password' });
    } catch (error) {
      notification.error({ message: 'Reset password error', description: error.message });
    }

    setLoading(false);
  }

  const newPasswordCode = async values => {
      setPageData({ pageName: 'passwordConfirm', pageText: 'Create a new password' })    
  }

  const resetPassword = async values => {
    setLoading(true);

    const data = {
      email: selectedEmail,
      password: values.new_password,
      code: otp.join("")
    }

    try {
      await axiosInstance.post('/login/forgot_password/confirm', data);
      handleReset();

      setPageData({ pageName: 'signin', pageText: 'Sign in' })
      notification.success({ message: 'Password reset', description: 'You can now sign in with your new password.' });
    } catch (error) {
      notification.error({ message: 'Reset password error', description: error.message });
    }

    setLoading(false);
  }

  const validatePassword = (_, value) => {
    // Length should be 8 characters
    if (pageData.pageName !== "registration") {
      return Promise.resolve(); // No validation if not in registration page
    }

    if (value.length < 8) {
      return Promise.reject('Password must be at least 8 characters long');
    }

    // Password must have uppercase characters
    if (!/[A-Z]/.test(value)) {
      return Promise.reject('Password must have uppercase characters');
    }

    // Password must have symbol characters
    if (!/[\W_]/.test(value)) {
      return Promise.reject('Password must have symbol characters');
    }

    return Promise.resolve();
  };

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOtpPress = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleOtpPaste = (e, index) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text/plain').trim();
  
    // Extract only numeric characters from the pasted data
    const extractedDigits = pastedData.replace(/\D/g, '');
  
    // Paste the first six digits into OTP input boxes
    const newOtp = [...otp];
    let pasteIndex = index;
    for (let i = 0; i < extractedDigits.length && pasteIndex < otp.length; i++) {
      newOtp[pasteIndex] = extractedDigits[i];
      pasteIndex++;
    }
    setOtp(newOtp);
    form.setFieldValue("verificationCode", newOtp)
  
    // Move focus to the next input box if available
    const nextIndex = index + 1;
    if (nextIndex < otp.length && inputRefs.current[nextIndex]) {
      inputRefs.current[nextIndex].focus();
    }
  };
  

  useEffect(() => {
    if(localStorage.getItem('authToken')!== null && localStorage.getItem('authToken') !== undefined 
        && localStorage.getItem('company_id')!== null && localStorage.getItem('company_id')!== undefined) {
      window.location.assign("/engage/campaign-list");
    }
    if (pageLoaded.current) {
      return;
    }

    pageLoaded.current = true;

    if (params.has("verification_success")) {
      notification.success({ message: 'Email verified', description: 'You can now sign in' });
    }

    if (params.has('code')) {
      setPageData({ pageName: 'registration', pageText: 'Create your account.' })
    }
  }, [])

  return (
    <>
      

      
      <div className="auth-background">
        <div className="d-flex flex-row bootstrap">
          <div className="persona-left-icon d-flex flex-row mt-4 ms-4">
            <img src="\assets\svg\purple-persona-icon.svg" />
            <span className="pipeline-text ps-2 pt-1">Pipeline</span>
          </div>
          <div className=" bootstrap">
            <img src="\assets\svg\top-grid.svg" className="top-grid" />
          </div>
        </div>
        <div>
          {(loading) && <div >
            {/* <LogoBackground
              backgroundImage="/assets/svg/eclipse-big.svg"
              backgroundImageStyle={{ height: '100vh' }}
            /> */}
          </div>
          }
          {(!loading) &&
            <div className="signin-container bootstrap">
              <div
                // style={{
                //   border: "0px",
                //   width: "28%"
                // }}
              >
                {/* <LogoBackground
                  backgroundImageStyle={{ height: '30vh' }}
                  absoluteImageStyle={{
                    position: 'absolute',
                    height: '10vh'
                  }}
                /> */}
                <div className="w-100 d-flex flex-row justify-content-center mb-4">
                  <img src="\assets\svg\purple-persona-icon.svg" height={80} width={80} />
                </div>

                <div className="font-weight-500 font-size-32 text-white" style={{ textAlign: 'center' }}>
                  <span dangerouslySetInnerHTML={{ __html: pageData.pageText }}></span>
                </div>

                {(pageData.pageName === "signin" || pageData.pageName === "registration") && <div className="page-quote">Get more leads, close more deals.</div>}
                {(pageData.pageName === "forgot") && <div className="page-quote">Verify your email and reset your password.</div>}
                {(pageData.pageName === "verify" || pageData.pageName === "newPassword") && <div className="page-quote">Enter the verification code sent to your email address.</div>}

                <Form form={form} initialValues={{ username: params.has('code') ? params.get('email') : "" }}>
                  {(pageData.pageName === "registration") && <div>
                    <Form.Item
                      name="name"
                      rules={[{ required: true, message: 'Please input your name!' }]}
                      style={{ marginBottom: '8px' }}>
                      <Input placeholder="Your Name" className="signin-name" />
                    </Form.Item>
                    <Form.Item
                      name="company_name"
                      rules={[{ required: true, message: 'Please input your company name!' }]}
                      style={{ marginBottom: '8px' }}>
                      <Input placeholder="Company Name" className="signin-name" />
                    </Form.Item>
                  </div>
                  }
                  {(pageData.pageName !== "verify" && pageData.pageName !== "newPassword" && pageData.pageName !== "passwordConfirm") &&
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your username',
                        },
                        {
                          type: 'email',
                          message: 'Please enter a valid email',
                        },
                      ]}
                      style={{ marginBottom: '8px' }}
                    >
                      <Input
                        placeholder="Email"
                        className="signin-name"
                        defaultValue={params.has('code') ? params.get('email') : ""}
                      // suffix={
                      //   (pageData.pageName === "forgot") &&
                      //   <ImageComponent
                      //     src="/assets/svg/submit-button.svg"
                      //     onClickEvent={handleSubmit}
                      //   />

                      // }
                      />
                    </Form.Item>
                  }

                  {/* Verification code section */}
                  {(pageData.pageName === "verify" || pageData.pageName === "newPassword") &&
                    (<div style={{width:"408px"}}>
                      <Form.Item
                        name="verificationCode"
                        style={{ marginBottom: '8px' }}
                        rules={[{ required: true, message: 'Please input the verification code!' }]}
                      >
                        <div className="otp-container d-flex flex-row justify-content-center">
                          {otp.map((digit, index) => (
                            <Input
                              key={index}
                              ref={(el) => (inputRefs.current[index] = el)}
                              value={digit}
                              onChange={(e) => handleOtpChange(e, index)}
                              onKeyPress={(e) => handleOtpPress(e, index)}
                              onPaste={(e) => handleOtpPaste(e, index)}
                              maxLength={1}
                              className="otp-input"
                            />
                          ))}
                        </div>
                      </Form.Item>
                      <div className="d-flex justify-content-end pointer resend-btn"><span
                          onClick={() => resendVerificationCode(form.getFieldValue('username'))}>Resend</span></div>
                    </div>
                    )
                  }

                  {/* New password section */}
                  {/* {(pageData.pageName === "newPassword") &&
                    <Form.Item
                      name="code"
                      style={{ marginBottom: '8px' }}
                      rules={[{ required: true, message: 'Please input the code sent to your email!' }]}
                    >
                      <Input className="signin-input" placeholder="Verification Code" autoComplete="off" />
                    </Form.Item>
                  } */}

                  {/* Password section */}
                  {(pageData.pageName !== "forgot" && pageData.pageName !== "verify" &&
                    pageData.pageName !== "newPassword" && pageData.pageName !== "passwordConfirm") &&
                    <Form.Item
                      name="password"
                      className="pb-1"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password',
                        },
                        {
                          validator: validatePassword,
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Password"
                        className="signin-name"
                        // iconRender={() => SubmitButton()}
                        autoComplete="off"
                      />
                    </Form.Item>}
                  {(pageData.pageName === "passwordConfirm") && <div>
                    <Form.Item
                      name="new_password"
                      className="pb-4 mt-4"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password',
                        },
                        {
                          validator: validatePassword,
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="New Password"
                        className="signin-name"
                        // iconRender={() => SubmitButton()}
                        autoComplete="off"
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirm_password"
                      className="pb-4"
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('new_password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords do not match'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm Password"
                        className="signin-name"
                        // iconRender={() => SubmitButton()}
                        autoComplete="off"
                      />
                    </Form.Item>
                  </div>
                  }
                  {(pageData.pageName === "signin") &&
                    <p
                      onClick={() => setPageData({ pageName: 'forgot', pageText: 'Enter Account Email' })}
                      className="forgot-pwd pointer text-end">
                      Forgot Password
                    </p>
                  }
                  {pageData.pageName === "signin" && <div className="log-in-btn pointer py-2 mt-3" onClick={handleSubmit}>
                    Log in
                  </div>}
                  {pageData.pageName === "registration" && <div className="log-in-btn pointer py-2 mt-3" onClick={handleSubmit}>
                    Sign Up
                  </div>}
                  {pageData.pageName === "newPassword" && <div className="log-in-btn pointer py-2 mt-3" onClick={handleSubmit}>
                    Verify
                  </div>}
                  {(pageData.pageName === "forgot" || pageData.pageName === "passwordConfirm" || pageData.pageName === "verify") &&
                    <div className="log-in-btn pointer py-2 mt-4" onClick={handleSubmit}>
                      Submit
                    </div>

                  }
                  {(pageData.pageName === "forgot" || pageData.pageName === "newPassword") &&
                    <div className="pointer mt-4 text-center">
                      <span className="dont-account">Remember your password now? {" "}</span>
                      <span className="font-weight-500 font-size-14" onClick={() => setPageData({ pageName: 'signin', pageText: 'Sign In' })}>Sign in</span>
                    </div>
                  }
                </Form>

                {(pageData.pageName !== "forgot") &&
                  <>
                    {/* Page heading */}
                    {/* Google login */}
                    {/* {(pageData.pageName === "registration" || pageData.pageName === "signin")&&
                  <ImageComponent src="/assets/svg/google-signin.svg" style={{cursor:"pointer"}}/>} */}

                    <div className="cursor-pointer text-center last-text" style={{ marginBottom: '5px' }}>
                      {(pageData.pageName === "signin") &&
                        <div className=""
                          onClick={() => setPageData({ pageName: 'registration', pageText: 'Sign Up' })}>
                          <span className="dont-account pt-4">Don’t have an account? {" "}</span>
                          <span className="font-weight-500 font-size-14 last-text-color">Sign up</span>
                        </div>
                      }

                      {pageData.pageName === "registration" &&
                        <div className="pointer"
                          onClick={() => setPageData({ pageName: 'signin', pageText: 'Sign In' })}>
                          <span className="dont-account">Already have an account? {" "}</span>
                          <span className="font-weight-500 font-size-14 last-text-color">Sign in</span>
                        </div>
                      }
                      {pageData.pageName === "verify" &&
                        <div className="pointer"
                          onClick={() => setPageData({ pageName: 'signin', pageText: 'Sign In' })}>
                          <span className="dont-account">Already have an account? {" "}</span>
                          <span className="font-weight-500 font-size-14 last-text-color">Sign in</span>
                        </div>
                      }
                    </div>



                  </>
                }
              </div>
            </div>
          }
          </div>
        </div>
      
    </>
  )
}

export default Authentication;
