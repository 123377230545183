import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Row, Col, Button,
    Card,
    Input,
    notification,
    Select,
    Spin,
    Switch,
    Avatar,
    Space,
    Typography,
    Modal,
} from "antd"
import {
    PlusOutlined,
    MinusOutlined,
    CloseOutlined,
    CaretDownOutlined
} from "@ant-design/icons";
import axiosInstance from "../config/axiosConfig";
import { DropdownArrow } from "../assets/svg/Icons";
import LinkedinCampaignCreationTitle from "../component/LinkedinCampaignCreationTitle"


const DeleteCrossMark = () => {

    const iconStyle = {
        position: 'relative',
        display: 'inline-block',
    };

    const circleStyle = {
        position: 'absolute',
        width: '25px',
        height: '25px',
        backgroundColor: '#b51a1a',
        borderRadius: '50%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '0',
    };

    const closeIconStyle = {
        color: 'white',
        position: 'relative',
        zIndex: '1',
    };

    return (
        <div style={iconStyle}>
            <CloseOutlined style={closeIconStyle} />
            <div style={circleStyle}></div>
        </div>
    )
}

const items = [
    { key: 'first_name', label: 'First Name' },
    { key: 'last_name', label: 'Last Name' },
    { key: 'email', label: 'Email' },
    { key: 'phone_number', label: 'Phone Number' },
    { key: 'company_name', label: 'Company Name' },
    { key: 'website', label: 'Website' },
    { key: 'linkedin_profile', label: 'Linkedin Profile' },
    { key: 'location', label: 'Location' },
    { key: 'role', label: 'Role' },
    // { key: 'sl_day_of_week', label: 'Day of Week' },
    // { key: 'sl_time_of_day', label: 'Time of Day' },
    // { key: 'sl_time_of_day_cap', label: 'Time of Day Cap' }
];

const SequenceSteps = ({ updateStep, isStepUpdate, newCampaignDetails, allLeads, leadIds }) => {
const keyLabelMap = {
    "first_name": "First Name",
    "last_name": "Last Name",
    "email": "Email",
    "phone_number": "Phone Number",
    "company": "Company",
    "website": "Website",
    "linkedin_profile": "Linkedin Profile",
    "location": "Location",
    "role": "Role"
  };

    const [loading, setLoading] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const { Text } = Typography;
    const authData = useSelector((state) => state.auth);
    const companyData = useSelector(
        (state) => state.companyReducer.selectedCompany
    );
    const companyId = companyData.company_id;
    const userId = authData.userInfo.is_super_admin
        ? companyData.user_id
        : authData.userInfo.userid;
    const [campaignDetails, setCampaignDetails] = useState([{ sequence_number: 1, value: [], accountsIsMultipleMode: false }])
    const { TextArea } = Input;
    const [profileAccounts, setProfileAccounts] = useState([]);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
    const [isCampaignEdit, setIsCampaignEdit] = useState(false)
    const [selectedCampaignId, setSelectedCampaignId] = useState('')
    const [selectedCampaignName, setSelectedCampaignName] = useState('')
    const [showOptions, setShowOptions] = useState(false);
    const [showOptionsIdx, setShowOptionsIdx] = useState()
    const [campaignList, setCampaignList] = useState([])
    const [linkedInVariables, setLinkedInVariables] = useState([])
    const [leadName, setLeadName] = useState("")
    const textAreaRefs = useRef([]);


    useEffect(() => {
        const route = location.pathname.split('/')[3];
        let isEdit = false
        if (route == 'campaign-edit') {
            isEdit = true
        }
        setIsCampaignEdit(isEdit)
        setLoading(1)
        if (isEdit) {
            fetchSequence();
        }
        fetchAccounts();
        fetchCampaigns()
        if(!isEdit) {
            fetchLinkedInVariables()
            fetchPersonas(newCampaignDetails.SelectedLead)
        }
        setLoading(0)
    }, []);

    useEffect(() => {
        if (isStepUpdate) {
            validateValues()
        }

    }, [isStepUpdate]);

    const fetchPersonas = async (leadId) => {
        setLoading(1)
        try {
          const response = await axiosInstance.get(
            `/v2_lists/fetch_lists_only/${userId}?company_id=${companyId}`
          );
          const options = [];
    
          response.data.forEach((persona) => {
            const option = {
              label: persona.persona_name,
              value: persona.persona_id,
            };
    
            options.push(option);
          });
          let selectedLeadName = options.filter(i => i.value === leadId)[0]?.label
          setLeadName(selectedLeadName)
        } catch (error) {
          notification.error({
            message: "Error",
            description: "Error in fetching the Leads(s)",
          });
        }
        setLoading(0)
      };
    
    const postNewCampaign = async () => {
        setLoading(1)
        try {

            const data = {
                company_id: companyId,
                campaign_name: newCampaignDetails.Name,
                list_id: newCampaignDetails.SelectedLead,
                remove_duplicate: newCampaignDetails.remove_duplicate,
                non_duplicate_leads: newCampaignDetails.non_duplicate_leads,
                all_leads: allLeads,
                lead_ids: leadIds,
                sequences: campaignDetails.map((item, index) => {
                    if (item.sequence_number == 2) {
                        return {
                            ...item,
                            order_number: index + 1,
                            value: `${item.value.days} days ${item.value.hours} hours ${item.value.minutes} minutes`
                        };
                    } else {
                        return { ...item, order_number: index + 1 };
                    }
                })
            }
            const response = await axiosInstance.post(
                `/linkedin/create_campaign/${userId}`, data
            );
            if (response.status === 200) {
                updateStep(true)
                /*
                notification.success({
                    message: "Success",
                    description: "Campaign created successfully",
                });
                */
            }
        } catch (error) {
            updateStep(false)
            notification.error({
                message: "Error",
                description: "Error in creating Campaign",
            });
        }

        setLoading(0);
    };

    const validateValues = () => {

        if (campaignDetails[0].value.length == 0) {
            notification.error({
                message: "Error",
                description: "Please select the Profile",
            });
            { !isCampaignEdit && updateStep(false) }
            return
        }

        for (const item of campaignDetails) {
            if (item.sequence_number !== 3) {
                if (item.value?.days === '' || item.value?.hours === '' || item.value?.minutes === '') {
                    if (item.sequence_number == 2) {
                        notification.error({
                            message: "Error",
                            description: "Please select the Waiting Period",
                        });
                    } else {
                        notification.error({
                            message: "Error",
                            description: "Please select the Follow-Up message",
                        });
                    }
                    { !isCampaignEdit && updateStep(false) }
                    return
                } else {
                    if (item.sequence_number == 2) {
                        if (item.value?.days < 0) {
                            notification.error({
                                message: "Error",
                                description: "Please select the Waiting Period Days 0 or greater than",
                            });

                            return
                        }
                        if (item.value?.hours < 0) {
                            notification.error({
                                message: "Error",
                                description: "Please select the Waiting Period Hours 0 or greater than",
                            });

                            return
                        }
                        if (item.value?.minutes < 0) {
                            notification.error({
                                message: "Error",
                                description: "Please select the Waiting Period Mins 0 or greater than",
                            });

                            return
                        }

                    }
                }
            }
        }

        { isCampaignEdit ? postEditCampaign() : postNewCampaign() }
    };

    const isSectionSelected = (val) => {
        return campaignDetails.some(item => item.sequence_number === val);
    }
    const getIsLastSequenceNumber = (sequence_number) => {
        const lastItem = campaignDetails[campaignDetails.length - 1];

        return lastItem && lastItem.sequence_number === sequence_number;
    }

    const handleLinkedinCampaignSteps = (step) => {
        if (step == 1) {
            setCampaignDetails([...campaignDetails, { sequence_number: step, value: campaignDetails[0].value, accountsIsMultipleMode: false }])
        } else if (step == 2) {
            setCampaignDetails([...campaignDetails, { sequence_number: step, value: { days: 0, hours: 0, minutes: 0 } }])
        }
        else if (step == 3) {
            setCampaignDetails([...campaignDetails, { sequence_number: step, value: "" }])
        } else if (step == 4) {
            const lastItem = campaignDetails[campaignDetails.length - 1]
            if (lastItem && lastItem.sequence_number == 2) {
                campaignDetails.pop()
            }

            setCampaignDetails([...campaignDetails, { sequence_number: 2, value: { days: 0, hours: 0, minutes: 0 } }, { sequence_number: step, value: "" }])
        }
    }

    const handleDecreaseWaitingDays = (idx, value) => {
        if ((parseInt(value, 10) - 1) < 0) {
        } else {
            const updatedData = [...campaignDetails];
            updatedData[idx] = { ...updatedData[idx], value: ({ ...updatedData[idx].value, days: parseInt(value, 10) - 1 }) };
            setCampaignDetails(updatedData);
        }
    }

    const handleIncreaseWaitingDays = (idx, value) => {
        const updatedData = [...campaignDetails];
        updatedData[idx] = { ...updatedData[idx], value: ({ ...updatedData[idx].value, days: parseInt(value, 10) + 1 }) };
        setCampaignDetails(updatedData);
    }

    const handleDecreaseWaitingHours = (idx, value) => {
        if ((parseInt(value, 10) - 1) < 0) {
        } else {
            const updatedData = [...campaignDetails];
            updatedData[idx] = { ...updatedData[idx], value: ({ ...updatedData[idx].value, hours: parseInt(value, 10) - 1 }) };
            console.log(updatedData)
            setCampaignDetails(updatedData);
        }
    }

    const handleIncreaseWaitingHours = (idx, value) => {
        const updatedData = [...campaignDetails];
        updatedData[idx] = { ...updatedData[idx], value: ({ ...updatedData[idx].value, hours: parseInt(value, 10) + 1 }) };
        setCampaignDetails(updatedData);
    }

    const handleDecreaseWaitingMinutes = (idx, value) => {
        if ((parseInt(value, 10) - 1) < 0) {
        } else {
            const updatedData = [...campaignDetails];
            updatedData[idx] = { ...updatedData[idx], value: ({ ...updatedData[idx].value, minutes: parseInt(value, 10) - 1 }) };
            console.log(updatedData)
            setCampaignDetails(updatedData);
        }
    }
    

    const handleIncreaseWaitingMinutes = (idx, value) => {
        const updatedData = [...campaignDetails];
        updatedData[idx] = { ...updatedData[idx], value: ({ ...updatedData[idx].value, minutes: parseInt(value, 10) + 1 }) };
        setCampaignDetails(updatedData);
    }

    const handleRemoveLinkedinCampaignSteps = async (step) => {
        const updatedArray = [...campaignDetails];
        const lastItem = updatedArray[updatedArray.length - 1]
        let deleteWaitingPeriodID = ''
        if (lastItem && lastItem.sequence_number == 4) {
            updatedArray.pop()
            deleteWaitingPeriodID = updatedArray[updatedArray.length - 1]
            updatedArray.pop()
        } else {
            updatedArray.pop()
        }
        setCampaignDetails(updatedArray);
        setDeleteConfirmModal(false)

        if (isCampaignEdit) {
            if (lastItem.id) {
                if (deleteWaitingPeriodID) {
                    await deleteSequence(lastItem.id)
                    await deleteSequence(deleteWaitingPeriodID.id)
                } else {
                    deleteSequence(lastItem.id)
                }

            }
        }
    }

    const handleEditorChange = (index, html) => {
        const updatedData = campaignDetails.map((item, i) => {
            if (index === i) {
                return { ...item, value: html };
            }
            return item;
        });
        setCampaignDetails(updatedData);
    };

    const fetchAccounts = async () => {
        try {
            const response = await axiosInstance.get(
                `/linkedin/fetch_accounts/${userId}?company_id=${companyId}`
            );
            if (response.status === 200) {

                const options = [];
                response.data.forEach((account) => {
                    if (!account.reconnect_status && account.active_status) {
                        const option = {
                            label: account.account_name,
                            value: account.account_id,
                        };

                        options.push(option);
                    }
                });
                setProfileAccounts(options);
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the Accounts",
            });
        }
    };

    const fetchCampaigns = async () => {
        try {
            const response = await axiosInstance.get(
                `/linkedin/all_linkedin_campaign_list/${userId}?company_id=${companyId}`
            );
            if (response.status === 200) {

                const options = [];
                response.data.forEach((campaign) => {
                    
                        const option = {
                            label: campaign.campaign_name,
                            value: campaign.campaign_id,
                        };

                        options.push(option);
                    
                });
                setCampaignList(options);
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the Accounts",
            });
        }
    };

    const fetchLinkedInVariables = async (listId, isEdit) => {
        let personaId
        if(isEdit) {
            personaId = listId
        }
        else {
            personaId = newCampaignDetails.SelectedLead
        }
        try {
            const response = await axiosInstance.get(
                `/linkedin/verify_variables/${userId}?persona_id=${personaId}&company_id=${companyId}`
            );
            if (response.status === 200) {

                const options = [];
                const items = response.data
                const itemsArray = Object.keys(items).map(key => ({
                    key: key,
                    label: keyLabelMap[key] || "",
                    enabled: items[key]
                  }));
                setLinkedInVariables(itemsArray);
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the LinkedIn variables",
            });
        }
    };


    const importCampaign = async (value) => {
        Modal.confirm({
            title: (<div style={{ color: "#ffffff" }}>Import Sequence from Campaign</div>),
            content: (<div style={{ color: "#ffffff" }}>This will override any existing text. Are you sure you want to import this sequence?</div>),
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {     
              try {
                const payload = {
                    campaign_id: value,
                    campaign_name: campaignList.filter(i => i.value === value)[0]?.label,
                    company_id: companyId
                }
            const response = await axiosInstance.post(
                `/linkedin/import_campaign/${userId}`, payload
            );
            if (response.status === 200) {
                const sequences = response.data.sequences.map((item, index) => {

                    if (item.sequence_number == 1) {
                        console.log("At 433")
                        if (item.value?.length > 1) {
                            console.log("At 435")
                            return {
                                ...item,
                                accountsIsMultipleMode: false
                            };
                        } else {
                            console.log("At 441")
                            return {
                                ...item,
                                accountsIsMultipleMode: true
                            };
                        }
                    } else if (item.sequence_number == 2) {
                        console.log("At 448")
                        const timeParts = item.value?.split(' ');

                        let days = 0;
                        let hours = 0;
                        let minutes = 0;
                        
                        for (let i = 0; i < timeParts.length; i += 2) {
                            const value = parseInt(timeParts[i], 10);
                            if (!isNaN(value)) {
                                switch (timeParts[i + 1]) {
                                    case 'days':
                                        days = value;
                                        break;
                                    case 'hours':
                                        hours = value;
                                        break;
                                    case 'minutes':
                                        minutes = value;
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }

                        return {
                            ...item,
                            value: { days, hours, minutes }
                        };
                    } else {
                        console.log("At 479")
                        return {
                            ...item,

                        };
                    }

                })
                console.log("sequences are", sequences)
                setCampaignDetails(sequences)

            }

        } 
        catch (error) {
            notification.error({
                message: "Error",
                description: "Error in importing the campaign",
            });
        }
    }})
    };

    const fetchSequence = async () => {
        const searchParams = new URLSearchParams(location.search);
        const paramsFromURL = searchParams.get('campaign_id');
        setSelectedCampaignId(paramsFromURL)
        try {
            const response = await axiosInstance.get(
                `/linkedin/linkedin_campaign_fetch_sequence/${userId}?campaign_id=${paramsFromURL}&company_id=${companyId}`
            );
            if (response.status === 200) {
                setSelectedCampaignName(response.data.campaign_name)
                const sequences = response.data.sequences.map((item, index) => {

                    if (item.sequence_number == 1) {
                        if (item.value.length > 1) {
                            return {
                                ...item,
                                accountsIsMultipleMode: false
                            };
                        } else {
                            return {
                                ...item,
                                accountsIsMultipleMode: true
                            };
                        }
                    } else if (item.sequence_number == 2) {
                        const timeParts = item.value.split(' ');

                        let days = 0;
                        let hours = 0;
                        let minutes = 0;
                        
                        for (let i = 0; i < timeParts.length; i += 2) {
                            const value = parseInt(timeParts[i], 10);
                            if (!isNaN(value)) {
                                switch (timeParts[i + 1]) {
                                    case 'days':
                                        days = value;
                                        break;
                                    case 'hours':
                                        hours = value;
                                        break;
                                    case 'minutes':
                                        minutes = value;
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }

                        return {
                            ...item,
                            value: { days, hours, minutes }
                        };
                    } else {
                        return {
                            ...item,

                        };
                    }

                })

                setCampaignDetails(sequences)
                fetchLinkedInVariables(response.data.list_id, true)
                fetchPersonas(response.data.list_id)
            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in fetching the Sequence",
            });
        }
    };

    const deleteSequence = async (id) => {
        try {
            const response = await axiosInstance.put(
                `/linkedin/linkedin_campaign_delete_sequence/${selectedCampaignId}?user_id=${userId}&company_id=${companyId}&sequence_id=${id}`
            );
            if (response.status === 200) {

            }

        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in deleting the Sequence",
            });
        }
    };

    const postEditCampaign = async () => {
        setLoading(1)
        try {

            const data = {
                company_id: companyId,
                campaign_name: selectedCampaignName,
                list_id: 0,
                sequences: campaignDetails.map((item, index) => {
                    if (item.sequence_number == 2) {


                        if (item.id) {
                            return {
                                ...item,
                                order_number: index + 1,
                                value: `${item.value.days} days ${item.value.hours} hours ${item.value.minutes} minutes`,
                            };
                        } else {
                            return {
                                ...item,
                                order_number: index + 1,
                                value: `${item.value.days} days ${item.value.hours} hours ${item.value.minutes} minutes`,
                                id: null
                            };
                        }

                    } else {
                        if (item.id) {
                            return { ...item, order_number: index + 1 };
                        } else {
                            return {
                                ...item, order_number: index + 1,
                                id: null
                            };
                        }

                    }
                })
            }
            const response = await axiosInstance.put(
                `/linkedin/linkedin_campaign_update_sequence/${selectedCampaignId}?user_id=${userId}&company_id=${companyId}`, data
            );
            if (response.status === 200) {
                navigate(`/tools/linkedin/campaign-list`)
                /*
                notification.success({
                    message: "Success",
                    description: "Campaign updated successfully",
                });
                */
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: "Error in updating Campaign",
            });
        }

        setLoading(0);
    };

    const handleToggleOptions = (idx) => {
        setShowOptions(!showOptions);
        setShowOptionsIdx(idx)
    };

    const handleOptionSelect = (option, idx) => {

        const updatedData = [...campaignDetails];

        const textArea = updatedData[idx];

        let textBeforeCursor = textArea?.value?.substring(0, updatedData[idx].cursorPosition);
        let textAfterCursor = textArea?.value?.substring(updatedData[idx].cursorPosition);
        textBeforeCursor = textBeforeCursor === undefined ? "" : textBeforeCursor
        textAfterCursor = textAfterCursor === undefined ? "" : textAfterCursor
        console.log(updatedData[idx].cursorPosition)
        const updatedText = `${textBeforeCursor}{{${option}}}${textAfterCursor}`;

        setShowOptions(false)
        const newCursorPosition = updatedData[idx].cursorPosition + option.length + 4;
        const textAreaRef = textAreaRefs.current[idx];
        textAreaRef.focus();
        setTimeout(() => {
            textAreaRef.resizableTextArea.textArea.selectionStart = newCursorPosition;
            textAreaRef.resizableTextArea.textArea.selectionEnd = newCursorPosition;
        }, 0);
        updatedData[idx] = { ...textArea, value: updatedText, cursorPosition: newCursorPosition };
        setCampaignDetails(updatedData);

    };

    const handleKeyDown = (e, idx) => {

        let newPosition = e.target.selectionStart;
        if (e.key === 'ArrowLeft') {
            newPosition = newPosition > 0 ? newPosition - 1 : newPosition;
        } else if (e.key === 'ArrowRight') {
            newPosition = newPosition < e.target.value.length ? newPosition + 1 : newPosition;
        }

        const updatedData = [...campaignDetails];
        updatedData[idx] = { ...updatedData[idx], cursorPosition: newPosition };
        setCampaignDetails(updatedData);

    };

    return (

        <>
            <Row className="campaign-creation-step2">
                <Col span={2}>
                    <LinkedinCampaignCreationTitle isCampaignEdit={isCampaignEdit} />
                </Col>
                <Col
                    span={14}
                    style={{ textAlign: "center", marginTop: '10%' }}

                >
                    <div className="white-text mb-4 font-size-16 font-weight-600" style={{ width: "80%"}}>{leadName}</div>
                    <div
                    // style={{
                    //     maxHeight: "72vh",
                    //     minHeight: "72vh",
                    //     overflowY: "auto",
                    //     marginRight: "10px",

                    // }}
                    >
                        <div className="vertical-container d-flex align-items-center justify-content-center">
                            <Card className="card-sequence"><span style={{ fontSize: '17.06px', color: 'rgba(177, 174, 174, 1)' }}>Start Sequence</span></Card>
                            <div className="line"></div>
                            {campaignDetails.map((processStep, idx) => (
                                <>
                                    {processStep.sequence_number == 1 && (
                                        <>
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{
                                                    width: "100%",
                                                    position: "relative",
                                                }}
                                            >
                                                <Avatar
                                                    style={{
                                                        position: "absolute",
                                                        left: "21%",
                                                    }}
                                                    shape="circle"
                                                    className="circle-one"
                                                    size={20}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "10px",
                                                            color: "rgba(30, 117, 255, 1)",
                                                        }}
                                                    >
                                                        {idx + 1}
                                                    </span>
                                                </Avatar>
                                                <Card className="card-sequence">
                                                    <img
                                                        className="campaign-creation-linkedinIcon"
                                                        src="/assets/svg/linkedin-bg-grey.svg"
                                                        alt="linkedin"
                                                    />
                                                    <span style={{ fontSize: '17.06px', color: 'rgba(177, 174, 174, 1)' }} className="ps-2">View Profile</span>
                                                </Card>
                                            </div>
                                            <div className="line"></div>
                                        </>
                                    )}

                                    {processStep.sequence_number == 2 && (
                                        <>
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{
                                                    width: "100%",
                                                    position: "relative",
                                                }}
                                            >
                                                <Avatar
                                                    style={{
                                                        position: "absolute",
                                                        left: "21%",
                                                    }}
                                                    shape="circle"
                                                    className="circle-one"
                                                    size={20}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "10px",
                                                            color: "rgba(30, 117, 255, 1)",
                                                        }}
                                                    >
                                                        {idx + 1}
                                                    </span>
                                                </Avatar>
                                                <Card className="card-sequence">
                                                    <img
                                                        className="campaign-creation-followupIcon"
                                                        src="/assets/svg/alarm.svg"
                                                        alt="linkedin"
                                                    />
                                                    <span style={{ fontSize: '17.06px', color: 'rgba(177, 174, 174, 1)' }} className="ps-2">
                                                        Waiting Period
                                                    </span>
                                                </Card>
                                            </div>
                                            <div className="line"></div>
                                        </>
                                    )}
                                    {processStep.sequence_number == 3 && (
                                        <>
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{
                                                    width: "100%",
                                                    position: "relative",
                                                }}
                                            >
                                                <Avatar
                                                    style={{
                                                        position: "absolute",
                                                        left: "21%",
                                                    }}
                                                    shape="circle"
                                                    className="circle-one"
                                                    size={20}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "10px",
                                                            color: "rgba(30, 117, 255, 1)",
                                                        }}
                                                    >
                                                        {idx + 1}
                                                    </span>
                                                </Avatar>
                                                <Card className="card-sequence">
                                                    <img
                                                        className="campaign-creation-connection-request-Icon"
                                                        src="/assets/svg/connection-request.svg"
                                                        alt="linkedin"
                                                    />
                                                    <span style={{ fontSize: '17.06px', color: 'rgba(177, 174, 174, 1)' }} className="ps-2">
                                                        Send Connection Request
                                                    </span>
                                                </Card>
                                            </div>
                                            <div className="line"></div>
                                        </>
                                    )}
                                    {processStep.sequence_number == 4 && (
                                        <>
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{
                                                    width: "100%",
                                                    position: "relative",
                                                }}
                                            >
                                                <Avatar
                                                    style={{
                                                        position: "absolute",
                                                        left: "21%",
                                                    }}
                                                    shape="circle"
                                                    className="circle-one"
                                                    size={20}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "10px",
                                                            color: "rgba(30, 117, 255, 1)",
                                                        }}
                                                    >
                                                        {idx + 1}
                                                    </span>
                                                </Avatar>
                                                <Card className="card-sequence">
                                                    <img
                                                        className="campaign-creation-followupIcon"
                                                        src="/assets/svg/chatbox.svg"
                                                        alt="follow-up"
                                                    />
                                                    <span style={{ fontSize: '17.06px', color: 'rgba(177, 174, 174, 1)' }} className="ps-2">
                                                        Send Follow-Up Message
                                                    </span>
                                                </Card>
                                            </div>
                                            <div className="line"></div>
                                        </>
                                    )}
                                </>
                            ))}

                            <div className="square">
                                <img
                                    src="/assets/svg/vector-plus.svg"
                                    alt="Plus Icon"
                                    className="plus-icon"
                                />
                            </div>
                            <Card className="card-sequence-steps mb-3">
                                <Space direction="vertical">
                                    <Space onClick={!getIsLastSequenceNumber(2) ? () => handleLinkedinCampaignSteps(2) : undefined}
                                        style={!getIsLastSequenceNumber(2) ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
                                    >
                                        <img
                                            src="/assets/svg/alarm.svg"
                                            width="18"
                                            height=""
                                            alt="alarm"
                                        />
                                        <Typography.Text>
                                            Waiting Period
                                        </Typography.Text>
                                    </Space>
                                    <Space onClick={!getIsLastSequenceNumber(1) ? () => handleLinkedinCampaignSteps(1) : undefined}
                                        style={!getIsLastSequenceNumber(1) ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
                                    >
                                        <img
                                            src="/assets/svg/happy.svg"
                                            width="18"
                                            height=""
                                            alt="happy"
                                        />
                                        <Typography.Text>View Profile</Typography.Text>
                                    </Space>
                                    <Space onClick={!isSectionSelected(3) ? () => handleLinkedinCampaignSteps(3) : undefined}
                                        style={!isSectionSelected(3) ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
                                    >
                                        <img
                                            src="/assets/svg/person.svg"
                                            width="18"
                                            height=""
                                            alt="person"
                                        />
                                        <Typography.Text>
                                            Send Connection Request
                                        </Typography.Text>
                                    </Space>
                                    {/* <Space onClick={isSectionSelected(3) ? () => handleLinkedinCampaignSteps(4) : undefined} */}
                                    <Space onClick={() =>handleLinkedinCampaignSteps(4)}
                                        // style={isSectionSelected(3) ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <img
                                            src="/assets/svg/chatbox.svg"
                                            width="18"
                                            height=""
                                            alt="chatbox"
                                        />
                                        <Typography.Text>
                                            Send Follow-Up Message
                                        </Typography.Text>
                                    </Space>
                                </Space>
                            </Card>

                            {
                                isCampaignEdit && <Button
                                    style={{
                                        background: "#591EFF",
                                        padding: "17px 30px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        marginTop: '50px',
                                        marginBottom: '10px'
                                    }}
                                    type="primary"
                                    onClick={() => validateValues()}
                                >
                                    Save
                                </Button>
                            }
                        </div></div>
                </Col>
                <Col
                    span={8}
                    style={{
                        color: "white",
                        backgroundColor: "#1c1c24",
                        padding: "20px 0",
                    }}
                >
                    {!isCampaignEdit && <div
                        className="d-flex flex-column mb-3"
                        style={{
                            paddingLeft: "20px",
                            padding: "20px",
                            borderBottom: "2px solid rgb(56,56,63)",
                        }}
                    >
                        <p style={{ fontSize: "16px" }}>
                            Import Sequence:
                            
                        </p>
                        <Select
                            placeholder="Import Sequence from Campaign"
                            options={campaignList}
                            suffixIcon={<DropdownArrow />}
                            className={`w-100 linkedin-viewprofile-custom-select`}
                            onChange={(value) => {
                                console.log("value", value)
                                importCampaign(value)
                            }}
                        />
                    </div>}
                    <div
                        style={{
                            // maxHeight: "82vh",
                            minHeight: "88vh",
                            overflowY: "auto",
                            marginRight: "10px",
                        }}
                    >
                        {campaignDetails.map((processStep, idx) => (
                            <>
                                {processStep.sequence_number == 1 && (
                                    <>
                                        <div
                                            className="d-flex align-items-center"
                                            style={{
                                                paddingLeft: "20px",
                                                borderBottom: "2px solid rgb(56,56,63)",
                                                paddingBottom: "20px",
                                                padding: "20px",
                                                ...(idx != 0 ? {
                                                    paddingTop: "2px solid rgb(56,56,63)"
                                                } : { paddingTop: '2px' }),
                                            }}
                                        >
                                            <Avatar
                                                shape="circle"
                                                style={{ marginRight: "20px" }}
                                                size={20}
                                                className="circle-one"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10px",
                                                        color: "rgba(30, 117, 255, 1)",
                                                    }}
                                                >
                                                    {idx + 1}
                                                </span>
                                            </Avatar>
                                            <img
                                                className="campaign-creation-linkedinIcon"
                                                src="/assets/svg/linkedin-bg-grey.svg"
                                                alt="linkedin"
                                            />
                                            <span style={{ fontSize: '17.06px', color: 'rgba(177, 174, 174, 1)' }} className="ps-2">View Profile</span>
                                            {idx != 0 && campaignDetails.length === idx + 1 &&
                                                <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setDeleteConfirmModal(true)}>
                                                    <DeleteCrossMark />
                                                </div>
                                            }
                                        </div>
                                        <div
                                            className="d-flex flex-column"
                                            style={{
                                                paddingLeft: "20px",
                                                borderBottom: "2px solid rgb(56,56,63)",
                                                padding: "20px",
                                            }}
                                        >
                                            <p style={{ fontSize: "16px" }}>
                                                Choose a profile to perform this action:
                                                <span style={{ color: "red" }}> *</span>
                                            </p>
                                            <Select
                                                disabled={(isSectionSelected(1) && idx != 0) || isCampaignEdit ? true : false}
                                                placeholder="Select Profile"
                                                mode={processStep.accountsIsMultipleMode ? 'single' : 'multiple'}
                                                options={profileAccounts}
                                                suffixIcon={<DropdownArrow />}
                                                className={`w-100 linkedin-viewprofile-custom-select ${processStep.accountsIsMultipleMode ? '' : 'linkedin-viewprofile-custom-select-multiple'}`}
                                                onChange={(value) => {
                                                    const updatedValue = typeof (value) == 'object' ? [...value] : [value]
                                                    const updatedData = [...campaignDetails];
                                                    updatedData[idx] = { ...updatedData[idx], value: updatedValue };
                                                    setCampaignDetails(updatedData);
                                                }}
                                                value={campaignDetails[0].value}
                                            />
                                            <div className="d-flex align-items-center pt-3">
                                                <Switch
                                                    checked={processStep.accountsIsMultipleMode}
                                                    onChange={(toggleState) => {
                                                        const updatedData = [...campaignDetails];
                                                        updatedData[idx] = { ...updatedData[idx], accountsIsMultipleMode: toggleState }; // Update the object at the specified index
                                                        setCampaignDetails(updatedData);
                                                    }}
                                                    disabled={campaignDetails[0]?.value?.length > 1 ? true : isSectionSelected(1) && idx != 0 ? true : false}
                                                    // style={{ width: '33px',height:'18px' }}
                                                    size="small"
                                                />

                                                <span className="p-2">
                                                    Choose a single profile for this step
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {processStep.sequence_number == 2 && (
                                    <>
                                        {/* {campaignDetails[idx + 1] && campaignDetails[idx + 1].sequence_number == 4 && <div style={{
                                            marginLeft: "20px",
                                        }}>
                                            <Text type="secondary">After connection request is accepted, how long do you want to wait before sending a follow-up message? Follow-up message will only send if you have not received a message.</Text></div>} */}
                                        <div
                                            className="d-flex align-items-center"
                                            style={{
                                                paddingLeft: "20px",
                                                padding: "20px",
                                                paddingTop: "20px solid rgb(56,56,63)",
                                            }}
                                        >
                                            <Avatar
                                                shape="circle"
                                                style={{ marginRight: "20px" }}
                                                size={20}
                                                className="circle-one"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10px",
                                                        color: "rgba(30, 117, 255, 1)",
                                                    }}
                                                >
                                                    {idx + 1}
                                                </span>
                                            </Avatar>
                                            <img
                                                className="campaign-creation-followupIcon"
                                                src="/assets/svg/alarm.svg"
                                                alt="alarm"
                                            />
                                            <span style={{ fontSize: '17.06px', color: 'rgba(177, 174, 174, 1)' }} className="ps-2">Waiting Period</span>
                                            {campaignDetails.length === idx + 1 &&
                                                <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setDeleteConfirmModal(true)}>
                                                    {<DeleteCrossMark />}
                                                </div>
                                            }
                                        </div>
                                        <div
                                            className="d-flex flex-column"
                                            style={{
                                                paddingLeft: "20px",
                                                borderBottom: "2px solid rgb(56,56,63)",
                                                borderTop: "2px solid rgb(56,56,63)",
                                                padding: "20px",

                                            }}
                                        >
                                            <span className="mb-2" style={{ marginRight: '10px', textAlign: 'center' }}>Wait</span>
                                            <Space size={0} >
                                                <Space direction="vertical" className="d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="camapign-creation-waitdays" style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                                            <Button
                                                                className="camapign-creation-waitdays-btn"
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<MinusOutlined style={{ color: "rgba(30, 117, 255, 1)" }} />}
                                                                onClick={() => handleDecreaseWaitingDays(idx, processStep.value?.days)}
                                                            />

                                                            <Input
                                                                style={{ border: 'none', background: 'inherit', color: 'white' }}
                                                                value={processStep.value?.days}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    const updatedData = [...campaignDetails];
                                                                    updatedData[idx] = { ...updatedData[idx], value: ({ ...updatedData[idx].value, days: value }) };
                                                                    setCampaignDetails(updatedData);
                                                                }}
                                                            />

                                                            <Button
                                                                className="camapign-creation-waitdays-btn"
                                                                shape="circle"
                                                                icon={<PlusOutlined style={{ color: "rgba(30, 117, 255, 1)" }} />}
                                                                onClick={() => handleIncreaseWaitingDays(idx, processStep.value?.days)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <span>{processStep.value == 1 ? 'Day' : 'Days'}</span>
                                                </Space>
                                                <Space direction="vertical" className="d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="camapign-creation-waitdays" style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                                            <Button
                                                                className="camapign-creation-waitdays-btn"
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<MinusOutlined style={{ color: "rgba(30, 117, 255, 1)" }} />}
                                                                onClick={() => handleDecreaseWaitingHours(idx, processStep.value?.hours)}
                                                            />

                                                            <Input
                                                                style={{ border: 'none', background: 'inherit', color: 'white' }}
                                                                value={processStep.value?.hours}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    const updatedData = [...campaignDetails];
                                                                    updatedData[idx] = { ...updatedData[idx], value: ({ ...updatedData[idx].value, hours: value }) };
                                                                    setCampaignDetails(updatedData);
                                                                }}
                                                            />

                                                            <Button
                                                                className="camapign-creation-waitdays-btn"
                                                                shape="circle"
                                                                icon={<PlusOutlined style={{ color: "rgba(30, 117, 255, 1)" }} />}
                                                                onClick={() => handleIncreaseWaitingHours(idx, processStep.value?.hours)}
                                                            />
                                                        </div>




                                                    </div>
                                                    <span>{processStep.value == 1 ? 'Hour' : 'Hours'}</span>
                                                </Space>
                                                <Space direction="vertical" className="d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="camapign-creation-waitdays" style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                                            <Button
                                                                className="camapign-creation-waitdays-btn"
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<MinusOutlined style={{ color: "rgba(30, 117, 255, 1)" }} />}
                                                                onClick={() => handleDecreaseWaitingMinutes(idx, processStep.value?.minutes)}
                                                            />

                                                            <Input
                                                                style={{ border: 'none', background: 'inherit', color: 'white' }}
                                                                value={processStep.value?.minutes}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    const updatedData = [...campaignDetails];
                                                                    updatedData[idx] = { ...updatedData[idx], value: ({ ...updatedData[idx].value, minutes: value }) };
                                                                    setCampaignDetails(updatedData);
                                                                }}
                                                            />

                                                            <Button
                                                                className="camapign-creation-waitdays-btn"
                                                                shape="circle"
                                                                icon={<PlusOutlined style={{ color: "rgba(30, 117, 255, 1)" }} />}
                                                                onClick={() => handleIncreaseWaitingMinutes(idx, processStep.value?.minutes)}
                                                            />
                                                        </div>




                                                    </div>
                                                    <span>{processStep.value == 1 ? 'Min' : 'Mins'}</span>
                                                </Space>
                                            </Space>



                                        </div>
                                    </>
                                )}
                                {processStep.sequence_number == 3 && (
                                    <>
                                        <div
                                            className="d-flex align-items-center"
                                            style={{
                                                paddingLeft: "20px",
                                                padding: "20px",
                                                paddingTop: "20px solid rgb(56,56,63)",
                                            }}
                                        >
                                            <Avatar
                                                shape="circle"
                                                style={{ marginRight: "20px" }}
                                                size={20}
                                                className="circle-one"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10px",
                                                        color: "rgba(30, 117, 255, 1)",
                                                    }}
                                                >
                                                    {idx + 1}
                                                </span>
                                            </Avatar>
                                            <img
                                                className="campaign-creation-connection-request-Icon"
                                                src="/assets/svg/connection-request.svg"
                                                alt="alarm"
                                            />
                                            <span style={{ fontSize: '17.06px', color: 'rgba(177, 174, 174, 1)' }} className="ps-2">Send Connection Request</span>
                                            {campaignDetails.length === idx + 1 &&
                                                <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setDeleteConfirmModal(true)}>
                                                    <DeleteCrossMark />
                                                </div>
                                            }
                                        </div>
                                        <div
                                            className="d-flex flex-column"
                                            style={{
                                                paddingLeft: "20px",
                                                borderBottom: "2px solid rgb(56,56,63)",
                                                borderTop: "2px solid rgb(56,56,63)",
                                                padding: "20px",
                                            }}

                                        >

                                            <p style={{ fontSize: "16px" }}>
                                                Enter the message you want to send with your
                                                connection request:
                                                <span style={{ color: "red" }}> *</span>
                                            </p>
                                            <TextArea
                                                id="myTextarea"
                                                className="campaign-creation-text-area"
                                                placeholder="Enter the message"
                                                rows={10}
                                                style={{ margin: "0 10px" }}
                                                onChange={(e) => {
                                                    if(e.target.value.length <= 300) {
                                                    const updatedData = [...campaignDetails];
                                                    updatedData[idx] = { ...updatedData[idx], value: e.target.value, cursorPosition: e.target.selectionStart };
                                                    setCampaignDetails(updatedData);
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    const updatedData = [...campaignDetails];
                                                    updatedData[idx] = { ...updatedData[idx], cursorPosition: e.target.selectionStart };
                                                    setCampaignDetails(updatedData);
                                                }}
                                                onKeyDown={(e) => handleKeyDown(e, idx)}
                                                value={processStep.value}
                                                ref={(textarea) => {
                                                    textAreaRefs.current[idx] = textarea;
                                                }}
                                                maxLength={300}
                                            />
                                            <div className="d-flex justify-content-between">
                                            <div className="linkedin-sequence-custom-dropdown mt-2">
                                                <button className="dropdown-button" onClick={() => handleToggleOptions(idx)}>
                                                    Insert Variable <CaretDownOutlined className="small-arrow-icon" />
                                                </button>
                                                <div className={`dropdown-options w-100 ${showOptions && (idx == showOptionsIdx) ? 'show' : ''}`}>
                                                    {linkedInVariables.map((item, index) => (
                                                        <div className={"option" +(item.enabled ? "" : " disable-variable")} key={index} onClick={() => handleOptionSelect(item.key, idx)}>
                                                            {item.label}
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                            <div className="mt-2">{processStep?.value?.length}/300</div></div>

                                        </div>
                                    </>
                                )}

                                {processStep.sequence_number == 4 && (
                                    <>
                                        <div
                                            className="d-flex align-items-center"
                                            style={{
                                                paddingLeft: "20px",
                                                padding: "20px",
                                                paddingTop: "20px solid rgb(56,56,63)",
                                            }}
                                        >
                                            <Avatar
                                                shape="circle"
                                                style={{ marginRight: "20px" }}
                                                size={20}
                                                className="circle-one"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10px",
                                                        color: "rgba(30, 117, 255, 1)",
                                                    }}
                                                >
                                                    {idx + 1}
                                                </span>
                                            </Avatar>
                                            <img
                                                className="campaign-creation-followupIcon"
                                                src="/assets/svg/chatbox.svg"
                                                alt="chatbox"
                                            />
                                            <span style={{ fontSize: '17.06px', color: 'rgba(177, 174, 174, 1)' }} className="ps-2">Send Follow-Up Message</span>
                                            {campaignDetails.length === idx + 1 &&
                                                <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setDeleteConfirmModal(true)}>
                                                    <DeleteCrossMark />
                                                </div>
                                            }
                                        </div>
                                        <div
                                            className="d-flex flex-column"
                                            style={{
                                                paddingLeft: "20px",
                                                borderBottom: "2px solid rgb(56,56,63)",
                                                borderTop: "2px solid rgb(56,56,63)",
                                                padding: "20px",
                                            }}
                                        >
                                            <p style={{ fontSize: "16px" }}>
                                                Enter the message you want to send as a follow-up
                                                after they have accepted your invitation:
                                               <br/> (This Follow-Up message will not be sent if the lead responds to your connection message.)
                                                <span style={{ color: "red" }}> *</span>
                                            </p>
                                            <TextArea
                                                className="campaign-creation-text-area"
                                                placeholder="Enter the message"
                                                rows={10}
                                                style={{ margin: "0 10px" }}
                                                onChange={(e) => {
                                                    const updatedData = [...campaignDetails];
                                                    updatedData[idx] = { ...updatedData[idx], value: e.target.value, cursorPosition: e.target.selectionStart };
                                                    setCampaignDetails(updatedData);
                                                }}
                                                onClick={(e) => {
                                                    const updatedData = [...campaignDetails];
                                                    updatedData[idx] = { ...updatedData[idx], cursorPosition: e.target.selectionStart };
                                                    setCampaignDetails(updatedData);
                                                }}
                                                onKeyDown={(e) => handleKeyDown(e, idx)}
                                                value={processStep.value}
                                                ref={(textarea) => {
                                                    textAreaRefs.current[idx] = textarea;
                                                }}
                                            />
                                            <div className="d-flex justify-content-between">
                                            <div className="linkedin-sequence-custom-dropdown mt-2">
                                                <button className="dropdown-button" onClick={() => handleToggleOptions(idx)}>
                                                    Insert Variable <CaretDownOutlined className="small-arrow-icon" />
                                                </button>
                                                <div className={`dropdown-options w-100 ${showOptions && (idx == showOptionsIdx) ? 'show' : ''}`}>
                                                    {linkedInVariables.map((item, index) => (
                                                        <div className={"option" +(item.enabled ? "" : " disable-variable")}key={index} onClick={() => handleOptionSelect(item.key, idx)}>
                                                            {item.label}
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ))}
                    </div>
                </Col>
            </Row>
            <Modal
                closable={false}
                width={null}
                centered={true}
                footer={null}
                title={null}
                open={loading === 1 ? true : false}

            // className="bootstrap custom-modal text-white"
            >
                <div className="pt-4 text-center">
                    <Spin size="large" />
                </div>
            </Modal>
            <Modal
                title=""
                open={deleteConfirmModal}
                onCancel={() => { setDeleteConfirmModal(false) }}
                footer={[]}
                centered
                className="bootstrap text-white"

            >
                <div className="d-flex flex-column align-items-center">

                    <div className="pb-4 font-weight-400 font-size-24">
                        Are you sure to delete ?
                    </div>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <Button onClick={() => setDeleteConfirmModal(false)}>Cancel</Button>
                        <Button
                            type="primary"

                            onClick={() => handleRemoveLinkedinCampaignSteps()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </>

    )
}

export default SequenceSteps
