// Loader.js
import React from 'react';
// import { useLoader } from './LoaderContext';
import { useLoader } from '../store/loaderContext';
import '../assets/Styles/Loader.css'

const Loader = () => {
  const { showLoader } = useLoader();
  // console.log('showLoader',showLoader);
  
  if (!showLoader) {
    return null;
  }

  return (
		<div>
			{/* Your component content */}
			<div className="loader-container">
				<div className="loader"></div>
			</div>
		</div>
  );
};

export default Loader;
