// src/store/slices/notificationSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications = [action.payload, ...state.notifications];
    },
    markNotificationAsRead: (state, action) => {
      const notification = state.notifications.find(notif => notif.id === action.payload);
      if (notification) {
        notification.isRead = true;
      }
    },
    dismissNotification: (state, action) => {
      state.notifications = state.notifications.filter(notif => notif.id !== action.payload);
    },
    clearNotifications: (state) => {
      state.notifications = [];
    }
  },
});

export const { setNotifications, addNotification, markNotificationAsRead, dismissNotification, clearNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;

