import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Input, Form, Switch, Modal, Spin, Button, Col, Card, Space, Select } from 'antd';
import { LoaderProvider } from '../../store/loaderContext';
import { useNavigate } from 'react-router-dom';
import { menuItems } from '../../constants/Settings-Menu'
import { DropdownArrow } from "../../assets/svg/Icons";
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

import '../../assets/Styles/Settings-SideMenu.css'
import { useLoader } from '../../store/loaderContext';
import axiosInstance from '../../config/axiosConfig';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setCompany as setCompanyRedux } from "../../store/slices/companySlice";

const Prospecting = () => {
  const dispatch = useDispatch();
  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;

  const isSuperAdmin = authData.userInfo.is_super_admin;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const [syncHubspot, setSyncHubspot] = useState(false);
  const [settingsData, setSettingsData] = useState(false);
  const [apiKeyOpen, setApiKeyOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hubspotKey, setHubspotKey] = useState("");

  const [selectedLeadStatus, setSelectedLeadStatus] = useState();
  const [selectedHubspotField, setSelectedHubspotField] = useState();
  const [hubspotField, setHubspotField] = useState(false);
  const [smartleadsApiKeyModalOpen, setSmartleadsApiKeyModalOpen] = useState(false);
  const [smartleadsDisconnectModalOpen, setSmartleadsDisconnectModalOpen] = useState(false);

  const { Option } = Select;

  const [form] = Form.useForm();

  const leadStatusOptions = [
    { label: "Do not contact", value: "Do not contact" },
    { label: "Information Request", value: "Information Request" },
    { label: "Out of Office", value: "Out of Office" },
    { label: "Wrong Person", value: "Wrong Person" },
    { label: "Interested", value: "Interested" },
    { label: "Meeting Request", value: "Meeting Request" },
    { label: "Not Interested", value: "Not Interested" },
  ]
  const fetchSelectedHubspotFields = async () => {
    try {
      const response = await axiosInstance.get(`/settings/fetch/hs_status/${userId}?company_id=${companyId}`);
      setSelectedHubspotField(response.data.hs_field)
      setSelectedLeadStatus(response.data.sl_fields)
    }
    catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching selected fields' });
    }
  }


  const fetchHubspotFields = async () => {
    try {
      const response = await axiosInstance.get(`/settings/list/hs_status/${userId}?company_id=${companyId}`);
      setHubspotField(response.data)
    }
    catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching hubspot fields' });
    }
  }


  const fetchSettings = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/settings/get_settings/${companyId}?user_id=${userId}`);
      setSyncHubspot(response.data.company.sync_hubspot)
      setSettingsData(response.data)
      if (response?.data?.hubspost_synced === true && isSuperAdmin) {
        fetchHubspotFields()
        fetchSelectedHubspotFields()
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Settings' });
    }

    setLoading(false);
  }

  const connectHubspot = async () => {
    if (!settingsData.hubspost_synced) {
      setApiKeyOpen(true)
    }
    else {
      disconnectHubspotApiKey()
    }
  }

  const saveHubspotApiKey = async () => {
    setLoading(true);

    try {
      const data = {
        api_key: hubspotKey
      };

      await axiosInstance.put(`/settings/update/hubspot_key/${companyId}?user_id=${userId}`, data);
      notification.success({ message: 'Success', description: 'Hubspot API key has been saved successfully' });
      if(isSuperAdmin) {
        fetchHubspotFields()
        fetchSelectedHubspotFields()
      }
      setApiKeyOpen(false)
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in saving the Hubspot API key' });
    }
    setLoading(false)
    fetchSettings()
  }

  const disconnectHubspotApiKey = async () => {
    setLoading(true);

    try {
      await axiosInstance.put(`/settings/disconnect_hubspot/${companyId}?user_id=${userId}`);
      notification.success({ message: 'Success', description: 'Hubspot has been disconnected successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in disconnecting Hubspot' });
    }
    setLoading(false)
    fetchSettings()
  }
  const saveSmartleadsApiKey = async ({ api_key }) => {
    closeSmartleadsApiKeyModal();
    setLoading(true);

    try {
      const data = { api_key };

      await axiosInstance.put(`/settings/update/smartlead_key/${companyId}?user_id=${userId}`, data);
      notification.success({ message: 'Success', description: 'Smartlead API key has been saved successfully' });
      dispatch(setCompanyRedux({company_id: Number(companyId), user_id: Number(userId), sl_key: 'true'}));
    } catch (error) {
      setSmartleadsApiKeyModalOpen(true);
      notification.error({ message: 'Error', description: 'Error in saving the Smartlead API key' });
    }

    const data = { ...settingsData };
    data.smartlead_synced = true;

    setSettingsData({ ...data });
    setLoading(false);
  }

  const handleSyncHubspot = async (event) => {
    setLoading(true);
    const payload = {
      data: {
        name: settingsData?.company?.companyName,
        website: settingsData?.company?.website,
        display_name: settingsData?.company?.displayName,
        job_title: settingsData?.company?.jobTitle,
        sync_hubspot: event
      }
    }
    await axiosInstance.put(`/settings/update/company/${companyId}?user_id=${userId}`, payload);
    setLoading(false)
    fetchSettings()
  };

  const closeSmartleadsApiKeyModal = () => {
    setSmartleadsApiKeyModalOpen(false);
  }

  const closeSmartleadsDisconnectModal = () => {
    setSmartleadsDisconnectModalOpen(false);
  }
  const disconnectSmartleadsApiKey = async () => {
    closeSmartleadsDisconnectModal();
    setLoading(true);

    try {
      await axiosInstance.put(`/settings/disconnect_smartlead/${companyId}?user_id=${userId}`);
      notification.success({ message: 'Success', description: 'Smartlead has been disconnected successfully' });
      dispatch(setCompanyRedux({company_id: Number(companyId), user_id: Number(userId), sl_key: 'false'}));
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in disconnecting Smartlead' });
    }

    const data = { ...settingsData };
    data.smartlead_synced = false;

    setSettingsData({ ...data });
    setLoading(false);
  }

  const saveHubspotField = async () => {
    const payload = {
      company_id: companyId,
      sl_fields: selectedLeadStatus,
      hs_field: selectedHubspotField
    }
    try {
      await axiosInstance.post(`/settings/save/hs_status/${userId}`, payload);
      notification.success({ message: 'Success', description: 'Hubspot fields mapped successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in mapping hubspot fields' });
    }
  }

  useEffect(() => {
    fetchSettings()
  }, []);

  return (
    <div className="bootstrap d-flex flex-row text-white w-100 justify-content-center align-items-center settings-background">
      <div className='d-flex flex-column profile-card'>
        <div className='profile-text w-100 mb-3'>Prospecting Preferences</div>
        <div className='d-flex justify-content-between mb-3'>
          <div className='prospecting-sync-lead w-100'>
            Automatically Sync Leads to Hubspot
          </div>
          <Switch checked={syncHubspot}
            onChange={handleSyncHubspot} />
        </div>
        <div className='profile-row mt-4 mb-3 h-100'></div>
        <div className='prospecting-integration-text mb-3'>Integrations</div>
        <div className='prospecting-hubspot py-3 ps-3'>
          <div className='d-flex'>
            <img src="/assets/svg/hubspot-icon.svg" className='me-2' />
            <div className='prospecting-hubspot-text pt-1'>Hubspot</div>
          </div>
          {!settingsData.hubspost_synced &&
            <div className='prospecting-hubspot-subText mt-3'>
              Connect your Hubspot to manage your CRM.
            </div>}
          <div className='prospecting-hubspot-btn pointer mt-3' onClick={() => connectHubspot()}>
            {!settingsData.hubspost_synced ? "Connect" : "Disconnect"}</div>
        </div>
        {apiKeyOpen && <div className='blocklist-input-table d-flex px-2 py-3 mb-1 mt-3'>
          <Input className="blocklist-input" placeholder="Enter API key" onChange={(e) => setHubspotKey(e.target.value)} />
          <div className={ 'ms-2 blocklist-add pointer ' + (hubspotKey === "" ? "disabled-div" : "")} onClick={saveHubspotApiKey}>Connect</div>
        </div>}

        {isSuperAdmin &&  <Col span={12} className='mt-3'>
          <Card
            className='default-background'
            style={{ lineHeight: '10px', padding: '0px' }}
          >
            <Space direction='vertical' size={"middle"}>
              <img src="/assets/images/smart-lead.png" width="auto" height="52" alt="Smart Lead" />
              <div>
                <p className='font-size-16'>Smartlead</p>
                <span style={{ color: '#AAAAAA' }}>Login credentials</span>
              </div>
              {!settingsData.smartlead_synced &&
                <Button
                  type='primary'
                  icon={<PlusSquareOutlined />}
                  onClick={() => setSmartleadsApiKeyModalOpen(true)}
                  disabled={!editAccess}
                >
                  Connect
                </Button>
              }
              {settingsData.smartlead_synced &&
                <Button
                  icon={<MinusSquareOutlined />}
                  onClick={() => setSmartleadsDisconnectModalOpen(true)}
                  className="red-btn"
                  disabled={!editAccess}
                >
                  Disconnect
                </Button>
              }
            </Space>
          </Card>
        </Col>}

        {settingsData.hubspost_synced === true && settingsData.smartlead_synced === true ?
          <div className='mt-4'>
            <div>
              <span className="white-text mt-3 me-3">Create deals when lead is marked as</span>
              <Select className="mt-2 w-25 ms-3"
                mode="multiple"
                value={selectedLeadStatus}
                onChange={(e) => {
                  setSelectedLeadStatus(e)
                }}
              >
                {leadStatusOptions.map((i) => <Option value={i.value}>{i.label}</Option>)}
              </Select>
            </div>
            <div className='mt-4'>
              <span className="white-text mt-3 me-3">Select Hubspot Stage for Deal Creation</span>
              <Select className="mt-2 w-25" value={selectedHubspotField}
                onChange={(e) => {
                  setSelectedHubspotField(e)
                }}
              >
                {hubspotField && hubspotField.map((i) => <Option value={i.field_value}>{i.field_label}</Option>)}
              </Select>
            </div>
            <div className='text-end w-75 mt-2'><Button type="primary" onClick={saveHubspotField}>Save</Button></div>
          </div>
          : null}
      </div>

      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={loading}

      >
        <div className="pt-4 text-center">
          <Spin size="large" />
        </div>
      </Modal>
      <Modal
        title=" "
        open={smartleadsApiKeyModalOpen}
        onOk={closeSmartleadsApiKeyModal}
        onCancel={closeSmartleadsApiKeyModal}
        maskClosable={false}
        footer={[]}
        centered
        className="bootstrap custom-modal"
      >
        <div className="fs-6 pb-4">Smartlead - Login Credentials</div>
        <div className="d-flex flex-column align-items-center">
          <Form onFinish={saveSmartleadsApiKey} className="w-100">
            <Form.Item
              name="api_key"
              className="w-100"
              rules={[
                {
                  required: true,
                  message: "please enter api key"
                },
              ]}
            >
              <Input className="custom-select" placeholder="enter api key" />
            </Form.Item>
            <div className="w-100 d-flex align-items-center justify-content-end">
              <button type="submit" disabled aria-hidden="true" class="d-none">PreventSubmit</button>
              <Button type="primary" htmlType="submit">Save Key</Button>
              <Button className="ms-2 black-btn" onClick={closeSmartleadsApiKeyModal}>Close</Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title=" "
        open={smartleadsDisconnectModalOpen}
        onOk={closeSmartleadsDisconnectModal}
        onCancel={closeSmartleadsDisconnectModal}
        maskClosable={false}
        footer={[]}
        centered
        className="bootstrap custom-modal"
      >
        <div className="fs-6 pb-4">Disconnect Smartlead</div>
        <div className="w-100 d-flex align-items-center justify-content-end">
          <button type="submit" disabled aria-hidden="true" class="d-none">PreventSubmit</button>
          <Button htmlType="submit" className="red-btn" onClick={disconnectSmartleadsApiKey}>Disconnect</Button>
          <Button className="ms-2 black-btn" onClick={closeSmartleadsDisconnectModal}>Close</Button>
        </div>
      </Modal>
    </div>
  );
};

export default Prospecting;
