import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	loading:false,
	selectedCompany: {},
  viewAs: 0,
	vault:[]
}

export const companySlice = createSlice({
  name: 'company',
  initialState: initialState,
  reducers: {
    setCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setViewAs: (state, action) => {
      state.viewAs = action.payload;
    }
  },
})

export const { 
	setCompany,
  setViewAs
} = companySlice.actions

export const companyReducer = companySlice.reducer
