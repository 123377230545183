import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Card,
  Drawer,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Progress,
  Result,
  Row,
  Select,
  Spin,
  Tag,
  Upload,
  Checkbox,
  Switch
} from "antd";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import { DropdownArrow, DropdownArrowWhite } from "../../assets/svg/Icons";
import axios from "axios";
import axiosInstance from "../../config/axiosConfig";
import {
  getChance,
  getWidthPercent,
  getSubjectLength,
  getWordCount,
  getQuestionCount,
  getReadingLevel,
  getSpamWords,
  getNoOfLinks
} from '../../common/copywritingFunctions';
import { API_BASE_URL, AUTH_TOKEN } from "../../config/AppConfig";
import CTA from "../../common/ctas";
import TimeoutErrorModal from "../../common/TimeoutErrorModal";

import $ from 'jquery';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import normalizeHtml from "../../common/normalizeHtml";
import insertLineBreak from "../../common/insertLineBreak";
import moment from "moment";
import { useLocation } from "react-router-dom";

const toolbarConfig = {
  toolbarKeys: [
    'insertVariable',
    'insertLink',
    'bold',
    'italic',
    'underline',
    'bulletedList',
    'numberedList',
    'uploadImage'
  ]
};

const emptyScores = {
  score_percentage: 0,
  total_score: 0,
  subject_length: 0,
  word_count: 0,
  question_count: 0,
  reading_level: 0,
  spam_words: 0,
  no_of_links: 0
};

const {TextArea} = Input;
const { Dragger } = Upload;
const { Option } = Select;

const CampaignsForm = () => {
  const navigate = useNavigate();

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ?  companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const isSuperAdmin = authData.userInfo.is_super_admin;
  const isAdmin = authData.userInfo.isAdmin === true ? true : false;
  const isViewer = authData.userInfo.isViewer === true ? true : false;

  const [form1] = Form.useForm();
  const [improveCopyForm] = Form.useForm();
  const [editCopyForm] = Form.useForm();
  const [generateCopyForm] = Form.useForm();

  const pageLoaded = useRef(false);
  const [personas, setPersonas] = useState([]);
  const [copies, setCopies] = useState([]);
  const [emailCopies, setEmailCopies] = useState([]);
  const campaignId = useRef();
  const campaignName = useRef("");
  const copyWriteScore = useRef({
    score_percentage: 0,
    total_score: 0,
    subject_length: 0,
    word_count: 0,
    question_count: 0,
    reading_level: 0,
    spam_words: 0,
    no_of_links: 0
  });
  const importedCsvFile = useRef(null);
  const isNewVariant = useRef(false);
  const disableGenerateCopy = useRef(true);

  const [loading, setLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [campaignNameEdit, setCampaignNameEdit] = useState(false);
  const [currentCopy, setCurrentCopy] = useState("1-1");
  const [editCopy, setEditCopy] = useState(false);
  const [additionalEmails, setAdditionalEmails] = useState(true);
  const [improveCopy, setImproveCopy] = useState(false);
  const [copyProgress, setCopyProgress] = useState(false);
  const [sendingLimit, setSendingLimit] = useState(null);
  const [minTimeBtwEmails, setminTimeBtwEmails] = useState(null);
  const [activateCampaignError, setActivateCampaignError] = useState(false);
  const [improveCopyTone, setImproveCopyTone] = useState("");
  const [improveCopyToneError, setImproveCopyToneError] = useState(false);
  const [improveCopyLength, setImproveCopyLength] = useState("");
  const [improveCopyLengthError, setImproveCopyLengthError] = useState(false);
  const [showUploadProspects, setShowUploadProspects] = useState(false);
  const [showGenerateCopy, setShowGenerateCopy] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [includeDuplicates, setIncludeDuplicates] = useState(false);
  // const [generateCopyModalOpen, setGenerateCopyModalOpen] = useState(false);
  // const [generateCopyType, setGenerateCopyType] = useState("ai");
  const [defaultAllMail, setDefaultAllMail] = useState(true);
  const [emailAccounts, setEmailAccounts] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [delayDays, setDelayDays] = useState();
  const [newVariantDetails, setNewVariantDetails] = useState(false); 
  const [newvariantStep, setNewvariantStep] = useState();
  const [importCsvScreen, setImportCsvScreen] = useState(0);
  const [copyId, setCopyId] = useState();
  const [campaignList, setCampaignList] = useState([])
  const [allDuplicates, setAllDuplicates] = useState(false);
  const [leadIdsSelected, setLeadIdsSelected] = useState([])
  const [cursorPosition, setCursorPosition] = useState(null);
  const [selectedVariable, setSelectedVariable] = useState(); 
  const inputRef = useRef(null);

  const [editor, setEditor] = useState(null);
  const [html, setHtml] = useState(null);
  const csvMappings = useRef({file_id: 0, file_name: "", columns: []});
  const location = useLocation();

  const csvPeopleOptions = [
    {
      label: <div className="bootstrap"><div className="d-flex align-items-center">
        <img src="/assets/images/csv-mapping-icons/name-2x.png" width="15" height="auto" alt="icon" />
        <span className="ps-2">First Name</span>
      </div></div>,
      value: "First Name"
    },
    {
      label: <div className="bootstrap"><div className="d-flex align-items-center">
      <img src="/assets/images/csv-mapping-icons/name-2x.png" width="15" height="auto" alt="icon" />
      <span className="ps-2">Last Name</span>
    </div></div>,
      value: "Last Name"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/job-title-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Role</span>
        </div>
      </div>,
      value: "Role"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/name-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Company</span>
        </div>
      </div>,
      value: "Company"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/linkedin-url-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Website</span>
        </div>
      </div>,
      value: "Website"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/location-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Location</span>
        </div>
      </div>,
      value: "Location"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/email-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Email</span>
        </div>
      </div>,
      value: "Email"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/linkedin-url-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Linkedin URL</span>
        </div>
      </div>,
      value: "Linkedin URL"
    },
    {
      label: <div className="bootstrap">
        <div className="d-flex align-items-center">
          <img src="/assets/images/csv-mapping-icons/do-not-include-2x.png" width="15" height="auto" alt="icon" />
          <span className="ps-2">Do Not Include</span>
        </div>
      </div>,
      value: "Do Not Include"
    }
  ]

  let editorConfig = {
    placeholder: 'Type here...',
    MENU_CONF : {}
  }

  editorConfig.MENU_CONF['uploadImage'] = {
    server: `${API_BASE_URL}/engage/upload_image/${userId}`,
    fieldName: 'file',
    timeout: 3 * 100000,
    meta: {
      company_id: companyData.company_id,
      campaign_id: campaignId.current,
      variant_id: emailCopies[currentCopy.split("-")[0] - 1]?.variants[currentCopy.split("-")[1] - 1]?.id,
      upload_type: "email",
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`
    },

    onSuccess(file, res) {
      console.log(`${file.name} uploaded`, res)
    },
    onFailed(file, res) {               
      console.log(`${file.name} failed`, res)
    },
    customInsert(res, insertFn) {                  
      insertFn(`${API_BASE_URL}/engage/get_image/${res.file_id}`, "", `${API_BASE_URL}/engage/get_image/${res.file_id}`)
    },
  }

  const renderLoader = () => (
    <div
      className={"loader2 flex-column justify-content-center align-items-center "
        + (loading === 0 ? "d-none" : "d-flex")}
    >
      <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
      <div className={"pt-4 text-center" + (loading === 1 ? "" : " d-none")}>
        <Spin size="large" />
      </div>
    </div>
  )

  const prevPage = () => {
    setEditCopy(false);
    setCurrentPage(currentPage - 1);
  }

  const nextPage = () => {
    setEditCopy(false);
    document.querySelector(`#form${currentPage}-submit-btn`).click();
  }

  const renderFormTitle = () => (
    <>
      {currentPage === 1 &&
        <Row className={campaignNameEdit ? "w-100" : "d-none"} justify="space-between" align="middle">
          <Col span={24}>
            <Form.Item
              className="pb-0"
              name="campaign_name"
              initialValue={campaignName.current}
              rules={[
                { 
                  required: true,
                  message: "Please enter a name for the Campaign"
                },
              ]}
            >
              <Input
                className="settings-enter-email custom-input"
                suffix={
                  <div className="arrow-parent pointer" onClick={alterCampaignName}>➜</div>
                }
              />
            </Form.Item>
          </Col>
        </Row>
      }
      <div className={"w-100 align-items-center position-relative " + (campaignNameEdit ? "d-none" : "d-flex")}>
        <span className="d-block inbox-indicator pull-left unread position-absolute" />
        {campaignName.current.length === 0
          ?
            <span className="pe-2">Enter a name for your new campaign here</span>
          :
            <span className="pe-2">{campaignName.current}</span>
        }
        <img
          src="/assets/images/wizard-blue-2x.png"
          width="18"
          height="18"
          alt="wizard"
          className={"pointer" + (currentPage > 1 ? " d-none" : "")}
          onClick={() => setCampaignNameEdit(true)}
        />
      </div>
    </>
  )

  const alterCampaignName = () => {
    campaignName.current = form1.getFieldValue("campaign_name");
    setCampaignNameEdit(false);
  }

  const addVariant = async copyNo => {
    if (editCopy) {
      setEditCopy(false);
    }

    const copyIndex = copyNo - 1;

    const copies = [...emailCopies];
    const variants = [...copies[copyIndex].variants];

    const newVariantNo = variants.length + 1;

    const newVariant = {
      variant_id: 0,
      variant_message: "",
      id: 0,
      variant_name: `Variant ${newVariantNo}`,
      variant_subject: "",
      score: {...emptyScores},
    }

    variants.push(newVariant);
    copies[copyIndex].variants = [...variants];
    setEmailCopies([...copies]);

    setCurrentCopy(copyNo + "-" + newVariantNo);
    setNewvariantStep(copyIndex)
    setNewVariantDetails(copies[copyIndex])
    setLoading(1);

    setTimeout(() => {
      initiateEditCopy(copies[copyIndex].seq_id, newVariant, true);
      setLoading(0);
    }, 500);
  }

  const addEmailStep = () => {
    setLoading(1);
    const newCopyNo = emailCopies.length + 1;
    setNewvariantStep(emailCopies.length)
    const newStep = {
      engage_campaign_id: campaignId.current,
      delay_in_days: 1,
      variants: [
        {
          variant_id: 0,
          variant_message: "",
          id: 0,
          variant_name: `Variant 1`,
          variant_subject: "",
          score: {...emptyScores},
        }
      ]
    };

    setEmailCopies([...emailCopies, newStep]);
    setNewVariantDetails(newStep)
    setCurrentCopy(newCopyNo + "-1");
    
    setTimeout(() => {
      initiateEditCopy(0, newVariant, true);
      setLoading(0);
    }, 500);
  }

  /*
  const gotoProspecting = () => {
    navigate("/prospecting?showImport=true");
  }

  const gotoCopywriting = () => {
    navigate("/copywriting?showForm=true");
  }
  */

  const fetchPersonas = async () => {
    try {
			const response = await axiosInstance.get(`/v2_lists/fetch_lists_only/${userId}?company_id=${companyId}`);
      
      const existingPersonaz = [];
      response.data.forEach(persona => {
        existingPersonaz.push(
          {
            value: persona.persona_id,
            label: persona.persona_name
          }
        );
      });
      setPersonas([...existingPersonaz]);
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching the Persona(s)'});
	  }

    setLoading(0);
  }

  const fetchCopies = async (selectedPersona) => {
    setLeadIdsSelected([])
    setLoading(1);
    let personaId
    if (selectedPersona) { 
      personaId = selectedPersona 
    }
    else { 
      personaId = form1.getFieldValue("persona_id"); 
    }
    try {
			const response = await axiosInstance.get(`/copywriting/fetch_copy/${userId}?company_id=${companyId}&persona_id=${personaId}`);

      const existingCopies = [];
      response.data.forEach(copy => {
        existingCopies.push(
          {
            value: copy.copy_id,
            //label: copy.copy_name
            label: copy.copy_name === "" ? copy.persona_name + ' - '+ copy.copy_id : copy.persona_name + ' - '+ copy.copy_name 
          }
        );
      });
      setCopies([...existingCopies]);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Copies' });
    }

    form1.setFieldValue("persona_id", personaId);
    generateCopyForm.setFieldValue("persona_id", personaId);

    disableGenerateCopy.current = false;
    checkDuplicattion(personaId);
  }

  const checkDuplicattion = async personaId => {
    try {
			const {data} = await axiosInstance.get(`/v2_engage/verify_new_duplicate/${personaId}?company_id=${companyId}`);
      if (data.is_duplicate) {
        setShowDuplicateModal(true);
      }
      else {
        setIncludeDuplicates(false)
      }
      if (data.is_duplicate) {
        if (data.all_leads_duplicate) {
          setAllDuplicates(true)
        }
        else {
          setAllDuplicates(false)
        }
      }
		} catch (error) {
      console.error("Network Error")

	  }

    setLoading(0);
  }

  const createCampaign = async (values, copy_id) => {
    setLoading(1);

    const payload = {
      company_id: companyId,
      campaign_name: values.campaign_name,
      persona_id: values.persona_id,
      copy_id: copy_id,
      lead_ids: leadIdsSelected.map(id => parseInt(id, 10)),
      exclude_duplicate: includeDuplicates ? false : true
    };

    try {
      const response = await axiosInstance.post(`/v2_engage/create_new_campaign/${userId}`, payload);
      setEmailCopies(response.data);

      campaignId.current = response.data[0].engage_campaign_id;
      setCampaignNameEdit(false);
      setCurrentPage(2);

      const firstMessage = response.data[0].variants[0];
      
      // if (generateCopyType === "blank") {
        initiateEditCopy(response.data[0].seq_id, firstMessage);
      // }
		} catch (error) {
      if(error?.response?.status === 401) {
        notification.error({message: 'Error', description: 'Your subscription plan has expired'});
      }
      else {
        notification.error({message: 'Error', description: error?.response?.data?.detail});
      }
	  }

    setLoading(0);
  }

  const createSequence = async values => {
    setEditCopy(false);
    setLoading(1);
    updateSequence(values)
    // const payload = {user_id: userId};
    // const sequences = [];
    // const seqLength = values.seq_length;

    // for (let a = 0; a < seqLength; a++) {
    //   sequences.push({
    //     seq_id: values["seq_id_" + a],
    //     delay_days: Number(delayDays?.["delay_days_" + a] || values["delay_days_" + a])
    //   }) 
    // }

    // payload.sequences = sequences;

    // try {
    //   const response = await axiosInstance.post(`/engage/create_sequence/${values.campaign_id}`, payload);
    //   if(response.data.status === 200) {
    //     notification.success({message: 'Success', description: 'Sequence has been created successfully'});
    //   }
		// } catch (error) {
    //   notification.error({message: 'Error', description: 'Error in creating sequence'});
	  // }

    setCurrentPage(3);
    setLoading(0);
  }

  const activateCampaign = () => {
    setLoading(1);

    const max_new_leads_per_day = 1000;
    const min_time_btw_emails = minTimeBtwEmails;

    if (!max_new_leads_per_day || max_new_leads_per_day < 1 ) {
      setActivateCampaignError(true);
      setLoading(0);
    } else {
      setActivateCampaignError(false);
      processActivation(max_new_leads_per_day, 10);
    }
  }

  const processActivation = async (max_new_leads_per_day, min_time_btw_emails) => {
    const payload = {
      max_new_leads_per_day,
      min_time_btw_emails,
      user_id: userId,
      // remove_duplicate: includeDuplicates ? false : true,
      default_all_email: defaultAllMail,
      email_accounts_used: emailAccounts,
      file_id: csvMappings.current.file_id
    }

    try {
			await axiosInstance.put(`/v2_engage/activate_new_campaign/${campaignId.current}`, payload);
      // notification.success({message: 'Success', description: 'Campaign activation request successful. Refresh in a few minutes.'});
      Modal.info({
        title: (<div style={{ color: "#ffffff" }}>Campaign creation</div>),
        content: (<div style={{ color: "#ffffff" }}>Campaign will be created in few minutes</div>),
        okText: 'OK',
        centered: true,
      })
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in Campaign activattion'});
	  }

    setLoading(0);
    navigate("/engage/campaign-list");
  }

  const deleteStep = async (index, id) => {
    Modal.confirm({
      title: (<div style={{ color: "#ffffff" }}>Delete step?</div>),
      content: (<div style={{ color: "#ffffff" }}>Are you sure you want to delete this step?</div>),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: async () => {
        setEditCopy(false);
        setLoading(1);

        try {
          await axiosInstance.put(`/v2_engage/delete_new_sequence/${id}?user_id=${userId}`);
          notification.success({ message: 'Success', description: 'Sequence has been deleted successfully' });

          const copies = [...emailCopies];
          copies.splice(index, 1);
          setEmailCopies([...copies]);

          let lastCopyIndex = copies.length;
          let lastVariantIndex;

          if (lastCopyIndex < 1) {
            setCurrentCopy("");
          } else {
            const variantsLength = copies[lastCopyIndex - 1].variants.length;
            lastVariantIndex = variantsLength;

            setCurrentCopy(lastCopyIndex + "-" + lastVariantIndex);
          }
        } catch (error) {
          notification.error({ message: 'Error', description: 'Error in deleting the Sequence' });
        }

        setLoading(0);
      },
    });
  }

  const deleteVariant = async (copyIndex, variantIndex, id, enable) => {
    Modal.confirm({
      title: (<div style={{ color: "#ffffff" }}>Confirm</div>),
      content: (<div style={{ color: "#ffffff" }}>Are you sure about {enable? "enabling" : "disabling"} the variant?</div>),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: async () => {
        setEditCopy(false);
        setLoading(1);

        const copies = [...emailCopies];
        const variants = [...copies[copyIndex].variants];

        try {
          if (id > 0) {
            await axiosInstance.put(`/v2_engage/delete_new_variant/${id}?user_id=${userId}&enable_variant=${enable}`);
          }

          variants[variantIndex].row_status = enable
          copies[copyIndex].variants = [...variants];
          const isAnyRowStatusTrue = copies[copyIndex]?.variants?.some(variant => variant.row_status === true);
          if (isAnyRowStatusTrue) {
            setEmailCopies([...copies]);
            setCurrentCopy((copyIndex+1) + "-" + (variantIndex+1));
          } else {
            copies.splice(copyIndex, 1)
            setEmailCopies([...copies]);
            setCurrentCopy("1-1")
          }
          
          if(enable) {
          notification.success({ message: 'Success', description: 'Variant has been enabled successfully' });
          }else {
            notification.success({ message: 'Success', description: 'Variant has been disabled successfully' });
          }
        } catch (error) {
          notification.error({ message: 'Error', description: 'Error in deleting the Variant' });
        }

        // let lastCopyIndex = copies.length;
        // let lastVariantIndex = variants.length;

        // if (lastVariantIndex < 1) {
        //   lastCopyIndex = lastCopyIndex - 1;

        //   if (lastCopyIndex < 1) {
        //     setCurrentCopy("");
        //   } else {
        //     const variantsLength = copies[lastCopyIndex - 1].variants.length;
        //     lastVariantIndex = variantsLength;

        //     setCurrentCopy(lastCopyIndex + "-" + lastVariantIndex);
        //   }
        // } else {
        //   setCurrentCopy(lastCopyIndex + "-" + lastVariantIndex);
        // }

        setLoading(0);
      }
    })
  }

  const destroyWangEditor = () => {
    if (!editor) return;

    $('.wang-editor').off();
    const $toolbar = $('.wang-copywriting-toolbar');
    const toolbarLength = $toolbar.length;

    if (toolbarLength) {
      $toolbar.first().remove();
    }

    editor.destroy();
    
    setEditor(null);
    setHtml("");
  }

  const triggerEditCopy = () => {
    document.querySelector("#edit-copy-btn-" + currentCopy).click();
  }

  const initiateEditCopy = (seqId, variant, action = null) => {
    editCopyForm.setFieldsValue({
      seq_id: seqId,
      var_id: variant.id,
      variant_name: variant.variant_name,
      subject: variant.variant_subject,
      body: variant.variant_message,
    });

    if (action === null) {
      action = !editCopy;
      isNewVariant.current = false;
    } else {
      isNewVariant.current = true;
    }

    if (action) { // open
      destroyWangEditor();
      setHtml(normalizeHtml(variant.variant_message));
    }

    setEditCopy(action);
  }

  const setHtmlContent = (content, text) => {
    const value = text.length ? content : "";

    let editValue = value
    let tempElement = document.createElement('div');
    tempElement.innerHTML = editValue;
    let imgTags = tempElement.getElementsByTagName('img');
    for (let i = 0; i < imgTags.length; i++) {
      let imgTag = imgTags[i];
      if (!imgTag.style.width) {
        imgTag.style.width = '100%';
      }
    }
    editValue = tempElement.innerHTML;

    editCopyForm.setFieldValue("body", editValue);
    if(isNewVariant.current === true && newVariantDetails) {
      let details = newVariantDetails
      let lastIndex = newVariantDetails.variants.length - 1
      details.variants[lastIndex].variant_message = editValue
    }
    setHtml(editValue);
  }

  const newStep = async (values, newVarDetails) => {
    const newCopyNo = emailCopies.length;
    let payload= {}
    if(values !== null && values !== undefined) {
      payload = {
      subject: values.subject || "",
      body: values.body.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      campaign_id: campaignId.current,
      new_step: true,
      delay_in_days: 1,
      variant_label: "Variant 1",
      seq_label: "Step " + newCopyNo,
    }
  }
  else {
    payload = {
      subject: newVarDetails.variants[0].variant_subject || "",
      body:  newVarDetails.variants[0].variant_message.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      campaign_id: campaignId.current,
      new_step: true,
      delay_in_days: 1,
      variant_label: "Variant 1",
      seq_label: "Step " + newCopyNo,
    }
    }

    try {
			const response = await axiosInstance.post(`/v2_engage/add_new_variant/${userId}?company_id=${companyId}`, payload);
      setEmailCopies(response.data);

      notification.success({message: 'Success', description: 'Step has been added successfully'});
		} catch (error) {
      let copies = emailCopies
      copies.pop()
      setEmailCopies([...copies])
      notification.error({message: 'Error', description: 'Error in adding the Step'});
	  }

    setEditCopy(false);
    setLoading(0);
  }

  const newVariant = async (values, newVarDetails) => {
    if (Number(values?.seq_id) === 0 || newVariantDetails.seq_id === null || newVariantDetails.seq_id === undefined) {
      newStep(values, newVarDetails);
      return;
    }

    let payload = {}
    if(values !== null && values !== undefined) {
      payload = {
      subject: values.subject,
      body: values.body.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      seq_id: values.seq_id,
      seq_label: values.seq_label,
      variant_label: values.variant_name,
      new_step: false,
      campaign_id: campaignId.current,
      delay_in_days: 0
    }
  }
  else {
    let index = newVarDetails.variants.length -1
    payload = {
      subject: newVarDetails.variants[index].variant_subject,
      body: newVarDetails.variants[index].variant_message.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      seq_id: newVarDetails.seq_id,
      seq_label: newVarDetails.seq_label,
      variant_label: newVarDetails.variants[index].variant_name,
      new_step: true,
      campaign_id: campaignId.current,
      delay_in_days: 0
  }
    }

    try {
			const response = await axiosInstance.post(`/v2_engage/add_new_variant/${userId}?company_id=${companyId}`, payload);
      setEmailCopies(response.data);

      notification.success({message: 'Success', description: 'Variant has been added successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in adding the Variant'});
	  }

    setEditCopy(false);
    setLoading(0);
  }

  const editVariant = async (values, newVarDetails ) => {
    setLoading(1);
    if (isNewVariant.current || values.var_id === 0) {
      if(newVarDetails !== null && newVarDetails !== undefined) {
        newVariant(values, newVarDetails)
      }
      else {
        newVariant(values);
      }
      return;
    }

    const payload = {
      subject: values.subject,
      body: values.body.replace(/style="color: rgb\(255,\s*255,\s*255\); background-color: rgb\(32,\s*32,\s*41\);"(.*?)/g, ''),
      edit_smartlead_sequence: false,
      user_id: userId
    }

    try {
			await axiosInstance.put(`/v2_engage/edit_new_variant/${values.var_id}`, payload);
      notification.success({message: 'Success', description: 'Variant has been edited successfully'});

      let [copyIndex, variantIndex] = currentCopy.split("-");

      copyIndex = Number(copyIndex) - 1;
      variantIndex = Number(variantIndex) - 1;

      const copies = [...emailCopies];
      const variants = [...copies[copyIndex].variants];

      const editedVariant = {...variants[variantIndex]};
      editedVariant.variant_subject = payload.subject;
      editedVariant.variant_message = payload.body;
            
      variants[variantIndex] = {...editedVariant};
      copies[copyIndex].variants = [...variants];
      setEmailCopies([...copies]);
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in editing the Variant'});
	  }

    setEditCopy(false);
    setSelectedVariable("")
    setLoading(0);
  }

  const triggerRegenerateFormSubmit = () => {
    if (improveCopyTone.length < 1) {
      setImproveCopyToneError(true);
    } else {
      setImproveCopyToneError(false);
    }
    
    if (improveCopyLength.length < 1) {
      setImproveCopyLengthError(true);
    } else {
      setImproveCopyLengthError(false);
    }

    document.querySelector(`#regenerate-form-btn`).click();
  }

  const improveVariant = async values => {
    setEditCopy(false);
    
    if (improveCopyToneError || improveCopyLengthError) {
      return;
    }

    setLoading(1);
    const varId = Number(currentCopy.split("-").pop());

    const payload = {
      tone: improveCopyTone,
      length: improveCopyLength,
      cta: values.cta,
      user_input: values.user_input,
      user_id: userId,
      edit_smartlead_sequence: false,
    }

    try {
			const response = await axiosInstance.put(`/engage/improve_variant/${varId}`, payload);
      notification.success({message: 'Success', description: 'Variant has been improved successfully'});

      let [copyIndex, variantIndex] = currentCopy.split("-");

      copyIndex = Number(copyIndex) - 1;
      variantIndex = Number(variantIndex) - 1;

      const copies = [...emailCopies];
      const variants = [...copies[copyIndex].variants];

      const improvedVariant = {...variants[variantIndex]};
      improvedVariant.variant_subject = response.data.variant_subject;
      improvedVariant.variant_message = response.data.variant_message;
            
      variants[variantIndex] = {...improvedVariant};
      copies[copyIndex].variants = [...variants];
      setEmailCopies([...copies]);

      setImproveCopyTone("");
      setImproveCopyToneError(false);
      setImproveCopyLength("");
      setImproveCopyLengthError(false);
      improveCopyForm.resetFields();
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in improving the Variant'});
    }

    setLoading(0);
  }

  const dummyUpload = async ({onSuccess}) => {
    onSuccess("Ok");
  }

  const csvDraggableProps = {
    name: 'csv_file',
    multiple: false,
    maxCount: 1,
    customRequest: dummyUpload,
    beforeUpload (file) {
      setLoading(-1);
      importedCsvFile.current = file;

      setLoading(0);
    }
  };

  const csvUpload = async () => {
    // setShowUploadProspects(false);
    setLoading(1);

    const formData = new FormData();
    formData.append('file', importedCsvFile.current);

    const config = {
      method: 'post',
      baseURL: API_BASE_URL,
      url:  `/prospect/upload_csv/${userId}?data_type=people&company_id=${companyId}`,
      // url:  `/engage/upload_csv/${userId}?company_id=${companyId}`,
      headers: {
        Authorization: `Bearer ${authData.token}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    };

    const res = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        if (err.request || err.response) {
          console.error(err);
          return false;
        } else {
          return null;
        }
      });

    if (res) {
      // const newPersona = {label: res.persona_name, value: res.persona_id};
      // personas.current = [newPersona, ...personas.current];

      // notification.success({message: 'Success', description: 'File has been uploaded successfully'});

      // form1.setFieldValue("persona_id", res.persona_id);
      // generateCopyForm.setFieldValue("persona_id", res.persona_id);

      // disableGenerateCopy.current = false;
      csvMappings.current = {...res, file_name: importedCsvFile.current.name};

      notification.success({message: 'Success', description: 'File has been uploaded successfully'});
      setImportCsvScreen(2);
    } else if (res === false) {
      notification.error({message: 'Error', description: 'Error in Uploading the file'});
    } else {
      setShowTimeoutModal(true);
    }

    setLoading(0);
  }

  const postCsvMappings = async values => {
    setLoading(1);
    const data = {
      file_id: csvMappings.current.file_id,
      mapping: values,
      data_type: "people"
    }

    try {
      await axiosInstance.post(`/prospect/column_mapping/${userId}`, data);
      await enrichLeads(data.file_id);

      // notification.success({message: 'Success', description: 'Column mappings have been saved successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in Saving the mappings'});
	  }
    setLoading(0);
   
  }

  const updateSequence = async values => {
    setLoading(1);

    const payload = {user_id: userId};
    const sequences = [];
    const seqLength = values.seq_length;

    for (let a = 0; a < seqLength; a++) {
      sequences.push({
        seq_id: values["seq_id_" + a],
        delay_days: Number(values["delay_days_" + a])
      }) 
    }

    payload.sequences = sequences;

    try {
      await axiosInstance.put(`/v2_engage/update_sequence_delay_days/${campaignId.current}`, payload);
      notification.success({message: 'Success', description: 'Sequence has been updated successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in updating sequence'});
	  }

    setLoading(0);
  }

  const enrichLeads = async fileId => {
    const data = {
      file_id: Number(fileId),
      company_id : companyId,
    }

    try {
      //const res = await axiosInstance.post(`/engage/enrich_csv/${userId}`, data);   
      const res = await axiosInstance.post(`/engage/upload_csv/${userId}`, data);  
      setIncludeDuplicates(true)
      const newPersona = {label: res.data.persona_name, value: res.data.persona_id};
      setPersonas([newPersona, ...personas]);
      form1.setFieldValue("persona_id", res.data.persona_id);
      fetchCopies()
      generateCopyForm.setFieldValue("persona_id", res.data.persona_id);

      disableGenerateCopy.current = false;
      notification.success({message: 'Success', description: 'Leads are being uploaded in the background'});
      // if (res.data.missed_list.length > 0) {
      //   if(res.data.missed_type === "email_address") {
      //     Modal.confirm({
      //     title: <div style={{ color: "#ffffff" }}>Add prospects</div>,
      //     content: (
      //       <div style={{ color: "#ffffff" }}>
      //         <div>
      //           Following prospects could not be enriched. Do you still wish to add them to the campaigns?
      //         </div>
      //         <div>
      //           <ol>
      //             {res.data.missed_list.map((email, index) => (
      //               <li key={index}>{email}</li>
      //             ))}
      //           </ol>
      //         </div>
      //       </div>
      //     ),
      //     okText: 'OK',
      //     cancelText: 'Cancel',
      //     onOk: async () => {
      //       try {
      //         const payload= {
      //           company_id: companyId,
      //           emails_list: res.data.missed_list,
      //           file_id: res.data.file_id,
      //           persona_id: res.data.persona_id
      //         }
      //         const response = await axiosInstance.post(`/engage/non_enrich/lead/${userId}`, payload);
      //         if (response.status === 200) {
      //           setTimeout(async () => {
      //             notification.success({ message: 'Success', description: 'Leads added successfully' });
      //           }, 1000);           
      //         }
      //         else {
      //           notification.error({message: 'Error', description: 'Error in adding leads'});
      //         }
      //       } catch (error) {
      //         notification.error({message: 'Error', description: 'Error in adding leads'});
      //       }
      //     },
      //   });
      // } else if(res.data.missed_type === "linkedin_URL"){
      //   Modal.info({
      //     title: <div style={{ color: "#ffffff" }}>LinkedIn URLs</div>,
      //     content: (
      //       <div style={{ color: "#ffffff" }}>
      //         <div>
      //           No emails were found for the following leads so they were removed from your lead list.
      //         </div>
      //         <div>
      //           <ol>
      //             {res.data.missed_list.map((email, index) => (
      //               <li key={index}>{email}</li>
      //             ))}
      //           </ol>
      //         </div>
      //       </div>
      //     ),
      //     okText: 'OK',
      //   });
      // }
      // } 
      // else {
      //   notification.success({message: 'Success', description: 'File has been uploaded successfully'});
      // } 
      setImportCsvScreen(0);
      setShowUploadProspects(false);
		} catch (error) {
      // setShowTimeoutModal(true)
	  }
  }

  const triggerPostCsv = () => {
    document.querySelector('#post-csv-btn').click();
  }

  // const triggerGenerateCopySubmit = () => {
  //   document.querySelector(`#generate-copy-btn`).click();
  // }

  const saveGenerateCopy = async values => {
    setShowGenerateCopy(false);
    setLoading(1);

    const payload = {...values}
    payload.company_id = companyId;
    payload.persona_id = generateCopyForm.getFieldValue("persona_id");
    // if (generateCopyType === "blank") {
      payload.cta = "";
      payload.message_length = 0;
      payload.url = "";
    // }

    let url, newCopy;

    // if (generateCopyType === "ai") {
    //   url = `/copywriting/create_copy/${userId}`;
    // } else {
      url = `/copywriting/blank_copy/${userId}`;
    // }

    try {
			const response = await axiosInstance.post(url, payload);
      const newCopyName = campaignName.current + "_" + response.data.generated_copies[0].persona_name + "_blank_"
      + moment().format("DD_MMM_YYYY");
      newCopy = {label: newCopyName, value: response.data.copy_id};

      setCopies([newCopy, ...copies]);
      setCopyId(newCopy.value)
      // form1.setFieldValue("copy_id", newCopy.value);

      notification.success({message: 'Success', description: 'Copy has been created successfully'});
      await createCampaign(values, response.data.copy_id)
		} catch (err) {
      if (err.request || err.response) {
        notification.error({message: 'Error', description: 'Error in Creating the Copy'});
      } /* else {
        setShowTimeoutModal(true);
      } */
    }

    setLoading(0);

    // if (generateCopyType === "blank") {
      // setTimeout(() => document.querySelector(`#form1-submit-btn`).click(), 100);
    // }
  }

  const closeTimeoutModal = () => {
    setShowTimeoutModal(false);
  }

  const closeDuplicateModal = () => {
    setShowDuplicateModal(false);
  }

  // const closeGenerateCopyModal = () => {
  //   setGenerateCopyModalOpen(false);
  // }

  // const setGenerationType = type => {
  //   setGenerateCopyType(type);
  //   setGenerateCopyModalOpen(false);
  //   setShowGenerateCopy(true);
  // }

  const initGenerateModal = (values) => {
    let proceed, error;

    if(campaignName.current.length < 1) {
      proceed = false;
      error = "Please enter campaign name and click the arrow next to it";
    } else if (disableGenerateCopy.current) {
      proceed = false;
      error = "Please select a Persona";
    } else {
      proceed = true;
    }
    if (proceed) {
      // setGenerateCopyModalOpen(true);
      saveGenerateCopy(values)
    } else {
      notification.error({message: 'Error', description: error});
    }
  }

  const getAccountsList = async () => {
    // setLoading(1);

    try {
      const response = await axiosInstance.get(`/engage/accounts_list/${userId}?company_id=${companyData.company_id}&inbox_accounts=true`);
      setEmailList(response.data);
    } catch {
      notification.error({message: 'Error', description: 'Error in fetching email list'});
    }
    // setLoading(0);
  }
  const fetchCampaigns = async () => {
    try {
        const response = await axiosInstance.get(
            `/engage/all_campaign_list/${userId}?company_id=${companyId}`
        );
        if (response.status === 200) {

            const options = [];
            response.data.forEach((campaign) => {
                
                    const option = {
                        label: campaign.campaign_name,
                        value: campaign.campaign_id,
                    };

                    options.push(option);
                
            });
            setCampaignList(options);
        }

    } catch (error) {
        notification.error({
            message: "Error",
            description: "Error in fetching the Accounts",
        });
    }
};

const importCampaign = async (value) => {
  Modal.confirm({
      title: (<div style={{ color: "#ffffff" }}>Import Sequence from Campaign</div>),
      content: (<div style={{ color: "#ffffff" }}>This will override any existing text. Are you sure you want to import this sequence?</div>),
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {     
        try {
          const payload = {
              selected_campaign_id : value,
              campaign_id: campaignId.current,
              campaign_name: campaignList.filter(i => i.value === value)[0]?.label,
              company_id: companyId
          }
      const response = await axiosInstance.post(
          `/v2_engage/import_campaign/${userId}`, payload
      );
      if (response.status === 200) {
        console.log("response", response.data)
        setEmailCopies(response.data)
        setEditCopy(false)
      }

  } 
  catch (error) {
      notification.error({
          message: "Error",
          description: "Error in importing the campaign",
      });
  }
}})
};

const dropdownOptions = [
  {label : "First Name", value: "{{first_name}}"},
  {label : "Last Name", value: "{{last_name}}"},
  {label : "Email", value: "{{email}}"},
  {label : "Phone Number", value: "{{phone_number}}"},
  {label : "Company Name", value: "{{company_name}}"},
  {label : "Website", value: "{{website}}"},
  {label : "LinkedIn Profile", value: "{{linkedin_profile}}"},
  {label : "Location", value: "{{location}}"},
  {label : "Role", value: "{{role}}"},
  {label : "Day of week", value: "{{sl_day_of_week}}"},
  {label : "Time of Day", value: "{{sl_time_of_day}}"},
  {label : "Time of Day Cap", value: "{{sl_time_of_day_cap}}"},
 ];

 const handleInputChange = (e) => {
   if (isNewVariant.current === true && newVariantDetails) {
     let details = newVariantDetails;
     let lastIndex = newVariantDetails.variants.length - 1;
     details.variants[lastIndex].variant_subject = e.target.value;
   }
 };
 const handleInputClick = (event) => {
   setCursorPosition(event.target.selectionStart);
 };

 const handleDropdownChange = (value) => {
  setSelectedVariable(value)
   const currentInputValue = inputRef.current.input.value;
   const newValue = 
     currentInputValue.slice(0, cursorPosition) +
     value +
     currentInputValue.slice(cursorPosition);
   editCopyForm.setFieldValue('subject', newValue)
   setCursorPosition(cursorPosition + value.length);

   // Move the cursor to the end of the inserted text
   setTimeout(() => {
     inputRef.current.focus();
     inputRef.current.setSelectionRange(cursorPosition + value.length, cursorPosition + value.length);
   }, 0);
 };

  useEffect(() => {
    if (pageLoaded.current) {
      return;
    }
    
    pageLoaded.current = true;
    getAccountsList()
    fetchPersonas()
    fetchCampaigns()
  }, []);

  useEffect(() => {
    if (loading === 1 || !editor) {
      return;
    }

    insertLineBreak(editor);
  }, [editor]);

  useEffect(() => {
    if (location?.state !== null) {
      fetchCopies(location?.state?.selectedProspect)
      setLeadIdsSelected(location?.state?.leadIds)
    }
  }, [location?.state]);

  return (
    <Layout style={{minHeight: '100vh', padding: 15}} className="bootstrap">
      {/* Header */}
      <Row gutter={30} className="pb-3">
        <Col span={16} className="gutter-row d-flex justify-content-between align-items-center">
          <div className="analytics-heading font-weight-500 px-2">New Campaign</div>

          {/* {currentPage === 2 &&
            <div className="lead-status-btn inactive d-flex align-items-center mx-2 pointer">
              <CheckCircleOutlined />
              <span
                className="text-nowrap ps-2 pointer"
                onClick={() => {
                  setAdditionalEmails(true);
                  setImproveCopy(false);
                  setCopyProgress(false);
                }}
              >Add Additional Emails</span>
            </div>
          } */}
        </Col>

        <Col span={8} className="gutter-row d-flex align-items-center justify-content-end">
          { currentPage !== 2 && <Button
            type="primary"
            icon={<UnorderedListOutlined />}
            className="br-13"
            onClick={() => navigate("/engage/campaign-list")}
          >
            View Campaigns
          </Button> }

         {currentPage === 2 &&  <Select
            placeholder="Import Sequence from Campaign"
            options={campaignList}
            className="custom-select mx-2"
            style={{width: '500px'}}
            onChange={(value) => {
              importCampaign(value)
            }}
          /> }
        </Col>
      </Row>

      {/* Main */}
      <Row gutter={30} id="engage-account-main" align="stretch">
        {currentPage <= 2 &&
          <Col span={16} className="gutter-row">
            {/* Loader */}
            {renderLoader()}

            {(loading === 0 && currentPage < 3) &&
              <Card className="custom-card h-100 p-4">
                {/* Page 1 */}
                <div id="page-1" className={currentPage === 1 ? "" : "d-none"}>
                  {/* Title */}
                  <Form
                    form={form1}
                    onFinishFailed={() => campaignName.current.length < 1 ? setCampaignNameEdit(true) : ""}
                    onFinish={initGenerateModal}
                  >
                    {renderFormTitle()}

                    <Row className="pt-4">
                      <Col span={24} className="pb-2 fw-semibold">Choose a Lead List:</Col>
                      <Col span={24} className="d-flex align-items-center">
                        <Form.Item
                          className="pb-0"
                          name="persona_id"
                          initialValue={personas.filter(i => i.value === location?.state?.selectedProspect)[0]?.label}
                          rules={[
                            { 
                              required: true,
                              message: "Please select a lead list"
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            className="custom-select"
                            options={personas}
                            style={{width: '500px'}}
                            onChange={fetchCopies}
                          />
                        </Form.Item>
                        {/* <span className="px-4">or</span>
                        <div
                          className="lead-status-btn find-leads d-flex align-items-center br-13 pointer"
                          onClick={() => setShowUploadProspects(true)}
                        >
                          <img src="/assets/images/export-black-2x.png" width="auto" height="20" alt="edit" />
                          <span className="text-nowrap text-dark fw-semibold ps-2">Upload CSV</span>
                        </div> */}
                      </Col>
                    </Row>

                    {/* <Row className="pt-4">
                      <Col span={24} className="pb-2 fw-semibold">Choose a sequence Copy:</Col>
                      <Col span={24} className="d-flex align-items-center">
                        <Form.Item
                          className="pb-0"
                          name="copy_id"
                          rules={[
                            { 
                              required: true,
                              message: "Please select a Sequence copy"
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            className="custom-select"
                            options={copies}
                            style={{width: '500px'}}
                          />
                        </Form.Item>
                        <span className="px-4">or</span>
                        <div
                          className={"lead-status-btn generate-copy d-flex align-items-center br-13 pointer"}
                          onClick={initGenerateModal}
                        >
                          <img src="/assets/images/edit-white-2x.png" width="auto" height="15" alt="edit" />
                          <span className="text-nowrap ps-2">Blank Template</span>
                        </div>
                      </Col>
                    </Row> */}
                    <Button htmlType="submit" className="d-none" id="form1-submit-btn" />
                  </Form>
                </div>

                {/* Page 2 */}
                <div id="page-2" className={currentPage === 2 ? "" : "d-none"}>
                  {emailCopies?.map((copy, index) => {
                    const copyNo = index + 1;

                    return (
                      <div key={copyNo}>
                        {copy?.variants?.map((variant, index_2) => {
                          const variantNo = index_2 + 1;
                          const key = copyNo + "-" + variantNo;
                          // const scorePercent = Math.round(variant.score.score_percentage);
                          return (
                            <div key={key} className={currentCopy === key ? "" : "d-none"}>
                              <Row>
                                <Col span={18}>{renderFormTitle()}</Col>
                                {/* <Col
                                  span={6}
                                  className={"custom-progress" + (generateCopyType === "blank" ? " d-none" : "")}
                                >
                                  <div className='d-flex align-items-center'>
                                    <Progress
                                      percent={scorePercent}
                                      status="active"
                                      className='mb-0'
                                      showInfo={false}
                                      strokeColor={scorePercent > 50 ? (scorePercent > 74 ? "#3EDF23" : "#FFA41B") : "#DD2525"}
                                    />
                                    <InfoCircleOutlined
                                      className="text-white small pointer"
                                      onClick={() => {
                                        copyWriteScore.current = variant.score;
                                        setAdditionalEmails(false);
                                        setImproveCopy(false);
                                        setCopyProgress(true);
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{width: "50%"}}
                                    className="font-weight-400 font-size-10 d-flex align-items-center justify-content-between">
                                    <span className="pr-2">Score</span>
                                    <span>{scorePercent}%</span>
                                  </div>
                                </Col> */}
                              </Row>

                              <Row className={"pt-4" + (editCopy ? " d-none" : "")}>
                                <Col span={24}>
                                  <div className="d-inline-block black-highlight fw-semibold p-1 mb-4">Email copy {copyNo}</div>
                                  <div className="copywriting-subject fw-semibold pb-4">Subject: {variant.variant_subject}</div>
                                  <div
                                    className="copywriting-body small"
                                    dangerouslySetInnerHTML={{__html: variant.variant_message}}
                                  />
                                </Col>
                                <Button
                                  className="d-none"
                                  id={"edit-copy-btn-" + key}
                                  onClick={() => initiateEditCopy(copy.seq_id, variant)}
                                />
                              </Row>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}

                  <Row className={"pt-4" + (editCopy ? "" : " d-none")}>
                    <Col span={24}>
                      <Form form={editCopyForm} onFinish={editVariant} className="copywriting-form">
                        <Form.Item name="seq_id" className="d-none">
                          <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item name="var_id" className="d-none">
                          <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item name="variant_name" className="d-none">
                          <Input className="custom-input" />
                        </Form.Item>
                        <Row className="d-flex flex-row me-2">
                        <Col span={18}>
                        <Form.Item
                          name="subject"
                          rules={[
                            {
                              validator: (rule, value) => {
                                const isSubjectRequired = emailCopies.length + 1 <= 1 || currentCopy.split('-')[0] === "1";
                                if (isSubjectRequired && !value) {
                                  return Promise.reject("Please enter a subject");
                                }
                        
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                        <Input className="custom-input" placeholder={emailCopies.length + 1 <= 1 || currentCopy.split('-')[0] === "1" ? 
                            "Enter subject" 
                            : "Leave subject blank to thread response. Adding a subject will create a new email thread."
                            } 
                            onChange={handleInputChange}
                            onClick={handleInputClick}
                            onKeyUp={handleInputClick}
                            ref={inputRef}
                            />
                        </Form.Item></Col>
                       <Col span={6} >
                          <Select
                            style={{ width: 190 }}
                            onChange={handleDropdownChange}
                            placeholder="Insert Variable"
                            value={selectedVariable}
                            className="ms-2"
                          >
                            {dropdownOptions.map((option, index) => (
                              <Option key={index + 1} value={option.value}>{option.label}</Option>
                            ))}
                          </Select></Col>
                        </Row>
                        {editCopy &&
                          <>
                            <Editor
                              defaultConfig={editorConfig}
                              value={html}
                              onCreated={setEditor}
                              onChange={editor => setHtmlContent(editor.getHtml(), editor.getText())}
                              mode="default"
                              className="wang-editor wang-copywriting-editor br-10"
                              style={{height: '400px', overflowX: 'hidden', overflowY: 'auto'}}
                            />
                            <Toolbar
                              editor={editor}
                              defaultConfig={toolbarConfig}
                              mode="default"
                              className="wang-toolbar wang-copywriting-toolbar"
                            />
                          </>
                        }
                        <Form.Item
                          name="body"
                          rules={[
                            { 
                              required: true,
                              message: "Please enter your message"
                            },
                          ]}
                          className="copywriting-email-text"
                        >
                          <Input className="custom-input2" />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" className="ms-3" id="form2-save-var-btn">Save Variant</Button>
                      </Form>
                    </Col>
                  </Row>

                  <Row className="pt-4">
                    <Col span={24} className="d-flex align-items-center">
                      <div
                        className="lead-status-btn copy d-flex align-items-center pointer br-13 me-2"
                        onClick={() => { 
                            if(editCopy) {
                              notification.error({message: 'Error', description: 'Please save the current variant first'})
                            }
                            else {triggerEditCopy() }}}
                        >
                        <img src="/assets/images/settings-white-2x.png" width="15" height="auto" alt="settings" />
                        <span className="text-nowrap ps-2">Edit</span>
                      </div>
                      {/* {generateCopyType === "blank"? null :
                      <div
                        className="lead-status-btn generate d-flex align-items-center br-13 pointer me-2"
                        onClick={() => {
                          setImproveCopy(true);
                          setAdditionalEmails(false);
                          setCopyProgress(false);
                        }}
                      >
                        <img src="/assets/images/stars-white-2x.png" width="auto" height="24" alt="stars" />
                         <span className="text-nowrap ps-2">Improve Copy</span> 
                      </div> } */}
                      <div
                        className="lead-status-btn inactive d-flex align-items-center br-13 pointer"
                        onClick={ () => { if(editCopy) {
                          notification.error({message: 'Error', description: 'Please save the current variant first'})
                        }
                        else {
                          addEmailStep()
                        }}}
                      >
                        <PlusCircleOutlined style={{height: "24px"}} />
                        <span className="ps-2">Add new step</span>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* Pagination */}
                <div id="pagination" className="pt-4 d-flex justify-content-between align-items-center">
                  <div className="pagination-left d-flex align-items-center position-relative">
                    <span
                      className={"inverted-pagination-arrow position-absolute"
                      + (currentPage === 1 ? " text-secondary disabled-upload" : " pointer")}
                      {...(currentPage !== 1 && {onClick: prevPage})}
                    >➞</span>
                    <span className={"pe-3 right-arrow"
                      + (currentPage === 3 ? " text-secondary disabled-upload" : " pointer")}
                      {...(currentPage !== 3 && {onClick: nextPage})}
                    >➞</span>
                    <span className="text-secondary small">Page {currentPage} of 3</span>
                  </div>

                  {currentPage === 1 &&
                    <div
                      className="pagination-right d-flex align-items-center continue pointer"
                      onClick={() => {
                        if (campaignName.current.length === 0) {
                          notification.error({message: 'Error', description: 'Please enter campaign name and click the arrow next to it'})
                        } else {
                          nextPage()
                        }
                      }}
                    >
                      <span className="text-secondary small pe-2">Continue</span>
                      <div className="arrow-parent">➜</div>
                    </div>
                  }
                </div>
              </Card>
            }
          </Col>
        }

        {(loading === 0 && currentPage === 2) &&
          <Col span={8} className="gutter-row">
            {/* Tooltip */}
            <div className={"h-100 d-flex flex-column justify-content-between"
              + ((additionalEmails === false && improveCopy === false && copyProgress === false) ? "" : " d-none")}>
              <div></div>
              <div>
                <img src="/assets/images/jet-blue-2x.png" width="24" height="auto" alt="jet" />
                <div className="fw-semibold text-white py-2">Review the copy and click continue.</div>
                <div className="text-secondary small">You can edit your copy directly, update<br />
                any personal info such as your signature<br />by clicking "Edit."</div>
              </div>
              <div className="d-flex justify-content-end pt-4">
                <div
                  className="pagination-right d-flex align-items-center continue pointer"
                  onClick={nextPage}
                >
                  <span className="text-secondary small pe-2">Continue</span>
                  <div className="arrow-parent">➜</div>
                </div>
              </div>
            </div>

            {/* Improve Copy */}
            <div className={"h-100 improve-copy-black flex-column justify-content-center "
              + (improveCopy ? "d-flex" : " d-none")}
            >
              <img src="/assets/images/persona-ai-logo-small.png" width="60" height="auto" alt="AI logo"  className="mb-2" />
              <div className="custom-card text-white w-100 p-4 br-13">
                <div className="d-flex justify-content-end fw-semibold pb-4">
                  <span
                    className="close-btn d-flex align-items-center justify-content-center pointer"
                    onClick={() => setImproveCopy(false)}
                  >X</span>
                </div>

                <Form form={improveCopyForm} onFinish={improveVariant}>
                  <div className="fw-semibold fs-5">Change the tone</div>
                  <div className="d-flex align-items-center pt-3 pb-4 flex-wrap">
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                      + (improveCopyTone === "casual" ? " active" : "")}
                      onClick={() => setImproveCopyTone("casual")}
                    >Casual</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                      + (improveCopyTone === "friendly" ? " active" : "")}
                      onClick={() => setImproveCopyTone("friendly")}
                    >Friendly</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                      + (improveCopyTone === "professional" ? " active" : "")}
                      onClick={() => setImproveCopyTone("professional")}
                    >Professional</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                      + (improveCopyTone === "humorous" ? " active" : "")}
                      onClick={() => setImproveCopyTone("humorous")}
                    >Humorous</div>
                  </div>
                  <div
                    class={"pb-4" + (improveCopyToneError ? "" : " d-none")}
                    style={{color: "#dc4446", marginTop: "-1rem"}}
                  >Please select a tone</div>

                  <div className="fw-semibold fs-5">Change the length</div>
                  <div className="d-flex align-items-center pt-3 pb-4 flex-wrap">
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                      + (improveCopyLength === "shorter" ? " active" : "")}
                      onClick={() => setImproveCopyLength("shorter")}
                    >Shorter</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3 mb-2"
                      + (improveCopyLength === "longer" ? " active" : "")}
                      onClick={() => setImproveCopyLength("longer")}
                    >Longer</div>
                  </div>
                  <div
                    class={"pb-4" + (improveCopyLengthError ? "" : " d-none")}
                    style={{color: "#dc4446", marginTop: "-1rem"}}
                  >Please select a length</div>

                  <div className="fw-semibold fs-5">Switch Call to Action</div>
                  <Form.Item
                    name="cta"
                    className="pt-3 pb-4"
                    rules={[
                      { 
                        required: true,
                        message: "Please select Call to Action"
                      },
                    ]}
                  >
                    <Select
                      className="custom-select3 w-75"
                      placeholder="Call To Action"
                      suffixIcon={<DropdownArrowWhite />}
                      options={[                        {value: 'view_demo', label: 'View a Demo Video'},
                        {value: 'schedule_meeting', label: 'Schedule a Meeting'},
                        {value: 'event_signup', label: 'Event Sign-Up'},
                        {value: 'product_signup', label: 'Product Sign-Up'},
                        {value: 'user_research', label: 'User Research'}
                      ]}
                    />
                  </Form.Item>

                  <div className="fw-semibold fs-6 py-3">Describe what you want to improve:</div>
                  <Form.Item
                    name="user_input"
                    className="pt-3 pb-4"
                    rules={[
                      { 
                        required: true,
                        message: "Please enter a message"
                      },
                    ]}
                  >
                    <TextArea
                      className="custom-copy-improve"
                      rows={6}
                    />
                  </Form.Item>
                  <Button htmlType="submit" id="regenerate-form-btn" className="d-none" />
                </Form>

                <div className="d-flex justify-content-center pt-4">
                  <div
                    className="lead-status-btn generate br-13 d-flex align-items-center pointer"
                    onClick={triggerRegenerateFormSubmit}
                  >
                    <img src="/assets/images/stars-white-2x.png" width="auto" height="24" alt="stars" />
                    <span className="text-nowrap ps-2">Regenerate</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Scores */}
            <div
              className={"h-100 improve-copy-black flex-column justify-content-center "
              + (copyProgress ? "d-flex" : " d-none")}
            >
              <img src="/assets/images/persona-ai-logo-small.png" width="60" height="auto" alt="AI logo"  className="mb-2" />
              <div className="custom-card text-white w-100 p-4 br-13">
                <div className="d-flex justify-content-between fw-semibold pb-4">
                  <span className='copywrite-score-text'>Copywriting Score</span>
                  <span
                    className="close-btn d-flex align-items-center justify-content-center pointer"
                    onClick={() => setCopyProgress(false)}
                  >X</span>
                </div>
                <div
                  className="mb-3 copy-text"
                  style={{
                    color: copyWriteScore.current.total_score > 8
                    ? (copyWriteScore.current.total_score > 12 ? "#3EDF23" : "#FFA41B")
                    : "#DD2525"
                  }}
                >
                    {`${getChance(copyWriteScore.current.total_score)} chance to receive a response.`}
                </div>

                <div className="copy-header-txt">Subject Length</div>
                <div
                  className="custom-progress copy-progress-cnt"
                  style={{width: `${getWidthPercent(copyWriteScore.current.subject_length, 18)}%`}}
                >
                  {copyWriteScore.current.subject_length}
                </div>
                <Progress
                  percent={`${getWidthPercent(copyWriteScore.current.subject_length, 18)}`}
                  status="active"
                  className='mb-4'
                  showInfo={false}
                  strokeColor={`${getSubjectLength(copyWriteScore.current.subject_length)}`}
                />

                <div className="copy-header-txt">Word Count</div>
                <div
                  className="custom-progress copy-progress-cnt"
                  style={{width: `${getWidthPercent(copyWriteScore.current.word_count, 600)}%`}}
                >
                  {copyWriteScore.current.word_count}
                </div>
                <Progress
                  percent={`${getWidthPercent(copyWriteScore.current.word_count, 600)}`}
                  status="active"
                  className='mb-4'
                  showInfo={false}
                  strokeColor={`${getWordCount(copyWriteScore.current.word_count)}`}
                />

                <div className="copy-header-txt">Question Count</div>
                <div
                  className="custom-progress copy-progress-cnt"
                  style={{width: `${getWidthPercent(copyWriteScore.current.question_count, 3)}%`}}
                >
                  {copyWriteScore.current.question_count}
                </div>
                <Progress
                  percent={`${getWidthPercent(copyWriteScore.current.question_count, 3)}`}
                  status="active"
                  className='mb-4'
                  showInfo={false}
                  strokeColor={`${getQuestionCount(copyWriteScore.current.question_count)}`}
                />

                <div className="copy-header-txt">Reading Level</div>
                <div
                  className="custom-progress copy-progress-cnt"
                  style={{width: `${getWidthPercent(copyWriteScore.current.reading_level, 21)}%`}}
                >
                  {copyWriteScore.current.reading_level}
                </div>
                <Progress
                  percent={`${getWidthPercent(copyWriteScore.current.reading_level, 21)}`}
                  status="active"
                  className='mb-4'
                  showInfo={false}
                  strokeColor={`${getReadingLevel(copyWriteScore.current.reading_level)}`}
                />

                <div className="copy-header-txt">Spam words</div>
                <div
                  className="custom-progress copy-progress-cnt"
                  style={{width: `${getWidthPercent(copyWriteScore.current.spam_words, 8)}%`}}
                >
                  {copyWriteScore.current.spam_words}
                </div>
                <Progress
                  percent={`${getWidthPercent(copyWriteScore.current.spam_words, 8)}`}
                  status="active"
                  className='mb-4'
                  showInfo={false}
                  strokeColor={`${getSpamWords(copyWriteScore.current.spam_words)}`}
                />

                <div className="copy-header-txt">Number of links</div>
                <div
                  className="custom-progress copy-progress-cnt"
                  style={{width: `${getWidthPercent(copyWriteScore.current.no_of_links, 4)}%`}}
                >
                  {copyWriteScore.current.no_of_links}
                </div>
                <Progress
                  percent={`${getWidthPercent(copyWriteScore.current.no_of_links, 4)}`}
                  status="active"
                  className='mb-4'
                  showInfo={false}
                  strokeColor={`${getNoOfLinks(copyWriteScore.current.no_of_links)}`}
                />

                {/*
                <div className="d-flex justify-content-center pt-4">
                  <div className="lead-status-btn mtng_booked d-flex align-items-center pointer generate" onClick={improveCopy}>
                    <img src="/assets/images/stars-white-2x.png" width="auto" height="24" alt="stars" />
                    <span className="text-nowrap ps-2">Fix copy</span>
                  </div>
                </div>
                */}
              </div>
            </div>

            {/* Additional Emails */}
            <div className={"h-100 flex-column justify-content-between " + (additionalEmails ? "d-flex" : " d-none")}>
              <div>
                <Form onFinish={createSequence} id="additional-emails-container">
                  {emailCopies?.map((step, index) => {
                    const copyNo = index + 1;
                    return (
                      <div className="step-block" key={copyNo}>
                        {index === 0 &&
                          <>
                            <Form.Item name="campaign_id" initialValue={step.engage_campaign_id} className="d-none">
                              <Input />
                            </Form.Item>
                            <Form.Item name="seq_length" initialValue={emailCopies.length} className="d-none">
                              <Input />
                            </Form.Item>
                          </>
                        }
                        <div className={"text-white align-items-center pb-2 " + (index === 0 ? "d-none" : "d-flex")}>
                          <span>Wait for </span>
                          <Form.Item
                            name={`delay_days_${index}`}
                            initialValue={Number(delayDays?.["delay_days_" + index] || 1)}
                            // initialValue={1}
                            className="pb-0"
                            style={{width: "50px"}}
                          >
                            <Input className="custom-input mx-2" onChange={(e) => {
                              const updatedDelayDays = { ...delayDays, [`delay_days_${index}`]: e.target.value };
                              setDelayDays(updatedDelayDays);
                            }} />
                          </Form.Item>
                          <span className="ms-3">days then send:</span>
                        </div>
                        <div className="custom-card text-white w-100 p-3 br-13 mb-4">
                          <div className="d-flex align-items-center justify-content-between pb-3">
                            <span className="step-count fw-semibold">Step {copyNo}</span>
                            {isViewer || emailCopies?.length === 1 ? null :
                              <img
                                src="/assets/images/delete-gray-2x.png"
                                width="20"
                                height="auto"
                                alt="delete"
                                className="pointer"
                                onClick={() => {
                                  if (editCopy && isNewVariant.current) {
                                    Modal.confirm({
                                      title: (<div style={{ color: "#ffffff" }}>Save/Delete Variant?</div>),
                                      content: (<div style={{ color: "#ffffff" }}>Would you like to save or delete the unsaved variant?</div>),
                                      okText: (newvariantStep === 0 && 
                                        newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                        newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                        (newvariantStep !== 0 && 
                                          newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")? 'Save' : "Go Back",
                                        cancelText: 'Delete',
                                        onOk: async () => {
                                          if((newvariantStep === 0 && 
                                            newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                            newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                            (newvariantStep !== 0 && 
                                              newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")) {
                                                editVariant(null, newVariantDetails)
                                              }
                                              else {
                                                setCurrentCopy((newvariantStep + 1 ) + '-' + (newVariantDetails.variants.length))
                                                setEditCopy(true)
                                              }
                                      },
                                      onCancel: async () => {
                                        let updatedEmailCopies = [...emailCopies];
                                        if (updatedEmailCopies[newvariantStep]?.variants.length < 2) {
                                          updatedEmailCopies.splice(newvariantStep, 1)
                                        }
                                        else {
                                          updatedEmailCopies[newvariantStep] = {
                                            ...updatedEmailCopies[newvariantStep],
                                            variants: updatedEmailCopies[newvariantStep]?.variants.slice(0, -1),
                                          };
                                        }
                                        setEmailCopies([...updatedEmailCopies])
                                        setCurrentCopy("1-1");
                                        setEditCopy(false)
                                      }
                                    })
                                  }
                                  else {
                                  deleteStep(index, step.seq_id)}} }
                              />
                            }
                          </div>
                          {step?.variants?.map((variant, index_2) => {
                            const variantNo = index_2 + 1;
                            return (
                              <div
                                className={"d-flex align-items-center justify-content-between px-3 py-2 mb-3 pointer variant-row"
                                + (currentCopy === `${copyNo}-${variantNo}` ? " active" : "")}
                                key={variantNo}
                                >
                                <div className="d-flex align-items-center"
                                  onMouseUp={() => {
                                    if (editCopy && isNewVariant.current) {
                                      Modal.confirm({
                                        title: (<div style={{ color: "#ffffff" }}>Save/Delete Variant?</div>),
                                        content: (<div style={{ color: "#ffffff" }}>Would you like to save or delete the unsaved variant?</div>),
                                        okText: (newvariantStep === 0 && 
                                          newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                          newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                          (newvariantStep !== 0 && 
                                            newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")? 'Save' : "Go Back",
                                        cancelText: 'Delete',
                                        onOk: async () => {
                                          if((newvariantStep === 0 && 
                                            newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                            newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                            (newvariantStep !== 0 && 
                                              newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")) {
                                                editVariant(null, newVariantDetails)
                                              }
                                              else {
                                                setCurrentCopy((newvariantStep + 1 ) + '-' + (newVariantDetails.variants.length))
                                                setEditCopy(true)
                                              }
                                        },
                                        onCancel: async () => {
                                          let updatedEmailCopies = [...emailCopies];
                                          if (updatedEmailCopies[newvariantStep]?.variants.length < 2) {
                                            updatedEmailCopies.splice(newvariantStep, 1)
                                          }
                                          else {
                                            updatedEmailCopies[newvariantStep] = {
                                              ...updatedEmailCopies[newvariantStep],
                                              variants: updatedEmailCopies[newvariantStep]?.variants.slice(0, -1),
                                            };
                                          }
                                          setEmailCopies([...updatedEmailCopies])
                                          setCurrentCopy("1-1");
                                          setEditCopy(false)
                                        }
                                      })
                                    }
                                    else {
                                      setCurrentCopy(copyNo + "-" + variantNo);
                                    }
                                  }}>
                                  <img src="/assets/images/mail-blue-2x.png" width="20" height="auto" alt="mail" />
                                  <span className="fw-semibold ps-2">Variant {variantNo}</span>
                                </div>
                                {isViewer || step?.variants?.length === 1 ? null :
                                  <Switch checked={variant.row_status!== null && variant.row_status!== undefined ? variant.row_status : false} 
                                  disabled={(emailCopies?.length === 1 && variant.row_status === true && (
                                   step?.variants?.filter(i => i.row_status === true).length <= 1
                                 ))}   
                                  // src="/assets/images/close-gray-2x.png"
                                    // width="20"
                                    // height="auto"
                                    // alt="close"
                                    // className="pointer"
                                    onChange={(checked, e) => {
                                      e.stopPropagation();
                                      if (editCopy && isNewVariant.current) {
                                        Modal.confirm({
                                          title: (<div style={{ color: "#ffffff" }}>Save/Delete Variant?</div>),
                                          content: (<div style={{ color: "#ffffff" }}>Would you like to save or delete the unsaved variant?</div>),
                                          okText: (newvariantStep === 0 && 
                                            newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                            newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                            (newvariantStep !== 0 && 
                                              newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")? 'Save' : "Go Back",
                                          cancelText: 'Delete',
                                          onOk: async () => {
                                            if((newvariantStep === 0 && 
                                              newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "" && 
                                              newVariantDetails.variants[newVariantDetails.variants.length-1].variant_subject !== "" )||
                                              (newvariantStep !== 0 && 
                                                newVariantDetails.variants[newVariantDetails.variants.length-1].variant_message !== "")) {
                                                  editVariant(null, newVariantDetails)
                                                }
                                                else {
                                                  setCurrentCopy((newvariantStep + 1 ) + '-' + (newVariantDetails.variants.length))
                                                  setEditCopy(true)
                                                }
                                          },
                                          onCancel: async () => {
                                            let updatedEmailCopies = [...emailCopies];
                                            if (updatedEmailCopies[newvariantStep]?.variants.length < 2) {
                                              updatedEmailCopies.splice(newvariantStep, 1)
                                            }
                                            else {
                                              updatedEmailCopies[newvariantStep] = {
                                                ...updatedEmailCopies[newvariantStep],
                                                variants: updatedEmailCopies[newvariantStep]?.variants.slice(0, -1),
                                              };
                                            }
                                            setEmailCopies([...updatedEmailCopies])
                                            setCurrentCopy("1-1");
                                            setEditCopy(false)
                                          }
                                        })
                                      }
                                      else { deleteVariant(index, index_2, variant.id, checked) }
                                    }}
                                  />
                                }
                              </div>
                            )
                          })}
                          <div className="d-flex justify-content-end">
                            <div
                              className="lead-status-btn d-flex align-items-center pointer"
                              onClick={() => { 
                                if(editCopy) {
                                  notification.error({message: 'Error', description: 'Please save the current variant first'})
                                }
                                else {
                                addVariant(copyNo) 
                              }}}
                            >
                              <CheckCircleOutlined />
                              <span className="text-nowrap ps-2">Add Variant</span>
                            </div>
                          </div>
                        </div>
                        <Form.Item name={`seq_id_${index}`} initialValue={step.seq_id} className="d-none">
                          <Input />
                        </Form.Item>
                      </div>
                    )
                  })}
                  <Button type="primary" htmlType="submit" id="form2-submit-btn" className="d-none">Save</Button>
                </Form>
              </div>

              <div className="d-flex justify-content-end pt-4">
                <div
                  className="pagination-right d-flex align-items-center continue pointer"
                  onClick={() => {
                    if (editCopy && emailCopies.length === 1 && emailCopies[0].variants.length === 1) {
                      if (editCopyForm.getFieldValue("subject") === "" || editCopyForm.getFieldValue("body") === "") {
                        Modal.confirm({
                          title: (<div style={{ color: "#ffffff" }}>Edit/Delete Variant?</div>),
                          content: (<div style={{ color: "#ffffff" }}>Would you like to edit or delete the unsaved variant?</div>),
                          okText: 'Delete',
                          cancelText: 'Go back',
                          onOk: async () => {
                            nextPage()
                          },
                        })
                      }
                      else {
                        Modal.confirm({
                          title: (<div style={{ color: "#ffffff" }}>Save/Delete Variant?</div>),
                          content: (<div style={{ color: "#ffffff" }}>Would you like to save the unsaved variant?</div>),
                          okText: 'Save',
                          cancelText: 'Delete',
                          onOk: async () => {
                            await document.querySelector(`#form2-save-var-btn`).click();
                            nextPage()
                          },
                          onCancel: async () => {
                            nextPage()
                          }
                        })
                      }
                    }
                    else if (editCopy && isNewVariant.current) {
                      Modal.confirm({
                        title: (<div style={{ color: "#ffffff" }}>Save/Delete Variant?</div>),
                        content: (<div style={{ color: "#ffffff" }}>Would you like to {(newvariantStep === 0 &&
                          newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "" &&
                          newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_subject !== "") ||
                          (newvariantStep !== 0 &&
                            newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "") ? "save " : "edit "} or delete the unsaved variant?</div>),
                        okText: (newvariantStep === 0 &&
                          newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "" &&
                          newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_subject !== "") ||
                          (newvariantStep !== 0 &&
                            newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "") ? 'Save' : "Go Back",
                        cancelText: 'Delete',
                        onOk: async () => {
                          if ((newvariantStep === 0 &&
                            newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "" &&
                            newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_subject !== "") ||
                            (newvariantStep !== 0 &&
                              newVariantDetails.variants[newVariantDetails.variants.length - 1].variant_message !== "")) {
                            editVariant(null, newVariantDetails)
                            nextPage()
                          }
                          else {
                            setCurrentCopy((newvariantStep + 1) + '-' + (newVariantDetails.variants.length))
                            setEditCopy(true)
                          }
                        },
                        onCancel: async () => {
                          let updatedEmailCopies = [...emailCopies];
                          if (updatedEmailCopies[newvariantStep]?.variants.length < 2) {
                            updatedEmailCopies.splice(newvariantStep, 1)
                          }
                          else {
                            updatedEmailCopies[newvariantStep] = {
                              ...updatedEmailCopies[newvariantStep],
                              variants: updatedEmailCopies[newvariantStep]?.variants.slice(0, -1),
                            };
                          }
                          setEmailCopies([...updatedEmailCopies])
                          setCurrentCopy("1-1");
                          setEditCopy(false)
                          nextPage()
                        }
                      })
                    }
                    else {
                      nextPage()
                    }
                  }}
                >
                  <span className="text-secondary small pe-2">Continue</span>
                  <div className="arrow-parent">➜</div>
                </div>
              </div>
            </div>
          </Col>
        }

        {/* Page 3 */}
        {currentPage === 3 &&
          <Col span={24} className="gutter-row d-flex justify-content-center align-items-center">
            {/* Loader */}
            {renderLoader()}

            <div className={loading === 1 ? "d-none" : ""}>
              <div className="d-flex align-items-center pb-4">
                <div
                  className="pagination-right align-items-center back pointer position-relative me-2"
                  onClick={prevPage}
                >
                  <div className="arrow-parent position-absolute inverted-pagination-arrow">➜</div>
                </div>
                <span className="fs-6 text-secondary">Go Back</span>
              </div>
              <div className="improve-copy-black">
                <div className="custom-card text-white p-4 br-13 d-flex flex-column align-items-center">
                  <div className="fs-5 fw-semibold pb-4">Campaign Settings</div>
                  <div className="small text-secondary text-center pb-4 mb-4">This will determine how your campaign runs<br />
                  You'll be able to adjust these later on at anytime.
                  </div>

                  {/* <div className="w-100 fs-6 fw-semibold pb-3">Daily Sending Limit Per Campaign</div>
                  <div className="d-flex justify-content-center align-items-center pb-4 mb-4">
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3"
                      + (sendingLimit === 15 ? " active" : "")}
                      onClick={() => setSendingLimit(15)}
                    >15</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3"
                      + (sendingLimit === 30 ? " active" : "")}
                      onClick={() => setSendingLimit(30)}
                    >30</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3"
                      + (sendingLimit === 60 ? " active" : "")}
                      onClick={() => setSendingLimit(60)}
                    >60</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3"
                      + (sendingLimit === 90 ? " active" : "")}
                      onClick={() => setSendingLimit(90)}
                    >90</div>
                    <Input
                      id="sending-limit-input"
                      className="custom-input"
                      placeholder="enter custom amount"
                      value={sendingLimit}
                      onChange={(e) => setSendingLimit(Number(e.target.value))}
                    />
                  </div> */}

                  {/* <div className="w-100 fs-6 fw-semibold pb-3">Time gap between emails (minutes)</div>
                  <div className="d-flex justify-content-center align-items-center pb-4 mb-4">
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3"
                      + (minTimeBtwEmails === 20 ? " active" : "")}
                      onClick={() => setminTimeBtwEmails(20)}
                    >20</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3"
                      + (minTimeBtwEmails === 30 ? " active" : "")}
                      onClick={() => setminTimeBtwEmails(30)}
                    >30</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3"
                      + (minTimeBtwEmails === 45 ? " active" : "")}
                      onClick={() => setminTimeBtwEmails(45)}
                    >45</div>
                    <div
                      className={"white-outlined-btns fill-blue pointer me-3"
                      + (minTimeBtwEmails === 60 ? " active" : "")}
                      onClick={() => setminTimeBtwEmails(60)}
                    >60</div>
                    <Input
                      id="sending-limit-input"
                      className="custom-input"
                      placeholder="enter custom amount"
                      value={minTimeBtwEmails}
                      onChange={(e) => setminTimeBtwEmails(Number(e.target.value))}
                    />
                  </div> */}

                  <div
                    className={"justify-content-center mb-4 "
                    + (activateCampaignError ? "d-flex" : "d-none")}
                  >
                    <Tag color="red">Please select or enter valid options</Tag>
                  </div>

                  <div className="fs-5 fw-semibold pb-2">Select Emails to use for this Campaign</div>
                  <Select mode="multiple" className="mt-2" value={emailAccounts} 
                  style={{ maxHeight: '55px', overflowY: 'auto', width: "70%" }}
                  onChange={((e) => {
                  if (e.length > 0) {
                    setDefaultAllMail(false)
                  }
                  // if(e.length < 1) {
                  //   setDefaultAllMail(true)
                  // }
                  setEmailAccounts(e)
                })}>
                  {emailList.map((i) => (
                    <Option key={i.account_id} value={i.account_id}>{i.account_email}</Option>
                  ))}
                </Select>
                {/* <Checkbox className="d-flex mb-2 mt-2" disabled={emailAccounts.length <1} */}
                <Checkbox className="d-flex mb-2 mt-2"
                  checked={defaultAllMail}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setEmailAccounts([])
                    }
                    setDefaultAllMail(e.target.checked)
                  }}>
                  Send emails from all connected email addresses</Checkbox>
                  <div
                    className="lead-status-btn mtng_booked d-flex align-items-center br-13 pointer"
                    onClick={activateCampaign}
                  >
                    <img src="/assets/images/stars-white-2x.png" width="auto" height="24" alt="stars" />
                    <span className="text-nowrap ps-2">Create Campaign</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        }
      </Row>

      {/* Drawers */}
      <Drawer
        title=""
        placement="right"
        width={450}
        closable={false}
        onClose={() => setShowUploadProspects(false)}
        open={showUploadProspects}
        key="left"
        className="bootstrap"
      >
        <div className="d-flex align-items-center pb-2 pointer" onClick={() => setShowUploadProspects(false)}>
          <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
          <span className="ps-2 white-text-62">Close</span>
        </div>

        <div className={"d-flex flex-column justify-content-center" + (importCsvScreen === 2 ? " d-none" : "")} style={{height: "calc(100% - 38px)"}}>
          <div id="upload-csv-holder" className="w-50 pb-4 mx-auto">
            <Dragger {...csvDraggableProps}>
              <div className="d-flex align-items-center justify-content-center">
                <img src="/assets/images/export-gray-2x.png" width="14" height="auto" alt="export" />
                <span className="ps-2 fw-semibold">Upload CSV</span>
              </div>
            </Dragger>
          </div>

          <div className="text-center text-white pt-3 pb-4">Drag and Drop a CSV file to<br />begin the import.</div>

          <div className="d-flex justify-content-center">
            <Button type="primary" className="br-10" onClick={csvUpload}>Upload</Button>
          </div>
        </div>

        <div id="upload-csv-mapping-parent" className={"right-sidebar-parent text-white"
            + (importCsvScreen === 2 ? "" : " d-none")}>
              <div className="d-flex align-items-center pb-4">
                <img src="/assets/images/folder-gray-2x.png" width="18" height="auto" alt="folder" />
                <span className="px-2">{csvMappings.current.file_name}</span>
                <img
                  src="/assets/images/close-white-2x.png"
                  width="14"
                  height="auto"
                  alt="close"
                  className="pointer"
                  onClick={() => setImportCsvScreen(0)}
                />
              </div>
              <Row>
                <Col span={24}>
                  <Row gutter={30} align="middle">
                    <Col span={8} className="gutter-row">
                      <div className="text-secondary small pb-3">Column Name</div>
                    </Col>
                    <Col span={16} className="gutter-row">
                      <div className="text-secondary small pb-3">Select Type</div>
                    </Col>
                  </Row>
                  <Form onFinish={postCsvMappings}>
                    {csvMappings.current.columns.map((column, index) => {
                      return (
                        <Row gutter={30} align="middle" className="pb-4" key={index}>
                          <Col span={8} className="gutter-row">{column}</Col>
                          <Col span={16} className="gutter-row">
                            <Form.Item
                              name={column}
                              className="pb-0"
                              rules={[{required: true, message: "please enter an option"}]}
                            >
                              <Select
                                className="w-100 custom-select3 csv-mapping"
                                options={csvPeopleOptions}
                                placeholder="Select Type"
                                suffixIcon={<DropdownArrow />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )
                    })}
                    <Row gutter={30} className="pb-4" justify="center">
                      <Col className="gutter-row">
                        <div
                          className="w-150px lead-status-btn generate d-flex align-items-center justify-content-center
                          br-13 pointer"
                          onClick={triggerPostCsv}
                        >
                          <img src="/assets/images/stars-white-2x.png" width="auto" height="24" alt="stars" />
                          <span className="text-nowrap ps-2">save</span>
                        </div>
                        <Button id="post-csv-btn" htmlType="submit" className="d-none">Save</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>              
            </div>
      </Drawer>

      {/* <Drawer
        title=""
        placement="right"
        width={450}
        closable={false}
        onClose={() => setShowGenerateCopy(false)}
        open={showGenerateCopy}
        key="left2"
        className="bootstrap"
      >
        <div className="d-flex align-items-center pb-2 pointer" onClick={() => setShowGenerateCopy(false)}>
          <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
          <span className="ps-2 white-text-62">Close</span>
        </div>

        <div className="d-flex justify-content-center align-items-center" style={{height: "calc(100% - 38px)"}}>
          <Form
            form={generateCopyForm}
            onFinish={saveGenerateCopy}
            className="w-75"
          >
            <Row gutter={24}>
              <Col span={24} className="gutter-row">
                <Form.Item name="persona_id">
                  <Select
                    className="copyright-select"
                    placeholder="Select a Persona"
                    options={personas.current}
                    disabled={true}
                  />
                </Form.Item>

                {generateCopyType === "ai" &&
                  <>
                    <p className="text-center text-white pb-3">Copy Details</p>

                    <Form.Item
                      name="cta"
                      rules={[
                        { 
                          required: true,
                          message: "Please select call to action"
                        },
                      ]}
                    >
                      <Select
                        className="copyright-select"
                        placeholder="Call To Action"
                        options={CTA}
                        suffixIcon={<DropdownArrow />}
                      />
                    </Form.Item>

                    <Form.Item
                      name="message_length"
                      rules={[
                        { 
                          required: true,
                          message: "Please enter no. of messages"
                        },
                      ]}
                    >
                      <Select
                        className="copyright-select"
                        placeholder="Number of Messages"
                        options={[
                          {label: 1, value: 1},
                          {label: 2, value: 2},
                          {label: 3, value: 3},
                          {label: 4, value: 4},
                          {label: 5, value: 5},
                          {label: 6, value: 6},
                        ]}
                        suffixIcon={<DropdownArrow />}
                      />
                    </Form.Item>

                    <Form.Item
                      name="url"
                      rules={[
                        {
                          type: "url",
                          required: true,
                          message: "Please enter URL"
                        },
                      ]}
                    >
                      <Input placeholder="URL to include in Message" />
                    </Form.Item>
                  </>
                }

                <Tag color="blue" className="d-block text-center mb-3 mr-0 text-wrap">
                  To improve your copy, you can edit information about your company in Settings.
                </Tag>

                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Button htmlType="submit" className="d-none" id="generate-copy-btn" />
                  <div
                    className={"lead-status-btn d-flex align-items-center pointer " +
                    (generateCopyType === "ai" ? "generate-copy" : "mtng_booked")}
                    onClick={triggerGenerateCopySubmit}
                  >
                    <img src="/assets/images/edit-white-2x.png" width="auto" height="15" alt="edit" />
                    <span className="text-nowrap ps-2">
                      {generateCopyType === "ai" ? "Generate using AI" : "Create a Blank Copy"}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Drawer> */}

      {/* Modals */}
      <TimeoutErrorModal shown={showTimeoutModal} action={closeTimeoutModal} close={closeTimeoutModal} />

      <Modal
        title=" "
        open={showDuplicateModal}
        onOk={closeDuplicateModal}
        onCancel={closeDuplicateModal}
        maskClosable={false}
        footer={null}
        centered
        className="bootstrap custom-modal"
      >
        <Result
          subTitle={<div>Selected Persona has {allDuplicates ? "all" : ""} duplicate leads</div>}
          extra={
            <div className="d-flex justify-content-center align-items-center">
              <Button type="primary" onClick={() => {
                setIncludeDuplicates(true);
                closeDuplicateModal();
              }}>Include Duplicate</Button>
              {!allDuplicates && <Button className="ms-2 red-btn" onClick={() => {
                setIncludeDuplicates(false);
                closeDuplicateModal();
              }}>Exclude Duplicate</Button>}
              {allDuplicates && <Button className="ms-2 red-btn" onClick={() => {
                closeDuplicateModal();
                setIncludeDuplicates(true);
              }}>Cancel</Button>}
            </div>
          }
        />
      </Modal>

      {/* <Modal
        title=" "
        open={generateCopyModalOpen}
        onOk={closeGenerateCopyModal}
        onCancel={closeGenerateCopyModal}
        maskClosable={false}
        footer={null}
        centered
        className="bootstrap custom-modal"
      >
        <div className="fs-6 fw-semibold pb-3">Please select the Copy generation type</div>
        <div className="d-flex justify-content-center align-items-center small">
          <div
            className="lead-status-btn mtng_booked d-flex align-items-center me-2 pointer"
            onClick={() => setGenerationType("blank")}
          >
            <span className="text-nowrap ps-2">Create using a Blank Template</span>
          </div>
          <div
            className="lead-status-btn generate-copy d-flex align-items-center pointer"
            onClick={() => setGenerationType("ai")}
          >
            <span className="text-nowrap ps-2">Generate using AI</span>
          </div>
        </div>
      </Modal> */}
    </Layout>
  )
}

export default CampaignsForm;
