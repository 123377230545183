// LoaderContext.js
import React, { useState, useContext } from 'react';

const LoaderContext = React.createContext();

export const LoaderProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);

  return (
    <LoaderContext.Provider value={{ showLoader, setShowLoader }}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  // console.log('called')
  const context = useContext(LoaderContext);
  
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }

  return context;
};
