// src/components/NotificationListener.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSocketConnection, disconnectSocket } from '../socket';
import { addNotification } from '../store/slices/notificationSlice';
import PopUpNotification from './PopUpNotification';
import "./menu.css";

const NotificationListener = ({ userId }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    console.log('NotificationListener mounted');

    const handleNotification = (data) => {
      console.log('Notification from server: LISTENER', data);
      const newNotification = { id: Date.now(), ...data };

      // only add the notificaion if it has not been displayed before.
      setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
      dispatch(addNotification(data));
    };

    createSocketConnection(userId, handleNotification);

    return () => {
      console.log('NotificationListener unmounted');
      disconnectSocket();
    };
  }, [userId, dispatch]);

  const dismissNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  return (
    <>
      <div className="notification-container">
        {notifications.map((notification) => (
          <PopUpNotification
            key={notification.id}
            id={notification.id}
            title={notification.title}
            message={notification.message}
            action_url={notification.action_url}
            notification_type={notification.notification_type}
            onDismiss={() => dismissNotification(notification.id)}
          />
        ))}
      </div>
    </>
  );
};

export default NotificationListener;
