export const timezoneAbbreviations = {
    'America/Adak': 'HST',       
    'Pacific/Apia': 'BST',       
    'Pacific/Honolulu': 'HST',  
    'Pacific/Nauru': 'NRT',      
    'Pacific/Tarawa': 'GILT',    
    'Asia/Kolkata': 'IST',       
    'Asia/Dubai': 'GST',         
    'Europe/London': 'GMT',      
    'Etc/GMT+4': 'UST',          
    'America/New_York': 'EDT',   
    'America/Chicago': 'CDT',    
    'America/Denver': 'MDT',     
    'America/Los_Angeles': 'PDT',   
    'Australia/Sydney': 'AEST',  
    'Pacific/Auckland': 'NZST',  
  };