//super admin analytics page.
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from "../../config/AppConfig";
import SearchBar from './SearchBar';
import { Table, Drawer, Spin, Tabs } from "antd";
import './AdminAnalytics.css';
import { columns } from './columns';
import axiosInstance from "../../config/axiosConfig";
import moment from 'moment'

const {TabPane} = Tabs

const generateUniqueKey = () => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

const AdminAnalytics= () => {
  const authData = useSelector(state => state.auth);
  const isSuperAdmin = authData.userInfo.is_super_admin;
  const navigate = useNavigate();


  const [filteredData, setFilteredData] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState([]);
  const [liAgentInfo, setLiAgentInfo] = useState(null);

   useEffect(() => {
    if (!isSuperAdmin) {
      navigate('/'); // Redirect to a "not authorized" page if the user is not a super admin
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/customer_analytics/customer_analytics/`, {
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${authData.token}` 
          }
        });

        const data = await response.json();

        // Format the last_login date and add a unique key
        const formattedData = data.map(item => ({
            ...item,
            key: generateUniqueKey()
        }));

        setDataSource(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const handleSearch = (value) => {
    const filtered = dataSource.filter(item =>
      item.company_name.toLowerCase().includes(value.toLowerCase()) ||
      item.user_email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleCellClick = async (record, type) => {
    setLoadingDrawer(true);
    setShowHistory(true);

    try {
      const payload = type === 'user' 
        ? {user_id: record.user_id}
        : type === 'company' ? {company_id: record.company_id} : {company_id: record.company_id, user_id: record.user_id};
        
      const [lastActionResponse, liAgentResponse] = await Promise.all([
        axiosInstance.post('/customer_analytics/all_last_action/', payload),
        payload.user_id 
          ? fetch(`${API_BASE_URL}/customer_analytics/linkedin_desktop/linkedin_desktop_sys_config?user_id=${payload.user_id}`, {
              method: 'GET',
              headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${authData.token}` 
              }
            })
          : Promise.resolve(null)
      ]);

      setDrawerData(lastActionResponse.data);

      if (liAgentResponse) {
        const liAgentData = await liAgentResponse.json();
        setLiAgentInfo(liAgentData);
        console.log(liAgentInfo)
        console.log('user ' + payload.user_id);
      }
    } catch (error) {
      console.error(`Error fetching details:`, error);
    } finally {
      setLoadingDrawer(false);
    }
};


const columnsWithEvents = columns.map(col => {
    if (col.dataIndex === 'user_email' || col.dataIndex === 'company_name' || col.dataIndex === "last_action_timestamp") {
      return {
        ...col,
        onCell: (record) => ({
          className: 'clickable-cell',
          onClick: () => {
            handleCellClick(record, col.dataIndex === 'user_email' ? 'user'
              : col.dataIndex === 'company_name' ? 'company' : "company_user");
          },
        }),
      };
    }
    return col;
  });

  const userLogs = drawerData.filter(i => i.table_affected === 'users' || i.table_affected === 'company' || i.table_affected === null);
  const otherLogs = drawerData.filter(i => i.table_affected !== 'users' && i.table_affected !== 'company' && i.table_affected !== null);

  return (
    <div className="analytics-page-container">
      <div className="header-container">
        <div className="logo-and-info">
          <img src="/favicon.ico" alt="Company Logo" className="company-logo" />
          <div className="company-info">
            <h1 className="company-name">Pipeline</h1>
            <p className="company-subtitle">Customer Analytics</p>
          </div>
        </div>

        <SearchBar onSearch={handleSearch} />

      </div>

      <div className="table-container">
        <Table
          bordered
          dataSource={filteredData}
          columns={columnsWithEvents}
          scroll={{ x:'max-content'}}
          rowHoverable={true}
          pagination={false}
          rowClassName="custom-row-height" // Assign custom class
          tableLayout="auto" // Allow columns to adjust their width based on content
          sticky={true}
        />
      </div>
      <Drawer
        title="MORE INFO"
        placement="right"
        width={700}
        closable={false}
        onClose={() => setShowHistory(false)}
        open={showHistory}
        className="bootstrap billing-drawer"
        style={{
          borderLeft: "3px solid",
          borderImageSource: "linear-gradient(180deg, rgba(112, 66, 248, 0.54) 0%, #A599CA 100%)",
          borderImageSlice: "1",
          backgroundColor: "#09080D",
        }}
      >{loadingDrawer ? (
        <Spin />
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="User Logs" key="1">
                <div className='text-white'>
                    {userLogs.map(i =>
                        <div className='mb-2 d-flex flex-row' key={i.id}>
                            <img src={i.details === "User Logout" || i.details === "User Login" ? "/assets/svg/logging-icon.svg" :
                              "/assets/svg/green-tick-icon.svg"} style={{ paddingBottom: "4%" }} alt="log-icon" />
                          <div className='d-flex mb-1 flex-column ms-4'>
                              <div className="user-log-detail">
                                  {i.details}
                              </div>
                              <div className="history-date pb-2">{moment.utc(i.created_date).local().format('MMMM-DD-YYYY,  h:mm A z')}</div>
                          </div>
                      </div>)}
              </div>
          </TabPane>

          <TabPane tab="Other Logs" key="2">
            <div className='text-white'>
              {otherLogs.map(i =>
                <div className='mb-2 d-flex flex-row' key={i.id}>
                    <img src="/assets/svg/green-tick-icon.svg" style={{ paddingBottom: "4%" }} alt="log-icon" />
                    <div className='d-flex mb-1 flex-column ms-4'>
                        <div className="user-log-detail">
                            {i.details}
                        </div>
                        <div className="history-date pb-2">{moment.utc(i.created_date).local().format('MMMM-DD-YYYY,  h:mm A z')}</div>
                    </div>
                </div>
              )}
            </div>
          </TabPane>

          <TabPane tab="LI Agent Info" key="3">
            <div className='text-white'>
              <pre>{JSON.stringify(liAgentInfo, null, 2)}</pre>
            </div>
          </TabPane>

        </Tabs>)}
      </Drawer>
    </div>
  );
};

export default AdminAnalytics;
