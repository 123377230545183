// googleApiService.js
export function loadGoogleMapsScript(apiKey, callback) {
     const script = document.createElement('script');
     script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
     script.onload = callback;
     document.head.appendChild(script);
   }
   
   export function getPlacePredictions(input, apiKey, callback) {
     const service = new window.google.maps.places.AutocompleteService();
     service.getPlacePredictions({ input }, callback);
   }
   