import React, { useEffect, useState } from 'react';
import { Modal, Spin, Switch } from 'antd';
import axiosInstance from '../../config/axiosConfig';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import SlackButton from '../../component/SlackButton.js';

const Notifications = () => {
  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);
  const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const [showLoader, setShowLoader] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({
    receive_email_notifications: true,
    linkedin_disconnected: true,
    email_disconnected: true,
    campaign_notifications: true,
    email_warming: true,
    new_responses: true,
    product_updates: true,
  });

  const fetchNotificationSettings = async () => {
    setShowLoader(true);
    try {
      const response = await axiosInstance.get(`/notifications/settings/${userId}`);
      setNotificationSettings(response.data);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the notification settings' });
    }
    setShowLoader(false);
  };

  const updateNotificationSettings = async (settings) => {
    setShowLoader(true);
    try {
      await axiosInstance.post(`/notifications/settings/${userId}`, settings);
      notification.success({ message: 'Success', description: 'Notification settings updated successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in updating the notification settings' });
    }
    setShowLoader(false);
  };

  const handleToggleChange = (settingKey) => (checked) => {
    const updatedSettings = { ...notificationSettings, [settingKey]: checked };
    setNotificationSettings(updatedSettings);
    updateNotificationSettings(updatedSettings);
  };

 useEffect(() => {
    fetchNotificationSettings();
  }, []);

  return (
    <div className="bootstrap d-flex flex-row text-white w-100 justify-content-center align-items-center settings-background">
      <div className='d-flex flex-column profile-card'>
        <div className='profile-text w-100 mb-4'>Notifications</div>
        <div className='d-flex justify-content-between profile-row pb-4 pt-4'>
          <div className='profile-label pt-1 pb-2'>Receive Email Notifications</div>
          <Switch checked={notificationSettings.receive_email_notifications} onChange={handleToggleChange('receive_email_notifications')} disabled={!editAccess} />
        </div>
        <div className='profile-sub-text w-100 mb-3 profile-row'></div>
        <div className='w-100 pt-5'>
          <div className='d-flex pb-3'>
            <Switch checked={notificationSettings.linkedin_disconnected} onChange={handleToggleChange('linkedin_disconnected')} disabled={!editAccess} />
            <div className='notification-label pt-1 ps-4'>LinkedIn Account is Disconnected</div>
          </div>
          <div className='d-flex pb-3 pt-3'>
            <Switch checked={notificationSettings.email_disconnected} onChange={handleToggleChange('email_disconnected')} disabled={!editAccess || !notificationSettings.receive_email_notifications} />
            <div className='notification-label pt-1 ps-4'>Email Account is Disconnected</div>
          </div>
          <div className='d-flex pb-3 pt-3'>
            <Switch checked={notificationSettings.campaign_notifications} onChange={handleToggleChange('campaign_notifications')} disabled={!editAccess} />
            <div className='notification-label pt-1 ps-4'>Campaign Related Notifications</div>
          </div>
          <div className='d-flex pb-3 pt-3'>
            <Switch checked={notificationSettings.email_warming} onChange={handleToggleChange('email_warming')} disabled={!editAccess} />
            <div className='notification-label pt-1 ps-4'>Email Warming</div>
          </div>
          <div className='d-flex pb-3 pt-3'>
            <Switch checked={notificationSettings.new_responses} onChange={handleToggleChange('new_responses')} disabled={!editAccess} />
            <div className='notification-label pt-1 ps-4'>New Responses</div>
          </div>
          <div className='d-flex pt-3 pb-4'>
            <Switch checked={notificationSettings.product_updates} onChange={handleToggleChange('product_updates')} disabled={!editAccess} />
            <div className='notification-label pt-1 ps-4'>Pipeline Product Updates</div>
          </div>
          <div className='d-flex pt-5'>
            <SlackButton userId={userId} />
          </div>
        </div>
      </div>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={showLoader}
      >
        <div className="pt-4 text-center">
          <Spin size="large" />
        </div>
      </Modal>
    </div>
  );

};

export default Notifications;

