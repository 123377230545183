import {
  AccountsIcon,
  AnalyticsIcon,
  CampaingsIcon,
  InboxIcon,
  EmailMenuIcon,
} from '../assets/svg/Icons';
import { SettingOutlined } from '@ant-design/icons';
import AuthenticationNew from '../views/Auth/AuthenticationNew';
import Profile from '../views/settings/profile'
import BlockList from '../views/settings/blocklist'
import Notifications from '../views/settings/notifications'
import Prospecting from '../views/settings/prospecting'
import Email from '../views/settings/email'
import LinkedIn from '../views/settings/linkedin'
import Collaborators from '../views/settings/collaborator'
import Billing from '../views/settings/billing'

export const settingsMenuItems = [
  {
    key: "auth",
    route: "/auth",
    component: <AuthenticationNew />,
    isMenu: false,
    subMenuItems: [
      { key: "auth",
      route: "/auth",
      component: <AuthenticationNew />} 
    ]
  },
  {
    key: '/settings-new/my-account',
    label: "My Account",
    icon: "/assets/svg/my-account-icon.svg",
    iconSelected: <EmailMenuIcon color="yellow" />,
    route: "/settings-new/my-account",
    isMenu: true,
    // component: <CampaignsList />,
    subMenuItems: [
        {
          key: "/settings-new/profile",
          label: "Profile",
          route: "/settings-new/profile",
          component: <Profile />,
        },
        {
          key: "/settings-new/blocklist",
          label: "Blocklist",
          route: "/settings-new/blocklist",
          component: <BlockList />,
        },
        {
          key: "/settings-new/notifications",
          label: "Notifications",
          route: "/settings-new/notifications",
          component: <Notifications/>,
        },
]},
  {
    key: '/settings-new/preferences',
    label: "Preferences",
    icon: "/assets/svg/preferences-icon.svg",
    // iconSelected: <EmailMenuIcon color="yellow" />,
    route: "/settings-new/prospecting",
    isMenu: true,
    // component: <CampaignsList />,
    subMenuItems: [
        {
          key: "/settings-new/prospecting",
          label: "Prospecting",
          route: "/settings-new/prospecting",
          component: <Prospecting />,
        },
        {
          key: "/settings-new/email",
          label: "Email",
          route: "/settings-new/email",
          component: <Email />,
        },
        {
          key: "/settings-new/linkedIn",
          label: "LinkedIn",
          route: "/settings-new/linkedIn",
          component: <LinkedIn />,
        },
]},
  {
    key: '/workspace',
    label: "Workspace Settings",
    icon: "/assets/svg/workspace-icon.svg",
    iconSelected: <EmailMenuIcon color="yellow" />,
    route: "/settings-new/collaborators",
    isMenu: true,
    // component: <CampaignsList />,
    subMenuItems: [
        {
          key: "/settings-new/collaborators",
          label: "Collaborators",
          route: "/settings-new/collaborators",
          component: <Collaborators />,
        },
        {
          key: "/settings-new/billing",
          label: "Billing",
          route: "/settings-new/billing",
          component: <Billing />,
        },
]},
];
