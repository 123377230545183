import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SideMenu from './component/SideMenu';
import SettingsSideMenu from './views/settings/Settings-SideMenu';
import { ConfigProvider, theme } from 'antd';
import { menuItems, adminMenuItems, profileMenuItems } from './constants/Menu';
import { settingsMenuItems } from './constants/Settings-Menu';
import { AuthProvider } from './auth/AuthContext';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './views/Loader';
import AdminAnalyticsPage from './views/adminAnalytics/AdminAnalyticsPage';

import { i18nChangeLanguage } from '@wangeditor/editor';
import initInsertVariableMenu from "./component/InsertVariableMenu";

i18nChangeLanguage('en');
initInsertVariableMenu();

const App = () => {
  const urlPath = window.location.pathname.split("/");
  let urlFirst = urlPath[1]
  let urlSecond = urlPath[2]
  const isShare = !!(( urlFirst === "share" && urlSecond !== "leadlist"))
  const isLogin = !!["", "auth", "select-company"].includes(urlFirst);

  const allMenu = [...menuItems,...adminMenuItems, ...profileMenuItems];
  const settingsMenu = [...settingsMenuItems]

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token:{fontFamily: 'Inter'}
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            {window.location.pathname === "/admin-analytics" ? (
              <Routes>
                <Route
                  path="/admin-analytics"
                  element={<AdminAnalyticsPage />}
                />
              </Routes>
            ) : 
            ( urlPath[1] === "settings-new")? (
            <SettingsSideMenu>
              <Routes>
              {settingsMenu.map(routeData => 
                      routeData.subMenuItems.map((submenuRoute)=> 
                      <Route
                      key={submenuRoute.key}
                      path={submenuRoute.route}
                      exact={submenuRoute.exact}
                      element={<AuthProvider>{submenuRoute.component}</AuthProvider>}
                    />
                    )) }
              </Routes>
              </SettingsSideMenu>
            ) : 
            (
              <SideMenu isLogin={isLogin} isShare={isShare}>
                <Routes>
                  {allMenu.map((routeData) => 
                    (
                       routeData.subMenu ? routeData.subMenuItems.map((submenuRoute)=> <Route
                      key={submenuRoute.key}
                      path={submenuRoute.route}
                      exact={submenuRoute.exact}
                      element={<AuthProvider>{submenuRoute.component}</AuthProvider>}
                    />)   :
                        <Route
                          key={routeData.key}
                          path={routeData.route}
                          exact={routeData.exact}
                          element={(["auth", "select-company"].includes(routeData.key) === false && isShare === false) ? 
                          <AuthProvider>{routeData.component}</AuthProvider> : routeData.component}
                        />
                    )
                  )}
                </Routes>
                <Loader />
              </SideMenu>
            )}
          </Router>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
