import React, {useEffect, useRef, useState} from "react";
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Form,
  Input,
  Layout,
  notification,
  Row,
  Spin,
  Switch,
  Tooltip,
  Tag,
  Modal
} from "antd";
import {
  FlagOutlined,
} from "@ant-design/icons";
import axiosInstance from '../../config/axiosConfig';
import { useSelector } from "react-redux";
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import insertLineBreak from "../../common/insertLineBreak";
import {API_BASE_URL, AUTH_TOKEN} from "../../config/AppConfig";

const Account = () => {
  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ?  companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const url = new URL(window.location)
  const params = new URLSearchParams(url.search);

  const pageLoaded = useRef(false);
  const settingsData = useRef({});

  const [loading, setLoading] = useState(0);
  const [loadingModal, setLoadingModal] = useState(0);
  const [currentPage, setCurrentPage] = useState(3);
  const [emailList, setEmailList] = useState([]);
  const [signatureModal, setSignatureModal] = useState(false);
  const [html, setHtml] = useState(null);
  const [editor, setEditor] = useState(null);
  const [editAccount, setEditAccount] = useState(false);
  const [accountDetails, setAccountDetails] = useState();
  const [dailyLimit, setDailyLimit] = useState();
  const [selectedAccount, setSelectedAccount] = useState("");

  let editorConfig = {
    placeholder: 'Type here...',
    MENU_CONF : {}
  }

  editorConfig.MENU_CONF['uploadImage'] = {
    server: `${API_BASE_URL}/engage/upload_image/${userId}`,
    fieldName: 'file',
    timeout: 3 * 100000,
    meta: {
      company_id: companyData.company_id,
      account_id: selectedAccount,
      upload_type: "signature",
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`
    },

    onSuccess(file, res) {  
      console.log(`${file.name} uploaded`, res)
    },
    onFailed(file, res) {               
      console.log(`${file.name} failed`, res)
    },

    customInsert(res, insertFn) {            
      insertFn(`${API_BASE_URL}/engage/get_image/${res.file_id}`, "", `${API_BASE_URL}/engage/get_image/${res.file_id}`)
    },

  }

  const toolbarConfig = {
    toolbarKeys: [
      'insertVariable',
      'insertLink',
      'bold',
      'italic',
      'underline',
      'bulletedList',
      'numberedList',
      'uploadImage'
    ]
  };

  const triggerSubmit = () => {
    document.querySelector('#submit-btn-' + currentPage).click();
  }

  const connectEmailOld = ({email}) => {
    let url = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&prompt=consent&access_type=offline&include_granted_scopes=true&response_type=code&state=%7B%22team_member_id%22%3A2317%2C%22user_id%22%3A10640%7D&client_id=1021517043376-ipe8289dof3t2v9apjpae8hs2q9abetp.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fserver.smartlead.ai%2Fapi%2Femail-account%2Fgmail%2Fcallback&flowName=GeneralOAuthFlow&service=lso&o2v=2&theme=glif";

    setLoading(1);
    window.open(url, "_blank");

    let inProgress = false;
    let emailConnected = false;
    
    const isEmailConnected = async () => {
      inProgress = true;

      try {
        let url = `/engage/fetch_email_status/${userId}?company_id=${companyId}&email=${email}`;

        if (params.has('campaign_id')) {
          url += "&campaign_id=" + params.get('campaign_id');
        }

        const response = await axiosInstance.get(url);
        const {account_id} = response.data;

        if (account_id) {
          emailConnected = true;
        }
      } catch {
        console.error("Email not yet connected")
      }

      inProgress = false;
    }

    const emailConnectedInterval = setInterval(() => {
      if (inProgress) {
        return;
      }

      if (emailConnected) {
        clearInterval(emailConnectedInterval);
        gotoList();
      } else {
        isEmailConnected();
      }
    }, 5000);

    isEmailConnected();
  }

  const connectEmail = async ({name, email, password}) => {
    setLoading(1);

    const payload = {
      company_id: companyId,
      email: email,
      name: email,
      password: password,
      daily_limit: 30,
      signature_content: html
    };
    
    try {
      const response = await axiosInstance.post(`/engage/add_account/${userId}`, payload);
      if(response.data.status === 200) {
      notification.success({message: 'Success', description: "Email account has been connected successfully"});
      }
      else {
        notification.error({message: 'Error', description: response.data.message});
      }

      gotoList();
    } catch {
      setLoading(0);
      notification.error({message: 'Error', description: "Error in adding the email account"});
    }
  }

  const gotoList = async () => {
    setLoading(1);

    try {
      const response = await axiosInstance.get(`/engage/accounts_list/${userId}?company_id=${companyId}`);
      setEmailList(response.data);
    } catch(error) {
      if(error?.response?.status === 401) {
        notification.error({message: 'Error', description: 'Your subscription plan has expired'});
      }
      else {
        notification.error({message: 'Error', description: 'Error in fetching email list'});
      }
    }
    
    setCurrentPage(3);
    fetchSettings();
  }

  const getBatteryLevel = score => {
    let level = "full";

    if (score === 0 ) {
      level = "empty";
    } else if (score <= 25) {
      level = "low";
    } else if (score <= 50) {
      level = "half";
    } else if (score <= 90) {
      level = "high";
    }

    return level;
  }

  const warmUpToggle = async (accountId, checked) => {
    let url, successMsg, FailureMsg;

    if (checked) {
      url = `/engage/enable_warmup/${accountId}?user_id=${userId}&company_id=${companyId}`;
      successMsg = "Warmup has been enabled successfully";
      FailureMsg = "Enabling Warmup process failed";
    } else {
      url = `/engage/disable_warmup/${accountId}?user_id=${userId}&company_id=${companyId}`;
      successMsg = "Warmup has been disabled successfully";
      FailureMsg = "Disabling Warmup process failed";
    }

    try {
      setLoading(1)
      await axiosInstance.post(url, {});
      setLoading(0)
      notification.success({message: 'Success', description: successMsg});
    } catch {
      notification.error({message: 'Error', description: FailureMsg});
    }
  }

  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get(`/settings/get_settings/${companyId}?user_id=${userId}`);
      settingsData.current = response.data;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the Settings' });
    }

    setLoading(0);
  }

  const navigateToConnect = () => {
    {/*
    if (settingsData.current.smartlead_synced) {
      window.open("https://app.smartlead.ai/app/email-accounts", "_blank");
    } else {
      setCurrentPage(1);
    }
    */}

    setCurrentPage(1);
  }

  const deleteAccount = async ({key}) => {
    setLoading(1);

    try {
      await axiosInstance.delete(`/engage/delete_account/${key}?user_id=${userId}&company_id=${companyId}`);
      notification.success({ message: 'Success', description: 'Account has been deleted successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in deleting the account' });
    }
    gotoList()
    setLoading(0);
  }

  const getAccountDetails = async (accountId) => {
    setLoadingModal(1)
    try {
      const response = await axiosInstance.get(`/engage/get_account/${userId}?company_id=${companyId}&account_id=${accountId}`);
      setAccountDetails(response.data)
      setDailyLimit(response.data.daily_limit)
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching the account details' });
    }
    setLoadingModal(0)
  }


  const setHtmlContent = (content, text) => {
    const value = text.length ? content : "";

    let editValue = value
    let tempElement = document.createElement('div');
    tempElement.innerHTML = editValue;
    let imgTags = tempElement.getElementsByTagName('img');
    for (let i = 0; i < imgTags.length; i++) {
      let imgTag = imgTags[i];
      if (!imgTag.style.width) {
        imgTag.style.width = '100%';
      }
    }
    editValue = tempElement.innerHTML;
    setHtml(editValue);
  }

  const saveAccount = async(accountId) => {
    const payload = {
      account_id: accountId,
      company_id: companyId,
      signature_content: html,
      daily_limit: dailyLimit
    }

    try {
      await axiosInstance.put(`/engage/edit_account/${userId}`, payload);
      notification.success({ message: 'Success', description: 'Account has been edited successfully' });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in editing the account' });
    }
    setHtml("")
  }

  const reconnectAccount = async() => {
    setLoading(1)
    try {
      const res = await axiosInstance.put(`engage/reconnect_failed_email_accounts?company_id=${companyId}`);
      if(res?.data?.status === 200) {
      notification.success({ message: 'Success', description: res?.data?.message });
      }
      else {
        notification.error({ message: 'Error', description: res?.data?.message });
      }
    } catch (error) {
      notification.error({ message: 'Error', description: error?.response?.data?.detail });
    }
    setLoading(0)
  }

  useEffect(() => {   
    if (pageLoaded.current) {
      return;
    }
    
    pageLoaded.current = true;
    gotoList();
  }, []);

  useEffect(() => {
    if (loading === 1 || !editor) {
      return;
    }

    insertLineBreak(editor);
  }, [editor]);

  return (
    <Layout style={{minHeight: '100vh', padding: 15}} className="bootstrap">
      {/* Header */}
      <Row gutter={30} className="pb-3">
        <Col span={6} className="gutter-row">
          <div className="analytics-heading font-weight-500 px-2">Accounts</div>
        </Col>

        <Col span={12} className="gutter-row"></Col>

        {editAccess && 
          <Col span={6} className="gutter-row d-flex align-items-center justify-content-end">
            {emailList.some(email => email.status === false )&& 
            <div
              className="lead-status-btn mtng_booked d-flex align-items-center pointer br-13 ms-3 me-3"
              onClick={reconnectAccount}
            >
              <span className="text-nowrap ps-2">Reconnect</span>
              </div> }
            <img src="/assets/images/disk-ivory-2x.png" width="25" height="auto" alt="disk" />
            <div
              className="lead-status-btn mtng_booked d-flex align-items-center pointer br-13 ms-3"
              onClick={navigateToConnect}
            >
              <img src="/assets/images/link-white-2x.png" width="auto" height="14" alt="link" />
              <span className="text-nowrap ps-2">Add New</span>
            </div>
          </Col>
        }
      </Row>

      {/* Main */}
      <Row gutter={30} id="engage-account-main">
        <Col span={24} className="h-100">
          {/* Loader */}
          <div
            className={"loader2 flex-column justify-content-center align-items-center "
              + (loading === 0 ? "d-none" : "d-flex")}
          >
            <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
            <div className={"pt-4 text-center" + (loading === 1 ? "" : " d-none")}>
              <Spin size="large" />
            </div>
          </div>

          {/* Prompt */}
          <div
            id="prompt"
            className={"h-100 justify-content-center align-items-center "
            + (loading === 0 && currentPage === 1 ? "d-flex" : "d-none")}
          >
            <div className="form-card d-flex flex-column align-items-center my-4">
              <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
              <div className="fs-4 text-white pt-3 pb-4">Connect your inboxes with Google SSO.</div>
              <Form className="w-100 d-flex flex-column align-items-center" 
                onFinish={connectEmail}>
                {/* <Form.Item
                  name="name"
                  className="w-50 pb-4"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a name"
                    },
                  ]}
                >
                  <Input className="custom-input2" placeholder="enter a name" />
                </Form.Item> */}
                <Form.Item
                  name="email"
                  className="w-50 pb-4"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please enter an email"
                    },
                  ]}
                >
                  <Input className="custom-input2" placeholder="enter an email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  className="w-50 pb-4"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a password"
                    },
                  ]}
                >
                  <Input className="custom-input2" placeholder="enter a password" type="password" />
                </Form.Item>
                {/* <div className="pb-4" style={{textAlign:"left", width:"50%"}}>
                  Enter daily email limit
                </div>
                <Form.Item
                  name="limit"
                  className="w-50 pb-4"
                  rules={[
                    {
                      required: true,
                      message: "Please enter limit"
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: 'Please enter a valid integer',
                    },
                  ]}
                >
                  <Input className="custom-input2" placeholder="enter daily email limit" defaultValue="30"/>
                </Form.Item> */}
                <Button type="primary" className="mb-4"
                  onClick={() => {setSignatureModal(true)}}>Add signature</Button>
                <button type="submit" disabled aria-hidden="true" class="d-none">PreventSubmit</button>
                <Button htmlType="submit" className="d-none" id="submit-btn-1" />
              </Form>
              <div
                className="lead-status-btn mtng_booked d-flex align-items-center pointer br-13"
                onClick={triggerSubmit}
              >
                <img src="/assets/images/arrow-white-2x.png" width="18" height="auto" alt="arrow" />
                <span className="text-nowrap ps-2">Connect inbox</span>
              </div>
            </div>
          </div>

          {/* Form */}
          <div id="form" className="h-100 d-none justify-content-center align-items-center">
            <div className="form-card d-flex flex-column align-items-center my-4">
              <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
              <div className="fs-4 text-white pt-3 pb-4">Great work! Want to add more?</div>
              <Form className="w-100 d-flex flex-column align-items-center">
                <Form.Item className="w-50">
                  
                </Form.Item>
                <Form.Item className="w-50">
                  <Input className="custom-input2" placeholder="julie@test.com" />
                </Form.Item>
              </Form>
              <div className="lead-status-btn mtng_booked d-flex align-items-center pointer br-13">
                <img src="/assets/images/arrow-white-2x.png" width="18" height="auto" alt="arrow" />
                <span className="text-nowrap ps-2">Connect More</span>
              </div>
            </div>
          </div>

          {/* List */}
          <div id="list" className={loading === 0 && currentPage === 3 ? "d-block" : "d-none"}>
            {emailList.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {emailList.map((email, index) => {
              return (
                <Row align="middle" className="engage-acc-list-row text-white mb-4" key={index}>
                  <Col span={9} className="d-flex justify-content-between align-items-center fw-semibold column-1">
                    <span>{email.account_email}</span>
                    {email.status === false ? 
                    <Tooltip title={<div>
                        <div><i>This email account has been disconnected. 
                          Please click the "Reconnect" button.</i></div>
                          <br />
                        <div><i>Reach out to the Pipeline team if you have questions.</i></div>  
                    </div>}>
                                             
                          <Tag color="red" style={{ cursor: 'default', userSelect: 'none' }}>
                            <FlagOutlined style={{ marginRight: 4 }} />
                          </Tag>      
                    </Tooltip> : null }
                    <div className="d-none align-items-center">
                      <img src="/assets/images/arrow-cross-blue-2x.png" width="18" height="auto" alt="icon" />
                      <span className="ps-2">0</span>
                    </div>
                  </Col>
                  <Col span={3} className="d-flex align-items-center px-4">
                    <span className="pe-2">Warmup</span>
                    <Switch
                      defaultChecked={email.warmup_enabled ? true : false}
                      onChange={(checked) => warmUpToggle(email.email_account_id, checked)}
                      disabled={!editAccess}
                    />
                  </Col>
                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                    {/* <div className="d-flex align-items-center"> */}
                      <Col span={2}>
                      <div className="d-flex align-items-center">
                        <img src="/assets/images/attachment-gray-2x.png" width="14" height="" alt="icon" />
                        <span className="ps-2">{email.inbox_count ?? 0}</span>
                      </div>
                      </Col>
                      <Col span={2}>
                      <div className="d-flex align-items-center">
                        <img src="/assets/images/disk-gray-2x.png" width="16" height="" alt="icon" />
                        <span className="ps-2">{email.sent_count ?? 0}</span>
                      </div>
                      </Col>
                      <Col span={1}>
                      <div className="d-flex align-items-center">
                        <img src="/assets/images/alert-gray-2x.png" width="20" height="" alt="icon" />
                        <span className="ps-2">{email.spam_count ?? 0}</span>
                      </div>
                      </Col>
                    {/* </div> */}
                    <Col span={3}>
                    <div className="d-flex align-items-center px-4">
                      <div className={"battery " + getBatteryLevel(email.reputation ? Number(email.reputation) : 0)}></div>
                      <span className="ps-2">{email.reputation ?? 0}%</span>
                    </div>
                    </Col>
                    <Col span={2}>
                    <div>
                      <Button type="primary" onClick={() => { 
                        getAccountDetails(email.account_id)
                        setEditAccount(true)
                        setSelectedAccount(email.account_id) }}>Edit</Button>
                    </div>
                    </Col>
                    <Col span={2}>
                    <div>
                      <Dropdown
                        className="bootstrap"
                        menu={{
                          items: [
                            {
                              label: "Delete Account",
                              key: email.account_id
                            },
                          ],
                          onClick: deleteAccount
                        }}
                        dropdownRender={(menu, index) => (
                          <div className="inbox-filter-dropdown" key={index}>{React.cloneElement(menu)}</div>
                        )}
                      >
                        <div className="three-dots-btn blue pointer">...</div>
                      </Dropdown>
                    </div>
                    </Col>
                  {/* </div> */}
                </Row>
              )
            })}
          </div>
        </Col>
      </Row>
      <Modal
        open={signatureModal}
        closable={false}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={() => {
                setSignatureModal(false)
              }}
            >
              Save
            </Button>
          </div>
        }
      >
        <div>
          <Editor
            defaultConfig={editorConfig}
            value={html}
            onCreated={setEditor}
            onChange={editor => setHtmlContent(editor.getHtml(), editor.getText())}
            mode="default"
            className="wang-editor wang-copywriting-editor br-10"
            style={{ height: '200px', overflowX: 'hidden', overflowY: 'auto' }}
          />
          <Toolbar
            editor={editor}
            defaultConfig={toolbarConfig}
            mode="default"
            className="wang-toolbar wang-copywriting-toolbar"
          />
        </div>
      </Modal>
      <Modal
        open={editAccount}
        onClose={() => { 
          setEditor(null);
          setEditAccount(false)
          setHtml("")
          setSelectedAccount("")
          }}
        onCancel={() => { 
          setEditor(null);
          setEditAccount(false)
          setHtml("")
          setSelectedAccount("")
          }}
        footer={
          <div style={{ textAlign: 'right' }}>         
            { loadingModal === 0 ? <Button
              type="primary"
              onClick={() => {
                setEditor(null);
                saveAccount(accountDetails?.account_id)
                setEditAccount(false);
                setSelectedAccount("")
              }}
            >
              Save
            </Button> : null }
          </div>
        }
      >
        <div>
            {loadingModal === 1 ?
            <div className={"pt-4 justify-content-center" } style={{display:"flex"}}>
              <Spin size="large" />
            </div> : 
          <div className={"pt-4 text-center" }>
          <div><b>{accountDetails?.email_account}</b></div>
          <div className="d-flex flex-row justify-content-between" style={{ marginTop: "6%"  }}>
            <div>Daily limit</div>
            <div><Input value={dailyLimit} onChange={(e) => setDailyLimit(e.target.value)}></Input></div>
          </div>
          <div style={{ marginTop: "4%" }}>
            <div style={{ marginBottom: "1%" }}>Signature</div>
            <Editor
              defaultConfig={editorConfig}
              value={accountDetails?.signature}
              defaultValue={accountDetails?.signature}
              onCreated={setEditor}
              onChange={editor => setHtmlContent(editor.getHtml(), editor.getText())}
              mode="default"
              className="wang-editor wang-copywriting-editor br-10"
              style={{ height: '200px', overflowX: 'hidden', overflowY: 'auto' }}
            />
            <Toolbar
              editor={editor}
              defaultConfig={toolbarConfig}
              mode="default"
              className="wang-toolbar wang-copywriting-toolbar"
            />
          </div>
          </div> }
        </div>
      </Modal>
    </Layout>
  )
}

export default Account;
