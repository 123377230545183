const getChance = level => {
  let chance;

  if (level < 9) {
    chance = "Low";
  } else if (level < 13) {
    chance = "Some";
  } else {
    chance = "High";
  }

  return chance;
}

const getWidthPercent = (value, total) => {
  if (value > total) {
    value = total;
  }

  let percent = (Math.round((value/total) * 100)/100) * 100;

  return percent;
}

const getStrokeColor = percent => {
  let color;

  if (percent < 50) {
    color = "#DD2525";
  } else if (percent < 75) {
    color = "#FFA41B";
  } else if (percent > 0) {
    color = "#3EDF23";
  } else {
    color = "#797979";
  }

  return color;
}

const getSubjectLength = value => {
  let result;

  if (value > 2 && value < 7) {
    result = 99;
  } else if (value > 6 && value < 11) {
    result = 66;
  } else if (value > 0) {
    result = 33;
  } else {
    result = 0;
  }

  return getStrokeColor(result);
}

const getWordCount = value => {
  let result;

  if (value > 69 && value < 106) {
    result = 99;
  } else if ((value > 49 && value < 70) || (value > 104 && value < 126)) {
    result = 66;
  } else if (value > 0) {
    result = 33;
  } else {
    result = 0;
  }

  return getStrokeColor(result);
}

const getQuestionCount = value => {
  let result;

  if (value === 1) {
    result = 99;
  } else if (value === 2) {
    result = 66;
  } else if (value > 0) {
    result = 33;
  } else {
    result = 0;
  }

  return getStrokeColor(result);
}

const getReadingLevel = value => {
  let result;

  if (value >= 1 && value < 5) {
    result = 99;
  } else if (value > 5 && value < 9) {
    result = 66;
  } else if (value > 0) {
    result = 33;
  } else {
    result = 0;
  }

  return getStrokeColor(result);
}

const getSpamWords = value => {
  let result;

  if (value >= 0 && value < 3) {
    result = 99;
  } else if (value > 2 && value < 7) {
    result = 66;
  } else if (value > 0) {
    result = 33;
  } else {
    result = 33;
  }

  return getStrokeColor(result);
}

const getNoOfLinks = value => {
  let result;

  if (value === 1) {
    result = 99;
  } else if (value === 2) {
    result = 66;
  } else if (value > 0) {
    result = 33;
  } else {
    result = 0;
  }

  return getStrokeColor(result);
}

export {
  getChance,
  getWidthPercent,
  getStrokeColor,
  getSubjectLength,
  getWordCount,
  getQuestionCount,
  getReadingLevel,
  getSpamWords,
  getNoOfLinks
}
