import moment from 'moment-timezone';

const momentTimezone = require('moment-timezone');
// format date for last logged in page
const formatDate = (date) => {
    return moment.utc(date).local().format('MM-DD-YYYY [at] h:mm A z');
};

// columns exports for table in analytics page. 
export const columns = [
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      //defaultSortOrder: 'descend', //Default to most recent at top
      width: 200, // Set the width for the user_email column
      fixed: "left"
    },
    {
      title: 'User Email',
      dataIndex: 'user_email',
      key: 'user_email',
      sorter: (a, b) => a.user_email.localeCompare(b.user_email),
      width: 200, // Set the width for the user_email column
      fixed: "left"
    },
    {
      title: 'Last Logged In',
      dataIndex: 'last_login',
      key: 'last_login',
      width: 250, // Set the width for the user_email column
      sorter: (a, b) => new Date(a.last_login) - new Date(b.last_login),
      render: (text) => <span>{formatDate(text)} {momentTimezone.tz(momentTimezone.tz.guess()).zoneAbbr()}</span>,
    },
    {
      title: 'Enriched Leads',
      dataIndex: 'enriched_leads',
      key: 'enriched_leads',
      sorter: (a, b) => a.enriched_leads- b.enriched_leads,
      width: 200, // Set the width for the user_email column
    },
    {
      title: 'Emails Sent',
      dataIndex: 'email_sent',
      key: 'email_sent',
      sorter: (a, b) => a.email_sent - b.email_sent,
      width: 200, // Set the width for the user_email column
    },
    {
      title: 'Active Campaigns',
      dataIndex: 'active_campaigns',
      key: 'active_campaigns',
      sorter: (a, b) => a.active_campaigns- b.active_campaigns,
      width: 200, // Set the width for the user_email column
    },
    {
      title: 'Campaigns Created',
      dataIndex: 'campaigns_created',
      key: 'campaigns_created',
      sorter: (a, b) => a.campaigns_created- b.campaigns_created,
      width: 200, // Set the width for the user_email column
    },
    {
      title: 'Active L Campaigns',
      dataIndex: 'active_linkedin_campaigns',
      key: 'active_linkedin_campaigns',
      sorter: (a, b) => a.active_linkedin_campaigns- b.active_linkedin_campaigns,
      width: 200, // Set the width for the user_email column
    },
    {
      title: 'Email Accounts',
      dataIndex: 'email_accounts',
      key: 'email_accounts',
      sorter: (a, b) => a.email_accounts - b.email_accounts,
      width: 200, // Set the width for the user_email column
    },
    {
      title: 'LinkedIn Accounts',
      dataIndex: 'linkedin_accounts',
      key: 'linkedin_accounts',
      sorter: (a, b) => a.linkedin_accounts - b.linkedin_accounts,
      width: 200,
    },
    {
      title: 'Connections Sent',
      dataIndex: 'connections_sent',
      key: 'connections_sent',
      sorter: (a, b) => a.connections_sent - b.connections_sent,
      width: 200,
    },
    {
      title: 'Last Action Taken',
      dataIndex: 'last_action_timestamp',
      key: 'last_action_timestamp',
      width: 250, // Set the width for the user_email column
      sorter: (a, b) => new Date(a.last_action_timestamp) - new Date(b.last_action_timestamp),
      render: (text) => <span>{text === null ? "--" : `${formatDate(text)} ${momentTimezone.tz(momentTimezone.tz.guess()).zoneAbbr()}`}</span>,
    },
    {
      title: 'Profiles Viewed Today',
      dataIndex: 'todays_profile_views',
      key: 'todays_profile_views',
      sorter: (a, b) => a.todays_profile_views- b.todays_profile_views,
      width: 200,
    },
    {
      title: 'Invites Sent Today',
      dataIndex: 'todays_invites_sent',
      key: 'todays_invites_sent',
      sorter: (a, b) => a.todays_invites_sent- b.todays_invites_sent,
      width: 200,
    },
    {
      title: 'Follow-ups Sent Today',
      dataIndex: 'todays_followup_sent',
      key: 'todays_followup_sent',
      sorter: (a, b) => a.todays_followup_sent- b.todays_followup_sent,
      width: 200,
    },
    {
      title: 'Last Action Taken',
      dataIndex: 'last_action_taken',
      key: 'last_action_taken',
      width: 200,
    },
    /*
    {
      title: 'Total Sessions',
      dataIndex: 'total_sessions',
      key: 'total_sessions',
      sorter: (a, b) => a.total_sessions - b.total_sessions,
      width: 200, 
    },
    */
  ];
