import React, {useEffect, useRef, useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate,  useParams } from 'react-router-dom';
import {
  Button,
  Col,
  DatePicker,
  Layout,
  Modal,
  notification,
  Result,
  Row,
  Select,
  Spin,
  Input,
  Tooltip as TooltipNative
} from "antd";
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
// import { BarChart, Card, Divider, Switch } from '@tremor/react';
import axiosInstance from "../../config/axiosConfig";
import dayjs from 'dayjs';
import { InfoCircleOutlined } from "@ant-design/icons";

const {RangePicker} = DatePicker;

const Analytics = () => {
let userId, companyId;
let sharedCompanyId = useParams().companyId
let sharedUserId = useParams().userId
const authData = useSelector(state => state.auth);
const companyData = useSelector(state => state.companyReducer.selectedCompany);
  if(window.location.href.split('/')[3] === "share") {
    userId = sharedUserId;
    companyId = sharedCompanyId
  }
  else {
  userId = authData.userInfo.is_super_admin ?  companyData.user_id : authData.userInfo.userid;
  companyId = companyData.company_id; 
}
  const navigate = useNavigate();
  const pageLoaded = useRef(false);

  const analyticsData = useRef({
    campaign_name: "",
    leads_remaining: "",
    campaign_status: "",
    contacted: 0,
    contacted_percentage: "",
    opened: 0,
    opened_rate: "",
    replied: 0,
    reply_rate: "",
    link_clicks: 0,
    link_clicks_percentage: "",
    opportunities: 0,
    opportunities_percentage: "",
    total_sent: 0,
    total_open: 0,
    total_contacted_percentage: "",
    total_opened_rate: ""
  });

  const [loading, setLoading] = useState(0);

  const [analyticsDefaultFilter, setAnalyticsDefaultFilter] = useState("last_7_days");
  const [analyticsCustomFilter, setAnalyticsCustomFilter] = useState([null, null]);
  const [analyticsDateErrorModalShown, setAnalyticsDateErrorModalShown] = useState(false);
  const [shareModalShown, setShareModalShown] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [highestTopPerforming, setHighestTopPerforming] = useState();
  const [sharedLink, setSharedLink] = useState(false);

  const [analyticsChartData, setAnalyticsChartData] = useState([]);

  const shareUrl = `${window.location.href.split('/').slice(0, 3).join('/')}/share/analytics-full/${companyId}/${userId}`;

  const getAnalyticsData = async (pageLoad = true) => {
    setLoading(1);

    let time_period, custom_start_date, custom_end_date;

    if (analyticsDefaultFilter) {
      time_period = analyticsDefaultFilter;
    } else if (analyticsCustomFilter[0]) {
      [custom_start_date, custom_end_date] = analyticsCustomFilter;

      custom_start_date = dayjs(custom_start_date).format('YYYY-MM-DD');
      custom_end_date = dayjs(custom_end_date).format('YYYY-MM-DD');
    } else if (!analyticsDefaultFilter && !analyticsCustomFilter[0]) {
      setAnalyticsDefaultFilter("last_7_days");
      time_period = "last_7_days";
    }

    try {
      let url
      if(window.location.href.split('/')[3] === "share") { 
        url = `/share_engage/campaign_aggregated_analytics/${userId}?company_id=${companyId}`;
      }
      else {
        url = `/engage/campaign_aggregated_analytics/${userId}?company_id=${companyId}`;
      }  
      if (time_period) {
        url += `&time_period=${time_period}`;
      }

      if (custom_start_date) {
        url += `&custom_start_date=${custom_start_date}&custom_end_date=${custom_end_date}`;
      }

      if(selectedCampaign !== "") {
        url+= `&campaign_id=${selectedCampaign}`
      }

			const response = await axiosInstance.get(url);
      analyticsData.current = response.data;
      setHighestTopPerforming(Math.max(...analyticsData.current.top_performing.map(item => item.open_count)))
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Analytics data'});
	  }

    getChartData();
  }

  const getChartData = async () => {
    let time_period, custom_start_date, custom_end_date;

    if (analyticsDefaultFilter) {
      time_period = analyticsDefaultFilter;
    } else if (analyticsCustomFilter[0]) {
      [custom_start_date, custom_end_date] = analyticsCustomFilter;

      custom_start_date = dayjs(custom_start_date).format('YYYY-MM-DD');
      custom_end_date = dayjs(custom_end_date).format('YYYY-MM-DD');
    } else if (!analyticsDefaultFilter && !analyticsCustomFilter[0]) {
      setAnalyticsDefaultFilter("last_7_days");
      time_period = "last_7_days";
    }

    try {
      let url
      if(window.location.href.split('/')[3] === "share") {
        url = `/share_engage/campaign_aggregated_chart_analytics/${userId}?company_id=${companyId}`;
      }
      else {
        url = `/engage/campaign_aggregated_chart_analytics/${userId}?company_id=${companyId}`;
      }
      if (time_period) {
        url += `&time_period=${time_period}`;
      }

      if (custom_start_date) {
        url += `&custom_start_date=${custom_start_date}&custom_end_date=${custom_end_date}`;
      }

      if(selectedCampaign !== "" ) {
        url+= `&campaign_id=${selectedCampaign}`
      }

			const response = await axiosInstance.get(url);
      setAnalyticsChartData(response.data);
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Analytics chart data'});
	  }

    setLoading(0);
  }

  const getTextColor = percentage => {
    if (!percentage) return;
    let className;

    if (percentage.indexOf("-") === 0) {
      className = "text-danger";
    } else {
      className = "text-success";
    }

    return className;
  }

  const zetAnalyticsDefaultFilter = value => {
    setAnalyticsCustomFilter([null, null]);
    setAnalyticsDefaultFilter(value);
  }

  const zetAnalyticsCustomFilter = dates => {
    let date;

    if (!dates) {
      date = [null, null];
    } else {
      date = [dayjs(dates[0].$d).format('YYYY-MM-DD'), dayjs(dates[1].$d).format('YYYY-MM-DD')];

      const dateDiff = dayjs(date[1]).diff(date[0], 'day');
      if (dateDiff > 365) {
        dates = [null, null];
        setAnalyticsDateErrorModalShown(true);
      }
    }

    setAnalyticsDefaultFilter(null);
    setAnalyticsCustomFilter(dates);
  }

  const closeAnalyticsDateErrorModal = () => {
    setAnalyticsDateErrorModalShown(false);
  }

    const copyShareUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    notification.success({message: 'Success', description: 'Share link has been copied successfully'});
  }

  const closeShareModal = () => {
    setShareModalShown(false);
  }

  const getCampaignList = async () => {
    setLoading(1);
    try {
      let response 
      if(window.location.href.split('/')[3] === "share") {
        response = await axiosInstance.get(
        `/share_engage/all_campaign_list/${userId}?company_id=${companyId} `
      );
    }
    else {
      response = await axiosInstance.get(
        `/v2_engage/all_campaign_list/${userId}?company_id=${companyId} `
      );
    }
      setCampaignList(response.data);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error in fetching campaigns List",
      });
    }
    setLoading(0);
  };

  const disabledDate = (current) => {
    // Disable future dates
    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    if (pageLoaded.current) {
      return;
    }
    if(window.location.href.split('/')[3] === "share") { 
      setSharedLink(true)
    }
    
    pageLoaded.current = true;
    getCampaignList() 
    getAnalyticsData();
  }, []);

  useEffect(() => {
    if (loading === 1) {
      return;
    }
    
    getAnalyticsData();
  }, [analyticsDefaultFilter, analyticsCustomFilter, selectedCampaign]);

 

  return (
    <Layout style={{minHeight: '100vh', padding: 15}} className="bootstrap">
      {/* Header */}
      <Row gutter={30} className="text-white pb-4">
        <Col span={10} className="d-flex align-items-center">
          <div className="analytics-heading font-weight-600 pe-2">Email Analytics</div>
          <div className="lead-status-btn inactive d-none align-items-center pointer br-13 me-2">
            <img src="/assets/images/download-gray-2x.png" width="18" height="auto" alt="icon" />
            <span className="text-nowrap white-text-71 ps-2">View Campaign</span>
          </div>
        </Col>
        <Col span={14} className="d-flex justify-content-end align-items-center">

                        <Select
            placeholder="All Campaigns"
            suffixIcon={<img src="/assets/svg/dropdown-arrow.svg" width="12" height="auto" alt="down" />}
            options={[
              // { label: "Select", value: "Select" }, 
              ...campaignList?.map((campaign) => ({
                label: campaign.campaign_name,
                value: campaign.campaign_id,
              }))
            ]}
            // value={analyticsDefaultFilter}
            onChange={value => setSelectedCampaign(value)}
            className="analytics-select me-2"
            style={{width:"250px"}}
          />
          
          <Select
            suffixIcon={<img src="/assets/svg/dropdown-arrow.svg" width="12" height="auto" alt="down" />}
            options={[
              {label: "", value: null},
              {label: "Last 7 days", value: "last_7_days"},
              {label: "Month to Date", value: "month_to_date"},
              {label: "Last 4 Weeks", value: "last_4_weeks"},
              {label: "Last 3 Months", value: "last_3_months"}
            ]}
            value={analyticsDefaultFilter}
            onChange={value => zetAnalyticsDefaultFilter(value)}
            className="w-150px analytics-select me-2"
          />
          
          <RangePicker
            className={"position-relative custom-range-picker analytics-date-picker"
            + (analyticsCustomFilter[0] ? " hide-placeholder" : "")}
            placeholder={null}
            value={analyticsCustomFilter}
            onChange={dates => zetAnalyticsCustomFilter(dates)}
            suffixIcon={<img src="/assets/svg/dropdown-arrow.svg" width="12" height="auto" alt="down" />}
            style={{width: "310px"}}
            disabledDate={disabledDate}
          />

                 {!sharedLink && <div
                            style={{
                                background: 'rgba(30, 117, 255, 1)',
                                width: '127px',
                                height: '32.6px',
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '32.6px',
                                letterSpacing: '0em',
                                textAlign: 'center',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRadius: '4.18px',
                                cursor: 'pointer'
                            }}
                            className="me-2 pointer ms-2" 
                            onClick={() => setShareModalShown(true)}
                        >
                            Share
                        </div> }
        </Col>
      </Row>

      {/* Main */}
      <Row gutter={30} id="engage-account-main" align="stretch">
        {/* Loader */}
        <div className={"loader2 w-100 flex-column justify-content-center align-items-center "
          + (loading === 0 ? "d-none" : "d-flex")}>
          <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
          <div className={"pt-4 text-center" + (loading === 1 ? "" : " d-none")}>
            <Spin size="large" />
          </div>
        </div>

        <Col span={24} className={"gutter-row" + (loading === 1 ? " d-none" : "")}>
          <div>
            <Row className=" pb-4">
              <div className="custom-card analytics-card br-13 mb-2 me-2" style={{width: "calc((100% - 50px)/6)"}}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Unique Contacted</span>
                  {/* <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="auto" alt="icon" /> */}
                </div>

                <div className="text-white analytics-count">{analyticsData.current.contacted}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.contacted_percentage)}>
                    {analyticsData.current.contacted_percentage}
                  </span>
                  <span className="white-text-71 analytics-percent"> from last week</span>
                </div>
              </div>
              <div className="custom-card analytics-card br-13 mb-2 me-2" style={{width: "calc((100% - 50px)/6)"}}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Emails Sent</span>
                  {/* <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="auto" alt="icon" /> */}
                </div>

                <div className="text-white analytics-count">{analyticsData.current.contacted}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.contacted_percentage)}>
                    {analyticsData.current.contacted_percentage}
                  </span>
                  <span className="white-text-71 analytics-percent"> open rate</span>
                </div>
              </div>

              <div className="custom-card analytics-card br-13 me-2" style={{width: "calc((100% - 50px)/6)"}}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Unique Opened</span>
                  {/* <img src="/assets/images/mail-open-gray-2x.png" width="17" height="auto" alt="icon" /> */}
                </div>

                <div className="text-white analytics-count">{analyticsData.current.opened}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.opened_rate)}>
                    {analyticsData.current.opened_rate}%
                  </span>
                  <span className="white-text-71 analytics-percent"> reply rate</span>
                </div>
              </div>

              <div className="custom-card analytics-card br-13 me-2" style={{width: "calc((100% - 50px)/6)"}}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Total Opens</span>
                  {/* <img src="/assets/images/mail-open-gray-2x.png" width="17" height="auto" alt="icon" /> */}
                </div>

                <div className="text-white analytics-count">{analyticsData.current.opened}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.total_opened_rate)}>
                    {analyticsData.current.total_opened_rate}%
                  </span>
                  <span className="white-text-71 analytics-percent"> from last week</span>
                </div>
                <div style={{ float: "right" }}>
                  <TooltipNative
                    title="This is the number of opened messages."
                    overlayInnerStyle={{
                      backgroundColor: "#16161D",
                      margin: "0%",
                      border: "0.75px solid #949497",
                      borderRadius: "5px",
                      paddingLeft: "1%",
                      width: "150px"
                    }}
                    className="pointer"
                  >
                    <InfoCircleOutlined style={{ color: "white" }} />
                  </TooltipNative>
                </div>
              </div>

              <div className="custom-card analytics-card br-13 me-2" style={{width: "calc((100% - 50px)/6)"}}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Replied</span>
                  {/* <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="16" height="auto" alt="icon" /> */}
                </div>

                <div className="text-white analytics-count">{analyticsData.current.replied}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.reply_rate)}>
                    {analyticsData.current.reply_rate}%
                  </span>
                  <span className="white-text-71 analytics-percent"> from last week</span>
                </div>
                <div style={{ float: "right" }}>
                  <TooltipNative
                    title="This is the number of responses you received (not counting out of office messages)."
                    overlayInnerStyle={{
                      backgroundColor: "#16161D",
                      margin: "0%",
                      border: "0.75px solid #949497",
                      borderRadius: "5px",
                      paddingLeft: "1%",
                      width: "150px"
                    }}
                    className="pointer"
                  >
                    <InfoCircleOutlined style={{ color: "white" }} />
                  </TooltipNative>
                </div>
              </div>

              <div className="custom-card analytics-card br-13 me-2" style={{width: "calc((100% - 50px)/6)"}}>
                <div className="d-flex align-items-center pb-3">
                  <span className="text-white fw-bold pe-2">Opportunities</span>
                  {/* <img src="/assets/images/jet-gray-2x.png" width="19" height="auto" alt="icon" /> */}
                </div>

                <div className="text-white analytics-count">{analyticsData.current.opportunities}</div>

                <div className={analyticsDefaultFilter === "last_7_days" ? "" : "d-none"}>
                  <span
                    className={getTextColor(analyticsData.current.opportunities_percentage)}>
                    {analyticsData.current.opportunities_percentage}
                  </span>
                  <span className="white-text-71 analytics-percent"> from last week</span>
                </div>
                <div style={{ float: "right" }}>
                  <TooltipNative
                    title="This is the number of interested leads you have from this campaign."
                    overlayInnerStyle={{
                      backgroundColor: "#16161D",
                      margin: "0%",
                      border: "0.75px solid #949497",
                      borderRadius: "5px",
                      paddingLeft: "1%",
                      width: "150px",
                    }}
                    className="pointer"
                  >
                    <InfoCircleOutlined style={{ color: "white" }} />
                  </TooltipNative>
                </div>
              </div>
            </Row>

              <Row className="campaign-detail-analytics analytics-graph">
              {analyticsChartData[0]?.empty_set?
              <div className="d-flex flex-column w-100">
              <div className="ms-2 mt-2 graph-view text-nowrap">Graph View</div>
              <div className="align-items-center d-flex flex-column justify-content-center w-100 h-100">
                <div className="no-data mb-4">No data to display.</div>
                <div className="chart-new-campaign px-3 py-2"
                onClick={() => navigate('/engage/campaign-form')}>New Campaign</div>
              </div> 
              </div> 
              : 
                <div className="w-100 br-13">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={analyticsChartData}
                      margin={{
                        top: 15,
                        right: 15,
                        left: -23,
                        bottom: 15,
                      }}
                    >
                      <XAxis dataKey="name" stroke="#fff" axisLine={{ stroke: "transparent" }} />
                      <YAxis stroke="#fff" axisLine={{ stroke: "transparent" }} />
                      <Tooltip cursor={{ fill: "#202029" }} />
                      <Legend
                        verticalAlign="top"
                        height={36}
                        iconSize={20}
                        wrapperStyle={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          display: 'flex',
                          top: 0,
                          // justifyContent: 'center'
                        }}  // Adjust spacing
                        content={props => {
                          const { payload } = props;

                          return (
                            <div className="d-flex flex-row justify-content-between text-nowrap ms-3 mb-2 w-100">
                              <div className="graph-view">Graph View</div>
                              <div className="">
                                {payload.map((entry, index) => (
                                  <div key={`item-${index}`} className="d-flex" >
                                    <div style={{
                                      width: '10px',
                                      height: '10px',
                                      backgroundColor: entry.color,
                                      marginRight: '5px',
                                      marginTop: "6%",
                                      borderRadius: "10px"
                                    }}
                                    />
                                    <span style={{ color: entry.color }} className="text-capitalize">{entry.value}</span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        }}
                      />

                      <Bar dataKey="outbound" fill="#1E75FF" barSize={20} radius={5} />
                      <Bar dataKey="opens" fill="#3BCD92" barSize={20} radius={5} />
                      <Bar dataKey="replies" fill="#B03BCD" barSize={20} radius={5} />
                    </BarChart>
                  </ResponsiveContainer>
                </div> 
              } 
              </Row>
            
            <Row className="mt-2 analytics-graph">
              <div className="d-flex flex-column w-100">
                <div className="top-performing mb-4 ps-3 pt-2 text-nowrap">Top Performing Accounts</div>
                { analyticsData.current.empty_set ? 
                <div className="align-items-center d-flex flex-column justify-content-center w-100 h-100 pb-4">
                <div className="no-data mb-4">No data to display.</div>
                <div className="chart-new-campaign px-3 py-2 mb-4"
                onClick={() => navigate('/engage/campaign-form')}>New Campaign</div>
                </div>  
                : <div>
                <div className="d-flex flex-row justify-content-between email-opens mb-2 px-4">
                  <div>
                    Email
                  </div>
                  <div>Opens</div>
                </div>
                    {analyticsData.current?.top_performing?.map(i =>
                      <div className="d-flex flex-row justify-content-between mb-2 px-4">
                        <div className="top-performing-bar py-2 ps-2" style={{ width: `calc((${i.open_count} / ${highestTopPerforming}) * 80%)` }}>
                          {i.account_email}
                        </div>
                        <div className="white-text">{i.open_count}</div>
                      </div>
                    )}

                </div> }
              </div>
                </Row>

            {/*       
            <Row className="pb-2">
              <div className="d-flex align-items-center">
                <div className="white-text-71 pe-2">Hot Leads this Week</div>
                <img src="/assets/images/fire-white-2x.png" width="12" height="auto" alt="icon" className="me-2" />
                <TooltipNative
                  title="These leads have been the most engaged with your recent outreach"
                  className="pointer"
                >
                  <img src="/assets/images/info-slant-gray-2x.png" width="18" height="auto" alt="icon" />
                </TooltipNative>
              </div>
            </Row>

            <Row gutter={20}>
              <Col span={4} className="gutter-row">
                <div className="p-3 bg-darkgray br-10">
                  <div className="d-flex justify-content-between align-items-start pb-4">
                    <img src="/assets/images/user-profile.png" width="50" height="auto" alt="dp" />
                    <div className="p-2 text-white br-10 hot-leads-reply">9 Replies</div>
                  </div>
                  <div className="fs-6 fw-semibold text-white pb-2">John Ross</div>
                  <div className="small white-text-62">CTO at Monty.so</div>
                </div>
              </Col>
              <Col span={4} className="gutter-row">
                <div className="p-3 bg-darkgray br-10">
                  <div className="d-flex justify-content-between align-items-start pb-4">
                    <img src="/assets/images/user-profile.png" width="50" height="auto" alt="dp" />
                    <div className="p-2 text-white br-10 hot-leads-reply">9 Replies</div>
                  </div>
                  <div className="fs-6 fw-semibold text-white pb-2">John Ross</div>
                  <div className="small white-text-62">CTO at Monty.so</div>
                </div>
              </Col>
              <Col span={4} className="gutter-row">
                <div className="p-3 bg-darkgray br-10">
                  <div className="d-flex justify-content-between align-items-start pb-4">
                    <img src="/assets/images/user-profile.png" width="50" height="auto" alt="dp" />
                    <div className="p-2 text-white br-10 hot-leads-reply">9 Replies</div>
                  </div>
                  <div className="fs-6 fw-semibold text-white pb-2">John Ross</div>
                  <div className="small white-text-62">CTO at Monty.so</div>
                </div>
              </Col>
              <Col span={4} className="gutter-row">
                <div className="p-3 bg-darkgray br-10">
                  <div className="d-flex justify-content-between align-items-start pb-4">
                    <img src="/assets/images/user-profile.png" width="50" height="auto" alt="dp" />
                    <div className="p-2 text-white br-10 hot-leads-reply">9 Replies</div>
                  </div>
                  <div className="fs-6 fw-semibold text-white pb-2">John Ross</div>
                  <div className="small white-text-62">CTO at Monty.so</div>
                </div>
              </Col>
              <Col span={4} className="gutter-row">
                <div className="p-3 bg-darkgray br-10">
                  <div className="d-flex justify-content-between align-items-start pb-4">
                    <img src="/assets/images/user-profile.png" width="50" height="auto" alt="dp" />
                    <div className="p-2 text-white br-10 hot-leads-reply">9 Replies</div>
                  </div>
                  <div className="fs-6 fw-semibold text-white pb-2">John Ross</div>
                  <div className="small white-text-62">CTO at Monty.so</div>
                </div>
              </Col>
              <Col span={4} className="gutter-row">
                <div className="p-3 bg-darkgray br-10">
                  <div className="d-flex justify-content-between align-items-start pb-4">
                    <img src="/assets/images/user-profile.png" width="50" height="auto" alt="dp" />
                    <div className="p-2 text-white br-10 hot-leads-reply">9 Replies</div>
                  </div>
                  <div className="fs-6 fw-semibold text-white pb-2">John Ross</div>
                  <div className="small white-text-62">CTO at Monty.so</div>
                </div>
              </Col>
            </Row>
            */}
          </div>
        </Col>
      </Row>

      {/* Modals */}
      <Modal
        title=" "
        open={analyticsDateErrorModalShown}
        onOk={closeAnalyticsDateErrorModal}
        onCancel={closeAnalyticsDateErrorModal}
        maskClosable={false}
        footer={null}
        centered
        className="bootstrap custom-modal"
      >
        <Result
          status="error"
          title="Error"
          subTitle="Date range must be within 365 days"
          extra={<Button className="black-btn" onClick={closeAnalyticsDateErrorModal}>Close</Button>}
        />
      </Modal>
      <Modal
        title=" "
        open={shareModalShown}
        onOk={closeShareModal}
        onCancel={closeShareModal}
        maskClosable={true}
        closable={false}
        footer={null}
        centered
        className="bootstrap custom-modal show-close"
        width={550}
      >
        <div className="d-flex flex-column ">
            <div className="ms-2 mb-2 pointer" onClick={closeShareModal}><img src="/assets/svg/close-circle.svg" /></div>

            <div className="share-campaign-text pb-4 justify-content-center d-flex mb-3">🎉  Share Campaign</div>
          <Input
            className="custom-input-analytics mb-4"
            value={shareUrl}
            suffix={<div className="copy-link-analytics py-1 px-2 pointer"
            onClick={copyShareUrl}>Copy Link</div>}
          />
          <div className="align-items-center">
          <span className="blue-font analytics-warning">Remember</span> 
          <span className="analytics-warning text-white">{ } : You do not need a Pipeline account to view the analytics in this link. <br />This link will not work once the original campaign is deleted. </span>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default Analytics;
