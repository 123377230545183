import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ConfigProvider, Menu } from 'antd';
import { LoaderProvider } from '../../store/loaderContext';
import { useNavigate } from 'react-router-dom';
import {settingsMenuItems} from '../../constants/Settings-Menu'
import { useSelector } from 'react-redux';

import '../../assets/Styles/Settings-SideMenu.css'

const SideMenu = ({ children}) => {

  const navigate = useNavigate();
  const { SubMenu } = Menu;
  const location = useLocation();
  let { pathname } = location;
  const [selectedKeys, setSelectedKeys] = useState();
  const authData = useSelector(state => state.auth);
const companyData = useSelector(state => state.companyReducer.selectedCompany);

const userId = authData.userInfo.is_super_admin ? companyData.user_id : authData.userInfo.userid;
const companyId = companyData.company_id;
const isSuperAdmin = authData.userInfo.is_super_admin;
const editAccess = authData.userInfo.isViewer === false ? true : false;

  const isSameMenuItem = ({ key }) => {
    if (key === selectedKeys[0]) {
      window.location.reload();
    }
  }

  useEffect(() => {
    setSelectedKeys([pathname]);
  }, [location]);

  return (
    <div className="bootstrap d-flex flex-row">
      {location.pathname !== '/auth' &&
      <div className='settings-sidemenu-background'>
        <div className='d-flex flex-row pointer mb-4 ms-3' onClick={() => window.location.assign('/engage/campaign-list')}>
          <img src="/assets/svg/settings-backarrow.svg" />
          <span className='settings-sidemenu-header ms-3'>Settings</span>
        </div>
        <Menu
          selectedKeys={selectedKeys}
          // theme="dark"
          mode="inline"
          className="settings-menu settings-menu-item"
          onClick={itemProps => isSameMenuItem(itemProps)}
        > {settingsMenuItems.map(menu =>
          menu.isMenu &&<div className='mb-4'>
            <div className='d-flex flex-row settings-menu mb-2 ms-3'>
              <img src={menu.icon} className='me-2' />
                <span style={{paddingTop:"1%"}}>{menu.label}</span>
            </div>
            {menu.subMenuItems.map(subMenu => 
                 <Menu.Item className={'settings-submenu settings-menu-item' + (subMenu.key === pathname ? " selected-menu" : "") 
                  + ((subMenu.key === "/settings-new/billing") && !editAccess ? " disabled-settings-menu" : "")} 
                  key={subMenu.key} >
                  <Link to={subMenu.route} style={{textDecoration:"none"}}>
                    <span style={{paddingLeft:"25px"}}>{subMenu.label}</span></Link></Menu.Item>
            )}
          </div>
        )}</Menu>
        <div 
            className='bottom-question'
          >
            <img src="/assets/svg/question-mark.svg" />
          </div>
      </div>
}
      <LoaderProvider>
        {children}
      </LoaderProvider>
    </div>
  );
};

export default SideMenu;
