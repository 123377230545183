import React from "react";
import ImageComponent from "./ImageComponent";

const LogoBackground = ({
		customStyle = {},
		backgroundImage="/assets/svg/eclipse.svg",
		backgroundImageStyle = {},
		absoluteImageStyle={
			position:'absolute',
			// left:'44%',
			// top:'29%'
		}
}) => {
     return (
          <div className="logo-container" style={customStyle}>
							<ImageComponent 
								src={backgroundImage}
								style={backgroundImageStyle}
								/>
							<ImageComponent 
								src="/assets/svg/logo.svg"
								style={absoluteImageStyle} 
							/>
          </div>
     )
}

export default LogoBackground;