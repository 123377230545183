import { t } from '@wangeditor/editor';
import React from 'react';

const CustomPaginationProspecting = ({totalItems, noOfRecords, currentPage, onChange}) => {
  if (!noOfRecords) {
    noOfRecords = 25;
  }
  const totalPages = Math.ceil(totalItems/noOfRecords);

  const startItem = ((currentPage - 1) * noOfRecords) + 1;
  let endItem = (startItem - 1) + noOfRecords;
  if (endItem > totalItems) {
    endItem = totalItems;
  }

  const handlePrev = () => {
    const page = currentPage - 1;
    const start = startItem - (noOfRecords + 1);
    const end = start + noOfRecords;

    onChange({page, start, end});
  };

  const handleNext = () => {
    const page = currentPage + 1;
    const start = (startItem - 1) + noOfRecords;
    const end = start + noOfRecords;

    onChange({page, start, end});
  };

  return (
    <div className="d-flex mt-4 justify-content-center ">
      <div className='pagination-style d-flex flex-row justify-content-between'>
      <div
        {...(currentPage !== 1 && {onClick: handlePrev})}
        className={"ps-1 pe-1"
          + (currentPage === 1 ? " text-secondary disabled-upload" : " pointer pagination-arrow")}
      ><img src="/assets/svg/right-arrow.svg"/></div>
      <div className='' style={{paddingTop: "1%"}}>{(noOfRecords * currentPage) > totalItems ? totalItems?.toLocaleString() : (noOfRecords * currentPage)?.toLocaleString()}/{totalItems?.toLocaleString()}</div>
     <div
        {...(currentPage !== totalPages && {onClick: handleNext})}
        className={"pe-1 ps-1" + (currentPage === totalPages ? " text-secondary disabled-upload" : " pointer pagination-arrow")}
      ><img src="/assets/svg/left-arrow.svg"/></div>
  </div>
      {/* <span className="text-secondary small">{startItem} - {endItem} of {totalItems} records</span> */}
    </div>
  );
};

export default CustomPaginationProspecting;
