import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Input,
  Layout,
  List,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Tag,
} from "antd";
import { PlusSquareOutlined } from '@ant-design/icons';
import { DownloadIcon, AnalyticsDocIcon } from "../../assets/svg/Icons";
import axiosInstance from "../../config/axiosConfig";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import getLeadCategoryIcon from "../../common/getLeadCategoryIcon";

import $ from "jquery";
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import CustomEditorButtons from "../../component/CustomEditorButtons";
import insertLineBreak from "../../common/insertLineBreak";
import normalizeHtml from "../../common/normalizeHtml";

const toolbarConfig = {
  toolbarKeys: [
    'insertLink',
    // 'uploadImage',
    'bold',
    'italic',
    'underline',
    'bulletedList',
    'numberedList',
  ]
};

const Inbox = () => {
  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ?  companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const pageLoaded = useRef(false);
  const isLoading = useRef(false);
  const inboxMessages = useRef({total_leads: 0, leads: []});
  const inboxFilters = useRef([]);
  const selectedMsgIndex = useRef(0);
  const leadActivity = useRef({});

  const [loading, setLoading] = useState(1);
  const [tab, setTab] = useState("inbox");
  const [selectedThread, setSelectedThread] = useState({email_history: []});
  const [currentThreadIndex, setCurrentThreadIndex] = useState(0);
  const [expandedThreads, setExpandedThreads] = useState([0]);
  const [selectedInboxFilters, setSelectedInboxFilters] = useState([]);
  const [inboxSearchString, setInboxSearchString] = useState("");
  const [leadCategoryValue, setLeadCategoryValue] = useState(null);
  const [replyModalOpen, setReplyModalOpen] = useState(false);
  const [forwardModalOpen, setForwardModalOpen] = useState(false);
  const [inboxPageNo, setInboxPageNo] = useState(1);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [openDontContact, setOpenDontContact] = useState(false);
  const [blockList, setBlockList] = useState(false);
  const [sidebarTab, setSidebarTab] = useState("details"); // details, activity
  const [signatureContent, setSignatureContent] = useState(false); 
  const [cc, setCc] = useState(""); 
  const [bcc, setBcc] = useState(""); 
  const [toFwdMail, setToFwdMail] = useState(""); 

  const [editor, setEditor] = useState(null);
  const [html, setHtml] = useState(null);
  const [emailIds, setEmailIds] = useState([])
  const [selectedEmailIds, setSelectedEmailIds] = useState([])
  const [inboxSync, setInboxSync] = useState("");
  const { Option } = Select

  const editorConfig = {
    placeholder: 'Type here...',
    MENU_CONF: {
      uploadImage: {
        server: 'http://localhost:3000/upload',
        fieldName: 'image_input',
        maxFileSize: 1 * 1024 * 1024, // 1M
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
        headers: {
          Accept: 'text/x-json',
          otherKey: 'xxx'
        },
        withCredentials: false,
        timeout: 5 * 1000, // 5 secs
        onBeforeUpload(file) {
          return file;
  
          // You can return:
          // 1. return the file object or a new file object. Editor will upload it.
          // 2. return false. Stop upload this file.
        },
        onProgress(progress) {
          // progress is a number 0-100
          console.log('progress', progress);
        },
        onSuccess(file, res) {
          console.log(`${file.name} uploaded`, res)
        },
        onFailed(file, res) {
          console.log(`${file.name} failed`, res)
        },
        onError(file, err, res) {
          console.log(`${file.name} error`, err, res)
        }
      },
    }
  }

  const getLeadCategories = async () => {
    try {
      const filters = [];

      filters.push({
        key: "replied",
        value: "replied",
        name: "replied",
        label: <div className="bootstrap">
          <div className="d-flex align-items-center">
            <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="16" height="auto" alt="icon" />
            <span className="ps-2">Replied</span>
          </div>
        </div>
      });

			const response = await axiosInstance.get(`/engage_inbox/lead_categories`);
      response.data.forEach(filter => {
        const row = {
          key: filter.id,
          value: filter.id,
          name: filter.name,
          label: <div className="bootstrap">
            <div className="d-flex align-items-center">
              <img src={`/assets/images/${getLeadCategoryIcon(filter.name)}`} width="16" height="auto" alt="icon" />
              <span className="ps-2">{filter.name}</span>
            </div>
          </div>
        }
        filters.push(row);
      });

      inboxFilters.current = [...filters];
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Lead categories'});
	  }

    // getInboxMessages();
  }

  const getEmailIds = async () => {
    isLoading.current = true;
    setLoading(1);
    const filters = [];

    try {
      const response = await axiosInstance.get(`/engage/accounts_list/${userId}?company_id=${companyId}&inbox_accounts=true`);

      setEmailIds(response.data)
    } catch (error) {
      notification.error({ message: 'Error', description: 'Error in fetching Email Ids' });
    }

    isLoading.current = false;
    setLoading(0);
  }

  const getInboxMessages = async (emailSelected, leadFilters, emailFilters ) => {
    isLoading.current = true;
    setLoading(1);

    try {
      let url = `/engage_inbox/inbox_message/${userId}?company_id=${companyId}`;
      let selectLeadFilters = [], selectMailFilters = []
      if (leadFilters !== null && leadFilters !== undefined) {
        selectLeadFilters = [...leadFilters]
      }
      else {
        selectLeadFilters = [...selectedInboxFilters]
      }
      if (emailFilters !== null && emailFilters !== undefined) {
        selectMailFilters = [...emailFilters]
      }
      else {
        selectMailFilters = [...selectedEmailIds]
      }
      let repliedIndex, inboxFilters, savingLeads = [], savingMails= [];

      if (selectLeadFilters.length) {
        repliedIndex = selectLeadFilters.findIndex(f => f === "replied");

        if (repliedIndex > -1) {
          inboxFilters = selectLeadFilters.toSpliced(repliedIndex, 1);
          url += "&status=replied";
          savingLeads.push("replied")
        } else {
          inboxFilters = [...selectLeadFilters];
          savingLeads = [...selectLeadFilters];
        }

        if (inboxFilters.length) {
          url += `&lead_category_ids=${inboxFilters.toString()}`;
          if(repliedIndex > -1) {
            savingLeads = [...savingLeads, ...inboxFilters]
          }
          else {
            savingLeads = [...inboxFilters]
          }
        }
      }

      if (inboxSearchString.length) {
        url += `&search=${inboxSearchString}`;
      }

      if(emailSelected !== false && selectMailFilters.length){
        savingMails = [...selectMailFilters];
        url +=`&email_accounts=${selectMailFilters.toString()}`
      }

      url += `&page=${inboxPageNo}&limit=10`;
			const response = await axiosInstance.get(url);
      setInboxSync(response.data.manual_sync_inbox)
      const fetchedLeads = response.data.leads;
      const oldLeads = [...inboxMessages.current.leads];

      inboxMessages.current = {total_leads: response.data.total_leads, leads: [...oldLeads, ...fetchedLeads]};

      const payload = {
        email_accounts_selected : [...savingMails],
        view_by_status_selected : [...savingLeads]
      }

      await axiosInstance.put(`/engage_inbox/update_inbox_filters/${userId}?company_id=${companyId}`, payload)

		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching Inbox messages'});
	  }

    isLoading.current = false;
    setLoading(0);
    setTimeout(clickThread, 0);
  }

  const getSavedFilters = async () => {
    
      try {
        const response = await axiosInstance.get(`/engage_inbox/get_inbox_filters/${userId}?company_id=${companyId}`);
        console.log("Response from getSavedFilters:", response);
        return response
      } catch (error) {
        console.error('Error in fetching Inbox filters:', error);
      } 
  }
  
  const loadMoreData = () => {
    const loadDataInterval = setInterval(() => {
      if (isLoading.current === false) {
        clearInterval(loadDataInterval);
        setInboxPageNo(inboxPageNo => inboxPageNo + 1);
      }
    }, 500);
  };

  const removeHtml = (html, preserveLineBreaks = false) => {
    let gmailThreadIndex = html?.indexOf('<div class="gmail_quote">');
    if (gmailThreadIndex < 0) {
      gmailThreadIndex = html?.indexOf('<blockquote class="gmail_quote">');
    }
    
    const context = gmailThreadIndex < 0 ? html : html?.substr(0, gmailThreadIndex);
    let message = normalizeHtml(context);

    let response;

    if (preserveLineBreaks) {
      response = message;
    } else {
      const div = document.createElement("div");
      div.innerHTML = message;

      response = div.textContent;
    }
    
    return response;
  }

  const getSubject = (emailHistory, index = 0) => {
    const subject = emailHistory[index].subject;

    if (subject) {
      return subject;
    }

    const statsId = emailHistory[index].stats_id;
    const email = emailHistory.find(e => e.stats_id === statsId && e.subject);

    if (email) {
      return email.subject;
    } else {
      return "";
    }
  }

  const expandAll = () => {
    const emailHistoryLength = selectedThread.email_history.length;
    const indexes = [];

    for (let a = 0; a < emailHistoryLength; a++) {
      indexes.push(a);
    }

    setExpandedThreads([...indexes]);
  }

  const setInboxFilters = ({key}) => {
    const filters = [...selectedInboxFilters];
    const index = filters?.indexOf(key);

    if (index > -1) {
      filters.splice(index, 1);
    } else {
      filters.push(key);
    }

    setSelectedInboxFilters([...filters]);
  }

  const setSelectedMails = (e) => {

    setSelectedEmailIds(e);
  }

  const triggerInboxSearch = () => {
    const searchValue = document.querySelector("#inbox-search-input").value;

    if (!searchValue || searchValue.length < 1) {
      setInboxSearchString("");
    } else {
      setInboxSearchString(searchValue);
    }
  }

  const updateLeadCategory = async lead_category_id => {
     if(lead_category_id === 4) {
      setOpenDontContact(true);
    } else {
      updateLead(lead_category_id);
    }
  }

  const blockUser = async () => {
    setLoading(1);

    try {
      const payload = {
        lead_id : selectedThread.id,
        company_id: companyId,
        block_type: blockList ? "company_url" : "person_email"
      }

			await axiosInstance.post(`/settings/block_user/${userId}`, payload);
      updateLead(4);
    } catch(error){
      setLoading(0);
      notification.error({message: 'Error', description: 'Error in blocking user'});
    }
  }

  const updateLead = async(lead_category_id) => {
    setLoading(1);

    const payload = {lead_category_id, user_id: userId, block: blockList};

    try {
			await axiosInstance.put(`/engage_inbox/update_lead_category/${selectedThread.id}`, payload);
      notification.success({message: 'Success', description: 'Lead category has been updated successfully'});

      const allLeads = [...inboxMessages.current.leads];
      const filteredRow = inboxFilters.current.find(f => f.value === lead_category_id);

      allLeads[selectedMsgIndex.current].lead_category = filteredRow.name;
      inboxMessages.current.leads = [...allLeads];

      setSelectedThread({...allLeads[selectedMsgIndex.current]});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in updating Lead category'});
	  }

    setLoading(0);
  }

  const getLeadCategoryValue = () => {
    if (selectedThread.email_history.length < 1) {
      setLeadCategoryValue(null);
      return;
    };

    const filteredRow = inboxFilters.current.find(f => f.name === selectedThread.lead_category);

    if (!filteredRow) {
      setLeadCategoryValue(null);
      return;
    };

    setLeadCategoryValue(filteredRow.value);
  }

  const closeReplyModal = () => {
    setReplyModalOpen(false);
  }
  const closeForwardModal = () => {
    setForwardModalOpen(false);
  }

  const replyToLead = async () => {
    setLoading(1);

    const editorContent = editor.getHtml();
    const emailHistory = selectedThread.email_history;
    const currentThread = emailHistory[currentThreadIndex];
    const replayEmail = emailHistory.find(email => email.type === "REPLY" && email.stats_id === currentThread.stats_id);

    if (!replayEmail) {
      closeReplyModal();
      setLoading(0);
      return;
    }

    const payload = {
      user_id: userId,
      email_stats_id: currentThread.stats_id,
      email_body: editorContent,
      reply_message_id: replayEmail.message_id,
      reply_email_time: replayEmail.time,
      reply_email_body: replayEmail.email_body,
      signature: signatureContent,
      cc: cc,
      bcc: bcc
    }

    try {
			await axiosInstance.post(`/engage_inbox/reply_to_lead/${selectedThread.campaign_id}`, payload);
      notification.success({message: 'Success', description: 'Reply has been sent successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: error?.response?.data?.detail});
	  }

    closeReplyModal();
    setLoading(0);
  }

  const fowardToLead = async () => {
    setLoading(1);

    const editorContent = editor.getHtml();
    const emailHistory = selectedThread.email_history;
    const currentThread = emailHistory[currentThreadIndex];
    const replayEmail = emailHistory.find(email => email.type === "REPLY" && email.stats_id === currentThread.stats_id);

    if (!replayEmail) {
      closeReplyModal();
      setLoading(0);
      return;
    }

    const payload = {
      campaign_id: selectedThread.campaign_id,
      stats_id: currentThread.stats_id,
      message_id: replayEmail.message_id,
      to_emails: toFwdMail,
      lead_id: selectedThread.id
    }

    try {
			await axiosInstance.post(`/engage_inbox/email_forwarding/${userId}?company_id=${companyId}`, payload);
      notification.success({message: 'Success', description: 'Forwarded successfully'});
      setForwardModalOpen(false)
		} catch (error) {
      notification.error({message: 'Error', description: error?.response?.data?.detail});
	  }

    closeReplyModal();
    if (inboxPageNo !== 1) {
      setInboxPageNo(1);
    } else {
      getInboxMessages();
    }
    setLoading(0);
  }

  const addSignature = (value) => {
    setSignatureContent(value)
  }

  const clickThread = () => {
    const firstInboxMsg = document.querySelector("#email-thread-" + selectedMsgIndex.current);

    if(firstInboxMsg) {
      firstInboxMsg.click();
    } else {
      setSelectedThread({email_history: []});
    }
  }

  const openWangEditor = i => {
    if (editor !== null) {
      $('.wang-editor').off();
      $('.wang-inbox-toolbar').first().remove();
      editor.destroy();
      setEditor(null);
      setHtml("");
    }

    setCurrentThreadIndex(i);
    setReplyModalOpen(true);
  }

  const openForwardWangEditor = (i, history) => {
    if (editor !== null) {
      $('.wang-editor').off();
      $('.wang-inbox-toolbar').first().remove();
      editor.destroy();
      setEditor(null);
      setHtml("");
    }
    const initialHtml = `
    <span style="margin: 0px;">===================Forwarded Message===================</span>
    <span>From: ${selectedThread.from_address}</span>
    <span>To: ${selectedThread.to_address}</span>
    <span>Date: ${history.time}</span>
    <span>Subject: ${history.subject}</span>
    <span>${history.email_body}</span>`
    setCurrentThreadIndex(i);
    setHtml(initialHtml)
    setForwardModalOpen(true);
  }

  const updateReadStatus = async id => {
    try {
			await axiosInstance.put(`/engage_inbox/update_is_read/${id}`);
		} catch (error) {
      console.error('Error in updating the read status of email');
	  }
  }

  const getLeadActivity = async () => {
    setLoading(1);

    try {
			const {data} = await axiosInstance.get(`/engage_inbox/lead_activity/${userId}?lead_id=${selectedThread.id}`);
      leadActivity.current = data;
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching the Lead activity'});
	  }

    setLoading(0);
  }

  const onManualSync = async () => {
    setLoading(1)
    try {
      const response = await axiosInstance.post(`/engage_inbox/manual_sync_inbox_message/${userId}?company_id=${companyId}`);
      setInboxSync(response.data.message)
      if (response.data.status === 200) {
        notification.success({ message: 'Success', description: 'Sync started' }); }
        else {
          notification.error({ message: 'Error', description: response.data.message }); }
    }
    catch (error) {
      notification.error({ message: 'Error', description: 'Error in syncing' });
    }
    setLoading(0)
  }

  useEffect(() => {
    getEmailIds()
  }, []);

  useEffect(() => {
    if (loading === 1) {
      return;
    }
    
    inboxMessages.current.leads = [];

    if (inboxPageNo !== 1) {
      setInboxPageNo(1);
    } else {
      getInboxMessages();
    }
  }, [selectedInboxFilters, inboxSearchString]);

  useEffect(() => {
    if (loading === 1) {
      return;
    }
    getInboxMessages();
  }, [inboxPageNo]);

  useEffect(() => {
    if (loading === 1) {
      return;
    }
    getLeadCategoryValue();
  }, [selectedThread]);

  useEffect(() => {
    if (loading === 1) {
      return;
    }

    const openedThreads = [...expandedThreads];
    const thread = {...selectedThread};
    const emailHistory = [...thread.email_history];
    const updateEmails = [];

    emailHistory.forEach((msg, index) => {
      if (msg.is_read === false && openedThreads.includes(index)) {
        emailHistory[index].is_read = true;
        updateEmails.push(msg.id);
      }
    });


    thread.email_history = [...emailHistory];
    setSelectedThread({...thread});

    const inboxMsgs = {...inboxMessages.current};
    inboxMsgs.leads[selectedMsgIndex.current].email_history = [...emailHistory];
    inboxMessages.current = {...inboxMsgs};

    updateEmails.forEach(id => {
      updateReadStatus(id);
    });
  }, [expandedThreads]);

  useEffect(() => {
    if (loading === 1 || !editor) {
      return;
    }

    insertLineBreak(editor);
  }, [editor]);

  useEffect(() => {
    if (loading === 1 || sidebarTab !== "activity") {
      return;
    }

    getLeadActivity();
  }, [sidebarTab]);

  useEffect(() => {
    if (pageLoaded.current) {
      return;
    }
    
    pageLoaded.current = true;
    getLeadCategories()
      .then(async () => {
        const response = await getSavedFilters();
        return response
      })
      .then((response) => { 
        let inboxFilter = [...response?.data[0]?.view_by_status_selected] || [];
        let mailIdFilter = [...response?.data[0]?.email_accounts_selected] || [];
        setSelectedInboxFilters([...inboxFilter]);
        setSelectedEmailIds([...mailIdFilter]);
        return getInboxMessages(true,[...inboxFilter], [...mailIdFilter]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Layout style={{minHeight: '100vh', padding: 15}} className="bootstrap">
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center pb-4">
        <div className="analytics-heading font-weight-500 px-2">{tab === "inbox" ? "Inbox" : "CRM"}</div>
        <div className="d-flex align-items-center">
          {/*
          <div
            className={"w-120px lead-status-btn pointer br-13 me-2 "
            + (tab === "inbox" ? "mtng_booked" : "inactive")}
            onClick={() => setTab("inbox")}
          >Inbox</div>
          <div
            className={"w-120px lead-status-btn pointer br-13 me-2 "
            + (tab === "crm" ? "mtng_booked" : "inactive")}
            onClick={() => setTab("crm")}
          >CRM</div>
          */}
        </div>

        {editAccess &&
          <Button
            type="primary"
            icon={<PlusSquareOutlined />}
            className="d-none br-13"
          >
            New
          </Button>
        }
      </div>

      {/* Loader */}
      <div
        className={"loader2 flex-column justify-content-center align-items-center "
          + ((loading === 0 || isLoading.current) ? "d-none" : "d-flex")}
      >
        <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
        <div className={"pt-4 text-center" + (loading === 1 ? "" : " d-none")}>
          <Spin size="large" />
        </div>
      </div>

      {/* Inbox */}
      <div className={((loading === 0 || isLoading.current) && tab === "inbox") ? "" : "d-none"}>
        <div className="d-flex align-items-center pb-4">
          <Dropdown
            menu={{
              items: inboxFilters.current,
              onClick: setInboxFilters,
              selectedKeys: selectedInboxFilters,
            }}
            dropdownRender={(menu, index) => (
              <div className="inbox-filter-dropdown" key={index}>{React.cloneElement(menu)}</div>
            )}
          >
            <div
              className="lead-status-btn inactive d-flex align-items-center pointer br-13 me-2 ms-sm-3"
            >
              <img src="/assets/images/download-gray-2x.png" width="18" height="auto" alt="arrow" />
              <span className="text-nowrap ps-2">View By Status</span>
            </div>
          </Dropdown>
          <Select mode="multiple" className="w-25 me-2" onChange={setSelectedMails} placeholder="Please select Emails"
            value={selectedEmailIds}
            style={{height:"33px", overflowY: "auto"}}
            >
            {emailIds.map((i) => (
              <Option key={i.account_email}>{i.account_email}</Option>
            ))}
          </Select>
          <Button type="primary" className="me-2" onClick={() => {
            inboxMessages.current.leads = [];
            if (inboxPageNo !== 1) {
              setInboxPageNo(1);
            } else {
              getInboxMessages();
            }
          }}>Apply</Button>
          <Button className="me-2" onClick={()=> {
            setSelectedEmailIds([])
            inboxMessages.current.leads = [];
            if (inboxPageNo !== 1) {
              setInboxPageNo(1);
            } else {
              getInboxMessages(false);
            }
          }}>Clear</Button>

          <Input
            // prefix={<img
            //   src="/assets/images/search-white-2x.png"
            //   width="12"
            //   height="auto"
            //   alt="search"
            //   className="pointer"
            //   onClick={triggerInboxSearch}
            // />}
            suffix={
              <div className="arrow-parent pointer" onClick={triggerInboxSearch}>➜</div>}   
            className="settings-enter-email search-input-inbox"         
            id="inbox-search-input" 
            placeholder="Search Email"
            // className="search-input no-border"
          />
          <Button
            disabled={!(inboxSync === null || inboxSync === undefined || inboxSync === "Completed")}
            type="primary"
            className="br-13 ms-2"
            onClick={onManualSync}
          >
            {(inboxSync === null || inboxSync === undefined || inboxSync === "Completed") ? "Sync Now" : "Sync In Progress"}
          </Button>
        </div>

        <Row gutter={20} className="text-white">
          <Col span={6} id="scrollable-inbox-div" className="gutter-row emails-list-column emails-list ps-4 pe-3">
            {/* inboxMessages.current.total_leads === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> */}

            <InfiniteScroll
              dataLength={inboxPageNo * 10}
              next={loadMoreData}
              hasMore={inboxMessages.current.leads.length < inboxMessages.current.total_leads}
              loader={<div className="d-flex justify-content-center p-4"><Spin size="large" /></div>}
              endMessage={<Divider plain>No more messages to show.</Divider>}
              scrollableTarget="scrollable-inbox-div"
            >
              <List
                dataSource={inboxMessages.current.leads}
                renderItem={(msg, index) => {
                  const emailHistoryDesc = [...msg.email_history];
                  // emailHistoryDesc.reverse();
                  msg.email_history = [...emailHistoryDesc];
                  const notRead = emailHistoryDesc.filter(e => e.type === "REPLY" && e.is_read === false);
                  const htmlNormalized = removeHtml(emailHistoryDesc[0].email_body);

                  return (
                    <div
                      className={"emails-list-row pointer mb-3" + (selectedMsgIndex.current === index ? " active" : "")}
                      onClick={() => {
                        selectedMsgIndex.current = index;
                        setReplyModalOpen(false);
                        setForwardModalOpen(false);
                        setExpandedThreads([0]);
                        setSelectedThread(msg);
                      }}
                      key={index}
                      id={`email-thread-${index}`}
                    >
                      <div className="d-flex align-items-center pb-3">
                        {notRead.length > 0 && <span className="inbox-indicator unread me-2" />}
                        <span className="pe-2 fw-semibold flex-grow-100">{msg.email}</span>
                        {msg.lead_category &&
                          <img
                            src={`/assets/images/${getLeadCategoryIcon(msg.lead_category)}`}
                            width="16"
                            height="auto"
                            alt="icon"
                          />
                        }
                      </div>
                      <div className="small">
                        <p className="mb-2">{getSubject(emailHistoryDesc)}</p>
                        <p className="mb-0">{htmlNormalized.slice(0, 128)}{htmlNormalized.length > 128 ? "..." : ""}</p>
                      </div>
                    </div>
                  )
                }}
              />
            </InfiniteScroll>

            {/*
            <div className="emails-list-row mb-3">
              <div className="d-flex align-items-center pb-3">
                <span className="inbox-indicator unread" />
                <span className="px-2 fw-semibold flex-grow-100">testemail@gmail.com</span>
                <img src="/assets/images/outgoing-green-2x.png" width="16" height="auto" alt="icon" />
              </div>
              <div className="small">
                <p className="mb-2">Test title of email: ABC Test</p>
                <p className="mb-0">Thank you for reaching out! Nice to meet you. Ready to sign-up for the next wave of AI. Meet our product - Lome - the next time you need productivity tooling...</p>
              </div>
            </div>
            <div className="emails-list-row mb-3">
              <div className="d-flex align-items-center pb-3">
                <span className="inbox-indicator unread" />
                <span className="px-2 fw-semibold flex-grow-100">testemail@gmail.com</span>
                <img src="/assets/images/happy-face-green-2x.png" width="16" height="auto" alt="icon" />
              </div>
              <div className="small">
                <p className="mb-2">Test title of email: ABC Test</p>
                <p className="mb-0">Thank you for reaching out! Nice to meet you. Ready to sign-up for the next wave of AI. Meet our product - Lome - the next time you need productivity tooling...</p>
              </div>
            </div>
            <div className="emails-list-row mb-3">
              <div className="d-flex align-items-center pb-3">
                <span className="inbox-indicator unread" />
                <span className="px-2 fw-semibold flex-grow-100">testemail@gmail.com</span>
                <img src="/assets/images/refresh-pink-2x.png" width="16" height="auto" alt="icon" />
              </div>
              <div className="small">
                <p className="mb-2">Test title of email: ABC Test</p>
                <p className="mb-0">Thank you for reaching out! Nice to meet you. Ready to sign-up for the next wave of AI. Meet our product - Lome - the next time you need productivity tooling...</p>
              </div>
            </div>
            <div className="emails-list-row mb-3">
              <div className="d-flex align-items-center pb-3">
                <span className="inbox-indicator unread" />
                <span className="px-2 fw-semibold flex-grow-100">testemail@gmail.com</span>
                <img src="/assets/images/snooze-orange-2x.png" width="16" height="auto" alt="icon" className="me-2" />
                <img src="/assets/images/happy-face-green-2x.png" width="16" height="auto" alt="icon" />
              </div>
              <div className="small">
                <p className="mb-2">Test title of email: ABC Test</p>
                <p className="mb-0">Thank you for reaching out! Nice to meet you. Ready to sign-up for the next wave of AI. Meet our product - Lome - the next time you need productivity tooling...</p>
              </div>
            </div>
            */}
          </Col>

          <Col span={18} className="gutter-row emails-list-column">
            <div className={"emails-list-column" + (inboxMessages.current.total_leads === 0 ? " d-none" : "")}>
              {selectedThread.email_history.map((history, index) => {
                const htmlNormalized = removeHtml(history.email_body);

                return (
                  <>
                    {/* Expanded */}
                    <div className={"emails-list-row mb-3" + (expandedThreads.includes(index) ? "" : " d-none")}>
                      {/* Email meta */}
                      <div className="d-flex align-items-center pb-4 pt-2 ps-3">
                        <img
                          src={selectedThread.profile_pic === null ? "/assets/images/default-dp-radial-2x.png" : selectedThread.profile_pic }
                          width="28"
                          height="auto"
                          alt="profile"
                          className="pointer"
                          onClick={() => setShowContactDetails(true)}
                        />
                        <div className="px-2 flex-grow-100">
                          <div className="d-flex align-items-center">
                            <span className="fs-6 fw-semibold">{getSubject(selectedThread.email_history, index)}</span>
                            {history.type === "SENT"
                              ?
                                <Tag color="green" className="ms-2">Sent</Tag>
                              : history.type === "FORWARD" ? <Tag color="blue" className="ms-2">Forwarded</Tag> :
                                <Tag color="blue" className="ms-2">Replied</Tag>
                            }
                          </div>
                          <div
                            className="small pt-2 pointer"
                            onClick={() => setShowContactDetails(true)}
                          >{history.type === "SENT" ? "To:" : "From:"} {selectedThread.email}</div>
                        </div>
                        <div className="d-flex flex-column align-items-end ps-2">
                          <div className="d-flex">
                            <div className="d-flex align-items-center pb-2">
                            <span className="small white-text-62 text-nowrap pe-3 pb-3">
                              {moment.utc(history.time).local().format('llll')}
                            </span>
                            {history.is_read === false &&
                              <span className="inbox-indicator unread" />
                            }
                          </div>
                          {(editAccess && history.type === "REPLY") &&
                        <div className="d-flex justify-content-end align-items-center pb-4">
                          <div
                            className="reply-btn ms-2 pointer"
                            onClick={() => openWangEditor(index)}
                          >
                            <img src="/assets/images/reply-white-2x.png" width="auto" height="15" alt="edit" />
                            <span className="text-nowrap ps-2">Reply</span>
                          </div>
                          <div
                            className="forward-btn ms-2 pointer"
                            onClick={() => openForwardWangEditor(index, history)}
                          >
                            <img src="/assets/images/forward-white-2x.png" width="auto" height="15" alt="edit" />
                            <span className="text-nowrap ps-2">Forward</span>
                          </div>
                        </div>
                      }
                          </div>
                          {history.type === "REPLY" &&
                            <Select
                              className="custom-select4"
                              options={(inboxFilters.current).slice(1)}
                              onChange={updateLeadCategory}
                              value={leadCategoryValue}
                              disabled={!editAccess}
                              style={{width: "200px"}}
                            />
                          }
                        </div>
                      </div>

                      {/* Email body */}
                      <div
                        className="copywriting-body small pb-4 ps-3"
                        dangerouslySetInnerHTML={{__html: removeHtml(history.email_body, true)}}
                      />

                      {/* Buttons */}


                      {/* Form */}
                      {(replyModalOpen && currentThreadIndex === index) &&
                        <div key={index} className="inbox-editor-parent">
                          <div className="d-flex align-items-center small inbox-email-bar">
                            <div className="white-text-62 pe-2">From:</div>
                            <div className="fw-semibold">{selectedThread.from_address}</div>
                          </div>
                          <div className="d-flex align-items-center small inbox-email-bar">
                            <div className="white-text-62 pe-2">To:</div>
                            <div className="fw-semibold">{selectedThread.to_address}</div>
                          </div>
                          <div className="align-items-center small inbox-email-bar">
                          <div className="d-flex ">
                            <div className="white-text-62 pe-2">Cc:</div>
                            <div className="fw-semibold"><Input onChange={(e) => setCc(e.target.value)}/></div>
                            </div>
                            <div className="font-size-12 fst-italic">Multiple recipients need to be provided in a comma-separated format.</div>
                      
                          </div>
                          <div className="align-items-center small inbox-email-bar">
                            <div className="d-flex ">
                              <div className="white-text-62 pe-2">Bcc:</div>
                              <div className="fw-semibold"><Input onChange={(e) => setBcc(e.target.value)} /></div>
                            </div>
                            <div className="font-size-12 fst-italic">Multiple recipients need to be provided in a comma-separated format.</div>

                          </div>
                          <div className="position-relative">
                            <Editor
                              defaultConfig={editorConfig}
                              value={html}
                              onCreated={setEditor}
                              onChange={editor => setHtml(editor.getHtml())}
                              mode="default"
                              className="wang-editor wang-inbox-editor"
                              style={{height: '240px', overflowX: 'hidden', overflowY: 'auto'}}
                            />
                            <Toolbar
                              editor={editor}
                              defaultConfig={toolbarConfig}
                              mode="default"
                              className="wang-toolbar wang-inbox-toolbar"
                            />
                            <CustomEditorButtons send={replyToLead} close={closeReplyModal} isInbox={true} signatureSet={addSignature}/>
                          </div>
                        </div>
                      }
                      {(forwardModalOpen && currentThreadIndex === index) &&
                        <div key={index} className="inbox-editor-parent">
                          <div className="d-flex align-items-center small inbox-email-bar">
                            <div className="white-text-62 pe-2">From:</div>
                            <div className="fw-semibold">{selectedThread.from_address}</div>
                          </div>
                          <div className="d-flex align-items-center small inbox-email-bar">
                            <div className="white-text-62 pe-2">To:</div>
                            <div className="fw-semibold"><Input onChange={(e) => setToFwdMail(e.target.value)}/></div>
                          </div>
                          <div className="position-relative">
                            <Editor
                              defaultConfig={{...editorConfig, readOnly: true}}
                              value={html}
                              onCreated={setEditor}
                              onChange={editor => setHtml(editor.getHtml())}
                              mode="default"
                              className="wang-editor wang-inbox-editor"
                              style={{height: '240px', overflowX: 'hidden', overflowY: 'auto'}}
                            />
                            <Toolbar
                              editor={editor}
                              defaultConfig={toolbarConfig}
                              mode="default"
                              className="wang-toolbar wang-inbox-toolbar"
                            />
                            <CustomEditorButtons send={fowardToLead} close={closeForwardModal} isInbox={false} signatureSet={addSignature}/>
                          </div>
                        </div>
                      }
                    </div>

                    {/* Collapsed */}
                    <div
                      className={"emails-list-row small justify-content-between align-items-center pointer mb-3 "
                      + (expandedThreads.includes(index) ? "d-none" : " d-flex")}
                      key={index}
                      onClick={() => {
                        const expandedItems = [...expandedThreads];
                        expandedItems.push(index);

                        setExpandedThreads([...expandedItems]);
                      }}
                    >
                      <div className="pe-3">
                        <div className="fw-semibold pb-2 d-flex align-items-center">
                          {history.type === "SENT" ? "Sent" : "Replied"}: {getSubject(selectedThread.email_history, index)}
                          {history.type === "SENT"
                            ?
                              <Tag color="green" className="ms-2">Sent</Tag>
                            : history.type === "FORWARD" ? <Tag color="blue" className="ms-2">Forwarded</Tag> :
                              <Tag color="blue" className="ms-2">Replied</Tag>
                          }
                        </div>
                        <div>{htmlNormalized.slice(0, 128)}{htmlNormalized.length > 128 ? "..." : ""}</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="small text-nowrap text-end white-text-62">{moment.utc(history.time).local().format('llll')}</span>
                        {(history.type === "REPLY" && history.is_read === false) &&
                          <span className="ms-2 inbox-indicator unread" />
                        }
                      </div>
                    </div>

                    {/* Expand all */}
                    {index === 0 &&
                      <div className="d-flex justify-content-end align-items-center pointer mb-3" onClick={expandAll}>
                        <span className="text-secondary small pe-2">Expand all</span>
                        <img src="/assets/images/expand-gray-2x.png" width="16" height="auto" alt="icon" />
                      </div>
                    }
                  </>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>

      {/* Crm */}
      <div className={(loading === 0 && tab === "crm") ? "" : "d-none"}>
        <div className="d-flex justify-content-between align-items-center pb-4">
          <div className="bg-darkgray text-white px-3 py-2 br-13 d-flex align-items-center">
            <div className="d-flex align-items-center pe-4">
              <img src="/assets/images/man-gray-2x.png" width="18" height="auto" alt="icon" />
              <span className="fw-semibold ps-2">2.5K</span>
            </div>
            <div className="d-flex align-items-center pe-4">
              <img src="/assets/images/arrow-cross-gray-2x.png" width="18" height="auto" alt="icon" />
              <span className="fw-semibold ps-2">876</span>
            </div>
            <div className="d-flex align-items-center pe-4">
              <img src="/assets/images/silent-gray-2x.png" width="18" height="auto" alt="icon" />
              <span className="fw-semibold ps-2">12</span>
            </div>
            <div className="d-flex align-items-center pe-4">
              <img src="/assets/images/sad-face-gray-2x.png" width="18" height="auto" alt="icon" />
              <span className="fw-semibold ps-2">3</span>
            </div>
            <div className="d-flex align-items-center">
              <img src="/assets/images/jet-gray-2x.png" width="18" height="auto" alt="icon" />
              <span className="fw-semibold ps-2">55</span>
            </div>
          </div>

          <div className="d-flex align-items-center crm-right-btns">
            <div className="lead-status-btn inactive d-flex align-items-center pointer br-13 me-2">
              <DownloadIcon />
              <span className="text-nowrap ps-2">View By Status</span>
            </div>

            <div className="lead-status-btn mtng_booked d-flex align-items-center pointer br-13 me-2">
              <AnalyticsDocIcon />
              <span className="text-nowrap ps-2">Import</span>
            </div>
          </div>
        </div>

        <Row className="campaign-detail-table text-white">
          <Row gutter={30} className="w-100 pb-4 mx-2">
            <Col span={4} className="gutter-row fw-semibold">Contact</Col>
            <Col span={4} className="gutter-row fw-semibold">Email</Col>
            <Col span={4} className="gutter-row fw-semibold">Company</Col>
            <Col span={3} className="gutter-row fw-semibold">Job Title</Col>
            <Col span={3} className="gutter-row fw-semibold">Website</Col>
            <Col span={3} className="gutter-row fw-semibold">Linkedin</Col>
            <Col span={3} className="gutter-row fw-semibold">Phone Number</Col>
          </Row>
          
          <div className="w-100 custom-card br-13">
            <Row gutter={30} className="pb-3">
              <Col span={4} className="gutter-row pb-2">Bob Smith</Col>
              <Col span={4} className="gutter-row pb-2">test@gmail.com</Col>
              <Col span={4} className="gutter-row pb-2">Apple Inc</Col>
              <Col span={3} className="gutter-row pb-2">Product Manager</Col>
              <Col span={3} className="gutter-row pb-2">test.com</Col>
              <Col span={3} className="gutter-row pb-2">linkedin.com/abc</Col>
              <Col span={3} className="gutter-row pb-2">888-888-8888</Col>
            </Row>
            <Row gutter={30} className="pb-3">
              <Col span={4} className="gutter-row pb-2">Bob Smith</Col>
              <Col span={4} className="gutter-row pb-2">test@gmail.com</Col>
              <Col span={4} className="gutter-row pb-2">Apple Inc</Col>
              <Col span={3} className="gutter-row pb-2">Product Manager</Col>
              <Col span={3} className="gutter-row pb-2">test.com</Col>
              <Col span={3} className="gutter-row pb-2">linkedin.com/abc</Col>
              <Col span={3} className="gutter-row pb-2">888-888-8888</Col>
            </Row>
            <Row gutter={30} className="pb-3">
              <Col span={4} className="gutter-row pb-2">Bob Smith</Col>
              <Col span={4} className="gutter-row pb-2">test@gmail.com</Col>
              <Col span={4} className="gutter-row pb-2">Apple Inc</Col>
              <Col span={3} className="gutter-row pb-2">Product Manager</Col>
              <Col span={3} className="gutter-row pb-2">test.com</Col>
              <Col span={3} className="gutter-row pb-2">linkedin.com/abc</Col>
              <Col span={3} className="gutter-row pb-2">888-888-8888</Col>
            </Row>
            <Row gutter={30} className="pb-3">
              <Col span={4} className="gutter-row pb-2">Bob Smith</Col>
              <Col span={4} className="gutter-row pb-2">test@gmail.com</Col>
              <Col span={4} className="gutter-row pb-2">Apple Inc</Col>
              <Col span={3} className="gutter-row pb-2">Product Manager</Col>
              <Col span={3} className="gutter-row pb-2">test.com</Col>
              <Col span={3} className="gutter-row pb-2">linkedin.com/abc</Col>
              <Col span={3} className="gutter-row pb-2">888-888-8888</Col>
            </Row>
          </div>
        </Row>
      </div>

      {/* Drawers */}
      <Drawer
        title=""
        placement="right"
        width={450}
        closable={false}
        onClose={() => {
          setSidebarTab("details");
          setShowContactDetails(false);
        }}
        open={showContactDetails}
        key="left"
        className="bootstrap"
      >
        <div className={"text-white" + (inboxMessages.current.total_leads === 0 ? " d-none" : "")}>
          <div className="d-flex align-items-center pb-2 pointer" onClick={() => { 
            setSidebarTab("details");
            setShowContactDetails(false)}}>
            <img src="/assets/svg/submit-button.svg" width="30" height="30" alt="close" />
            <span className="ps-2 white-text-62">Close</span>
          </div>

          <div className="d-flex justify-content-end">
            <Select
              className="w-75 custom-select4"
              options={(inboxFilters.current).slice(1)}
              onChange={updateLeadCategory}
              value={leadCategoryValue}
              disabled={!editAccess}
            />
          </div>

          <div className="d-flex flex-column align-items-center py-4">
            <img src={selectedThread.profile_pic === null ? "/assets/images/default-dp-radial-2x.png" : selectedThread.profile_pic } width="48" height="auto" alt="profile" className="mb-3" />
            <div className="fs-6 fw-semibold pb-3">{selectedThread.email ?? ""}</div>
            <div className="small fw-semibold pb-3">{selectedThread.campaign_name ?? ""}</div>
            <div className="d-flex justify-content-between align-items-center">
              <div
                className={"lead-status-btn d-flex align-items-center pointer br-13 me-2 "
                + (sidebarTab === "details" ? "mtng_booked" : "inactive")}
                onClick={() => setSidebarTab("details")}
              >
                <span className="text-nowrap ps-2">Contact Details</span>
              </div>
              <div
                className={"lead-status-btn d-flex align-items-center pointer br-13 "
                + (sidebarTab === "activity" ? "mtng_booked" : "inactive")}
                onClick={() => setSidebarTab("activity")}
              >
                <span className="text-nowrap ps-2">Lead Activity</span>
              </div>
            </div>
          </div>

          <div className={sidebarTab === "details" ? "d-block" : "d-none"}>
            <div className="pb-4">
              <div>Name</div>
              <div className="pt-2 text-secondary">{selectedThread.first_name ?? "-"} {selectedThread.last_name ?? ""}</div>
            </div>

            <div className="pb-4">
              <div>Email</div>
              <div className="pt-2 text-secondary">{selectedThread.email ?? "-"}</div>
            </div>

            <div className="pb-4">
              <div>Job Title</div>
              <div className="pt-2 text-secondary">{selectedThread.role ?? "-"}</div>
            </div>

            <div className="pb-4">
              <div>Company</div>
              <div className="pt-2 text-secondary">{selectedThread.company_name ?? "-"}</div>
            </div>

            <div className="pb-4">
              <div>Phone</div>
              <div className="pt-2 text-secondary">{selectedThread.phone_number ?? "-"}</div>
            </div>

            <div className="pb-4">
              <div>Website</div>
              <div className="pt-2 text-secondary">{selectedThread.website ? (
                <a href={selectedThread.website} target="_blank" rel="noopener noreferrer">
                  {selectedThread.website}
                </a>
              ) : (
                "-"
              )}</div>
            </div>

            <div className="pb-4">
              <div>Linkedin</div>
              <div className="pt-2 text-secondary">{selectedThread.linkedin_profile ?(
                  <a href={selectedThread.linkedin_profile} target="_blank" rel="noopener noreferrer">
                  {selectedThread.linkedin_profile}
                </a>
              ) : (
                "-"
              )}</div>
            </div>
          </div>

          <div className={sidebarTab === "activity" ? "d-block" : "d-none"}>
            <div className="d-flex justify-content-center align-items-center mt-2 mb-4">
              <div className="d-flex align-items-center pe-4">
                <img src="/assets/images/mail-open-gray-2x.png" width="18" height="auto" alt="icon" />
                <span className="ps-2 fw-semibold fs-6">{leadActivity.current.opened_count ?? 0}</span>
              </div>
              <div className="d-flex align-items-center pe-4">
                <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="18" height="auto" alt="icon" />
                <span className="ps-2 fw-semibold fs-6">{leadActivity.current.replied_count ?? 0}</span>
              </div>
              <div className="d-flex align-items-center">
                <img src="/assets/images/link-gray-2x.png" width="18" height="auto" alt="icon" />
                <span className="ps-2 fw-semibold fs-6">{leadActivity.current.link_clicked_count ?? 0}</span>
              </div>
            </div>

            {leadActivity.current.hasOwnProperty('lead_activity') &&
            leadActivity.current.lead_activity.map((activity, index) => (
              <>
                <div
                  className={"emails-list-row darker mb-3" + (activity.opened ? "" : " d-none")}
                  key={index + "_1"}
                >
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/mail-open-cyan-2x.png" width="12" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">Opened</span>
                    </div>
                    {activity.opened &&
                      <span className="small white-text-62">
                        {moment(activity.open_time).format('ddd, MMM Do @ h A')}
                      </span>
                    }
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <span className="small">{activity.email ?? ""}</span>
                    <span className="small">{activity.step_name ?? ""}, {activity.variant_name ?? ""}</span>
                  </div>
                </div>

                <div
                  className={"emails-list-row darker mb-3" + (activity.replied ? "" : " d-none")}
                  key={index + "_2"}
                >
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/flash-white-2x.png" width="12" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">Replied</span>
                    </div>
                    {activity.replied &&
                      <span className="small white-text-62">
                        {moment(activity.reply_time).format('ddd, MMM Do @ h A')}
                      </span>
                    }
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <span className="small">{activity.email ?? ""}</span>
                    <span className="small">{activity.step_name ?? ""}, {activity.variant_name ?? ""}</span>
                  </div>
                </div>

                <div
                  className={"emails-list-row darker mb-3" + (activity.link_clicked ? "" : " d-none")}
                  key={index + "_3"}
                >
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/link-pink-2x.png" width="12" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">Clicked</span>
                    </div>
                    {activity.link_clicked &&
                      <span className="small white-text-62">
                        {moment(activity.click_time).format('ddd, MMM Do @ h A')}
                      </span>
                    }
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <span className="small">{activity.email ?? ""}</span>
                    <span className="small">{activity.step_name ?? ""}, {activity.variant_name ?? ""}</span>
                  </div>
                </div>

                <div
                  className={"emails-list-row darker mb-3" + (activity.sent ? "" : " d-none")}
                  key={index + "_4"}
                >
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/arrow-cross-blue-2x.png" width="12" height="auto" alt="icon" />
                      <span className="fw-semibold ps-2">Sent</span>
                    </div>
                    {activity.sent &&
                      <span className="small white-text-62">
                        {moment(activity.sent_time).format('ddd, MMM Do @ h A')}
                      </span>
                    }
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <span className="small">{activity.email ?? ""}</span>
                    <span className="small">{activity.step_name ?? ""}, {activity.variant_name ?? ""}</span>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </Drawer>

      {/* Modals */}
      <Modal
        title="Block List"
        open={openDontContact}
        onOk={async () => {
          setOpenDontContact(false)
          await blockUser();
          }
        }
        onCancel={() => {setOpenDontContact(false)}}
        maskClosable={false}
        centered
        className="bootstrap custom-modal"
      >
        <div>
          You are adding the following domain to the global blocklist
          <Input disabled value={selectedThread.email} className="custom-input my-3" />
          <Checkbox className="d-flex" onChange={(e) => setBlockList(e.target.checked)}>
             Block the entire domain associated with this lead
          </Checkbox>
        </div>
      </Modal>
    </Layout>
  )
};

export default Inbox;
