const isViewAsMember = (authData) => {
  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const isViewer = params.has('viewer');

  const isAdmin = authData.userInfo.is_admin;

  if (isViewer && isAdmin) {
    return true;
  } else {
    return false;
  }
}

export default isViewAsMember;
