import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Button, Drawer, Form, Input, notification, Select, Table, Tag, Row, Col } from "antd";
import { PlusSquareOutlined } from '@ant-design/icons';
import { DropdownArrowPurple } from '../../assets/svg/Icons';

import axiosInstance from "../../config/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import {setToken} from "../../store/slices/authSlice";
import { setCompany as setCompanyRedux } from "../../store/slices/companySlice";
import { setUserInfo } from "../../store/slices/authSlice";

const {TextArea} = Input;

const SelectCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const companies = useRef([]);
  const companyOptions = useRef([]);
  const billingData = useRef([]);

  const [loading, setLoading] = useState(1);
  const [isNewCompany, setIsNewCompany] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [formDirection, setFormDirection] = useState(1); // 1, -1
  const [companyInfo, setCompanyInfo] = useState({});
  const [showBilling, setShowBilling] = useState(false);
  const [billingValue, setBillingValue] = useState("");

  const authData = useSelector(state => state.auth);
  const userId = authData.userInfo.userid;

  const isSuperAdmin = authData.userInfo.is_super_admin;

  const fetchCompanies = async () => {
    if (!localStorage.getItem('authToken')) {
      window.location.assign("/");
      return;
    }

    try {
			const response = await axiosInstance.get(`/settings/fetch_company/${userId}`);
      companies.current = response.data;

      const options = [];
      response.data.forEach(company => {
        const { name, company_id, user_id, role_id, sl_key } = company;
        const roleId = role_id ?? 0;
        options.push({
          label: name,
          value: `${company_id}|${user_id}|${roleId}|${sl_key ?? ''}`
        });
      });

      companyOptions.current = [...options];
      if(response.data.length === 1) {
        let company = response.data[0]
        setCompany(
          `${company.company_id}|${company.user_id}|${company.role_id ?? 0}|${company.sl_key}`
        )
      }
      
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching the Companies'});
	  }

    if (isSuperAdmin) {
      fetchBilling();
    } else {
      setLoading(0);
    }
    
  }

  const fetchBilling = async () => {
    try {
			const response = await axiosInstance.get(`/settings/billing_limits/${userId}`);
      billingData.current = response.data.data;
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching the Billing information'});
	  }

    setLoading(0);
  }

  const columns = [
    {
      title: 'Company Name',
      key: 'name',
      render: (_, record) => (
        <span>{record.company_name}</span>
      ),
    },
    {
      title: 'Limit',
      key: 'limit',
      render: (_, record) => (
        <Input
          id={`input-limit-${record.company_id}`}
          defaultValue={record.limit_count}
          className="custom-input"
          suffix={
            <div className="arrow-parent pointer" onClick={() => updateBilling(record.company_id)}>➜</div>
          }
        />
      ),
    },
  ];

  const updateBilling = async record => {
    setLoading(1);
    // const limit = document.querySelector(`#input-limit-${companyId}`).value;

    try {
			await axiosInstance.put(`/settings/update_billing/${record.company_id}?user_id=${userId}&limit=${record.limit_count}`);
      notification.success({message: 'Success', description: 'Billing information has been updated successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in updating the Billing information'});
	  }

    setLoading(0);
  }

  const setCompany = async (company_info, showLoader = true) => {
    const [company_id, user_id, role_id, sl_key] = company_info.split("|");

    if (showLoader) {
      setLoading(1);
    }

    setCompanyInfo({company_id, user_id, sl_key});
    localStorage.setItem("user_id", user_id)
    localStorage.setItem("company_id", company_id)
    localStorage.setItem("sl_key", sl_key)
    dispatch(setToken(localStorage.getItem('authToken')));
    dispatch(setCompanyRedux({company_id: Number(company_id), user_id: Number(user_id), sl_key: sl_key}));

    const userInfo = {...authData.userInfo};
    const isAdmin = isSuperAdmin === false && role_id === "null" ? true : false;
    const isViewer = isSuperAdmin === false && Number(role_id) === 1 ? true : false;

    await dispatch(setUserInfo({...userInfo, isAdmin, isViewer, role_id}));
    try {
      const payload ={
        userid: Number(user_id),
        company_id: Number(company_id),
        login: true
      }
			axiosInstance.post(`/login/auth_update`, payload);
		} catch (error) {
      console.log("error", error)
	  }
    if(sl_key === "false") {
      window.location.assign("/prospecting-new");
    }
    else {
      window.location.assign("/engage/campaign-list");
    }
  }

  const triggerSubmit = () => {
    document.querySelector('#submit-btn-' + currentPage).click();
  }

  const navigatePage = direction => {
    setFormDirection(direction);

    if (direction ===  1) {
      triggerSubmit();
      return;
    }

    const navigatedPage = currentPage + direction;
    let gotoPage;

    if (navigatedPage < 1) {
      gotoPage = 1;
    } else if (navigatedPage > 2) {
      gotoPage = 2;
    } else {
      gotoPage = navigatedPage;
    }

    setCurrentPage(gotoPage);
  }

  const saveForm = values => {
    setLoading(1);
    submitForm(values);
  }

  const submitForm = async values => {
    let result = true;

    switch (currentPage) {
      case 0:
        result = await createCompany(values);
        break;
      case 1:
        result = await saveCompanyInformation(values);
        break;
      default:
        await saveMarketInformation(values);
    }

    if (currentPage < 2) {
      if (result) {
        setCurrentPage(currentPage + 1);
      }
      setLoading(0);
    }
  }

  const createCompany = async values => {
    const data = { 
      company_id: values.company_id,
      name: values.name,
      website: values.website,
      display_name: values.display_name,
      job_title: values.job_title
    }

    if (isSuperAdmin) {
      data.email = values.email;
    }
    
    let result;

    try {
      const response = await axiosInstance.post(`/settings/create/company/${userId}`, data);
      setCompanyInfo({company_id: response.data.company_id, user_id: response.data.user_id});
      setCompany(response.data.company_id + "|" + response.data.user_id + "|" + 0, true);

      result = true;
		} catch (error) {
      result = false;
      notification.error({message: 'Error', description: 'Error in saving the Information'});
	  }

    return result;
  }

  const saveCompanyInformation = async values => {
    const data = {
      product_id: 0,
      company_description: values.company_description,
      product_name: values.product_name,
      product_description: values.product_description,
      product_price: values.product_price
    }

    let result;

    try {
      await axiosInstance.post(`/settings/create/company_information/${companyInfo.company_id}?user_id=${companyInfo.user_id}`, [data]);
      result = true;
		} catch (error) {
      result = false;
      notification.error({message: 'Error', description: 'Error in saving the Information'});
	  }

    return result;
  }

  const saveMarketInformation = async values => {    
    const data = {
      competition: values.competition,
      complementary: values.complementary,
      market_focus: values.market_focus,
      company_focus: values.company_focus
    }

    try {
      await axiosInstance.post(`/settings/create/market_information/${companyInfo.company_id}?user_id=${companyInfo.user_id}`, data);
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in saving the Information'});
	  }

    setCompany(companyInfo.company_id + "|" + companyInfo.user_id + "|" + 0, true);
  }

  useEffect(() => {
    document.querySelector('body').classList.add('persona-ai');
    fetchCompanies();
  }, []);

  return (
    <div className="d-flex flex-row auth-background bootstrap">
      {/* Loader */}

      <div className="persona-left-icon d-flex flex-row mt-4 ms-4">
            <img src="\assets\svg\purple-persona-icon.svg" />
            <span className="pipeline-text ps-2 pt-1">Pipeline</span>
          </div>
    <div>
     <img src="\assets\svg\top-grid.svg" className="top-grid" />

      <div className={"loader-parent align-items-center auth-background justify-content-center w-100 h-100 "
      + (loading === 1 ? "d-flex" : "d-none")}>
        {/* <img src="/assets/images/loading-logo-mega.png" alt="logo" width="880" height="auto" className="mb-3" /> */}
      </div>

      {/* Form */}
      {companies.current.length > 1 && 
      <div className={"select-company flex-column align-items-center my-4 "
      + (loading === 0 ? "d-flex" : "d-none")}>
        <div className="w-100 justify-content-center d-flex"><img src="\assets\svg\purple-persona-icon.svg" height={80} width={80}/></div>


        <div className={isNewCompany ? "w-50 d-none" : "w-50 d-block"}>
          <div className="select-company-text text-center pb-3 my-3">Select a company</div>

          <div className="company-selection-section d-flex justify-content-center text-secondary mb-3">
            {companies.current.length < 3
              ?
                companies.current.map(company => {
                  return (
                    <div className="company-row continue position-relative mb-3">
                      <span>{company.name}</span>
                      <div
                        className="arrow-parent pointer position-absolute"
                        onClick={() => setCompany(
                          `${company.company_id}|${company.user_id}|${company.role_id ?? 0}|${company.sl_key}`
                        )}
                      >➜</div>
                    </div>
                  )
                })
              :
                <Select
                  className="select-company-dropdown mb-3"
                  suffixIcon={<DropdownArrowPurple />}
                  options={companyOptions.current}
                  placeholder="Companies"
                  onChange={(value) => setCompany(value)}
                />
            }
          </div>

          <div className="d-flex align-items-center justify-content-center">
          {isSuperAdmin && <div className="new-company pointer  me-4"
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={() => setIsNewCompany(true)}
            >
              <span>New Company</span>
            </div> }

            {isSuperAdmin &&
              <div
                type="primary"
                className="ms-4 new-company pointer"
                onClick={() => setShowBilling(true)}
              >
                <span>Settings</span>
              </div>
            }
          </div>
        </div>
      
        <div className={isNewCompany ? "" : "d-none"} style={{width: "35%"}}>
          {/* Info */}
          {/* <Tag color="blue" className="d-block text-center mb-3 mr-0">
            Company Information can be edited in Settings at any time.
            <br />
            Please answer questions in 3rd person.
          </Tag> */}

          {/* Page 0 */}
          <div className={"page-0" + (currentPage !== 0 ? " d-none" : "")}>
            <div className="create-company-text">Create New Company</div>

            <Form
              onFinish={saveForm}
            >
              <Form.Item
                name="name"
                rules={[{required: (formDirection === 1 ? true : false), message: 'Please enter the company name'}]}
              >
                <Input
                  className="company-name-input"
                  placeholder="Company Name"
                  style={{height: "36px"}}
                  // suffix={<div className="d-none">➜</div>}
                />
              </Form.Item>

              <Form.Item
                name="website"
                rules={[{required: (formDirection === 1 ? true : false), message: 'Please enter the company website'}]}
              >
                <Input
                  className="continue company-name-input"
                  placeholder="Company Website"
                  style={{height: "36px"}}
                  // suffix={isSuperAdmin ? <div className="arrow-parent pointer" onClick={() => triggerSubmit()}>➜</div> : null}
                />
              </Form.Item>

              {!isSuperAdmin && <Form.Item
                name="display_name"
                rules={[{required: false, message: 'Please enter a value'}]}
              >
                <Input
                  className="custom-input2"
                  placeholder="name of the user"
                  suffix={<div className="d-none">➜</div>}
                />
              </Form.Item> }

              {/* {isSuperAdmin &&
                <Form.Item
                  name="email"
                  rules={[{
                    required: (formDirection === 1 ? true : false),
                    type: "email",
                    message: 'Please enter a valid email'
                  }]}
                >
                  <Input
                    className="custom-input2"
                    placeholder="email"
                    suffix={<div className="d-none">➜</div>}
                  />
                </Form.Item>
              } */}

            {!isSuperAdmin && <Form.Item
                name="job_title"
                rules={[{required: false, message: 'Please enter a value'}]}
              >
                <Input
                  className="continue custom-input2"
                  placeholder="job title"
                  suffix={<div className="arrow-parent pointer" onClick={() => triggerSubmit()}>➜</div>}
                />
              </Form.Item>}

              <button type="submit" disabled aria-hidden="true" className="d-none">PreventSubmit</button>
              <Button htmlType="submit" className="d-none" id="submit-btn-0" />

              <div className="create-company pointer " onClick={() => triggerSubmit()}>Create</div>

            </Form>
          </div>

          {/* Page 1 */}    
          {/* <div className={"page-1" + (currentPage !== 1 ? " d-none" : "")}>
            <h3 className="fs-5 fw-semibold text-white text-center pb-3">Company Information</h3>

            <Form
              onFinish={saveForm}
            >
              <Form.Item
                name="company_description"
                rules={[{required: (formDirection === 1 ? false : false), message: 'Please enter a value'}]}
              >
                <TextArea
                  className="custom-input2 textarea"
                  placeholder="Enter your company description"
                />
              </Form.Item>

              <Form.Item
                name="product_name"
                rules={[{required: (formDirection === 1 ? false : false), message: 'Please enter a value'}]}
              >
                <Input
                  className="custom-input2"
                  placeholder="Enter the product name"
                />
              </Form.Item>

              <Form.Item
                name="product_description"
                rules={[{required: (formDirection === 1 ? false : false), message: 'Please enter a value'}]}
              >
                <TextArea
                  className="custom-input2 textarea"
                  placeholder="Enter your product description"
                />
              </Form.Item>

              <Form.Item
                name="product_price"
                rules={[{required: (formDirection === 1 ? false : false), message: 'Please enter a value'}]}
              >
                <Input
                  className="continue custom-input2"
                  placeholder="How is your product priced?"
                  suffix={<div className="arrow-parent pointer" onClick={() => triggerSubmit()}>➜</div>}
                />
              </Form.Item>

              <button type="submit" disabled aria-hidden="true" className="d-none">PreventSubmit</button>
              <Button htmlType="submit" className="d-none" id="submit-btn-1" />
            </Form>
          </div> */}

          {/* Page 2 */}   
          {/* <div className={"page-2" + (currentPage !== 2 ? " d-none" : "")}>
            <h3 className="fs-5 fw-semibold text-white text-center pb-3">Market Information</h3>

            <Form
              onFinish={saveForm}
            >
              <Form.Item
                name="competition"
                rules={[{required: (formDirection === 1 ? false : false), message: 'Please enter a value'}]}
              >
                <TextArea
                  className="custom-input2 textarea"
                  placeholder="What are companies that are similar or competitive to you?"
                />
              </Form.Item>

              <Form.Item
                name="complementary"
                rules={[{required: (formDirection === 1 ? false : false), message: 'Please enter a value'}]}
              >
                <Input
                  className="custom-input2"
                  placeholder="What are complementary companies to you?"
                  suffix={<div className="d-none">➜</div>}
                />
              </Form.Item>

              <Form.Item
                name="market_focus"
                rules={[{required: (formDirection === 1 ? false : false), message: 'Please enter a value'}]}
              >
                <Input
                  className="custom-input2"
                  placeholder="What industries or markets do you sell into?"
                  suffix={<div className="d-none">➜</div>}
                />
              </Form.Item>

              <Form.Item
                name="company_focus"
                rules={[{required: (formDirection === 1 ? false : false), message: 'Please enter a value'}]}
              >
                <Input
                  className="continue custom-input2"
                  placeholder="What are companies you sell to right now?"
                  suffix={<div className="arrow-parent pointer" onClick={() => triggerSubmit()}>➜</div>}
                />
              </Form.Item>

              <button type="submit" disabled aria-hidden="true" className="d-none">PreventSubmit</button>
              <Button htmlType="submit" className="d-none" id="submit-btn-2" />
            </Form>
          </div> */}

          {/* Pagination */}
          {/* <div className={"align-items-center pt-4 position-relative " + (currentPage > 0 ? "d-flex" : "d-none")}>
            <div className="d-flex align-items-center">
              <span
                className={"inverted-pagination-arrow position-absolute pointer "
                + (currentPage === 1 ? "text-secondary" : "text-white")
                }
                onClick={() => navigatePage(-1)}
              >➞</span>
              <span
                className={"pe-3 right-arrow pointer " + (currentPage === 2 ? "text-secondary" : "text-white")
                }
                onClick={() => navigatePage(1)}
              >➞</span>
              <span className="text-secondary small">{currentPage}-2 pages</span>
            </div>
          </div> */}
        </div>
      </div> }
      </div>

      {/* Drawers */}
      <Drawer
        title=""
        placement="right"
        width={700}
        closable={false}
        onClose={() => setShowBilling(false)}
        open={showBilling}
        key="left"
        className="bootstrap billing-drawer"
        style={{borderLeft: "3px solid",
        borderImageSource: "linear-gradient(180deg, rgba(112, 66, 248, 0.54) 0%, #A599CA 100%)",
        borderImageSlice: "1",
        backgroundColor: "#09080D",
      }}
      >
        <div className="w-100 d-flex justify-content-end">
        <div className=" pointer exit-settings mb-4" onClick={() => setShowBilling(false)}>
          Exit Settings
        </div>
        </div>
          <Row className="my-4">
            <Col span={12} className="billing-text">Company Name</Col>
            <Col span={12} className="billing-text ps-3">Set Limits</Col>
          </Row>
          {billingData.current.map((record,index) =>
          <div className="d-flex flex-row justify-content-between mb-4">
              <div className="billing-company-name me-4 ps-2">{record.company_name}</div>
              {/* <div className="billing-company-name d-flex flex-row justify-content-between">
                <div className="ps-2">{record.limit_count}</div>
                <div className="billing-update-text pt-1 pointer pe-2" onClick={() => updateBilling(record)}>Update</div>
              </div> */}
              <Input onChange={(e) => {
                let billingDetails = billingData.current
                billingDetails[index].limit_count = e.target.value
                billingData.current = billingDetails
              }}
          // id={`input-limit-${record.company_id}`}
          defaultValue={record.limit_count}
          className="billing-company-name billing-company-text ps-2"
          suffix={
            <div className="billing-update-text pt-1 pointer pe-2" onClick={() => updateBilling(record)}>Update</div>
          }
        />
          </div>
           )}
        {/* <Table
          dataSource={billingData.current}
          columns={columns}
          pagination={true}
        /> */}
      </Drawer>
    </div>
  );
};

export default SelectCompany;
