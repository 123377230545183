import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Empty,
  Input,
  Layout,
  notification,
  Row,
  Spin,
  Switch,
  Modal
} from "antd";
import {
  PlusSquareOutlined
} from '@ant-design/icons';
import axiosInstance from "../../config/axiosConfig";
import CustomPagination from '../../component/CustomPagination';

const CampaignsList = () => {
  const navigate = useNavigate();

  const authData = useSelector(state => state.auth);
  const companyData = useSelector(state => state.companyReducer.selectedCompany);

  const userId = authData.userInfo.is_super_admin ?  companyData.user_id : authData.userInfo.userid;
  const companyId = companyData.company_id;
  const editAccess = authData.userInfo.isViewer === false ? true : false;

  const pageLoaded = useRef(false);
  const campaignList = useRef([]);
  const campaignListFiltered = useRef([]);

  const [loading, setLoading] = useState(1);
  const [campaignPageNo, setCampaignPageNo] = useState(1);
  const [campaignPageDetails, setCampaignPageDetails] = useState({});
  const [campaignSync, setCampaignSync] = useState("");

  const getBatteryLevel = score => {
    let level = "full";

    if (score === 0 ) {
      level = "empty";
    } else if (score <= 25) {
      level = "low";
    } else if (score <= 50) {
      level = "half";
    } else if (score <= 90) {
      level = "high";
    }

    return level;
  }

  const getCampaignList = async (pageNo) => {
    try {
			const response = await axiosInstance.get(`/engage/campaign_list/${userId}?company_id=${companyId}&page=${pageNo}`);
      campaignList.current = response.data.campaigns;
      campaignListFiltered.current = response.data.campaigns;
      setCampaignSync(response.data.manual_sync_campaign)
      setCampaignPageDetails(response.data.pagination)
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in fetching the Campaign(s)'});
	  }

    setLoading(0);
  }

  const updateCampaignStatus = async (id, toggle) => {
    setLoading(1);

    const payload = {
      user_id: userId,
      status: toggle ? "START" : "PAUSED"
    }

    try {
			await axiosInstance.put(`/engage/update_campaign_status/${id}`, payload);
      notification.success({message: 'Success', description: 'Campaign has been updated successfully'});
		} catch (error) {
      notification.error({message: 'Error', description: 'Error in updating the Campaign'});
	  }

    getCampaignList(campaignPageNo);
  }

  const gotoDetails = id => {
    navigate("/engage/campaign-details/" + id);
  }

  const handleCampaignsTableChange = ({page}) => {
    setCampaignPageNo(page)
    getCampaignList(page)
  }

  const onManualSync = async () => {
    setLoading(1)
    try {
      const response = await axiosInstance.post(`/engage/manual_sync_campaign/${userId}?company_id=${companyId}`);
      setCampaignSync(response.data.message)    
      if (response.data.status === 200) {
        notification.success({ message: 'Success', description: 'Sync started' }); }
        else {
          notification.error({ message: 'Error', description: response.data.message }); }   
    }
    catch (error) {
      notification.error({ message: 'Error', description: 'Error in syncing' });
    }
    setLoading(0)   
  }

  const onDeleteCampaign = async (campaign) => {

    Modal.confirm({
      title: <div style={{ color: "#ffffff" }}>Delete Campaign</div>,
      content: (
        <div style={{ color: "#ffffff"}} >
          Do you want to delete the campaign?
        </div>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
    try {
      setLoading(1)
      const response = await axiosInstance.put(`v2_engage/delete_campaign/${campaign.campaign_id}?user_id=${userId}`);
      if (response.data.status === 200) {
        notification.success({ message: 'Success', description: 'Campaign deleted successfully' }); 
        getCampaignList(1)
      }
        else {
          notification.error({ message: 'Error', description: response.data.message }); }   
    }
    catch (error) {
      notification.error({ message: 'Error', description: error?.response?.data?.detail });
    }
    setLoading(0) }})   
  }

  const searchCampaigns = () => {
    setLoading(1);

    const searchValue = document.querySelector('#campaigns-list-search').value.trim();
    let regex;

    if (searchValue.length) {
      regex = new RegExp(searchValue, "gi");
    } else {
      regex = null;
    }
    
    if (!regex) {
      campaignListFiltered.current = [...campaignList.current];
    } else {
      campaignListFiltered.current = campaignList.current.filter(c => regex.test(c.campaign_name));
    }

    setTimeout(() => setLoading(0), 500);
  }

  useEffect(() => {
    if (pageLoaded.current) {
      return;
    }
    
    pageLoaded.current = true;
    getCampaignList(1);
  }, []);

  return (
    <Layout style={{minHeight: '100vh', padding: 15}} className="bootstrap">
      {/* Header */}
      <Row gutter={30} className="pb-3">
        <Col span={12} className="gutter-row">
          <div className="analytics-heading font-weight-500 px-2">Campaigns</div>
        </Col>
        <Col span={12} className="gutter-row d-flex justify-content-end align-items-center">
          <Button
            disabled={!(campaignSync === null || campaignSync === undefined || campaignSync === "Completed")}
            type="primary"
            className="br-13 me-2"
            onClick={onManualSync}
          >
            {(campaignSync === null || campaignSync === undefined || campaignSync === "Completed") ? "Sync Now" : "Sync In Progress"}
          </Button>
          <Input
            prefix={
              <img
                src="/assets/images/search-white-2x.png"
                width="16"
                height="auto"
                alt="search"
                className="pointer"
                onClick={searchCampaigns}
              />
            }
            id="campaigns-list-search"
            className="search-input"
          />
          {editAccess &&
            <Button
              type="primary"
              icon={<PlusSquareOutlined />}
              className="br-13 ms-2"
              onClick={() => navigate("/engage/campaign-form")}
            >
              Add New
            </Button>
          }
        </Col>
      </Row>

      {/* Main */}
      <Row gutter={30} id="engage-account-main" justify="center" align="top">
        {/* Loader */}
        <div className={"loader2 flex-column justify-content-center align-items-center "
          + (loading === 0 ? "d-none" : "d-flex")}>
          <img src="/assets/images/loading-logo.png" width="260" height="auto" alt="loading" />
          <div className={"pt-4 text-center" + (loading === 1 ? "" : " d-none")}>
            <Spin size="large" />
          </div>
        </div>

        {/* List */}
        <Col span={24} className={"gutter-row" + (loading === 1 ? " d-none" : "")}>
          <>
            {campaignListFiltered.current.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

            {campaignListFiltered && campaignListFiltered.current && campaignListFiltered.current.map((campaign, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="engage-acc-list-row text-white mb-4"
                  // onClick={() => navigate("/engage/campaign-details")}
                >
                  <Col span={8} className="d-flex justify-content-between align-items-center">
                    <div className={(campaign?.status !== "PROGRESS" ? "pointer" : "")} onClick={() => { 
                      if(campaign?.status !== "PROGRESS") {
                        gotoDetails(campaign.campaign_id)}}}>
                      <div className="fw-bold pb-3">{campaign.campaign_name}</div>
                      <div className="small fw-semibold pb-3">{campaign.persona_name}</div>
                      <div className="d-flex align-items-center">
                        <div className={"battery " + getBatteryLevel(Number(campaign.leads_remaining))}></div>
                        <span className="ps-2">{campaign.leads_remaining}% Remaining</span>
                      </div>
                    </div>
                    <div classNam="d-flex flex-column align-items-center" estyle={{width: "40%"}}>
                      <div className="d-flex justify-content-center align-items-center pb-2">
                        <img src="/assets/svg/refresh.svg" width="7" height="auto" alt="refresh" />
                        <span className="tiny ps-2">{campaign.updated_time}</span>
                      </div>
                      <div
                        className={"lead-status-btn s-2 w-120px pointer br-10 "
                        + (campaign.status !== "PAUSED"
                          ? campaign.status === "COMPLETED" ? "replied" : "mtng_booked"
                          : "inactive")}
                      >
                        <span className="text-nowrap">{campaign.status === "PROGRESS" ? "PROCESSING" : campaign.status === "DRAFTED" ? "DRAFT" : campaign.status}</span>
                      </div>
                    </div>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={6} className="d-flex justify-content-between align-items-center px-4">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center pe-4">
                        <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="" alt="icon" />
                        <span className="ps-2">{campaign.sent_count}</span>
                      </div>
                      <div className="d-flex align-items-center pe-4">
                        <img src="/assets/images/mail-open-gray-2x.png" width="18" height="" alt="icon" />
                        <span className="ps-2">{campaign.open_count}</span>
                      </div>
                      <div className="d-flex align-items-center pe-4">
                        <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="17" height="" alt="icon" />
                        <span className="ps-2">{campaign.reply_count}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <img src="/assets/images/link-gray-2x.png" width="19" height="" alt="icon" />
                        <span className="ps-2">{campaign.click_count}</span>
                      </div>
                    </div>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={4} className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Switch
                        checked={campaign.status === "ACTIVE" || campaign.status === "COMPLETED" ? true : false}
                        onChange={(toggleState) => updateCampaignStatus(campaign.campaign_id, toggleState)}
                        disabled={campaign.status === "COMPLETED" || campaign.status === "DRAFTED" ||!editAccess}
                      />
                      <span className="ps-2">{(campaign.status === "ACTIVE" || campaign.status === "COMPLETED") ? "ON" : "OFF"}</span>
                    </div>
                    <div className="d-none three-dots-btn blue pointer">...</div>
                  </Col>

                  {/* { campaign.status === "DRAFTED" && <Col span={2}>
                    <div className="edit-campaign-btn inactive d-flex align-items-center p-1 pointer"
                     style={{ width: "fit-content" }}
                     onClick={() => onDeleteCampaign(campaign)}>
                      <img className="ps-2 pe-2" src="/assets/svg/delete-white.svg" />
                    </div>
                  </Col> } */}
                </Row>
              )
            })}

                  <CustomPagination
                      currentPage={campaignPageNo}
                      totalItems={campaignPageDetails.total_records}
                      noOfRecords={10}
                      onChange={handleCampaignsTableChange}
                    />

            {/*
            <Row align="middle" className="engage-acc-list-row text-white mb-4" onClick={() => navigate("/engage/campaign-details")}>
              <Col span={8} className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="fw-bold pb-3">Campaign 1 - Test Name</div>
                  <div className="small fw-semibold pb-3">Persona 4 - Test Name</div>
                  <div className="d-flex align-items-center">
                    <div className="battery full"></div>
                    <span className="ps-2">95% Remaining</span>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-center align-items-center pb-2">
                    <img src="/assets/svg/refresh.svg" width="7" height="auto" alt="refresh" />
                    <span className="tiny ps-2">6 days ago</span>
                  </div>
                  <div className="lead-status-btn mtng_booked pointer br-13">
                    <span className="text-nowrap ps-2">Active</span>
                  </div>
                </div>
              </Col>
              <Col span={3}></Col>
              <Col span={6} className="d-flex justify-content-between align-items-center px-4">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center pe-4">
                    <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="" alt="icon" />
                    <span className="ps-2">560</span>
                  </div>
                  <div className="d-flex align-items-center pe-4">
                    <img src="/assets/images/mail-open-gray-2x.png" width="18" height="" alt="icon" />
                    <span className="ps-2">256</span>
                  </div>
                  <div className="d-flex align-items-center pe-4">
                    <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="17" height="" alt="icon" />
                    <span className="ps-2">12</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img src="/assets/images/link-gray-2x.png" width="19" height="" alt="icon" />
                    <span className="ps-2">22</span>
                  </div>
                </div>
              </Col>
              <Col span={3}></Col>
              <Col span={4} className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <Switch />
                  <span className="ps-2">OFF</span>
                </div>
                <div className="three-dots-btn blue pointer">...</div>
              </Col>
            </Row>

            <Row align="middle" className="engage-acc-list-row text-white mb-4" onClick={() => navigate("/engage/campaign-details")}>
              <Col span={8} className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="fw-bold pb-3">Campaign 1 - Test Name</div>
                  <div className="small fw-semibold pb-3">Persona 4 - Test Name</div>
                  <div className="d-flex align-items-center">
                    <div className="battery full"></div>
                    <span className="ps-2">95% Remaining</span>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-center align-items-center pb-2">
                    <img src="/assets/svg/refresh.svg" width="7" height="auto" alt="refresh" />
                    <span className="tiny ps-2">6 days ago</span>
                  </div>
                  <div className="lead-status-btn mtng_booked pointer br-13">
                    <span className="text-nowrap ps-2">Active</span>
                  </div>
                </div>
              </Col>
              <Col span={3}></Col>
              <Col span={6} className="d-flex justify-content-between align-items-center px-4">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center pe-4">
                    <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="" alt="icon" />
                    <span className="ps-2">560</span>
                  </div>
                  <div className="d-flex align-items-center pe-4">
                    <img src="/assets/images/mail-open-gray-2x.png" width="18" height="" alt="icon" />
                    <span className="ps-2">256</span>
                  </div>
                  <div className="d-flex align-items-center pe-4">
                    <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="17" height="" alt="icon" />
                    <span className="ps-2">12</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img src="/assets/images/link-gray-2x.png" width="19" height="" alt="icon" />
                    <span className="ps-2">22</span>
                  </div>
                </div>
              </Col>
              <Col span={3}></Col>
              <Col span={4} className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <Switch />
                  <span className="ps-2">OFF</span>
                </div>
                <div className="three-dots-btn blue pointer">...</div>
              </Col>
            </Row>

            <Row align="middle" className="engage-acc-list-row text-white mb-4" onClick={() => navigate("/engage/campaign-details")}>
              <Col span={8} className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="fw-bold pb-3">Campaign 1 - Test Name</div>
                  <div className="small fw-semibold pb-3">Persona 4 - Test Name</div>
                  <div className="d-flex align-items-center">
                    <div className="battery full"></div>
                    <span className="ps-2">95% Remaining</span>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-center align-items-center pb-2">
                    <img src="/assets/svg/refresh.svg" width="7" height="auto" alt="refresh" />
                    <span className="tiny ps-2">6 days ago</span>
                  </div>
                  <div className="lead-status-btn mtng_booked pointer br-13">
                    <span className="text-nowrap ps-2">Active</span>
                  </div>
                </div>
              </Col>
              <Col span={3}></Col>
              <Col span={6} className="d-flex justify-content-between align-items-center px-4">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center pe-4">
                    <img src="/assets/images/arrow-cross-gray-2x.png" width="20" height="" alt="icon" />
                    <span className="ps-2">560</span>
                  </div>
                  <div className="d-flex align-items-center pe-4">
                    <img src="/assets/images/mail-open-gray-2x.png" width="18" height="" alt="icon" />
                    <span className="ps-2">256</span>
                  </div>
                  <div className="d-flex align-items-center pe-4">
                    <img src="/assets/images/bidirectional-arrows-gray-2x.png" width="17" height="" alt="icon" />
                    <span className="ps-2">12</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img src="/assets/images/link-gray-2x.png" width="19" height="" alt="icon" />
                    <span className="ps-2">22</span>
                  </div>
                </div>
              </Col>
              <Col span={3}></Col>
              <Col span={4} className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <Switch />
                  <span className="ps-2">OFF</span>
                </div>
                <div className="three-dots-btn blue pointer">...</div>
              </Col>
            </Row>
            */}
          </>
        </Col>
      </Row>
    </Layout>
  )
}

export default CampaignsList;
